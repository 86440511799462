import { OverlayLoaderAction } from "../../../pages/common/overlayLoader/OverlayLoader.model";
import { NotificationAction } from 'src/pages/common/notification/notification.reducer';
import { actionTypes } from "./TaxSoftwareSetting.actions";

export interface ITaxSoftwareSetting {
	taxSoftware: TaxSoftwareType[];
}

export enum TaxSoftwareType {
	None = "None",
	ProSystems = "ProSystems",
	UltraTax = "UltraTax",
	GoSystem = "GoSystem",
	UltraTaxM18 = "UltraTaxM18",
	Lacerte = "Lacerte",
	Drake = "Drake"
}

export const initialState: ITaxSoftwareSetting = {
	taxSoftware: [TaxSoftwareType.ProSystems]
}

interface RequestTaxSoftwareSetting {
	type: actionTypes.REQUEST_TAXSOFTWARE_SETTING;
	isLoading: boolean;
}

interface ReceiveTaxSoftwareSetting {
	type: actionTypes.RECEIVE_TAXSOFTWARE_SETTING,
	taxSoftwareSetting: ITaxSoftwareSetting;
	isLoading: boolean;
}

interface UpdateTaxSoftwareSetting {
	type: actionTypes.UPDATE_TAXSOFTWARE_SETTING,
	taxSoftwareSetting: ITaxSoftwareSetting
}

export type KnownAction =
	RequestTaxSoftwareSetting |
	ReceiveTaxSoftwareSetting |
	UpdateTaxSoftwareSetting |
	NotificationAction |
	OverlayLoaderAction;