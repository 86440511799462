import { NotificationAction } from 'src/pages/common/notification/notification.reducer';
import { actionTypes } from './PasswordPolicySettings.actions';

export interface IDefinedPasswordPolicy {
    passwordPolicy: PasswordPolicyType,
    defaultSettings: IPasswordSettings,
    isEnabled: boolean
}

export interface IPasswordSettings {
    passwordOptions: PasswordOptions;
    length: number;
    passwordAge: number;
}

export enum PasswordOptions {
    None = 0,
    UpperCase = 1,
    LowerCase = 2,
    Numbers = 4,
    SpecialCharacters = 8,
}

export enum PasswordPolicyType {
    NoPolicy = 'NoPolicy',
    SSRDefault = 'SSRDefault',
    FirmDefinedPolicy = 'FirmDefinedPolicy',
}

export interface IPasswordPolicySettings {
    passwordPolicy: PasswordPolicyType;
    passwordSettings: IPasswordSettings;
    modifiedDate: Date;
}

export interface ReceiveDefinedPasswordPolicyAction {
    type: actionTypes.RECEIVE_DEFINED_PASSWORD_POLICY;
    definedPasswordPolicies: IDefinedPasswordPolicy[];
}

export interface RequestPasswordPolicySettingsAction {
    type: actionTypes.REQUEST_PASSWORD_POLICY_SETTINGS;
    message: string;
}

export interface ReceivePasswordPolicySettingsAction {
    type: actionTypes.RECEIVE_PASSWORD_POLICY_SETTINGS;
    passwordPolicySettings: IPasswordPolicySettings;
}

export const initialPasswordPolicySettings: IPasswordPolicySettings = {
    passwordPolicy: PasswordPolicyType.NoPolicy,
    modifiedDate: new Date(),
    passwordSettings: {
        passwordOptions: PasswordOptions.None,
        length: 8,
        passwordAge: 0,
    },
};

export type DispatchActions =
    ReceiveDefinedPasswordPolicyAction
    | RequestPasswordPolicySettingsAction
    | ReceivePasswordPolicySettingsAction

export type KnownActions = DispatchActions | NotificationAction;