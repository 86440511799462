import React, { FC, useEffect, useState } from 'react';
import BreadCrumbNav from 'src/components/userManagement/BreadCrumbNav';
import OverlayLoader from 'src/components/common/OverlayLoader';
import { IOverlayLoader } from '../common/overlayLoader/OverlayLoader.model';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { useDispatch } from 'react-redux';
import { IPaymentHistoryList } from 'src/modules/paymentHistory/PaymentHistory.model';
import { StatusType } from '../common/notification/notification.reducer';
import { PaymentHistoryConstants } from 'src/helper/Constants';
import * as PaymentHistoryStore from 'src/modules/paymentHistory/PaymentHistory.store';
import * as PurchaseReturnStore from 'src/modules/paymentHistory/PurchaseReturn.store';
import moment from 'moment';
import PaymentHistoryTable from '../../modules/paymentHistory/PaymentHistoryTable';
import PaymentHistoryHead from 'src/modules/paymentHistory/PaymentHistoryHead';
import { ICompanyData } from '../common/companySettings/companySettings.types';
import {
	IBillingDetailData,
	IPurchaseData,
	initialBillingDetailData,
} from 'src/modules/paymentHistory/PurchaseReturn.model';
import SuiteModal from 'src/components/common/SuiteModal';
import PurchaseReturnModal from 'src/modules/paymentHistory/PurchaseReturnModal';
import { ISaleTaxSetting, initialSaleTaxSetting } from '../../modules/paymentHistory/Tax.model';
import { getSaleSetting } from '../../modules/paymentHistory/Tax.apis';
import { logger } from '../../oidcClient/authProvider';
import { getBundleName } from '../../helper/HelperFunctions';

type PaymentHistoryProps = {
	companyData: ICompanyData;
	purchaseData: IPurchaseData;
	overlayLoader: IOverlayLoader;
} & typeof PaymentHistoryStore.actionCreators &
	typeof PurchaseReturnStore.actionCreators;


const defaultPageNumber = 1;
const defaultPageSizeForExportToExcel = 100000;
const PaymentHistory: FC<PaymentHistoryProps> = (props) => {
	const breadCrumbConfig = [
		{
			name: 'SafeSend Suite',
			activePage: false,
			link: '/',
		},
		{
			name: 'Payment History',
			activePage: true,
			link: '',
		},
	];

	const getPreviousMonthDate = () => {
		var today = new Date();
		return new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
	};

	const [fromDate, setFromDate] = useState(getPreviousMonthDate());
	const [toDate, setToDate] = useState(new Date());
	const [paymentHistoryList, setPaymentHistoryList] = useState<IPaymentHistoryList[]>([]);
	const [sortName, setSortName] = useState('paymentDate');
	const [sortOrder, setSortOrder] = useState('desc');
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [billingDetail, setBillingDetail] = useState<IBillingDetailData>(initialBillingDetailData);
	const [showPurchaseReturnModal, setShowPurchaseReturnModal] = useState(false);
	const dispatch = useDispatch();

	const [saleTaxSetting, setSaleTaxSetting] = useState<ISaleTaxSetting>(initialSaleTaxSetting);

	const pageSize: number = 10;

	useEffect(() => {
		logger && logger.trackPageView('Navigated to Payment History');
		dispatch({
			type: loaderTypes.LOADING,
			show: true,
			text: 'Loading...',
		});
		getPaymentHistoryReport(page, pageSize, fromDate, toDate);
	}, []);

	useEffect(() => {
		dispatch({
			type: loaderTypes.LOADING,
			show: true,
			text: 'Loading...',
		});
		getSaleSetting()
			.then((value: ISaleTaxSetting) => {
				setSaleTaxSetting(value);
			})
			.finally(() => {
				dispatch({
					type: loaderTypes.LOADING,
					show: false,
					text: '',
				});
			});
	}, []);

	const getPaymentHistoryReport = (page: number, pageSize: number, fromDate: Date, toDate: Date) => {
		requestBillingDetail();
		loadPaymentHistoryDetails(page, pageSize, sortName, sortOrder, fromDate, toDate);
	};

	const onFromDateChange = (date: any) => {
		const fromdate = new Date(date);
		setFromDate(fromdate);
	};

	const onToDateChange = (date: any) => {
		const todate = new Date(date);
		setToDate(todate);
	};

	const onFilter = () => {
		if (!fromDate || !moment(fromDate).isValid()) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: PaymentHistoryConstants.Validate.FromDateEmptyWarning,
			});
			return;
		}
		if (!toDate || !moment(toDate).isValid()) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: PaymentHistoryConstants.Validate.ToDateEmptyWarning,
			});
			return;
		}
		if (fromDate > toDate) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: PaymentHistoryConstants.Validate.ToDateLessThanFromDateWarning,
			});
			return;
		}
		getPaymentHistoryReport(1, pageSize, fromDate, toDate);
	};

	const setData = (data?: any) => {
		dispatch({
			type: loaderTypes.LOADING,
			show: false,
			text: '',
		});
		if (data && Array.isArray(data.paymentHistory) && data.paymentHistory.length > 0) {
			setPaymentHistoryList(data.paymentHistory);
			setTotalPages(Math.ceil(data.count / pageSize));
			setTotalCount(data.count);
		} else {
			setPaymentHistoryList([]);
			setTotalPages(0);
			setTotalCount(0);
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: PaymentHistoryConstants.Validate.NoPaymentHistory,
			});
		}
	};

	const exportToExcel = () => {
		if (paymentHistoryList.length > 0) {
			props.exportPaymentListAsExcel(buildQuery(defaultPageNumber, defaultPageSizeForExportToExcel, sortName, sortOrder, fromDate, toDate));
		}
	};

	const requestBillingDetail = () => {
		dispatch({
			type: loaderTypes.LOADING,
			show: true,
			text: 'Loading...',
		});
		props.getElectronicFiledBillingDetail((data: IBillingDetailData) => {
			setBillingDetail(data);
			dispatch({
				type: loaderTypes.LOADING,
				show: false,
				text: '',
			});
		});
	};

	const onSortChange = (newSortName: string) => {
		let newSortOrder = sortOrder;
		if (sortName !== newSortName) {
			setSortName(newSortName);
			newSortOrder = 'desc';
			setSortOrder(newSortOrder);
		} else {
			setSortName(newSortName);
			newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
			setSortOrder(newSortOrder);
		}
		setPage(1);
		loadPaymentHistoryDetails(1, pageSize, newSortName, newSortOrder, fromDate, toDate);
	};

	const goToPage = (newPageIndex: number) => {
		if (newPageIndex !== page && newPageIndex <= totalPages && newPageIndex >= 1) {
			setPage(newPageIndex);
			loadPaymentHistoryDetails(newPageIndex, pageSize, sortName, sortOrder, fromDate, toDate);
		}
	};

	const loadPaymentHistoryDetails = (
		newPage: number,
		newPageSize: number,
		newSortName: string,
		newSortOrder: string,
		fromDate: Date,
		toDate: Date,
	) => {
		dispatch({
			type: loaderTypes.LOADING,
			show: true,
			text: 'Loading...',
		});
		let queryString = buildQuery(newPage, newPageSize, newSortName, newSortOrder, fromDate, toDate);
		props.getPaymentHistoryReportAsync(queryString, setData);
	};

	const buildQuery = (
		pageNumber: number,
		pageSize: number,
		sortBy: string,
		sortOrder: string,
		fromDate: Date,
		toDate: Date,
	) => {
		return (
			'?pageNumber=' +
			pageNumber +
			'&pageSize=' +
			pageSize +
			'&sortBy=' +
			(sortBy === 'default' ? 'userName' : sortBy) +
			'&sortOrder=' +
			sortOrder +
			'&fromDate=' +
			moment(fromDate).format('MM/DD/YYYY') +
			'&toDate=' +
			moment(toDate).format('MM/DD/YYYY')
		);
	};

	return (
		<div className='suitePageContent'>
			<section className='suitePageInnerContent'>
				<div>
					<BreadCrumbNav config={breadCrumbConfig} />
					<h1 className='suitePageHeading'>Payment History</h1>
					<p className='suitePageSectionDescription'>You have subscribed to the {getBundleName(props.companyData)} Tier</p>
				</div>
				<OverlayLoader
					show={props.overlayLoader.show}
					text={props.overlayLoader.text}
				/>
				<section>
					<PaymentHistoryHead
						fromDate={fromDate}
						onFromDateChange={onFromDateChange}
						toDate={toDate}
						onToDateChange={onToDateChange}
						onFilter={onFilter}
						exportToExcel={exportToExcel}
						paymentHistoryList={paymentHistoryList}
						currentBalance={billingDetail.remainingReturns}
						setShowPurchaseReturnModal={setShowPurchaseReturnModal}
					/>
					<PaymentHistoryTable
						paymentHistoryData={paymentHistoryList}
						page={page}
						totalPages={totalPages}
						totalCount={totalCount}
						goToPage={goToPage}
						sortName={sortName}
						sortOrder={sortOrder}
						onSortChange={onSortChange}
					/>
				</section>
			</section>

			{showPurchaseReturnModal && (
				<SuiteModal
					width='850'
					theme='light'
					title='Returns Purchase'
					hide={() => setShowPurchaseReturnModal(false)}>
					<PurchaseReturnModal
						purchaseDetails={billingDetail}
						companySubscription={props.companyData.companyProfile.companySubscription}
						paymentDetails={props.purchaseData.paymentDetails}
						requestBillingDetail={requestBillingDetail}
						savePurchase={props.savePurchase}
						currentPage={'payment-history'}
						getPaymentSetUpToken={props.getPaymentSetUpToken}
						saleTaxSetting={saleTaxSetting}
						setShowPurchaseReturnModal={setShowPurchaseReturnModal}
					/>
				</SuiteModal>
			)}
		</div>
	);
};

export default PaymentHistory;
