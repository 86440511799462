import produce from "immer";
import { Action, Reducer } from "redux";
import { actionTypes } from "./userSettings.actions";
import * as UserSettingsActions from "./userSettings.types";

export interface IUserSettingsStore {
    isLoading: boolean;
    defaultSettings: { enableLastLogin: boolean };
}

const initialState: IUserSettingsStore = {
    isLoading: false,
    defaultSettings: { enableLastLogin: false }
};

export const reducer: Reducer<IUserSettingsStore> = produce(
    (draft: IUserSettingsStore = initialState, incomingAction: Action) => {
        const action = incomingAction as UserSettingsActions.DispatchActions;
        switch (action.type) {
            case actionTypes.RECEIVE_LAST_LOGIN_ENABLE:
                draft.defaultSettings.enableLastLogin = action.payload;
                return draft;
            default:
                return draft;
        }
    }
);
