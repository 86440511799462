import { type FC } from 'react';
import { ReactComponent as Building } from 'src/components/svg/building.svg';

import styles from './add-company-card.module.scss';

const AddCompanyCard: FC = () => {
	return (
		<div className={styles.add_company_card_container}>
			<div className={styles.add_company_icon}>
				<Building />
			</div>
			<div className={styles.add_company_text}>Add New Office Location</div>
		</div>
	);
};

export default AddCompanyCard;
