import BreadCrumbNav from 'src/components/userManagement/BreadCrumbNav';
import CustomAuthoritiesTable from 'src/modules/voucher-invoices/custom-authorities/custom-authorities-table';
import { TaxReturnSettingsResources } from 'src/helper/rbac-constants';
import { useEffect } from 'react';
import { logger } from '../../oidcClient/authProvider';

const VoucherAndInvoices: React.FC = () => {
	const pageBreadCrumConfig = [
		{
			name: 'SafeSend Suite',
			activePage: false,
			link: '/',
		},
		{
			name: 'Vouchers & Invoices',
			activePage: true,
			link: '',
		},
	];

	useEffect(() => {
		logger && logger.trackPageView('Navigated to Vouchers and Invoices');
	}, []);

	return (
		<div className='suitePageContent'>
			<BreadCrumbNav config={pageBreadCrumConfig} />
			<section>
				<h1 className='suitePageHeading'>Vouchers & Invoices</h1>

				<CustomAuthoritiesTable parentResourceIdentifier={TaxReturnSettingsResources.VoucherAndInvoicesPrefix} />
			</section>
		</div>
	);
};

export default VoucherAndInvoices;
