import CloseIcon from "src/components/svg/CloseIcon";
import React, { FC, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./SignatureDelegationModal.style.scss";
import { getAllUsers } from "./SignatureDelegationModal.api";
import AvailableUsersSD from "../AvailableUsersSD";
import { IUsersDropdown } from "../Users.model";
import DelegationUsers from "../DelegationUsers";

interface SignatureDelegationModalProps {
    setShowSignatureDelegationModal: (showSignatureDelegationModal: boolean) => void;
    saveDelegateeIDs: (delegateeIDs: number[]) => void;
    delegateeIDsFromDB: number[];
}

const SignatureDelegationModal: FC<SignatureDelegationModalProps> = (props) => {
    const { setShowSignatureDelegationModal, saveDelegateeIDs, delegateeIDsFromDB } = props;

    const [availableUsers, setAvailableUsers] = useState<IUsersDropdown[]>([]);
    const [usersInDelegation, setUsersInDelegation] = useState<IUsersDropdown[]>([]);
    const [availableUsersInitial, setAvailableUsersInitial] = useState<IUsersDropdown[]>([]);
    const [usersInDelegationInitial, setUsersInDelegationInitial] = useState<IUsersDropdown[]>([]);
    const [usersInProgress, setUsersInProgress] = useState(false);

    const handleClose = () => setShowSignatureDelegationModal(false);

    const handleDropChange = (delegationUsers: IUsersDropdown[], usersAvailable: IUsersDropdown[]) => {
        const uniqueDelegationUsers = delegationUsers.filter((user: any, index: any) => delegationUsers.findIndex((u: any) => user.value === u.value) === index);
        const uniqueUsersAvailable = usersAvailable.filter((user: any, index: any) => usersAvailable.findIndex((u: any) => user.value === u.value) === index);
        setUsersInDelegation(uniqueDelegationUsers);
        setAvailableUsers(uniqueUsersAvailable);
    };

    const handleReset = () => {
        setAvailableUsers(availableUsersInitial);
        setUsersInDelegation(usersInDelegationInitial);
    }

    const handleSave = () => {
        const delegateeIDs = usersInDelegation.map(eachUser => eachUser.value);
        saveDelegateeIDs(delegateeIDs);
    }

    useEffect(() => {
        setUsersInProgress(true);
        getAllUsers((totalUsersFromDB) => {
            const filteredAvailableUsers: IUsersDropdown[] = [];
            const delegateUsersArray = totalUsersFromDB.filter((eachUser: IUsersDropdown) => {
                if (delegateeIDsFromDB.includes(eachUser.value)) {
                    return true;
                } else {
                    filteredAvailableUsers.push(eachUser);
                    return false;
                }
            });
            setAvailableUsers(filteredAvailableUsers);
            setAvailableUsersInitial(filteredAvailableUsers);
            setUsersInDelegation(delegateUsersArray);
            setUsersInDelegationInitial(delegateUsersArray);
            setUsersInProgress(false);
        });
    }, []);

    return (
        <Modal show={true} onHide={handleClose} className="signatureDelegationModal">
            <Modal.Header>
                <div><Modal.Title>Signature Delegation</Modal.Title></div>
                <div data-test-auto="17307175-8dc7-4af0-a4fd-8cd33359151d" className="closeIcon" onClick={handleClose}><CloseIcon /></div>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <div className="containerClass">
                    <section className="descriptionText">
                        Move users that can apply your signature stamp to the Delegation side.
                    </section>
                    <section className="usersContainer">
                        <AvailableUsersSD
                            usersInDelegation={usersInDelegation}
                            availableUsers={availableUsers}
                            handleDropChange={handleDropChange}
                        />
                        <DelegationUsers
                            usersInDelegation={usersInDelegation}
                            availableUsers={availableUsers}
                            handleDropChange={handleDropChange}
                        />
                    </section>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button data-test-auto="208bc100-6906-41bc-898d-92a8a762682b" variant="secondary" className="signatureDelegationReset" disabled={usersInProgress} onClick={handleReset}>
                    Reset
                </Button>
                <Button data-test-auto="141a1ede-fc65-4062-9f0d-90617ac224f6" variant="secondary" className="signatureDelegationCancel" onClick={handleClose}>
                    Cancel
                </Button>
                <Button data-test-auto="2811ecb9-2e9a-48a3-a96e-5fe1294a386a" variant="primary" className="signatureDelegationApply" disabled={usersInProgress} onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SignatureDelegationModal;