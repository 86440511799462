import { API_BASE_URL } from "src/helper/Constants";
import axios, { AxiosResponse } from "axios";
import { IEROSignatureSettings } from "./EROSignatureModal.model";
import { b64toBlob } from "src/helper/HelperFunctions";
import { userTabConstants } from '../../../../helper/rbac-constants';
import { logger } from 'src/oidcClient/authProvider';

export function getEROSignatureSettings(userId: number, callback: (eroSignatureSettings: IEROSignatureSettings) => void) {
    axios.get(API_BASE_URL + "/api/user-management/ero-signature-settings/" + userId)
        .then((response: AxiosResponse) => {
            callback(response.data);
        }).catch((error) => {
            logger && logger.trackWarning('Error while getEROSignatureSettings', { 'Error': error?.response?.data});
        });
}

export function uploadSignature(userId: number, signaturePath: string, callback: (signaturePath: string) => void) {
    axios.get(API_BASE_URL + '/api/user-management/user-signature-upload-link/' + userId)
        .then((response: AxiosResponse) => {
            const url = response.data;
            const blobData = b64toBlob(
                signaturePath.replace('data:image/png;base64,', ''),
                "image/png",
                512);
            const apiheaders = new Headers();
            apiheaders.append("Access-Control-Allow-Origin", "*");
            apiheaders.append("x-ms-blob-type", "BlockBlob");
            apiheaders.append("Content-Type", "image/png");
            apiheaders.append("x-ms-meta-filename", "UserSignature.png");
            apiheaders.append("x-ms-meta-filetype", "image/png");
            apiheaders.append('X-Resource-Id', `${userTabConstants.prefix}_${userTabConstants.grid}_${userTabConstants.eroSign}`);
            fetch(url, {
                method: 'PUT',
                headers: apiheaders,
                body: blobData
            }).then(response => {
                callback(url);
            });
        })
        .catch(error => {
            logger && logger.trackWarning('Error while uploadSignature', { 'Error': error?.response?.data});
        });
}

export function saveEROSignatureSettings(eroSignatureSettings: IEROSignatureSettings, callback: () => void) {
    axios.put(API_BASE_URL + "/api/user-management/ero-signature-settings", eroSignatureSettings, {
        headers: {
            'X-Resource-Id': `${userTabConstants.prefix}_${userTabConstants.grid}_${userTabConstants.eroSign}`
        }
    })
        .then((response: AxiosResponse) => {
            const { data } = response;
            if (data) {
                callback();
            }
        }).catch((error) => {
            logger && logger.trackWarning('Error while saveEROSignatureSettings', { 'Error': error?.response?.data});
        });
}