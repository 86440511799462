import InfoIcon from 'src/components/svg/InfoIcon';
import React, { useState, FC, useEffect } from 'react';
import DraggableListSD from '../DraggableListSD';
import { IUsersDropdown } from '../Users.model';

interface DelegationUsersProps {
    usersInDelegation: IUsersDropdown[];
    availableUsers: IUsersDropdown[];
    handleDropChange: (delegationUsers: IUsersDropdown[], usersAvailable: IUsersDropdown[]) => void;
}

const DelegationUsers: FC<DelegationUsersProps> = (props) => {
    const {
        usersInDelegation,
        availableUsers,
        handleDropChange
    } = props;
    const [delegationUser, setDelegationUser] = useState<any[]>([]);

    useEffect(() => {
        setDelegationUser(usersInDelegation)
    }, [usersInDelegation]);

    const dragOverHandler = (event: any) => {
        event.preventDefault();
    };

    const dropHandler = (event: any) => {
        const user = JSON.parse(event.dataTransfer.getData('text'));
        if (usersInDelegation.some((ele: any) => user.findIndex((datum: any) => datum.value === ele.value) === -1) || !usersInDelegation.length) {
            const newUserList = [...user, ...usersInDelegation];
            const newAvailableUser = availableUsers.filter((ele: any) => !user.some((eachUser: any) => eachUser.value === ele.value));
            handleDropChange(newUserList, newAvailableUser);
        }
    };

    return (
        <article className="delegationUsersContainer">
            <div className="delegationUsersHeaderDiv"><span className="delegationUsersHeader">DELEGATION</span><span className="delegationUsersInfo" title="Listed users allowed to apply signature stamp"><InfoIcon /></span></div>
            <ul className="list_container" onDragOver={(event) => dragOverHandler(event)} onDrop={(event) => dropHandler(event)}>
                <DraggableListSD
                    listData={delegationUser}
                />
            </ul>
        </article>
    );
};

export default DelegationUsers;