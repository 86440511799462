import { GroupBase, ValueContainerProps, components } from 'react-select';
import CloseIcon from '../../../components/svg/CloseIcon';
import DownIcon from '../../../components/svg/DownIcon';

export const OptionComponent = (props: any) => {
	const { value } = props.selectProps;
	const locationNumber = props.selectProps.options.filter((x: any) => x.value === props.value)[0]?.id;
	return (
		<div>
			<components.Option {...props}>
				<div className='single-option-container'>
					<label className='single-option-container_label'>
						<div
							className='text-ellipsis single-option-container_label--name'
							title={props.label}
							style={{ color: `${value?.value === props.value && '#fff'}` }}>
							{props.label}
						</div>
						<div
							style={{ color: `${value?.value === props.value && '#fff'}` }}
							className='single-option-container_label--hyphen'>
							-
						</div>
						<div
							className='text-ellipsis single-option-container_label--value'
							title={`Location #${locationNumber}`}
							style={{ color: `${value?.value === props.value && '#fff'}` }}>{`Location #${locationNumber}`}</div>
					</label>
				</div>
			</components.Option>
		</div>
	);
};

export const ValueComponent: React.FC<ValueContainerProps<any, boolean, GroupBase<any>>> = ({ children, ...props }) => {
	const {
		selectProps: { value, placeholder },
	} = props;

	if (value) {
		return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
	} else {
		return (
			<components.ValueContainer {...props}>
				{<div>{placeholder}</div>}
				{children}
			</components.ValueContainer>
		);
	}
};

export const DropdownIndicator = (props: any) => {
	return (
		<components.DropdownIndicator {...props}>
			<DownIcon />
		</components.DropdownIndicator>
	);
};

export const ClearIndicator = (props: any) => {
	return (
		<components.ClearIndicator {...props}>
			<CloseIcon />
		</components.ClearIndicator>
	);
};
