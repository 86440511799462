import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CloseIcon from '../../../components/svg/CloseIcon';
import './ColumnOptions.style.scss';
import { IColumnOptions, columnNames, columnOptionsOrder } from '../Client.model';
import { saveColumnOptions } from '../Client.api';

interface ColumnOptionsProps {
	activeColumns: IColumnOptions[];
	setActiveColumns: (activeColumns: IColumnOptions[]) => void;
	setShowColumnOptions: (showColumnOptions: boolean) => void;
	requestClientsAndColumns: () => void;
}

const ColumnOptions: React.FC<ColumnOptionsProps> = (props) => {
	const { setShowColumnOptions, activeColumns, requestClientsAndColumns } = props;

	const [showColumns, setShowColumns] = useState(
		JSON.parse(
			JSON.stringify(
				columnOptionsOrder.map((columnName: string) =>
					activeColumns.find((column: IColumnOptions) => column.columnName === columnName),
				),
			),
		),
	);
	const [inProgress, setInProgress] = useState(false);

	const handleChanges = (e: React.ChangeEvent<HTMLInputElement>, columnName: string) => {
		const columns = [...showColumns];
		const col = columns.find((x) => x.columnName === columnName);
		if (col) {
			col.isActive = e.target.checked;
		}
		setShowColumns(columns);
	};

	const handleSave = () => {
		setInProgress(true);
		saveColumnOptions(showColumns, () => {
			requestClientsAndColumns();
			setInProgress(false);
			setShowColumnOptions(false);
		});
	};

	const getCheckboxName = (columnName: string) => {
		if (columnName === columnNames.spouseDob) {
			return columnNames.dob;
		} else if (columnName === columnNames.spouseEmailAddress) {
			return columnNames.emailAddress;
		} else if (columnName === columnNames.spouseMobile) {
			return columnNames.mobile;
		} else if (columnName === columnNames.spouseName) {
			return columnNames.name;
		} else if (columnName === columnNames.spouseSsn) {
			return 'SSN';
		} else {
			return columnName;
		}
	};

	return (
		<Modal
			show={true}
			className='modalClass columnOptionsModal'
			onHide={() => setShowColumnOptions(false)}>
			<Modal.Header className='columnOptionsModalHeader'>
				<div>
					<Modal.Title>Customize Columns</Modal.Title>
				</div>
				<div
					data-test-auto='e2ab6c41-b6f5-4181-b38f-3455585b2141'
					className='closeIcon'
					onClick={() => setShowColumnOptions(false)}>
					<CloseIcon />
				</div>
			</Modal.Header>
			<Modal.Body>
				<ul className='ugCheckArea'>
					{showColumns.map((column: IColumnOptions, index: number) => {
						return (
							<>
								{column.columnName === columnNames.dob && (
									<div className='columnOptionsSection'>Taxpayer / Entity</div>
								)}
								{column.columnName === columnNames.spouseDob && (
									<div className='columnOptionsSection'>Spouse</div>
								)}
								<li
									key={index}
									className='eachUGCheck'
									data-name={`${index}_li`}
									id={`${index}_li`}>
									<input
										type='checkbox'
										className='ugCheckbox'
										id={`${index}_id`}
										name={`${index}_input`}
										checked={column?.isActive}
										disabled={column.columnName === 'Client ID' && true}
										onChange={(e) => handleChanges(e, column.columnName)}
									/>
									<label
										htmlFor={`${index}_id`}
										data-name={`${index}_label`}
										id={`${index}_label`}>
										{getCheckboxName(column.columnName)}
									</label>
								</li>
							</>
						);
					})}
				</ul>
			</Modal.Body>
			<Modal.Footer>
				<Button
					data-test-auto='bf271b75-04e5-4238-bebc-b1f3667be192'
					variant='secondary'
					onClick={() => setShowColumnOptions(false)}>
					Cancel
				</Button>
				<Button
					disabled={inProgress}
					data-test-auto='6e15750b-3c9d-49a9-b272-49e048689c94'
					variant='primary'
					onClick={handleSave}>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ColumnOptions;
