import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { LogoutConfirmationConstants } from "src/helper/Constants";
import { WithLogout, WithLogoutProps } from "src/oidcClient/WithLogout";

interface ILogoutConfirmationProps extends WithLogoutProps {
    show: boolean;
    onHide: () => void;
}

const LogoutConfirmation: FC<ILogoutConfirmationProps> = ({ show, onHide, onLogout }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleLogout = () => {
        setIsLoading(true);
        onLogout();
    };
    return <Modal
        show={show}
        onHide={onHide}
        contentClassName="border-radius-5"
        dialogClassName="common-modal">
        <Modal.Header className="common-modal-header"><span className='common-modal-header-text'>
            {LogoutConfirmationConstants.TITLE}
        </span>
        </Modal.Header>
        <Modal.Body className="common-modal-content">
            <LoadingOverlay>
                <Loader
                    classNamePrefix="sm"
                    text=""
                    loading={isLoading}
                />
                <div className="py-3">{LogoutConfirmationConstants.CONFIRMATION_TEXT}</div>
            </LoadingOverlay>
        </Modal.Body>

        <Modal.Footer className="common-modal-footer">
            <button
                className="modal-secondary-button secondary-btn-bg"
                onClick={onHide}
                data-test-auto="31DF48DE-9F14-4065-B44A-08D265BA2CF5"
            >{LogoutConfirmationConstants.CANCEL_TEXT}</button>
            <button
                className="modal-primary-button red-btn-bg"
                onClick={handleLogout}
                disabled={isLoading}
                data-test-auto="32FE58D3-FB91-4F86-98BA-29D93FBB5762"
            >{LogoutConfirmationConstants.OK_TEXT}</button>
        </Modal.Footer>
    </Modal>
};

export default WithLogout(LogoutConfirmation);