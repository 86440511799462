import { Reducer } from "redux";
import axios, { AxiosResponse } from 'axios';
import { AppThunkAction } from 'src/store/index';
import { IDefinedPasswordPolicy, KnownActions } from './PasswordPolicySettings.model';
import { actionTypes } from './PasswordPolicySettings.actions';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { API_BASE_URL } from 'src/helper/Constants';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {

    requestDefinedPasswordPolicies: ():
        AppThunkAction<KnownActions> => (dispatch, getState) => {
            axios.get(API_BASE_URL + '/api/settings/password-policy/defined-password-policy',
            {
                headers: {
                    'X-Resource-Id': 'SUITE_Secr_PswrdPolicy_BtnApply'
                  },
            },   
            )
                .then((response: AxiosResponse<IDefinedPasswordPolicy[]>) => {
                    const { data } = response;
                    dispatch({
                        type: actionTypes.RECEIVE_DEFINED_PASSWORD_POLICY, definedPasswordPolicies: data
                    });
                }).catch(function (error) {
                    logger && logger.trackWarning('Error while requestDefinedPasswordPolicies', { 'Error': error?.response?.data});
                    const statusMessage: any = error.statusText;
                    if (typeof (statusMessage) === "string") {
                        dispatch({
                            type: notificationTypes.NOTIFICATION, statusMessage: statusMessage,
                            statusType: StatusType.Error
                        });
                    }
                });
        }
};

const unloadedState: IDefinedPasswordPolicy[] = [];

export const reducer: Reducer<IDefinedPasswordPolicy[]> = (draftState = unloadedState, incomingAction) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.RECEIVE_DEFINED_PASSWORD_POLICY:
            var received = action.definedPasswordPolicies;
            return received;
    }
    return draftState;
}