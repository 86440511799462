import { actionTypes } from './roles-and-permissions.actions';
import { Reducer } from 'redux';
import { DispatchAction } from './roles-and-permissions.types';

interface IRolesData {
	isLoading: boolean;
	rolesData: any[];
}

const unloadedState: IRolesData = {
	isLoading: false,
	rolesData: [],
} as IRolesData;

export const reducer: Reducer<any> = (state = unloadedState, incomingAction) => {
	const action = incomingAction as DispatchAction;
	switch (action.type) {
		case actionTypes.REQUEST_ALL_ROLES:
			return {
				isLoading: true,
			} as any;

		case actionTypes.REQUEST_ALL_ROLES_SUCCESS:
			const rolesState = {
				...state,
				...{
					isLoading: false,
					allRoles: action['rolesData'],
				},
			} as any;
			return rolesState;
		case actionTypes.REQUEST_ALL_PRODUCTS:
			return {
				isLoading: true,
			} as any;

		case actionTypes.REQUEST_ALL_PRODUCTS_SUCCESS:
			const newState = {
				...state,
				...{
					isLoading: false,
					allProducts: action['productData'],
				},
			} as any;
			return newState;
		default:
	}

	return state;
};
