import React, { FC } from 'react';
import { Button, Form } from 'react-bootstrap';
import { IPurchaseState } from '../PurchaseReturn.model';
import { ITaxInfo } from '../Tax.model';
import CartIcon from 'src/components/svg/CartIcon';
import CloseIcon from 'src/components/svg/CloseIcon';
import styles from './PurchaseReturnBody.module.scss';
import HorizontalDivider from 'src/components/common/HorizontalDivider';

interface PurchaseReturnBodyProps {
	isLoading: boolean;
	isPurchaseTab: boolean;
	purchaseData: IPurchaseState;
	onTabSelect(isPurchaseClick: boolean): void;
	onSsrQuantityChange(quantity: number): void;
	onSSRItemAdd(): void;
	onSSRItemRemove(): void;
	ssrItemAdded: boolean;
	taxInfo: ITaxInfo;
}
const PurchaseReturnBody: FC<PurchaseReturnBodyProps> = (props) => {
	let totaltax: number = props.taxInfo.totalTax;
	return (
		<div id='purcahsetabcontent'>
			{props.isPurchaseTab ? (
				<div id='purchase'>
					<Form>
						<h4 className='suitePageSectionSubHeader'>Purchase Details</h4>
						<table className={`table ${styles.purchaseReturnTable}`}>
							<thead>
								<tr>
									<th
										className={styles.productCol}
										scope='col'>
										Product
									</th>
									<th
										className={styles.currentBalanceCol}
										scope='col'>
										Current Balance
									</th>
									<th
										className={styles.quantityCol}
										scope='col'>
										Quantity
									</th>
									<th
										className={styles.pricePerReturnCol}
										scope='col'>
										Price Per Return
									</th>
									<th
										className={styles.totalCol}
										scope='col'>
										Total
									</th>
									<th
										className={styles.addCol}
										scope='col'></th>
								</tr>
							</thead>
							<tbody>
								<tr className={styles.purchaseRow}>
									<td className={styles.productColData}>SafeSend Returns</td>
									<td
										className={`${styles.currentBalanceColData} ${
											props.purchaseData.ssrPurchaseState.currentBalance < 0 ? styles.negativeCurrentBalance : ''
										}`}>
										{props.purchaseData.ssrPurchaseState.currentBalance}
									</td>
									<td className={styles.quantityColData}>
										<input
											type='text'
											name='returnQuantity'
											id='returnQuantity'
											value={props.purchaseData.ssrPurchaseState.ssrReturnQuantityTemp}
											onChange={(e) => {
												props.onSsrQuantityChange(Number(e.target.value));
											}}
											data-test-auto='2f9264e0-9142-4f8c-99b0-a7386249d1f7'
										/>
									</td>
									<td className={styles.pricePerReturnColData}>
										{'$' + props.purchaseData.ssrPurchaseState.ssrPricePerReturn.toFixed(2)}
									</td>
									<td className={styles.totalColData}>
										{'$' + props.purchaseData.ssrPurchaseState.ssrTotalAmountTemp.toFixed(2)}
									</td>
									<td className={styles.addColData}>
										<Button
											title='Add SafeSend Returns'
											className={styles.addButton}
											disabled={
												!(
													props.purchaseData.ssrPurchaseState.ssrOutStandingBalance +
														props.purchaseData.ssrPurchaseState.ssrReturnQuantityTemp >
														0 && props.purchaseData.ssrPurchaseState.ssrPricePerReturn > 0
												)
											}
											onClick={props.onSSRItemAdd}
											data-test-auto='30a17660-e4ff-4755-84a7-ae5c800b5323'
											variant='primary'>
											<span className={styles.cartIcon}>
												<CartIcon />
											</span>
											<span className={styles.addText}>Add</span>
										</Button>
									</td>
								</tr>
							</tbody>
						</table>
						<div>
							{props.ssrItemAdded ? (
								<div className={styles.addedPurchaseTable}>
									<div className={styles.header}>
										<div className={styles.headerText}>SafeSend Returns</div>
										<div
											className={styles.headerClose}
											onClick={props.onSSRItemRemove}
											data-test-auto='18eab610-e3ab-487a-ae61-15192c757693'>
											<CloseIcon />
										</div>
									</div>
									<div className={styles.addedPurchaseTableDetails}>
										<div className={styles.eachDetail}>
											<label>Number of Returns</label>
											<div>
												<input
													type='text'
													onChange={(e) => {
														props.onSsrQuantityChange(Number(e.target.value));
													}}
													value={props.purchaseData.ssrPurchaseState.ssrReturnQuantity}
													placeholder='0'
													data-test-auto='5cda3eb4-4608-4c04-a0b8-ff3a7d6aefe5'
													className='form-control marginBottm10p txt-right-align'
												/>
											</div>
										</div>
										<div className={`${styles.eachDetail} ${styles.eachDetailDisabled}`}>
											<label>Price Per Return</label>
											<div>
												<input
													type='text'
													value={'$ ' + props.purchaseData.ssrPurchaseState.ssrPricePerReturn.toFixed(2)}
												/>
											</div>
										</div>
										<div className={`${styles.eachDetail} ${styles.eachDetailDisabled}`}>
											<label>Total</label>
											<div>
												<input
													type='text'
													value={
														'$ ' +
														(
															props.purchaseData.ssrPurchaseState.ssrPricePerReturn *
															props.purchaseData.ssrPurchaseState.ssrReturnQuantity
														).toFixed(2)
													}
												/>
											</div>
										</div>
										<HorizontalDivider />
										<div
											className={`${styles.eachDetail} ${styles.eachDetailDisabled}`}
											style={{ marginTop: '0.6rem' }}>
											<label>Current Purchase</label>
											<div>
												<input
													type='text'
													value={
														'$ ' +
														(
															props.purchaseData.ssrPurchaseState.ssrPricePerReturn *
															props.purchaseData.ssrPurchaseState.ssrReturnQuantity
														).toFixed(2)
													}
												/>
											</div>
										</div>
										<div className={`${styles.eachDetail} ${styles.eachDetailDisabled}`}>
											<label>Outstanding Balance</label>
											<div>
												<input
													type='text'
													value={
														'$ ' +
														(
															props.purchaseData.ssrPurchaseState.ssrOutStandingBalance *
															props.purchaseData.ssrPurchaseState.ssrPricePerReturn
														).toFixed(2)
													}
												/>
											</div>
										</div>
										<div className={`${styles.eachDetail} ${styles.eachDetailDisabled}`}>
											<label>Sub Total</label>
											<div>
												<input
													type='text'
													value={'$ ' + props.purchaseData.ssrPurchaseState.ssrTotalAmount.toFixed(2)}
												/>
											</div>
										</div>
									</div>
								</div>
							) : (
								<div></div>
							)}
						</div>
					</Form>
				</div>
			) : (
				<div
					className={styles.profileClass}
					id='profile'>
					<div className={styles.profileInnerClass}>
						<div className={`${styles.eachRow} ${styles.profileHeader}`}>
							<label className=''>Product Details</label>
							<label className=''>Price</label>
						</div>
						<HorizontalDivider />
						{props.ssrItemAdded ? (
							<div className={`${styles.eachRow} ${styles.productRowData}`}>
								<label className=''>
									SafeSend Returns
									<br />
									<small className=''>
										Quantity : {props.purchaseData.ssrPurchaseState.ssrReturnQuantity}&nbsp;&nbsp;<span></span>
										&nbsp;&nbsp;Outstanding : {props.purchaseData.ssrPurchaseState.ssrOutStandingBalance}
									</small>
								</label>
								<label className=''>${props.purchaseData.ssrPurchaseState.ssrTotalAmount.toFixed(2)}</label>
							</div>
						) : (
							''
						)}
						<div className={`${styles.eachRow} ${styles.salesTaxRowData}`}>
							<label className=''>Sales Tax </label>
							<label className=''>${totaltax.toFixed(2)} </label>
						</div>

						<div className={styles.eachRow}>
							<label className=''>Total</label>
							<label className=''>
								${((props.ssrItemAdded ? props.purchaseData.ssrPurchaseState.ssrTotalAmount : 0) + totaltax).toFixed(2)}
							</label>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default PurchaseReturnBody;
