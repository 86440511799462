import React, { FC, useEffect, useState } from 'react';
import DraggableList from '../DraggableList';
import InfoIcon from 'src/components/svg/InfoIcon';
import { IDragAndDropData } from '../DragAndDrop.model';

interface SourceProps<T1 extends string, T2, T3 = undefined> {
	destinationData: IDragAndDropData<T1, T2, T3 | undefined>[];
	sourceData: IDragAndDropData<T1, T2, T3 | undefined>[];
	handleDropChange: (destination: IDragAndDropData<T1, T2, T3 | undefined>[], source: IDragAndDropData<T1, T2, T3 | undefined>[],
		usersRemoved?: IDragAndDropData<T1, T2, T3 | undefined>[]) => void;
	sourceHeading?: string;
	sourceHeadingInfoText?: string;
	sourceWidth?: string;
	sourceHeight?: string;
}

function Source<T1 extends string, T2, T3 = undefined>(props: SourceProps<T1, T2, T3>) {
	const {
		destinationData = [],
		sourceData = [],
		handleDropChange = () => null,
		sourceHeading,
		sourceHeadingInfoText,
		sourceWidth,
		sourceHeight,
	} = props;

	const [source, setSource] = useState<IDragAndDropData<T1, T2, T3 | undefined>[]>([]);

	useEffect(() => {
		setSource(sourceData);
	}, [sourceData]);

	const dragOverHandler = (event: any) => {
		event.preventDefault();
	};

	const dropHandler = (event: any) => {
		const data = JSON.parse(event.dataTransfer.getData('text'));
		if (
			sourceData.some((ele: any) => data.findIndex((datum: any) => datum.value === ele.value) === -1) ||
			!sourceData.length
		) {
			const newSourceList = [...data, ...sourceData];
			const newDestinationList = destinationData.filter(
				(ele: any) => !data.some((eachData: any) => eachData.value === ele.value),
			);
			handleDropChange(newDestinationList, newSourceList, data);
		}
	};

	return (
		<article
			className='sourceDataContainer'
			style={sourceWidth ? { width: sourceWidth } : null}>
			{sourceHeading && (
				<div className='sourceDataHeaderDiv'>
					<span className='sourceDataHeader'>{sourceHeading}</span>
					{sourceHeadingInfoText && (
						<span
							className='sourceDataInfo'
							title={sourceHeadingInfoText}>
							<InfoIcon />
						</span>
					)}
				</div>
			)}
			<ul
				className='list_container'
				onDragOver={(event) => dragOverHandler(event)}
				onDrop={(event) => dropHandler(event)}
				style={sourceHeight ? { height: sourceHeight } : null}>
				<DraggableList<T1, T2, T3 | undefined>
					listData={source} />
			</ul>
		</article>
	);
};
export default Source;
