import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Table, FormControl } from 'react-bootstrap';
import { IMFAIPAddressModel, IMFASettingsState, initialMFAIPAddress } from '../MFASettings.model';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import ConfirmationPopup from 'src/components/common/confirmationPopup';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { IPAddressConstant, IPDeleteConfirmationPopupConstants } from 'src/helper/Constants';
import { ValidateIPAdress, convertIPAddresstoNumber } from 'src/helper/Validations';
import {
	AddButtonFormatter,
	CancelButtonFormatter,
	DeleteButtonFormatter,
	EditButtonFormatter,
} from './ButtonFormatter';
import styles from './MFAIP.module.scss';

interface MFAIPProps {
	mfaSettings: IMFASettingsState;
	saveMFAIPAddress(imfaIPAddressModel: IMFAIPAddressModel, callback: () => void): void;
	deleteMFAIPAddress(ipAddressId: number, callback: () => void): void;
	updateMFAIPAddress(imfaIPAddressModel: IMFAIPAddressModel, callback: () => void): void;
	requestMFAIPAddress(reload: boolean): void;
}
const MFAIP: FC<MFAIPProps> = (props) => {
	const { mfaSettings, saveMFAIPAddress, deleteMFAIPAddress, updateMFAIPAddress, requestMFAIPAddress } = props;

	const [ipAddress, setIPAddress] = useState(initialMFAIPAddress);
	const [addRow, setAddRow] = useState(false);
	const [editRow, setEditRow] = useState(false);
	const [editRowId, setEditRowId] = useState(0);
	const [ipAddressList, setIPAddressList] = useState<IMFAIPAddressModel[]>([]);
	const [ipAddressEdit, setIPAddressEdit] = useState<IMFAIPAddressModel>(initialMFAIPAddress);
	const [showDeleteIPModal, setShowDeleteIPModal] = useState(false);
	const [deleteIPId, setDeleteIPId] = useState(0);
	const dispatch = useDispatch();

	useEffect(() => {
		if (mfaSettings && mfaSettings.mfaIpAddress) {
			setIPAddressList(mfaSettings.mfaIpAddress);
		}
	}, [mfaSettings]);

	const onAddFromIPRowChange = (event: any) => {
		if (editRow) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: IPAddressConstant.CloseEditingWarning,
			});
		} else {
			let temp = { ...ipAddress };
			temp.fromIPAddress = event.target.value;
			setIPAddress(temp);
			setAddRow(true);
		}
	};

	const onAddToIPRowChange = (event: any) => {
		if (editRow) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: IPAddressConstant.CloseEditingWarning,
			});
		} else {
			let temp = { ...ipAddress };
			temp.toIPAddress = event.target.value;
			setIPAddress(temp);
			setAddRow(true);
		}
	};

	const addIPAddress = () => {
		if (ValidateIPAddressModel(ipAddress)) {
			saveMFAIPAddress(ipAddress, requestIPAddress);
			cancelAdd();
		}
	};

	const cancelAdd = () => {
		setIPAddress(initialMFAIPAddress);
		setAddRow(false);
	};

	const cancelEdit = () => {
		let temp = structuredClone(ipAddressEdit);
		let tempList = structuredClone(ipAddressList);
		let index = tempList.findIndex((x: IMFAIPAddressModel) => x.id === temp.id);
		if (index > -1) {
			tempList[index] = temp;
		}
		setIPAddressList(tempList);
		setEditRow(false);
		setEditRowId(0);
		setIPAddressEdit(initialMFAIPAddress);
	};

	const ValidateIPAddressModel = (imfaIPAddressModel: IMFAIPAddressModel): boolean => {
		if (
			imfaIPAddressModel.fromIPAddress === undefined ||
			imfaIPAddressModel.fromIPAddress === null ||
			imfaIPAddressModel.fromIPAddress === ''
		) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: IPAddressConstant.FromIPWarning,
			});
			return false;
		}

		if (
			imfaIPAddressModel.toIPAddress === undefined ||
			imfaIPAddressModel.toIPAddress === null ||
			imfaIPAddressModel.toIPAddress === ''
		) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: IPAddressConstant.ToIPWarning,
			});
			return false;
		}

		if (!ValidateIPAdress(imfaIPAddressModel.fromIPAddress)) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: IPAddressConstant.ValidFromIPWarning,
			});
			return false;
		}

		if (!ValidateIPAdress(imfaIPAddressModel.toIPAddress)) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: IPAddressConstant.ValidToIPWarning,
			});
			return false;
		}
		if (
			convertIPAddresstoNumber(imfaIPAddressModel.fromIPAddress) >
			convertIPAddresstoNumber(imfaIPAddressModel.toIPAddress)
		) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: IPAddressConstant.InvalidIPRangeWarning,
			});
			return false;
		}
		if (ipAddressList.some(x => x.fromIPAddress == imfaIPAddressModel.fromIPAddress &&
			x.toIPAddress == imfaIPAddressModel.toIPAddress &&
			x.id != imfaIPAddressModel.id)) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: IPAddressConstant.IPAddressExist,
			});
			return false;
		}
		return true;
	};

	const editIPAddress = (e: any, row: IMFAIPAddressModel) => {
		if (ValidateIPAddressModel(row)) {
			updateMFAIPAddress(row, requestIPAddress);
			setEditRow(false);
			setEditRowId(0);
			setIPAddressEdit(initialMFAIPAddress);
		}
	};

	const onEditFromIPRowChange = (event: any, value: IMFAIPAddressModel) => {
		let temp = structuredClone(ipAddressList);
		let index = temp.findIndex((x: IMFAIPAddressModel) => x.id === value.id);
		if (index > -1) {
			let address = temp[index];
			address.fromIPAddress = event.target.value;
		}
		setIPAddressList(temp);
	};

	const onEditToIPRowChange = (event: any, value: IMFAIPAddressModel) => {
		let temp = structuredClone(ipAddressList);
		let index = temp.findIndex((x: IMFAIPAddressModel) => x.id === value.id);
		if (index > -1) {
			let address = temp[index];
			address.toIPAddress = event.target.value;
		}
		setIPAddressList(temp);
	};

	const requestIPAddress = () => {
		requestMFAIPAddress(true);
	};

	const deleteIPAddress = (event: any, row: any) => {
		setShowDeleteIPModal(true);
		setDeleteIPId(row.id);
	};

	const onConfirmDeleteIPAddress = () => {
		setShowDeleteIPModal(false);
		deleteMFAIPAddress(deleteIPId, requestIPAddress);
	};

	const rowOnClick = (row: IMFAIPAddressModel) => {
		let temp = { ...row };
		if (editRow || addRow) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: IPAddressConstant.CloseEditingWarning,
			});
		} else {
			setEditRowId(row.id);
			setEditRow(true);
			setIPAddressEdit(temp);
		}
	};

	return (
		<>
			<Table
				striped
				bordered
				hover>
				<tbody>
					<tr>
						<td className={styles.fromIPCol}>
							<FormControl
								type='text'
								value={ipAddress.fromIPAddress}
								placeholder='From IP Address'
								onChange={onAddFromIPRowChange}
							/>
						</td>
						<td className={styles.toIPCol}>
							<FormControl
								type='text'
								value={ipAddress.toIPAddress}
								placeholder='To IP Address'
								onChange={onAddToIPRowChange}
							/>
						</td>
						<td className={styles.actionsCol}>
							{addRow ? (
								<div className={styles.dispFlex}>
									<CancelButtonFormatter
										disabled={false}
										onCancelClick={cancelAdd}
									/>
									<EditButtonFormatter
										disabled={false}
										onEditClick={addIPAddress}
									/>
								</div>
							) : (
								<div className={styles.addIPCol}>
									<AddButtonFormatter
										disabled={false}
										onAddClick={addIPAddress}
									/>
								</div>
							)}
						</td>
					</tr>
					{ipAddressList && ipAddressList.length > 0 ? (
						ipAddressList.map((value, index) => {
							{
								return editRowId === value.id ? (
									//Edit row
									<tr>
										<td className={styles.editIPCell}>
											<FormControl
												type='text'
												value={value.fromIPAddress}
												onChange={(event: React.ChangeEvent) => onEditFromIPRowChange(event, value)}
											/>
										</td>
										<td className={styles.editIPCell}>
											<FormControl
												type='text'
												value={value.toIPAddress}
												onChange={(event: React.ChangeEvent) => onEditToIPRowChange(event, value)}
											/>
										</td>
										<td className={styles.dispFlex}>
											<CancelButtonFormatter
												disabled={false}
												onCancelClick={cancelEdit}
											/>
											&nbsp;
											<EditButtonFormatter
												disabled={false}
												onEditClick={(event: any) => editIPAddress(event, value)}
											/>
										</td>
									</tr>
								) : (
									//Non Editable row
									<tr key={value.id}>
										<td
											onClick={() => rowOnClick(value)}
											className={styles.cellValueIP}>
											{value.fromIPAddress}
										</td>
										<td
											onClick={() => rowOnClick(value)}
											className={styles.cellValueIP}>
											{value.toIPAddress}
										</td>
										<td>
											<div className={styles.deleteIPCell}>
												<DeleteButtonFormatter
													disabled={false}
													onDeleteClick={(event: any) => deleteIPAddress(event, value)}
												/>
											</div>
										</td>
									</tr>
								);
							}
						})
					) : (
						<tr>
							<td
								colSpan={4}
								className={styles.noData}>
								There is no data to display
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			{showDeleteIPModal && (
				<ConfirmationPopup
					show={showDeleteIPModal}
					actionButtonType={'danger'}
					cancelText={IPDeleteConfirmationPopupConstants.cancelText}
					header={IPDeleteConfirmationPopupConstants.headerText}
					message={IPDeleteConfirmationPopupConstants.bodyText}
					model={deleteIPId}
					okText={IPDeleteConfirmationPopupConstants.okText}
					onCancel={() => {
						setShowDeleteIPModal(false);
					}}
					onOk={onConfirmDeleteIPAddress}
					onHide={() => {
						setShowDeleteIPModal(false);
					}}
					actionDisabled={false}
					disabledText={''}
				/>
			)}
		</>
	);
};

export default MFAIP;
