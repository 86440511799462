import { type FC } from 'react';
import { ipFilteringResources } from 'src/helper/rbac-constants';

import styles from './index.module.scss';
import { useIpFiltering } from './ip-filtering-options.hooks';
import { type IIpFilterOptionsProps } from './ip-filtering-options.types';

const IpFilterOption: FC<IIpFilterOptionsProps> = (props) => {
	const { handleChangeOptionState, isChecked, clientIp } = useIpFiltering(props);

	return (
		<article className='suitePageSection'>
			<h3 className='suitePageSectionHeader'>IP Filtering Options</h3>
			<p className='suitePageSectionDescription'>
				Allow user login from trusted IPs only
			</p>
			<h4 className='suitePageSectionSubHeader'>Client IP Address</h4>
			<div className={styles.ipFilterSection}>
				<div className='switchSettingsBlock form-switch'>
					<input
						className='switchSettingsButton form-check-input'
						type='checkbox'
						id='ipAddressOptionCheck'
						data-test-auto='4853c2fc-e885-474b-8422-111296906260'
						data-resource-id={`${ipFilteringResources.prefix}_${ipFilteringResources.ipFilterControl}_${ipFilteringResources.saveButton}`}
						checked={isChecked}
						onChange={() => {
							handleChangeOptionState();
						}}
					/>
					<span className='switchSettingsText'>Enable IP Filtering</span>
				</div>
				<div className={styles.clientIpText}>Client IP Address: {clientIp}</div>
			</div>
		</article>
	);
};

export default IpFilterOption;
