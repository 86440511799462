import { ICustomDropdownOption } from '@components/common/customDropdown/CustomDropdownWithCustomOptions';
import { IOfficeLocation } from '../Client.model';
import { FC, useEffect, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import CloseIcon from 'src/components/svg/CloseIcon';
import CustomDropdown from 'src/components/common/customDropdown';
import { ClientBulkEditConfirmationPopupConstants } from 'src/helper/Constants';
import './bulkOfficeLocationUpdation-popup.scss';
import { IOfficeLocationDropdownModel } from '@modules/company-management/partials/save-office-location-modal/save-office-location-modal.types';

interface bulkOfficeLocationconfirmationPopupProps {
	show: boolean;
	header: string;
	model?: any;
	okText: string;
	cancelText: string;
	onOk: (value: any) => void;
	onCancel: () => void;
	actionDisabled?: boolean;
	disabledText?: string;
	actionButtonType?: string;
	cancelButtonType?: string;
	onHide?: () => void;
	hideOkBtn?: boolean;
	className?: string;
	officeLocations?: IOfficeLocation[];
	isOfficeLocationOptionNotSelected?: boolean;
	setIsOfficeLocationOptionNotSelected: (isOfficeLocationOptionNotSelected: boolean) => void;
}

const BulkOfficeLocationUpdationModal: FC<bulkOfficeLocationconfirmationPopupProps> = (props) => {
	const [officeLocationOptionSelected, setOfficeLocationOptionSelected] = useState<ICustomDropdownOption | null>(null);
	const [officeLocationOptions, setOfficeLocationOptions] = useState<ICustomDropdownOption[]>([]);

	const {
		header,
		show,
		cancelText,
		okText,
		actionDisabled,
		disabledText,
		actionButtonType,
		cancelButtonType,
		hideOkBtn,
		className,
		officeLocations,
	} = props;

	useEffect(() => {
		generateOfficeLocationOptions(officeLocations);
	}, []);

	const handleNewOfficeLocationClick = (option: ICustomDropdownOption): void => {
		setOfficeLocationOptionSelected(option);
		props.setIsOfficeLocationOptionNotSelected(false);
	};

	const handleOnBlurOfficeLocationSelect = (): void => {
		if (!officeLocationOptionSelected) props.setIsOfficeLocationOptionNotSelected(true);
	};

	const generateOfficeLocationOptions = (officelocationClicked: IOfficeLocation[]): ICustomDropdownOption[] => {
		const officeLocationListGenerated = officelocationClicked.map((eachOfficeLoation: IOfficeLocationDropdownModel) => {
			const eachOfficeLocationOption = {
				value: -1,
				label: '',
				id: -1,
			};
			eachOfficeLocationOption.value = eachOfficeLoation.locationId;
			eachOfficeLocationOption.id = eachOfficeLoation.locationNumber;
			eachOfficeLocationOption.label = (				
					<div className='officeLocationContainer'>
						<div
							className='officeLocationName'
							title={eachOfficeLoation.name}>
							{eachOfficeLoation.name}
						</div>
						<div
							className='officeLocationId'
							title={`Location #${eachOfficeLoation.locationNumber}`}>
							- Location #{eachOfficeLoation.locationNumber}
						</div>
					</div>				
			);
			return eachOfficeLocationOption;
		});
		setOfficeLocationOptions(officeLocationListGenerated);
		return officeLocationListGenerated;
	};
	return (
		<Modal
			className={`bulkOfficeLocationUpdationpopup ${className ?? ''}`}
			show={show}
			onHide={props.onHide}>
			<Modal.Header>
				<div>
					<Modal.Title>{header}</Modal.Title>
				</div>
				<div
					data-test-auto='8cc2eef8-27ec-494a-9518-5d52ce8a0891'
					className='closeIcon'
					onClick={props.onHide}>
					<CloseIcon />
				</div>
			</Modal.Header>
			<Modal.Body className='show-grid'>
				<Container className='popup_grid_container'>
					<div className='modaltext'>{ClientBulkEditConfirmationPopupConstants.bodyText}</div>
					<div className='selectOfficeLocationWrapper'>
						<div className='selectOfficeLocationTitle'>Change Office Location</div>
						<div className='selectOfficeLocationData'>
							<CustomDropdown
								id='officeLocationDropdown'
								selectedOption={officeLocationOptionSelected ?? null}
								setSelectedOption={handleNewOfficeLocationClick}
								handleOnBlur={handleOnBlurOfficeLocationSelect}
								options={officeLocationOptions}
								customPlaceHolder='Select Office Location'
								clearable={false}
								searchable={false}
								height={38}
								className={`${
									props.isOfficeLocationOptionNotSelected
										? 'officeLocationOptionsError errorBorder noBorder'
										: 'officeLocationOptions'
								}`}
								classNamePrefix='officeLocationOptions'
								scrollableMenu={true}
								maxMenuHeight={89}
							/>
						</div>

						{props.isOfficeLocationOptionNotSelected && (
							<div className='selectOfficeLocationError '>This field is required</div>
						)}
					</div>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button
					data-test-auto='0d0aa5c7-7109-4037-95e9-ab0a8ae97498'
					variant={cancelButtonType || 'secondary'}
					onClick={props.onCancel}>
					{cancelText}
				</Button>
				{!hideOkBtn && (
					<Button
						className='okbutton'
						data-test-auto='cc6f3863-a3f9-4dd4-bbe8-bee6994459a2'
						variant={actionDisabled ? 'disbaled' : actionButtonType}
						onClick={() => {
							actionDisabled ? null : props.onOk(officeLocationOptionSelected?.value);
						}}
						title={actionDisabled ? disabledText : ''}>
						{okText}
					</Button>
				)}
			</Modal.Footer>
		</Modal>
	);
};

export default BulkOfficeLocationUpdationModal;
