import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import SearchIcon from 'src/components/svg/SearchIcon';
import styles from './ClientHeader.module.scss';
import './CustomBootstrap.style.scss';
import ExportIcon from '../../../components/svg/Export';
import ImportIcon from '../../../components/svg/Import';
import AddUserIcon from '../../../components/svg/AddUserIcon';
import ColumnOptionsIcon from '../../../components/svg/ColumnOptions';
import { clientMngmntResources } from '../../../helper/rbac-constants';
import { IClientModel, IOfficeLocation, PageProps, templateOptions } from '../Client.model';
import { ISortColumn } from '..';
import CustomDropdown from '../../../components/common/customDropdown';
import { downloadImportTemplate, getImportLink, sendImportData } from '../Client.api';
import { uploadedFile } from '../../../components/common/uploadDocument/uploadFunctions';
import { isValidAndReadableCsv } from '../Client.helper';
import { AppNotifier as VenusNotifier } from '../../../helper/AppNotifier';
import { ClientManagementConstants } from '../../../helper/Constants';
import Filter from '../../common/filter';
import { DeleteIcon, UpdateOfficeLocationIcon } from '../../../components/svg/IconCollection';
import { ICustomDropdownOption } from 'src/components/common/customDropdown/CustomDropdownWithCustomOptions';

interface ClientHeaderProps {
	inputText: string;
	page: PageProps;
	sortColumn: ISortColumn;
	clientCount: number;
	newClientBasicInfoIds: number[];
	selectedClients: IClientModel[];
	setInputText: (inputText: string) => void;
	filterSearchText: (text: string) => void;
	showClientModal: boolean;
	setShowClientModal: (showClientModal: boolean) => void;
	setShowBulkDeleteModal: (showBulkDeleteModal: boolean) => void;
	setShowBulkEditModal: (showBulkEditModal: boolean) => void;
	setShowColumnOptions: (showColumnOptions: boolean) => void;
	exportClientDetailsAsExcel: (queryString: string, callback?: () => void, resourceId?: string) => void;
	selectedOfficeLocations: ICustomDropdownOption[];
	setSelectedOfficeLocations: (selectedOfficeLocations: ICustomDropdownOption[]) => void;
	requestAllUsers: (selectedOfficeLocations: ICustomDropdownOption[]) => void;
	officeLocationList: IOfficeLocation[];
}

const ClientHeader: React.FC<ClientHeaderProps> = (props) => {
	const {
		setShowClientModal,
		setShowBulkDeleteModal,
		setShowBulkEditModal,
		selectedClients,
		setShowColumnOptions,
		inputText,
		setInputText,
		filterSearchText,
		officeLocationList,
		selectedOfficeLocations,
		setSelectedOfficeLocations,
		requestAllUsers,
	} = props;

	const [exportInProgress, setExportInProgress] = useState(false);
	const [importInProgress, setImportInProgress] = useState(false);
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleExportClientDetailsClick = () => {
		setExportInProgress(true);
	};

	const onExportClientDetails = (onExportClientDetailsComplete: () => void) => {
		const queryString = buildQueryStringForExportClientDetails();
		props.exportClientDetailsAsExcel(queryString, onExportClientDetailsComplete, clientMngmntResources.exportList);
	};

	const onExportClientDetailsComplete = () => {
		setExportInProgress(false);
	};

	useEffect(() => {
		if (exportInProgress) {
			onExportClientDetails(onExportClientDetailsComplete);
		}
	}, [exportInProgress]);

	const buildQueryStringForExportClientDetails = () => {
		let filterLocationList = selectedOfficeLocations.map((x) => x.value).join();
		return (
			'?filterText=' +
			inputText +
			'&sortBy=' +
			props.sortColumn.sortBy +
			'&sortOrder=' +
			props.sortColumn.sortOrder +
			'&newClientBasicInfoIds=' +
			props.newClientBasicInfoIds +
			`&filterLocation= ${filterLocationList.split(',').includes('0') ? '' : filterLocationList}`
		);
	};

	const handleDownloadTemplate = (option: ICustomDropdownOption | null) => {
		if (option) {
			downloadImportTemplate(option.value);
		}
	};

	const uploadCompleted = (fileToUpload: any) => {
		sendImportData(fileToUpload.fileGUID, fileToUpload.fileName);
		setImportInProgress(false);
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
	};

	const handleImportFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files && files.length > 0) {
			const file = files[0];
			isValidAndReadableCsv(file, () =>
				getImportLink((source: { sas: string; fileName: string }) => {
					uploadedFile(file, source, file.name, undefined, uploadCompleted);
					VenusNotifier.Success(ClientManagementConstants.importInProgress);
					setImportInProgress(true);
				}),
			);
		}
	};

	const handleKeyDown = (e: any) => {
		if (e.key === 'Enter' && inputText.trim() === '') {
			filterSearchText(inputText);
		}
	};

	const handleSubmitForm = (e: any) => {
		e.preventDefault();
	};

	return (
		<div className={styles.clientGridHeader}>
			<form
				onSubmit={handleSubmitForm}
				className={styles.searchClient}>
				<input
					type='text'
					id='searchText'
					placeholder='Search'
					value={inputText}
					maxLength={500}
					onChange={(e: any) => setInputText(e.target.value)}
					onKeyDown={handleKeyDown}
				/>
				{inputText && (
					<i
						className={`${styles.crossIcon} fa fa-close`}
						onClick={() => {
							setInputText('');
							filterSearchText('');
						}}></i>
				)}
				<button
					style={{ cursor: `${inputText.trim().length ? 'pointer' : 'not-allowed'}` }}
					onClick={() => filterSearchText(inputText)}
					className={styles.searchIcon}
					disabled={inputText.trim().length ? false : true}>
					<SearchIcon />
				</button>
			</form>
			<div className={styles.addexport}>
				<button
					data-test-auto='d83d508a-27b0-4096-a9aa-122c4361239d'
					className={styles.bulkEditClients}
					onClick={() => setShowBulkEditModal(true)}
					disabled={selectedClients.length === 0}
					title='Edit Office Location'>
					<UpdateOfficeLocationIcon />
				</button>
				<button
					data-test-auto='d83d508a-27b0-4096-a9aa-122c4361239d'
					className={styles.bulkDeleteClients}
					onClick={() => setShowBulkDeleteModal(true)}
					disabled={selectedClients.length === 0}
					data-resource-id={clientMngmntResources.gridPrefix + '_' + clientMngmntResources.deleteButton}
					title='Bulk delete clients'>
					<DeleteIcon />
				</button>
				{officeLocationList.length > 1 ? (
					<Filter
						id='filter'
						className='filterOfficeLocations'
						selectedOptions={selectedOfficeLocations}
						setSelectedOptions={setSelectedOfficeLocations}
						options={officeLocationList.map((x) => ({ label: x.name, value: x.locationId, id: x.locationNumber }))}
						borderColor={'#6B7075'}
						requestAllUsers={requestAllUsers}
					/>
				) : (
					<></>
				)}
				<CustomDropdown
					id='templateDropdown'
					selectedOption={null}
					setSelectedOption={handleDownloadTemplate}
					options={templateOptions}
					className={styles.dropdown}
					customPlaceHolder='Download Template'
					clearable={false}
					searchable={false}
					height={38}
					data-resource-id={clientMngmntResources.downloadTemplate}
					title='Download template for Import CSV'
				/>
				<button
					data-test-auto='7e3d06e6-0114-4a48-a497-0acff29813a7'
					className={styles.exportUserDetails}
					title='Column Options'
					data-resource-id={clientMngmntResources.columnOptions}
					onClick={() => setShowColumnOptions(true)}>
					<ColumnOptionsIcon />
				</button>
				<label
					data-test-auto='22555958-dcd1-44dd-a813-95b43e31b182'
					className={styles.exportUserDetails}
					title='Import CSV'
					htmlFor='fileInput'
					style={{ cursor: 'pointer' }}
					data-resource-id={clientMngmntResources.importClientData}>
					<ImportIcon color='#212529' />
					<input
						type='file'
						id='fileInput'
						accept='.csv'
						onChange={handleImportFileUpload}
						disabled={importInProgress}
						style={{ display: 'none' }}
						ref={fileInputRef}
					/>
				</label>
				<button
					data-test-auto='d33b0d41-5d27-4a34-8110-867b38ce6c73'
					className={styles.exportUserDetails}
					onClick={handleExportClientDetailsClick}
					disabled={exportInProgress || props.clientCount == 0}
					data-resource-id={clientMngmntResources.exportList}
					title='Export to excel'>
					<ExportIcon color='#212529' />
				</button>
				<button
					data-test-auto='bf9fa24b-b2ff-4559-94a6-2d0474ae6825'
					className={styles.addUser}
					data-bs-toggle='modal'
					data-bs-target='#staticBackdrop'
					data-resource-id={clientMngmntResources.addClient}
					onClick={() => setShowClientModal(true)}>
					<AddUserIcon />
					Add&nbsp;Client
				</button>
			</div>
		</div>
	);
};

export default ClientHeader;
