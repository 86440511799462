import React, { useState } from 'react';
import './UserPermissionTable.style.scss';
import CheckMark from '../../../components/svg/CheckMark';
import { IPageProps, ISSOAccessRequest, ISortColumn, columnNames } from '../UserPermission.model';
import CloseIcon from '../../../components/svg/CloseIcon';
import { denySSOAccessRequest, grantSSOAccessRequest } from '../UserPermission.api';
import ConfirmationPopup from '../../../components/common/confirmationPopup';
import { userPermissionResources } from '../../../helper/rbac-constants';
import { logger } from 'src/oidcClient/authProvider';

interface IUserPermissionTableProps {
	users: ISSOAccessRequest[];
	isLoading: boolean;
	sortColumn: ISortColumn;
	setSortColumn: (sortColumn: ISortColumn) => void;
	getAllSSOAccessRequestedUsers(
		reload?: boolean,
		pageNo?: number,
		pageSize?: number,
		sortBy?: string,
		sortOrder?: string,
		filterText?: string,
	): void;
	page: IPageProps;
	searchText: string;
}

const UserPermissionTable: React.FC<IUserPermissionTableProps> = (props) => {
	const { users, isLoading, sortColumn, setSortColumn, getAllSSOAccessRequestedUsers, page, searchText } = props;

	const [stateBusy, setStateBusy] = useState<boolean>(false);
	const [showDenyPopup, setShowDenyPopup] = useState<boolean>(false);
	const [selectedEmail, setSelectedEmail] = useState<string>('');

	const handleSort = (column: string) => {
		const newSortColumn = { ...sortColumn };

		switch (column) {
			case columnNames.name:
				if (newSortColumn.sortBy === columnNames.name.toLowerCase()) {
					newSortColumn.sortOrder = newSortColumn.sortOrder === 'desc' ? 'asc' : 'desc';
				} else {
					newSortColumn.sortBy = columnNames.name.toLowerCase();
					newSortColumn.sortOrder = 'desc';
				}
				setSortColumn(newSortColumn);
				break;

			case columnNames.email:
				if (newSortColumn.sortBy === 'emailAddress') {
					newSortColumn.sortOrder = newSortColumn.sortOrder === 'desc' ? 'asc' : 'desc';
				} else {
					newSortColumn.sortBy = 'emailAddress';
					newSortColumn.sortOrder = 'desc';
				}
				setSortColumn(newSortColumn);
				break;

			default:
				break;
		}
	};

	const sortClass = (sortBy: string, column: string) => {
		if (sortBy === columnNames.name.toLowerCase() && column === columnNames.name) {
			return sortColumn.sortOrder === 'asc' ? 'fa-sort-asc' : 'fa-sort-desc';
		} else if (sortBy === 'emailAddress' && column === columnNames.email) {
			return sortColumn.sortOrder === 'asc' ? 'fa-sort-asc' : 'fa-sort-desc';
		} else {
			return 'fa-sort';
		}
	};

	const handleGrantAccess = (email: string) => {
		logger && logger.trackTrace('handleGrantAccess: Function called in User Permission');
		setStateBusy(true);
		grantSSOAccessRequest(email, () => {
			getAllSSOAccessRequestedUsers(
				true,
				page.pageIndex,
				page.pageSize,
				sortColumn.sortBy,
				sortColumn.sortOrder,
				searchText,
			);
			setStateBusy(false);
		});
	};

	const handleDenyAccess = (email: string) => {
		setStateBusy(true);
		denySSOAccessRequest(email, () => {
			getAllSSOAccessRequestedUsers(
				true,
				page.pageIndex,
				page.pageSize,
				sortColumn.sortBy,
				sortColumn.sortOrder,
				searchText,
			);
			setStateBusy(false);
		});
	};

	return (
		<>
			<table className='table table-striped table-hover userPermissionTable'>
				<thead className='table-header'>
					<tr>
						{Object.values(columnNames).map((column: string, index: number) => (
							<th
								className='widthAuto clickableColumns'
								scope='col'
								key={index}
								onClick={() => handleSort(column)}>
								{column} <i className={`fa fa-fw ${sortClass(sortColumn.sortBy, column)} sortIcon`}></i>
							</th>
						))}
						<th
							className='actions'
							scope='col'>
							Actions
						</th>
					</tr>
				</thead>
				<tbody>
					{isLoading ? (
						<tr>
							<td
								data-toggle='collapse'
								colSpan={Object.values(columnNames).length + 1}
								className='usersLoadingArea'>
								<div className='text-center'>
									<div
										className='spinner-border'
										role='status'></div>
									<div>
										<span className='usersLoadingText'>Loading...</span>
									</div>
								</div>
							</td>
						</tr>
					) : users.length === 0 ? (
						<tr>
							<td
								data-toggle='collapse'
								colSpan={Object.values(columnNames).length + 1}
								className='usersLoadingArea text-center'>
								No matching records found
							</td>
						</tr>
					) : (
						users.map((user: ISSOAccessRequest, index: number) => {
							return (
								<tr
									key={index}
									className='userRow'>
									<td className='widthAuto fullName'>{`${user.firstName} ${user.lastName}`}</td>
									<td className='widthAuto'>{user.emailAddress}</td>
									<td className='actionsColData'>
										<div className='alignActionButtons'>
											<div>
												<button
													data-resource-id={userPermissionResources.grant}
													data-test-auto='b4adcd19-2b62-462d-91ab-c7c746f3c430'
													className='grantAccess'
													disabled={stateBusy}
													onClick={() => handleGrantAccess(user.emailAddress)}>
													<CheckMark /> Grant Access
												</button>
											</div>
											<div>
												<button
													data-resource-id={userPermissionResources.deny}
													data-test-auto='0264ee0c-f0f4-4c32-aab4-b40069cb1a6a'
													className='denyAccess'
													disabled={stateBusy}
													onClick={() => {
														setShowDenyPopup(true);
														setSelectedEmail(user.emailAddress);
													}}>
													<CloseIcon /> Deny
												</button>
											</div>
										</div>
									</td>
								</tr>
							);
						})
					)}
				</tbody>
			</table>
			{showDenyPopup && (
				<ConfirmationPopup
					show={showDenyPopup}
					actionButtonType={'danger'}
					cancelText='Cancel'
					header='Deny Access?'
					message='Are you sure you want to deny this user?'
					okText='OK'
					onCancel={() => {
						setShowDenyPopup(false);
					}}
					onOk={() => {
						handleDenyAccess(selectedEmail);
						setShowDenyPopup(false);
					}}
					onHide={() => {
						setShowDenyPopup(false);
					}}
					actionDisabled={false}
					className='userPermissionTablePopup'
				/>
			)}
		</>
	);
};

export default UserPermissionTable;
