import { Reducer } from 'redux';
import axios, { AxiosResponse } from 'axios';

import { AppThunkAction } from 'src/store/index';
import { ICompanyMfaSettings, KnownAction, initialcMfaSettings } from './MFASettings.model';
import { actionTypes } from './MFASettings.actions';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';
import { API_BASE_URL, MFASettingsConstants } from 'src/helper/Constants';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {

    requestMFASettings: (callback: (cMfaSettings: ICompanyMfaSettings) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.get(API_BASE_URL + '/api/mfa/mfa-setting')
            .then((response: AxiosResponse<ICompanyMfaSettings>) => {
                const { data } = response;
                dispatch({
                    type: loaderTypes.LOADING,
                    show: false,
                    text: '',
                });
                dispatch({ type: actionTypes.RECEIVE_MFA_SETTINGS, cMfaSettings: data });
                callback(data);
            })
            .catch(function (error) {
				logger && logger.trackWarning('Error while requestMFASettings', { 'Error': error?.response?.data});
                dispatch({
                    type: loaderTypes.LOADING,
                    show: false,
                    text: '',
                });
                const statusMessage: any = error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
    },

    updateMFASettings: (cMfaSettings: ICompanyMfaSettings, callback: () => void, resourceId = ''):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            axios.put(API_BASE_URL + '/api/mfa/mfa-setting-update', cMfaSettings, {
                headers: {
                    'X-Resource-Id': resourceId,
                }
            })
                .then((response: AxiosResponse) => {
                    dispatch({ type: actionTypes.UPDATE_MFA_SETTINGS, cMfaSettings: cMfaSettings });
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: MFASettingsConstants.StatusMessage.UpdateMFASettingsSuccess,
                        statusType: StatusType.Success
                    });
                    callback();
                })
                .catch((error) => {
                    logger && logger.trackWarning('Error while updateMFASettings', { 'Error': error?.response?.data});
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: MFASettingsConstants.StatusMessage.UpdateMFASettingsError,
                        statusType: StatusType.Error
                    });
                    if (callback) {
                        callback();
                    }
                });
        },

    switchMFASettingsIU: (isEnabled: boolean, callback: () => void, resourceId = ''):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            axios.put(API_BASE_URL + `/api/mfa/mfa-setting-update-internal-users?isEnabled=${isEnabled}`, {
                headers: {
                    'X-Resource-Id': resourceId,
                }
            })
                .then((response: AxiosResponse) => {
                    callback();
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: MFASettingsConstants.StatusMessage.UpdateMFASettingsSuccess,
                        statusType: StatusType.Success
                    });
                })
                .catch((error) => {
                    logger && logger.trackWarning('Error while switchMFASettingsIU', { 'Error': error?.response?.data});
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: MFASettingsConstants.StatusMessage.UpdateMFASettingsError,
                        statusType: StatusType.Error
                    });
                    callback();
                });
        },

    switchMFASettingsTP: (isEnabled: boolean, callback: () => void, resourceId = ''):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            axios.put(API_BASE_URL + `/api/mfa/mfa-setting-update-tp?isEnabled=${isEnabled}`, {
                headers: {
                    'X-Resource-Id': resourceId,
                }
            })
                .then((response: AxiosResponse) => {
                    callback();
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: MFASettingsConstants.StatusMessage.UpdateMFASettingsSuccess,
                        statusType: StatusType.Success
                    });
                })
                .catch((error) => {
                    logger && logger.trackWarning('Error while switchMFASettingsTP', { 'Error': error?.response?.data});
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: MFASettingsConstants.StatusMessage.UpdateMFASettingsError,
                        statusType: StatusType.Error
                    });
                    callback();
                });
        }
};

const unloadedState: ICompanyMfaSettings = initialcMfaSettings;

export const reducer: Reducer<ICompanyMfaSettings> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.RECEIVE_MFA_SETTINGS:
            const receivedCompanyMFASetting = action.cMfaSettings;
            return receivedCompanyMFASetting;
        case actionTypes.UPDATE_MFA_SETTINGS:
            const newCompanyMFASetting = action.cMfaSettings;
            return newCompanyMFASetting;
    }
    return state;
};