import React, { FC } from 'react';

const AddIconCircular: FC = () => {
	return (
		<svg
			width='16'
			height='17'
			viewBox='0 0 16 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_3813_151385)'>
				<rect
					width='16'
					height='16'
					transform='translate(0 0.5)'
					fill='white'
					fillOpacity='0.01'
				/>
				<g clipPath='url(#clip1_3813_151385)'>
					<path
						d='M8 15.499C6.14348 15.499 4.36301 14.7615 3.05025 13.4488C1.7375 12.136 1 10.3555 1 8.49902C1 6.64251 1.7375 4.86203 3.05025 3.54928C4.36301 2.23652 6.14348 1.49902 8 1.49902C9.85652 1.49902 11.637 2.23652 12.9497 3.54928C14.2625 4.86203 15 6.64251 15 8.49902C15 10.3555 14.2625 12.136 12.9497 13.4488C11.637 14.7615 9.85652 15.499 8 15.499ZM8 16.499C10.1217 16.499 12.1566 15.6562 13.6569 14.1559C15.1571 12.6556 16 10.6208 16 8.49902C16 6.37729 15.1571 4.34246 13.6569 2.84217C12.1566 1.34188 10.1217 0.499023 8 0.499023C5.87827 0.499023 3.84344 1.34188 2.34315 2.84217C0.842855 4.34246 0 6.37729 0 8.49902C0 10.6208 0.842855 12.6556 2.34315 14.1559C3.84344 15.6562 5.87827 16.499 8 16.499Z'
						fill='#05386B'
					/>
					<path
						d='M8 4.49902C8.13261 4.49902 8.25979 4.5517 8.35355 4.64547C8.44732 4.73924 8.5 4.86642 8.5 4.99902V7.99902H11.5C11.6326 7.99902 11.7598 8.0517 11.8536 8.14547C11.9473 8.23924 12 8.36642 12 8.49902C12 8.63163 11.9473 8.75881 11.8536 8.85258C11.7598 8.94635 11.6326 8.99902 11.5 8.99902H8.5V11.999C8.5 12.1316 8.44732 12.2588 8.35355 12.3526C8.25979 12.4463 8.13261 12.499 8 12.499C7.86739 12.499 7.74021 12.4463 7.64645 12.3526C7.55268 12.2588 7.5 12.1316 7.5 11.999V8.99902H4.5C4.36739 8.99902 4.24021 8.94635 4.14645 8.85258C4.05268 8.75881 4 8.63163 4 8.49902C4 8.36642 4.05268 8.23924 4.14645 8.14547C4.24021 8.0517 4.36739 7.99902 4.5 7.99902H7.5V4.99902C7.5 4.86642 7.55268 4.73924 7.64645 4.64547C7.74021 4.5517 7.86739 4.49902 8 4.49902Z'
						fill='#05386B'
					/>
				</g>
			</g>
			<defs>
				<clipPath id='clip0_3813_151385'>
					<rect
						width='16'
						height='16'
						fill='white'
						transform='translate(0 0.5)'
					/>
				</clipPath>
				<clipPath id='clip1_3813_151385'>
					<rect
						width='16'
						height='16'
						fill='white'
						transform='translate(0 0.5)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default AddIconCircular;
