import { actionTypes } from "./userProfile.actions";
import {
    NotificationAction,
} from "../notification/notification.reducer";

export interface IUserGroups {
    id: number;
    name: string;
    userId?: number;
    userGroups?: number;
}

export enum IUserGroup {
    None,
    SystemAdmin = 1,
    Staff,
    Partner,
    Signatures,
    Exchange
}
export enum AuthenticationProvider {
    None,
    AzureAD,
    SAML,
    Google,
}
export interface IUserProfile {
    firstName: string;
    lastName: string;
    phone: string;
    extension: string;
    fax: string;
    emailAddress: string;
    userId: number;
    userGroups: IUserGroups[];
    authenticationProviders: AuthenticationProvider[];
    metadata: string;
    countryCode: string;
    mobileNumber: string;
    isMobileVerify: boolean;
    isMFAEnabled: boolean;
    readonlyFields: string[];
}



export const initialUserProfile: IUserProfile = {
    firstName: "",
    lastName: "",
    phone: "",
    extension: "",
    fax: "",
    emailAddress: "",
    userId: 0,
    userGroups: [],
    authenticationProviders: [],
    metadata: "",
    countryCode: "",
    mobileNumber: "",
    isMobileVerify: false,
    isMFAEnabled: false,
    readonlyFields: []
};


// export interface IUserModel {
//     userId: number;
//     firstName: string;
//     middleName: string;
//     lastName: string;
//     phoneNumber: string;
//     extension: string;
//     ptin: string;
//     email: string;
//     confirmEmail: string;
//     faxNumber: string;
//     title: string;
//     customUserGroup: IUserGroups[];
//     userGroups: IUserGroups[];
//     createdOn?: any;
//     password?: string;
//     authenticationProviders: AuthenticationProvider[];
//     metadata: string;
//     isDeceased: boolean;
//     mobileNumber: string;
//     countryCode: string;
//     isMobileVerify: boolean;
//     lastLogin: Date;
// }

export interface IModalProps {
    showState?: boolean;
    onHide: () => void;
}

interface RequestUserProfileAction {
    type: actionTypes.REQUEST_USER_PROFILE;
}

interface ReceiveUserProfileAction {
    type: actionTypes.RECEIVE_USER_PROFILE;
    user: IUserProfile;
}

interface UpdateUserProfileAction {
    type: actionTypes.UPDATE_USER_PROFILE;
    userDetails: IUserProfile;
}

export type DispatchActions =
    | RequestUserProfileAction
    | ReceiveUserProfileAction
    | UpdateUserProfileAction;

export type KnownAction = DispatchActions | NotificationAction;