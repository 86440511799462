import * as TaxSoftwareSettingStore from 'src/modules/generalSettings/TaxSoftwares/TaxSoftwareSetting.store';
import { type IPaymentHistoryData } from 'src/modules/paymentHistory/PaymentHistory.model';
import * as PaymentHistoryStore from 'src/modules/paymentHistory/PaymentHistory.store';
import * as PurchaseReturnStore from 'src/modules/paymentHistory/PurchaseReturn.store';
import {
	type ICompanyMfaSettings,
	type IMFASettingsState,
} from 'src/modules/securitySettings/MFASettings/MFASettings.model';
import {
	type IDefinedPasswordPolicy,
	type IPasswordPolicySettings,
} from 'src/modules/securitySettings/PasswordPolicy/PasswordPolicySettings.model';
import { type ISSOSettings } from 'src/modules/securitySettings/SSOSettings/SSOSettings.model';
import { type ICompanyData } from 'src/pages/common/companySettings/companySettings.types';
import { type IOverlayLoader } from 'src/pages/common/overlayLoader/OverlayLoader.model';

import { type IClientData } from '../modules/clientManagement/Client.model';
import * as ClientStore from '../modules/clientManagement/Client.store';
import * as EventSettingStore from '../modules/developer-section/developer-section.store';
import { type ITaxSoftwareSetting } from '../modules/generalSettings/TaxSoftwares/TaxSoftwareSetting.model';
import * as IPFilteringListStore from '../modules/securitySettings/ip-filtering-list/ip-filtering-list.reducer';
import * as IPFilteringOptionStore from '../modules/securitySettings/ip-filtering-option/ip-filtering.reducer';
import * as CMFASettingsStore from '../modules/securitySettings/MFASettings/CMFASettings.store';
import * as MFASettingsStore from '../modules/securitySettings/MFASettings/MFASettings.store';
import * as DefinedPasswordPoliciesStore from '../modules/securitySettings/PasswordPolicy/DefinedPasswordPolicies.store';
import * as PasswordPolicySettingsStore from '../modules/securitySettings/PasswordPolicy/PasswordPolicySettings.store';
import * as SSOSettingsStore from '../modules/securitySettings/SSOSettings/SSOSettings.store';
import { type IUsageReport } from '../modules/usageReport/UsageReport.model';
import * as UsageReportStore from '../modules/usageReport/UsageReport.store';
import * as RolesStore from '../modules/userManagement/rolesAndPermissions/roles-and-permissions.reducer';
import * as UserGroupStore from '../modules/userManagement/userGroups/user-groups.reducer';
import { type IUserGroupData } from '../modules/userManagement/userGroups/user-groups.types';
import { type IUserData } from '../modules/userManagement/users/Users.model';
import * as UsersStore from '../modules/userManagement/users/Users.store';
import * as BrandingManagementStore from '../pages/branding-management/branding-management.reducers';
import * as UserAuthStore from '../pages/common/auth/auth.reducer';
import * as CommonStore from '../pages/common/common.reducer';
import * as CompanySettingsStore from '../pages/common/companySettings/companySettings.reducer';
import * as OneTimePasswordStore from '../pages/common/myAccount/otp.reducer';
import * as NotificationStore from '../pages/common/notification/notification.reducer';
import * as OverlayLoaderStore from '../pages/common/overlayLoader/OverlayLoader.store';
import * as PasswordStore from '../pages/common/password/password.reducer';
import { IPurchaseData } from 'src/modules/paymentHistory/PurchaseReturn.model';
import * as ResourceIdStore from '../pages/common/resource-validator/resource-validator.reducer';
import * as UserProfileStore from '../pages/common/userProfile/userProfile.reducer';
import { type IUserProfile } from '../pages/common/userProfile/userProfile.types';
import * as UserSettingsStore from '../pages/common/userSettings/userSettings.reducer';
import * as CompanyManagementStore from '../pages/company-management/company-management.reducers';
import * as WelcomeStore from '../pages/welcome/welcome.reducer';
import * as DropdownListStore from '../store/common/dropdown/dropdown-list.reducer';
import { type dropdownListData } from './common/dropdown/dropdown-list.types';
import { ISSOAccessRequestedUsers } from '../modules/userPermission/UserPermission.model';
import * as SSOAccessRequestStore from '../modules/userPermission/UserPermission.store';
import { IApiAccessSetting } from '@modules/generalSettings/ApiAccess/ApiAccessSetting.model';
import { type IClientManagementSetting } from '../modules/generalSettings/ClientManagement/ClientManagementSetting.model';
import * as ClientManagementSettingStore from 'src/modules/generalSettings/ClientManagement/ClientManagementSetting.store';
import { IInOfficeEsignReturn } from '../modules/inoffice-esigning/InOfficeESigning.model';
import * as InOfficeESigningStore from '../modules/inoffice-esigning/InOfficeESigning.store';

export interface ApplicationState {
	userAuth: UserAuthStore.UserAuthState;
	userProfile: IUserProfile;
	notification: NotificationStore.NotificationState;
	usersList: IUserData;
	clientsList: IClientData;
	inOfficeReturnList: IInOfficeEsignReturn;
	oneTimePasswordData: OneTimePasswordStore.IOneTimePasswordState;
	passwordData: PasswordStore.IPasswordData;
	commonState: CommonStore.ICommonState;
	userSettingsState: UserSettingsStore.IUserSettingsStore;
	companyData: ICompanyData;
	welcomeState: WelcomeStore.IWelcomeState;
	userGroupList: IUserGroupData;
	dropdownListData: dropdownListData;
	rolesData: any;
	userPrivilege: UserAuthStore.IUserPreviligeState;
	resourceIdList: string[];
	ssoSettings: ISSOSettings[];
	mfaSettings: IMFASettingsState;
	cMfaSettings: ICompanyMfaSettings;
	overlayLoader: IOverlayLoader;
	passwordPolicySettings: IPasswordPolicySettings;
	definedPasswordPolicies: IDefinedPasswordPolicy[];
	branding: any;
	ipFilteringOptionStore: any;
	ipFilterList: any;
	eventSettingsState: EventSettingStore.IEventSettingsStoreState;
	usageReport: IUsageReport;
	paymentHistory: IPaymentHistoryData;
	purchaseData: IPurchaseData;
	taxSoftwareSetting: ITaxSoftwareSetting;
	apiAccessSetting: IApiAccessSetting;
	ssoAccessRequestUsers: ISSOAccessRequestedUsers;
	companyManagementData: any;
	clientManagementSetting: IClientManagementSetting;
}

export const reducers = {
	userAuth: UserAuthStore.reducer,
	userProfile: UserProfileStore.reducer,
	notification: NotificationStore.reducer,
	usersList: UsersStore.reducer,
	clientsList: ClientStore.reducer,
	inOfficeReturnList: InOfficeESigningStore.reducer,
	oneTimePasswordData: OneTimePasswordStore.reducer,
	passwordData: PasswordStore.reducer,
	commonState: CommonStore.reducer,
	userSettingsState: UserSettingsStore.reducer,
	companyData: CompanySettingsStore.reducer,
	welcomeState: WelcomeStore.reducer,
	userGroupList: UserGroupStore.reducer,
	dropdownListData: DropdownListStore.reducer,
	rolesData: RolesStore.reducer,
	userPrivilege: UserAuthStore.userPrivilegeReducer,
	resourceIdList: ResourceIdStore.reducer,
	ssoSettings: SSOSettingsStore.reducer,
	mfaSettings: MFASettingsStore.reducer,
	cMfaSettings: CMFASettingsStore.reducer,
	passwordPolicySettings: PasswordPolicySettingsStore.reducer,
	definedPasswordPolicies: DefinedPasswordPoliciesStore.reducer,
	branding: BrandingManagementStore.reducer,
	ipFilteringOptionStore: IPFilteringOptionStore.reducer,
	ipFilterList: IPFilteringListStore.reducer,
	overlayLoader: OverlayLoaderStore.reducer,
	eventSettingsState: EventSettingStore.reducer,
	usageReport: UsageReportStore.reducer,
	paymentHistory: PaymentHistoryStore.reducer,
	purchaseData: PurchaseReturnStore.reducer,
	taxSoftwareSetting: TaxSoftwareSettingStore.reducer,
	ssoAccessRequestUsers: SSOAccessRequestStore.reducer,
	companyManagementData: CompanyManagementStore.reducer,
	clientManagementSetting: ClientManagementSettingStore.reducer
};

export type AppThunkAction<TAction> = (
	dispatch: (action: TAction | AppThunkAction<TAction>) => void,
	getState: () => ApplicationState,
) => void;
