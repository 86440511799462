import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Settings from 'src/components/svg/Settings';
import { ICompanyMfaSettings, IMFAIPAddressModel, IMFASettingsState } from './MFASettings.model';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';
import SuiteModal from 'src/components/common/SuiteModal';
import MFASettingsModal from './MFASettingsModal';
import DisableMFAModal from './DisableMFAModal';
import styles from './MFASettings.module.scss';
import { logger } from 'src/oidcClient/authProvider';

interface IMFASettingsProps {
	requestMFASettings(callback: (companySettings: ICompanyMfaSettings) => void): void;
	updateMFASettings(companySettings: ICompanyMfaSettings, callback: () => void, resourceId?: string): void;
	switchMFASettingsIU(isIUEnabled: boolean, callback: () => void, resourceId?: string): void;
	switchMFASettingsTP(isTPEnabled: boolean, callback: () => void, resourceId?: string): void;
	mfaSettings: IMFASettingsState;
	cMfaSettings: ICompanyMfaSettings;
	saveMFAIPAddress(imfaIPAddressModel: IMFAIPAddressModel, callback: () => void): void;
	deleteMFAIPAddress(ipAddressId: number, callback: () => void): void;
	updateMFAIPAddress(imfaIPAddressModel: IMFAIPAddressModel, callback: () => void): void;
	requestMFAIPAddress(reload: boolean): void;
	requestMFAIPUser(query: string): void;
	updateMFAEnableForAllUser(mfaEnable: boolean, callback: () => void): void;
	updateMFAEnableForUser(userId: number, mfaEnable: boolean, callback: () => void): void;
}

const MFASettings: FC<IMFASettingsProps> = (props) => {
	const {
		requestMFASettings,
		updateMFASettings,
		switchMFASettingsIU,
		switchMFASettingsTP,
		mfaSettings,
		cMfaSettings,
		saveMFAIPAddress,
		deleteMFAIPAddress,
		updateMFAIPAddress,
		requestMFAIPAddress,
		requestMFAIPUser,
		updateMFAEnableForAllUser,
		updateMFAEnableForUser,
	} = props;

	const [showMFASettingsModal, setShowMFASettingsModal] = useState(false);
	const [mfaSwitch, setMfaSwitch] = useState(false);
	const [tpmfaSwitch, setTpmfaSwitch] = useState(false);
	const [showMFAAlert, setShowMFAAlert] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		loadMFAData();
		requestMFAIPAddress(true);
	}, []);

	const loadMFAData = () => {
		requestMFASettings((cMFASettingsReceivedFromAPI) => {
			initCMFAValues(cMFASettingsReceivedFromAPI);
		});
	};

	const initCMFAValues = (cMFASettingsReceivedFromAPI: ICompanyMfaSettings) => {
		setMfaSwitch(cMFASettingsReceivedFromAPI.isMfaEnabled);
		setTpmfaSwitch(cMFASettingsReceivedFromAPI.iSTPMfaEnabled);
	};

	const handleUpdateMFASettings = (mfaSetting: ICompanyMfaSettings, resourceId?: string) => {
        logger && logger.trackTrace('handleUpdateMFASettings: Updating MFA settings in Security settings');
		updateMFASettings(
			mfaSetting,
			() => {
				dispatch({
					type: loaderTypes.LOADING,
					show: false,
					text: '',
				});
			},
			resourceId,
		);
	};

	const handleSettingsClick = () => {
		setShowMFASettingsModal(true);
	};

	const handleIUToggleSwitchChange = (event: any) => {
        logger && logger.trackTrace('handleIUToggleSwitchChange: Internal users toggle switch in MFA settings');
		const switched = event.currentTarget.checked;
		if (switched) {
			dispatch({
				type: loaderTypes.LOADING,
				show: true,
				text: 'Updating Multi-factor Authentication Settings...',
			});
			setMfaSwitch(switched);
			switchMFASettingsIU(
				true,
				() => {
					loadMFAData();
				},
				'SUITE_Secr_MltFacAuth_IntrnlUsrs_TglEnblOrDisbl',
			);
		} else {
			setShowMFAAlert(true);
		}
	};

	const handleTpToggleSwitchChange = (event: any) => {
        logger && logger.trackTrace('handleTpToggleSwitchChange: Taxpayer toggle switch in MFA settings');
		dispatch({
			type: loaderTypes.LOADING,
			show: true,
			text: 'Updating Multi-factor Authentication Settings...',
		});
		const switched = event.currentTarget.checked;
		setTpmfaSwitch(switched);
		switchMFASettingsTP(
			switched,
			() => {
				loadMFAData();
			},
			'SUITE_Secr_MltFacAuth_TxPyrUsrs_TglEnblOrDisbl',
		);
	};

	const onMFAAlertYes = () => {
		setShowMFAAlert(false);
		setMfaSwitch(false);
		dispatch({
			type: loaderTypes.LOADING,
			show: true,
			text: 'Updating Multi-factor Authentication Settings...',
		});
		switchMFASettingsIU(
			false,
			() => {
				loadMFAData();
			},
			'SUITE_Secr_MltFacAuth_IntrnlUsrs_TglEnblOrDisbl',
		);
	};

	const hideMFAAlert = () => {
		setShowMFAAlert(false);
	};

	const onHide = () => {
		setShowMFASettingsModal(false);
	};

	return (
		<article className='suitePageSection'>
			<h3 className='suitePageSectionHeader'>Multi-factor Authentication</h3>
			<h4 className='suitePageSectionSubHeader'>SMS/Text Message</h4>
			<p className='suitePageSectionDescription'>
				If you have a mobile number present for user or client, then access code will be sent via text/SMS.
				<br />
				However, if mobile number is not provided, the access code will be sent via email.
			</p>
			<div className='switchSettingsBlock form-switch'>
				<input
					className='switchSettingsButton form-check-input'
					type='checkbox'
					id='defaultSignerButton'
					data-test-auto='b2cb2a6b-df18-495b-baff-77517ac57d20'
					onChange={handleIUToggleSwitchChange}
					checked={mfaSwitch}
					data-resource-id='SUITE_Secr_MltFacAuth_IntrnlUsrs_TglEnblOrDisbl'
				/>
				<span className='switchSettingsText'>Internal Users</span>
				<span
					className={`switchSettingsIcon${cMfaSettings.isMfaEnabled ? '' : ' ' + styles.disableIUsettings}`}
					data-resource-id='SUITE_Secr_MltFacAuth_IntrnlUsrs_TglEnblOrDisbl'
					onClick={handleSettingsClick}>
					<Settings />
				</span>
			</div>
			<div className='switchSettingsBlock form-switch'>
				<input
					className='switchSettingsButton form-check-input'
					type='checkbox'
					id='defaultSignerButton'
					data-test-auto='fcc27ea6-d008-4e88-aaca-b65904abca62'
					checked={tpmfaSwitch}
					onChange={handleTpToggleSwitchChange}
					data-resource-id='SUITE_Secr_MltFacAuth_TxPyrUsrs_TglEnblOrDisbl'
				/>
				<span className='switchSettingsText'>Taxpayers</span>
			</div>

			{showMFASettingsModal && (
				<SuiteModal
					width='600'
					theme='light'
					title='Multi-factor Authentication Settings'
					hide={onHide}>
					<MFASettingsModal
						onHide={onHide}
						cMfaSettings={props.cMfaSettings}
						updateMfaSettings={handleUpdateMFASettings}
						mfaSettings={mfaSettings}
						saveMFAIPAddress={saveMFAIPAddress}
						deleteMFAIPAddress={deleteMFAIPAddress}
						requestMFAIPAddress={requestMFAIPAddress}
						updateMFAIPAddress={updateMFAIPAddress}
						requestMFAIPUser={requestMFAIPUser}
						updateMFAEnableForAllUser={updateMFAEnableForAllUser}
						updateMFAEnableForUser={updateMFAEnableForUser}
					/>
				</SuiteModal>
			)}

			{showMFAAlert && (
				<SuiteModal
					width='600'
					theme='light'
					title='Disable Multi-factor Authentication'
					hide={() => setShowMFAAlert(false)}>
					<DisableMFAModal
						onDisableAlertNo={hideMFAAlert}
						onDisableAlertYes={onMFAAlertYes}
					/>
				</SuiteModal>
			)}
		</article>
	);
};

export default MFASettings;
