import React from 'react';
import './UserPermissionHeader.style.scss';
import SearchIcon from '../../../components/svg/SearchIcon';

interface IUserPermissionHeader {
	inputText: string;
	setInputText(inputText: string): void;
	filterSearchText(text: string): void;
}

const UserPermissionHeader: React.FC<IUserPermissionHeader> = (props) => {
	const { inputText, setInputText, filterSearchText } = props;

	return (
		<div className='userPermissionHeader'>
			<div className='searchUser'>
				<input
					type='text'
					id='searchText'
					placeholder='Search by Name and Email Id'
					value={inputText}
					maxLength={500}
					onChange={(e: any) => setInputText(e.target.value)}
				/>
				{inputText && (
					<i
						className={`crossIcon fa fa-close`}
						onClick={() => {
							setInputText('');
							filterSearchText('');
						}}></i>
				)}
				<button
					style={{ cursor: `${inputText.trim().length ? 'pointer' : 'not-allowed'}` }}
					onClick={() => filterSearchText(inputText)}
					className='searchIcon'
					disabled={inputText.trim().length ? false : true}>
					<SearchIcon />
				</button>
			</div>
		</div>
	);
};

export default UserPermissionHeader;
