import React, { useState } from 'react';
import './ChangeContactPersonModal.style.scss';
import { Button, Container, Modal, Row } from 'react-bootstrap';
import CloseIcon from '../../../../components/svg/CloseIcon';
import RowContent from './rowContent';
import { IContactPersonViewModelList } from '../saveUserModal/SaveUserModal.model';
import Warning from '../../../../components/svg/Warning';
import { ISaveUserModel } from '../Users.model';
import { modificationType } from '..';
import { IErrorField, IMandatoryFieldError, getInitialData } from './ChangeContactPersonModal.model';

interface IChangeContactPersonModal {
	toggleContactPersonModal(bool: boolean): void;
	contactPersonModalData: IContactPersonViewModelList[];
	handleUpdateUserLocation(contactPersonData: IContactPersonViewModelList[]): void;
	editedUser: ISaveUserModel;
	inProgressContactPerson: boolean;
	processType: modificationType;
}

const ChangeContactPersonModal: React.FC<IChangeContactPersonModal> = (props) => {
	const {
		toggleContactPersonModal,
		contactPersonModalData,
		handleUpdateUserLocation,
		editedUser,
		inProgressContactPerson,
		processType,
	} = props;

	const [mandatoryFieldError, setMandatoryFieldError] = useState<IMandatoryFieldError>(
		getInitialData(contactPersonModalData),
	);

	const handleClose = () => {
		toggleContactPersonModal(false);
	};

	const handleSave = () => {
		const checkMandatoryFields = Object.values(mandatoryFieldError);

		let checkEmptyFields: IContactPersonViewModelList[];
		if (processType === modificationType.Edit) {
			checkEmptyFields = contactPersonModalData
				.filter((ele1) => !ele1.isGroupedReturn)
				.filter((ele2) => ele2.contactPerson.every((ele3) => !ele3.selected));
		} else {
			checkEmptyFields = contactPersonModalData.filter((ele1) => ele1.contactPerson.every((ele2) => !ele2.selected));
		}

		checkEmptyFields.forEach((ele1) =>
			handleMandatoryFieldErrorChanges(ele1.LocationId.toString(), { error: true, message: 'This is required field' }),
		);
		if (
			checkMandatoryFields.length &&
			checkMandatoryFields.every((value) => value.error === false) &&
			checkEmptyFields.length === 0
		) {
			handleUpdateUserLocation(contactPersonModalData);
		}
	};

	const handleMandatoryFieldErrorChanges = (locationId: string, errorValues: IErrorField) => {
		setMandatoryFieldError((prevState: any) => ({
			...prevState,
			[`LocationId${locationId}`]: errorValues,
		}));
	};

	const checkForScrollableContainer = () => {
		if (processType === modificationType.Edit) {
			return contactPersonModalData.filter((x) => x.LocationId !== -1).length > 3 ? 'addScroll addScroll_edit' : '';
		} else {
			return contactPersonModalData.length > 3 ? 'addScroll addScroll_delete' : '';
		}
	};

	const getMenuPlacement = (index: number) => {
		if (processType === modificationType.Edit) {
			const data = contactPersonModalData.filter((x) => x.LocationId !== -1);
			return data.length > 3 && index === data.length - 1 ? 'top' : 'bottom';
		} else {
			return contactPersonModalData.length > 3 && index === contactPersonModalData.length - 1 ? 'top' : 'bottom';
		}
	};

	return (
		<Modal
			className='changeContactPersonModal'
			style={{ fontSize: '14px' }}
			show={true}
			onHide={handleClose}>
			<Modal.Header className='header'>
				<div>
					<Modal.Title style={{ fontSize: '20px' }}>
						{processType === modificationType.Edit ? 'Update User Location' : 'Delete Users'}
					</Modal.Title>
				</div>
				<div
					data-test-auto='c8648949-3979-4fd9-8c7b-e94b6153086f'
					className='closeIcon'
					onClick={handleClose}>
					<CloseIcon />
				</div>
			</Modal.Header>
			<Modal.Body>
				{processType === modificationType.Delete ? (
					<div className='headerText'>Are you sure you want to delete this User permanently?</div>
				) : (
					<></>
				)}
				<Container>
					<Row>
						<div className='warningMessage'>
							<div className='icon'>
								<Warning />
							</div>
							{processType === modificationType.Edit ? (
								<div className='text'>
									You are updating the location for {editedUser.firstName} {editedUser.lastName} who is currently
									designated as the contact person for Returns Documents that has already been delivered.
									<br />
									Please select the new <span className='bold'>Contact Person</span> to replace the{' '}
									{editedUser.firstName} {editedUser.lastName} who has been relocated from this location.
								</div>
							) : (
								<div className='text'>
									You are trying to delete a user that has been selected as the contact person for returns that have
									already been delivered. (i.e., the person the taxpayer should contact if they have questions.)
									<br />
									Please select the <span className='bold'>contact person</span> you would like to replace the deleted
									user.
								</div>
							)}
						</div>
					</Row>
					<div className={`scrollableArea ${checkForScrollableContainer()}`}>
						{contactPersonModalData.map((data, index) => (
							<RowContent
								className='rowContent'
								key={index}
								contactPersonData={data}
								setMandatoryFieldError={handleMandatoryFieldErrorChanges}
								mandatoryFieldError={mandatoryFieldError}
								processType={processType}
								menuPlacement={getMenuPlacement(index)}
							/>
						))}
					</div>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button
					data-test-auto='47a98933-a3b8-4cd7-a7cb-7b873f0f0061'
					variant='secondary'
					onClick={handleClose}>
					Cancel
				</Button>
				<Button
					disabled={inProgressContactPerson}
					data-test-auto='fb3e3e36-9ee6-4bc1-ae0b-4d298e7a8dde'
					variant={`${processType === modificationType.Edit ? 'primary' : 'danger'}`}
					onClick={handleSave}>
					{processType === modificationType.Edit ? 'Save' : 'Yes, Delete It'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ChangeContactPersonModal;
