import { IContactPersonViewModelList } from '../saveUserModal/SaveUserModal.model';

export interface IErrorField {
	error: boolean;
	message: string;
}

export interface IMandatoryFieldError {
	[key: string]: IErrorField;
}

export const getInitialData = (contactPersonModalData: IContactPersonViewModelList[]): IMandatoryFieldError => {
	const data: IMandatoryFieldError = {};
	for (let i = 0; i < contactPersonModalData.length; i++) {
		data[`LocationId${contactPersonModalData[i].LocationId}`] = { error: false, message: '' };
	}
	return data;
};
