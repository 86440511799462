import moment from 'moment';
import React, { FC } from 'react';
import { IPaymentHistoryList } from 'src/modules/paymentHistory/PaymentHistory.model';
import Receipt from 'src/components/svg/Receipt';
import styles from './PaymentHistoryTable.module.scss';

interface PaymentHistoryTableProps {
	paymentHistoryData: IPaymentHistoryList[];
	page: number;
	totalPages: number;
	totalCount: number;
	goToPage: (pageIndex: number) => void;
	sortName: string;
	sortOrder: string;
	onSortChange: any;
}

const PaymentHistoryTable: FC<PaymentHistoryTableProps> = (props) => {
	const { paymentHistoryData, page, totalPages, totalCount, goToPage, sortName, sortOrder, onSortChange } = props;

	const downloadReceipt = (value: any) => {
		window.open(value, '_blank');
	};

	return (
		<div className={styles.paymentsTableSection}>
			<section>
				<table className={`table table-striped table-hover ${styles.paymentHistoryTable}`}>
					<thead>
						<tr>
							<th
								data-test-auto='b0ba6a56-0ef4-4a7f-b4bd-9cbeb2d8248c'
								className={styles.dateCol}
								scope='col'
								onClick={() => onSortChange('paymentDate')}>
								Date{' '}
								<i
									className={`fa fa-fw fa-sort${
										sortName !== 'paymentDate' ? '' : sortOrder === 'asc' ? '-asc' : '-desc'
									} sortIcon`}></i>
							</th>
							<th
								data-test-auto='3bff3ff3-b45c-4270-bacf-9af6d1600134'
								className={styles.descriptionCol}
								scope='col'
								onClick={() => onSortChange('paymentDescription')}>
								Description{' '}
								<i
									className={`fa fa-fw fa-sort${
										sortName !== 'paymentDescription' ? '' : sortOrder === 'asc' ? '-asc' : '-desc'
									} sortIcon`}></i>
							</th>
							<th
								data-test-auto='a33f6086-7f8a-4791-9097-381caba2348a'
								className={styles.amountCol}
								scope='col'
								title='Amount ($), (Inc. All Taxes and Charges)'
								onClick={() => onSortChange('amount')}>
								Amount ($){' '}
								<i
									className={`fa fa-fw fa-sort${
										sortName !== 'amount' ? '' : sortOrder === 'asc' ? '-asc' : '-desc'
									} sortIcon`}></i>
							</th>
							<th
								data-test-auto='eae06ca8-86e1-4336-8637-c2d59e0a460e'
								className={styles.quantityCol}
								scope='col'
								onClick={() => onSortChange('quantity')}>
								Quantity{' '}
								<i
									className={`fa fa-fw fa-sort${
										sortName !== 'quantity' ? '' : sortOrder === 'asc' ? '-asc' : '-desc'
									} sortIcon`}></i>
							</th>
							<th
								data-test-auto='c472705e-196b-4e51-a289-c2244fe9b006'
								className={styles.invoiceCol}
								scope='col'
								onClick={() => onSortChange('invoiceNo')}>
								Invoice No{' '}
								<i
									className={`fa fa-fw fa-sort${
										sortName !== 'invoiceNo' ? '' : sortOrder === 'asc' ? '-asc' : '-desc'
									} sortIcon`}></i>
							</th>
							<th
								data-test-auto='fbdb9b2b-e1d6-403f-9463-4195d37c923a'
								className={styles.receiptCol}
								scope='col'>
								Receipt Details
							</th>
						</tr>
					</thead>
					<tbody>
						{paymentHistoryData.length === 0 ? (
							<tr>
								<td
									data-toggle='collapse'
									colSpan={6}
									className={`text-center ${styles.noData}`}>
									There is no data to display
								</td>
							</tr>
						) : (
							paymentHistoryData.map((eachPayment, index) => {
								return (
									<tr
										className={styles.paymentRow}
										key={`${eachPayment.paymentDate}--${index}`}>
										<td className={styles.dateColData}>{moment(eachPayment.paymentDate).format('MM/DD/YYYY')}</td>
										<td className={styles.descriptionColData}>{eachPayment.paymentDescription}</td>
										<td
											title={eachPayment.info}
											className={styles.amountColData}>
											{eachPayment.amount ? eachPayment.amount : 0}
										</td>
										<td className={styles.quantityColData}>{eachPayment.quantity ? eachPayment.quantity : 0}</td>
										<td className={styles.invoiceColData}>{eachPayment.invoiceNo ? eachPayment.invoiceNo : 0}</td>
										<td className={styles.receiptColData}>
											{eachPayment.receipt ? (
												<span onClick={() => downloadReceipt(eachPayment.receipt)}>
													<span className={styles.viewReceiptIcon}>
														<Receipt />
													</span>
													<span className={styles.viewReceiptText}>View Receipt</span>
												</span>
											) : (
												<span>N/A</span>
											)}
										</td>
									</tr>
								);
							})
						)}
					</tbody>
				</table>
			</section>
			<section className={styles.paymentTablePagination}>
				<div className={styles.paginationDetails}>
					Showing{' '}
					<span className={styles.paymentPageFirst}>{paymentHistoryData.length > 0 ? (page - 1) * 10 + 1 : 0}</span> to{' '}
					<span className={styles.paymentPageLast}>
						{page * 10 > paymentHistoryData.length ? (page - 1) * 10 + paymentHistoryData.length : page * 10}
					</span>{' '}
					of <span className={styles.paymentPageTotal}>{totalCount}</span> entries
				</div>
				<ul className={styles.showPageNumber}>
					<li
						data-test-auto='4609aa3d-26c6-41fe-9b24-37f49bf4ee95'
						title={page <= 1 ? '' : 'First Page'}
						className={page <= 1 ? styles.paginationActionDisabled : ''}
						onClick={() => goToPage(1)}>
						<span className='fa fa-angle-double-left'></span>
					</li>
					<li
						data-test-auto='18496e49-ccbe-4714-9732-5a83b3b11efc'
						title={page <= 1 ? '' : 'Previous Page'}
						className={page <= 1 ? styles.paginationActionDisabled : ''}
						onClick={() => goToPage(page - 1)}>
						<span className='fa fa-angle-left'></span>
					</li>
					<li
						title={page.toString()}
						className={styles.currentPageNumber}>
						{page.toString()}
					</li>
					<li
						data-test-auto='41069500-94c0-492b-b719-e49bd12c0551'
						title={page >= paymentHistoryData.length ? '' : 'Next Page'}
						className={page >= totalPages ? styles.paginationActionDisabled : ''}
						onClick={() => goToPage(page + 1)}>
						<span className='fa fa-angle-right'></span>
					</li>
					<li
						data-test-auto='30ebb53f-31d6-45ad-a2b6-c5c0a26e64e0'
						title={page >= paymentHistoryData.length ? '' : 'Last Page'}
						className={page >= totalPages ? styles.paginationActionDisabled : ''}
						onClick={() => goToPage(totalPages)}>
						<span className='fa fa-angle-double-right'></span>
					</li>
				</ul>
			</section>
		</div>
	);
};

export default PaymentHistoryTable;
