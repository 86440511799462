import { type Reducer } from 'redux';

import { actionTypes } from './company-management.action';

export const reducer: Reducer<any> = (state = {}, action): any => {
	switch (action.type) {
		case actionTypes.REQUEST_ADMIN_USERS:
			return {
				...state,
				isUsersLoading: action.isUsersLoading,
			};
		case actionTypes.RECEIVE_ADMIN_USERS:
			return {
				...state,
				isUsersLoading: action.actionData.isUsersLoading,
				adminUsers: action.actionData.data,
			};
		case actionTypes.REQUEST_ALL_COMPANY_LIST:
			return {
				...state,
				isCompanyListLoading: action.isCompanyListLoading,
			};
		case actionTypes.RECEIVE_ALL_COMPANY_LIST:
			return {
				...state,
				isCompanyListLoading: action.actionData.isCompanyListLoading,
				officeList: action.actionData.data,
			};
		case actionTypes.START_UPDATE_PRODUCTS:
			return {
				...state,
				isProductUpdating: action.isProductUpdating,
			};
		case actionTypes.REQUEST_ALL_PRODUCTS :
			return {
				...state,
				isProductsLoading : action.isProductsLoading
			}
		case actionTypes.RECEIVE_ALL_PRODUCTS :
			return {
				...state,
				isProductsLoading : action.actionData.isProductsLoading,
				allProducts : action.actionData.data
			}
		case actionTypes.REQUEST_ALL_ADD_ON_FEATURES :
			return {
				...state,
				isAddOnFeaturesLoading : action.isAddOnFeaturesLoading
			}
		case actionTypes.RECEIVE_ALL_ADD_ON_FEATURES :
			return {
				...state,
				isAddOnFeaturesLoading : action.actionData.isAddOnFeaturesLoading,
				allAddOnFeatures : action.actionData.data
			}
		case actionTypes.START_UPDATE_ADD_ON_FEATURES :
			return {
				...state,
				isAddOnFeaturesUpdating : action.isAddOnFeaturesUpdating
			}
		case actionTypes.END_UPDATE_ADD_ON_FEATURES :
			return {
				...state,
				isAddOnFeaturesUpdating : action.isAddOnFeaturesUpdating
			}
		default:
			return state;
	}
};
