import { API_BASE_URL } from '../../../helper/Constants';
import { AppThunkAction } from '../../../store';
import { actionTypes as notificationActionTypes } from '../../../pages/common/notification/notification.types';
import { StatusType } from '../../../pages/common/notification/notification.reducer';
import axios from 'axios';
import { actionTypes } from './resource-validator.action';
import { ResourceValidator } from '@sssuite-component-ui/resource-id-validator';
import { CommonMessages } from '../../../helper/Constants';
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {
    requestResourceIds:(): AppThunkAction<any> => (dispatch, getState) =>{
        axios.get(API_BASE_URL + '/api/resource/1001', {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			}
        })
                .then((response: any) => {
                    const { data } = response;
                    const validator = new ResourceValidator(data,'disable','click',	CommonMessages.UnAuthorized);
                    validator.start();                    
                    dispatch({
                        type: actionTypes.SAVE_RESOURCE_IDS, payload: data
                    });
                }).catch(function (error) {
                    logger && logger.trackWarning('Error while requestResourceIds', { 'Error': error?.response?.data});
                    const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof (statusMessage) === 'string') {
                        dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                    }
                });
    }
};
