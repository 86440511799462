import React from "react";

interface ICustomToggleSwitchProps {
    switched?: boolean;
    handleChange(event: any, name?: string): void;
    name?: string;
    disabled?: boolean;
}
const CustomToggleSwitch: React.FC<ICustomToggleSwitchProps> = props => {

    const handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!props.disabled) {
            props.handleChange(e, props.name);
        }
    };

    return (
        <label className="custom switch">
            <input
                type="checkbox"
                onChange={handleToggleChange}
                checked={props.switched}
            />
            <span
                style={{
                    cursor: props.disabled ? "not-allowed" : "pointer",
                }}
                className="slider round"
            ></span>
        </label>
    );
};

export default CustomToggleSwitch;
