import { connect } from 'react-redux';
import { ApplicationState } from 'src/store';
import { PaymentStatus } from './PurchaseReturn.model';
import PaymentInfo from './PaymentInfo';

export default connect(
    (state: ApplicationState) => ({
        pageTitle: 'Payment Summary',
        paymentStatus: PaymentStatus.Cancelled,
    }),
)(PaymentInfo as any);