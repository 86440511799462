import React, { FC } from 'react';
import { Modal, Container, Button } from 'react-bootstrap';
import './DisableSSOModal.style.scss';

interface DisableSSOModalProps {
    onDisableAlertHide: () => void;
    onDisableAlertOk: () => void;
}
const DisableSSOModal: FC<DisableSSOModalProps> = props => {
    return (
        <>
            <Modal.Body className="show-grid">
                <Container>
                    <div className="disableMessage">
                        Single Sign-on will be disabled. A password reset email will be sent to all users currently
                        using SSO. Do you still wish to disable?
                    </div>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    data-test-auto="45ba105b-d383-4bdd-87e5-a6d3bdc085cf"
                    variant="secondary"
                    onClick={props.onDisableAlertHide}
                >
                    Cancel
                </Button>
                <Button
                    data-test-auto="c6568a96-e92e-4d53-afc9-314ffc474bf8"
                    variant="primary"
                    onClick={props.onDisableAlertOk}
                >
                    Ok
                </Button>
            </Modal.Footer>
        </>
    );
};

export default DisableSSOModal;
