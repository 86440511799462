import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import OverlayLoader from 'src/components/common/OverlayLoader';
import BreadCrumbNav from 'src/components/userManagement/BreadCrumbNav';
import ProductDownloads from 'src/modules/generalSettings/ProductDownloads';
import SMSNotifications from 'src/modules/generalSettings/SMSNotifications';
import { TaxSoftwareTypes } from 'src/modules/generalSettings/TaxSoftwares/index';
import { ClientManagement } from 'src/modules/generalSettings/ClientManagement/index';
import * as TaxSoftwareSettingStore from 'src/modules/generalSettings/TaxSoftwares/TaxSoftwareSetting.store';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';

import { ITaxSoftwareSetting } from '../../modules/generalSettings/TaxSoftwares/TaxSoftwareSetting.model';
import { ICompanyProfile } from '../common/companySettings/companySettings.types';
import { IOverlayLoader } from '../common/overlayLoader/OverlayLoader.model';
import styles from './index.module.scss';
import ApiAccessSetting from '../../modules/generalSettings/ApiAccess';
import * as ClientManagementSettingStore from 'src/modules/generalSettings/ClientManagement/ClientManagementSetting.store';
import { logger } from '../../oidcClient/authProvider';

export type GeneralSettingsProps = {
	companyProfile: ICompanyProfile;
	overlayLoader: IOverlayLoader;
	requestTaxSoftwareSetting: () => void;
	updateTaxSoftwareSetting: (taxSoftware: ITaxSoftwareSetting, resourceId: string, callback: () => void) => void;
} & typeof TaxSoftwareSettingStore.actionCreators
	& typeof ClientManagementSettingStore.actionCreators;

const GeneralSettings: FC<GeneralSettingsProps> = (props) => {
	const [isCchSelected, setCchSelected] = useState<boolean>(false); 
	const breadCrumbConfig = [
		{
			name: 'SafeSend Suite',
			activePage: false,
			link: '/',
		},
		{
			name: 'General',
			activePage: true,
			link: '',
		},
	];

	const dispatch = useDispatch();

	const bindCCH = (isSelected: boolean): void => {
		setCchSelected(isSelected);
	}

	useEffect(() => {
		logger && logger.trackPageView('Navigated to General Settings');
		dispatch({
			type: loaderTypes.LOADING,
			show: true,
			text: 'Loading...',
		});
	}, []);

	return (
		<div className='suitePageContent'>
			<section className='suitePageInnerContent'>
				<div className={styles.securitySettingsFixedHeader}>
					<BreadCrumbNav config={breadCrumbConfig} />
					<h1 className='suitePageHeading'>General</h1>
				</div>
				<OverlayLoader
					show={props.overlayLoader.show}
					text={props.overlayLoader.text}
				/>
				<section className={styles.mainSection}>
					<ProductDownloads companyProfile={props.companyProfile} />
				</section>
				<hr className={styles.horizontal_divider} />
				<section className={styles.mainSection}>
					<ApiAccessSetting />
				</section>
				<hr className={styles.horizontal_divider} />
				<section className={styles.mainSection}>
					<SMSNotifications />
				</section>
				<hr className={styles.horizontal_divider} />
				<ClientManagement
					requestClientManagementSetting={props.requestClientManagementSetting}
					updateClientManagementSetting={props.updateClientManagementSetting}					
					isCCHSelected={isCchSelected} />
				<hr className={styles.horizontal_divider} />
				<section className={styles.mainSection}>
					<TaxSoftwareTypes
						requestTaxSoftwareSetting={props.requestTaxSoftwareSetting}
						updateTaxSoftwareSetting={props.updateTaxSoftwareSetting}
						bindCCH={bindCCH}
					/>
				</section>
			</section>
		</div>
	);
};

export default GeneralSettings;
