import React, { useState, FC, useEffect } from 'react';
import AccordianList from '../../../../../components/common/accordian-list';
import search from '../../../../../components/svg/search.svg';
import './groups.scss';
import Tooltip from '../../../../../components/common/tooltip';
import { IUserGroupsProps, IUserGroups } from './groups.type';
import { userGroupsConstants } from '../../../../../helper/rbac-constants';

const UserGroups: FC<IUserGroupsProps> = (props) => {

    const [ searchText, setSearchText] = useState('');  
    
    const { 
        groups,
        onEdit,
        onDelete,
        newGroupIds,
        handleGroupSelection,
        selectedGroup,
        applyDisabled
    } = props; 

    const [groupListState, setGroupListState]= useState<IUserGroups[]>([]); 
    const [searchError, setSearchError] = useState(false); 
    
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        if(event.target.value.length===0) {
            setGroupListState([...groups]);     
            setSearchError(false);            
        } else {
            const filteredData = groups.filter((data) => data.groupName.toLowerCase().includes(event.target.value.toLowerCase()));

            if(!filteredData.length) {
                setSearchError(true);
            } else {
                setSearchError(false);
            }

            setGroupListState(filteredData);
        }
    };

    useEffect(() => {
        setGroupListState([...groups]);        
    }, [groups]);  


    return (
        <div className='groups_list_container'>
            <div className='section_header'>
                GROUPS
                <Tooltip text='Custom User Groups with assigned roles you place your users in.' />
            </div>
            <div className='content_container'>
                <div className='search_container'>
                    <input type="text" id="search" placeholder='Search...' value={searchText} onChange={(event) => handleSearch(event)} autoComplete="off" />
                    <img src={search} />
                </div>
                <div className='list_container'>
                    <AccordianList 
                        groups={groupListState}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        newGroupIds={newGroupIds}
                        handleGroupSelection={handleGroupSelection}
                        selectedGroup={selectedGroup}
                        errorText="No matching records found."
                        displayError={searchError}
                        selectDataResourceId={`${userGroupsConstants.prefix}_${userGroupsConstants.selectGroup}`}
                        deleteDataResourceId={`${userGroupsConstants.prefix}_${userGroupsConstants.deleteGroup}`}
                        editDataResourceId={`${userGroupsConstants.prefix}_${userGroupsConstants.editGroup}`}
                        applyDisabled={applyDisabled}
                    />
                </div>
            </div>
        </div>
    );
};

export default UserGroups;