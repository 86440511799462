import { AuthenticationProvider } from 'src/modules/userManagement/users/Users.model';
import { NotificationAction } from '../../../pages/common/notification/notification.reducer';
import { actionTypes } from './SSOSettings.actions';
import { OverlayLoaderAction } from 'src/pages/common/overlayLoader/OverlayLoader.model';

export interface ISSOSettings {
    provider: AuthenticationProvider,
    enabled: boolean,
    authorizationType: AuthorizationMode[],
    data: ISSOSettingsData[],
    isModified: boolean;
}

export enum AuthorizationMode {
    None,
    AzureAdGroups,
    SSRUserList,
    AzureTenantId
}

export interface ISSOSettingsData {
    key: string,
    roles: IUserGroup[],
    authorizationType: AuthorizationMode
}

export enum IUserGroup {
    None,
    SystemAdmin = 1,
    Staff,
    Partner,
    Signatures,
    Exchange,
    EROOnly = 1001
}

export type KnownAction =
    DispatchAction |
    NotificationAction |
    OverlayLoaderAction;

export type DispatchAction =
    ReceiveSSOSettingsAction |
    UpdateSSOSettingsAction;

interface ReceiveSSOSettingsAction {
    type: actionTypes.RECEIVE_SSO_SETTINGS;
    ssoSettings: ISSOSettings[];
}

interface UpdateSSOSettingsAction {
    type: actionTypes.UPDATE_SSO_SETTINGS;
    ssoSettings: ISSOSettings[];
}