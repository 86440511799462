import { FC, useEffect, useState } from 'react';
import ButtonWithTextIcon from 'src/components/common/button-with-text-icon';
import { myCompanyResources } from 'src/helper/rbac-constants';
import { ProductDownloadsConstant, AddOnFeaturesConstant } from 'src/helper/Constants';
import SectionDescription from '../partials/section-description/section-description';
import SectionHeader from '../partials/section-header/section-header';
import styles from './subscribed-products.module.scss';
import { logger } from 'src/oidcClient/authProvider';
import { IProduct, IAddOnFeature } from 'src/pages/company-management/company-management.types';

const SubscribedProducts: FC<any> = (props) => {
	const {
		name = '',
		subscriptionId = 0,
		subscribedProducts = [],
		updateProducts = () => null,
		notifyTierChange = () => null,
		allProducts = [],
		allAddOnFeatures = [],
		updateAddOnFeatures = () => null,
	} = props;

	const [productList, setProductList] = useState([]);
	const [addOnFeatureList, setAddOnFeatureList] = useState([]);
	const [changedProducts, setChangedProducts] = useState([]);
	const [changedAddOnFeatures, setChangedAddOnFeatures] = useState([]);

	useEffect(() => {
		getProductNameAndStatus();
		// getAddOnNameAndStatus();
	}, [subscribedProducts]);

	const handleApply = (): void => {
		logger && logger.trackTrace('handleApply: Updating subscribed products');
		let queryParam = '';
		for (const product in changedProducts) queryParam += `productTypes=${changedProducts[product].productId}&`;

		notifyTierChange(queryParam);
		updateProducts(changedProducts);
		// updateAddOnFeatures(changedAddOnFeatures);
		setChangedProducts([]);
		// setChangedAddOnFeatures([]);
	};

	const getProductNameAndStatus = () => {
		const newProductList = allProducts?.map((prod: IProduct) => {
			const matchedProduct = subscribedProducts?.find((singleProd) => prod.id === singleProd.productId);

			return {
				productId: prod.id,
				productStatus: matchedProduct ? matchedProduct.productStatus : 0,
				isSubscribed: matchedProduct ? matchedProduct.isSubscribed : false,
				isProduct: matchedProduct ? matchedProduct.isProduct : false,
				name: prod.name,
				checked: matchedProduct ? matchedProduct.productStatus === 1 : false,
				isVisible: prod.isVisible,
			};
		});
		setProductList([...newProductList]);
	};

	const getAddOnNameAndStatus = () => {
		const newAddOnFeatureList = (allAddOnFeatures || [])
			.filter((addOn: IAddOnFeature) => addOn.subscriptionId !== 0)
			.map((addOn: IAddOnFeature) => {
				const isAddonAvailable = addOn.subscriptionId === subscriptionId;
				return {
					addOnFeatureId: addOn.addOnFeatureId,
					isEnabled: addOn.isEnabled,
					menuText: addOn.menuText,
					subscriptionId: isAddonAvailable ? addOn.subscriptionId : 0,
					disabled: !isAddonAvailable,
				};
			});
		setAddOnFeatureList(newAddOnFeatureList);
	};

	const getClassNameForNotSubscribed = (isSubscribed) => {
		return !isSubscribed ? styles.checkboxDisabled : '';
	};

	const getClassNameForNotSubscribedLabel = (product) => {
		const disabledCursor = `${styles.checkboxDisabled} subscribe-disabled-label`
		return !product.isSubscribed || product.productId == 1 ? disabledCursor  : '';
};

	const getTitleForNotSubscribed = (isSubscribed) => {
		return !isSubscribed ? ProductDownloadsConstant.ProductSubscriptionError : '';
	};

	const handleProductChanges = (clickedProduct: any) => {
		if (changedProducts.filter((prod) => prod.productId === clickedProduct.productId).length === 0)
			setChangedProducts([...changedProducts, clickedProduct]);
		else {
			const newList = changedProducts.filter((prod) => prod.productId !== clickedProduct.productId);
			setChangedProducts(newList);
		}
	};

	const handleAddOnChanges = (clickedAddOn: IAddOnFeature) => {
		if (!changedAddOnFeatures.some((addOn) => addOn.addOnFeatureId === clickedAddOn.addOnFeatureId))
			setChangedAddOnFeatures([...changedAddOnFeatures, clickedAddOn]);
		else {
			const newList = changedAddOnFeatures.filter((addOn) => addOn.addOnFeatureId !== clickedAddOn.addOnFeatureId);
			setChangedAddOnFeatures(newList);
		}
	};

	return (
		<div className={styles.subscribed_products_wrapper}>
			<div className={styles.subscribed_products_header}>
				<SectionHeader title='Company Account Details' />
			</div>
			<div className={styles.subscribed_products_description}>
				<SectionDescription
					text={`To update your current subscription please contact  
				your customer success representative.`}
				/>
			</div>
			<div className={styles.subscription_tier_wrapper}>
				<div className={styles.subscription_tier_header}>Subscription Tier</div>
				<div className={styles.subscription_tier_name}>{name}</div>
			</div>
			<div className={styles.included_products_wrapper}>
				<div className={styles.included_products_title}>Included Products</div>
				<div className={styles.included_products_list}>					
					{productList?.filter((product) => product.isVisible)
						?.map((product) => (
						<div
							className={
								product.productId === 1 || !product.isSubscribed
									? `${styles.productListWrapper} ${styles.checkboxDisabled}`
									: styles.productListWrapper
							}
							>
							<input
								aria-disabled={product.productId == 1}
								disabled={!product.isSubscribed}
								title={getTitleForNotSubscribed(product.isSubscribed)}
								className={getClassNameForNotSubscribed(product.isSubscribed)}
								type='checkbox'
								id={product.productId}
								name={product.name}
								value={product.name}
								defaultChecked={product.checked}
								data-test-auto={`company-management_company-account-details_products_${product?.productId}`}
								onChange={(event) => {
									if (product.productId != 1 && product.isSubscribed) {
										const storeObjectWithChange = {
											productId: event?.target.id,
											isSubscribed: product.isSubscribed,
											productStatus: product.productStatus === 1 ? 2 : 1,
											isProduct: product.isProduct,
										};
										handleProductChanges(storeObjectWithChange);
									}
								}}
							/>
							<label 
								htmlFor={product.name}
								className={getClassNameForNotSubscribedLabel(product)}
								title={getTitleForNotSubscribed(product.isSubscribed)}
							>
								{product.name}
							</label>
						</div>
					))}
					{/* To be enabled when we want to enable disable addons for the company */}
					{/* {addOnFeatureList?.map((addOn) => (
						<div
							className={
								addOn.disabled ? `${styles.productListWrapper} ${styles.checkboxDisabled}` : styles.productListWrapper
							}
							title={addOn.disabled ? AddOnFeaturesConstant.AddOnSubscriptionError : ''}>
							<input
								disabled={addOn.disabled}
								type='checkbox'
								id={addOn.addOnFeatureId}
								name={addOn.menuText}
								value={addOn.menuText}
								defaultChecked={addOn.isEnabled}
								onChange={(event) => {
									if (!addOn.disabled) {
										const storeObjectWithChange = {
											addOnFeatureId: Number(event?.target.id),
											isEnabled: !addOn.isEnabled,
										};
										handleAddOnChanges(storeObjectWithChange);
									}
								}}
							/>
							<label htmlFor={addOn.menuText}>{addOn.menuText}</label>
						</div>
					))} */}
				</div>
				<div className={styles.action_button_wrapper}>
					<ButtonWithTextIcon
						label='Apply Changes'
						className={styles.action_button}
						data-test-auto='company-management_company-account-details_apply-changes'
						disabled={changedProducts.length === 0}
						// To be enabled when we want to enable disable addons for the company
						// disabled={changedAddOnFeatures.length === 0 && changedProducts.length === 0}
						disabledText='Please make changes to enable button'
						onClick={() => {
							handleApply();
						}}
						data-resource-id={`${myCompanyResources.prefix}_${myCompanyResources.accountDetails}_${myCompanyResources.section}`}
					/>
				</div>
			</div>
		</div>
	);
};

export default SubscribedProducts;
