import React, { FC } from 'react';
import Tooltip from '../../../../../components/common/tooltip';
import { ReactComponent as Right } from '../../../../../components/svg/chevron-right.svg';
import { IColumnWithHeaderProps, IRoles } from './column-with-header.types';
import './column-with-header.style.scss';

const ColumnWithHeader: FC<IColumnWithHeaderProps> = (props) => {

    const {
        roles = [],
        title = '',
        showTooltip = false,
        selected = -1,
        onClick = () => null
    } = props;

    const handleRowClick = (e: React.SyntheticEvent, rowData: IRoles) => {
        onClick(rowData.id);
    };

    const getClassNameForRole = (selected: number, id: number, isActive: boolean) =>  {

        if(selected === id && isActive) {
            return 'element-container selected-element';
        } else if (!isActive) {
            return 'element-container disabled-element';
        } else {
            return 'element-container';
        }

    };

    return (
        <div className='column-with-header-container'>
                <div className='column-header'>
                    {title}
                </div>
                <div className='column-container'>
                    {
                        roles.map((role: IRoles, index: number) => {
                            if(!role.isSubscribed) {
                                return;
                            }
                            return (
                                <div 
                                    className={getClassNameForRole(selected, role.id, role.isActive)}
                                    key={`element-container-${index}`} 
                                    onClick={(e) => role.isActive ? handleRowClick(e, role) : null}
                                >
                                    <div className='text-element'>
                                        <div className='text' title={role.name}>
                                            {role.name}
                                        </div>
                                        {showTooltip && <Tooltip text={role.description} />}
                                    </div>
                                    <div className='image-element'>
                                        <Right />
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
    );
};

export default ColumnWithHeader;