import React from 'react';
import './Unauthorized.scss';
import { RouteConstants } from '../helper/Constants';
import { AllMenuAccessDenied, SubMenuAccessDenied } from './AccessDenied';
import { ProductType } from '@sssuite-component-ui/ss-suite-layout';
import { logger } from '../oidcClient/authProvider';

interface IUnauthorizedProps {
	message: string;
	isUserLogoutEnable?: boolean;
	isAllMenuDisabled?: boolean;
}

export class Unauthorized extends React.Component<IUnauthorizedProps> {	
	componentDidMount(): void {		
		logger && logger.trackTrace("Unauthorized Access", { 
			"ProductType": ProductType.SSSuitePortal.toString(), 
			"IsAllMenuAccessDenied": this.props.isAllMenuDisabled });
		if (this.props.isAllMenuDisabled) window.history.replaceState(null, '', RouteConstants.UNAUTHORIZED);
	}

	public render() {
		return this.props.isAllMenuDisabled ? <AllMenuAccessDenied /> : <SubMenuAccessDenied />;
	}
}
