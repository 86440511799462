import { API_BASE_URL } from "src/helper/Constants";
import axios, { AxiosResponse } from "axios";
import { logger } from 'src/oidcClient/authProvider';

export function getAllUsers(callback: (availableUsers: any) => void) {
    axios.get(API_BASE_URL + "/api/dropdown/all-user-dropdown/")
        .then((response: AxiosResponse) => {
            callback(response.data);
        }).catch((error) => {
            logger && logger.trackWarning('Error while getAllUsers', { 'Error': error?.response?.data});
        });
}