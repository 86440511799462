import React, { FC, useState } from 'react';
import { ReactComponent as ToolTip } from '../../svg/tooltip.svg';
import './tooltip.scss';

const Tooltip: FC<any> = (props) => {
    const {
        text = 'default tooltip. Please pass custom text message via props'
    } = props;

    return (
        <div className='tooltip-container'>
            <div 
                className='tooltip-image-container' 
                title={text || 'default tooltip'}
            >
                <ToolTip />
            </div>
        </div>
    ); 
};

export default Tooltip;