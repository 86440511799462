import React, { useState, FC, useEffect } from 'react';
import DraggableList from '../../../../../components/common/draggable-list';
import Tooltip from '../../../../../components/common/tooltip';
import search from '../../../../../components/svg/search.svg';
import './available-users.scss';
import { AppNotifier } from "../../../../../helper/AppNotifier";


const AvailableUsers: FC<any> = (props) => {

    const {
        usersInGroup = [],
        availableUsers = [],
        handleDropChange = () => null,
        disableDragDrop = false,
        drapDropDisabledMessage = ''
    } = props;

    const [searchText, setSearchText] = useState('');
    const [usersAvailable, setUsersAvailable] = useState<any[]>([]);
    const [searchError, setSearchError] = useState(false);

 
    useEffect(() => {
        setUsersAvailable(filterUsersBySearchText(availableUsers, searchText));
    }, [availableUsers, searchText]);

    const dragOverHandler = (event: any) => {
        event.preventDefault();
    };

    const dropHandler = (event: any) => {
        if(!disableDragDrop)
        { 
        const user = JSON.parse(event.dataTransfer.getData('text'));
        if (availableUsers.some((ele: any) => user.findIndex((datum: any) => datum.userId === ele.userId) === -1) || !availableUsers.length) {
            const newUserList = [...user, ...availableUsers];
            const newUsersInGroup = usersInGroup.filter((ele: any) => !user.some((value: any) => value.userId === ele.userId));
            handleDropChange(newUsersInGroup, newUserList, user);
        }
        }
    };

    const handleSearch = (event: any) => {
        const searchText = event.target.value;
        setSearchText(searchText);
        setUsersAvailable(filterUsersBySearchText(availableUsers, searchText));
    };

    const filterUsersBySearchText = (availableUsers: any[], searchText: string) => {
        if (searchText.length === 0) {
            setSearchError(false);
            return availableUsers;
        } else {
            const filteredData = availableUsers.filter((data: any) => data.fullName.toLowerCase().includes(searchText.toLowerCase()));
            setSearchError(filteredData.length === 0);
            return filteredData;
        }
    };

    const isAllowDragging = () => {
        if(disableDragDrop) {
            AppNotifier.Warning(drapDropDisabledMessage);
            return false;
        }
        return true;
    }

    return (
        <div className='groups_list_container'>
            <div className='section_header'>
                AVAILABLE USERS
                <Tooltip text='List of all users not in selected group.' />
            </div>
            <div
                className='content_container'
                onDragOver={(event) => dragOverHandler(event)}
                onDrop={(event) => dropHandler(event)}
            >
                <div className='search_container'>
                    <input type="text" id="search" placeholder='Search...' value={searchText} onChange={(event) => handleSearch(event)} autoComplete="off" />
                    <img src={search} />
                </div>
                <div className='list_container'>
                    <DraggableList
                        listData={usersAvailable}
                        displayError={searchError}
                        errorText="No matching records found."
                        isDragAllowed={isAllowDragging}
                    />
                </div>
            </div>
        </div>
    );
};

export default AvailableUsers;