import React, { FC } from "react";

const UploadIcon: FC = () => {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.499512 9.89918C0.63212 9.89918 0.759297 9.95186 0.853065 10.0456C0.946833 10.1394 0.999512 10.2666 0.999512 10.3992V12.8992C0.999512 13.1644 1.10487 13.4188 1.2924 13.6063C1.47994 13.7938 1.7343 13.8992 1.99951 13.8992H13.9995C14.2647 13.8992 14.5191 13.7938 14.7066 13.6063C14.8942 13.4188 14.9995 13.1644 14.9995 12.8992V10.3992C14.9995 10.2666 15.0522 10.1394 15.146 10.0456C15.2397 9.95186 15.3669 9.89918 15.4995 9.89918C15.6321 9.89918 15.7593 9.95186 15.8531 10.0456C15.9468 10.1394 15.9995 10.2666 15.9995 10.3992V12.8992C15.9995 13.4296 15.7888 13.9383 15.4137 14.3134C15.0387 14.6885 14.5299 14.8992 13.9995 14.8992H1.99951C1.46908 14.8992 0.960371 14.6885 0.585298 14.3134C0.210225 13.9383 -0.000488281 13.4296 -0.000488281 12.8992V10.3992C-0.000488281 10.2666 0.0521901 10.1394 0.145958 10.0456C0.239727 9.95186 0.366903 9.89918 0.499512 9.89918Z" fill="white" />
            <path d="M7.64551 1.14518C7.69196 1.09862 7.74713 1.06168 7.80788 1.03647C7.86862 1.01127 7.93374 0.998291 7.99951 0.998291C8.06528 0.998291 8.1304 1.01127 8.19114 1.03647C8.25189 1.06168 8.30707 1.09862 8.35351 1.14518L11.3535 4.14518C11.4474 4.23907 11.5001 4.36641 11.5001 4.49918C11.5001 4.63196 11.4474 4.7593 11.3535 4.85318C11.2596 4.94707 11.1323 4.99982 10.9995 4.99982C10.8667 4.99982 10.7394 4.94707 10.6455 4.85318L8.49951 2.70618V11.4992C8.49951 11.6318 8.44683 11.759 8.35307 11.8527C8.2593 11.9465 8.13212 11.9992 7.99951 11.9992C7.8669 11.9992 7.73973 11.9465 7.64596 11.8527C7.55219 11.759 7.49951 11.6318 7.49951 11.4992V2.70618L5.35351 4.85318C5.30702 4.89967 5.25183 4.93655 5.1911 4.96171C5.13036 4.98687 5.06526 4.99982 4.99951 4.99982C4.93377 4.99982 4.86867 4.98687 4.80793 4.96171C4.74719 4.93655 4.692 4.89967 4.64551 4.85318C4.59902 4.8067 4.56215 4.75151 4.53699 4.69077C4.51183 4.63003 4.49888 4.56493 4.49888 4.49918C4.49888 4.43344 4.51183 4.36834 4.53699 4.3076C4.56215 4.24686 4.59902 4.19167 4.64551 4.14518L7.64551 1.14518Z" fill="white" />
        </svg>
    );
};

export default UploadIcon;