import { connect } from 'react-redux';

import { type ApplicationState } from '../../store/index';
import GeneralSettings from './index';
import * as TaxSoftwareSettingStore from 'src/modules/generalSettings/TaxSoftwares/TaxSoftwareSetting.store';
import * as ClientManagementSettingStore from 'src/modules/generalSettings/ClientManagement/ClientManagementSetting.store';

export default connect((state: ApplicationState) => ({
	companyProfile: state.companyData.companyProfile,
	overlayLoader: state.overlayLoader,
}),
	{
		...TaxSoftwareSettingStore.actionCreators,
		...ClientManagementSettingStore.actionCreators
	})(GeneralSettings as React.FC);
