import axios, { AxiosResponse,AxiosError } from "axios";
import { AppThunkAction } from "../../../store";
import { actionTypes } from "./otp.actions";
import { API_BASE_URL, OTPVerify } from "../../../helper/Constants";
import { KnownActions } from "./otp.types";
import { StatusType } from "../notification/notification.reducer";
import { actionTypes as notificationTypes } from "../notification/notification.types";
import { logger } from 'src/oidcClient/authProvider';


export const saveOneTimePassword =
    (mobileNumber: string, countryCode: string): AppThunkAction<KnownActions> =>
        (dispatch) => {
            dispatch({
                type: actionTypes.REQUEST_ONETIMEPASSWORD,
            });
            axios
                .post(
                    `${API_BASE_URL}/api/otp/send-otp`, { mobileNumber: mobileNumber, countryCode: countryCode }
                )
                .then(() => {
                    dispatch({
                        type: actionTypes.RECEIVE_ONETIMEPASSWORD,
                    });
                })
                .catch(function (error) {
                    logger && logger.trackWarning('Error while saveOneTimePassword', { 'Error': error?.response?.data});
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: error.statusText,
                        statusType: StatusType.Error,
                    });
                });
        };

export const validateOTP =
    (
        otp: string,
        mobileNumber: string,
        callback: (isOTPValid: boolean) => void
    ): AppThunkAction<KnownActions> =>
        (dispatch) => {
            dispatch({ type: actionTypes.REQUEST_VALIDATE_ONETIMEPASSWORD });

            axios
                .post(
                    `${API_BASE_URL}/api/otp/verify-otp`, { mobileNumber: mobileNumber, otp: otp }
                )
                .then((response: any) => {
                    const data = response.data;
                    dispatch({
                        type: actionTypes.COMPLETED_VALIDATE_ONETIMEPASSWORD,
                        otpState: { ...data, isOTPValid: data },
                    });
                    callback(data);
                    if (data) {
                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: OTPVerify.validOTP,
                            statusType: StatusType.Success,
                        });
                    } else {
                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: OTPVerify.InvalidOTP,
                            statusType: StatusType.Warning,
                        });
                    }
                })
                .catch(function (error) {
                    logger && logger.trackWarning('Error while validateOTP', { 'Error': error?.response?.data});
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: error.statusText,
                        statusType: StatusType.Error,
                    });
                });
        };

export const requestMFAOTPLength =
    (): AppThunkAction<KnownActions> => (dispatch) => {
        dispatch({ type: actionTypes.REQUEST_MFA_ONETIMEPASSWORD_LENGTH });

        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/helper/mfa-otp-length`
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.RECEIVE_MFA_ONETIMEPASSWORD_LENGTH,
                    otpState: { ...data, mfaOTPLength: data },
                });
            })
            .catch(function (error: AxiosError) {
                logger && logger.trackWarning('Error while requestMFAOTPLength', { 'Error': error?.response?.data});
                if(error.response && error.response.status!=419 && error.response.status!=512)
                {
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: error.statusText,
                        statusType: StatusType.Error,
                    });
                }
            });
    };
