import React from 'react';

const Warning: React.FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='17'
			fill='none'
			viewBox='0 0 16 17'>
			<g clipPath='url(#clip0_5275_220283)'>
				<path
					fill='#fff'
					fillOpacity='0.01'
					d='M0 0H16V16H0z'
					transform='translate(0 .5)'></path>
				<g clipPath='url(#clip1_5275_220283)'>
					<path
						fill='#D69F38'
						d='M8.982 2.065a1.13 1.13 0 00-1.96 0L.165 13.732c-.457.778.09 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 2.065zM8 5.499c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 01-1.1 0L7.1 6.494a.905.905 0 01.9-.995zm.002 6a1 1 0 110 2 1 1 0 010-2z'></path>
				</g>
			</g>
			<defs>
				<clipPath id='clip0_5275_220283'>
					<path
						fill='#fff'
						d='M0 0H16V16H0z'
						transform='translate(0 .5)'></path>
				</clipPath>
				<clipPath id='clip1_5275_220283'>
					<path
						fill='#fff'
						d='M0 0H16V16H0z'
						transform='translate(0 .5)'></path>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Warning;
