import * as Request from 'superagent';
import { AppNotifier as VenusNotifier } from '../../../helper/AppNotifier';

export const uploadedFile = (
	fileObject: any,
	sas: { sas: string; fileName: string },
	fileName: string,
	uploadProgressCallback?: () => void,
	uploadCompleteCallback?: (fileToUpload: any) => void,
) => {
	const fileToUpload = {
		maxBlockSize: 0,
		file: 0,
		size: 0,
		uploadFailed: false,
		bytesUploaded: 0,
		sasUrl: '',
		completeEvent: 0,
		blockIds: new Array(),
		uploadedIds: new Array(),
		filecontent: new Array(),
		blockUploaded: new Array(),
		blockIdPrefix: '',
		numberOfBlocks: 0,
		fileName: '',
		fileGUID: '',
	};
	fileToUpload.maxBlockSize = 4096 * 1024;
	fileToUpload.file = fileObject;
	fileToUpload.size = fileObject?.size;
	fileToUpload.uploadFailed = false;
	fileToUpload.bytesUploaded = 0;
	fileToUpload.fileGUID = sas.fileName;
	if (fileToUpload.size < fileToUpload.maxBlockSize) {
		fileToUpload.maxBlockSize = fileToUpload.size;
	}
	if (fileToUpload.size % fileToUpload.maxBlockSize == 0) {
		fileToUpload.numberOfBlocks = fileToUpload.size / fileToUpload.maxBlockSize;
	} else {
		fileToUpload.numberOfBlocks = parseInt((fileToUpload.size / fileToUpload.maxBlockSize).toString(), 10) + 1;
	}
	fileToUpload.sasUrl = sas.sas;
	fileToUpload.blockIdPrefix = 'block-';
	fileToUpload.fileName = fileName;
	uploadFileInBlocks(fileToUpload, uploadProgressCallback, uploadCompleteCallback);
};

const uploadFileInBlocks = (
	fileToUpload: any,
	uploadProgressCallback?: any,
	uploadCompleteCallback?: (fileToUpload: any) => void,
) => {
	for (let i = 0; i < fileToUpload.numberOfBlocks; i++) {
		let fileContent;
		if (i == fileToUpload.numberOfBlocks - 1) fileContent = fileToUpload.file.slice(i * fileToUpload.maxBlockSize);
		else fileContent = fileToUpload.file.slice(i * fileToUpload.maxBlockSize, (i + 1) * fileToUpload.maxBlockSize);

		var blockId = fileToUpload.blockIdPrefix + pad(fileToUpload.blockIds.length, 6);
		fileToUpload.blockIds.push(btoa(blockId));
		fileToUpload.filecontent.push(fileContent);
	}
	let ajaxcaller = 10;
	if (fileToUpload.numberOfBlocks < 10) ajaxcaller = fileToUpload.numberOfBlocks;
	for (let i = 0; i < ajaxcaller; i++) {
		fileToUpload.nextItemtoUpload = ajaxcaller;
		initReaderObject(
			fileToUpload,
			fileToUpload.blockIds[i],
			fileToUpload.filecontent[i],
			i,
			uploadProgressCallback,
			uploadCompleteCallback,
		);
	}
};

const pad = (number: number, length: number) => {
	let str = '' + number;
	while (str.length < length) {
		str = '0' + str;
	}
	return str;
};

const initReaderObject = (
	fileToUpload: any,
	blockId: any,
	fileContent: any,
	blockIndex: number,
	uploadProgressCallback?: any,
	uploadCommittCallBack?: (fileToUpload: any) => void,
) => {
	const readerObject: any = new FileReader();
	readerObject.onloadend = function (evt: any) {
		if (evt.target.readyState == 2) {
			const uri = fileToUpload.sasUrl + '&comp=block&blockid=' + blockId;
			const requestData = new Uint8Array(evt.target.result);
			let encryptedFileName = '';
			try {
				encryptedFileName = btoa(fileToUpload.file.name);
			} catch (exp) {
				try {
					encryptedFileName = btoa(encodeURIComponent(fileToUpload.file.name));
				} catch (exp) {
					encryptedFileName = fileToUpload.file.name.replace(/\W/g, '');
					encryptedFileName = btoa(encryptedFileName);
				}
			}

			Request.put(uri)
				.set('Access-Control-Allow-Origin', '*')
				.set('x-ms-blob-type', 'BlockBlob')
				.set('x-ms-blob-content-disposition', 'attachment;filename=' + '"' + encryptedFileName + '"')
				.set('x-ms-meta-filename', fileToUpload.fileName)
				.set('x-ms-meta-filetype', fileToUpload.file.type)
				.send(requestData)
				.on('progress', function (e: any) {
					uploadProgressCallback && uploadProgressCallback();
				})
				.end((err: any, res: any) => {
					fileToUpload.bytesUploaded += requestData.length;
					fileToUpload.uploadedIds.push(blockId);
					if (fileToUpload.blockIds[fileToUpload.nextItemtoUpload]) {
						initReaderObject(
							fileToUpload,
							fileToUpload.blockIds[fileToUpload.nextItemtoUpload],
							fileToUpload.filecontent[fileToUpload.nextItemtoUpload],
							fileToUpload.nextItemtoUpload,
							uploadProgressCallback,
							uploadCommittCallBack,
						);
						fileToUpload.nextItemtoUpload = fileToUpload.nextItemtoUpload + 1;
					} else {
						if (fileToUpload.uploadedIds.length === fileToUpload.blockIds.length)
							commitBlockList(fileToUpload, uploadCommittCallBack);
					}
				});
		}
	};
	readerObject.readAsArrayBuffer(fileContent);
};

const commitBlockList = (fileToUpload: any, uploadCommittCallBack?: (fileToUpload: any) => void) => {
	const uri = fileToUpload.sasUrl + '&comp=blocklist';
	let requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
	for (var i = 0; i < fileToUpload.blockIds.length; i++) {
		requestBody += '<Latest>' + fileToUpload.blockIds[i] + '</Latest>';
	}
	requestBody += '</BlockList>';
	let encryptedFileName = '';
	try {
		encryptedFileName = btoa(fileToUpload.fileName);
	} catch (exp) {
		try {
			encryptedFileName = btoa(encodeURIComponent(fileToUpload.fileName));
		} catch (exp) {
			encryptedFileName = fileToUpload.fileName.replace(/\W/g, '');
			encryptedFileName = btoa(encryptedFileName);
		}
	}

	Request.put(uri)
		.set('Access-Control-Allow-Origin', '*')
		.set('x-ms-blob-content-disposition', 'attachment;filename=' + '"' + encryptedFileName + '"')
		.set('x-ms-meta-filename', fileToUpload.fileName)
		.set('x-ms-meta-filetype', fileToUpload.file.type)
		.send(requestBody)
		.end((err: any, res: any) => {
			if (res && res.statusCode === 201) {
				uploadCommittCallBack && uploadCommittCallBack(fileToUpload);
			} else {
				VenusNotifier.Error('Upload failed for file name: ' + fileToUpload.fileName);
				uploadCommittCallBack && uploadCommittCallBack(fileToUpload);
			}
		});
};
