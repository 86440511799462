import { actionTypes } from './user-groups.actions';
import { actionTypes as notificationActionTypes } from '../../../pages/common/notification/notification.types';
import { API_BASE_URL } from '../../../helper/Constants';
import { StatusType } from '../../../pages/common/notification/notification.reducer';
import { AppThunkAction } from '../../../store';
import axios, { AxiosResponse } from 'axios';
import { apiResponse, IUserGroups, KnownAction,getReorderedGroups } from './user-groups.types';
import { userGroupsConstants } from '../../../helper/rbac-constants';
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {
    requestAllUserGroups:(newGroupIds?:number[]): AppThunkAction<KnownAction> => (dispatch, getState) =>{
        dispatch({ type: actionTypes.REQUEST_USER_GROUP_LIST });
        axios.get(API_BASE_URL + '/api/user-group/all-groups-with-role')
                .then((response: AxiosResponse<IUserGroups[]>) => {
                    let { data } = response;
                    if(newGroupIds && newGroupIds.length>0)
                    {
                        data=getReorderedGroups(data,newGroupIds);
                    }
                    dispatch({
                        type: actionTypes.RECEIVE_USER_GROUP_LIST, userGroupList: data
                    });
                }).catch(function (error) {
                    logger && logger.trackWarning('Error while requestAllUserGroups', { 'Error': error?.response?.data});
                    const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof (statusMessage) === 'string') {
                        dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                    }
                });
    },
    addUserGroup:(group:IUserGroups, successCallback: (id: number) => void, errorCallback:(message:string)=>void) : AppThunkAction<KnownAction> => (dispatch, getState) =>{
        axios.post(API_BASE_URL + '/api/user-group', 
            group, 
            { 
                headers: { 
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Resource-Id': `${userGroupsConstants.prefix}_${userGroupsConstants.addGroups}` 
                }, 
                withCredentials: true,

            }
        )
        .then((response: AxiosResponse<apiResponse>) => {
            const { data } = response;
            if(data.isSuccess)
            {
                successCallback(data.id);
            }
            else{
                errorCallback(data.errorMessage);
            }
        }).catch(function (error) {
            logger && logger.trackWarning('Error while addUserGroup', { 'Error': error?.response?.data});
            const statusMessage: any = error.statusText?.message ?? error.statusText;
            if (typeof (statusMessage) === 'string') {
                errorCallback(statusMessage);
            }
        });
    },
    updateUserGroup:(group:IUserGroups,successCallback:()=>void, errorCallback:(message:string)=>void):AppThunkAction<KnownAction> => (dispatch, getState) =>{
        axios.put(
            API_BASE_URL + '/api/user-group',
            group, 
            { 
                headers: { 
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Resource-Id': `${userGroupsConstants.prefix}_${userGroupsConstants.editGroup}`
                },
                withCredentials: true 
            }
        )
        .then((response: AxiosResponse<apiResponse>) => {
            const { data } = response;
            if(data.isSuccess)
            {
                successCallback();
            }
            else{
                errorCallback(data.errorMessage);
            }
        }).catch(function (error) {
            logger && logger.trackWarning('Error while updateUserGroup', { 'Error': error?.response?.data});
            const statusMessage: any = error.statusText?.message ?? error.statusText;
            if (typeof (statusMessage) === 'string') {
                errorCallback(statusMessage);
            }
        });
    },
    deleteUserGroup: (groupId:number,successCallback: () => void):AppThunkAction<KnownAction> => (dispatch, getState) =>{
        axios.delete(
            API_BASE_URL + '/api/user-group/'+groupId,
            {
                headers: {
                    'X-Resource-Id': `${userGroupsConstants.prefix}_${userGroupsConstants.deleteGroup}`
                }
            }
        )
        .then((response: AxiosResponse<apiResponse>) => {
            const { data } = response;
            if(data.isSuccess)
            {
                successCallback();
            }
            else{
                dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: data.errorMessage, statusType: StatusType.Error });
            }
        }).catch(function (error) {
            logger && logger.trackWarning('Error while deleteUserGroup', { 'Error': error?.response?.data});
            const statusMessage: any = error.statusText?.message ?? error.statusText;
            if (typeof (statusMessage) === 'string') {
                dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
            }
        });
    },
    getUsersInGroup: (groupId: number, successCallback: (data: any)=> void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if(groupId) {
            axios.get(
                `${API_BASE_URL}/api/user-group/users-in-group/${groupId}` 
            ).then((response: AxiosResponse<any>) => {
                const { data } = response;
                if(data.groupId) {
                    successCallback(data);
                } else{
                    dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: data.errorMessage, statusType: StatusType.Error });
                }
            }).catch(function(error) {
                logger && logger.trackWarning('Error while getUsersInGroup', { 'Error': error?.response?.data});
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof (statusMessage) === 'string') {
                    dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                }
            });
        }
    },
    applyChanges: (usersInGroup: any, successCallback: (value: apiResponse) => void) : AppThunkAction<KnownAction> => (dispatch, getState) =>{
        axios.put(
            API_BASE_URL + '/api/user-group/update-user-group',
            usersInGroup, 
            { 
                headers: { 
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Resource-Id': `${userGroupsConstants.prefix}_${userGroupsConstants.editGroup}`
                },
                withCredentials: true,
                
            }
        ).then((response: AxiosResponse<apiResponse>) => {
            const { data } = response;
            if(data)
            {
                successCallback(data);
            }
            else{
                dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: 'Update failed', statusType: StatusType.Error });
            }
        }).catch(function (error) {
            logger && logger.trackWarning('Error while applyChanges', { 'Error': error?.response?.data});
            const statusMessage: any = error.statusText?.message ?? error.statusText;
            if (typeof (statusMessage) === 'string') {
                dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
            }
        });
    },
    validateUserGroupUpdate: (usersInGroup: any, callback: (value: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.post(API_BASE_URL + '/api/user-group/validate-user-group',
            usersInGroup,
            {
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                withCredentials: true
            })
            .then((response: AxiosResponse<boolean>) => {
                const { data } = response;
                callback(data);
            }).catch(function (error) {
                logger && logger.trackWarning('Error while validateUserGroupUpdate', { 'Error': error?.response?.data});
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof (statusMessage) === 'string') {
                    dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                }
            });
    },

};


