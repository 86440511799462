/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import { type FC } from 'react';
import Table from 'react-bootstrap/Table';
import ButtonWithTextIcon from 'src/components/common/button-with-text-icon';
import { ReactComponent as DoubleLeft } from 'src/components/svg/chevron-double-left.svg';
import { ReactComponent as DoubleRight } from 'src/components/svg/chevron-double-right.svg';
import { ReactComponent as Left } from 'src/components/svg/chevron-left.svg';
import { ReactComponent as Right } from 'src/components/svg/chevron-right.svg';
import { ReactComponent as DashCircle } from 'src/components/svg/dash-circle.svg';
import { ReactComponent as AddInCircle } from 'src/components/svg/plus-circle.svg';
import { ipFilteringResources } from 'src/helper/rbac-constants';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';

import ConfirmationPopup from '../../../components/common/confirmationPopup/index';
import styles from './index.module.scss';
import { useIpFilteringList } from './ip-filtering-list.hooks';
import { type IAddressListProps } from './ip-filtering-list.types';

const IpFilteringList: FC<IAddressListProps> = (props) => {
	const {
		searchResults,
		searchName,
		setSearchName,
		searchFromIp,
		setSearchFromIp,
		searchToIp,
		setSearchToIp,
		newIpName,
		setNewIpName,
		newFromIp,
		setNewFromIp,
		newToIp,
		setNewToIp,
		selectedRecord,
		setSelectedRecord,
		showDeletePopup,
		setShowDeletePopup,
		setIdToDelete,
		pages,
		currentPage,
		setCurrentPage,
		pageSize,
		ipInputRegex,
		dispatch,
		handleHide,
		handleDiscard,
		handleAdd,
		handleDelete,
		handleUpdate,
		handleSearch,
		handleSearchReset,
	} = useIpFilteringList(props);

	return (
		<article className='suitePageSection'>
			<h3 className='suitePageSectionHeader'>IP Address List</h3>
			<p className='suitePageSectionDescription'>By enabling this feature, you can ensure a unified experience.</p>
			<div className={styles.table_container}>
				<Table
					striped
					bordered>
					<thead>
						<tr>
							<th>
								<input
									type='text'
									placeholder='Search by Name'
									className={styles.searchInput}
									value={searchName}
									onChange={(event) => {
										setSearchName(event.target.value);
										handleSearch(event.target.value, 'name');
									}}
									onFocus={() => {
										setSearchFromIp('');
										setSearchToIp('');
										if (searchName.length === 0) handleSearchReset();
									}}
								/>
							</th>
							<th>
								<input
									type='text'
									placeholder='Search by from IP Address'
									value={searchFromIp}
									className={styles.searchInput}
									onChange={(event) => {
										setSearchFromIp(event.target.value);
										handleSearch(event.target.value, 'fromIPAddress');
									}}
									onFocus={() => {
										setSearchName('');
										setSearchToIp('');
										if (searchFromIp.length === 0) handleSearchReset();
									}}
								/>
							</th>
							<th className={styles.actionSectionBorder}>
								<input
									type='text'
									placeholder='Search by to IP Address'
									value={searchToIp}
									className={styles.searchInput}
									onChange={(event) => {
										setSearchToIp(event.target.value);
										handleSearch(event.target.value, 'toIPAddress');
									}}
									onFocus={() => {
										setSearchName('');
										setSearchFromIp('');
										if (searchToIp.length === 0) handleSearchReset();
									}}
								/>
							</th>
							<th className={styles.actionSection}>Action</th>
						</tr>
						<tr className={styles.addIpSection}>
							<th>
								<input
									type='text'
									placeholder='New Name'
									className={styles.input}
									value={newIpName}
									onChange={(e) => {
										setNewIpName(e.target.value);
									}}
								/>
							</th>
							<th>
								<input
									type='text'
									placeholder='From IP Address'
									className={styles.input}
									value={newFromIp}
									onChange={(e) => {
										if (ipInputRegex.test(e?.target.value)) setNewFromIp(e.target.value);
									}}
								/>
							</th>
							<th>
								<input
									type='text'
									placeholder='To IP Address'
									className={styles.input}
									value={newToIp}
									onChange={(e) => {
										if (ipInputRegex.test(e.target.value)) setNewToIp(e.target.value);
									}}
								/>
							</th>
							<th className={styles.actionDivButtonSection}>
								{!newFromIp?.length && !newIpName?.length && !newToIp?.length ? (
									<div className={styles.addButtonSection}>
										<ButtonWithTextIcon
											label='Add'
											onClick={() => {
												dispatch({
													type: notificationTypes.NOTIFICATION,
													statusMessage: 'Please enter IP name.',
													statusType: StatusType.Warning,
												});
											}}
											SvgIcon={<AddInCircle />}
											className={styles.addButton}
											data-test-auto='add-ip-button'
										/>
									</div>
								) : (
									<div className={styles.actionDivButton}>
										<ButtonWithTextIcon
											onClick={() => {
												setNewIpName('');
												setNewFromIp('');
												setNewToIp('');
											}}
											label='Cancel'
											className={styles.cancleButton}
											data-test-auto='cancel-add-ip-button'
										/>
										<ButtonWithTextIcon
											onClick={() => {
												handleAdd();
											}}
											label='Save'
											className={styles.saveButton}
											data-test-auto='save-new-ip-button'
											data-resource-id={`${ipFilteringResources.prefix}_${ipFilteringResources.ipFilterTable}_${ipFilteringResources.addButton}`}
										/>
									</div>
								)}
							</th>
						</tr>
					</thead>
					<tbody>
						{searchResults.length === 0 ? (
							<tr className={styles.noRecordDiv}>
								<td>
									There is <span>no data</span> to display.
								</td>
							</tr>
						) : (
							searchResults
								?.sort((a, b) => b.ipAddressId - a.ipAddressId)
								.map((addr = [], index) => {
									if (index < currentPage * pageSize && index >= (currentPage - 1) * pageSize) {
										if (addr.ipAddressId === selectedRecord.ipAddressId && props.isFilteringEnabled)
											return (
												<tr key={index}>
													<th>
														<input
															type='text'
															className={styles.input}
															value={selectedRecord.name}
															onChange={(e) => {
																setSelectedRecord({
																	...selectedRecord,
																	name: e.target.value,
																});
															}}
														/>
													</th>
													<th>
														<input
															type='text'
															className={styles.input}
															value={selectedRecord.fromIPAddress}
															onChange={(e) => {
																if (ipInputRegex.test(e?.target.value))
																	setSelectedRecord({
																		...selectedRecord,
																		fromIPAddress: e.target.value,
																	});
															}}
														/>
													</th>
													<th>
														<input
															type='text'
															className={styles.input}
															value={selectedRecord.toIPAddress}
															onChange={(e) => {
																if (ipInputRegex.test(e.target.value))
																	setSelectedRecord({
																		...selectedRecord,
																		toIPAddress: e.target.value,
																	});
															}}
														/>
													</th>
													<th className={styles.actionDivButtonSection}>
														<div className={styles.actionDivButton}>
															<ButtonWithTextIcon
																onClick={() => {
																	setSelectedRecord({});
																}}
																label='Cancel'
																className={styles.cancleButton}
																data-test-auto='cancel-ip-update-button'
															/>
															<ButtonWithTextIcon
																onClick={() => {
																	handleUpdate();
																}}
																label='Save'
																className={styles.saveButton}
																data-test-auto='save-ip-update-button'
																data-resource-id={`${ipFilteringResources.prefix}_${ipFilteringResources.ipFilterTable}_${ipFilteringResources.editButton}`}
															/>
														</div>
													</th>
												</tr>
											);

										return (
											<tr
												key={index}
												onClick={() => {
													if (props.isFilteringEnabled) setSelectedRecord(addr);
												}}>
												<td className={styles.textStyle}>{addr.name}</td>
												<td className={styles.textStyle}>{addr.fromIPAddress}</td>
												<td className={styles.textStyle}>{addr.toIPAddress}</td>
												<td className={styles.actionDivButtonSection}>
													<div
														className={styles.addButtonSection}
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
														}}>
														<ButtonWithTextIcon
															label='Delete'
															onClick={() => {
																setIdToDelete(addr.ipAddressId);
																setShowDeletePopup(true);
															}}
															className={styles.deleteButton}
															SvgIcon={<DashCircle />}
															data-test-auto='delete-ip-button'
															data-resource-id={`${ipFilteringResources.prefix}_${ipFilteringResources.ipFilterTable}_${ipFilteringResources.deleteButton}`}
														/>
													</div>
												</td>
											</tr>
										);
									}
								})
						)}
					</tbody>
				</Table>
				<div className={styles.navigatorContainer}>
					<div
						className={`${styles.navAction} ${currentPage === 1 ? styles.disabled_nav : ''}`}
						title='First Page'
						onClick={() => {
							if (currentPage > 1) setCurrentPage(1);
						}}>
						<DoubleLeft />
					</div>
					<div
						className={`${styles.navAction} ${currentPage === 1 ? styles.disabled_nav : ''}`}
						title='Previous Page'
						onClick={() => {
							if (currentPage > 1) setCurrentPage(currentPage - 1);
						}}>
						<Left />
					</div>
					<div
						className={styles.pageNumbers}
						title={currentPage.toString()}>
						{currentPage}
					</div>
					<div
						className={`${styles.navAction} ${currentPage === pages ? styles.disabled_nav : ''}`}
						title='Next Page'
						onClick={() => {
							if (currentPage < pages) setCurrentPage(currentPage + 1);
						}}>
						<Right />
					</div>
					<div
						className={`${styles.navAction} ${currentPage === pages ? styles.disabled_nav : ''}`}
						title='Last Page'
						onClick={() => {
							if (currentPage < pages) setCurrentPage(pages);
						}}>
						<DoubleRight />
					</div>
				</div>
			</div>
			<ConfirmationPopup
				show={showDeletePopup}
				actionButtonType={'danger'}
				cancelText='No, Keep It'
				header='Delete Address'
				message={'Are you sure you want to delete the IP Address?'}
				okText='Yes, Delete It'
				onHide={() => {
					handleHide();
				}}
				onCancel={() => {
					handleDiscard();
				}}
				onOk={() => {
					handleDelete();
				}}
			/>
		</article>
	);
};

export default IpFilteringList;
