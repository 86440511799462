import React from 'react';
import { AccessDenied, ProductAccessDenied } from './svg/CustomGraphics';
import { Button } from 'react-bootstrap';
import { WithLogout, WithLogoutProps } from 'src/oidcClient/WithLogout';

export const SubMenuAccessDenied: React.FC = () => (
	<div style={{position: 'relative', width:'100%', height:'100%'}}>
		<div
		style={{
			position: 'absolute',
			left: '50%',
			top: '50%',
			transform: 'translate(-50%, -50%)',
		}}>
		<div className='access-denied-wrapper'>
			<div className='access-denied'>
				<div>
					<AccessDenied
						width={200}
						height={200}
					/>
				</div>
				<br />
				<div className='access-denied-text'>
					<h5>Access Denied</h5>
					<div>
						Please consult your system <br />
						administrator for assistance.
					</div>
				</div>
			</div>
		</div>
	</div>
	</div>
);

const AllMenuAccessDeniedComponent: React.FC<WithLogoutProps> = ({
	onLogout
}) => {
	return (
		<div style={{position: 'relative', width:'100%', height:'100%'}}>
		<div
			style={{
				position: 'absolute',
				left: '50%',
				top: '50%',
				transform: 'translate(-50%, -50%)',
			}}>
			<div className='product-access-denied'>
				<div>
					<ProductAccessDenied
						width={200}
						height={200}
					/>
				</div>
				<br />
				<div className='product-access-denied-text'>
					<h5>Access Denied</h5>
					<div>
						<div>
							We are sorry, you do not have permission to access Safesend Suite.
							<br /> Please contact your system administrator to update your user profile
						</div>
						<br />
						<Button
							onClick={onLogout}
							id='product-access-denied-btn'
							data-test-auto='8b5d9c1d-823e-42bd-9b20-443a9bd4dade'>
							Sign in as a Different User
						</Button>
					</div>
				</div>
			</div>
		</div>
		</div>
	);
};

export const AllMenuAccessDenied = WithLogout(AllMenuAccessDeniedComponent);
