import { connect } from 'react-redux';
import { type ApplicationState } from '../../store/index';
import PaymentHistory from './index';
import * as PaymentHistoryStore from 'src/modules/paymentHistory/PaymentHistory.store';
import * as PurchaseReturnStore from 'src/modules/paymentHistory/PurchaseReturn.store';

export default connect(
    (state: ApplicationState) => ({
        companyData: state.companyData,
        purchaseData: state.purchaseData,
        overlayLoader: state.overlayLoader,
    }),
    {
        ...PaymentHistoryStore.actionCreators,
        ...PurchaseReturnStore.actionCreators,
    }
)(PaymentHistory as React.FC);
