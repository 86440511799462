import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ClientManagement.module.scss';
import { ApplicationState } from '../../../store';
import { IClientManagementSetting, initialState, ReconcileType } from './ClientManagementSetting.model';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { ClientManagementSettingsConstants } from 'src/helper/Constants';
import { clientMngmntResources } from '../../../helper/rbac-constants';
import { logger } from 'src/oidcClient/authProvider';

interface ClientManagementProps {
    requestClientManagementSetting: () => void;
    updateClientManagementSetting: (
        setting: IClientManagementSetting,
        resourceId: string,
        callback: () => void) => void;
    isCCHSelected: boolean;
}

export const ClientManagement: FC<ClientManagementProps> = (props) => {
    const clientManagement = useSelector((state: ApplicationState) => state.clientManagementSetting);
    const [clientManagementSetting, setClientManagementSetting] = useState<IClientManagementSetting>(initialState);
    const dispatch = useDispatch();

    useEffect(() => {
        props.requestClientManagementSetting();
    }, []);

    useEffect(() => {
        setClientManagementSetting(clientManagement);
    }, [clientManagement]);

    const handleClientManagementChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        logger && logger.trackTrace('handleClientManagementChange: Client management settings change in general settings');
        let temp = clientManagementSetting;
        dispatch({
            type: loaderTypes.LOADING,
            show: true,
            text: ClientManagementSettingsConstants.ClientManagementSettingMessage,
        });
        const value = event.target.value;
        switch (value) {
            case ClientManagementSettingsConstants.Add:
                temp.reconcileType = ReconcileType.Add;
                break;
            case ClientManagementSettingsConstants.Ignore:
                temp.reconcileType = ReconcileType.Ignore;
                break;
            case ClientManagementSettingsConstants.Confirm:
                temp.reconcileType = ReconcileType.Confirm;
                break;
            default:
                temp.reconcileType = ReconcileType.Ignore;
                break;
        }
        setClientManagementSetting(temp);
        props.updateClientManagementSetting(clientManagementSetting, clientMngmntResources.updateClientManagementSetting, () => {
            dispatch({
                type: notificationTypes.NOTIFICATION,
                statusType: StatusType.Success,
                statusMessage: ClientManagementSettingsConstants.UpdateClientManagementSettingsSuccess,
            });
            dispatch({
                type: loaderTypes.LOADING,
                show: false,
                text: '',
            });
        });
    };

    const handleToggleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let temp = clientManagementSetting;
        dispatch({
            type: loaderTypes.LOADING,
            show: true,
            text: ClientManagementSettingsConstants.ClientManagementSettingMessage,
        });
        temp.extractClientIdEnabled = event.target.checked;
        setClientManagementSetting(temp);
        props.updateClientManagementSetting(clientManagementSetting, clientMngmntResources.updateClientManagementSetting, () => {
            dispatch({
                type: notificationTypes.NOTIFICATION,
                statusType: StatusType.Success,
                statusMessage: ClientManagementSettingsConstants.UpdateClientManagementSettingsSuccess,
            });
            dispatch({
                type: loaderTypes.LOADING,
                show: false,
                text: '',
            });
        });
    }

    return (
        <article className='suitePageSection'>
            <h3 className='suitePageSectionHeader'>Client Management</h3>
            <div className={props.isCCHSelected ? styles.showDiv : styles.hideDiv}>
                <h3 className='suitePageSectionSubHeader'>Extract Client ID’s from CCH</h3>
                <p className='suitePageSectionDescription'>
                    By enabling this feature, client ID’s will be extracted from CCH tax returns
                    <br />
                    and updated in our client management system.
                </p>
                <div className='switchSettingsBlock form-switch'>
                    <input
                        className='switchSettingsButton form-check-input'
                        type='checkbox'
                        id='extractClientIdEnabled'
                        data-test-auto='bc7d39b3-99b8-4617-afbc-bd6747c759e7'
                        checked={clientManagementSetting.extractClientIdEnabled}
                        onChange={handleToggleSwitchChange} 
                    />
                    <span className='switchSettingsText'>Extract CCH Client ID’s</span>
                </div>
            </div>
            <h3 className='suitePageSectionSubHeader'>Automatically Reconcile New Record</h3>
            <div className={styles.clientManagement}>
                <div>
                    <input
                        type='radio'
                        id='add'
                        name="client-management"
                        onChange={(event) => {
                            handleClientManagementChange(event);
                        }}
                        data-test-auto='ddc6537d-bc5a-4796-adcb-f6a13e9959c5'
                        value="add"
                        checked={clientManagementSetting.reconcileType == ReconcileType.Add}
                    />
                    <label
                        htmlFor='Add'>
                        Add
                    </label>
                    <div>
                        <span className="row">
                            {ClientManagementSettingsConstants.AddMessage}
                        </span>
                    </div>
                </div>

                <div>
                    <input
                        type='radio'
                        id='ignore'
                        name="client-management"
                        onChange={(event) => {
                            handleClientManagementChange(event);
                        }}
                        data-test-auto='93a53d68-17da-44b5-94fe-8efd1e3bb926'
                        value="ignore"
                        checked={clientManagementSetting.reconcileType == ReconcileType.Ignore}
                    />
                    <label
                        htmlFor='Ignore' >
                        Ignore
                    </label>
                    <div>
                        <span className="row">
                            {ClientManagementSettingsConstants.IgnoreMessage}
                        </span>
                    </div>
                </div>
                <div>
                    <input
                        type='radio'
                        id='confirm'
                        name="client-management"
                        onChange={(event) => {
                            handleClientManagementChange(event);
                        }}
                        data-test-auto='67aff888-ca0d-4918-812f-bf7b0b89c988'
                        value="confirm"
                        checked={clientManagementSetting.reconcileType == ReconcileType.Confirm}
                    />
                    <label
                        htmlFor='Confirm'>
                        Confirm
                    </label>
                    <div>
                        <span className="row">
                            {ClientManagementSettingsConstants.ConfirmMessage}
                        </span>
                    </div>
                </div>
            </div>
        </article>
    );
};
