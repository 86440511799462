export enum EventType {
    None = 0,    
    UserSigned = 1,    
    StatusChanged = 2,

    DocumentSigned = 3,

    SourceDocumentUploaded = 4,
    EngagementLetterSigned = 5,
    OrganizerCompleted = 6,
    AccessedOrganizerNotification = 7
}

export enum EventServiceStatus {
    Loading = -1,
    Unknown = 0,
    Running = 1,
    Stopped = 2
}

export enum AuthType {
    None = 0,
    PureAPI = 1,
    OAuth2 = 2
}

export interface DocumentEvents {
    eventSettings: IEventSettings[]
    clientAPIDetail: IClientAPIDetail[]
}

export interface IEventSettings {
    id: number;
    eventEnabled: boolean;
    eventType: number;
    serviceStatus: EventServiceStatus;
    failureMail: string;
    name:string;
    productType:number;
}

export interface IClientAPIDetail {
    id: number;
    apiUrl: string;
    authType: AuthType;
    authCred: string;
    uniqueArgs: string;    
    eventSettingsId: number;
}

export const initialClientAPIDetail: IClientAPIDetail = {
    id: 0,
    apiUrl: "",
    authType: AuthType.PureAPI,
    authCred: "",
    uniqueArgs: "",
    eventSettingsId: 0,
}

export const initialEventSettings: IEventSettings = {
    id: 0,
    eventEnabled: false,
    eventType:0,
    serviceStatus: EventServiceStatus.Unknown,
    failureMail: "[]",
    name:"",
    productType : 0
}

export interface EventSettingsState {
    showAPISettingsModal: boolean;
    showEventModal: boolean;
    clientAPIDetail: IClientAPIDetail;
    eventSettings: IEventSettings;
    productSubscription : IProductSubscription;
    resourceId?: string;    
}

export interface IProductSubscription {
    id : number;
    subscriptionId : string;
    productId : string;
    productName : string;
    subscriptionName : string;
    primaryKey : string;
    secondaryKey : string;
}

export const initialProductSubscriptions : IProductSubscription = {
    id : 0,
    subscriptionId : '',
    productId : '',
    productName :'',
    subscriptionName : '',
    primaryKey : '',
    secondaryKey : ''
}


   