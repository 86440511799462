import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiAccessSettingConstants } from 'src/helper/Constants';
import { apiAccessSettingResources } from 'src/helper/rbac-constants';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';

import { IApiAccessSettingRequest, IApiAccessSettingResponse } from './ApiAccessSetting.model';
import { getApiSetting, updateApiSetting } from './ApiAccessSetting.store';
import { logger } from 'src/oidcClient/authProvider';

const ApiAccessSetting: FC = () => {
	const [isApiAccessEnabled, setIsApiAccessEnabled] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		fetchApiSetting();
	}, []);

	const fetchApiSetting = (): void => {
		getApiSetting((responseObject: IApiAccessSettingResponse) => {
			if (responseObject.error)
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusType: StatusType.Warning,
					statusMessage: ApiAccessSettingConstants.FetchApiAccessError,
				});
			else setIsApiAccessEnabled(responseObject.isApiAccessEnabled);
		});
	};

	const handleToggleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		logger && logger.trackTrace('handleToggleSwitchChange: API access settings change');
		dispatch({
			type: loaderTypes.LOADING,
			show: true,
			text: 'Updating API Access Settings...',
		});
		const switched = event.target.checked;
		const requestObject: IApiAccessSettingRequest = {
			isApiAccessEnabled: switched,
		};
		updateApiSetting(apiAccessSettingResources.apiAccessControl, requestObject, (response: IApiAccessSettingResponse) => {
			dispatch({
				type: loaderTypes.LOADING,
				show: false,
				text: '',
			});
			if (response) {
				setIsApiAccessEnabled(switched);
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusType: StatusType.Success,
					statusMessage: ApiAccessSettingConstants.UpdateApiAccessSuccess,
				});
			} else
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusType: StatusType.Warning,
					statusMessage: ApiAccessSettingConstants.UpdateApiAccessError,
				});
		});
	};

	return (
		<article className='suitePageSection'>
			<h3 className='suitePageSectionHeader'>{ApiAccessSettingConstants.ApiAccessText}</h3>
			<p className='suitePageSectionDescription'>
				{ApiAccessSettingConstants.APIAccessInfoText}
			</p>
			<div className='switchSettingsBlock form-switch'>
				<input
					className='switchSettingsButton form-check-input'
					type='checkbox'
					id='isApiAccessEnabled'
					data-test-auto='7e455f4d-05df-402c-b18f-3dd43eb9585e'
					checked={isApiAccessEnabled}
					onChange={handleToggleSwitchChange}
					data-resource-id={apiAccessSettingResources.apiAccessControl}
				/>
				<span className='switchSettingsText'>{ApiAccessSettingConstants.ApiAccessText}</span>
				{isApiAccessEnabled && (
					<span
						className='switchSettingsIcon'
						data-test-auto='c8d820ef-36a5-4cd8-a196-1e1fb9f5d152'>
					</span>
				)}
			</div>
		</article>
	);
};

export default ApiAccessSetting;
