import ResetIcon from 'src/components/svg/ResetIcon';
import { IDisabledDnDItems } from 'src/modules/company-management/partials/save-office-location-modal/save-office-location-modal.types';

import Destination from './Destination';
import { IDragAndDropData } from './DragAndDrop.model';
import styles from './DragAndDrop.module.scss';
import Source from './Source';

interface DragAndDropProps<T1 extends string, T2, T3 = undefined, T4 = null> {
	sourceData: IDragAndDropData<T1, T2, T3 | undefined>[];
	setSourceData: (sourceData: IDragAndDropData<T1, T2, T3 | undefined>[]) => void;
	destinationData: IDragAndDropData<T1, T2, T3 | undefined>[];
	setDestinationData: (destinationData: IDragAndDropData<T1, T2, T3 | undefined>[]) => void;
	sourceDataInitial: IDragAndDropData<T1, T2, T3 | undefined>[];
	destinationDataInitial: IDragAndDropData<T1, T2, T3 | undefined>[];
	sourceHeading?: string;
	sourceHeadingInfoText?: string;
	destinationHeading?: string;
	destinationHeadingInfoText?: string;
	showResetButton?: boolean;
	sourceWidth?: string;
	sourceHeight?: string;
	destinationWidth?: string;
	destinationHeight?: string;
	setRemovedUsers?: (usersRemoved: IDragAndDropData<T1, T2, T3 | undefined>[]) => void;
	data?: T4;
	disableDestinationItems?: IDisabledDnDItems[];
	setIsDataChanged?: (isDataChanged: boolean) => void;
}

function DragAndDrop<T1 extends string, T2, T3 = undefined, T4 = null>(props: DragAndDropProps<T1, T2, T3, T4>) {
	const {
		sourceData,
		setSourceData,
		destinationData,
		setDestinationData,
		sourceDataInitial,
		destinationDataInitial,
		sourceHeading,
		sourceHeadingInfoText,
		destinationHeading,
		destinationHeadingInfoText,
		showResetButton,
		sourceWidth,
		sourceHeight,
		destinationWidth,
		destinationHeight,
		setRemovedUsers,
		data,
		disableDestinationItems,
		setIsDataChanged,
	} = props;

	const handleReset = (): void => {
		setSourceData(sourceDataInitial);
		setDestinationData(destinationDataInitial);
		setIsDataChanged && setIsDataChanged(false);
	};

	const handleDropChange = (
		destinationList: IDragAndDropData<T1, T2, T3 | undefined>[],
		sourceList: IDragAndDropData<T1, T2, T3 | undefined>[],
		usersRemoved?: IDragAndDropData<T1, T2, T3 | undefined>[],
	) => {
		const uniqueDestinationList = destinationList.filter(
			(each: any, index: any) => destinationList.findIndex((u: any) => each.value === u.value) === index,
		);
		const uniqueSourceList = sourceList.filter(
			(each: any, index: any) => sourceList.findIndex((u: any) => each.value === u.value) === index,
		);
		setDestinationData(uniqueDestinationList);
		setSourceData(uniqueSourceList);
		if (usersRemoved != undefined && setRemovedUsers) {
			setRemovedUsers(usersRemoved);
		}
		setIsDataChanged && setIsDataChanged(true);
	};

	return (
		<section className={styles.dragAndDropContainer}>
			<Source<T1, T2, T3 | undefined>
				destinationData={destinationData}
				sourceData={sourceData}
				handleDropChange={handleDropChange}
				sourceHeading={sourceHeading}
				sourceHeadingInfoText={sourceHeadingInfoText}
				sourceWidth={sourceWidth}
				sourceHeight={sourceHeight}
			/>
			<Destination<T1, T2, T3 | undefined, T4 | null>
				destinationData={destinationData}
				sourceData={sourceData}
				handleDropChange={handleDropChange}
				destinationHeading={destinationHeading}
				destinationHeadingInfoText={destinationHeadingInfoText}
				destinationWidth={destinationWidth}
				destinationHeight={destinationHeight}
				data={data}
				disableDestinationItems={disableDestinationItems}
			/>
			{showResetButton && (
				<button
					className={styles.resetButton}
					onClick={handleReset}>
					<span className={styles.resetIcon}>
						<ResetIcon />
					</span>
					<span className={styles.resetText}>Reset</span>
				</button>
			)}
		</section>
	);
}

export default DragAndDrop;
