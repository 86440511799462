import { ApplicationState } from '../../store/index';
import { connect } from 'react-redux';
import InOfficeESigning from '.';
import * as InOfficeESigningStore from '../../modules/inoffice-esigning/InOfficeESigning.store';

export default connect(
	(state: ApplicationState) => ({
		inOfficeReturnList: state.inOfficeReturnList,
	}),
	{
		...InOfficeESigningStore.actionCreators,
	},
)(InOfficeESigning as any);
