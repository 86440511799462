import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/Constants';
import { myCompanyResources } from 'src/helper/rbac-constants';

import {
	IAddLocationResponse,
	IEditOfficeLocationRequest,
	IEditOfficeLocationResponse,
	IGetUserIDsInLocationResponse,
	IOfficeLocationDataModel,
} from './save-office-location-modal.types';
import { logger } from 'src/oidcClient/authProvider';

export const addOfficeLocation = (
	officeLocationData: IOfficeLocationDataModel,
	callback: (responseData: IAddLocationResponse) => void,
) => {
	const responseData = {
		locationId: 0,
		error: false,
	};
	axios
		.post(`${API_BASE_URL}/api/office-location/add-location`, officeLocationData, {
			headers: {
				'X-Resource-Id': `${myCompanyResources.prefix}_${myCompanyResources.companyDetails}_${myCompanyResources.addButton}`,
			},
		})
		.then((response: AxiosResponse) => {
			callback({ ...responseData, locationId: response.data });
		})
		.catch((error) => {
            logger && logger.trackWarning('Error while addOfficeLocation', { 'Error': error?.response?.data});
			callback({ ...responseData, error: true });
		});
};

export const updateOfficeLocation = (
	officeLocationData: IOfficeLocationDataModel,
	callback: (response: boolean) => void,
) => {
	let error = false;
	axios
		.put(`${API_BASE_URL}/api/office-location/edit-location`, officeLocationData, {
			headers: {
				'X-Resource-Id': `${myCompanyResources.prefix}_${myCompanyResources.companyDetails}_${myCompanyResources.editButton}`,
			},
		})
		.then((response: AxiosResponse) => {
			const { data } = response;
			callback(data);
		})
		.catch((err) => {
            logger && logger.trackWarning('Error while updateOfficeLocation', { 'Error': err?.response?.data});
			error = true;
			callback(error);
		});
};

export const getUserIDsInLocation = (
	locationId: number,
	callback: (responseData: IGetUserIDsInLocationResponse) => void,
) => {
	const responseData = {
		userIDsInLocation: [],
		error: false,
	};
	axios
		.get(`${API_BASE_URL}/api/office-location/get-location-users?locationId=${locationId}`)
		.then((response: AxiosResponse) => {
			callback({ ...responseData, userIDsInLocation: response.data });
		})
		.catch((error) => {
            logger && logger.trackWarning('Error while getUserIDsInLocation', { 'Error': error?.response?.data});
			callback({ ...responseData, error: true });
		});
};

export const validateEditLocationRequest = (editLocationDetails: IEditOfficeLocationRequest,
	callback: (responseData: IEditOfficeLocationResponse) => void) => {
	const responseData = {
		error: false,
		isContactPerson: false,
		contactPersons: {}
	};
	axios
		.post(`${API_BASE_URL}/api/office-location/validate-edit-location`, editLocationDetails, {
			headers: {
				'X-Resource-Id': `${myCompanyResources.prefix}_${myCompanyResources.companyDetails}_${myCompanyResources.editButton}`,
			}
		})
		.then((response: AxiosResponse<IEditOfficeLocationResponse>) => {
			callback({ ...responseData, isContactPerson: response.data.isContactPerson, contactPersons: response.data.contactPersons });
		})
		.catch((error) => {
            logger && logger.trackWarning('Error while validateEditLocationRequest', { 'Error': error?.response?.data});
			callback({ ...responseData, isContactPerson: false, contactPersons: [], error: true });
		});
}

export const updateContactPerson = (editLocationDetails: IEditOfficeLocationRequest, callback: (isSuccess: boolean) => void) => {
	let error = false;
	axios
		.put(`${API_BASE_URL}/api/office-location/update-contact-person`, editLocationDetails,
			{
				headers: {
					'X-Resource-Id': `${myCompanyResources.prefix}_${myCompanyResources.companyDetails}_${myCompanyResources.editButton}`,
				}
			})
		.then((response: AxiosResponse<boolean>) => {
			callback(response.data);
		})
		.catch((err) => {
            logger && logger.trackWarning('Error while updateContactPerson', { 'Error': err?.response?.data});
			error = true;
			callback(error);
		});
}
