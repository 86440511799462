/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import './user-groups.style.scss';

import React, { type FC, Fragment, useEffect, useState } from 'react';
import { UserManagementConstants } from '../../../helper/Constants';
import ConfirmationPopup from '../../../components/common/confirmationPopup/index';
import UserGroupsContent from './partials/user-groups-content/user-groups-content';
import UserGroupsHeader from './partials/user-groups-header/user-groups-header';
import { IUserGroup } from '../../../modules/securitySettings/SSOSettings/SSOSettings.model';
import SaveUserGroupModal from './saveUserGroupModal';
import {
	type IUserGroups,
	type IUserGroupsAddUpdateModel,
	type IUserGroupsProps,
	type IUserInGroup,
	newGroup,
} from './user-groups.types';
import { logger } from 'src/oidcClient/authProvider';

const UserGroups: FC<IUserGroupsProps> = (props) => {
	const {
		display,
		userGroupList: { groups = [] },
		addUserGroup,
		updateUserGroup,
		requestAllUserGroups,
		requestRoleDrowpdownList,
		deleteUserGroup,
		roleDropdownList,
		getUsersInGroup,
		applyChanges,
		validateUserGroupUpdate,
		requestAllUsers,
		page,
		searchText,
		newUserIds,
	} = props;

	const [showSaveUserGroupModal, setShowSaveUserGroupModal] = useState(false);
	const [showDeleteUserGroupModal, setShowDeleteUserGroupModal] = useState(false);
	const [group, setGroup] = useState(newGroup);
	const [newGroupIds, setNewGroupIds] = useState<number[]>([]);
	const [groupList, setGroupList] = useState<IUserGroups[]>([]);
	const [applyDisabled, setApplyDisabled] = useState(true);
	const [showDiscardPopup, setShowDiscardPopup] = useState(false);
	const [changeParams, setChangeParams] = useState({});
	const [deleteDisabled, setDeleteDisabled] = useState(false);
	const [disableUserGroupAllocation, setUserGroupAllocationDisabled] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState(-1);
	const [usersInGroup, setUsersInGroup] = useState<any[]>([]);
	const [availableUsers, setAvailableUsers] = useState<any[]>([]);
	const [usersInGroupBeforeSave, setUsersInGroupBeforeSave] = useState<any[]>([]);
	const [selectedGroupId, setSelectedGroupId] = useState(-1);
	const [transientSelectedGroupId, setTransientSelectedGroupId] = useState(-1);
	const [usersRemovedInGroup, setusersRemovedInGroup] = useState<IUserInGroup[]>([]);
	const [showForbiddenPopup, setForbiddenPopup] = useState(false);

    const handleGroupSelection = (groupId: number) => {
        setSelectedGroupId(groupId);
        setSelectedGroup(groupId);
        getUsersInGroup(groupId, (data: any) => {
            const { usersInGroup = [], availableUsers = [] } = data;
            setUsersInGroup(usersInGroup);
            setAvailableUsers(availableUsers.filter((user: any) => user.fullName));
            setUsersInGroupBeforeSave(usersInGroup);
            if (usersInGroup.length) {
                setDeleteDisabled(true);
            } else {
                setDeleteDisabled(false);
            }

			setUserGroupAllocationDisabled(groupId == IUserGroup.EROOnly);
        });
    };

    const handleGroupChange = (groupId: number) => {
        if(groupId !== selectedGroupId) {
            setTransientSelectedGroupId(groupId);
            if (!applyDisabled) {
                setShowDiscardPopup(true);
            } else {
                setSelectedGroupId(groupId);
                setusersRemovedInGroup([]);
                handleGroupSelection(groupId);
            }
        }
    };

	const onEditShow = (value: IUserGroups) => {
		setShowSaveUserGroupModal(true);
		setGroup(value);
	};

	const onDeleteShow = (value: IUserGroups) => {
		setShowDeleteUserGroupModal(true);
		setGroup(value);
	};

	const onAdd = (value: IUserGroupsAddUpdateModel, errorCallback: (message: string) => void) => {
		logger && logger.trackTrace('onAdd: Adding user group in User Management');
		addUserGroup(
			value,
			(id) => {
				const groupIds = [...newGroupIds, id];
				setNewGroupIds(groupIds);
				requestAllUserGroups(groupIds);
				handleClose();
			},
			errorCallback,
		);
	};

	const getValueForAddUpdate = (orgObj: IUserGroups): IUserGroupsAddUpdateModel => {
		return {
			groupId: orgObj.groupId,
			groupName: orgObj.groupName,
			groupDescription: orgObj.groupDescription,
			isDefault: orgObj.isDefault,
			roles: orgObj.roles?.map((role: any) => role.roleId),
		};
	};

	const onUpdate = (value: IUserGroupsAddUpdateModel, errorCallback: (message: string) => void) => {
		logger && logger.trackTrace('onUpdate: Updating user group in User Management');
		updateUserGroup(
			value,
			() => {
				requestAllUserGroups(newGroupIds);
				handleClose();
			},
			errorCallback,
		);
	};

	const onDelete = (value: IUserGroups) => {
		logger && logger.trackTrace('onDelete: Deleting user group in User Management');
		deleteUserGroup(value.groupId, () => {
			requestAllUserGroups(newGroupIds);

			setShowDeleteUserGroupModal(false);
			setGroup(newGroup);
		});
	};

	const initiateGroupUpdates = () => {
		onGroupUpdate();
	};

	const getChangeParams = (value: any) => {
		setChangeParams(value);
	};

	const setUsersRemoved = (value: IUserInGroup[]) => {
		let removedList: IUserInGroup[] = [];
		value = value.filter((x, index) => value.findIndex((y) => y.userId == x.userId) === index);
		if (usersRemovedInGroup.length == 0)
			value.forEach((x: IUserInGroup) => {
				removedList.push(x);
			});
		else {
			removedList = [...usersRemovedInGroup];
			value.forEach((x: IUserInGroup) => {
				if (usersRemovedInGroup.findIndex((z: IUserInGroup) => z.userId == x.userId) == -1)
					removedList = [...removedList, x];
			});
		}
		setusersRemovedInGroup(removedList);
	};

    const onGroupUpdate = () => {
        setApplyDisabled(true);
        const uniqueUsers = usersRemovedInGroup.filter((user: any, index: any) =>
            usersInGroup.findIndex((u: any) => user.userId === u.userId)
        );

        if (uniqueUsers.length > 0) {
            const data = {
                groupId: selectedGroupId,
                userIds: uniqueUsers.map(x => x.userId),
            };
            validateUserGroupUpdate(data, (isValid: boolean) => {
                if (isValid) {
                    applyChanges(changeParams, (data: any) => {
                        handleGroupSelection(selectedGroupId);
                        setusersRemovedInGroup([]);
                        setUsersInGroupBeforeSave(usersInGroup);
                        requestAllUsers(true, page.pageIndex, page.pageSize, searchText, newUserIds.join());
                        setSelectedGroupId(transientSelectedGroupId);
                    });
                } else {
                    setForbiddenPopup(true);
                    setSelectedGroupId(data.groupId);
                }
            });
        } else {
            applyChanges(changeParams, (data: any) => {
                handleGroupSelection(selectedGroupId);
                setusersRemovedInGroup([]);
                setUsersInGroupBeforeSave(usersInGroup);
                requestAllUsers(true, page.pageIndex, page.pageSize, searchText, newUserIds.join());
                setSelectedGroupId(transientSelectedGroupId);
            });
        }
    };

	const onDiscardClick = () => {
		setApplyDisabled(true);
		setShowDiscardPopup(false);
		handleGroupSelection(transientSelectedGroupId);
		setusersRemovedInGroup([]);
	};

	const onSaveClick = () => {
		initiateGroupUpdates();
		handleGroupSelection(transientSelectedGroupId);
		setApplyDisabled(true);
		setShowDiscardPopup(false);
		setusersRemovedInGroup([]);
	};

	const handleClose = () => {
		setShowSaveUserGroupModal(false);
		setGroup(newGroup);
	};

	const onForbiddenActionClose = () => {
		setForbiddenPopup(false);
	};

	const updateEROGroup = () => {
		const eroGroup = groups.filter((data) => data.groupId == IUserGroup.EROOnly);
		if(eroGroup.length > 0) {
			eroGroup[0].tooltip = UserManagementConstants.SuiteEROUserGroupAllocationDisabledMessage;
			eroGroup[0].roles = [];
		}
	};

	useEffect(() => {
		requestAllUserGroups(newGroupIds);
		requestRoleDrowpdownList(false);
	}, []);

	useEffect(() => {
		setGroupList([...groups]);
		updateEROGroup();
	}, [groups]);

	return (
		<Fragment>
			<div className={`groups_header_container${display ? ' show' : ' hide'}`}>
				<UserGroupsHeader
					setShowSaveUserGroupModal={setShowSaveUserGroupModal}
					applyDisabled={applyDisabled}
					initiateGroupUpdates={initiateGroupUpdates}
				/>
				<UserGroupsContent
					applyDisabled={applyDisabled}
					selectedGroup={selectedGroup}
					setSelectedGroup={setSelectedGroup}
					handleGroupSelection={handleGroupSelection}
					usersInGroup={usersInGroup}
					availableUsers={availableUsers}
					setUsersInGroup={setUsersInGroup}
					setAvailableUsers={setAvailableUsers}
					newGroupIds={newGroupIds}
					groups={groupList}
					onEdit={onEditShow}
					onDelete={onDeleteShow}
					setApplyDisabled={setApplyDisabled}
					getChangeParams={getChangeParams}
					handleGroupChange={handleGroupChange}
					setUsersRemoved={setUsersRemoved}
					usersInGroupBeforeSave={usersInGroupBeforeSave}
					disableUserGroupAllocation={disableUserGroupAllocation}
					userGroupAllocationDisabledMessage={UserManagementConstants.SuiteEROUserGroupAllocationDisabledMessage}
				/>
			</div>
			{showSaveUserGroupModal && (
				<SaveUserGroupModal
					handleClose={handleClose}
					availableRoleList={roleDropdownList}
					userGroup={getValueForAddUpdate(group)}
					onAdd={onAdd}
					onUpdate={onUpdate}
				/>
			)}
			{showDeleteUserGroupModal && (
				<ConfirmationPopup
					show={showDeleteUserGroupModal}
					actionButtonType={'danger'}
					cancelText='No, Keep Group'
					header='Delete Group?'
					message={<><strong>{group.groupName}</strong> group will be permanently deleted. Are you sure you want to delete this group?</>}
					model={group}
					okText='Yes, Delete Group'
					onCancel={() => {
						setShowDeleteUserGroupModal(false);
						handleClose();
					}}
					onOk={onDelete}
					onHide={() => {
						setShowDeleteUserGroupModal(false);
						handleClose();
					}}
					actionDisabled={deleteDisabled}
					disabledText={'Cannot delete User Group while it contains Users.'}
				/>
			)}
			{showDiscardPopup && (
				<ConfirmationPopup
					show={showDiscardPopup}
					actionButtonType={'primary'}
					cancelText='Discard'
					header='Unsaved Changes'
					message={'You have made unsaved changes. Do you want to discard or save them?'}
					model={group}
					okText='Save'
					onHide={() => {
						setShowDiscardPopup(false);
					}}
					onCancel={() => {
						onDiscardClick();
					}}
					onOk={() => {
						onSaveClick();
					}}
				/>
			)}
			{showForbiddenPopup && (
				<ConfirmationPopup
					show={showForbiddenPopup}
					cancelButtonType={'primary'}
					cancelText='Close'
					header='Action Not Allowed'
					message={'Cannot have a user without any group, please add selected user(s) to another group.'}
					model={group}
					okText='Save'
					onHide={() => {
						onForbiddenActionClose();
					}}
					onCancel={() => {
						onForbiddenActionClose();
					}}
					onOk={() => null}
					hideOkBtn={true}
				/>
			)}
		</Fragment>
	);
};

export default UserGroups;
