import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/Constants';

import { IOfficeLocationDropdownResponse } from '../partials/save-office-location-modal/save-office-location-modal.types';
import { IDataPresentInLocationResponse, IDeleteOfficeLocationResponse } from './company-details.types';
import { logger } from 'src/oidcClient/authProvider';

export const getOfficeLocationDropdownList = (
    callback: (responseData: IOfficeLocationDropdownResponse) => void,
): void => {
    const responseData: IOfficeLocationDropdownResponse = {
        locationList: [],
        error: false,
    };
    axios
        .get(`${API_BASE_URL}/api/office-location/get-location-dropdown-data`)
        .then((response: AxiosResponse) => {
            callback({ ...responseData, locationList: response.data });
        })
        .catch((error) => {
            logger && logger.trackWarning('Error while getOfficeLocationDropdownList', { 'Error': error?.response?.data});
            callback({ ...responseData, error: true });
        });
};

export const isDataPresentInLocation = (
    locationId: number,
    callback: (responseData: IDataPresentInLocationResponse) => void,
): void => {
    const responseData: IDataPresentInLocationResponse = {
        dataPresent: false,
        error: false,
    };
    axios
        .get(`${API_BASE_URL}/api/office-location/check-location-details?locationid=${locationId}`)
        .then((response: AxiosResponse) => {
            callback({ ...responseData, dataPresent: response.data });
        })
        .catch((error) => {
            logger && logger.trackWarning('Error while isDataPresentInLocation', { 'Error': error?.response?.data});
            callback({ ...responseData, error: true });
        });
};

export const deleteOfficeLocation = (
    locationId: number,
    newLocationId: null | number,
    callback: (responseData: IDeleteOfficeLocationResponse) => void,
): void => {
    const responseData: IDeleteOfficeLocationResponse = {
        deleted: false,
        error: false,
    };
    axios
        .delete(
            `${API_BASE_URL}/api/office-location/delete-location?locationId=${locationId}&newLocationId=${newLocationId}`,
        )
        .then((response: AxiosResponse) => {
            callback({ ...responseData, deleted: response.data });
        })
        .catch((error) => {
            logger && logger.trackWarning('Error while deleteOfficeLocation', { 'Error': error?.response?.data});
            callback({ ...responseData, error: true });
        });
};
