const Config = {
  authority: process.env.REACT_APP_IDENTITY_AUTHORITY, // the URL of our identity server
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID, // this ID maps to the client ID in the identity client configuration
  redirect_uri: `${window.location.origin}/signin-oidc`, // URL to redirect to after login
  response_type: "id_token token",
  scope: "openid profile UserManagementApi", // the scopes or resources we would like access to
  accessTokenExpiringNotificationTime: 60,//The number of seconds before an access token is to expire to raise the accessTokenExpiring event (default: 60) 
  automaticSilentRenew: false,
  silent_redirect_uri: `${window.location.origin}/silent-signin-oidc`,
};

export default Config;
