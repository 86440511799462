import React, { FC } from 'react';

const WarningIcon: FC = () => {
	return (
		<svg
			width='14'
			height='14'
			viewBox='0 0 157 148'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M155.084 128.905L89.1751 6.50848C84.5036 -2.16949 72.059 -2.16949 67.3835 6.50848L1.47893 128.905C0.464613 130.789 -0.0439175 132.904 0.00297269 135.043C0.0498628 137.182 0.650572 139.272 1.74647 141.11C2.84236 142.948 4.39599 144.47 6.25572 145.528C8.11544 146.586 10.2177 147.143 12.3573 147.146H144.186C146.327 147.147 148.432 146.591 150.294 145.535C152.157 144.478 153.713 142.956 154.811 141.118C155.909 139.28 156.512 137.188 156.559 135.048C156.607 132.907 156.099 130.79 155.084 128.905ZM78.2813 128.197C76.7516 128.197 75.2562 127.744 73.9843 126.894C72.7124 126.044 71.721 124.836 71.1356 123.423C70.5502 122.009 70.3971 120.454 70.6955 118.954C70.9939 117.454 71.7306 116.076 72.8122 114.994C73.8939 113.912 75.272 113.176 76.7724 112.877C78.2727 112.579 79.8278 112.732 81.2411 113.317C82.6544 113.903 83.8623 114.894 84.7122 116.166C85.562 117.438 86.0156 118.933 86.0156 120.463C86.0156 121.479 85.8156 122.484 85.4269 123.423C85.0382 124.361 84.4685 125.214 83.7503 125.932C83.0321 126.65 82.1795 127.22 81.2411 127.609C80.3027 127.997 79.297 128.197 78.2813 128.197ZM86.6808 50.4088L84.461 97.5885C84.461 99.2295 83.8091 100.803 82.6488 101.964C81.4884 103.124 79.9146 103.776 78.2735 103.776C76.6325 103.776 75.0587 103.124 73.8983 101.964C72.7379 100.803 72.086 99.2295 72.086 97.5885L69.8663 50.4281C69.8164 49.3012 69.9939 48.1757 70.3882 47.1189C70.7826 46.062 71.3857 45.0953 72.1615 44.2765C72.9374 43.4577 73.8702 42.8034 74.9044 42.3528C75.9385 41.9021 77.0527 41.6643 78.1807 41.6535H78.2619C79.3976 41.6529 80.5216 41.8824 81.5662 42.3282C82.6107 42.7739 83.5541 43.4267 84.3394 44.2471C85.1248 45.0675 85.7357 46.0385 86.1355 47.1015C86.5352 48.1645 86.7155 49.2974 86.6653 50.432L86.6808 50.4088Z'
				fill='#842029'
			/>
		</svg>
	);
};

export default WarningIcon;
