import React, { FC, useEffect, useState } from 'react';
import Settings from 'src/components/svg/Settings';
import SSOSettingsModal from './SSOSettingsModal';
import SuiteModal from 'src/components/common/SuiteModal';
import DisableSSOModal from './DisableSSOModal';
import { AuthorizationMode, ISSOSettings, ISSOSettingsData, IUserGroup } from './SSOSettings.model';
import { initialSSOState } from 'src/modules/securitySettings/SSOSettings/SSOSettings.store';
import { NO_INDEX, SSOSettingsConstants } from 'src/helper/Constants';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { useDispatch } from 'react-redux';
import { AuthenticationProvider } from 'src/modules/userManagement/users/Users.model';
import * as Validation from 'src/helper/Validations';

interface SSOSettingsProps {
	getSingleSignOnSettings: (callback: (ssoSettings: ISSOSettings[]) => void) => void;
	updateSSOSettings: (ssoSettings: ISSOSettings[], resourceId: string, callback: () => void) => void;
	switchSingleSignOnSetting: (ssoEnable: boolean, resourceId: string, callback: () => void) => void;
	validateAzureADGroup: (keyList: string[], callback: (groupIDs: string[]) => void, resourceId: string) => void;
}

const SSOSettings: FC<SSOSettingsProps> = (props) => {
	const { getSingleSignOnSettings, updateSSOSettings, switchSingleSignOnSetting, validateAzureADGroup } = props;

	const [ssoSettings, setSSOSettings] = useState<ISSOSettings[]>(initialSSOState);
	const [groupIds, setGroupIds] = useState<string[]>([]);
	const [tenantIds, setTenantIds] = useState<string[]>([]);
	const [authorizationType, setAuthorizationType] = useState<AuthorizationMode[]>([]);
	const [groupIdExists, setGroupIdExists] = useState(true);
	const [tenantIdExists, setTenantIdExists] = useState(true);
	const [isValidGroupId, setIsValidGroupId] = useState(true);
	const [isValidTenantId, setIsValidTenantId] = useState(true);
	const [switched, setSwitched] = useState(false);
	const [inProgress, setInProgress] = useState(false);
	const dispatch = useDispatch();

	const [showDisableSSOModal, setShowDisableSSOModal] = useState(false);
	const [showSSOSettingsModal, setShowSSOSettingsModal] = useState(false);

	useEffect(() => {
		loadSSOData();
	}, []);

	const loadSSOData = () => {
		getSingleSignOnSettings((ssoSettingsReceivedFromAPI) => {
			initAllValues(ssoSettingsReceivedFromAPI);
		});
	};

	const initAllValues = (ssoSettings: ISSOSettings[]) => {
		setSSOSettings(ssoSettings);
		const ssoSettingsObject = ssoSettings[0];
		setSwitched(ssoSettingsObject.enabled);
		setGroupIdExists(
			ssoSettingsObject.authorizationType &&
				ssoSettingsObject.authorizationType.findIndex((x) => x == AuthorizationMode.AzureAdGroups) != NO_INDEX &&
				ssoSettingsObject.data &&
				(ssoSettingsObject.data.length > 0 ? true : false),
		);
		setTenantIdExists(
			ssoSettingsObject.data &&
				ssoSettingsObject.authorizationType &&
				ssoSettingsObject.authorizationType.findIndex((x) => x == AuthorizationMode.AzureTenantId) != NO_INDEX &&
				ssoSettingsObject.data.length > 0,
		);
		setAuthorizationType(ssoSettingsObject.authorizationType ? ssoSettingsObject.authorizationType : []);
		const groupIdList: string[] = [];
		const tenantIdList: string[] = [];
		const dataList = ssoSettingsObject.data;
		if (dataList) {
			dataList.map((eachData) => {
				if (
					eachData.authorizationType == AuthorizationMode.AzureAdGroups ||
					eachData.authorizationType == AuthorizationMode.None
				) {
					groupIdList.push(eachData.key);
				} else if (eachData.authorizationType == AuthorizationMode.AzureTenantId) {
					tenantIdList.push(eachData.key);
				}
			});
		}
		setGroupIds(groupIdList);
		setTenantIds(tenantIdList);
	};

	const handleToggleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const switched = e.target.checked;
		const showPopup =
			switched &&
			(authorizationType.length == 0 ||
				(!groupIdExists && authorizationType.findIndex((x) => x == AuthorizationMode.AzureAdGroups) != NO_INDEX) ||
				(!tenantIdExists && authorizationType.findIndex((x) => x == AuthorizationMode.AzureTenantId) != NO_INDEX));

		if (switched) {
			if (authorizationType.findIndex((x) => x == AuthorizationMode.AzureAdGroups) != NO_INDEX && groupIds.length > 0) {
				dispatch({
					type: loaderTypes.LOADING,
					show: true,
					text: 'Updating Single Sign On Settings...',
				});
				validateAzureADGroup(
					groupIds,
					(groupIds: string[]) => {
						if (groupIds && groupIds.length > 0) {
							dispatch({
								type: loaderTypes.LOADING,
								show: false,
								text: '',
							});
							dispatch({
								type: notificationTypes.NOTIFICATION,
								statusType: StatusType.Warning,
								statusMessage: SSOSettingsConstants.StatusMessage.ADAzureExist,
							});
						} else {
							setSwitched(switched);
							setShowSSOSettingsModal(showPopup);
							const newSSOSettings = ssoSettings.map((ssoSettingObject: ISSOSettings) => {
								if (ssoSettingObject.provider.toString() == AuthenticationProvider[AuthenticationProvider.AzureAD]) {
									ssoSettingObject.enabled = switched;
									ssoSettingObject.isModified = true;
								}
								return ssoSettingObject;
							});
							setSSOSettings(newSSOSettings);
							switchSingleSignOnSetting(true, 'SUITE_Secr_SnglSignOn_TglEnblOrDisbl', () => {
								loadSSOData();
							});
						}
					},
					'SUITE_Secr_SnglSignOn_TglEnblOrDisbl',
				);
			} else if (authorizationType.findIndex((x) => x == AuthorizationMode.SSRUserList) != NO_INDEX) {
				dispatch({
					type: loaderTypes.LOADING,
					show: true,
					text: 'Updating Single Sign On Settings...',
				});
				setSwitched(switched);
				setShowSSOSettingsModal(showPopup);
				const newSSOSettings = ssoSettings.map((ssoSettingObject: ISSOSettings) => {
					if (ssoSettingObject.provider.toString() == AuthenticationProvider[AuthenticationProvider.AzureAD]) {
						ssoSettingObject.enabled = switched;
						ssoSettingObject.isModified = true;
					}
					return ssoSettingObject;
				});
				setSSOSettings(newSSOSettings);
				switchSingleSignOnSetting(true, 'Suite_Secr_SnglSignOn_TglEnblOrDisbl', () => {
					loadSSOData();
				});
			} else if (authorizationType.findIndex((x) => x == AuthorizationMode.AzureTenantId) != NO_INDEX) {
				setSwitched(switched);
				dispatch({
					type: loaderTypes.LOADING,
					show: true,
					text: 'Updating Single Sign On Settings...',
				});
				setShowSSOSettingsModal(showPopup);
				const newSSOSettings = ssoSettings.map((ssoSettingObject: ISSOSettings) => {
					if (ssoSettingObject.provider.toString() == AuthenticationProvider[AuthenticationProvider.AzureAD]) {
						ssoSettingObject.enabled = switched;
						ssoSettingObject.isModified = true;
					}
					return ssoSettingObject;
				});
				setSSOSettings(newSSOSettings);
				switchSingleSignOnSetting(true, 'SUITE_Secr_SnglSignOn_TglEnblOrDisbl', () => {
					loadSSOData();
				});
			}
		} else {
			setShowDisableSSOModal(true);
		}
	};

	const handleSettingsClick = () => {
		setShowSSOSettingsModal(true);
	};

	const onDisableAlertHide = () => {
		setShowDisableSSOModal(false);
	};

	const onDisableAlertOk = () => {
		setShowDisableSSOModal(false);
		dispatch({
			type: loaderTypes.LOADING,
			show: true,
			text: 'Updating Single Sign On Settings...',
		});
		setSwitched(false);
		const newSSOSettings = ssoSettings.map((ssoSettingObject: ISSOSettings) => {
			if (ssoSettingObject.provider.toString() == AuthenticationProvider[AuthenticationProvider.AzureAD]) {
				ssoSettingObject.enabled = false;
				ssoSettingObject.isModified = true;
			}
			return ssoSettingObject;
		});
		setSSOSettings(newSSOSettings);
		switchSingleSignOnSetting(false, 'SUITE_Secr_SnglSignOn_TglEnblOrDisbl', () => {
			loadSSOData();
		});
	};

	const onHide = () => {
		setShowSSOSettingsModal(false);
		loadSSOData();
	};

	const saveSettings = () => {
		const providerData: ISSOSettingsData[] = [];

		groupIds.map((value, index) => {
			providerData.push({
				key: value,
				roles: [IUserGroup.Staff],
				authorizationType: AuthorizationMode.AzureAdGroups,
			});
		});

		tenantIds.map((value, index) => {
			providerData.push({
				key: value,
				roles: [IUserGroup.Staff],
				authorizationType: AuthorizationMode.AzureTenantId,
			});
		});

		let newSSOSettings = [];
		if (ssoSettings && ssoSettings.length > 0) {
			newSSOSettings = ssoSettings.map((ssoSettingsObject: ISSOSettings, index: number) => {
				if (ssoSettingsObject.provider.toString() == AuthenticationProvider[AuthenticationProvider.AzureAD]) {
					ssoSettingsObject.data = providerData;
					ssoSettingsObject.authorizationType = authorizationType;
					ssoSettingsObject.enabled = authorizationType.length > 0;
					ssoSettingsObject.isModified = true;
				}
				return ssoSettingsObject;
			});
		} else {
			const authProvider: ISSOSettings = {} as ISSOSettings;
			authProvider.isModified = true;
			authProvider.data = providerData;
			authProvider.provider = AuthenticationProvider.AzureAD;
			authProvider.authorizationType = authorizationType;
			authProvider.enabled = authorizationType.length > 0;
			newSSOSettings.push(authProvider);
		}

		updateSSOSettings(newSSOSettings, 'SUITE_Secr_SnglSignOn_TglEnblOrDisbl', () => {
			loadSSOData();
		});
	};

	const onSaveButtonClick = () => {
		setInProgress(true);

		if (authorizationType.length === 0) {
			setInProgress(false);
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: SSOSettingsConstants.Validate.SettingWarning,
			});
		} else if (
			groupIds.length == 0 &&
			authorizationType.findIndex((x) => x == AuthorizationMode.AzureAdGroups) != NO_INDEX
		) {
			setInProgress(false);
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: SSOSettingsConstants.Validate.GroupIdWarning,
			});
		} else if (
			tenantIds.length == 0 &&
			authorizationType.findIndex((x) => x == AuthorizationMode.AzureTenantId) != NO_INDEX
		) {
			setInProgress(false);
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: SSOSettingsConstants.Validate.TenantIdWarning,
			});
		} else if (
			groupIds.length > 0 &&
			authorizationType.findIndex((x) => x == AuthorizationMode.AzureAdGroups) != NO_INDEX
		) {
			validateAzureADGroup(
				groupIds,
				(groupIds: string[]) => {
					if (groupIds && groupIds.length > 0) {
						setInProgress(false);
						dispatch({
							type: notificationTypes.NOTIFICATION,
							statusType: StatusType.Warning,
							statusMessage: SSOSettingsConstants.StatusMessage.ADAzureExist,
						});
					} else {
						setInProgress(false);
						setShowSSOSettingsModal(false);
						dispatch({
							type: loaderTypes.LOADING,
							show: true,
							text: 'Updating Single Sign On Settings...',
						});
						saveSettings();
					}
				},
				'SUITE_Secr_SnglSignOn_TglEnblOrDisbl',
			);
		} else {
			setInProgress(false);
			setShowSSOSettingsModal(false);
			dispatch({
				type: loaderTypes.LOADING,
				show: true,
				text: 'Updating Single Sign On Settings...',
			});
			saveSettings();
		}
	};

	const onChangeGroupTagValue = (e: any, groupIds: string[]) => {
		let flag = false;
		for (let i = 0; i < groupIds.length; i++) {
			for (let j = i + 1; j < groupIds.length; j++) {
				if (groupIds[i] === groupIds[j]) flag = true;
			}
		}
		if (flag) {
			setShowSSOSettingsModal(false);
			setIsValidGroupId(false);
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: SSOSettingsConstants.Validate.GroupIdAlreadyAdded,
			});

			return false;
		}
		setGroupIds(groupIds);
		setIsValidGroupId(true);
	};

	const onChangeTenantTagValue = (e: any, tenantIds: string[]) => {
		let flag = false;
		for (let i = 0; i < tenantIds.length; i++) {
			for (let j = i + 1; j < tenantIds.length; j++) {
				if (tenantIds[i] === tenantIds[j]) flag = true;
			}
		}
		if (flag) {
			setShowSSOSettingsModal(false);
			setIsValidTenantId(false);
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: SSOSettingsConstants.Validate.TenantIdAlreadyAdded,
			});

			return false;
		}
		setTenantIds(tenantIds);
		setIsValidTenantId(true);
	};

	const onAuthorizationTypeChange = (authMode: AuthorizationMode) => {
		setAuthorizationType([authMode]);
		setShowSSOSettingsModal(true);
	};

	const onGroupValidate = (key: string[]) => {
		if (!Validation.isValidGuid(key[0]) && key[0] != '') {
			setIsValidGroupId(false);
		} else {
			setIsValidGroupId(true);
		}
	};

	const onTenantValidate = (key: string[]) => {
		if (!Validation.isValidGuid(key[0]) && key[0] != '') {
			setIsValidTenantId(false);
		} else {
			setIsValidTenantId(true);
		}
	};

	return (
		<article className='suitePageSection'>
			<h3 className='suitePageSectionHeader'>Single Sign On</h3>
			<p className='suitePageSectionDescription'>Activate Single Sign-On for Office 365.</p>
			<div className='switchSettingsBlock form-switch'>
				<input
					className='switchSettingsButton form-check-input'
					type='checkbox'
					id='defaultSignerButton'
					data-test-auto='bed6d75f-d680-44f3-ae6f-a13c0122cfc3'
					checked={switched}
					onChange={handleToggleSwitchChange}
					data-resource-id='SUITE_Secr_SnglSignOn_TglEnblOrDisbl'
				/>
				<span className='switchSettingsText'>Microsoft Office 365</span>
				<span
					className='switchSettingsIcon'
					onClick={handleSettingsClick}
					data-test-auto='7f4f1ba2-ddc2-41ac-9b28-df61cfea3e61'
					data-resource-id='SUITE_Secr_SnglSignOn_TglEnblOrDisbl'>
					<Settings />
				</span>
			</div>

			{showSSOSettingsModal && (
				<SuiteModal
					width='600'
					theme='light'
					title='Microsoft Office 365 Group Settings'
					hide={onHide}>
					<SSOSettingsModal
						onHide={onHide}
						onSaveButtonClick={onSaveButtonClick}
						authorizationType={authorizationType}
						onAuthorizationTypeChange={onAuthorizationTypeChange}
						onChangeGroupTagValue={onChangeGroupTagValue}
						onChangeTenantTagValue={onChangeTenantTagValue}
						groupIds={groupIds}
						tenantIds={tenantIds}
						onGroupValidate={onGroupValidate}
						onTenantValidate={onTenantValidate}
						isValidGroupKey={isValidGroupId}
						inProgress={inProgress}
					/>
				</SuiteModal>
			)}
			{showDisableSSOModal && (
				<SuiteModal
					width='600'
					theme='light'
					title='Disable'
					hide={() => setShowDisableSSOModal(false)}>
					<DisableSSOModal
						onDisableAlertHide={onDisableAlertHide}
						onDisableAlertOk={onDisableAlertOk}
					/>
				</SuiteModal>
			)}
		</article>
	);
};

export default SSOSettings;
