import React from 'react';

const DollarIcon: React.FC = () => {
	return (
		<svg
			width='17'
			height='16'
			viewBox='0 0 17 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_4603_24472)'>
				<rect
					width='16'
					height='16'
					transform='translate(0.5)'
					fill='white'
					fillOpacity='0.01'
				/>
				<path
					d='M4.5 10.78C4.648 12.447 6.013 13.63 8.091 13.783V14.999H9.134V13.783C11.404 13.604 12.812 12.345 12.812 10.483C12.812 8.89302 11.865 7.97302 9.856 7.45502L9.134 7.26802V3.46602C10.256 3.57602 11.013 4.18002 11.204 5.08202H12.674C12.508 3.48202 11.134 2.33402 9.134 2.20702V0.999023H8.091V2.23202C6.152 2.46202 4.821 3.70402 4.821 5.38802C4.821 6.84202 5.787 7.87102 7.482 8.30502L8.092 8.46702V12.498C6.943 12.328 6.152 11.698 5.961 10.78H4.5ZM7.891 6.94402C6.848 6.68102 6.291 6.11902 6.291 5.32802C6.291 4.38402 6.995 3.68702 8.091 3.50002V6.99502L7.891 6.94502V6.94402ZM9.482 8.81602C10.769 9.13902 11.334 9.67502 11.334 10.585C11.334 11.682 10.508 12.413 9.134 12.524V8.72902L9.482 8.81502V8.81602Z'
					fill='white'
				/>
			</g>
			<defs>
				<clipPath id='clip0_4603_24472'>
					<rect
						width='16'
						height='16'
						fill='white'
						transform='translate(0.5)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default DollarIcon;
