import { actionTypes } from "./dropdown-list.actions";
import { Reducer } from "redux";
import { DispatchAction,  dropdownListData } from "./dropdown-list.types";

const unloadedState: dropdownListData = {
    roles: [],
} as dropdownListData;

export const reducer: Reducer<dropdownListData> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    
    switch (action.type) {
        case actionTypes.REQUEST_ROLE_DROPDOWN_LIST:
            return {                
                roles: [...state.roles],                
            } as dropdownListData;
        case actionTypes.RECEIVE_ROLE_DROPDOWN_LIST:
            return {
                roles: action.roleList,
            } as dropdownListData;
            default:
        }
    
        return state;
};