import { useState } from 'react';

import { type ICompanyWhiteLogoHookReturn, type ICompanyWhiteLogoProps } from './company-white-logo.types';

const useCompanyWhiteLogo = (props: ICompanyWhiteLogoProps): ICompanyWhiteLogoHookReturn => {
	const {
		whiteLogoPath,
		isWhiteLogoLoading,
		deleteCompanyWhiteLogo,
		requestCompanyWhiteLogoUploadLink,
		whiteLogoUploadLink,
		getCompanyWhiteLogo,
		updateTempCompanyWhiteLogo,
	} = props;
	const [showWhiteLogoDeletePopup, setShowWhiteLogoDeletePopup] = useState(false);
	const [showLogoEditPopup, setShowLogoEditPopup] = useState(false);

	return {
		setShowLogoEditPopup,
		setShowWhiteLogoDeletePopup,
		showLogoEditPopup,
		showWhiteLogoDeletePopup,
		whiteLogoPath,
		isWhiteLogoLoading,
		deleteCompanyWhiteLogo,
		requestCompanyWhiteLogoUploadLink,
		whiteLogoUploadLink,
		getCompanyWhiteLogo,
		updateTempCompanyWhiteLogo,
	};
};

export { useCompanyWhiteLogo };
