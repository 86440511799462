import { Action, Reducer } from 'redux';
import { produce } from 'immer';
import { DispatchActions, IUserLoginHistoryResponse, IWidgetData, IWidgetDropdownItem } from './common.types';
import { actionTypes } from './common.actions';
import { IMenuSection } from '@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu';
import { RouteConstants } from '../../helper/Constants';

export interface ICommonState {
    loginHistory: IUserLoginHistoryResponse;
    loginHistoryLoading: boolean;
    widgetData: IWidgetData[];
    sidemenuData: IMenuSection[];
    isResetCacheApiCalled: boolean;
    isSideMenuDataLoaded: boolean;
    isWidgetMenuDataLoaded: boolean;
}

const unloadedState: ICommonState = {
    loginHistory: { count: 0, deviceId: '', loginHistory: [] },
    loginHistoryLoading: false,
    widgetData: [],
    sidemenuData: [],
    isResetCacheApiCalled: false,
    isSideMenuDataLoaded: false,
    isWidgetMenuDataLoaded: false
};

export function IsActiveAccountManagement(route: string) {
    if (
        RouteConstants.COMPANY_MANAGEMENT === route ||
        RouteConstants.BRANDING_MANAGEMENT === route ||
        RouteConstants.USER_MANAGEMENT === route ||
        RouteConstants.USER_PERMISSION === route ||
        RouteConstants.PAYMENT_HISTORY === route ||
        RouteConstants.USAGE_REPORT === route ||
        RouteConstants.CLIENT_MANAGEMENT === route){
        return true;
    }
    else {
        return false
    }
}


export const reducer: Reducer<ICommonState> = produce(
    (draftState: ICommonState = unloadedState, incomingAction: Action) => {
        const action = incomingAction as DispatchActions;
        switch (action.type) {
            case actionTypes.REQUEST_LOGIN_HISTORY:
                draftState.loginHistoryLoading = true;
                return draftState;
            case actionTypes.RECEIVE_LOGIN_HISTORY:
                draftState.loginHistory = action.payload;
                draftState.loginHistoryLoading = false;
                return draftState;
            case actionTypes.COMPLETE_LOGIN_HISTORY:
                draftState.loginHistoryLoading = false;
                return draftState;
            case actionTypes.RECEIVE_WIDGET_DATA:
                draftState.widgetData = action.payload;
                draftState.isWidgetMenuDataLoaded = true;
                return draftState;
            case actionTypes.RECEIVE_SIDEMENU_DATA:
                draftState.sidemenuData = action.payload;
                draftState.isSideMenuDataLoaded = true;
                return draftState;
            case actionTypes.RESET_CACHE_API_CALLED:
                draftState.isResetCacheApiCalled = true;
                return draftState;
            case actionTypes.CHANGE_ACTIVE_STATE:
                draftState.widgetData.forEach((widgetItem: IWidgetData) => {
                    if (widgetItem.caption === 'TOOLS') {
                        widgetItem.items.forEach((widgetDropdownItem: IWidgetDropdownItem) => {
                            if (
                                IsActiveAccountManagement(action.route) &&
                                widgetDropdownItem.caption === 'Account Management'
                            ) {
                                widgetDropdownItem.active = true;
                            } else if (
                                (action.route === RouteConstants.GENERAL_SETTING || action.route === RouteConstants.SECURITY_SETTING) &&
                                widgetDropdownItem.caption === 'Suite Settings'
                            ) {
                                widgetDropdownItem.active = true;
                            } else if (
                                action.route === RouteConstants.DEVELOPER_SECTION &&
                                widgetDropdownItem.caption === "Developer Section"
                            ) {
                                widgetDropdownItem.active = true;
                            }
                            else {
                                widgetDropdownItem.active = false;
                            }
                        });
                    }
                });
                return draftState;
        }
        return draftState;
    }
);
