import { API_BASE_URL } from "src/helper/Constants";
import axios, { AxiosResponse } from "axios";
import { logger } from 'src/oidcClient/authProvider';
import { IUserGroupAuditGridModel } from "./UserGroupAudit";
import { userTabConstants } from '../../../../helper/rbac-constants';

export function getUserGroupAuditData(userId:number, pageNo:number, pageSize:number, sortColumn:string, sortDirection:string, callback: (data: IUserGroupAuditGridModel) => void) {
    axios.get(API_BASE_URL + "/api/user-group/user-group-audit?userId=" + userId + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortColumn=" + sortColumn + "&sortDirection=" + sortDirection,
    {
        headers: { 'X-Resource-Id': `${userTabConstants.prefix}_${userTabConstants.grid}_${userTabConstants.auditReport}` },
    })
        .then((response: AxiosResponse) => {
            callback(response.data);
        }).catch((error) => {
            logger && logger.trackWarning('Error while getUserGroupAuditData', { 'Error': error?.response?.data});
        });
}