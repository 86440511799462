import React, { useState } from 'react';
import GripVertical from 'src/components/svg/GripVertical';
import { IDisabledDnDItems } from 'src/modules/company-management/partials/save-office-location-modal/save-office-location-modal.types';

import { IDragAndDropData } from '../DragAndDrop.model';
import styles from './DraggableList.module.scss';

interface DraggableListProps<T1 extends string, T2, T3 = undefined, T4 = null> {
	listData: IDragAndDropData<T1, T2, T3 | undefined>[];
	displayError?: boolean;
	errorText?: string;
	disableSelection?: boolean;
	data?: T4;
	disabledTitle?: string;
	disableDestinationItems?: IDisabledDnDItems[];
}

function DraggableList<T1 extends string, T2, T3 = undefined, T4 = null>(props: DraggableListProps<T1, T2, T3, T4>) {
	const {
		listData = [],
		displayError = false,
		errorText = '',
		disableSelection = false,
		data = 0,
		disabledTitle = '',
		disableDestinationItems,
	} = props;

	const [selectedList, setSelectedList] = useState<any>([]);

	const dragStart = (e: any, data: any) => {
		const newSelectedList = [...selectedList, data];
		setSelectedList([...newSelectedList]);

		e.dataTransfer.setData('text/plain', JSON.stringify(newSelectedList));
	};

	const dragEnd = () => {
		setSelectedList([]);
	};

	const handleClick = (data: any) => {
		let newSelectedList: any = [];
		if (selectedList.findIndex((listDatum: any) => listDatum.value === data.value) === -1) {
			newSelectedList = [...selectedList, data];
		} else {
			newSelectedList = selectedList.filter((datum: any) => datum.value !== data.value);
		}
		setSelectedList([...newSelectedList]);
	};

	const sortAlphabetically = (
		a: IDragAndDropData<T1, T2, T3 | undefined>,
		b: IDragAndDropData<T1, T2, T3 | undefined>,
	) => {
		if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
		if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
		return 0;
	};
	const checkDisablity = (datum: IDragAndDropData<T1, T2, T3 | undefined>) =>
		disableSelection &&
		datum.prop3 &&
		((typeof datum.prop3 === 'string' &&
			datum.prop3?.split(',').length === 1 &&
			datum.prop3?.split(',')[0]?.trim() === data?.toString()) ||
			(typeof datum.prop3 === 'string' && datum.prop3 === data?.toString()) ||
			(typeof datum.prop3 === 'number' && datum.prop3 === data));
	return (
		<div className={styles.draggableListContainer}>
			{displayError ? (
				<div className={styles.error_div}>{errorText}</div>
			) : (
				listData.sort(sortAlphabetically).map((datum: IDragAndDropData<T1, T2, T3 | undefined>, index: number) => {
					let elementTitleInDisabledItems = '';
					let isElementInDisabledItems = false;
					disableDestinationItems?.forEach((eachDisabledItem: IDisabledDnDItems) => {
						if (eachDisabledItem.id === datum.value) {
							isElementInDisabledItems = true;
							elementTitleInDisabledItems = eachDisabledItem.disabledTitle;
						}
					});
					const isDisabled = checkDisablity(datum) || isElementInDisabledItems;
					let title = '';
					if (isDisabled) {
						if (isElementInDisabledItems) title = elementTitleInDisabledItems ?? datum.name;
						else title = disabledTitle ?? datum.name;
					} else title = datum.name;
					return (
						<div
							className={`${styles.draggableListCard} ${
								selectedList.findIndex((listItem: any) => datum.value === listItem.value) !== -1
									? styles.selectedCard
									: ''
							} ${isDisabled ? styles.disabled : ''} `}
							onDragStart={(e) => dragStart(e, datum)}
							onDragEnd={() => dragEnd()}
							onClick={() => (isDisabled ? null : handleClick(datum))}
							data-test-id={`${datum.name}-${datum.value}`}
							key={`${datum.value}${index}`}
							draggable={isDisabled ? false : true}
							title={title}>
							<div className={styles.gripContainer}>
								<GripVertical />
							</div>
							<div className={styles.contentContainer}>{datum.name.slice(0, 50)}</div>
						</div>
					);
				})
			)}
		</div>
	);
}

export default DraggableList;
