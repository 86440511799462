import React, { FC } from 'react';

const LeftAngleIcon: FC = () => {
	return (
		<svg
			width='8'
			height='14'
			viewBox='0 0 8 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.85294 0.645185C7.8995 0.691631 7.93645 0.746806 7.96165 0.807551C7.98686 0.868296 7.99983 0.933418 7.99983 0.999185C7.99983 1.06495 7.98686 1.13007 7.96165 1.19082C7.93645 1.25156 7.8995 1.30674 7.85294 1.35318L2.20594 6.99918L7.85294 12.6452C7.94683 12.7391 7.99957 12.8664 7.99957 12.9992C7.99957 13.132 7.94683 13.2593 7.85294 13.3532C7.75905 13.4471 7.63172 13.4998 7.49894 13.4998C7.36616 13.4998 7.23883 13.4471 7.14494 13.3532L1.14494 7.35318C1.09838 7.30674 1.06143 7.25156 1.03623 7.19082C1.01102 7.13007 0.998047 7.06495 0.998047 6.99918C0.998047 6.93342 1.01102 6.8683 1.03623 6.80755C1.06143 6.74681 1.09838 6.69163 1.14494 6.64518L7.14494 0.645185C7.19139 0.598622 7.24656 0.561679 7.30731 0.536472C7.36805 0.511266 7.43317 0.498291 7.49894 0.498291C7.56471 0.498291 7.62983 0.511266 7.69057 0.536472C7.75132 0.561679 7.80649 0.598622 7.85294 0.645185Z'
				fill='#0973BA'
			/>
		</svg>
	);
};

export default LeftAngleIcon;
