import React from 'react'

const DeactivateIcon: React.FC = () => {
    return (
        <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_6256_4319)'>
                <rect width='16' height='16' transform='translate(0.5 0.5)' fill='white' fill-opacity='0.01' />
                <path d='M8.00036 1.49902V8.49902H9.00036V1.49902H8.00036Z' fill='#05386B' />
                <path d='M3.50036 9.31102C3.50012 8.41799 3.73911 7.5412 4.19248 6.7718C4.64585 6.00241 5.29704 5.36852 6.07836 4.93602L5.59336 4.06202C4.43389 4.70409 3.51964 5.712 2.99334 6.9284C2.46704 8.14481 2.35831 9.50124 2.68412 10.7859C3.00994 12.0707 3.75197 13.2113 4.79436 14.0299C5.83675 14.8485 7.12085 15.2988 8.44617 15.3107C9.7715 15.3226 11.0635 14.8953 12.1204 14.0956C13.1773 13.2959 13.9397 12.1687 14.2885 10.8901C14.6373 9.61141 14.5529 8.25325 14.0485 7.0276C13.5441 5.80194 12.6481 4.77779 11.5004 4.11502L10.9994 4.98002C11.7596 5.41871 12.3909 6.04979 12.83 6.80982C13.269 7.56986 13.5002 8.43207 13.5004 9.30979C13.5005 10.1875 13.2696 11.0498 12.8309 11.81C12.3922 12.5702 11.7611 13.2015 11.001 13.6405C10.2409 14.0795 9.3787 14.3106 8.50098 14.3107C7.62326 14.3108 6.76098 14.0799 6.0008 13.6411C5.24063 13.2023 4.60936 12.5712 4.17044 11.8111C3.73151 11.051 3.50041 10.1887 3.50036 9.31102Z' fill='#05386B' />
            </g>
            <defs>
                <clipPath id='clip0_6256_4319'>
                    <rect width='16' height='16' fill='white' transform='translate(0.5 0.5)' />
                </clipPath>
            </defs>
        </svg>

    )
}

export default DeactivateIcon;