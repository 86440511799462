import React from "react";

export const ImportIcon = (style?: React.CSSProperties)=> {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={style ? style : {}}>
        <g clipPath="url(#clip0_3259_41663)">
            <path fill="#fff" fillOpacity="0.01" d="M0 0H16V16H0z"></path>
        <g fill="currentColor" fillRule="evenodd" clipPath="url(#clip1_3259_41663)" clipRule="evenodd">
                <path d="M3.5 5.999a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-8a.5.5 0 00-.5-.5h-2a.5.5 0 010-1h2a1.5 1.5 0 011.5 1.5v8a1.5 1.5 0 01-1.5 1.5h-9a1.5 1.5 0 01-1.5-1.5v-8a1.5 1.5 0 011.5-1.5h2a.5.5 0 110 1h-2z"></path>
                <path d="M7.646.145a.5.5 0 01.708 0l3 3a.5.5 0 01-.708.708L8.5 1.706v8.793a.5.5 0 01-1 0V1.706L5.354 3.853a.5.5 0 01-.708-.708l3-3z"></path>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_3259_41663">
                <path fill="#fff" d="M0 0H16V16H0z"></path>
            </clipPath>
            <clipPath id="clip1_3259_41663">
                <path fill="#fff" d="M0 0H16V16H0z"></path>
            </clipPath>
        </defs>
    </svg>
};

export default ImportIcon;
