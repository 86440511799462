import { type IHeaderProps } from '@sssuite-component-ui/ss-suite-layout/dist/Header/Header';
import { type IMenuSection, type ISideMenuProps } from '@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu';
import { type NavigateFunction, useLocation } from 'react-router-dom';

import { HeaderConstants, MenuHeaderConstants, ProductType, RouteConstants } from '../helper/Constants';
import { type IHeaderModal } from '../pages/common/common.types';
import { ICompanyData } from '../pages/common/companySettings/companySettings.types';

export const initialHeaderConfig: IHeaderProps = {
	productName: ProductType.SSSuitePortal,
	companyName: '',
	onClickProductLogo: () => null,
	profileDropDown: {
		dropdownHeaderTitle: '',
		dropdownHeader: '',
		onClickLogout: () => { },
		profileDropDownItems: [],
	},
	widgetData: {
		// THE BELOW FUNCTION WILL BE REMOVED ONCE IT IS MADE OPTIONAL
		onBackClick: () => { },
		hideBackIcon: true,
		dropDownData: [
			{
				caption: HeaderConstants.SS_SUITE,
				items: [],
			},
			{
				caption: HeaderConstants.TOOLS,
				items: [
					{
						caption: HeaderConstants.ACCOUNT_MANAGEMENT,
						title: HeaderConstants.ACCOUNT_MANAGEMENT,
						route: '/user-management',
					},
					{
						caption: HeaderConstants.HELP,
						title: HeaderConstants.HELP,
						route: '',
					},
				],
			},
		],
	},
};

export const headerConfig = (
	showModal: IHeaderModal,
	setShowModal: React.Dispatch<React.SetStateAction<IHeaderModal>>,
	navigate: any,
	isDarkTheme: boolean,
	logoPath: string,
	isSsrLogo: boolean,
	user: any,
	userProfile: any,
	widgetData: any,
	locationPath: string,
	hideHeader: boolean,
	companyData: ICompanyData,
): IHeaderProps => {
	const { firstName } = userProfile;
	const company_name = companyData.companyProfile.companyInfo.companyName;
	const isWelcomePage = locationPath === '/';

	if (!isSsrLogo && logoPath)
		return {
			isDarkTheme,
			productName: ProductType.SSSuitePortal,
			companyLogo: logoPath,
			hideHeader: hideHeader,
			onClickProductLogo: () => {
				if (isWelcomePage) {
				} else navigate('/');
			},
			profileDropDown: {
				dropdownHeaderTitle: firstName?.trim(),
				dropdownHeader: `Hi, ${firstName?.trim()}!`,
				onClickLogout: () => {
					setShowModal({ ...showModal, logout: true });
				},
				profileDropDownItems: [
					{
						caption: HeaderConstants.MY_ACCOUNT,
						onClick: () => {
							setShowModal({ ...showModal, myAccount: true });
						},
					},
					{
						caption: HeaderConstants.LOGIN_HISTORY,
						onClick: () => {
							setShowModal({ ...showModal, loginHistory: true });
						},
					},
				],
			},
			widgetData: {
				onBackClick: () => {
					if (isWelcomePage) {
					} else window.open('/', '_blank');
				},
				hideBackIcon: true,
				dropDownData: widgetData,
				onNavigate(route) {
					navigate(route);
				},
			},
		};

	return {
		isDarkTheme,
		productName: ProductType.SSSuitePortal,
		companyName: company_name,
		hideHeader: hideHeader,
		onClickProductLogo: () => {
			if (isWelcomePage) {
			} else navigate('/');
		},
		profileDropDown: {
			dropdownHeaderTitle: firstName?.trim(),
			dropdownHeader: `Hi, ${firstName?.trim()}!`,
			onClickLogout: () => {
				setShowModal({ ...showModal, logout: true });
			},
			profileDropDownItems: [
				{
					caption: HeaderConstants.MY_ACCOUNT,
					onClick: () => {
						setShowModal({ ...showModal, myAccount: true });
					},
				},
				{
					caption: HeaderConstants.LOGIN_HISTORY,
					onClick: () => {
						setShowModal({ ...showModal, loginHistory: true });
					},
				},
			],
		},
		widgetData: {
			onBackClick: () => {
				if (isWelcomePage) {
				} else window.open('/', '_blank');
			},
			hideBackIcon: true,
			dropDownData: widgetData,
			onNavigate(route) {
				navigate(route);
			},
		},
	};
};

export const sideMenuConfig = (arr: IMenuSection[], navigate: NavigateFunction): ISideMenuProps => {
	const topMenuData: IMenuSection[] = [];
	const bottomMenuData: IMenuSection[] = [];
	const location = useLocation();

	arr.forEach((x: IMenuSection) => {
		if (x.items?.length && x.items[0]?.isAtBottomOfLeftMenu) bottomMenuData.push(x);
		else {
			x.items.forEach((item: any) => {
				if (item?.items?.findIndex((ele: any) => ele.route === location.pathname) !== -1) {
					topMenuData.push({ section: 1, items: [item] });
				} else if (
					[RouteConstants.GRANT, RouteConstants.DENY].includes(location.pathname) &&
					item.caption === MenuHeaderConstants.ACCOUNT_MANAGEMENT
				) {
					topMenuData.push({ section: 1, items: [item] });
				}
			});
		}
	});

	return {
		isMenuFixed: true,
		hasButton: false,
		buttonText: '',
		buttonIcon: '',
		topMenuData,
		bottomMenuData,
		onButtonClick: () => null,
		onNavigate: (route: string) => {
			navigate(route);
		},
		isMenuToggled: false,
	};
};
