import React, {
    FC, useEffect,
    useState
} from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LoginHistoryTable from "../../../components/common/loginHistory/loginHistoryTable";
import CustomToggleSwitch from "../../../components/common/ui/switch/customToggleSwitch";
import { LoginHistoryConstants } from "../../../helper/Constants";
import { ApplicationState } from "../../../store";
import { fetchLastLoginEnable, updateLastLoginEnable } from "../userSettings/userSettings.apis";

interface ILoginHistoryProps {
    show: boolean;
    onHide: () => void;
}

const LoginHistory: FC<ILoginHistoryProps> = ({ show, onHide }) => {
    const [enablePrompt, setEnablePrompt] = useState(false);
    const dispatch = useDispatch();
    const { enableLastLogin } = useSelector((state: ApplicationState) => state.userSettingsState.defaultSettings);
    const handleCancel = () => {
        onHide();
    };
    const handleToggleChange = (enable: boolean) => {
        dispatch(updateLastLoginEnable(!enable));
        setEnablePrompt(!enable);
    };
    useEffect(() => {
        setEnablePrompt(enableLastLogin);
    }, [enableLastLogin]);
    useEffect(() => {
        if (show) {
            dispatch(fetchLastLoginEnable());
        }
    }, [show]);
    return <Modal show={show} onHide={handleCancel} size="xl" className="login-history">
        <Modal.Header closeButton closeVariant="white">{LoginHistoryConstants.TITLE}</Modal.Header>
        <Modal.Body>
            <LoginHistoryTable />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
            <div>
                <CustomToggleSwitch
                    switched={enablePrompt}
                    handleChange={() => handleToggleChange(enablePrompt)}
                />
                <span className="ms-2">{LoginHistoryConstants.PROMPT_TEXT}</span>
            </div>
            <Button onClick={handleCancel}>Close</Button>
        </Modal.Footer>
    </Modal>;
};

export default LoginHistory;