import { actionTypes } from './resource-validator.action';
import { Reducer } from 'redux';

const unloadedState: any = {
    data: []
};

export const reducer: Reducer<any> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as any;
    switch (action.type) {
        case actionTypes.SAVE_RESOURCE_IDS:
            return {
                data: action.payload,
            } as any;

        
        default: 
    }
        
    
        return state;
};