import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { EventServiceStatus, IClientAPIDetail, initialClientAPIDetail } from '../developer-section.model';
import { EventsAPIAuthTypes } from '../event-api-authtypes';
import { AppNotifier as VenusNotifier } from '../../../helper/AppNotifier';
import { EventSettingsConstants } from '../../../helper/Constants';
import { developersectionResources } from '../../../helper/rbac-constants'
import styles from './index.module.scss';
import CloseIcon from 'src/components/svg/CloseIcon';

interface EventsAPIModalProps {
    show: boolean;
    eventAPI: IClientAPIDetail;
    onToggleAPISettingsModal(show: boolean) : any;
    onTestConnection(apiLink: string, authCred: string, callback: (eventServiceStatus: EventServiceStatus) => void): void;
    onAddDocumentEventAPI(clientAPIDetail: IClientAPIDetail, resourceId?: string): void;
    parentResourceIdentifier: string;
}

interface EventsAPIModalState {
    eventAPI: IClientAPIDetail;
    testConnectionStatus: EventServiceStatus;
}

export class EventsAPIModal extends React.Component<EventsAPIModalProps, EventsAPIModalState> {

    constructor(props: EventsAPIModalProps) {
        super(props);

        this.state = {
            eventAPI: initialClientAPIDetail,
            testConnectionStatus: EventServiceStatus.Unknown
        }
    }


    UNSAFE_componentWillReceiveProps(props: EventsAPIModalProps) {
        this.setState({ eventAPI: props.eventAPI,testConnectionStatus: EventServiceStatus.Unknown });
    }

    private resetTestConnectionStatus = () => {
        this.setState({
            testConnectionStatus : EventServiceStatus.Unknown
        });    
    }

    private handleTestConnectionCallback = (eventServiceStatus: EventServiceStatus) => {
        this.setState({ testConnectionStatus: eventServiceStatus });
    }

    private handleTestConnection = () => {
        if(this.validateForm()){
        const { apiUrl, authCred } = this.state.eventAPI;

        this.setState({ testConnectionStatus: EventServiceStatus.Loading });

        this.props.onTestConnection(apiUrl,
            authCred,
            (eventServiceStatus: EventServiceStatus) => { this.handleTestConnectionCallback(eventServiceStatus) });
    }
}


    private handleApiEndpointChange = (event: any) => {
        this.setState({
            eventAPI: {
                ...this.state.eventAPI,
                apiUrl: event.target.value
            }
        }, () => { this.resetTestConnectionStatus(); });
    }

    private handleApiAuthCredChange = (event: any) => {
        this.setState({
            eventAPI: {
                ...this.state.eventAPI,
                authCred: event.target.value
            }
        }, () => { this.resetTestConnectionStatus(); });
    }

    private handleApiAuthTypeChange = (event: any) => {
        this.setState({
            eventAPI: {
                ...this.state.eventAPI,
                authType: event.target.value
            }
        }, () => { this.resetTestConnectionStatus(); });
    }

    private handleUniqueArgsChange = (event: any) => {
        this.setState({
            eventAPI: {
                ...this.state.eventAPI,
                uniqueArgs: event.target.value
            }
        });
    }

    private handleClose = () => {        
        this.setState({
            eventAPI: initialClientAPIDetail
        }, () => {
            this.props.onToggleAPISettingsModal(false);
        });
    }
    private isValidUrl = (urlString:any) =>  {
        var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
                                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
                                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate ip (v4) address
                                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
                                    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
                                    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
     return !!urlPattern.test(urlString);
    }

    private validateForm = () => {       

        if(!this.state.eventAPI.apiUrl){
            VenusNotifier.Error(EventSettingsConstants.ApiEndpointBlank);
            return false;
        }

        if(!this.isValidUrl(this.state.eventAPI.apiUrl)){
            VenusNotifier.Error(EventSettingsConstants.ApiEndpointInvalid);
            return false;
        }

        if(!this.state.eventAPI.authCred){
            VenusNotifier.Error(EventSettingsConstants.ApiKeyBlank);
            return false;
        }      

        return true;
    }

    private handleSave = () => {
        if(this.validateForm()){
        this.props.onAddDocumentEventAPI(this.state.eventAPI, this.props.parentResourceIdentifier + developersectionResources.APISettings + developersectionResources.ButtonEdit);
        this.resetTestConnectionStatus();
    }
}

    public render() {
        return (<Modal className={styles.api_endpoint_modal}
            show={this.props.show}
            onHide={this.handleClose}>

            <Modal.Header>
                <div>
                    <Modal.Title>                   
                        {EventSettingsConstants.ConfigureEndPoint}
                    </Modal.Title>
                </div>
                <div
					data-test-auto='e2ab6c41-b6f5-4181-b38f-3455585b2142'
					className={styles.close_icon}
					onClick={this.handleClose}>
					<CloseIcon />
				</div>      
            </Modal.Header>

            <Modal.Body>
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-xl-12'>

                        <div className="row mb-2">
                            <div className="col-lg-6">
                                <label className='fw-semibold'>
                                {EventSettingsConstants.ApiEndPointLabel}
                                </label>
                                <span className={styles.asterisk}>*</span>
                            </div>

                            <div className="col-lg-12">
                                <input type="text"
                                    className= "form-control"
                                    placeholder={EventSettingsConstants.ApiEndPointPlaceholder}
                                    value={this.state.eventAPI.apiUrl}
                                    data-lpignore="true"
                                    onChange={this.handleApiEndpointChange}
                                />
                            </div>
                        </div>

                        <EventsAPIAuthTypes
                            authCred={this.state.eventAPI.authCred}
                            authType={this.state.eventAPI.authType}
                            onTestConnection={this.handleTestConnection}
                            testConnectionStatus={this.state.testConnectionStatus}
                            onApiAuthCredChange={this.handleApiAuthCredChange}
                            onApiAuthTypeChange={this.handleApiAuthTypeChange} />

                        <div className="row mb-2">
                            <div className="col-lg-6">
                                <label className='fw-semibold'>{EventSettingsConstants.UniqueArgumentLabel}</label>
                            </div>
                                
                            <div className="col-lg-12">
                                <textarea rows={8}
                                    className= "form-control"
                                    placeholder={EventSettingsConstants.UniqueArgumentPlaceholder}
                                    value={this.state.eventAPI.uniqueArgs}
                                    data-lpignore="true" onChange={this.handleUniqueArgsChange} />
                            </div>
                        </div>

                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer className="clearfix">
                <Button
                    data-test-auto="975B65F0-0BC5-4EB6-B224-A36D7E42151B"
                    variant='outline-secondary'
                    className='btn-white'
                    onClick={this.handleClose}>
                    {EventSettingsConstants.Close}
                </Button>
                <Button
                    variant='primary'
                    data-resource-id = { this.props.parentResourceIdentifier + developersectionResources.APISettings +developersectionResources.ButtonEdit}
                    onClick={this.handleSave}
                    data-test-auto="1F1EBE5A-7B39-4065-9447-919D20FB9502">
                   {EventSettingsConstants.Save}
                </Button>
            </Modal.Footer>
        </Modal>);
    }

}

export default EventsAPIModal;
