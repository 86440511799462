/* eslint-disable max-statements */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { type FC, useEffect, useState } from 'react';
import { Col, Form, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import ButtonWithTextIcon from 'src/components/common/button-with-text-icon';
import CloseIcon from 'src/components/svg/CloseIcon';
import { myCompanyResources } from 'src/helper/rbac-constants';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { actionTypes as notificationActionTypes } from 'src/pages/common/notification/notification.types';

import styles from './edit-primary-admin-modal.module.scss';
import { type IEditPrimaryAdmin } from './edit-primary-admin-modal.types';
import { logger } from 'src/oidcClient/authProvider';

const EditAdminModal: FC<IEditPrimaryAdmin> = (props) => {
	const { show, handleClose, userList, currentAdmin, companyInfo, updatePrimaryAdmin, requestCompanyProfile } = props;

	const [users, setUsers] = useState([]);
	const [primaryAdmin, setPrimaryAdmin] = useState({});
	const [primaryAdminData, setPrimaryAdminData] = useState({});

	const dispatch = useDispatch();

	const getUserDrowpDown = (list) => {
		const nameList = list?.map((record) => ({
			value: `${record.firstName} ${record.lastName}`,
			label: `${record.firstName} ${record.lastName}`,
			id: record.id,
		}));
		setPrimaryAdmin(nameList?.find((list) => list.id === currentAdmin?.id));
		setPrimaryAdminData(userList?.find((list) => list.id === currentAdmin?.id));
		return nameList;
	};

	useEffect(() => {
		setUsers(getUserDrowpDown(userList));
	}, [userList]);

	const handleAdminChange = (e) => {
		if (!e) {
			const clearedData = {
				id: -1,
				value: '',
				label: 'Select...',
			};
			setPrimaryAdmin(clearedData);
			setPrimaryAdminData(null);
			return;
		}

		const newAdmin = users.find((list) => list.id === e.id);
		const newAdminData = userList.find((list) => list.id === e.id);
		setPrimaryAdmin(newAdmin);
		setPrimaryAdminData(newAdminData);
	};

	const handleAdminUpdate = () => {
		if (!primaryAdminData) {
			dispatch({
				type: notificationActionTypes.NOTIFICATION,
				statusMessage: 'Please select a user from the list.',
				statusType: StatusType.Warning,
			});
			return;
		}

		const changed = primaryAdmin.id !== currentAdmin.id;
		const isMobileAvaialble = primaryAdminData?.mobileNumber.length > 0;

		if (changed) {
			if (!isMobileAvaialble) {
				dispatch({
					type: notificationActionTypes.NOTIFICATION,
					statusMessage: 'Mobile Number cannot be empty.',
					statusType: StatusType.Warning,
				});
				return;
			}

			try {
				logger && logger.trackTrace('handleAdminUpdate: Updating primary admin');
				updatePrimaryAdmin(
					primaryAdmin.id,
					`${myCompanyResources.prefix}_${myCompanyResources.primaryAdmin}_${myCompanyResources.editButton}`,
					() => {
						dispatch(requestCompanyProfile());
						handleClose();
					},
				);
			} catch (error) {
				logger && logger.trackWarning('handleAdminUpdate: Error Updating primary admin', { 'Error': error.message });
			}
		} else
			dispatch({
				type: notificationActionTypes.NOTIFICATION,
				statusMessage: 'There are no changes to save.',
				statusType: StatusType.Warning,
			});
	};

	return (
		<Modal
			show={show}
			onHide={handleClose}
			className={styles.edit_primary_admin_wrapper}>
			<Modal.Header className={styles.modal_header}>
				<div>
					<Modal.Title>Update Primary Admin</Modal.Title>
				</div>
				<div
					data-test-auto='e2ab6c41-b6f5-4181-b38f-3455585b2141'
					className={styles.closeIcon}
					onClick={handleClose}>
					<CloseIcon />
				</div>
			</Modal.Header>
			<Modal.Body className={styles.modal_body}>
				<Form>
					<h1 className={styles.modal_body_title}>Admin Information</h1>
					<div className={styles.modal_content_wrapper}>
						<div className={styles.modal_row}>
							<Col md={4}>
								<div className={styles.row_title}>Account Holder</div>
							</Col>
							<Col md={8}>
								<div
									className={styles.row_content}
									data-test-auto='company-management_primary-admin_admin-select'>
									<Select
										className={styles.selectUsers}
										options={users}
										value={primaryAdmin}
										onChange={(e) => {
											handleAdminChange(e);
										}}
										isClearable
										isSearchable
										name='primary admin list'
									/>
								</div>
							</Col>
						</div>
						<div className={styles.modal_row}>
							<Col md={4}>
								<div className={styles.row_title}>Email Address</div>
							</Col>
							<Col md={8}>
								<div className={styles.row_content}>
									<div className={styles.email_address} title={primaryAdminData?.emailAddress}>{primaryAdminData?.emailAddress}</div></div>
							</Col>
						</div>
						<div className={styles.modal_row}>
							<Col md={4}>
								<div className={styles.row_title}>Mobile Number</div>
							</Col>
							<Col md={8}>
								<div className={styles.row_content}>
									{primaryAdminData?.countryCode?.length > 0 ? `(${primaryAdminData?.countryCode})` : null}{' '}
									{primaryAdminData?.mobileNumber}
								</div>
							</Col>
						</div>
						<div className={styles.modal_row}>
							<Col md={4}>
								<div className={styles.row_title}>Office Number</div>
							</Col>
							<Col md={8}>
								<div className={styles.row_content}>{primaryAdminData?.phone}</div>
							</Col>
						</div>
						<div className={styles.modal_row}>
							<Col md={4}>
								<div className={styles.row_title}>Extension</div>
							</Col>
							<Col md={8}>
								<div className={styles.row_content}>{primaryAdminData?.extension}</div>
							</Col>
						</div>
					</div>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<ButtonWithTextIcon
					label='Cancel'
					className={styles.cancel_button}
					onClick={() => handleClose()}
					bordered
				/>
				<ButtonWithTextIcon
					label='Update'
					className={styles.update_button}
					onClick={() => {
						handleAdminUpdate();
					}}
				/>
			</Modal.Footer>
		</Modal>
	);
};

export default EditAdminModal;
