import { type FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SuiteModal from 'src/components/common/SuiteModal';
import { ReactComponent as DoubleLeft } from 'src/components/svg/chevron-double-left.svg';
import { ReactComponent as DoubleRight } from 'src/components/svg/chevron-double-right.svg';
import { ReactComponent as Left } from 'src/components/svg/chevron-left.svg';
import { ReactComponent as Right } from 'src/components/svg/chevron-right.svg';

import AddCompanyCard from '../partials/add-company-card/add-company-card';
import CompanyCards from '../partials/company-cards/company-cards';
import SaveOfficeLocationModal from '../partials/save-office-location-modal';
import {
	IOfficeLocationDropdownModel,
	IOfficeLocationDropdownResponse,
	IOfficeLocationErrorsModel,
	IOfficeModel,
	initialOfficeLocationErrors,
	initialOfficeLocationData,
	IEditOfficeLocationRequest,
	IOfficeLocationDataModel,
} from '../partials/save-office-location-modal/save-office-location-modal.types';
import SectionHeader from '../partials/section-header/section-header';
import { formatUSNumber } from 'src/helper/formValidations';
import {
	IDataPresentInLocationResponse,
	type ICompanyDetailsProps,
	IDeleteOfficeLocationResponse,
} from './company-details.types';
import { deleteOfficeLocation, getOfficeLocationDropdownList, isDataPresentInLocation } from './company-details.api';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { OfficeLocationConstants } from 'src/helper/Constants';
import ConfirmationPopup from 'src/components/common/confirmationPopup';
import YellowWarningIcon from 'src/components/svg/YellowWarningIcon';
import styles from './company-details.module.scss';
import UpdateContactPersonModal from '../partials/updateContactPersonModal/index';
import { IDefaultContactPerson, IUserLocation } from '../../userManagement/users/Users.model';
import {
	updateContactPerson,
	updateOfficeLocation,
} from '../partials/save-office-location-modal/save-office-location-modal.api';
import CustomDropdown from '../../../components/common/customDropdown';
import { ICustomDropdownOption } from 'src/components/common/customDropdown/CustomDropdownWithCustomOptions';
import { logger } from '../../../oidcClient/authProvider';

const CompanyDetails: FC<ICompanyDetailsProps> = (props) => {
	const { officeList: { items = [], count = 1 } = {}, getStateFromStateId, getAllCompanyList } = props;

	const [currentPage, setCurrentPage] = useState(1);
	const [cardOpen, setCardOpen] = useState(-1);
	const [showSaveOfficeLocation, setShowSaveOfficeLocation] = useState('');
	const [showRemoveOfficeLocation, setShowRemoveOfficeLocation] = useState(false);
	const [dataPresentInLocation, setDataPresentInLocation] = useState(false);
	const [officeLocationToEdit, setOfficeLocationToEdit] = useState<null | IOfficeModel>(null);
	const [officeLocationToDelete, setOfficeLocationToDelete] = useState<null | IOfficeModel>(null);
	const [newOfficeLocationIds, setNewOfficeLocationIds] = useState<number[]>([]);
	const [officeLocationErrors, setOfficeLocationErrors] =
		useState<IOfficeLocationErrorsModel>(initialOfficeLocationErrors);
	const [deleteInProgress, setDeleteInProgress] = useState(false);
	const [officeLocationList, setOfficeLocationList] = useState<IOfficeLocationDropdownModel[]>([]);
	const [officeLocationOptions, setOfficeLocationOptions] = useState<ICustomDropdownOption[]>([]);
	const [officeLocationOptionSelected, setOfficeLocationOptionSelected] = useState<ICustomDropdownOption | null>(null);
	const [isOfficeLocationOptionNotSelected, setIsOfficeLocationOptionNotSelected] = useState(false);
	const [editedOfficeLocation, setEditedOfficeLocation] = useState<IOfficeLocationDataModel>({
		officeLocation: initialOfficeLocationData,
		userIds: [],
	});
	const [showContactPersonModal, setShowContactPersonModal] = useState<boolean>();
	const [removedUsers, setRemovedUsers] = useState<IUserLocation[]>([]);
	const [contactPersons, setContactPersons] = useState<IDefaultContactPerson[]>([]);
	const [selectedContactPerson, setSelectedContactPerson] = useState<ICustomDropdownOption | null>(null);
	const dispatch = useDispatch();

	const fetchEntireListOfOfficeLocation = (): void => {
		getOfficeLocationDropdownList((dropdownResponse: IOfficeLocationDropdownResponse) => {
			const { locationList, error } = dropdownResponse;
			if (error)
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusType: StatusType.Warning,
					statusMessage: OfficeLocationConstants.getOfficeLocationDropdownDataError,
				});
			else setOfficeLocationList(locationList);
		});
	};

	useEffect(() => {
		fetchEntireListOfOfficeLocation();
	}, []);

	const pages = Math.ceil(count / 7);

	const generateOfficeLocationOptions = (officelocationClicked: IOfficeModel): void => {
		const officeLocationListGenerated = officeLocationList
			.filter(
				(eachLocation: IOfficeLocationDropdownModel) => eachLocation.locationId !== officelocationClicked?.locationId,
			)
			.map((eachOfficeLoation: IOfficeLocationDropdownModel) => {
				const eachOfficeLocationOption = {
					value: -1,
					label: '',
					id: -1,
				};
				eachOfficeLocationOption.value = eachOfficeLoation.locationId;
				eachOfficeLocationOption.id = eachOfficeLoation.locationNumber;
				eachOfficeLocationOption.label = (
					<li className='eachOption'>
						<div
							className='officeLocationName'
							title={eachOfficeLoation.name}>
							{eachOfficeLoation.name}
						</div>
						<div
							className='officeLocationId'
							title={`Location #${eachOfficeLoation.locationNumber}`}>
							&nbsp;- Location #{eachOfficeLoation.locationNumber}
						</div>
					</li>
				);
				return eachOfficeLocationOption;
			});
		setOfficeLocationOptions(officeLocationListGenerated);
	};

	const handleNewOfficeLocationClick = (officeLocationSelected: ICustomDropdownOption): void => {
		setOfficeLocationOptionSelected(officeLocationSelected);
		setIsOfficeLocationOptionNotSelected(false);
	};

	const handleOnBlurOfficeLocationSelect = (): void => {
		if (!officeLocationOptionSelected) setIsOfficeLocationOptionNotSelected(true);
	};

	const getDataTestValue = (): string => {
		const office = officeLocationOptionSelected
			? officeLocationList.find((eachOffice) => eachOffice.locationId === officeLocationOptionSelected.value)
			: null;
		return office ? office.name + ' - Location #' + office.locationNumber : '';
	};
	const deleteModalBodyText = dataPresentInLocation ? (
		<section className='dataPresentMessage'>
			<div className='headerMessage'>{OfficeLocationConstants.deleteModalBodyText}</div>
			<div className='warningMessage'>
				<div className='warningSymbol'>
					<YellowWarningIcon />
				</div>
				<div className='warningText'>
					<header>Impacted Office Locations</header>
					<div className='bodyText'>
						You have tax data in this location. Before deleting this location <span>you must move all tax data</span> to
						another location. Please select the office location you would like to move
						<br />
						the documents to.
					</div>
				</div>
			</div>
			<div className='selectOfficeLocationWrapper'>
				<div className='selectOfficeLocationTitle'>Change Office Location</div>
				<div
					className='selectOfficeLocationData errorBorder'
					data-test-auto='7852e3ff-801a-453d-99b0-d32c6bfcd0e1'
					data-test-value={getDataTestValue()}>
					<CustomDropdown
						id='officeLocationDropdown'
						selectedOption={officeLocationOptionSelected ?? null}
						setSelectedOption={handleNewOfficeLocationClick}
						handleOnBlur={handleOnBlurOfficeLocationSelect}
						options={officeLocationOptions}
						customPlaceHolder='Select Office Location'
						clearable={false}
						searchable={false}
						height={38}
						className={isOfficeLocationOptionNotSelected ? 'officeLocationOptionsError' : ''}
						classNamePrefix='officeLocationOptions'
						scrollableMenu={true}
						maxMenuHeight={89}
					/>
				</div>
				{isOfficeLocationOptionNotSelected && <div className='selectOfficeLocationError'>This field is required</div>}
			</div>
		</section>
	) : (
		<section>{OfficeLocationConstants.deleteModalBodyText}</section>
	);

	const getPageData = (pageNumber: number): void => {
		getAllCompanyList(pageNumber, 7, '', newOfficeLocationIds.join(','));
		setCardOpen(-1);
	};

	const onHide = (): void => {
		setShowSaveOfficeLocation('');
		setOfficeLocationErrors({
			name: { error: false, message: '' },
			countryCode: { error: false, message: '' },
			address1: { error: false, message: '' },
			address2: { error: false, message: '' },
			city: { error: false, message: '' },
			state: { error: false, message: '' },
			zip: { error: false, message: '' },
			phone: { error: false, message: '' },
			fax: { error: false, message: '' },
			website: { error: false, message: '' },
		});
		setRemovedUsers([]);
	};

	const hideDeletePopup = (): void => {
		setDeleteInProgress(false);
		setOfficeLocationToDelete(null);
		setOfficeLocationOptionSelected(null);
		setIsOfficeLocationOptionNotSelected(false);
		setShowRemoveOfficeLocation(false);
	};

	const handleEditClick = (officeLocation: IOfficeModel): void => {
		logger && logger.trackTrace('handleEditClick: Editing office location');
		const tempOfficeLocation: IOfficeModel = structuredClone(officeLocation);
		if (tempOfficeLocation.phone && tempOfficeLocation.phone.length === 10)
			tempOfficeLocation.phone = formatUSNumber(tempOfficeLocation.phone);

		if (tempOfficeLocation.fax && tempOfficeLocation.fax.length === 10)
			tempOfficeLocation.fax = formatUSNumber(tempOfficeLocation.fax);

		setOfficeLocationToEdit(tempOfficeLocation);
		setShowSaveOfficeLocation('edit');
	};

	const handleRemoveClick = (officeLocation: IOfficeModel): void => {
		logger && logger.trackTrace('handleRemoveClick: Removing office location');
		if (!officeLocation.isPrimary) {
			setOfficeLocationToDelete(officeLocation);
			isDataPresentInLocation(officeLocation.locationId, (responseData: IDataPresentInLocationResponse) => {
				const { dataPresent, error } = responseData;
				if (error) {
					setShowRemoveOfficeLocation(false);
					setDataPresentInLocation(false);
					dispatch({
						type: notificationTypes.NOTIFICATION,
						statusType: StatusType.Warning,
						statusMessage: OfficeLocationConstants.getDataPresentInLocationError,
					});
				} else {
					setShowRemoveOfficeLocation(true);
					setDataPresentInLocation(dataPresent);
					if (dataPresent) generateOfficeLocationOptions(officeLocation);
				}
			});
		}
	};

	const deleteOfficeLocationConfirm = (): void => {
		if (dataPresentInLocation && !officeLocationOptionSelected) setIsOfficeLocationOptionNotSelected(true);
		else {
			setDeleteInProgress(true);
			deleteOfficeLocation(
				officeLocationToDelete?.locationId,
				dataPresentInLocation ? officeLocationOptionSelected?.value : null,
				(responseData: IDeleteOfficeLocationResponse) => {
					setDeleteInProgress(false);
					const { deleted, error } = responseData;
					if (error)
						dispatch({
							type: notificationTypes.NOTIFICATION,
							statusType: StatusType.Warning,
							statusMessage: OfficeLocationConstants.deleteOfficeLocationError,
						});
					else if (deleted) {
						dispatch({
							type: notificationTypes.NOTIFICATION,
							statusType: StatusType.Success,
							statusMessage: OfficeLocationConstants.deleteOfficeLocationSuccess,
						});
						const tempNewOfficeLocationIds = newOfficeLocationIds.filter(
							(eachNewLocationId: number) => eachNewLocationId !== officeLocationToDelete?.locationId,
						);
						setNewOfficeLocationIds(tempNewOfficeLocationIds);
						setDataPresentInLocation(false);
						setOfficeLocationOptionSelected(null);
						setIsOfficeLocationOptionNotSelected(false);
						setOfficeLocationToDelete(null);
						setShowRemoveOfficeLocation(false);
						getPageData(1);
						setCurrentPage(1);
						fetchEntireListOfOfficeLocation();
					} else
						dispatch({
							type: notificationTypes.NOTIFICATION,
							statusType: StatusType.Warning,
							statusMessage: OfficeLocationConstants.deleteOfficeLocationError,
						});
				},
			);
		}
	};

	const handleUpdateContactPersonClose = (): void => {
		setShowContactPersonModal(false);
		setShowSaveOfficeLocation('edit');
		setContactPersons([]);
		setSelectedContactPerson(null);
	};

	const handleUpdateContactPerson = (callback: () => void): void => {
		logger && logger.trackTrace('handleUpdateContactPerson: Updating contact person');
		const updateContactPersonModel: IEditOfficeLocationRequest = {
			locationId: editedOfficeLocation.officeLocation.locationId,
			removedUsers: removedUsers.map((x) => x.value),
			replacedContactPerson: selectedContactPerson?.value,
		};
		updateContactPerson(updateContactPersonModel, (response) => {
			if (response)
				updateOfficeLocation(editedOfficeLocation, (responseNew: boolean) => {
					if (responseNew) {
						onHide();
						setShowContactPersonModal(false);
						setContactPersons([]);
						setSelectedContactPerson(null);
						dispatch({
							type: notificationTypes.NOTIFICATION,
							statusType: StatusType.Success,
							statusMessage: OfficeLocationConstants.updateOfficeLocationSuccess,
						});
						getAllCompanyList(currentPage, 7, '', newOfficeLocationIds.join(','));
						fetchEntireListOfOfficeLocation();
					} else
						dispatch({
							type: notificationTypes.NOTIFICATION,
							statusType: StatusType.Warning,
							statusMessage: OfficeLocationConstants.updateOfficeLocationError,
						});
				});
			else {
				callback();
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusType: StatusType.Warning,
					statusMessage: OfficeLocationConstants.updateOfficeLocationError,
				});
			}
		});
	};

	return (
		<div className={styles.company_details_wrapper}>
			<div className={styles.company_details_header}>
				<SectionHeader title='Office Location' />
				<hr className={styles.horizontal_divider} />
			</div>
			<div className={styles.location_header}>Locations</div>
			<div className={styles.office_list_container}>
				{items.map((item, index: number) => {
					return (
						<CompanyCards
							stateDetails={getStateFromStateId(item.state)}
							cardOpen={cardOpen}
							setCardOpen={setCardOpen}
							{...item}
							key={`${item.locationId}-${index}`}
							item={item}
							handleEditClick={handleEditClick}
							handleRemoveClick={handleRemoveClick}
							isNew={newOfficeLocationIds.includes(item.locationId)}
						/>
					);
				})}
				<div>
					<div
						onClick={() => {
							setOfficeLocationToEdit(null);
							setShowSaveOfficeLocation('add');
						}}>
						<AddCompanyCard />
					</div>
				</div>
			</div>
			{pages > 1 ? (
				<div className={styles.navigatorContainer}>
					<div
						data-test-auto='5d52e0a4-9c67-408c-91f9-4be6c0253135'
						className={`${styles.navAction} ${currentPage === 1 ? styles.disabled_nav : ''}`}
						title='First Page'
						onClick={() => {
							getPageData(1);
							if (currentPage > 1) setCurrentPage(1);
						}}>
						<DoubleLeft />
					</div>
					<div
						data-test-auto='08299b87-6319-4e3b-9b08-d41b7d820a05'
						className={`${styles.navAction} ${currentPage === 1 ? styles.disabled_nav : ''}`}
						title='Previous Page'
						onClick={() => {
							getPageData(currentPage - 1);
							if (currentPage > 1) setCurrentPage(currentPage - 1);
						}}>
						<Left />
					</div>
					<div
						className={styles.pageNumbers}
						title={currentPage.toString()}>
						{currentPage}
					</div>
					<div
						data-test-auto='b53ffd43-b660-492c-b8e9-ccbf41645a9a'
						className={`${styles.navAction} ${currentPage === pages ? styles.disabled_nav : ''}`}
						title='Next Page'
						onClick={() => {
							getPageData(currentPage + 1);
							if (currentPage < pages) setCurrentPage(currentPage + 1);
						}}>
						<Right />
					</div>
					<div
						data-test-auto='5647e2b0-b519-4910-aa53-c725c834ec60'
						className={`${styles.navAction} ${currentPage === pages ? styles.disabled_nav : ''}`}
						title='Last Page'
						onClick={() => {
							getPageData(pages);
							if (currentPage < pages) setCurrentPage(pages);
						}}>
						<DoubleRight />
					</div>
				</div>
			) : null}
			{showSaveOfficeLocation && (
				<SuiteModal
					width='800'
					theme='dark'
					title={showSaveOfficeLocation === 'add' ? 'Add New Office Location' : 'Edit Office Location'}
					hide={onHide}>
					<SaveOfficeLocationModal
						onHide={onHide}
						officeLocationToEdit={officeLocationToEdit}
						isOfficeLocationToEditPrimary={officeLocationToEdit ? officeLocationToEdit.isPrimary : false}
						getAllCompanyList={getAllCompanyList}
						newOfficeLocationIds={newOfficeLocationIds}
						setNewOfficeLocationIds={setNewOfficeLocationIds}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						officeLocationErrors={officeLocationErrors}
						setOfficeLocationErrors={setOfficeLocationErrors}
						fetchEntireListOfOfficeLocation={fetchEntireListOfOfficeLocation}
						setEditedOfficeLocation={setEditedOfficeLocation}
						setShowContactPersonModal={setShowContactPersonModal}
						removedUsers={removedUsers}
						setRemovedUsers={setRemovedUsers}
						setContactPersons={setContactPersons}
						setShowSaveOfficeLocation={setShowSaveOfficeLocation}
						officeLocationList={officeLocationList}
					/>
				</SuiteModal>
			)}

			{showRemoveOfficeLocation && (
				<ConfirmationPopup
					show={showRemoveOfficeLocation}
					actionButtonType={'danger'}
					cancelText={OfficeLocationConstants.deleteModalCancelText}
					header={OfficeLocationConstants.deleteModalHeaderText}
					message={deleteModalBodyText}
					okText={OfficeLocationConstants.deleteModalOkText}
					onCancel={hideDeletePopup}
					onOk={deleteOfficeLocationConfirm}
					onHide={hideDeletePopup}
					actionDisabled={deleteInProgress}
					disabledText={''}
					className={styles.deleteOfficeLocationModal}
				/>
			)}

			{showContactPersonModal && (
				<UpdateContactPersonModal
					contactPersons={contactPersons}
					handleClose={handleUpdateContactPersonClose}
					handleUpdateContactPerson={handleUpdateContactPerson}
					selectedOption={selectedContactPerson}
					setSelectedOption={setSelectedContactPerson}
				/>
			)}
		</div>
	);
};

export default CompanyDetails;
