import React, { FC } from 'react';

const BreadCrumSeparator: FC = () => {
    return (
        <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="21" fill="white" fillOpacity="0.01" />
            <g clipPath="url(#clip0_3370_151157)">
                <rect width="8" height="8" transform="translate(8 8.5)" fill="white" fillOpacity="0.01" />
                <g clipPath="url(#clip1_3370_151157)">
                    <path d="M10.5 8.5L9 10L11.5 12.5L9 15L10.5 16.5L14.5 12.5L10.5 8.5Z" fill="black" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_3370_151157">
                    <rect width="8" height="8" fill="white" transform="translate(8 8.5)" />
                </clipPath>
                <clipPath id="clip1_3370_151157">
                    <rect width="8" height="8" fill="white" transform="translate(8 8.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default BreadCrumSeparator;
