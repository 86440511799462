import React, { FC, useState, useEffect } from 'react';
import BreadCrumbNav from '../../components/userManagement/BreadCrumbNav';
import RolesAndPermissions from '../../modules/userManagement/rolesAndPermissions';
import UserManagementTabs from '../../modules/userManagement/userManagementTabs';
import Users from '../../modules/userManagement/users';
import UserGroups from '../../modules/userManagement/userGroups';
import { UserManagementProps } from './UserManagement.model';
import useUserManagement from './UserManagement.hooks';
import { logger } from '../../oidcClient/authProvider';

const UserManagement: FC<UserManagementProps> = props => {
    const defaultTabsState: { [key: string]: boolean } = {
        usersTab: true,
        userGroupsTab: false,
        rolesAndPermissionsTab: false,
    };

    const { userGroupsListProps, rolesProps } = useUserManagement(props);

    const [tabs, setTabs] = useState(defaultTabsState);
    const [page, setPage] = useState({ pageIndex: 1, pageSize: 10 });
    const [searchText, setSearchText] = useState('');
    const [newUserIds, setNewUserIds] = useState<number[]>([]);

    const breadCrumbConfig = [
        {
            name: 'SafeSend Suite',
            activePage: false,
            link: '/',
        },
        {
            name: 'User Management',
            activePage: true,
            link: '',
        },
    ];

    useEffect(() => {
        logger && logger.trackPageView('Navigated to User Management');
        props.requestResourceIds();
        props.requestAllUsers(true, 1, 10, '', '');
    }, []);

    const showTab = (id: string) => {
        const newTabs = { ...tabs };
        for (const tab in tabs) {
            tab === id ? (newTabs[tab] = true) : (newTabs[tab] = false);
        }
        setTabs(newTabs);
    };

    return (
        <div className="suitePageContent">
            <section className="suitePageInnerContent">
                <BreadCrumbNav config={breadCrumbConfig} />
                <h1 className="suitePageHeading">User Management</h1>
                <section className="mainSection">
                    <UserManagementTabs tabs={tabs} showTab={showTab} />
                    <Users
                        profile={props.profile}
                        display={tabs.usersTab}
                        userGroupList={props.userGroupList}
                        usersList={props.usersList}
                        requestAllUsers={props.requestAllUsers}
                        requestUserById={props.requestUserById}
                        exportUserDetailsAsExcel={props.exportUserDetailsAsExcel}
                        page={page}
                        setPage={setPage}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        newUserIds={newUserIds}
                        setNewUserIds={setNewUserIds}
                    />
                    <UserGroups
                        display={tabs.userGroupsTab}
                        {...userGroupsListProps}
                        page={page}
                        searchText={searchText}
                        newUserIds={newUserIds}
                    />
                    <RolesAndPermissions display={tabs.rolesAndPermissionsTab} {...rolesProps} />
                </section>
            </section>
        </div>
    );
};

export default UserManagement;
