import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import MyAccountLayout from "../../../components/common/myAccount/myAccountLayout";
import { IModalProps } from "../userProfile/userProfile.types";
import "./style.scss";

const MyAccountModal: FC<IModalProps> = ({ showState, onHide }) => {
    return <Modal size="xl"
        show={showState} onHide={onHide} className="my-account-modal" centered>
        <Modal.Header closeButton closeVariant="white">My Account</Modal.Header>
        <Modal.Body className="p-0">
            <MyAccountLayout onHide={onHide} />
        </Modal.Body>
    </Modal>;
};

export default MyAccountModal;