import { actionTypes } from "./ClientManagementSetting.actions";
import { OverlayLoaderAction } from "../../../pages/common/overlayLoader/OverlayLoader.model";
import { NotificationAction } from 'src/pages/common/notification/notification.reducer';

export interface IClientManagementSetting {
	reconcileType: ReconcileType;
	extractClientIdEnabled: boolean;
}

export enum ReconcileType {
	None = -1,
	Add = 0,
	Ignore = 1,
	Confirm = 2,
}

export const initialState: IClientManagementSetting = {
	reconcileType: ReconcileType.Ignore,
	extractClientIdEnabled: true
}

interface RequestClientManagementSetting {
	type: actionTypes.REQUEST_CLIENTMANAGEMENT_SETTING;
	isLoading: boolean;
}

interface ReceiveClientManagementSetting {
	type: actionTypes.RECEIVE_CLIENTMANAGEMENT_SETTING,
	clientManagementSetting: IClientManagementSetting;
	isLoading: boolean;
}

interface UpdateTaxSoftwareSetting {
	type: actionTypes.UPDATE_CLIENTMANAGEMENT_SETTING,
	clientManagementSetting: IClientManagementSetting
}

export type KnownAction =
	RequestClientManagementSetting |
	ReceiveClientManagementSetting |
	UpdateTaxSoftwareSetting  |
	NotificationAction |
	OverlayLoaderAction;
