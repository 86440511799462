import CloseIcon from "src/components/svg/CloseIcon";
import React, { FC, useState } from "react";
import { Modal, Container, Button } from "react-bootstrap";
import "./RevokeO365AccessModal.style.scss";
import { SSORevokeStatus } from "../Users.model";

interface RevokeO365AccessModalProps {
    setShowRevokeO365AccessModal: (showRevokeO365AccessModal: boolean) => void;
    revokeO365Access: (revokeStatusSelected: number) => void;
    revokeInProgress: boolean;
}

const RevokeO365AccessModal: FC<RevokeO365AccessModalProps> = (props) => {
    const { setShowRevokeO365AccessModal, revokeO365Access, revokeInProgress } = props;
    const [revokeStatusSelected, setRevokeStatusSelected] = useState(SSORevokeStatus.TemporarilyRevoked);
    const handleClose = () => setShowRevokeO365AccessModal(false);
    return (
        <Modal show={true} onHide={handleClose} className="revokeO365AccessModal">
            <Modal.Header>
                <div><Modal.Title>Revoke O365 Access</Modal.Title></div>
                <div data-test-auto="fc5dd2aa-e64a-4c9e-95d1-e95deec8f5d2" className="closeIcon" onClick={handleClose}><CloseIcon /></div>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <Container>
                    The user will immediately loose Single Sign On access:
                    <ul className="revokeOptions">
                        <li className="revokeTemporarily">
                            <div className="revokeTempRadio">
                                <input type="radio" name="revokeRadio" id="revokeTempRadio" className="form-check-input" checked={revokeStatusSelected === SSORevokeStatus.TemporarilyRevoked} onChange={() => { setRevokeStatusSelected(SSORevokeStatus.TemporarilyRevoked) }} />
                            </div>
                            <div className="revokeTempMessage">
                                <div className={`revokeTempMessageHeader${revokeStatusSelected === SSORevokeStatus.TemporarilyRevoked ? " fontBold" : ""}`}>
                                    Temporarily
                                </div>
                                <div className="revokeTempMessageBody">
                                    The user will be able to request access again in the future.
                                </div>
                            </div>
                        </li>
                        <li className="revokePermanently">
                            <div className="revokePermRadio">
                                <input type="radio" name="revokeRadio" id="revokePermRadio" className="form-check-input" checked={revokeStatusSelected === SSORevokeStatus.PermanentlyRevoked} onChange={() => { setRevokeStatusSelected(SSORevokeStatus.PermanentlyRevoked) }} />
                            </div>
                            <div className="revokePermMessage">
                                <div className={`revokeTempMessageHeader${revokeStatusSelected === SSORevokeStatus.PermanentlyRevoked ? " fontBold" : ""}`}>
                                    Permanently
                                </div>
                                <div className="revokePermMessageBody">
                                    The user will be blocked and not be able to request access again.
                                </div>
                            </div>
                        </li>
                    </ul>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button data-test-auto="b8957c49-174d-4b7f-834e-0b3d28a19ec0" variant="secondary" className="revokeCancel" onClick={handleClose}>
                    Cancel
                </Button>
                <Button data-test-auto="0d32b284-c6ff-451e-be43-ee76858da737" variant="primary" className="revokeApply" disabled={revokeInProgress} onClick={() => revokeO365Access(revokeStatusSelected)}>
                    Apply Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RevokeO365AccessModal;