import { Action, Reducer } from "redux";
import { actionTypes } from "./userProfile.actions";
import * as profileActions from "./userProfile.types";

export interface IUserProfileData {
  user: profileActions.IUserProfile;
  isLoading: boolean;
}

export interface UserProfileState {
  user: profileActions.IUserProfile;
}

const unloadedState: profileActions.IUserProfile = profileActions.initialUserProfile;

export const reducer: Reducer<profileActions.IUserProfile> = (
  state: profileActions.IUserProfile = unloadedState,
  incomingAction: Action
) => {
  const action = incomingAction as profileActions.DispatchActions;
  switch (action.type) {
    case actionTypes.RECEIVE_USER_PROFILE:
      // Only accept the incoming data if it matches the most recent request. This ensures we correctly
      // handle out-of-order responses.
      if (action.user.userId === state.userId || state.firstName === "") {
        return {
          ...action.user,
          fullName() {
            return action.user.firstName + " " + action.user.lastName;
          },
        } as profileActions.IUserProfile;
      } else {
        return {
          ...state,
          fullName() {
            return this.firstName + " " + this.lastName;
          },
        } as profileActions.IUserProfile;
      }
    case actionTypes.UPDATE_USER_PROFILE:
      let received: profileActions.IUserProfile = { ...state };
      received = action.userDetails;
      return received;
    default:
    // The following line guarantees that every action in the KnownAction union has been covered by a case above
    // const exhaustiveCheck: never = action;
  }

  return state || unloadedState;
};
