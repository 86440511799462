import { IDefaultContactPerson } from "../../../userManagement/users/Users.model";

export interface IStepData {
    number: number;
    name: string;
    inProgress: boolean;
    completed: boolean;
}

export const initialStepData: IStepData[] = [
    {
        number: 1,
        name: 'Office Information',
        inProgress: true,
        completed: false,
    },
    {
        number: 2,
        name: 'Add Users',
        inProgress: false,
        completed: false,
    },
];

export const secondStepData: IStepData[] = [
    {
        number: 1,
        name: 'Office Information',
        inProgress: false,
        completed: true,
    },
    {
        number: 2,
        name: 'Add Users',
        inProgress: true,
        completed: false,
    },
];

export interface IOfficeModel {
    locationId: number;
    locationNumber: number;
    isPrimary: boolean;
    name: string;
    countryCode: string;
    address1: string;
    address2: string;
    city: string;
    state: number;
    zip: string;
    phone: string;
    fax: string;
    website: string;
}

export interface IOfficeLocationDropdownModel {
    locationId: number;
    name: string;
    locationNumber:number;
}

export interface IOfficeLocationDropdownResponse {
    locationList: IOfficeLocationDropdownModel[];
    error: boolean;
}

export interface IOfficeLocationDataModel {
    officeLocation: IOfficeModel;
    userIds: number[];
}

export const initialOfficeLocationData: IOfficeModel = {
    locationId: -1,
    locationNumber: -1,
    isPrimary: false,
    name: '',
    countryCode: '+1',
    address1: '',
    address2: '',
    city: '',
    state: 0,
    zip: '',
    phone: '',
    fax: '',
    website: '',
};

export interface IAddLocationResponse {
    error: boolean;
    locationId: number;
}

export interface IGetUserIDsInLocationResponse {
    error: boolean;
    userIDsInLocation: number[];
}

export interface IErrorModel {
    error: boolean;
    message: string;
}

export interface IOfficeLocationErrorsModel {
    name: IErrorModel;
    countryCode: IErrorModel;
    address1: IErrorModel;
    address2: IErrorModel;
    city: IErrorModel;
    state: IErrorModel;
    zip: IErrorModel;
    phone: IErrorModel;
    fax: IErrorModel;
    website: IErrorModel;
}

export const initialOfficeLocationErrors: IOfficeLocationErrorsModel = {
    name: { error: false, message: '' },
    countryCode: { error: false, message: '' },
    address1: { error: false, message: '' },
    address2: { error: false, message: '' },
    city: { error: false, message: '' },
    state: { error: false, message: '' },
    zip: { error: false, message: '' },
    phone: { error: false, message: '' },
    fax: { error: false, message: '' },
    website: { error: false, message: '' },
};

export interface IEditOfficeLocationRequest {
    locationId: number;
    removedUsers: number[];
    replacedContactPerson?: number;
}
export interface IEditOfficeLocationResponse {
    isContactPerson: boolean;
    contactPersons: IDefaultContactPerson[];
    error: boolean;
}

export interface IDisabledDnDItems {
    id: number;
    disabledTitle: string;
}
