interface IArrayOfObjects extends Array<any> {}

const sortArrayOfObjectWithStrings = (arrayOfObjects: IArrayOfObjects, propertyName: string) => {
    const sortedArray = [...arrayOfObjects].sort((a: any, b: any) => {
        const a_prop: string = a[propertyName].toLowerCase(),
              b_prop: string = b[propertyName].toLowerCase();

        if(a_prop < b_prop) {
            return -1;
        } 

        if(a_prop > b_prop) {
            return 1;
        }

        return 0;
    });

    return sortedArray;
};

export default sortArrayOfObjectWithStrings;