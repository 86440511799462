import React, { useEffect, useState } from 'react';
import UserPermissionHeader from './UserPermissionHeader';
import UserPermissionTable from './UserPermissionTable';
import UserPermissionPagination from './UserPermissionPagination';
import { IPageProps, ISSOAccessRequestedUsers, ISortColumn } from './UserPermission.model';

interface IUserPermissionProps {
	usersData: ISSOAccessRequestedUsers;
	getAllSSOAccessRequestedUsers(
		reload?: boolean,
		pageNo?: number,
		pageSize?: number,
		sortBy?: string,
		sortOrder?: string,
		filterText?: string,
		callback?: () => void,
	): void;
	requestResourceIds(): void;
}

export const UserPermission: React.FC<IUserPermissionProps> = (props) => {
	const {
		usersData: { users, isLoading, count },
		getAllSSOAccessRequestedUsers,
		requestResourceIds,
	} = props;

	const [page, setPage] = useState<IPageProps>({ pageIndex: 1, pageSize: 10 });
	const [inputText, setInputText] = useState<string>('');
	const [searchText, setSearchText] = useState<string>('');
	const [sortColumn, setSortColumn] = useState<ISortColumn>({
		sortBy: 'name',
		sortOrder: 'asc',
	});
	const [initialLoadCompleted, setInitialLoadCompleted] = useState<boolean>(false);

	useEffect(() => {
		getAllSSOAccessRequestedUsers(
			true,
			page.pageIndex,
			page.pageSize,
			sortColumn.sortBy,
			sortColumn.sortOrder,
			searchText,
			() => setInitialLoadCompleted(true),
		);
		requestResourceIds();
	}, []);

	useEffect(() => {
		if (initialLoadCompleted) {
			getAllSSOAccessRequestedUsers(
				true,
				page.pageIndex,
				page.pageSize,
				sortColumn.sortBy,
				sortColumn.sortOrder,
				searchText,
			);
		}
	}, [sortColumn]);

	const filterSearchText = (text: string) => {
		const newSearchText = text.trim();
		const newPage = { ...page };
		newPage.pageIndex = 1;
		setPage(newPage);
		getAllSSOAccessRequestedUsers(
			true,
			newPage.pageIndex,
			newPage.pageSize,
			sortColumn.sortBy,
			sortColumn.sortOrder,
			newSearchText,
		);
		setSearchText(newSearchText);
	};

	const goToPage = (newPageIndex: number, newPageSize: number) => {
		const totalPages = Math.ceil(count / page.pageSize);
		if (
			(newPageIndex !== page.pageIndex && newPageIndex <= totalPages && newPageIndex >= 1) ||
			newPageSize !== page.pageSize
		) {
			const newPage = { ...page };
			newPage.pageIndex = newPageIndex;
			newPage.pageSize = newPageSize;
			setPage(newPage);
			
			getAllSSOAccessRequestedUsers(
				true,
				newPageIndex,
				newPageSize,
				sortColumn.sortBy,
				sortColumn.sortOrder,
				searchText,
			);
		}
	};

	return (
		<>
			<article>
				<UserPermissionHeader
					inputText={inputText}
					setInputText={setInputText}
					filterSearchText={filterSearchText}
				/>
				<UserPermissionTable
					users={users}
					isLoading={isLoading}
					sortColumn={sortColumn}
					setSortColumn={setSortColumn}
					page={page}
					searchText={searchText}
					getAllSSOAccessRequestedUsers={getAllSSOAccessRequestedUsers}
				/>
				<UserPermissionPagination
					count={count}
					page={page}
					goToPage={goToPage}
				/>
			</article>
		</>
	);
};
