import { useState } from 'react';

import { type IHeaderDesignHooksReturn, type IHeaderDesignProps } from './header-design.types';

const useHeaderDesign = (props: IHeaderDesignProps): IHeaderDesignHooksReturn => {
	const {
		coverPageSetting: { bgColorCode = '', foreColorCode = '' } = {},
		isBrandingSettingsLoading,
		whiteLogoPath,
		company_name: companyName,
		logoPath,
		updateCompanyBrandSettings,
		primaryOfficeLocation: { address1, city, zip, state } = {},
		countryStates = [],
	} = props;

	const [showHeaderDesignPopup, setShowHeaderDesignPopup] = useState(false);

	const stateName = countryStates.find((st: any) => st.id === state)?.name || '';

	return {
		setShowHeaderDesignPopup,
		showHeaderDesignPopup,
		isBrandingSettingsLoading,
		bgColorCode,
		foreColorCode,
		whiteLogoPath,
		companyName,
		logoPath,
		updateCompanyBrandSettings,
		address: address1,
		city,
		zip,
		stateName,
	};
};

export { useHeaderDesign };
