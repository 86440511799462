const REGEX_VALUES = [{ Key: '0', Value: /\d/ }];

const MASK_PLACEHOLDER = '_';

export const getMaskedValue = (value: string, mask: string): string => {
	if (value) {
		let maskedValue = '';

		const computedMask: any[] = mask.split('').map((m) => {
			const index = REGEX_VALUES.findIndex((a) => a.Key == m);
			if (index > -1) {
				return REGEX_VALUES[index].Value;
			} else {
				return m;
			}
		});

		let processedIndex = 0;
		const currentValue = getUnmaskedValue(value, mask);

		for (let index = 0; index < computedMask.length; index++) {
			if (computedMask[index] instanceof RegExp) {
				if (processedIndex < currentValue.length) {
					let valueUpdated = false;

					for (let i = processedIndex; i < currentValue.length; i++) {
						if (computedMask[index].test(currentValue[processedIndex])) {
							maskedValue += currentValue[processedIndex];
							processedIndex += 1;
							valueUpdated = true;
							break;
						} else {
							processedIndex += 1;
						}
					}

					if (!valueUpdated) {
						maskedValue += MASK_PLACEHOLDER;
					}
				} else {
					maskedValue += MASK_PLACEHOLDER;
				}
			} else {
				maskedValue += computedMask[index];
			}
		}

		if (getUnmaskedValue(maskedValue, mask) != '') {
			return maskedValue;
		}
	}

	return '';
};

export const getUnmaskedValue = (value: string, mask: string): string => {
	if (value) {
		const separators = getSeparators(mask);

		return value
			.split('')
			.filter((v) => {
				return separators.every((separator) => separator != v) && v != MASK_PLACEHOLDER;
			})
			.join('');
	} else {
		return '';
	}
};

export const getSeparators = (mask: string): string[] => {
	return mask.split('').filter((m, index, self) => {
		return REGEX_VALUES.findIndex((regex) => regex.Key == m) == -1 && self.indexOf(m) == index;
	});
};
