import { Reducer } from "redux";
import axios, { AxiosResponse,AxiosError } from 'axios';
import { AppThunkAction } from 'src/store/index';
import { IPasswordPolicySettings, KnownActions, initialPasswordPolicySettings } from './PasswordPolicySettings.model';
import { actionTypes } from './PasswordPolicySettings.actions';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { API_BASE_URL, CompanySettingsConstants, PasswordPolicyConstants } from 'src/helper/Constants';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {

    requestPasswordPolicySettings:
        (): AppThunkAction<KnownActions> => (dispatch) => {
            axios
                .get(
                    `${API_BASE_URL}/api/settings/password-policy`
                )
                .then((response: AxiosResponse) => {
                    const data = response.data;
                    dispatch({
                        type: actionTypes.RECEIVE_PASSWORD_POLICY_SETTINGS,
                        passwordPolicySettings: data,
                    });
                })
                .catch(function (error: AxiosError) {
                    logger && logger.trackWarning('Error while requestPasswordPolicySettings', { 'Error': error?.response?.data});
                    if(error.response && error.response.status!=419 && error.response.status!=512)
                    {
                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage:
                                PasswordPolicyConstants.StatusMessage.PPSettingsError,
                            statusType: StatusType.Error,
                        });
                    }
                });
        },

    updatePasswordPolicySettings: (passwordPolicySettings: IPasswordPolicySettings, callback: () => void, resourceId: string): AppThunkAction<KnownActions> => (dispatch, getState) => {
        axios.put(API_BASE_URL + '/api/settings/password-policy', passwordPolicySettings
            , {
                headers: {
                    'X-Resource-Id': resourceId
                }
            }
        )
            .then((response: AxiosResponse) => {
                const { data } = response;
                dispatch({
                    type: actionTypes.RECEIVE_PASSWORD_POLICY_SETTINGS,
                    passwordPolicySettings: passwordPolicySettings
                });
                callback();
                if (data) {
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: PasswordPolicyConstants.StatusMessage.UpdatePPSettingsSuccess,
                        statusType: StatusType.Success
                    });
                } else {
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: PasswordPolicyConstants.StatusMessage.UpdatePPSettingsError,
                        statusType: StatusType.Error
                    });
                }

            })
            .catch((error) => {
                logger && logger.trackWarning('Error while updatePasswordPolicySettings', { 'Error': error?.response?.data});
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: PasswordPolicyConstants.StatusMessage.UpdatePPSettingsError,
                    statusType: StatusType.Error
                });
                callback();
            });
    }
};

const unloadedState: IPasswordPolicySettings = initialPasswordPolicySettings;

export const reducer: Reducer<IPasswordPolicySettings> = (draftState = unloadedState, incomingAction) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.RECEIVE_PASSWORD_POLICY_SETTINGS:
            const newSettings = action.passwordPolicySettings;
            return newSettings;
    }
    return draftState;
}