import { Reducer } from 'redux';
import axios, { AxiosResponse } from 'axios';

import { AppThunkAction } from 'src/store/index';
import { IPaymentHistoryData, KnownAction, initialPaymentHistoryData } from './PaymentHistory.model';
import { actionTypes } from './PaymentHistory.actions';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';
import { API_BASE_URL, PaymentHistoryConstants } from 'src/helper/Constants';
import { download } from 'src/helper/FileUtilities';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { paymentConstants } from 'src/helper/rbac-constants';
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {
    getPaymentHistoryReportAsync: (queryString: string, callback: (data?: any) => void):
        AppThunkAction<KnownAction> => (dispatch) => {
            axios.get(`${API_BASE_URL}/api/returns/payment-history/${queryString}`, {
                headers: {
                    'X-Resource-Id': paymentConstants.filterPaymentControl
                }
            })
                .then((response: AxiosResponse<IPaymentHistoryData>) => {
                    const { data } = response;
                    if (callback && data) {
                        callback(data);
                    }
                    dispatch({
                        type: actionTypes.RECEIVE_PAYMENT_HISTORY, paymentData: data
                    });
                }).catch((error) => {
                    logger && logger.trackWarning('Error while getPaymentHistoryReportAsync', { 'Error': error?.response?.data});
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: PaymentHistoryConstants.StatusMessage.FetchPaymentHistoryError,
                        statusType: StatusType.Error
                    });
                    dispatch({
                        type: loaderTypes.LOADING,
                        show: false,
                        text: '',
                    });
                });
        },

    exportPaymentListAsExcel: (queryString: string): AppThunkAction<KnownAction> => (dispatch) => {
        axios.get(API_BASE_URL + '/api/returns/payment-history/export-to-excel' + queryString, {
            responseType: 'blob',
            headers: {
                'X-Resource-Id': paymentConstants.exportPaymentHistoryControl
            }
        })
            .then((response: AxiosResponse) => {
                const { data } = response;
                const url = window.URL.createObjectURL(new Blob([data]));
                download(url, `Payment History.xlsx`);
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: PaymentHistoryConstants.StatusMessage.ExportSuccess,
                    statusType: StatusType.Success
                });
            }).catch((error) => {
				logger && logger.trackWarning('Error while exportPaymentListAsExcel', { 'Error': error?.response?.data});
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: PaymentHistoryConstants.StatusMessage.ExportError,
                    statusType: StatusType.Error
                });
            });
    },
};

const unloadedState: IPaymentHistoryData = initialPaymentHistoryData;

export const reducer: Reducer<IPaymentHistoryData> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.RECEIVE_PAYMENT_HISTORY:
            const newState = action.paymentData;
            return newState;
    }
    return state;
};