export function isValidNumber(value: string) {
    const pattern = new RegExp(/^\d+$/);
    return pattern.test(value);
}

export function isValidTenDigitNumber(value: string) {
    const pattern = new RegExp(/^[0-9]{0,10}$/);
    return pattern.test(value);
}

export function isValidNineDigitNumber(value: string) {
    const pattern = new RegExp(/^[0-9]{0,9}$/);
    return pattern.test(value);
}

export function isValidFiveDigitNumber(value: string) {
    const pattern = new RegExp(/^[0-9]{0,5}$/);
    return pattern.test(value);
}

export function isValidPTIN(ptin: string) {
    const ptin_regex = /^[Pp]{0,1}[0-9]{0,8}$/;
    return ptin_regex.test(ptin);
}

export function isValidEmailAddress(emailAddress: string) {
    const pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);
    return pattern.test(emailAddress);
}

export function onlyAlphabetsAndSpaces(value: string) {
    const pattern = new RegExp(/^[a-zA-Z ]{0,100}$/);
    return pattern.test(value);
}

export function formatSsn(ssn: string) {
    const groups = ssn.match(/^(\d{3})(\d{2})(\d{4})$/);
    const formattedSSN = groups && `${groups[1]}-${groups[2]}-${groups[3]}`;
    return formattedSSN ? formattedSSN : ssn;
}

export function formatEin(ein: string) {
    const groups = ein.match(/^(\d{2})(\d{7})$/);
    const formattedEIN = groups && `${groups[1]}-${groups[2]}`;
    return formattedEIN ? formattedEIN : ein;
}

export function formatUSNumber(phoneNumberString: string) {
    if (
        phoneNumberString.length < 14 &&
        phoneNumberString.indexOf('(') >= 0 &&
        phoneNumberString.indexOf(')') >= 0 &&
        phoneNumberString.indexOf(' ') >= 0 &&
        phoneNumberString.indexOf('-') >= 0
    ) {
        if (phoneNumberString.indexOf('(') >= 0) {
            const index = phoneNumberString.indexOf('(');
            phoneNumberString = phoneNumberString.slice(0, index) + phoneNumberString.slice(index + 1);
        }
        if (phoneNumberString.indexOf(')') >= 0) {
            const index = phoneNumberString.indexOf(')');
            phoneNumberString = phoneNumberString.slice(0, index) + phoneNumberString.slice(index + 1);
        }
        if (phoneNumberString.indexOf(' ') >= 0) {
            const index = phoneNumberString.indexOf(' ');
            phoneNumberString = phoneNumberString.slice(0, index) + phoneNumberString.slice(index + 1);
        }
        if (phoneNumberString.indexOf('-') >= 0) {
            const index = phoneNumberString.indexOf('-');
            phoneNumberString = phoneNumberString.slice(0, index) + phoneNumberString.slice(index + 1);
        }
        return phoneNumberString;
    }
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
}

