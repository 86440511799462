import AddIconCircular from 'src/components/svg/AddIconCircular';
import DeleteIconCircular from 'src/components/svg/DeleteIconCircular';
import * as React from 'react';
import { Button } from 'react-bootstrap';

interface EditProps {
	onEditClick: any;
	disabled: boolean;
	parentResourceIdentifier?: string;
}

export const EditButtonFormatter: React.FC<EditProps> = (props) => {
	return (
		<Button
			variant='primary'
			data-resource-id={props.parentResourceIdentifier}
			disabled={props.disabled}
			style={{ background: '#0973BA', border: '1px solid #0973BA', color: '#F8F9FA' }}
			onClick={props.onEditClick}>
			Save
		</Button>
	);
};

interface CancelProps {
	onCancelClick: any;
	disabled: boolean;
}

export const CancelButtonFormatter: React.FC<CancelProps> = (props) => {
	return (
		<Button
			variant='secondary'
			className='btn-white'
			disabled={props.disabled}
			style={{ background: '#FFFFFF', border: '1px solid #898D91', color: '#212529' }}
			onClick={props.onCancelClick}>
			Cancel
		</Button>
	);
};

interface DeleteProps {
	onDeleteClick: any;
	disabled: boolean;
	parentResourceIdentifier?: string;
}

export const DeleteButtonFormatter: React.FC<DeleteProps> = (props) => {
	return (
		<Button
			variant='default'
			className='btn btn-white'
			data-resource-id={props.parentResourceIdentifier}
			disabled={props.disabled}
			onClick={props.onDeleteClick}>
			<DeleteIconCircular /> Delete
		</Button>
	);
};

interface AddProps {
	onAddClick: any;
	disabled: boolean;
	parentResourceIdentifier?: string;
}

export const AddButtonFormatter: React.FC<AddProps> = (props) => {
	return (
		<Button
			variant='default'
			className='btn btn-white'
			data-resource-id={props.parentResourceIdentifier}
			disabled={props.disabled}
			onClick={props.onAddClick}>
			<AddIconCircular /> Add
		</Button>
	);
};
