import React, { useState, Fragment, FC, useEffect } from 'react';
import UserGroups from '../groups/groups';
import UsersInGroup from '../user-in-groups/users-in-group';
import AvailableUsers from '../available-users/available-users';
import createPropsFromObject from './helpers/createPropsFromObject';
import './user-groups-content.scss';
import { IUserGroups, IUserInGroup } from '../../user-groups.types';

interface UserGroupsContentProps {
	groups: IUserGroups[];
	onEdit: (value: IUserGroups) => void;
	onDelete: (value: IUserGroups) => void;
	newGroupIds: number[];
	setApplyDisabled: (applyDisabled: boolean) => void;
	getChangeParams: (value: any) => void;
	selectedGroup: number;
	setSelectedGroup: (value: number) => void;
	handleGroupSelection: (groupId: number) => void;
	usersInGroup: IUserGroups[];
	availableUsers: IUserGroups[];
	usersInGroupBeforeSave: IUserGroups[];
	setUsersInGroup: (value: IUserGroups[]) => void;
	setAvailableUsers: (value: IUserGroups[]) => void;
	handleGroupChange: (value: number) => void;
	setUsersRemoved: (value: IUserInGroup[]) => void;
	applyDisabled: boolean;
	disableUserGroupAllocation: boolean;
	userGroupAllocationDisabledMessage: string;
}


const UserGroupsContent: FC<UserGroupsContentProps> = (props) => {

	const {
		newGroupIds,
		groups,
		onEdit,
		onDelete,
		setApplyDisabled,
		getChangeParams,
		selectedGroup,
		handleGroupSelection,
		usersInGroup,
		availableUsers,
		setUsersInGroup,
		setAvailableUsers,
		handleGroupChange,
		setUsersRemoved,
		usersInGroupBeforeSave,
		applyDisabled,
		disableUserGroupAllocation,
		userGroupAllocationDisabledMessage = ''
	} = props;

	const [groupList, setGroupList] = useState([]);

	const objectsEqual = (object1: any, object2: any) => {
		return Object.keys(object1).length === Object.keys(object2).length
			&& Object.keys(object1).every((index: string) => object1[index] === object2[index]);
	};

	const arrayEqual = (array1: any, array2: any) => {
		return array1.length === array2.length && array1.every((datum: any, index: any) => objectsEqual(datum, array2[index]));
	};

	const handleDropChange = (groupUsers: any, usersAvailable: any, usersRemovedFromGroup?: IUserInGroup[]) => {
		const uniqueGroupUsers = groupUsers.filter((user: any, index: any) => groupUsers.findIndex((u: any) => user.userId === u.userId) === index);
		const uniqueUsersAvailable = usersAvailable.filter((user: any, index: any) => usersAvailable.findIndex((u: any) => user.userId === u.userId) === index);
		if (!arrayEqual(uniqueGroupUsers, usersInGroup) && !arrayEqual(uniqueUsersAvailable, availableUsers)) {
			setUsersInGroup(uniqueGroupUsers);
			setAvailableUsers(uniqueUsersAvailable);
			const changeParams = {
				'groupId': selectedGroup,
				'userIds': uniqueGroupUsers.map((user: any) => user.userId),
			};
			getChangeParams(changeParams);
			if (usersRemovedFromGroup != undefined) {
				setUsersRemoved(usersRemovedFromGroup);
			}
		}
		if(!arrayEqual(uniqueGroupUsers, usersInGroupBeforeSave)) {
			setApplyDisabled(false);
		} else {
			setApplyDisabled(true);
		}
	};

	useEffect(() => {
		handleGroupSelection(groups[0]?.groupId);
		setGroupList(createPropsFromObject(groups, ['groupId', 'groupName', 'groupDescription', 'roles', 'isDefault', 'tooltip']));
	}, [groups]);

	return (
		<Fragment >
			<div className='groups_content_container'>
				<UserGroups
					newGroupIds={newGroupIds}
					groups={groupList}
					onEdit={onEdit}
					onDelete={onDelete}
					handleGroupSelection={handleGroupChange}
					selectedGroup={selectedGroup}
					applyDisabled={applyDisabled}
				/>
				<UsersInGroup
					usersInGroup={usersInGroup}
					availableUsers={availableUsers}
					handleDropChange={handleDropChange}
					disableDragDrop={disableUserGroupAllocation}
					drapDropDisabledMessage={userGroupAllocationDisabledMessage}
				/>
				<AvailableUsers
					usersInGroup={usersInGroup}
					availableUsers={availableUsers}
					handleDropChange={handleDropChange}
					disableDragDrop={disableUserGroupAllocation}
					drapDropDisabledMessage={userGroupAllocationDisabledMessage}
				/>
			</div>
		</Fragment>
	);
};

export default UserGroupsContent;