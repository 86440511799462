import axios, { type AxiosError } from 'axios';

import { API_BASE_URL, CompanySettingsConstants } from '../../../helper/Constants';
import { type AppThunkAction } from '../../../store';
import { StatusType } from '../notification/notification.reducer';
import { actionTypes as notificationTypes } from '../notification/notification.types';
import { actionTypes } from './companySettings.actions';
import { type KnownActions } from './companySettings.types';
import { logger } from 'src/oidcClient/authProvider';

export const requestCompanyLogo = (): AppThunkAction<KnownActions> => (dispatch) => {
	axios
		.get(`${API_BASE_URL}/api/logo/company-logo`)
		.then((response: any) => {
			const { data } = response;
			const isSsrLogo = data.isSsrlogo == 'True';
			dispatch({ type: actionTypes.RECEIVE_COMPANY_LOGO, logoPath: data.logoPath, isSsrLogo });
		})
		.catch((error) => {
			logger && logger.trackWarning('Error while requestCompanyLogo', { 'Error': error?.response?.data});
		});
};

export const requestCompanyId = (): AppThunkAction<KnownActions> => (dispatch) => {
	axios
		.get(`${API_BASE_URL}/api/helper/companyId`)
		.then((response: any) => {
			dispatch({
				type: actionTypes.RECEIVE_COMPANY_ID,
				companyId: response.data,
			});
		})
		.catch(function (error) {
			logger && logger.trackWarning('Error while requestCompanyId', { 'Error': error?.response?.data});
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusMessage: CompanySettingsConstants.StatusMessage.CompanyDetailsError,
				statusType: StatusType.Error,
			});
		});
};
export const requestCompanyProfile = (): AppThunkAction<KnownActions> => (dispatch) => {
	dispatch({
		type: actionTypes.REQUEST_COMPANY_PROFILE,
		isLoading: true,
	});
	axios
		.get(`${API_BASE_URL}/api/company/company-profile`)
		.then((response: any) => {
			dispatch({
				type: actionTypes.RECEIVE_COMPANY_PROFILE,
				companyProfile: response.data.value.company,
			});

			dispatch({
				type: actionTypes.REQUEST_COMPANY_PROFILE,
				isLoading: false,
			});
		})
		.catch(function (error: AxiosError) {
			logger && logger.trackWarning('Error while requestCompanyProfile', { 'Error': error?.response?.data});
			if (error.response != null && error.response.status != 419 && error.response.status != 512)
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusMessage: CompanySettingsConstants.StatusMessage.CompanyProfileError,
					statusType: StatusType.Error,
				});
		});
};

export const requestCompanySignatureUploadLink =
	(callback: (url:string) => void): AppThunkAction<KnownActions> =>
	(dispatch) => {
		axios
			.get(`${API_BASE_URL}/api/upload/company-signature-upload-link`)
			.then((response: any) => {
				const { data } = response;
				callback(data.sas);
			})
			.catch((error: AxiosError) => {
				logger && logger.trackWarning('Error while requestCompanySignatureUploadLink', { 'Error': error?.response?.data});
				if (error.response !== null)
					dispatch({
						type: notificationTypes.NOTIFICATION,
						statusType: StatusType.Error,
						statusMessage: 'Error while fetching company signature upload link',
					});
			});
	};
	  