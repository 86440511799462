import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface IConfirmModalProps {
  show: boolean;
  cancelButtonName?: string;
  confirmButtonName?: string;
  additionalActionButtonName?: string;
  title?: string;
  onSubmit: () => void;
  onHide: () => void;
  children?: React.ReactNode;
  onAdditionalAction?: () => void;
  size?: any;
  className?: string;
}

const ConfirmModal: React.FC<IConfirmModalProps> = (props) => {
  return (
    <Modal
      className={"overlayPopup " + props.className + " confirm-modal"}
      show={props.show}
      onHide={props.onHide}
      size={props.size || "sm"}
    >
      {props.title && (
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
      )}
      {props.children && <Modal.Body>{props.children}</Modal.Body>}
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onHide}>
          {props.cancelButtonName || "Cancel"}
        </Button>
        {props.onAdditionalAction && (
          <Button
            variant="primary"
            onClick={props.onAdditionalAction}
          >
            {props.additionalActionButtonName}
          </Button>
        )}
        <Button variant="primary" onClick={props.onSubmit}>
          {props.confirmButtonName || "Ok"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
