const userTabConstants = {
	prefix: 'SSR_UserMgmt_Users',
	suitePrefix: 'SUITE_UserMgmt_Users',
	addUser: 'AddUser',
	exportList: 'ExportList',
	grid: 'Grid',
	editButton: 'EditBtn',
	deleteButton: 'DltBtn',
	resetPassword: 'ResetPwd',
	eroSign: 'EroSign',
	revokeOffice365: 'RevokeOfc',
	import: 'Import',
	downloadImportUserTemplate: 'DownloadImportUserTemplate',
	auditReport: 'AuditReport',
};

const userGroupsConstants = {
	prefix: 'SSR_UserMgmt_UsersGrps',
	addGroups: 'AddGrps',
	applyChanges: 'ApplyChngs',
	editGroup: 'EditGrps',
	deleteGroup: 'DltGrps',
	selectGroup: 'Select',
};

const clientMngmntResources = {
	addClient: 'SSR_ClientMgmt_AddClient',
	columnOptions: 'SUITE_CM_FilterColumn_Submit',
	gridPrefix: 'SSR_ClientMgmt_Grid',
	deleteButton: 'DeleteClient',
	editClient: 'SSR_ClientMgmt_EditClient',
	exportList: 'SSR_ClientMgmt_ExportList',
	importClientData: 'SSR_ClientMgmt_ImportClientData',
	downloadTemplate: 'SSR_ClientMgmt_DownloadImportTemplate',
	updateClientManagementSetting: 'SUITE_Gnrl_ClientMgmt_Update',
};

const brandingSecResources = {
	prefix: 'SUITE_BrandSec',
	companyLogo: 'CmpnyLogo',
	companyWhiteLogo: 'CmpnyWhtLogo',
	headerDesign: 'ClrPlt',
	EditButton: 'EditBtn',
	DeleteButton: 'DltBtn',
	SaveButton: 'SaveBtn',
};

const developersectionResources = {
	/* Common */
	DeveloperSectionPrefix: 'Dev_',

	/* Developer Section */
	Events: 'Events_',
	APISettings: 'APIStgs_',
	Subscription: 'Sub_',

	/* Action Buttons */
	ButtonApply: 'BtnApply',
	ButtonEdit: 'BtnEdit',
	ButtonDelete: 'BtnDelete',
	ButtonActivateOrDeactivate: 'BtnActOrDeAct',
	ToggleEnableOrDisable: 'TglEnblOrDisbl',
	ButtonDownload: 'BtnDwnld',
	ButtonReplace: 'BtnReplace',
	ButtonSubscribe: 'BtnSubscribe',
	ButtonRegeneratePrimaryKey: 'BtnGenPrimaryKey',
	ButtonRegenerateSecondaryKey: 'BtnGenSecondaryKey',
};

const usageReportResources = {
	Prefix: 'SSR_UsgRprt_',
	ExportButton: 'ExportBtn',
	PrintButton: 'PrintBtn',
	RunReport: 'RunRprt',
};

const ipFilteringResources = {
	prefix: 'SUITE_Secr',
	ipFilterControl: 'IpFltngStg',
	ipFilterTable: 'IpFltng',
	saveButton: 'BtnSave',
	addButton: 'BtnAdd',
	editButton: 'BtnEdit',
	deleteButton: 'BtnDelete',
};

const userPermissionResources = {
	grant: 'SSR_UsrPerm_grant',
	deny: 'SSR_UsrPerm_deny',
};
const approvePasswordResources = {
	apprvPassword: 'SUITE_AprvPass_apprv',
};

const myCompanyResources = {
	prefix: 'SUITE_MyCmpny',
	companyDetails: 'CmpnyDtls',
	primaryAdmin: 'PrmryAdm',
	accountDetails: 'AccDtls',
	addButton: 'AddBtn',
	editButton: 'EditBtn',
	section: 'Section',
};

const paymentConstants = {
	paymentHistoryBuyControl: 'SUITE_PymntHstry_BuyBtn',
	filterPaymentControl: 'SUITE_PymntHstry_FilterBtn',
	exportPaymentHistoryControl: 'SUITE_PymntHstry_ExptExlBtn',
};

const smsNotificationConstants = {
	smsNotificationControl: 'SUITE_Gnrl_Sms_TglEnblOrDisbl',
};

const apiAccessSettingResources = {
	apiAccessControl: 'SUITE_Gnrl_ApiAccess_TglEnblOrDisbl',
};

const TaxReturnSettingsResources = {
	VoucherAndInvoicesPrefix: 'SUITE_VchrAndIns_',

	/* Voucher & Invoices */
	CustomAuthorities: 'CustAuth_',

	ButtonAdd: 'BtnAdd',
	ButtonEdit: 'BtnEdit',
	ButtonDelete: 'BtnDelete',
	ButtonActivateOrDeactivate: 'BtnActOrDeAct',
};

export {
    brandingSecResources,
    clientMngmntResources,
    developersectionResources,
    ipFilteringResources,
    myCompanyResources,
    paymentConstants,
    smsNotificationConstants,
    apiAccessSettingResources,
    TaxReturnSettingsResources,
    usageReportResources,
    userGroupsConstants,
    userPermissionResources,
	userTabConstants,
	approvePasswordResources,
};
