import React from 'react';

export interface SvgImageProps {
	height?: number;
	width?: number;
	style?: React.CSSProperties;
	className?: string;
	onClick?: () => void;
}

const InOfficeGroupIcon: React.FC<SvgImageProps> = (props) => {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={props.className}
			onClick={props.onClick}>
			<g clip-path='url(#clip0_155_953)'>
				<path
					fill-rule='evenodd'
					clip-rule='evenodd'
					d='M15.2582 14.6594C15.393 14.3756 15.4602 14.0645 15.4545 13.7503C15.4545 12.5185 14.8364 11.2503 13.6945 10.3685C14.2645 10.1929 14.8582 10.107 15.4545 10.114C19.0909 10.114 20 12.8412 20 13.7503C20 14.6594 19.0909 14.6594 19.0909 14.6594H15.2582Z'
					fill='#05386B'
				/>
				<path
					d='M15.9091 9.20488C15.3063 9.20488 14.7282 8.96543 14.302 8.53921C13.8758 8.11299 13.6364 7.53492 13.6364 6.93215C13.6364 6.32939 13.8758 5.75131 14.302 5.32509C14.7282 4.89887 15.3063 4.65942 15.9091 4.65942C16.5119 4.65942 17.0899 4.89887 17.5162 5.32509C17.9424 5.75131 18.1818 6.32939 18.1818 6.93215C18.1818 7.53492 17.9424 8.11299 17.5162 8.53921C17.0899 8.96543 16.5119 9.20488 15.9091 9.20488Z'
					fill='#05386B'
				/>
				<path
					d='M6.36364 14.6591C6.36364 14.6591 5.45455 14.6591 5.45455 13.75C5.45455 12.8409 6.36364 10.1136 10 10.1136C13.6364 10.1136 14.5455 12.8409 14.5455 13.75C14.5455 14.6591 13.6364 14.6591 13.6364 14.6591H6.36364ZM10 9.20455C10.7233 9.20455 11.417 8.91721 11.9285 8.40574C12.4399 7.89428 12.7273 7.20059 12.7273 6.47727C12.7273 5.75395 12.4399 5.06026 11.9285 4.5488C11.417 4.03734 10.7233 3.75 10 3.75C9.27668 3.75 8.58299 4.03734 8.07153 4.5488C7.56006 5.06026 7.27273 5.75395 7.27273 6.47727C7.27273 7.20059 7.56006 7.89428 8.07153 8.40574C8.58299 8.91721 9.27668 9.20455 10 9.20455Z'
					fill='#05386B'
				/>
				<path
					fill-rule='evenodd'
					clip-rule='evenodd'
					d='M4.74182 14.6591C4.60705 14.3753 4.53984 14.0641 4.54545 13.75C4.54545 12.5182 5.16364 11.25 6.30545 10.3682C5.73554 10.1926 5.14177 10.1067 4.54545 10.1136C0.909091 10.1136 0 12.8409 0 13.75C0 14.6591 0.909091 14.6591 0.909091 14.6591H4.74182Z'
					fill='#05386B'
				/>
				<path
					d='M4.09091 9.20455C4.69367 9.20455 5.27175 8.9651 5.69797 8.53888C6.12419 8.11266 6.36364 7.53458 6.36364 6.93182C6.36364 6.32905 6.12419 5.75098 5.69797 5.32476C5.27175 4.89854 4.69367 4.65909 4.09091 4.65909C3.48814 4.65909 2.91007 4.89854 2.48385 5.32476C2.05763 5.75098 1.81818 6.32905 1.81818 6.93182C1.81818 7.53458 2.05763 8.11266 2.48385 8.53888C2.91007 8.9651 3.48814 9.20455 4.09091 9.20455Z'
					fill='#05386B'
				/>
			</g>
			<defs>
				<clipPath id='clip0_155_953'>
					<rect
						width='20'
						height='10.9091'
						fill='white'
						transform='translate(0 3.75)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default InOfficeGroupIcon;
