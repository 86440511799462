import { handleKeyDown } from '../../helper/HelperFunctions';
import React from 'react';

export interface SvgImageProps {
	height?: number;
	width?: number;
	style?: React.CSSProperties;
	className?: string;
	onClick?: () => void;
	tabIndex?: number;
}

const RefreshIcon: React.FC<SvgImageProps> = (props) => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			tabIndex={props.tabIndex}
			style={props.style}
			className={props.className}
			onClick={props.onClick}
			onKeyDown={(e) => props.onClick && handleKeyDown(e, props.onClick)}>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M8.00005 2.99999C9.08069 3.00041 10.1321 3.35092 10.9968 3.99903C11.8616 4.64714 12.493 5.55796 12.7967 6.59507C13.1003 7.63218 13.0598 8.73975 12.6812 9.75189C12.3025 10.764 11.6062 11.6263 10.6964 12.2094C9.7866 12.7926 8.71237 13.0653 7.63459 12.9868C6.5568 12.9082 5.53348 12.4826 4.71789 11.7736C3.90231 11.0646 3.33836 10.1105 3.11052 9.05419C2.88268 7.99784 3.0032 6.8961 3.45405 5.91399C3.50312 5.79459 3.50411 5.66083 3.4568 5.54072C3.4095 5.4206 3.31757 5.32344 3.20025 5.26956C3.08294 5.21568 2.94933 5.20927 2.82739 5.25166C2.70546 5.29405 2.60464 5.38196 2.54605 5.49699C2.00506 6.67558 1.86048 7.99772 2.13398 9.26538C2.40747 10.533 3.08432 11.6779 4.06312 12.5286C5.04193 13.3793 6.27001 13.89 7.56341 13.9842C8.8568 14.0783 10.1459 13.7509 11.2376 13.0509C12.3293 12.3509 13.1648 11.3161 13.619 10.1014C14.0732 8.88676 14.1216 7.55762 13.757 6.31311C13.3924 5.0686 12.6344 3.97571 11.5966 3.19815C10.5587 2.42059 9.29687 2.00021 8.00005 1.99999V2.99999Z'
				fill='#05386B'
			/>
			<path
				d='M8.00005 4.46599V0.533995C8.00003 0.486491 7.98648 0.439976 7.96098 0.399896C7.93548 0.359816 7.89909 0.32783 7.85607 0.307684C7.81305 0.287538 7.76518 0.280066 7.71807 0.286142C7.67096 0.292219 7.62655 0.311592 7.59005 0.341995L5.23005 2.30799C5.20191 2.33146 5.17928 2.36082 5.16374 2.39399C5.14821 2.42717 5.14016 2.46336 5.14016 2.49999C5.14016 2.53663 5.14821 2.57282 5.16374 2.60599C5.17928 2.63917 5.20191 2.66853 5.23005 2.69199L7.59005 4.65799C7.62655 4.6884 7.67096 4.70777 7.71807 4.71385C7.76518 4.71992 7.81305 4.71245 7.85607 4.69231C7.89909 4.67216 7.93548 4.64017 7.96098 4.60009C7.98648 4.56001 8.00003 4.5135 8.00005 4.46599Z'
				fill='#05386B'
			/>
		</svg>
	);
};

export default RefreshIcon;
