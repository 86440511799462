import React, { useEffect } from "react";
import { signinRedirectCallback } from "../oidcClient/userService";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import LoadingScreen from "src/components/LoadingScreen";

function SigninOidc() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    async function signinAsync() {
      await signinRedirectCallback();
      if (location.search) {
        const callbackParams = searchParams.get("callback_args");
        if (callbackParams) {
          navigate(callbackParams);
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    }
    signinAsync();
  }, [navigate]);

  return <LoadingScreen />;
}

export default SigninOidc;
