import { connect } from 'react-redux';

import { type ApplicationState } from '../../store/index';
import * as BrandingSettingsStore from './branding-management.apis';
import BrandingManagement from './index';

export default connect(
	(state: ApplicationState) => ({
		companyData: state.companyData,
		brandingData: state.branding,
		userAuth: state.userAuth,
	}),
	{
		...BrandingSettingsStore.actionCreators,
	},
)(BrandingManagement as React.FC);
