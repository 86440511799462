/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import { SessionTimeout } from '@sssuite-component-ui/session-timeout';
import Layout from '@sssuite-component-ui/ss-suite-layout';
import { type IHeaderProps } from '@sssuite-component-ui/ss-suite-layout/dist/Header/Header';
import type * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as PasswordPolicySettingsStore from 'src/modules/securitySettings/PasswordPolicy/PasswordPolicySettings.store';
import { userLoggedOut } from 'src/pages/common/auth/auth.actions';
import { requestSessionIdleMinutes } from 'src/pages/common/auth/auth.apis';
import { actionTypes } from 'src/pages/common/common.actions';

import { createForethoughtCookie, getcookie, injectPendoScript } from '../helper/HelperFunctions';
import { signoutRedirect } from '../oidcClient/userService';
import { actionCreators } from '../pages/branding-management/branding-management.apis';
import { LogoutCause } from '../pages/common/auth/auth.reducer';
import { getSidemenuData, getWalkMeScript, getWidgetData, resetUserCache } from '../pages/common/common.apis';
import { type IHeaderModal } from '../pages/common/common.types';
import {
	requestCompanyId,
	requestCompanyLogo,
	requestCompanyProfile,
} from '../pages/common/companySettings/companySettings.apis';
import LoginHistory from '../pages/common/loginHistory/loginHistory';
import MyAccountModal from '../pages/common/myAccount/myAccountModal';
import { requestMFAOTPLength } from '../pages/common/myAccount/otp.apis';
import { Notification } from '../pages/common/notification';
import { requestUserProfile } from '../pages/common/userProfile/userProfile.apis';
import { fetchLastLoginEnable } from '../pages/common/userSettings/userSettings.apis';
import { type ApplicationState } from '../store';
import LastLoginModal from './common/LastLoginModal';
import UserResourceChangePopup from './common/UserResourceChangePopup';
import { headerConfig, initialHeaderConfig, sideMenuConfig } from './LayoutConfig';
import LogoutConfirmation from './LogoutConfirmation';
import SignalRWebSocket from './SignalRWebSocket';

const WarningVisibleMinutes = 1;

declare global {
	interface Window {
		Variables: any;
		pendo: any;
	}
}

export interface ILayoutProps {
	sideMenuNeeded: boolean;
	hideHeader: boolean;
	children?: React.ReactNode;
}

export const LayoutWrapper: React.FunctionComponent<ILayoutProps> = (props) => {
	const [showLastLogin, setShowLastLogin] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<IHeaderModal>({
		myAccount: false,
		loginHistory: false,
		logout: false,
	});
	const [header, setHeader] = useState<IHeaderProps>(initialHeaderConfig);
	const [isPendoInjected, setIsPendoInjected] = useState<boolean>(false);
	const { enableLastLogin } = useSelector((state: ApplicationState) => state.userSettingsState.defaultSettings);
	const userPrivilegeChange = useSelector((state: ApplicationState) => state.userPrivilege);
	const sessionIdleMinutes = useSelector((appState: ApplicationState) => appState.userAuth.sessionIdleMinutes);
	const userProfile = useSelector((appState: ApplicationState) => appState.userProfile);

	const { user, loggedOut } = useSelector((appState: ApplicationState) => appState.userAuth);

	const { productList } = useSelector((appState: ApplicationState) => appState.welcomeState);
	const { widgetData, sidemenuData } = useSelector((appState: ApplicationState) => appState.commonState);
	const companyData = useSelector((appState: ApplicationState) => appState.companyData);
	const { whiteLogoPath, companyLogoData: { logoPath, isSsrLogo } = {} } = useSelector(
		(appState: ApplicationState) => appState.branding,
	);

	const { isResetCacheApiCalled } = useSelector((appState: ApplicationState) => appState.commonState);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [showUserResourceChangeModal, setShowUserResourceChangeModal] = useState<boolean>(false);

	useEffect(() => {
		const { userId, firstName, lastName, emailAddress } = userProfile;
		const { id: companyId, companyName } = companyData.companyProfile?.companyInfo;
		injectPendoScript(
			companyId,
			companyName,
			userId,
			firstName,
			lastName,
			emailAddress,
			isPendoInjected,
			setIsPendoInjected,
		);
	}, [userProfile, companyData]);

	useEffect(() => {
		if (isResetCacheApiCalled) {
			dispatch(fetchLastLoginEnable());
			dispatch(requestUserProfile());
			dispatch(requestMFAOTPLength());
			dispatch(requestCompanyLogo());
			dispatch(getWidgetData(navigate));
			dispatch(getSidemenuData());
			dispatch(requestCompanyId());
			dispatch(requestCompanyProfile());
			dispatch(actionCreators.getCompanyLogo());
			dispatch(actionCreators.getCompanyWhiteLogo());
			dispatch(PasswordPolicySettingsStore.actionCreators.requestPasswordPolicySettings());
			dispatch(requestSessionIdleMinutes());
			dispatch(
				getWalkMeScript((script: string) => {
					if (script && script.length > 0) {
						const scriptElement = document.createElement('script');
						scriptElement.type = 'text/javascript';
						scriptElement.async = true;
						scriptElement.text = script;
						document.head.appendChild(scriptElement);
						(window as any).loadWalkMe && (window as any).loadWalkMe();
					}
				}),
			);
		}
		if (!isResetCacheApiCalled && getcookie('userAutoLogout') !== '1') {
			dispatch(resetUserCache());
		}
	}, [isResetCacheApiCalled]);

	useEffect(() => {
		if (widgetData.length > 0) dispatch({ type: actionTypes.CHANGE_ACTIVE_STATE, route: window.location.pathname });
	}, [window.location.pathname]);

	useEffect(() => {
		setShowLastLogin(enableLastLogin);
	}, [enableLastLogin]);

	useEffect(() => {
		const isDarkTheme = location.pathname === '/' || location.pathname === '/inoffice-esigning';
		let logoPathCustom = '';
		let isSsrLogoCustom = false;
		if (location.pathname === '/') {
			if (whiteLogoPath && whiteLogoPath.length > 0) logoPathCustom = whiteLogoPath;
			else logoPathCustom = logoPath;
			if (whiteLogoPath && whiteLogoPath.length > 0) isSsrLogoCustom = false;
			else isSsrLogoCustom = isSsrLogo;
		} else {
			logoPathCustom = logoPath;
			isSsrLogoCustom = isSsrLogo;
		}
		setHeader(
			headerConfig(
				showModal,
				setShowModal,
				navigate,
				isDarkTheme,
				logoPathCustom,
				isSsrLogoCustom,
				user,
				userProfile,
				widgetData,
				location.pathname,
				props.hideHeader,
				companyData,
			),
		);
	}, [userProfile, productList, widgetData, location.pathname, logoPath, isSsrLogo, user, companyData]);

	useEffect(() => {
		setAutoLogout();
	}, [userPrivilegeChange]);

	const setAutoLogout = () => {
		if (
			userPrivilegeChange.logoutCause != LogoutCause.None &&
			userPrivilegeChange.userPrivilegesChanged &&
			!showUserResourceChangeModal
		)
			setShowUserResourceChangeModal(true);
	};

	const resetCache = (callback?: () => void): void => {
		dispatch(resetUserCache(callback));
	};

	return (
		<>
			<SessionTimeout
				deviceId={user?.profile?.device_id}
				logout={signoutRedirect}
				sessionIdleMinutes={sessionIdleMinutes}
				warningVisibleMinutes={WarningVisibleMinutes}
				currentTabLoggedOutShow={loggedOut}
				setCurrentTabLoggedOutShow={() => {
					dispatch(userLoggedOut());
				}}
				onExternalLogout={() => createForethoughtCookie('isForethoughtWidgetVisible', 'false')}
			/>

			{isResetCacheApiCalled && (
				<>
					<Notification />
					<Layout
						headerProps={header}
						sideMenuProps={props.sideMenuNeeded ? sideMenuConfig(sidemenuData, navigate) : undefined}>
						{props.children}
					</Layout>
					<LastLoginModal
						show={showLastLogin}
						onHide={setShowLastLogin}
					/>
					<MyAccountModal
						showState={showModal.myAccount}
						onHide={() => {
							setShowModal({ ...showModal, myAccount: false });
						}}
					/>
					<LoginHistory
						show={showModal.loginHistory}
						onHide={() => {
							setShowModal({ ...showModal, loginHistory: false });
						}}
					/>
					{showUserResourceChangeModal && (
						<UserResourceChangePopup
							show={showUserResourceChangeModal}
							setShowUserResourceChangeModal={setShowUserResourceChangeModal}
							logoutCause={userPrivilegeChange.logoutCause}
							resetUserCache={resetCache}
						/>
					)}
					<LogoutConfirmation
						show={showModal.logout}
						onHide={() => {
							setShowModal({ ...showModal, logout: false });
						}}
					/>
				</>
			)}

			{companyData.companyId > 0 && userProfile.userId > 0 && <SignalRWebSocket companyId={companyData.companyId} />}
		</>
	);
};

export default LayoutWrapper;
