import axios from "axios";
import { AppThunkAction } from "../../../store";
import { IPasswordState, KnownAction } from "./password.types";
import { API_BASE_URL, UserManagementConstants } from "../../../helper/Constants";
import { StatusType } from "../notification/notification.reducer";
import { actionTypes } from "./password.actions";
import { actionTypes as notificationTypes } from "../notification/notification.types";
import { logger } from 'src/oidcClient/authProvider';

export const savePassword =
  (
    passwordState: IPasswordState,
    notification: boolean,
    onResponse: (response: any, error: any) => void
  ): AppThunkAction<KnownAction> =>
    (dispatch) => {

      axios
        .put(`${API_BASE_URL}/api/user-management/user-password`,
          passwordState
        )
        .then((response: any) => {
          const data = response.data;
          dispatch({
            type: actionTypes.UPDATE_PASSWORD,
            passwordState: passwordState,
          });
          if (onResponse) {
            onResponse(data, null);
          }
          if (notification) {
            dispatch({
              type: notificationTypes.NOTIFICATION,
              statusMessage: UserManagementConstants.UserUpdationSuccess,
              statusType: StatusType.Success,
            });
          }
        })
        .catch((error) => {
          logger && logger.trackWarning('Error while savePassword', { 'Error': error?.response?.data});
          if (onResponse) {
            onResponse(null, error);
          }
          dispatch({
            type: actionTypes.UPDATE_PASSWORD,
            passwordState: passwordState,
          });
          if (notification) {
            dispatch({
              type: notificationTypes.NOTIFICATION,
              statusMessage: UserManagementConstants.SavingUserError,
              statusType: StatusType.Error,
            });
          }
        });
    };
