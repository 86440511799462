import React, { useEffect } from 'react';
import './InOfficeSearchScreen.style.scss';
import SearchIcon from '../../../components/svg/SearchIcon';
import ClientSearchImage from '../../../components/svg/ClientSearchImage';
import SearchClearIcon from '../../../components/svg/SearchClearIcon';
import { InOfficeScreen } from '..';

interface InOfficeSearchScreenProps {
	inputText: string;
	handleInputTextChange: (searchText: string) => void;
	handleOnSearchClick: (text: string, screenId: InOfficeScreen) => void;
}

const InOfficeSearchScreen: React.FC<InOfficeSearchScreenProps> = (props) => {
	const { inputText, handleInputTextChange, handleOnSearchClick } = props;

	const handleSubmitForm = (e: any) => {
		e.preventDefault();
		handleOnSearchClick(inputText, InOfficeScreen.SearchScreen);
	};
	
	//To restrict navigation to previous page
	useEffect(()=>{
		history.pushState(null, '', window.location.href);
        history.back();
        window.onpopstate = () => history.forward();
	},[]);

	return (
		<div className='inOfficeSearch'>
			<div className='inOfficeSearch_column1'>
				<div className='inOfficeSearch_column1--heading'>Client Search</div>
				<div className='inOfficeSearch_column1--infoText'>Search for a client to e-Sign tax return documents</div>
				<div className='inOfficeSearch_column1--searchClient'>
					<label htmlFor='clientSearch'>Search for Client ID or Client Name</label>
					<form
						onSubmit={handleSubmitForm}
						className={'searchClient'}>
						<input
							type='text'
							id='clientSearch'
							placeholder='Search'
							value={inputText}
							maxLength={500}
							onChange={(e: any) => handleInputTextChange(e.target.value)}
						/>
						{inputText && (
							<SearchClearIcon
								className='crossIcon'
								onClick={() => {
									handleInputTextChange('');
								}}
								tabIndex={0}
							/>
						)}
					</form>
				</div>
				<button
					className='inOfficeSearch_column1--searchBtn'
					onClick={() => handleOnSearchClick(inputText, InOfficeScreen.SearchScreen)}
					disabled={!inputText.trim()}>
					<SearchIcon color={'#FFFFFF'} />
					<span>Search</span>
				</button>
			</div>
			<div className='inOfficeSearch_column2'>
				<ClientSearchImage />
			</div>
		</div>
	);
};

export default InOfficeSearchScreen;
