import React from 'react';

const PurchaseSuccessIcon: React.FC = () => {
	return (
		<svg
			width='97'
			height='96'
			viewBox='0 0 97 96'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<circle
				cx='48.5'
				cy='48'
				r='48'
				fill='#669440'
			/>
			<path
				d='M31.8045 64.5654C34.6483 67.4548 38.2061 69.54 42.1168 70.61C46.0273 71.68 50.1512 71.6964 54.07 70.6573C57.9887 69.6183 61.5628 67.5611 64.4299 64.6942C67.2966 61.8275 69.3537 58.2534 70.3927 54.3343C71.4317 50.4156 71.4154 46.2915 70.3457 42.3811C69.2758 38.4706 67.1902 34.9127 64.3011 32.0688C62.1318 29.8923 59.5461 28.1755 56.6983 27.0211C51.1505 24.7859 44.953 24.7859 39.4054 27.0211C36.5567 28.1742 33.9707 29.891 31.8027 32.0688C31.3463 32.5252 31.3463 33.2652 31.8027 33.7217C32.2591 34.1781 32.9992 34.1781 33.4556 33.7217C35.4036 31.7655 37.7268 30.2229 40.2857 29.1865C45.2695 27.1815 50.8358 27.1815 55.8193 29.1865C59.6239 30.7337 62.8807 33.3803 65.1735 36.7882C67.4667 40.1957 68.6911 44.21 68.6911 48.3174C68.6911 52.4249 67.4666 56.4388 65.1735 59.8465C62.8806 63.2544 59.6237 65.901 55.8193 67.4483C50.8358 69.4565 45.2682 69.4565 40.2857 67.4483C37.5393 66.3303 35.0655 64.6354 33.0315 62.4781C30.9978 60.3205 29.4512 57.7515 28.4966 54.9442C27.5221 52.042 27.1987 48.9605 27.5495 45.9188C27.6244 45.2779 27.1654 44.6975 26.5245 44.6226C25.8832 44.5479 25.3029 45.007 25.2283 45.6479C24.8374 49.0326 25.1973 52.462 26.283 55.6915C27.4097 59.0381 29.2999 62.0762 31.8043 64.565L31.8045 64.5654Z'
				fill='white'
			/>
			<path
				d='M45.9894 54.8098H46.0085C47.4917 54.8064 48.9134 54.2454 49.9617 53.2492L59.43 44.2582C59.9699 43.7271 59.9615 42.8833 59.4117 42.3616C58.8619 41.8399 57.9734 41.8331 57.4145 42.3462L47.9441 51.3463C47.415 51.8446 46.697 52.1205 45.9517 52.1111C45.1983 52.0975 44.4839 51.791 43.9759 51.2632L38.8654 46.2122C38.322 45.6708 37.4195 45.6508 36.8498 46.1669C36.2801 46.6829 36.2587 47.5398 36.8025 48.0808L41.8959 53.1122H41.8955C42.9565 54.1885 44.437 54.8023 45.9894 54.8098Z'
				fill='white'
			/>
		</svg>
	);
};

export default PurchaseSuccessIcon;
