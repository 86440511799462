import { actionTypes } from './companySettings.actions';
import { NotificationAction } from '../notification/notification.reducer';

export enum AuthenticationProvider {
	None,
	AzureAD,
	SAML,
	Google,
}

export interface ILogoData {
	isSSRLogo: boolean;
	logoPath: string;
}

export interface ReceiveCompanyIdAction {
	type: actionTypes.RECEIVE_COMPANY_ID;
	companyId: number;
}

export interface ReceiveCompanyLogo {
	type: actionTypes.RECEIVE_COMPANY_LOGO;
	isSsrLogo: boolean;
	logoPath: string;
}

export enum SubscriptionTier {
	None = 0,
	SuiteEssentials = 1,
	SuitePreferred = 2,
}

export interface ICompanyData {
	companyId: number;
	isLoading: boolean;
	isSsrLogo: boolean;
	logoPath: string;
	companyProfile: ICompanyProfile;
	companyUploadProfileLink: string;
}

export const initialCompanyProfile: ICompanyProfile = {
	companyInfo: {
		companyName: '',
		phoneNumber: '',
		fax: '',
		website: '',
		address: '',
		unit: '',
		city: '',
		stateId: 0,
		zip: 0,
		adminUser: 0,
		isSsrLogo: false,
		id: 0,
		isCompanyNameAsWhiteLogo: false,
		isDisabled: false,
	},
	companySubscription: {
		id: 0,
		name: '',
		products: [],
	},
	companyProducts: [],
	countryStates: [
		{
			id: 0,
			name: '',
			image: '',
		},
	],
};
export interface ICountryState {
	id: number;
	name: string;
	image: string;
}

export interface ICompanySubscription {
	id: number;
	name: string;
	products: IProductInfo[];
}

export interface ICompanyProduct {
	productId: ProductType;
	productStatus: ProductStatus;
	isSubscribed: boolean;
}
export interface ICompanyInfo {
	companyName: string;
	phoneNumber: string;
	fax: string;
	website: string;
	address: string;
	unit: string;
	city: string;
	stateId: number;
	zip: number;
	adminUser: number;
	isSsrLogo: boolean;
	id: number;
	isCompanyNameAsWhiteLogo: boolean;
	isDisabled: boolean;
}
export interface ICompanyProfile {
	companyInfo: ICompanyInfo;
	countryStates: ICountryState[];
	companySubscription: ICompanySubscription;
	companyProducts: ICompanyProduct[];
}

export interface IProductInfo {
	name: string;
	pricingInfos: IPricingInfo[];
	productType: ProductType;
}

export interface IPricingInfo {
	id: number;
	maxQuantity: number;
	minQuantity: number;
	price: number;
}

export enum ProductType {
	None = 0,
	Returns = 1,
	SignaturesOneOff = 2,
	SignaturesMailMerge = 3,
	Organizers = 4,
	Extensions = 5,
	Exchange = 6,
	TicTieCalculate = 8,
	SSSuitePortal = 1001,
}

export enum ProductStatus {
	None = 0,
	Enabled = 1,
	Disabled = 2,
}

export interface ReceiveCompanyProfile {
	type: actionTypes.RECEIVE_COMPANY_PROFILE;
	companyProfile: ICompanyProfile;
}

export type DispatchActions = ReceiveCompanyIdAction | ReceiveCompanyLogo | ReceiveCompanyProfile;

export type KnownActions = DispatchActions | NotificationAction;
