/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { LayoutWrapper } from './components/LayoutWrapper';
import LoadingScreen from './components/LoadingScreen';
import { signinRedirect, signinRedirectWithCallbackArgs } from './oidcClient/userService';
import { type ApplicationState } from './store';

interface IProdectedRoute {
	component: React.FC;
	sideMenuNeeded: boolean;
	hideHeader?: boolean;
	path?: string;
}

function ProtectedRoute({ component: Component, sideMenuNeeded, hideHeader = false, ...rest }: IProdectedRoute) {
	const userState = useSelector((state: ApplicationState) => state.userAuth);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		(function login() {
			const path = location.pathname + location.search;
			if (!userState.isLoadingUser && !userState.user)
				if (path === '/') signinRedirect();
				else signinRedirectWithCallbackArgs(path);
			else navigate(rest.path ? rest.path : path);
		})();
	}, []);

	return userState.user ? (
		<LayoutWrapper
			sideMenuNeeded={sideMenuNeeded}
			hideHeader={hideHeader}>
			<Component />
		</LayoutWrapper>
	) : (
		<LoadingScreen />
	);
}

export default ProtectedRoute;
