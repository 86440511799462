import axios, { AxiosResponse } from 'axios';
import { ISaleTaxItem, ITaxInfo,ISaleTaxSetting } from './Tax.model';
import { API_BASE_URL } from 'src/helper/Constants';

export const getSalesTaxDetails = (saleTaxItem: ISaleTaxItem): Promise<ITaxInfo> => {
    return axios.post(API_BASE_URL + '/api/returns/pay-now/calculate-tax', saleTaxItem)
        .then((response: AxiosResponse<ITaxInfo>) => {
            const { data } = response;
            return data;
        });
}

export const getSaleSetting = (): Promise<ISaleTaxSetting> => {
    return axios.get(API_BASE_URL + '/api/sale-tax-setting')
        .then((response: AxiosResponse<ISaleTaxSetting>) => {
            const { data } = response;
            return data;
        });
}