import { Reducer } from 'redux';
import { AppThunkAction } from '../../store/index';
import { actionTypes } from './developer-section.actions';
import { StatusType ,NotificationAction } from 'src/pages/common/notification/notification.reducer';
import { DocumentEvents,EventServiceStatus, IClientAPIDetail, IEventSettings,IProductSubscription,EventType } from './developer-section.model';
import { API_BASE_URL ,EventSettingsConstants, ProductType } from '../../helper/Constants';
import { actionTypes as notificationActionTypes } from '../../pages/common/notification/notification.types';
import axios, { AxiosResponse } from 'axios';
import {download, getFileName} from '../../helper/FileUtilities';
import { logger } from 'src/oidcClient/authProvider';

export interface IEventSettingsStoreState {
    documentEvents: DocumentEvents;
    productSubscriptions : IProductSubscription[]
    isLoading: boolean;
}

interface ReceiveUserSignedEventSettingsAction {
    type: actionTypes.RECEIVE_USER_SIGNED_EVENT_SETTINGS;
    documentEvents: DocumentEvents;
    isLoading: boolean;
}

interface RequestUserSignedEventSettingsAction {
    type: actionTypes.REQUEST_USER_SIGNED_EVENT_SETTINGS;
    isLoading: boolean;
}

interface RequestEventServiceStatusAction {
    type: actionTypes.REQUEST_EVENT_SERVICE_STATUS;
    isLoading: boolean;
}

interface ReceiveEventServiceStatusAction {
    type: actionTypes.RECEIVE_EVENT_SERVICE_STATUS;
    serviceStatus: EventServiceStatus;
    isLoading: boolean;
}

interface RequestRestartUserSignedEventServiceAction {
    type: actionTypes.REQUEST_RESTART_USER_SIGNED_EVENT_SERVICE;
    isLoading: boolean;
}

interface ResponseRestartUserSignedEventServiceAction {
    type: actionTypes.RESPONSE_RESTART_USER_SIGNED_EVENT_SERVICE;
    serviceStatus: EventServiceStatus;
    isLoading: boolean;
}


interface RequestProductSubscriptionAction{
    type: actionTypes.REQUEST_PRODUCT_SUBSCRIPTION;    
    isLoading:boolean;
}

interface ResponseProductSubscriptionAction{
    type: actionTypes.RESPONSE_PRODUCT_SUBSCRIPTION;
    productSubscriptions : IProductSubscription[];
    isLoading:boolean;
}

interface ShowHideLoadingWindow{
    type: actionTypes.SHOW_HIDE_LOADING_WINDOW;   
    isLoading:boolean;
}

type KnownAction = ReceiveUserSignedEventSettingsAction
    | RequestUserSignedEventSettingsAction
    | RequestEventServiceStatusAction
    | ReceiveEventServiceStatusAction
    | RequestRestartUserSignedEventServiceAction
    | ResponseRestartUserSignedEventServiceAction
    | NotificationAction
    | RequestProductSubscriptionAction
    | ResponseProductSubscriptionAction
    | ShowHideLoadingWindow;

export const actionCreators = {

    requestEventSettings: (reload: boolean): 
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: actionTypes.REQUEST_USER_SIGNED_EVENT_SETTINGS, isLoading: true });
            axios.get(API_BASE_URL + '/api/developer-section')
                .then((response: AxiosResponse) => response.data as Promise<DocumentEvents>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_USER_SIGNED_EVENT_SETTINGS, documentEvents: data, isLoading: false });
                })
                .catch(function (error) {
                    logger && logger.trackWarning('Error while requestEventSettings', { 'Error': error?.response?.data});
                    dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                    dispatch({ type: actionTypes.REQUEST_USER_SIGNED_EVENT_SETTINGS, isLoading: false });
                });
        },

    addDocumentEvent: (eventSettings: IEventSettings, callback?: () => void, resourceId: string = ""): 
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: actionTypes.REQUEST_USER_SIGNED_EVENT_SETTINGS, isLoading: true });
                axios.post(API_BASE_URL + '/api/developer-section', eventSettings, {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'X-Resource-Id': resourceId,
                    },
                    withCredentials: true,
                }).then((response : AxiosResponse) => {                
                    dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: EventSettingsConstants.UpdateEventSettingsSuccess, 
                                        statusType: StatusType.Success });
                    if (callback) callback();
                })
                .catch(function (error) {
                    logger && logger.trackWarning('Error while addDocumentEvent', { 'Error': error?.response?.data});
                    dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                    dispatch({ type: actionTypes.REQUEST_USER_SIGNED_EVENT_SETTINGS, isLoading: false });
                });              
        },       

    changeEventStatus: (eventType: EventType, productType : ProductType, enabled: boolean, callback?: () => void, resourceId: string = ""):
    AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_USER_SIGNED_EVENT_SETTINGS, isLoading: true });   
        axios.patch(API_BASE_URL + '/api/developer-section/'+ eventType +'/change-status/?productType=' + productType + '&enabled=' + enabled, {
            headers: {
                'X-Resource-Id': resourceId,
            }
        })
        .then((response : AxiosResponse) => {
            if(callback)callback();
        }) 
        .catch(function (error) {
            logger && logger.trackWarning('Error while changeEventStatus', { 'Error': error?.response?.data});
            dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
            dispatch({ type: actionTypes.REQUEST_USER_SIGNED_EVENT_SETTINGS, isLoading: false });            
        });
    },

    addDocumentEventAPI: (clientAPIDetail: IClientAPIDetail, callback?: () => void, resourceId: string = ""): 
    AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_USER_SIGNED_EVENT_SETTINGS, isLoading: true });
            axios.post(API_BASE_URL + '/api/developer-section/client-api', clientAPIDetail, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Resource-Id': resourceId,
                },
                withCredentials: true,
            }).then((response : AxiosResponse) => {                
                dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: EventSettingsConstants.UpdateEventSettingsSuccess, 
                                    statusType: StatusType.Success });
                if (callback) callback();
            })
            .catch(function (error) {
                logger && logger.trackWarning('Error while addDocumentEventAPI', { 'Error': error?.response?.data});
                dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                dispatch({ type: actionTypes.REQUEST_USER_SIGNED_EVENT_SETTINGS, isLoading: false });
            });              
    },   

    resumeEventService: (eventType: EventType, productType : ProductType, callback?: () => void):
    AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_RESTART_USER_SIGNED_EVENT_SERVICE, isLoading: true });
        axios.patch(API_BASE_URL + '/api/developer-section/'+ eventType+'/resume/?productType=' + productType, {          
        })
        .then((response : AxiosResponse) => {
            if(callback)callback();
        }) 
        .catch(function (error) {
            logger && logger.trackWarning('Error while resumeEventService', { 'Error': error?.response?.data});
            dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
            dispatch({ type: actionTypes.RESPONSE_RESTART_USER_SIGNED_EVENT_SERVICE, serviceStatus: EventServiceStatus.Stopped, isLoading: false });          
        });
    },

    testEndpointAsync: (apiLink: string, authCred: string, callback: (eventServiceStatus: EventServiceStatus) => void): 
    AppThunkAction<KnownAction> => (dispatch, getState) => {
        const requestData = {
            apiEndPoint: apiLink,
            apiKey: authCred
        }

        axios.post(API_BASE_URL + '/api/developer-section/api-status', requestData)
                .then((response: AxiosResponse) => {        
                callback(EventServiceStatus.Running);
                dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: EventSettingsConstants.TestEventAPISuccessStatus + response.data, statusType: StatusType.Success });          
            })          
            .catch(function (error) {     
                logger && logger.trackWarning('Error while testEndpointAsync', { 'Error': error?.response?.data});                       
                let message = error.response.status === 500 ? error.response.data : error;
                dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: message, statusType: StatusType.Error });
                callback(EventServiceStatus.Stopped);
            });
    },

    downloaddocumentAsync: (productType : ProductType, callback?:() => void): 
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: actionTypes.SHOW_HIDE_LOADING_WINDOW, isLoading : true });
            axios.get(API_BASE_URL + '/api/developer-section/api-documentation/?productType='+ productType,{
                responseType: 'blob',
            })
                .then((response: AxiosResponse) => {  
                    dispatch({ type: actionTypes.SHOW_HIDE_LOADING_WINDOW, isLoading : false });
                    const { data } = response;
                    const fileName : any = getFileName(response)                    
                    const url = window.URL.createObjectURL(new Blob([data]));
                    download(url, fileName);
                    if(callback)callback();   
                })
                .catch(function (error) {
                    logger && logger.trackWarning('Error while downloaddocumentAsync', { 'Error': error?.response?.data});
                    dispatch({ type: actionTypes.SHOW_HIDE_LOADING_WINDOW, isLoading : false });
                    if(callback)callback();             
                    dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                });
    },

    downloadLogAsync: (eventType : EventType, productType : ProductType): 
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: actionTypes.SHOW_HIDE_LOADING_WINDOW, isLoading : true });
            axios.get(API_BASE_URL + '/api/developer-section/logs/'+ productType + '/'+eventType,{
                responseType: 'blob',
            })
                .then((response: AxiosResponse) => {                                          
                    dispatch({ type: actionTypes.SHOW_HIDE_LOADING_WINDOW, isLoading : false });
                    const { data } = response;
                    if(response.data.type == "application/json"){
                        const fileName : any = getFileName(response)                    
                        const url = window.URL.createObjectURL(new Blob([data]));
                        download(url, fileName);                    
                    }
                    else {                        
                        data.text().then((msg: string) =>  {
                            dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: msg, statusType: StatusType.Warning });
                            }
                        ).catch((error: any) => {
                            dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });    
                        });                         
                    }
                })
                .catch(function (error) {
                    logger && logger.trackWarning('Error while downloadLogAsync', { 'Error': error?.response?.data});
                    dispatch({ type: actionTypes.SHOW_HIDE_LOADING_WINDOW, isLoading : false });                                
                    dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                });
    },

    getProduct: (): 
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: actionTypes.REQUEST_PRODUCT_SUBSCRIPTION, isLoading: true }); 
            axios.get(API_BASE_URL + '/api/apim-subscription/product')
                .then((response: AxiosResponse<IProductSubscription>) => {                
                    dispatch({ type: actionTypes.RESPONSE_PRODUCT_SUBSCRIPTION, productSubscriptions: [response.data], isLoading: false });
                })          
                .catch(function (error) {
                    logger && logger.trackWarning('Error while getProduct', { 'Error': error?.response?.data});
                    dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });   
                    dispatch({ type: actionTypes.REQUEST_PRODUCT_SUBSCRIPTION, isLoading: false });     
                });
        },

    subscribe: (productId: string,callback?: () => void,resourceId : string =""):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: actionTypes.REQUEST_PRODUCT_SUBSCRIPTION, isLoading: true }); 
            axios.post(API_BASE_URL + '/api/apim-subscription',{ productId:productId,name : productId + "_subscription"}, {
                headers: {
                    'X-Resource-Id': resourceId,
                }
            })
            .then((response : AxiosResponse) => {
                dispatch({ type: actionTypes.REQUEST_PRODUCT_SUBSCRIPTION, isLoading: false });     
                dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: EventSettingsConstants.Subscribed, statusType: StatusType.Success });
                if(callback)callback();
            }) 
            .catch(function (error) {
                logger && logger.trackWarning('Error while subscribe', { 'Error': error?.response?.data});
                dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                dispatch({ type: actionTypes.REQUEST_PRODUCT_SUBSCRIPTION, isLoading: false });             
            });
        },

    regeneratePrimaryKey: (subscriptionId: string,callback?: () => void,resourceId : string =""):
    AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_PRODUCT_SUBSCRIPTION, isLoading: true }); 
        axios.put(API_BASE_URL + '/api/apim-subscription/regenerate-primary-key/'+ subscriptionId, {
            headers: {
                'X-Resource-Id': resourceId,
            }
        })
        .then((response : AxiosResponse) => {
            dispatch({ type: actionTypes.REQUEST_PRODUCT_SUBSCRIPTION, isLoading: false });     
            dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: EventSettingsConstants.PrimaryKeyRegenerated, statusType: StatusType.Success });
            if(callback)callback();
        }) 
        .catch(function (error) {
            logger && logger.trackWarning('Error while regeneratePrimaryKey', { 'Error': error?.response?.data});
            dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });  
            dispatch({ type: actionTypes.REQUEST_PRODUCT_SUBSCRIPTION, isLoading: false });             
        });
    },

    regenerateSecondaryKey: (subscriptionId: string,callback?: () => void,resourceId : string =""):
    AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_PRODUCT_SUBSCRIPTION, isLoading: true }); 
        axios.put(API_BASE_URL + '/api/apim-subscription/regenerate-secondary-key/'+ subscriptionId, {
            headers: {
                'X-Resource-Id': resourceId,
            }
        })
        .then((response : AxiosResponse) => {
            dispatch({ type: actionTypes.REQUEST_PRODUCT_SUBSCRIPTION, isLoading: false });     
            dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: EventSettingsConstants.SecondaryKeyRegenerated, statusType: StatusType.Success });
            if(callback)callback();
        }) 
        .catch(function (error) {
            logger && logger.trackWarning('Error while regenerateSecondaryKey', { 'Error': error?.response?.data});
            dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
            dispatch({ type: actionTypes.REQUEST_PRODUCT_SUBSCRIPTION, isLoading: false });             
        });    
    },

   
};

const unloadedState: IEventSettingsStoreState =
    {
        documentEvents: {
            eventSettings:[],
            clientAPIDetail: []
        },
        productSubscriptions : [],
        isLoading: false
    } as IEventSettingsStoreState;

export const reducer: Reducer<IEventSettingsStoreState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_USER_SIGNED_EVENT_SETTINGS:
            return {
                ...state,
                isLoading: action.isLoading
            } as IEventSettingsStoreState;
        case actionTypes.RECEIVE_USER_SIGNED_EVENT_SETTINGS:
            return {
                ...state,
                documentEvents: action.documentEvents,
                isLoading: action.isLoading
            } as IEventSettingsStoreState;
        case actionTypes.REQUEST_PRODUCT_SUBSCRIPTION:                
        case actionTypes.REQUEST_EVENT_SERVICE_STATUS:
        case actionTypes.REQUEST_RESTART_USER_SIGNED_EVENT_SERVICE:
        case actionTypes.RECEIVE_EVENT_SERVICE_STATUS:
            return {
                ...state,
                isLoading: action.isLoading
            } as IEventSettingsStoreState;
        case actionTypes.RESPONSE_RESTART_USER_SIGNED_EVENT_SERVICE:
            const documentEvents = state.documentEvents;
            // documentEvents.eventSettings.serviceStatus = action.serviceStatus;
            return {
                ...state,
                documentEvents: documentEvents,
                isLoading: action.isLoading
            } as IEventSettingsStoreState;
        case actionTypes.RESPONSE_PRODUCT_SUBSCRIPTION:            
            return {
                ...state,
                productSubscriptions : action.productSubscriptions,
                isLoading: action.isLoading
            } as IEventSettingsStoreState;
        case actionTypes.SHOW_HIDE_LOADING_WINDOW:
            return {
                ...state,
                isLoading : action.isLoading
            } as IEventSettingsStoreState;     
        default:
            return state;
    }   
};
