import React, { FC, useEffect, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { PaymentStatus } from './PurchaseReturn.model';
import { useNavigate, useParams } from 'react-router-dom';
import SuiteModal from 'src/components/common/SuiteModal';
import styles from './PaymentStatus.module.scss';

type PaymentInfoProps = {
	pageTitle: string;
	paymentStatus: PaymentStatus;
};

const PaymentInfo: FC<PaymentInfoProps> = (props) => {
	const [redirect, setRedirect] = useState(false);
	const [pageState, setPageState] = useState('');
	const navigate = useNavigate();
	const { page } = useParams();

	const onOkClick = () => {
		let temppage = '/usage-report';
		if (page) {
			temppage = '/' + page;
		}
		setPageState(temppage);
		setRedirect(true);
	};

	const onHide = () => {};

	const paymentStatusRender = () => {
		return (
			<SuiteModal
				width='600'
				theme='light'
				title={
					props.paymentStatus === PaymentStatus.Success
						? 'Transaction Successful'
						: props.paymentStatus === PaymentStatus.Cancelled
						? 'Payment Unsuccessful'
						: 'Invalid Link'
				}
				hide={onHide}
				className='paymentStatus'>
				{props.paymentStatus === PaymentStatus.Success ? (
					<>
						<Modal.Body className='show-grid'>
							<Container>
								<div
									className={`alert alert-success ${styles.success}`}
									role='alert'>
									<h3 className='alert-heading'>Thank you for your purchase.</h3>
								</div>
							</Container>
						</Modal.Body>
						<Modal.Footer>
							<Button
								data-test-auto='2ccaa620-58d1-4c45-b509-70f71c01405a'
								variant='primary'
								onClick={onOkClick}>
								Close
							</Button>
						</Modal.Footer>
					</>
				) : props.paymentStatus === PaymentStatus.Cancelled ? (
					<>
						<Modal.Body className='show-grid'>
							<Container>
								<div
									className={`alert alert-danger ${styles.success}`}
									role='alert'>
									<h3 className='alert-heading'>Payment Unsuccessful.</h3>
								</div>
							</Container>
						</Modal.Body>
						<Modal.Footer>
							<Button
								data-test-auto='c14550cb-62b5-4067-9b6f-a3f5e4082487'
								variant='primary'
								onClick={onOkClick}>
								Close
							</Button>
						</Modal.Footer>
					</>
				) : (
					<>
						<Modal.Body className='show-grid'>
							<Container>
								<div
									className={`alert alert-warning ${styles.success}`}
									role='alert'>
									<h3 className='alert-warning'>Invalid Transaction.</h3>
								</div>
							</Container>
						</Modal.Body>
						<Modal.Footer>
							<Button
								data-test-auto='d74a7cc4-1c34-46e9-bd19-a07f06e8b902'
								variant='primary'
								onClick={onOkClick}>
								Close
							</Button>
						</Modal.Footer>
					</>
				)}
			</SuiteModal>
		);
	};

	useEffect(() => {
		if (redirect) {
			navigate(pageState);
		}
	}, [redirect]);

	return paymentStatusRender();
};

export default PaymentInfo;
