import React, { FC } from "react";
import { Headphone } from "../svg/IconCollection";
import "../../pages/welcome/style.scss";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { Container } from "react-bootstrap";
import { getHelpUrl } from "../../pages/welcome/welcome.apis";

const TopSection: FC = () => {
    const { firstName } = useSelector((state: ApplicationState) => state.userProfile);
    const dispatch = useDispatch();

    const onHelpClick = () => {
        dispatch(getHelpUrl());
    };
    return <section className="top-section">
        <Container fluid="sm">
            <div className="greetings">{`Welcome back, ${firstName}!`}</div>
            <button className="button-gray-rounded" onClick={onHelpClick}>
                <Headphone />
                <span className="ms-2">Help Center</span>
            </button>
        </Container>
    </section>;
};

export default TopSection;