import React, { useEffect, useRef, useState } from 'react';
import { Modal, Container, Form, Row, Col, Button } from 'react-bootstrap';
import CloseIcon from 'src/components/svg/CloseIcon';
import './ClientModal.style.scss';
import PhoneInput from 'react-phone-input-2';
import { IClientModel, IEro, PageProps, initialClient, IOfficeLocation } from '../Client.model';
import { CountryData } from '../../../components/common/myAccount/profile';
import { isValidEmailAddress } from '../../../helper/formValidations';
import { addClient, editClient } from '../Client.api';
import CustomDropdown from '../../../components/common/customDropdown';
import CustomDatepicker from '../../../components/common/customDatepicker';
import Calender from '../../../components/svg/Calender';
import TaxIdentificationNumber from '../../../components/common/taxIdentificationNumber';
import { ISortColumn } from '..';
import { getFormattedUID, isValidUID } from '../../../helper/HelperFunctions';
import CustomDropdownWithCustomOptions, {
	ICustomDropdownOption,
} from '../../../components/common/customDropdown/CustomDropdownWithCustomOptions';
import { getSMSNotification } from 'src/modules/generalSettings/SMSNotifications/SMSNotifications.store';
import { ISMSNotificationResponse } from 'src/modules/generalSettings/SMSNotifications/SMSNotifications.model';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { SMSNotificationsConstant } from 'src/helper/Constants';
import { useDispatch } from 'react-redux';
import { logger } from '../../../oidcClient/authProvider';

interface ClientModalProps {
	setShowClientModal: (showClientModal: boolean) => void;
	requestAllClients: (
		reload?: boolean,
		pageNo?: number,
		pageSize?: number,
		sortBy?: string,
		sortOrder?: string,
		filterText?: string,
		newClientBasicInfoIds?: string,
		filterLocation?: string,
	) => void;
	page: PageProps;
	searchText: string;
	sortColumn: ISortColumn;
	eros: IEro[];
	client: IClientModel;
	setClient: (selectedClient: IClientModel) => void;
	newClientBasicInfoIds: number[];
	setPage: (page: PageProps) => void;
	setNewClientBasicInfoIds: (newClientBasicInfoIds: number[]) => void;
	officeLocations: IOfficeLocation[];
	selectedOfficeLocations: ICustomDropdownOption[];
}

enum clientType {
	SSN = 1,
	EIN = 2,
}

const ClientModal: React.FC<ClientModalProps> = (props) => {
	const {
		setShowClientModal,
		requestAllClients,
		eros,
		client,
		setClient,
		page,
		sortColumn,
		searchText,
		setPage,
		newClientBasicInfoIds,
		setNewClientBasicInfoIds,
		officeLocations,
		selectedOfficeLocations,
	} = props;

	const [initialMobileNumber, setInitialMobileNumber] = useState(String);
	const [initialSpouseMobileNumber, setInitialSpouseMobileNumber] = useState(String);

	const containerRef = useRef<HTMLInputElement>(null);

	const [inProgress, setInProgress] = useState(false);
	const [einSelected, setEinSelected] = useState(false);
	const [selectedClientDate, setSelectedClientDate] = useState<Date | null | undefined>(null);
	const [selectedSpouseDate, setSelectedSpouseDate] = useState<Date | null | undefined>(null);
	const [mandatoryFieldError, setMandatoryFieldError] = useState({
		clientId: { error: false, message: '' },
		uid: { error: false, message: '' },
		name: { error: false, message: '' },
		emailAddress: { error: false, message: '' },
		countryCode: { error: false, message: '' },
		mobileNumber: { error: false, message: '' },
		ero: { error: false, message: '' },
		type: { error: false, message: '' },
		dob: { error: false, message: '' },
		spouseSsn: { error: false, message: '' },
		spouseName: { error: false, message: '' },
		spouseEmailAddress: { error: false, message: '' },
		spouseDOB: { error: false, message: '' },
		spouseMobileNumber: { error: false, message: '' },
		spouseCountryCode: { error: false, message: '' },
		officeLocation: { error: false, message: '' },
	});

	const dispatch = useDispatch();
	const [isSMSNotificationEnabled, setIsSMSNotificationEnabled] = useState(false);

	useEffect(() => {
		if (containerRef.current !== null) {
			containerRef.current.scrollIntoView();
		}
		setMandatoryFieldError({
			clientId: { error: false, message: '' },
			uid: { error: false, message: '' },
			name: { error: false, message: '' },
			emailAddress: { error: false, message: '' },
			countryCode: { error: false, message: '' },
			mobileNumber: { error: false, message: '' },
			ero: { error: false, message: '' },
			type: { error: false, message: '' },
			dob: { error: false, message: '' },
			spouseSsn: { error: false, message: '' },
			spouseName: { error: false, message: '' },
			spouseEmailAddress: { error: false, message: '' },
			spouseDOB: { error: false, message: '' },
			spouseMobileNumber: { error: false, message: '' },
			spouseCountryCode: { error: false, message: '' },
			officeLocation: { error: false, message: '' },
		});
	}, []);

	useEffect(() => {
		if (client.type === clientType.EIN) {
			setEinSelected(true);
			setClient({
				...client,
				dob: '',
				mfj: false,
				defaultSignFirst: false,
				spouseName: '',
				spouseCountryCode: '',
				spouseDob: '',
				spouseEmailAddress: '',
				spouseSsn: '',
				spouseMobileNumber: '',
			});
			setMandatoryFieldError({
				...mandatoryFieldError,
				spouseName: { error: false, message: '' },
				spouseEmailAddress: { error: false, message: '' },
				spouseSsn: { error: false, message: '' },
				spouseMobileNumber: { error: false, message: '' },
			});
		} else {
			setEinSelected(false);
		}
	}, [client.type]);

	useEffect(() => {
		if (officeLocations.length === 1) {
			setClient({ ...client, locationId: officeLocations[0].locationId });
		}
	}, [officeLocations]);

	useEffect(() => {
		fetchSMSNotification();
		setInitialMobileNumber(client.countryCode + client.mobileNumber);
		setInitialSpouseMobileNumber(client.spouseCountryCode + client.spouseMobileNumber);
	}, []);

	const fetchSMSNotification = (): void => {
		getSMSNotification((responseObject: ISMSNotificationResponse) => {
			if (responseObject.error)
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusType: StatusType.Warning,
					statusMessage: SMSNotificationsConstant.FetchSMSNotificationError,
				});
			else setIsSMSNotificationEnabled(responseObject.isSMSNotificationEnabled);
		});
	};

	const setSelectedType = (type: number): ICustomDropdownOption | null => {
		if (type === 0 || type === undefined) {
			return null;
		} else {
			return { value: client.type, label: clientType[client.type] };
		}
	};

	const setSelectedOfficeLocation = (locationOption: number): ICustomDropdownOption | null => {
		if (locationOption === 0) {
			return null;
		} else {
			const clientLocation = officeLocations.find((x) => x.locationId === client.locationId);
			return clientLocation
				? { value: locationOption, label: `${clientLocation.name} - Location #${clientLocation.locationNumber}` }
				: null;
		}
	};
	const handleOfficeLocationChanges = (option: ICustomDropdownOption | null) => {
		if (option?.value !== 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError['officeLocation'].error = false;
			newMandatoryFieldError['officeLocation'].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setClient({ ...client, locationId: option?.value ?? 0 });
		}
		if (option == null) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError['officeLocation'].error = true;
			newMandatoryFieldError['officeLocation'].message = 'This field is required';
			setMandatoryFieldError(newMandatoryFieldError);
		}
	};

	const validateOfficeLocation = (id: string, value: number) => {
		if (value === undefined || value === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'This field is required';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setClient({ ...client, locationId: value });
		}
	};

	const handleSsnAndEinChanges = (option: ICustomDropdownOption | null) => {
		setClient({
			...client,
			type: option?.value ?? 0,
			uid: option?.value ? getFormattedUID(client.uid === undefined ? '' : client.uid, option.value) : '',
		});
		setMandatoryFieldError({ ...mandatoryFieldError, uid: { error: false, message: '' } });
	};

	const setSelectedEro = (eroOption: number): ICustomDropdownOption | null => {
		if (eroOption === 0) {
			return null;
		} else {
			const clientEro = eros.find((x) => x.id === client.ero);
			return clientEro ? { value: eroOption, label: `${clientEro.firstName} ${clientEro.lastName}` } : null;
		}
	};

	const handleEroChanges = (option: ICustomDropdownOption | null) => {
		setClient({ ...client, ero: option?.value ?? 0 });
	};

	const handleClientDobChanges = (date: Date | null | undefined) => {
		setSelectedClientDate(date);
		const formattedDate = date?.toLocaleDateString('en-US', {
			month: '2-digit',
			day: '2-digit',
			year: 'numeric',
		});
		if (formattedDate) {
			setClient({ ...client, dob: formattedDate });
		} else {
			setClient({ ...client, dob: '' });
		}
	};

	const handleSpouseDobChanges = (date: Date | null | undefined) => {
		setSelectedSpouseDate(date);
		const formattedDate = date?.toLocaleDateString('en-US', {
			month: '2-digit',
			day: '2-digit',
			year: 'numeric',
		});
		if (formattedDate) {
			setClient({ ...client, spouseDob: formattedDate });
		} else {
			setClient({ ...client, spouseDob: '' });
		}
	};

	const formatDate = (dateString: string): Date | null | undefined => {
		if (!dateString) {
			return null;
		} else if (dateString.length > 0) {
			return new Date(Date.parse(dateString));
		}
	};

	const handleInputChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
		setClient({
			...client,
			[e.target.name]: e.target.value,
		});
	};

	const handleClientSSNChanges = (value: string) => {
		setClient({ ...client, uid: value });
	};

	const handleOnBlurClientSsn = (name: string, value: string, ssnValue: string) => {
		if (value && value.trim().length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[name as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[name as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (value && !isValidUID(value.trim())) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[name as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[name as keyof typeof newMandatoryFieldError].message = 'Please enter a 9 digit number';
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (value && ssnValue && value.trim() !== ssnValue.trim()) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError['spouseSsn' as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError['spouseSsn' as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (value && ssnValue && value.trim() === ssnValue.trim()) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError['spouseSsn' as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError['spouseSsn' as keyof typeof newMandatoryFieldError].message =
				'Same SSN detected for taxpayer and spouse';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[name as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[name as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setClient({ ...client, uid: value });
		}
	};

	const handleSpouseSsnChanges = (value: string) => {
		setClient({ ...client, spouseSsn: value });
	};

	const handleOnBlurSpouseSsn = (name: string, value: string, ssnValue: string) => {
		if (value && value.trim().length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[name as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[name as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (value && !isValidUID(value.trim())) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[name as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[name as keyof typeof newMandatoryFieldError].message = 'Please enter a 9 digit number';
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (value && ssnValue && value.trim() === ssnValue.trim()) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[name as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[name as keyof typeof newMandatoryFieldError].message =
				'Same SSN detected for taxpayer and spouse';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[name as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[name as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setClient({ ...client, spouseSsn: value });
		}
	};

	const handleOnBlurClientId = (id: string, value: string) => {
		if (value.trim().length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'This field is required';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setClient({ ...client, clientId: value });
		}
	};

	const handleClientMobileChanges = (fullValue: string, countryObject: CountryData) => {
		setClient({
			...client,
			countryCode: `+${countryObject.dialCode}`,
			mobileNumber: fullValue.slice(countryObject.dialCode.length),
			taxPayerOptedIn: client.isTaxpayerSentSTOP ? false : client.taxPayerOptedIn,
		});
	};

	const handleSpouseMobileChanges = (fullValue: string, countryObject: CountryData) => {
		setClient({
			...client,
			spouseCountryCode: `+${countryObject.dialCode}`,
			spouseMobileNumber: fullValue.slice(countryObject.dialCode.length),
			spouseOptedIn: client.isSpouseSentSTOP ? false : client.spouseOptedIn,
		});
	};

	const handleOnBlurEmail = (id: string, value: string) => {
		if (value && value.trim().length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (value && !isValidEmailAddress(value)) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'Please fill valid Email Address';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setClient({ ...client, [id]: value });
		}
	};

	const handleOnBlurMobile = (id: string) => {
		if (client.mobileNumber && client.mobileNumber.trim().length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setClient({ ...client, countryCode: '' });
		} else if (client.mobileNumber && client.mobileNumber.trim().length < 10) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message =
				'Please enter a 10 digit mobile number';
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (client.mobileNumber && client.mobileNumber.trim().length && client.countryCode === '') {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'Please select country code';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
		}
	};

	const handleOnBlurSpouseMobile = (id: string) => {
		if (client.spouseMobileNumber && client.spouseMobileNumber.trim().length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setClient({ ...client, spouseCountryCode: '' });
		} else if (client.spouseMobileNumber && client.spouseMobileNumber.trim().length < 10) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message =
				'Please enter a 10 digit mobile number';
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (
			client.spouseMobileNumber &&
			client.spouseMobileNumber.trim().length &&
			client.spouseCountryCode === ''
		) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'Please select country code';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
		}
	};

	const handleMfjChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
		const isChecked: boolean = e.target.checked;
		if (isChecked) {
			setClient({ ...client, mfj: isChecked });
		} else {
			setClient({
				...client,
				mfj: isChecked,
				defaultSignFirst: false,
				spouseName: '',
				spouseCountryCode: '',
				spouseDob: '',
				spouseEmailAddress: '',
				spouseSsn: '',
				spouseMobileNumber: '',
			});
			setMandatoryFieldError({
				...mandatoryFieldError,
				spouseName: { error: false, message: '' },
				spouseEmailAddress: { error: false, message: '' },
				spouseSsn: { error: false, message: '' },
				spouseMobileNumber: { error: false, message: '' },
			});
		}
	};

	const handleSpouseSignFirstChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
		setClient({ ...client, defaultSignFirst: e.target.checked });
	};

	const eroUsersList = () => {
		const columnValues: any = [];
		eros.forEach((ero) => {
			columnValues.push({ value: ero.id, label: `${ero.firstName} ${ero.lastName}` });
		});
		return columnValues;
	};

	const checkAllMandatoryFields = () => {
		handleOnBlurClientId('clientId', client.clientId);
		handleOnBlurEmail('emailAddress', client.emailAddress);
		handleOnBlurEmail('spouseEmailAddress', client.spouseEmailAddress);
		handleOnBlurMobile('mobileNumber');
		handleOnBlurSpouseMobile('spouseMobileNumber');
		handleOnBlurClientSsn('uid', client.uid, client.spouseSsn);
		handleOnBlurSpouseSsn('spouseSsn', client.spouseSsn, client.uid);
		validateOfficeLocation('officeLocation', client.locationId);
		for (const field in mandatoryFieldError) {
			if (mandatoryFieldError[field as keyof typeof mandatoryFieldError].error) {
				return false;
			}
		}
		return true;
	};

	const handleSaveClient = () => {
		if (checkAllMandatoryFields()) {
			setInProgress(true);
			if (client.clientBasicInfoId == 0) {
				logger && logger.trackTrace('handleSaveClient: Adding client');
				addClient(
					client,
					(clientBasicInfoId: number) => {
						const changedClientBasicInfoIds = [...newClientBasicInfoIds, clientBasicInfoId];
						setNewClientBasicInfoIds(changedClientBasicInfoIds);

						const newPage = { ...page };
						newPage.pageIndex = 1;
						setPage(newPage);

						requestAllClients(
							true,
							newPage.pageIndex,
							newPage.pageSize,
							sortColumn.sortBy,
							sortColumn.sortOrder,
							searchText,
							changedClientBasicInfoIds.join(),
							selectedOfficeLocations.map((x) => x.value).join(),
						);
						setInProgress(false);
						handleCancelClient();
					},
					(message: string) => {
						if (message.includes('Client ID')) {
							setMandatoryFieldError({ ...mandatoryFieldError, clientId: { error: true, message: message } });
						} else if (message.includes('Spouse SSN')) {
							const removeSpouse = ' Spouse';
							const index = message.indexOf(removeSpouse);
							if (index !== -1) {
								message = message.substring(0, index) + message.substring(index + removeSpouse.length);
							}
							setMandatoryFieldError({ ...mandatoryFieldError, spouseSsn: { error: true, message: message } });
						} else if (message.includes('SSN') || message.includes('EIN')) {
							setMandatoryFieldError({ ...mandatoryFieldError, uid: { error: true, message: message } });
						}
						setInProgress(false);
					},
				);
			} else {
				logger && logger.trackTrace('handleSaveClient: Editing client');
				editClient(
					client,
					() => {
						requestAllClients(
							true,
							page.pageIndex,
							page.pageSize,
							sortColumn.sortBy,
							sortColumn.sortOrder,
							searchText,
							newClientBasicInfoIds.join(),
							selectedOfficeLocations.map((x) => x.value).join(),
						);
						setInProgress(false);
						handleCancelClient();
					},
					(message: string) => {
						if (message.includes('Client ID')) {
							setMandatoryFieldError({ ...mandatoryFieldError, clientId: { error: true, message: message } });
						} else if (message.includes('Spouse SSN')) {
							const removeSpouse = ' Spouse';
							const index = message.indexOf(removeSpouse);
							if (index !== -1) {
								message = message.substring(0, index) + message.substring(index + removeSpouse.length);
							}
							setMandatoryFieldError({ ...mandatoryFieldError, spouseSsn: { error: true, message: message } });
						} else if (message.includes('SSN') || message.includes('EIN')) {
							setMandatoryFieldError({ ...mandatoryFieldError, uid: { error: true, message: message } });
						}
						setInProgress(false);
					},
				);
			}
		}
	};

	const handleCancelClient = () => {
		setShowClientModal(false);
		setClient(initialClient);
	};

	const handleTaxPayerToggleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setClient({ ...client, taxPayerOptedIn: event.target.checked });
	};

	const handleSpouseToggleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setClient({ ...client, spouseOptedIn: event.target.checked });
	};

	return (
		<Modal
			show={true}
			className='modalClass clientModal'
			onHide={handleCancelClient}>
			<Modal.Header>
				<div>
					<Modal.Title>{client.clientBasicInfoId === 0 ? 'Add New Client' : 'Edit Client'}</Modal.Title>
				</div>
				<div
					data-test-auto='e2ab6c41-b6f5-4181-b38f-3455585b2141'
					className='closeIcon'
					onClick={handleCancelClient}>
					<CloseIcon />
				</div>
			</Modal.Header>
			<Modal.Body className='show-grid'>
				<Container
					ref={containerRef}
					className='container__topContainer'>
					<Form>
						<div className='flexCenter'>
							<h1 className='informationText'>Client Information</h1>
							<span
								style={{ paddingTop: 0 }}
								className='fieldErrorMessage requiredTag'>
								* <span className='requiredTag__text'>Required</span>
							</span>
						</div>
						<Row>
							<Col
								xs={4}
								md={6}>
								<Form.Group controlId='clientId'>
									<Form.Label>
										Client ID <span className='asterisk'>*</span>
									</Form.Label>
									<Form.Control
										type='text'
										name='clientId'
										autoFocus
										value={client.clientId}
										onChange={handleInputChanges}
										onBlur={(e: any) => handleOnBlurClientId(e.target.id, e.target.value)}
										className={`${mandatoryFieldError.clientId.error ? 'fieldErrorBorder' : ''}`}
										maxLength={500}
									/>
									<div
										id='clientFieldError'
										className={`fieldErrorSVG ${mandatoryFieldError.clientId.error ? 'fieldErrorMessage' : ''}`}>
										{mandatoryFieldError.clientId.message}
									</div>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId='name'>
									<Form.Label>Name</Form.Label>
									<Form.Control
										type='text'
										name='name'
										autoFocus
										value={client.name}
										onChange={handleInputChanges}
										maxLength={500}
									/>
								</Form.Group>
								<div
									id='clientFieldError'
									className={`fieldErrorSVG ${mandatoryFieldError.name.error ? 'fieldErrorMessage' : ''}`}>
									{mandatoryFieldError.name.message}
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId='emailAddress'>
									<Form.Label>Email Address</Form.Label>
									<Form.Control
										type='text'
										name='emailAddress'
										autoFocus
										value={client.emailAddress}
										onChange={handleInputChanges}
										onBlur={(e) => handleOnBlurEmail(e.target.id, e.target.value)}
										maxLength={320}
									/>
								</Form.Group>
								<div
									id='clientFieldError'
									className={`fieldErrorSVG ${mandatoryFieldError.emailAddress.error ? 'fieldErrorMessage' : ''}`}>
									{mandatoryFieldError.emailAddress.message}
								</div>
							</Col>
						</Row>
						<Row>
							<Col
								xs={9}
								md={6}>
								<div className='mobileSection'>
									<label htmlFor='mobileNumber'>
										<span>Mobile Number</span>
									</label>
									<PhoneInput
										containerClass={`${mandatoryFieldError.mobileNumber.error ? 'fieldErrorBorder ' : ''}inputCCMobile`}
										country={'us'}
										autoFormat={true}
										value={`${client.countryCode} ${client.mobileNumber}`}
										onChange={handleClientMobileChanges}
										onBlur={(e) => handleOnBlurMobile('mobileNumber')}
									/>
									<div
										id='clientFieldError'
										className={`${mandatoryFieldError.mobileNumber.error ? 'fieldErrorMessage' : ''}`}>
										{mandatoryFieldError.mobileNumber.message}
									</div>
								</div>
							</Col>
							<Col>
								<div
									title={`${
										!isSMSNotificationEnabled
											? 'To change, enable SMS notification at the Suite level.'
											: initialMobileNumber !== client.countryCode + client.mobileNumber
											? ''
											: !client.isTaxpayerSentSTOP
											? ''
											: 'Client can opt-in again by replying START.'
									}`}>
									<div className='clientSMSNotificationBlock form-switch'>
										<input
											className='clientSMSNotificationButton form-check-input toggle-custom'
											size={50}	
											type='checkbox'
											id='isTaxPayerSmsNotificationEnabled'
											data-test-auto='4afdfa8e-bbd3-4217-b878-d0439f5b2776'
											checked={client.taxPayerOptedIn ?? false}
											onChange={handleTaxPayerToggleSwitchChange}
											disabled={
												!isSMSNotificationEnabled
													? true
													: initialMobileNumber !== client.countryCode + client.mobileNumber
													? false
													: client.isTaxpayerSentSTOP
											}
										/>
										<span className='clientSMSNotificationText'>SMS Text Notification</span>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId='dob'>
									<Form.Label>Date of Birth</Form.Label>
									<div className='datepicker-input-container'>
										<CustomDatepicker
											selectedDate={formatDate(client.dob)}
											setSelectedDate={handleClientDobChanges}
											disabled={einSelected}
										/>
										<div className='datepicker-icon'>
											<Calender />
										</div>
									</div>
								</Form.Group>
								<div
									id='clientFieldError'
									className={`${mandatoryFieldError.dob.error ? 'fieldErrorMessage' : ''}`}>
									{mandatoryFieldError.dob.message}
								</div>
							</Col>
							<Col id='ssnRow'>
								<Form.Group className='ssn'>
									<CustomDropdown
										id='ssnDropdown'
										selectedOption={setSelectedType(client.type)}
										setSelectedOption={handleSsnAndEinChanges}
										options={[
											{ value: clientType.SSN, label: clientType[1] },
											{ value: clientType.EIN, label: clientType[2] },
										]}
										className='ssnDropdown'
										customPlaceHolder='SSN/EIN'
										clearable={false}
										searchable={false}
										height={31}
									/>
									<TaxIdentificationNumber
										mask={client.type === clientType['EIN'] ? '00-0000000' : '000-00-0000'}
										placeholder=''
										value={client.uid}
										onBlur={(val: string) => handleOnBlurClientSsn('uid', val, client.spouseSsn)}
										onChange={handleClientSSNChanges}
										className='ssnTextField'
										disabled={client.type === 0}
									/>
								</Form.Group>
								<div
									id='clientFieldError'
									style={{ paddingLeft: '10px' }}
									className={`${mandatoryFieldError.uid.error ? 'fieldErrorMessage' : ''}`}>
									{mandatoryFieldError.uid.message}
								</div>
							</Col>
						</Row>
						<Row>
							<Col
								xs={9}
								md={6}>
								<Form.Group controlId='ero'>
									<Form.Label>ERO</Form.Label>
									<CustomDropdown
										id='eroDropdown'
										selectedOption={setSelectedEro(client.ero)}
										setSelectedOption={handleEroChanges}
										options={eroUsersList()}
										className='eroDropdown margin-right20'
										height={31}
										scrollableMenu={true}
									/>
								</Form.Group>
								<div
									id='clientFieldError'
									className={`fieldErrorSVG ${mandatoryFieldError.ero.error ? 'fieldErrorMessage' : ''}`}>
									{mandatoryFieldError.ero.message}
								</div>
							</Col>
							<Col
								xs={9}
								md={6}>
								<Form.Group controlId='officeLocation'>
									<Form.Label>
										Office Location <span className='asterisk'>*</span>
									</Form.Label>
									<CustomDropdownWithCustomOptions
										id='locationDropdown'
										selectedOption={setSelectedOfficeLocation(client.locationId)}
										setSelectedOption={handleOfficeLocationChanges}
										options={officeLocations.map((x) => ({ label: x.name, value: x.locationId, id: x.locationNumber }))}
										className={`eroDropdown ${officeLocations.length === 1 ? ' disabled-dropdown' : ''} `}
										height={31}
										disabled={officeLocations.length === 1 ? true : false}
										borderColor={mandatoryFieldError.officeLocation.error ? '#CC4A43' : '#898D91'}
										scrollableMenu={true}
									/>
								</Form.Group>
								<div
									id='clientFieldError'
									className={`fieldErrorSVG ${mandatoryFieldError.officeLocation.error ? 'fieldErrorMessage' : ''}`}>
									{mandatoryFieldError.officeLocation.message}
								</div>
							</Col>
						</Row>

						<Row>
							<div className='checkboxes'>
								<li
									className='eachUGCheck'
									data-name={'mfj_li'}
									id={'mfj_li'}>
									<input
										type='checkbox'
										className='ugCheckbox'
										id={'mfj_id'}
										name={'mfj_input'}
										checked={client.mfj}
										onChange={handleMfjChanges}
										disabled={einSelected}
									/>
									<label
										style={{ color: `${einSelected ? '#C4C6C8' : '#212529'}` }}
										htmlFor={'mfj_id'}
										data-name={'mfj_label'}
										id={'mfj_label'}>
										Married Filing Jointly
									</label>
								</li>
								{client.mfj && (
									<li
										className='eachUGCheck'
										data-name={'spouseSignFirst_li'}
										id={'spouseSignFirst_li'}>
										<input
											type='checkbox'
											className='ugCheckbox'
											id={'spouseSignFirst_id'}
											name={'spouseSignFirst_input'}
											checked={client.defaultSignFirst}
											onChange={handleSpouseSignFirstChanges}
										/>
										<label
											htmlFor={'spouseSignFirst_id'}
											data-name={'spouseSignFirst_label'}
											id={'spouseSignFirst_label'}>
											Spouse to Sign First by Default
										</label>
									</li>
								)}
							</div>
						</Row>
					</Form>
				</Container>
				{client.mfj && (
					<Container className='container__bottomContainer'>
						<Form>
							<h1 className='informationText'>Spouse Information</h1>
							<Row>
								<Col>
									<Form.Group controlId='spouseName'>
										<Form.Label>Name</Form.Label>
										<Form.Control
											type='text'
											name='spouseName'
											autoFocus
											value={client.spouseName}
											onChange={handleInputChanges}
											maxLength={500}
										/>
									</Form.Group>
									<div
										id='clientFieldError'
										className={`fieldErrorSVG ${mandatoryFieldError.spouseName.error ? 'fieldErrorMessage' : ''}`}>
										{mandatoryFieldError.spouseName.message}
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Group controlId='spouseEmailAddress'>
										<Form.Label>Email Address</Form.Label>
										<Form.Control
											type='text'
											name='spouseEmailAddress'
											value={client.spouseEmailAddress}
											onChange={handleInputChanges}
											onBlur={(e) => handleOnBlurEmail(e.target.id, e.target.value)}
											maxLength={320}
										/>
									</Form.Group>
									<div
										id='clientFieldError'
										className={`fieldErrorSVG ${
											mandatoryFieldError.spouseEmailAddress.error ? 'fieldErrorMessage' : ''
										}`}>
										{mandatoryFieldError.spouseEmailAddress.message}
									</div>
								</Col>
							</Row>
							<Row>
								<Col
									xs={9}
									md={6}>
									<div className='mobileSection'>
										<label htmlFor='spouseMobileNumber'>
											<span>Mobile Number</span>
										</label>
										<PhoneInput
											containerClass={`${
												mandatoryFieldError.spouseMobileNumber.error ? 'fieldErrorBorder ' : ''
											}inputCCMobile`}
											country={'us'}
											autoFormat={true}
											value={`${client.spouseCountryCode} ${client.spouseMobileNumber}`}
											onChange={handleSpouseMobileChanges}
											onBlur={(e) => handleOnBlurSpouseMobile('spouseMobileNumber')}
										/>
										<div
											id='clientFieldError'
											className={`${mandatoryFieldError.spouseMobileNumber.error ? 'fieldErrorMessage' : ''}`}>
											{mandatoryFieldError.spouseMobileNumber.message}
										</div>
									</div>
								</Col>
								<Col>
									<div
										title={`${
											!isSMSNotificationEnabled
												? 'To change, enable SMS notification at the Suite level.'
												: initialSpouseMobileNumber !== client.spouseCountryCode + client.spouseMobileNumber
												? ''
												: !client.isSpouseSentSTOP
												? ''
												: 'Client can opt-in again by replying START.'
										}`}>
										<div className='clientSMSNotificationBlock form-switch'>
											<input
												className='clientSMSNotificationButton form-check-input toggle-custom'
												type='checkbox'
												id='isSpouseSmsNotificationEnabled'
												data-test-auto='b907ce38-3a8f-404c-ab1d-8cabdabb5d5e'
												checked={client.spouseOptedIn ?? false}
												onChange={handleSpouseToggleSwitchChange}
												disabled={
													!isSMSNotificationEnabled
														? true
														: initialSpouseMobileNumber !== client.spouseCountryCode + client.spouseMobileNumber
														? false
														: client.isSpouseSentSTOP
												}
											/>
											<span className='clientSMSNotificationText'>SMS Text Notification</span>
										</div>
									</div>
								</Col>
							</Row>
							<Row>
								<Col
									xs={9}
									md={6}>
									<Form.Group controlId='spouseDOB'>
										<Form.Label>Date of Birth</Form.Label>
										<div className='datepicker-input-container'>
											<CustomDatepicker
												selectedDate={formatDate(client.spouseDob)}
												setSelectedDate={handleSpouseDobChanges}
											/>
											<div className='datepicker-icon'>
												<Calender />
											</div>
										</div>
									</Form.Group>
									<div
										id='clientFieldError'
										className={`${mandatoryFieldError.spouseDOB.error ? 'fieldErrorMessage' : ''}`}>
										{mandatoryFieldError.spouseDOB.message}
									</div>
								</Col>
							</Row>
							<Row className='row__spouseSsn'>
								<Col
									xs={9}
									md={6}>
									<Form.Group controlId='spouseSsn'>
										<Form.Label>SSN</Form.Label>
										<TaxIdentificationNumber
											mask={'000-00-0000'}
											placeholder=''
											value={client.spouseSsn}
											onBlur={(val: string) => handleOnBlurSpouseSsn('spouseSsn', val, client.uid)}
											onChange={handleSpouseSsnChanges}
											className='ssnTextField'
										/>
									</Form.Group>
									<div
										id='clientFieldError'
										className={`fieldErrorSVG ${mandatoryFieldError.spouseSsn.error ? 'fieldErrorMessage' : ''}`}>
										{mandatoryFieldError.spouseSsn.message}
									</div>
								</Col>
							</Row>
						</Form>
					</Container>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					data-test-auto='bf271b75-04e5-4238-bebc-b1f3667be192'
					className='cancelBtn'
					variant='secondary'
					onClick={handleCancelClient}>
					Cancel
				</Button>
				<Button
					data-test-auto='6e15750b-3c9d-49a9-b272-49e048689c94'
					className={`${client.clientBasicInfoId === 0 ? 'addClientBtn' : 'updateClientBtn'}`}
					disabled={inProgress}
					variant='primary'
					onClick={handleSaveClient}>
					{client.clientBasicInfoId === 0 ? 'Add Client' : 'Update'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ClientModal;
