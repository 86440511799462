import { connect } from 'react-redux';
import * as IPListStore from 'src/modules/securitySettings/ip-filtering-list/ip-filtering-list.api';
import * as IPFilteringStore from 'src/modules/securitySettings/ip-filtering-option/ip-filtering.api';
import * as CMFASettingsStore from 'src/modules/securitySettings/MFASettings/CMFASettings.store';
import * as MFASettingsStore from 'src/modules/securitySettings/MFASettings/MFASettings.store';
import * as SSOSettingsStore from 'src/modules/securitySettings/SSOSettings/SSOSettings.store';
import * as PasswordPolicySettingsStore from 'src/modules/securitySettings/PasswordPolicy/PasswordPolicySettings.store';
import * as DefinedPasswordPoliciesStore from 'src/modules/securitySettings/PasswordPolicy/DefinedPasswordPolicies.store';
import { type ApplicationState } from '../../store/index';
import SecuritySettings from './index';

export default connect(
    (state: ApplicationState) => ({
        ssoSettings: state.ssoSettings,
        mfaSettings: state.mfaSettings,
        cMfaSettings: state.cMfaSettings,
        passwordPolicySettings: state.passwordPolicySettings,
        definedPasswordPolicies: state.definedPasswordPolicies,
        ipFilteringOptions: state.ipFilteringOptionStore,
        ipFilterList: state.ipFilterList,
        overlayLoader: state.overlayLoader,
    }),
    {
        ...SSOSettingsStore.actionCreators,
        ...MFASettingsStore.actionCreators,
        ...CMFASettingsStore.actionCreators,
        ...PasswordPolicySettingsStore.actionCreators,
        ...DefinedPasswordPoliciesStore.actionCreators,
        ...IPFilteringStore.actionCreators,
        ...IPListStore.actionCreators,
    }
)(SecuritySettings as React.FC);
