/* eslint-disable max-lines-per-function */
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/global.scss';
import './assets/custom/utilities.css';
import 'react-overlay-loader/styles.css';
import './assets/custom/custom.css';
import './assets/custom/site.css';
import './assets/font-awesome/css/all.css';

import React, { type FC } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';

import AxiosInterceptorsSetup from './axios-interceptor';
import store from './configureStore';
import { Toaster } from './helper/AppNotifier';
import AuthProvider from './oidcClient/authProvider';
import SigninOidc from './oidcClient/signin-oidc';
import SignoutOidc from './oidcClient/signout-oidc';
import SilentSigninOidc from './oidcClient/silent-signin-oidc';
import userManager from './oidcClient/userService';
import BrandingManagementContainer from './pages/branding-management/branding.container';
import ClientManagementContainer from './pages/ClientManagement/ClientManagement.container';
import CompanyManagementContainer from './pages/company-management/company-management.container';
import GeneralSettingsContainer from './pages/general-settings/general-settings.container';
import SecuritySettingsContainer from './pages/SecuritySettings/SecuritySettings.container';
import UserManagementContainer from './pages/UserManagement/UserManagement.container';
import UsageReportContainer from './pages/UsageReport/UsageReport.container';
import Welcome from './pages/welcome';
import ProtectedRoute from './protectedRoutes';
import RoleBasedRoute from './routes/RoleBasedRoute';
import DeveloperSectionContainer from './pages/developer-section/developer-section.container';
import InOfficeESigningContainer from './pages/inoffice-esigning/InOfficeESigning.container';
import RestrictedAccess from './pages/common/restrictedAccess/restrictedAccess';
import PaymentHistoryContainer from './pages/PaymentHistory/PaymentHistory.container';
import UserPermissionContainer from './pages/UserPermission/UserPermission.container';
import Grant from './modules/userPermission/Grant';
import Deny from './modules/userPermission/Deny';
import PaymentReceipt from 'src/modules/paymentHistory/PaymentReceipt';
import PaymentConfirmedContainer from 'src/modules/paymentHistory/PaymentConfirmed.container';
import PaymentCancelledContainer from 'src/modules/paymentHistory/PaymentCancelled.container';
import InvalidPaymentLinkContainer from 'src/modules/paymentHistory/InvalidPaymentLink.container';
import { MenuHeaderConstants } from './helper/Constants';
import VoucherAndInvoices from './pages/voucher-invoices';
import ApprovePassword from './modules/approvePassword';
import AdminRoute from './routes/AdminRoute';
import InOfficeESigning from './pages/inoffice-esigning';

function AxiosInterceptorNavigate() {
	const navigate = useNavigate();
	AxiosInterceptorsSetup(navigate);
	return <></>;
}

const App: FC = () => {
	return (
		<Provider store={store}>
			<AuthProvider
				userManager={userManager}
				store={store}>
				<Router>
					<AxiosInterceptorNavigate />
					<Routes>
						<Route
							path='/signout-callback-oidc'
							element={<SignoutOidc />}
						/>
						<Route
							path='/signin-oidc'
							element={<SigninOidc />}
						/>
						<Route
							path='/silent-signin-oidc'
							element={<SilentSigninOidc />}
						/>
						<Route
							path='/DownloadReceipt/:id?'
							element={<PaymentReceipt />}
						/>
						<Route
							path='/restricted-access'
							element={
								<ProtectedRoute
									component={RestrictedAccess}
									sideMenuNeeded={false}
									hideHeader={true}
								/>
							}
						/>
						<Route
							path='/user-management'
							element={
								<RoleBasedRoute
									path='/user-management'
									Component={UserManagementContainer}
									sideMenuNeeded={true}
									headerMenu={MenuHeaderConstants.ACCOUNT_MANAGEMENT}
								/>
							}
						/>

						<Route
							path='/developer-section'
							element={
								<RoleBasedRoute
									path='/developer-section'
									Component={DeveloperSectionContainer}
									sideMenuNeeded={false}
								/>
							}
						/>

						<Route
							path='/inoffice-esigning'
							element={
								<RoleBasedRoute
									path='/inoffice-esigning'
									Component={InOfficeESigningContainer}
									sideMenuNeeded={false}
								/>
							}
						/> 

						{/* 
                        This code would be uncommented after the left menu integration for Security Settings page is complete. Until then, NOT keeping Security Settings page in RoleBasedRoute.
                        <Route
                            path="/security-settings"
                            element={<RoleBasedRoute path="/security-settings" Component={UserManagementContainer} />}
                        /> */}
						<Route
							path='/client-management'
							element={
								<RoleBasedRoute
									path='/client-management'
									Component={ClientManagementContainer}
									sideMenuNeeded={true}
									headerMenu={MenuHeaderConstants.ACCOUNT_MANAGEMENT}
								/>
							}
						/>
						<Route
							path='/security-settings'
							element={
								<RoleBasedRoute
									path='/security-settings'
									Component={SecuritySettingsContainer}
									sideMenuNeeded={true}
								/>
							}
						/>
						<Route
							path='/general-settings'
							element={
								<RoleBasedRoute
									path='/general-settings'
									Component={GeneralSettingsContainer}
									sideMenuNeeded={true}
								/>
							}
						/>
						<Route
							path='/voucher-invoices'
							element={
								<RoleBasedRoute
									path='/voucher-invoices'
									Component={VoucherAndInvoices}
									sideMenuNeeded={true}
								/>
							}
						/>
						<Route
							path='/branding-management'
							element={
								<RoleBasedRoute
									path='/branding-management'
									Component={BrandingManagementContainer}
									sideMenuNeeded={true}
									headerMenu={MenuHeaderConstants.ACCOUNT_MANAGEMENT}
								/>
							}
						/>
						<Route
							path='/company-management'
							element={
								<RoleBasedRoute
									path='/company-management'
									Component={CompanyManagementContainer}
									sideMenuNeeded={true}
									headerMenu={MenuHeaderConstants.ACCOUNT_MANAGEMENT}
								/>
							}
						/>
						<Route
							path='/usage-report'
							element={
								<RoleBasedRoute
									path='/usage-report'
									Component={UsageReportContainer}
									sideMenuNeeded={true}
									headerMenu={MenuHeaderConstants.ACCOUNT_MANAGEMENT}
								/>
							}
						/>
						<Route
							path='/payment-history'
							element={
								<RoleBasedRoute
									path='/payment-history'
									Component={PaymentHistoryContainer}
									sideMenuNeeded={true}
									headerMenu={MenuHeaderConstants.ACCOUNT_MANAGEMENT}
								/>
							}
						/>
						<Route
							path='/user-permission'
							element={
								<RoleBasedRoute
									path='/user-permission'
									Component={UserPermissionContainer}
									sideMenuNeeded={true}
									headerMenu={MenuHeaderConstants.ACCOUNT_MANAGEMENT}
								/>
							}
						/>
						<Route
							path='/SSOAccessRequest/Grant/:param?'
							element={
								<ProtectedRoute
									component={Grant}
									sideMenuNeeded={true}
								/>
							}
						/>
						<Route
							path='/SSOAccessRequest/Deny/:param?'
							element={
								<ProtectedRoute
									component={Deny}
									sideMenuNeeded={true}
								/>
							}
						/>
						<Route path='/ApprovePassword/ApprovePassword/:param?'
							element={
								<AdminRoute	
									component={ApprovePassword}
									sideMenuNeeded={false}
								/>
							} />
						<Route
							path='/payment-confirmed/:id/:page?'
							element={
								<ProtectedRoute
									component={PaymentConfirmedContainer}
									sideMenuNeeded={true}
								/>
							}
						/>
						<Route
							path='/payment-cancelled/:page?'
							element={
								<ProtectedRoute
									component={PaymentCancelledContainer}
									sideMenuNeeded={true}
								/>
							}
						/>
						<Route
							path='/invalid-payment-link/:page?'
							element={
								<ProtectedRoute
									component={InvalidPaymentLinkContainer}
									sideMenuNeeded={true}
								/>
							}
						/>
						<Route
							path='/'
							element={
								<RoleBasedRoute
									Component={Welcome}
									sideMenuNeeded={false}
									path='/'
								/>
							}
						/>
						<Route
							path='/welcome'
							element={
								<RoleBasedRoute
									Component={Welcome}
									sideMenuNeeded={false}
									path='/'
									isWelcomePage={true}
								/>
							}
						/>
						<Route
							path='/*'
							element={
								<RoleBasedRoute
									Component={Welcome}
									sideMenuNeeded={false}
									path='/'
									isWelcomePage={true}
								/>
							}
						/>
					</Routes>
					<Toaster />
				</Router>
			</AuthProvider>
		</Provider>
	);
};

export default App;
