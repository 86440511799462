import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import './AppNotifier.scss';

export class AppNotifier extends React.Component {
	static Error = (message: string) =>
		message && toast.error(message, { position: toast.POSITION.TOP_RIGHT, className: 'toaster-error' });

	static Warning = (message: string) =>
		message && toast.warn(message, { position: toast.POSITION.TOP_RIGHT, className: 'toaster-warning' });

	static Success = (message: string) =>
		message && toast.success(message, { position: toast.POSITION.TOP_RIGHT, className: 'toaster-success' });
}

export const Toaster: React.FC = () => {
	return (
		<div className='toaster_container'>
			<ToastContainer
				autoClose={3000}
				className='toaster'
				position='top-right'
				hideProgressBar={true}
				newestOnTop={true}
				closeOnClick={true}
			/>
		</div>
	);
};
