import axios, { AxiosResponse } from 'axios';
import { Reducer } from 'redux';
import { API_BASE_URL } from '../../helper/Constants';
import { logger } from '../../oidcClient/authProvider';
import { DispatchAction, IInOfficeEsignReturn, IInOfficeReturnModel, KnownAction } from './InOfficeESigning.model';
import { openWindowWithPostRequest } from '../../helper/HelperFunctions';
import { AppThunkAction } from '../../store/index';
import { actionTypes } from './InOfficeESigning.actions';
import { StatusType } from '../../pages/common/notification/notification.reducer';

export const actionCreators = {
	requestInOfficeReturns:
		(
			reload = false,
			searchText = '',
			pageNo = 1,
			pageSize = 10,
			callback?: (data: any[]) => void,
		): AppThunkAction<KnownAction> =>
		async (dispatch, getState) => {
			try {
				const state = getState();
				if (reload || state.inOfficeReturnList.inOfficeReturnList.length === 0) {
					dispatch({ type: actionTypes.REQUEST_INOFFICE_RETURNS });
					const queryString = `?searchText=${searchText}&pageNo=${pageNo}&pageSize=${pageSize}`;
					const response = await axios.get(API_BASE_URL + '/api/inoffice-esigning' + queryString);
					dispatch({ type: actionTypes.RECEIVE_INOFFICE_RETURNS, inOfficeReturnList: response.data });
					if (callback) {
						callback(response.data);
					}
				}
			} catch (error: any) {
				logger && logger.trackWarning('Error while requestInOfficeReturns', { Error: error?.response?.data });
				dispatch({
					type: actionTypes.ERROR_INOFFICE_RETURNS,
					statusMessage: error,
					statusType: StatusType.Error,
				});
			}
		},

	requestInOfficeReturnsAsEmpty: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
		dispatch({ type: actionTypes.REQUEST_INOFFICE_RETURNS });
		dispatch({ type: actionTypes.RECEIVE_INOFFICE_RETURNS, inOfficeReturnList: [] });
	},

	redirectToTaxpayerSigningFlow:
		(selectedReturn: null | IInOfficeReturnModel, callback?: () => void): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			const responseData: string = '';
			axios
				.put(`${API_BASE_URL}/api/inoffice-esigning/generate-taxpayer-signing-url`, selectedReturn)
				.then((response: AxiosResponse) => {
					openWindowWithPostRequest(
						response.data.url,
						getState().userAuth.user.access_token,
						'CPAToken',
						'inoffice_esigning',
						 undefined,
						'_blank'
					);
					callback && callback();
				})
				.catch((error) => {
					logger && logger.trackWarning('Error while getOfficeLocationDropdownList', { Error: error?.response?.data });
					callback && callback();
				});
		},
};

const unloadedState: IInOfficeEsignReturn = {
	isLoading: false,
	inOfficeReturnList: [],
	count: 0,
};

export const reducer: Reducer<IInOfficeEsignReturn> = (state = unloadedState, incomingAction) => {
	const action = incomingAction as DispatchAction;

	switch (action.type) {
		case actionTypes.REQUEST_INOFFICE_RETURNS:
			return {
				isLoading: true,
				inOfficeReturnList: [...state.inOfficeReturnList],
				count: state.count,
			};
		case actionTypes.RECEIVE_INOFFICE_RETURNS:
			return {
				isLoading: false,
				inOfficeReturnList: action.inOfficeReturnList,
				count: action.inOfficeReturnList[0]?.totalCount ?? 0,
			};
		case actionTypes.ERROR_INOFFICE_RETURNS:
			const errorState: IInOfficeEsignReturn = Object.assign({}, state);
			errorState.isLoading = false;
			return errorState;

		default:
			return state;
	}
};
