import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import * as signalR from '@microsoft/signalr';
import { ApplicationState } from "../store";
import { API_BASE_URL } from "../helper/Constants";
import { LogoutCause } from "../pages/common/auth/auth.reducer";
import store from "../configureStore";
import { userPrivilegeChanged } from "../pages/common/auth/auth.actions";
import { resetCookie } from "../helper/HelperFunctions";

const bearerPrefix = 'Bearer ';

interface ISignalRWebSocketProps {
	companyId: number;
}

export interface IUserAutoLogoutNotificationMessage {
	users: number[];
	logoutCause: LogoutCause;
	companyId: number;
}

export const SignalRWebSocket: React.FunctionComponent<ISignalRWebSocketProps> = (props) => {
	const [signalRBaseUrl, setSignalRBaseUrl] = useState<string>("");
	const [token, setToken] = useState<string>("");
	const [user, setUser] = useState<string>("");
	const [group, setGroup] = useState<string>("");

	const userProfile = useSelector(
		(appState: ApplicationState) => appState.userAuth.user
	);

	const { companyId } = props;

	useEffect(() => {
		setToken(userProfile.access_token);
		getSocketUrl();
	}, []);

	useEffect(() => {
		if (signalRBaseUrl != "" && userProfile.profile.user_id > 0) {
			setUser(companyId + '_' + userProfile.profile.user_id);
			setGroup(getWebSocketGroup());
			signalRConnectionInit();
		}
	}, [signalRBaseUrl, user, group]);

	const getSocketUrl = () => {
		if (signalRBaseUrl == "") {
			getWebSocketUrl();
		}
	}
	const getWebSocketGroup = () => {
		const group = "00000000" + companyId;
		return 'suite' + group.substr(group.length - 8);
	}

	const signalRConnectionInit = () => {
		axios.post(`${signalRBaseUrl}/signalr/negotiate`,null, {
			headers: {
				'x-ms-signalr-userid': user,
				'Authorization': bearerPrefix + token,
			}
		})
			.then((response: any) => {
				response.accessToken = response.accessToken || response.accessKey;
				const url = response.data.url || response.data.endpoint;

				const options = {
					accessTokenFactory: () => response.data.accessToken
				};

				const connection = new signalR.HubConnectionBuilder()
					.withUrl(url, options)
					.withAutomaticReconnect()
					.configureLogging(signalR.LogLevel.Information)
					.build();
				console.log(connection);
				connection.on('UserPrivilegesChanged', notifyUserPrivilegeChange);

				connection.onclose(() => {
					console.log('disconnected');
					startConnection(connection);
				});
				startConnection(connection);

			}).catch((reason) => {
				console.log(reason);
			})
	}

	const startConnection = (connection: any) => {
		console.log('connecting...');
		connection.start()
			.then(() => {
				console.log('connected!');
				if(user && user.length>0)
				{
					addGroup();
					connection.invoke('getConnectionId');
				}
			})
			.catch((err: any) => {
				console.error(err);
				setTimeout(startConnection, 5000, connection);
			});
	}

	const addGroup = () => {
		axios.post(`${signalRBaseUrl}/signalr/AddToGroup`,{
			recipient: user,
			groupname: group
		}, {
			headers: {
				'x-ms-signalr-userid': user,
				'Authorization': bearerPrefix + token,
			}			
		}).then((resp: any) => {
			if (resp.status == 200) {
				console.log("User added to the group successfully")
			}
		}).catch((error: any) => {
			console.log(error);
		});
	}

	const removeGroup = () => {
		axios.post(`${signalRBaseUrl}/signalr/RemoveFromGroup`,
			{
			recipient: user,
			groupname: group
		}, {
			headers: {
				'x-ms-signalr-userid': user,
				'Authorization': bearerPrefix + token,
			}			
		}).then((resp: any) => {
			if (resp.status == 200) {
				console.log("User removed from group successfully")
			}
		}).catch((error: any) => {
			console.log(error);
		});
	}

	const getWebSocketUrl = () => {
		axios.get(`${API_BASE_URL}/api/WebSocket/socket-connection-url`).
			then((resp: any) => {
				setSignalRBaseUrl(resp.data);
			})
			.catch((error: any) => {
				console.log(error);
			});
	}

	const notifyUserPrivilegeChange = (notificationMessage: IUserAutoLogoutNotificationMessage) => {
		if (notificationMessage.users.includes(userProfile.profile.user_id)
			&& notificationMessage.companyId === companyId) {
			resetCookie('userAutoLogout', '1');
			store.dispatch(userPrivilegeChanged(notificationMessage.logoutCause));
		}
	}

	return (<> </>);
}

export default SignalRWebSocket;