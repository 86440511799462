import { IOfficeLocation } from "../../../../modules/clientManagement/Client.model";

export const changeTypeOfUserOfficeLocations = (officeLocations: string, officeLocationList: IOfficeLocation[]) => {
	const convertToArr = officeLocations ? officeLocations.split(',') : [];
	if (officeLocations === '' && officeLocationList.length === 1) {
		const list = officeLocationList[0];
		return [{ label: list.name, value: list.locationId }];
	}
	if (officeLocations && convertToArr.length) {
		const location = convertToArr.map((x: string) => {
			const list = officeLocationList.find(
				(officeLocation: IOfficeLocation) => officeLocation.locationId === Number(x),
			);
			return list ? { label: list.name, value: list.locationId } : { label: '', value: -1 };
		});
		return location;
	}
	return [];
};
