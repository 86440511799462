import axios, { AxiosResponse } from 'axios';
import { IPaymentReceiptInfo } from '../PurchaseReturn.model';
import { API_BASE_URL } from 'src/helper/Constants';

export const fetchPaymentDetails = (id: any): Promise<IPaymentReceiptInfo> => {
    return axios.get(API_BASE_URL + '/api/returns/payment-operation/download-receipt/' + id)
        .then((response: AxiosResponse<IPaymentReceiptInfo>) => {
            const { data } = response;
            return data;
        });
};