import React, { ReactNode } from 'react';
import { GroupBase, ValueContainerProps, components } from 'react-select';
import './Filter.style.scss';
import DownIcon from '../../../components/svg/DownIcon';
import FilterIcon from '../../../components/svg/FilterIcon';
import FilledFilterIcon from '../../../components/svg/FilledFilterIcon';

interface ValueComponentProps extends ValueContainerProps<any, boolean, GroupBase<any>> {
	getValue: () => any[];
	hasValue: boolean;
	children: ReactNode;
}

export const ValueComponent: React.FC<ValueContainerProps<any, boolean, GroupBase<any>>> = ({ children, ...props }) => {
	const {
		hasValue,
		selectProps: { menuIsOpen, selectedOptions },
	} = props;

	return (
		<components.ValueContainer {...props}>
			{
				<div
					className={`filterIcons ${menuIsOpen ? 'filterIcons_transformed' : ''} ${
						hasValue ? 'filterIcons_marginTop' : ''
					}`}>
					{Array.isArray(selectedOptions) && selectedOptions.length > 0 ? <FilledFilterIcon /> : <FilterIcon />}
					<DownIcon />
				</div>
			}
			{React.Children.map(children, (child: any) => {
				return child?.props.id === 'clickableInput' ? child : null;
			})}
		</components.ValueContainer>
	);
};

export const OptionComponent = (props: any) => {
	const locationNumber = props.options.filter((x: any) => x.value === props.value)[0]?.id;
	return (
		<div>
			<components.Option {...props}>
				<div className='option-container'>
					<input
						className='option-container_input'
						type='checkbox'
						checked={props.isSelected}
						onChange={() => null}
					/>
					<label className='option-container_label'>
						<div
							className={
								props.value !== 0 ? 'text-ellipsis option-container_label--name' : 'option-container_label--selectAll'
							}
							title={props.value !== 0 ? props.label : ''}>
							{props.label}
						</div>
						{props.value !== 0 && (
							<>
								<div className='option-container_label--hyphen'>-</div>
								<div
									className='text-ellipsis option-container_label--value'
									title={`Location #${locationNumber}`}>
									{`Location #${locationNumber}`}
								</div>
							</>
						)}
					</label>
				</div>
			</components.Option>
		</div>
	);
};

export const MenuComponent = (props: any) => {
	const { tempOfficeLocations, setSelectedOptions, setIsMenuOpen, setTempOfficeLocations, requestAllUsers } =
		props.selectProps;

	return (
		<components.Menu {...props}>
			<div
				id='menuReactSelect'
				className='menuComponent'>
				<div className='menuComponent_headerText'>Filters</div>
				<div>
					<span
						id='apply'
						className='menuComponent_applyBtn'
						onClick={() => {
							setSelectedOptions(tempOfficeLocations);
							requestAllUsers(tempOfficeLocations);
							setIsMenuOpen(false);
						}}>
						Apply
					</span>
					<span
						className='menuComponent_clearAll'
						onClick={() => {
							setSelectedOptions([]);
							setTempOfficeLocations([]);
							requestAllUsers([]);
							setIsMenuOpen(false);
						}}>
						Clear all
					</span>
				</div>
			</div>
			{props.children}
		</components.Menu>
	);
};

export const MenuListComponent = (props: any) => {
	return (
		<components.MenuList {...props}>
			<div className='menuListComponent'>Office Locations</div>
			{props.children}
		</components.MenuList>
	);
};
