import './confirmation-popup.scss';

import React, { type FC } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import CloseIcon from 'src/components/svg/CloseIcon';

import { type confirmationPopupProps } from './confirmation-popup.types';

const ConfirmationPopup: FC<confirmationPopupProps> = (props) => {
	const {
		header,
		message,
		show,
		cancelText,
		okText,
		model,
		actionDisabled,
		disabledText,
		actionButtonType,
		cancelButtonType,
		hideOkBtn,
		className,
	} = props;

	return (
		<Modal
			className={`confirmationPopup ${className ?? ''}`}
			show={show}
			onHide={props.onHide}>
			<Modal.Header>
				<div>
					<Modal.Title>{header}</Modal.Title>
				</div>
				<div
					data-test-auto='8cc2eef8-27ec-494a-9518-5d52ce8a0891'
					className='closeIcon'
					onClick={props.onHide}>
					<CloseIcon />
				</div>
			</Modal.Header>
			<Modal.Body className='show-grid'>
				<Container className='popup_grid_container'>
					<div>{message}</div>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button
					data-test-auto='0d0aa5c7-7109-4037-95e9-ab0a8ae97498'
					variant={cancelButtonType || 'secondary'}
					onClick={props.onCancel}>
					{cancelText}
				</Button>
				{!hideOkBtn && (
					<Button
						data-test-auto='cc6f3863-a3f9-4dd4-bbe8-bee6994459a2'
						variant={actionDisabled ? 'disbaled' : actionButtonType}
						onClick={() => {
							actionDisabled ? null : props.onOk(model);
						}}
						disabled={actionDisabled}
						title={actionDisabled ? disabledText : ''}>
						{okText}
					</Button>
				)}
			</Modal.Footer>
		</Modal>
	);
};

export default ConfirmationPopup;
