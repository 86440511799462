import React, { useEffect, useState } from 'react';
import InfoIcon from 'src/components/svg/InfoIcon';
import { IDisabledDnDItems } from 'src/modules/company-management/partials/save-office-location-modal/save-office-location-modal.types';

import { IDragAndDropData } from '../DragAndDrop.model';
import DraggableList from '../DraggableList';

interface DestinationProps<T1 extends string, T2, T3 = undefined, T4 = null> {
	destinationData: IDragAndDropData<T1, T2, T3 | undefined>[];
	sourceData: IDragAndDropData<T1, T2, T3 | undefined>[];
	handleDropChange: (
		destinationData: IDragAndDropData<T1, T2, T3 | undefined>[],
		sourceData: IDragAndDropData<T1, T2, T3 | undefined>[],
	) => void;
	destinationHeading?: string;
	destinationHeadingInfoText?: string;
	destinationWidth?: string;
	destinationHeight?: string;
	data?: T4;
	disableDestinationItems?: IDisabledDnDItems[];
}

function Destination<T1 extends string, T2, T3 = undefined, T4 = null>(props: DestinationProps<T1, T2, T3, T4>) {
	const {
		destinationData,
		sourceData,
		handleDropChange,
		destinationHeading,
		destinationHeadingInfoText,
		destinationWidth,
		destinationHeight,
		data,
		disableDestinationItems,
	} = props;
	const [destination, setDestination] = useState<any[]>([]);

	useEffect(() => {
		setDestination(destinationData);
	}, [destinationData]);

	const dragOverHandler = (event: any) => {
		event.preventDefault();
	};

	const dropHandler = (event: any) => {
		const data = JSON.parse(event.dataTransfer.getData('text'));
		if (
			destinationData.some((ele: any) => data.findIndex((datum: any) => datum.value === ele.value) === -1) ||
			!destinationData.length
		) {
			const newDestinationList = [...data, ...destinationData];
			const newSourceList = sourceData.filter(
				(ele: any) => !data.some((eachData: any) => eachData.value === ele.value),
			);
			handleDropChange(newDestinationList, newSourceList);
		}
	};

	return (
		<article
			className='destinationDataContainer'
			style={destinationWidth ? { width: destinationWidth } : null}>
			{destinationHeading && (
				<div className='destinationDataHeaderDiv'>
					<span className='destinationDataHeader'>{destinationHeading}</span>
					{destinationHeadingInfoText && (
						<span
							className='destinationDataInfo'
							title={destinationHeadingInfoText}>
							<InfoIcon />
						</span>
					)}
				</div>
			)}
			<ul
				className='list_container'
				onDragOver={(event) => dragOverHandler(event)}
				onDrop={(event) => dropHandler(event)}
				style={destinationHeight ? { height: destinationHeight } : null}>
				<DraggableList<T1, T2, T3 | undefined, T4 | undefined>
					listData={destination}
					disableSelection={true}
					data={data}
					disabledTitle={'User cannot be removed as mapped to only this location'}
					disableDestinationItems={disableDestinationItems}
				/>
			</ul>
		</article>
	);
}

export default Destination;
