import { type FC, useState, useEffect } from 'react';
import SectionHeader from '../partials/section-header/section-header';
import styles from './edit-company-details.module.scss';
import { ReactComponent as EditIcon } from '../../../components/svg/pencil.svg';
import ButtonWithTextIcon from '../../../components/common/button-with-text-icon';
import { Col } from 'react-bootstrap';
import { b64toBlob, printTextOnCanvas } from 'src/helper/HelperFunctions';
import { myCompanyResources } from 'src/helper/rbac-constants';
import { uploadFile } from '../../../helper/FileUpload';
import { requestCompanyProfile, requestCompanySignatureUploadLink } from 'src/pages/common/companySettings/companySettings.apis';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { IEditCompanyDetailsProps } from '../../../pages/company-management/company-management.types';
import { logger } from '../../../oidcClient/authProvider';

export const EditCompany: FC<IEditCompanyDetailsProps> = (props) => {

	const [companyName, setCompanyName] = useState<string>("");
	const [showEditBtn, setShowEditBtn] = useState<boolean>(true);
	const [showEditor, setShowEditor] = useState<boolean>(false);
	const [isUpdateBtnDisabled, setIsUpdateBtnDisabled] = useState<boolean>(true);

	const dispatch = useDispatch();

	const name = useSelector((state: ApplicationState) => state.companyData.companyProfile.companyInfo.companyName);

	useEffect(() => {
		setCompanyName(name);
	}, [name]);

	const handleCompanyNameChange = (event: any) => {
		setCompanyName(event.target.value);
		if (event.target.value?.trim() != props.companyName?.trim()) {
			setIsUpdateBtnDisabled(false);
		}
	}

	const handleOnCompanyNameEditBlur = () => {
		if (companyName?.trim() != props.companyName?.trim()) {
			setIsUpdateBtnDisabled(false);
		}
		else {
			setIsUpdateBtnDisabled(true);
		}
	}

	const updateCompany = () => {
		logger && logger.trackTrace('updateCompany: Updating company');
		props.updateCompany(companyName,
			`${myCompanyResources.prefix}_${myCompanyResources.companyDetails}_${myCompanyResources.editButton}`,
			() => {
				dispatch(
					requestCompanySignatureUploadLink((uploadLink: string) => {
						updateCompanySignature(uploadLink);
					}),
				);
				dispatch(requestCompanyProfile());
				setShowEditBtn(true);
				setShowEditor(false);
				setIsUpdateBtnDisabled(true);
			});
	}

	const onCancel = () => {
		setShowEditBtn(true);
		setShowEditor(false);
		setIsUpdateBtnDisabled(true);
		setCompanyName(props.companyName);
	}

	const updateCompanySignature = (uploadLink: string) => {
		const file = printTextOnCanvas(companyName);
		const blobFile = b64toBlob(file.replace('data:image/png;base64,', ''), 'image/png', 512);
		uploadFile(blobFile, uploadLink, 'MyCompanySignature.png');
	};


	return (
		<div className={styles.company_details_wrapper}>
			<div className={styles.company_details_header}>
				<SectionHeader title='Company Name' />
				<hr className={styles.horizontal_divider} />
			</div>
			{showEditBtn &&
				<Col sm={5} md={5} className={styles.company_details_content}>
					<div className={styles.editor_content}>

						<span title={companyName}>{companyName}</span>

						<ButtonWithTextIcon
							label='Edit'
							SvgIcon={<EditIcon />}
							className={`${styles.actionBtn} ${styles.editBtn}`}
							onClick={() => {
								setShowEditBtn(false);
								setShowEditor(true);
							}}
							data-test-auto='da47457d-8214-42e1-91c5-797520bd8ace'
							bordered
						/>
					</div>
				</Col>
			}

			{showEditor && (
				<Col sm={6} md={6} className={styles.company_details_content}>
					<div className={styles.editor_content}>
						<input
							type="text"
							value={companyName}
							onChange={(event) => { handleCompanyNameChange(event) }}
							onBlur={handleOnCompanyNameEditBlur}
							maxLength={100}
							data-test-auto='7e405280-d199-476d-aa96-55663d11e961'
						/>

						<ButtonWithTextIcon
							label='Update'
							className={`${styles.actionBtn} ${styles.updateBtn}`}
							disabled={isUpdateBtnDisabled}
							onClick={updateCompany}
							data-test-auto='4bcde0e5-f4c2-4d9a-9a61-fc61c2680193'
						/>

						<ButtonWithTextIcon
							label='Cancel'
							className={`${styles.margin_left} ${styles.cancelBtn} ${styles.actionBtn}`}
							onClick={onCancel}
							data-test-auto='d4a396e2-f2aa-40e3-b2f0-1ee947cd1551'
						/>

					</div>
				</Col>
			)}

			<canvas
				id='canvasComponent'
				style={{ display: 'none' }}
			/>
		</div>
	);
}