import { WithLogout, WithLogoutProps } from "src/oidcClient/WithLogout";
import { FC } from "react";

export const RestrictedAccess: FC<WithLogoutProps> = ({
    onLogout
}) => {
 
    return (
        <div style={{ marginLeft: '10px' }}>
            <div className="marB15">
                <h1 className="fontfamily-inital" style={{ fontSize: '2em', fontWeight: 'bolder' }}>
                    Restricted access
                </h1>
            </div>

            <div className="marB15">
                <h4 className="fontfamily-inital" style={{ fontSize: '1em', fontWeight: 'bolder' }}>
                    This Client's IP address is not allowed to access the server.
                </h4>
            </div>

            <div>
                <a
                    className="fontfamily-inital"
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={onLogout}
                >
                    Click here to login.
                </a>
            </div>
        </div>
    )
}

export default WithLogout(RestrictedAccess);