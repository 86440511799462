import { type FC } from 'react';

import styles from './color-display.module.scss';
import { type IColorDisplayProps } from './color-display.types';

const ColorDisplay: FC<IColorDisplayProps> = (props) => {
	const { title, color } = props;
	return (
		<div className={styles.color_display_wrapper}>
			<div className={styles.color_display_title}>{title}</div>
			<div
				className={styles.color_display_palette}
				style={{ backgroundColor: `${color}` }}
			/>
		</div>
	);
};

export default ColorDisplay;
