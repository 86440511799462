import React, { FC } from 'react';
import styles from './PasswordPolicySettings.module.scss';
import { IPasswordSettings, PasswordOptions } from '../PasswordPolicySettings.model';

interface PasswordPolicySettingsProps {
	passwordSettings: IPasswordSettings;
	handlePasswordOptionChange(event: any, changedOption: PasswordOptions): void;
	handlePasswordLengthChange(event: any): void;
	handlePasswordMaximumAgeChange(event: any): void;
	disable: string;
}

const PasswordPolicySettings: FC<PasswordPolicySettingsProps> = (props) => {
	const buildOptionsLength = () => {
		return [8, 9, 10, 11, 12, 13, 14, 15].map((each) => {
			return (
				<option
					key={each.toString()}
					value={each.toString()}>
					{each.toString()}
				</option>
			);
		});
	};

	const buildOptionsAge = () => {
		return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((each) => {
			return (
				<option
					key={each.toString()}
					value={each.toString()}>
					{each.toString()}
				</option>
			);
		});
	};

	const isPasswordOptionSelected = (option: PasswordOptions) => {
		return (props.passwordSettings.passwordOptions & option) === option;
	};

	return (
		<div
			className={props.disable === 'ssrDefault' ? styles.ssrDefault : ''}
			id={props.disable === 'ssrDefault' ? 'ssrContent' : 'fdpContent'}>
			<h4 className={`suitePageSectionSubHeader ${props.disable === 'ssrDefault' ? styles.disabledTextColor : ''}`}>
				Character Types
			</h4>
			<p className={`suitePageSectionDescription ${props.disable === 'ssrDefault' ? styles.disabledTextColor : ''}`}>
				Please choose a minimum of two options.
			</p>

			<div className={styles.passwordOptions}>
				<div>
					<input
						type='checkbox'
						id='UpperCase'
						checked={isPasswordOptionSelected(PasswordOptions.UpperCase)}
						onChange={(e: any) => {
							props.handlePasswordOptionChange(e, PasswordOptions.UpperCase);
						}}
						disabled={props.disable === 'ssrDefault' ? true : false}
						data-test-auto='e6bed6aa-9e3b-4329-a737-fbf97c580331'
					/>
					<label
						htmlFor='UpperCase'
						className={props.disable === 'ssrDefault' ? styles.disabledTextColor : ''}>
						Upper case
					</label>
				</div>
				<div>
					<input
						type='checkbox'
						id='LowerCase'
						checked={isPasswordOptionSelected(PasswordOptions.LowerCase)}
						onChange={(e: any) => {
							props.handlePasswordOptionChange(e, PasswordOptions.LowerCase);
						}}
						disabled={props.disable === 'ssrDefault' ? true : false}
						data-test-auto='5c3807b5-935e-4977-9e00-e81084c6875b'
					/>
					<label
						htmlFor='LowerCase'
						className={props.disable === 'ssrDefault' ? styles.disabledTextColor : ''}>
						Lower case
					</label>
				</div>
				<div>
					<input
						type='checkbox'
						id='Numbers'
						checked={isPasswordOptionSelected(PasswordOptions.Numbers)}
						onChange={(e: any) => {
							props.handlePasswordOptionChange(e, PasswordOptions.Numbers);
						}}
						disabled={props.disable === 'ssrDefault' ? true : false}
						data-test-auto='44d600cc-ed84-4db3-ae97-022f0a380cc0'
					/>
					<label
						htmlFor='Numbers'
						className={props.disable === 'ssrDefault' ? styles.disabledTextColor : ''}>
						Numbers
					</label>
				</div>
				<div>
					<input
						type='checkbox'
						id='SpecialCharacters'
						checked={isPasswordOptionSelected(PasswordOptions.SpecialCharacters)}
						onChange={(e: any) => {
							props.handlePasswordOptionChange(e, PasswordOptions.SpecialCharacters);
						}}
						disabled={props.disable === 'ssrDefault' ? true : false}
						data-test-auto='2650a5ab-c528-4564-92b2-6b69cf83e0b9'
					/>
					<label
						htmlFor='SpecialCharacters'
						className={props.disable === 'ssrDefault' ? styles.disabledTextColor : ''}>
						Special characters
					</label>
				</div>
			</div>
			<h4 className={`suitePageSectionSubHeader ${props.disable === 'ssrDefault' ? styles.disabledTextColor : ''}`}>
				Minimum number of characters
			</h4>
			<div className={styles.pwdLength}>
				<select
					name='pwdLength'
					id='pwdLength'
					className={props.disable === 'ssrDefault' ? styles.ssrDefault : ''}
					value={props.passwordSettings.length.toString()}
					onChange={props.handlePasswordLengthChange}
					data-test-auto='0cadfa46-d0a8-4290-a5a4-da722f2591a1'>
					{buildOptionsLength()}
				</select>
			</div>
			<h4 className={`suitePageSectionSubHeader ${props.disable === 'ssrDefault' ? styles.disabledTextColor : ''}`}>
				Maximum password age
			</h4>
			<div className={styles.passwordAgeText}>
				<span className={styles.requireText}>Require users to change password every</span>
				<span className={styles.selectBox}>
					<select
						name='pwdAge'
						id='pwdAge'
						className={props.disable === 'ssrDefault' ? styles.ssrDefault : ''}
						value={props.passwordSettings.passwordAge.toString()}
						onChange={props.handlePasswordMaximumAgeChange}
						data-test-auto='52a34d6c-ef99-4f63-aa52-09e51ee02f8e'>
						{buildOptionsAge()}
					</select>
				</span>
				<span className={styles.monthsText}>
					months.&nbsp;
					{props.passwordSettings.passwordAge === 0 ? (
						<label className='text-danger'>Warning: If "0" is selected, the user passwords will never expire.</label>
					) : (
						''
					)}
				</span>
			</div>
		</div>
	);
};

export default PasswordPolicySettings;
