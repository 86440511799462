import React, { useEffect } from 'react';
import './UsageReport.style.scss';
import BreadCrumbNav from '../../components/userManagement/BreadCrumbNav';
import Reports from '../../modules/usageReport';
import { UsageReportProps } from './UsageReport.model';
import { logger } from '../../oidcClient/authProvider';
import { getBundleName } from '../../helper/HelperFunctions';

const UsageReport: React.FC<UsageReportProps> = (props) => {
	const breadCrumbConfig = [
		{
			name: 'SafeSend Suite',
			activePage: false,
			link: '/',
		},
		{
			name: 'Usage Report',
			activePage: true,
			link: '',
		},
	];

	useEffect(() => {
		logger && logger.trackPageView('Navigated to Usage Report');
	}, []);

	return (
		<div className='content'>
			<section className='usageReport'>
				<BreadCrumbNav config={breadCrumbConfig} />
				<h1 className='usageReportHeading'>Usage Report</h1>
				<p className='usageReportInfo'>You have subscribed to the {getBundleName(props.companyData)} Tier</p>
				<section className='mainSection'>
					<Reports
						usageReport={props.usageReport}
						requestClientLedgerData={props.requestClientLedgerData}
						requestResourceIds={props.requestResourceIds}
					/>
				</section>
			</section>
		</div>
	);
};

export default UsageReport;
