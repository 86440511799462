import React, { FC } from 'react';
import { IMFAIPAddressModel, IMFASettingsState } from '../MFASettings.model';
import { ICompanyMfaSettings } from '../MFASettings.model';
import MFAIP from '../MFAIP';
import styles from './MFAIPSettings.module.scss';

interface MFAIPSettingsProps {
	mfaSettings: IMFASettingsState;
	saveMFAIPAddress(imfaIPAddressModel: IMFAIPAddressModel, callback: () => void): void;
	deleteMFAIPAddress(ipAddressId: number, callback: () => void): void;
	updateMFAIPAddress(imfaIPAddressModel: IMFAIPAddressModel, callback: () => void): void;
	requestMFAIPAddress(reload: boolean): void;
	handleSkipMfa(event: any): void;
	handleRememberTrustedDevice(event: any): void;
	onChangetrustedDeviceExpiryDays(event: any): void;
	settings: ICompanyMfaSettings;
}
const MFAIPSettings: FC<MFAIPSettingsProps> = (props) => {
	const {
		mfaSettings,
		saveMFAIPAddress,
		deleteMFAIPAddress,
		updateMFAIPAddress,
		requestMFAIPAddress,
		handleSkipMfa,
		handleRememberTrustedDevice,
		onChangetrustedDeviceExpiryDays,
		settings,
	} = props;

	return (
		<div>
			<div>
				<h4 className={styles.trustedIPHeader}>Trusted IP's </h4>
			</div>
			<div className={styles.skipMFACheck}>
				<input
					type='checkbox'
					id='cbSkipMfa'
					checked={settings.skipMfaForIP}
					onChange={handleSkipMfa}
					data-test-auto='9e7df1f8-3403-4319-a57a-a509f212814d'
				/>
				<label
					htmlFor='cbSkipMfa'
					className={settings.skipMfaForIP ? 'checked' : ''}>
					Skip multi-factor authentication for requests from federated users
				</label>
			</div>
			<div className={styles.skipMFAIPHead}>
				Skip multi-factor authentication for requests from following range
				<br />
				of IP address subnets
			</div>
			<div>
				<MFAIP
					mfaSettings={mfaSettings}
					saveMFAIPAddress={saveMFAIPAddress}
					deleteMFAIPAddress={deleteMFAIPAddress}
					requestMFAIPAddress={requestMFAIPAddress}
					updateMFAIPAddress={updateMFAIPAddress}
				/>
			</div>
			<div>
				<h4 className={styles.trustedIPHeader}>Remember Multifactor Authentication</h4>
			</div>
			<div className={styles.skipMFACheck}>
				<input
					type='checkbox'
					id='cbRememberMfa'
					checked={settings.isAllowUsertoRememberTrustedDevice}
					onChange={handleRememberTrustedDevice}
					data-test-auto='f394ba2e-3ad9-4b77-871f-4c78bd89cc1b'
				/>
				<label
					htmlFor='cbRememberMfa'
					className={settings.isAllowUsertoRememberTrustedDevice ? 'checked' : ''}>
					Allow users to remember multi-factor authentication on devices they trust
				</label>
			</div>
			<div className={styles.reauthenticateDaysBlock}>
				Days before a device must re-authenticate (1-60)
				<input
					type='text'
					data-test-auto='4663ee12-759d-4c37-81ca-f1e6abc63411'
					className='w40 marL10 padL10'
					value={settings.trustedDeviceExpiryDays}
					onChange={onChangetrustedDeviceExpiryDays}
					maxLength={2}
					disabled={!settings.isAllowUsertoRememberTrustedDevice}
					data-lpignore='true'></input>
			</div>
		</div>
	);
};

export default MFAIPSettings;
