import React from 'react';

const Calender: React.FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='17'
			height='16'
			fill='none'
			viewBox='0 0 17 16'>
			<path
				fill='#fff'
				fillOpacity='0.01'
				d='M0 0H16V16H0z'
				transform='translate(.998)'></path>
			<g clipPath='url(#clip0_3208_165978)'>
				<path
					fill='#fff'
					fillOpacity='0.01'
					d='M0 0H14V14H0z'
					transform='translate(1.998 1)'></path>
				<g
					fill='#565A5E'
					clipPath='url(#clip1_3208_165978)'>
					<path d='M11.495 7.252a.437.437 0 010 .62L8.87 10.495a.439.439 0 01-.62 0L6.939 9.184a.438.438 0 11.62-.62L8.56 9.568l2.315-2.316a.437.437 0 01.62 0z'></path>
					<path d='M5.06.999a.438.438 0 01.438.438v.437h7v-.437a.437.437 0 11.875 0v.437h.875a1.75 1.75 0 011.75 1.75v9.625a1.75 1.75 0 01-1.75 1.75h-10.5a1.75 1.75 0 01-1.75-1.75V3.624a1.75 1.75 0 011.75-1.75h.875v-.437a.437.437 0 01.438-.438zm-2.187 3.5v8.75a.875.875 0 00.875.875h10.5a.875.875 0 00.875-.875v-8.75H2.873z'></path>
				</g>
			</g>
			<defs>
				<clipPath id='clip0_3208_165978'>
					<path
						fill='#fff'
						d='M0 0H14V14H0z'
						transform='translate(1.998 1)'></path>
				</clipPath>
				<clipPath id='clip1_3208_165978'>
					<path
						fill='#fff'
						d='M0 0H14V14H0z'
						transform='translate(1.998 1)'></path>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Calender;
