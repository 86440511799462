import React, { FC } from 'react';
import FormFieldWithError from 'src/components/common/FormFieldWithError';

import {
	IOfficeLocationErrorsModel,
	IOfficeModel,
} from '../save-office-location-modal/save-office-location-modal.types';
import styles from './office-information.module.scss';

export interface IStateOptions {
	value: string;
	name: string;
}

interface OfficeInformationProps {
	officeLocation: IOfficeModel;
	officeLocationErrors: IOfficeLocationErrorsModel;
	handleNameChange: (event: any) => void;
	handleOnBlurName: (event: any) => void;
	handleAddress1Change: (event: any) => void;
	handleOnBlurAddress1: (event: any) => void;
	handleAddress2Change: (event: any) => void;
	handleOnBlurAddress2: (event: any) => void;
	handleCityChange: (event: any) => void;
	handleOnBlurCity: (event: any) => void;
	handleStateChange: (event: any) => void;
	handleOnBlurState: (event: any) => void;
	handleZipChange: (event: any) => void;
	handleOnBlurZip: (event: any) => void;
	handlePhoneChange: (event: any) => void;
	handleOnBlurPhone: (event: any) => void;
	handleFaxChange: (event: any) => void;
	handleOnBlurFax: (event: any) => void;
	handleWebsiteChange: (event: any) => void;
	handleOnBlurWebsite: (event: any) => void;
	stateOptions: IStateOptions[];
}

const OfficeInformation: FC<OfficeInformationProps> = (props) => {
	const {
		officeLocation,
		officeLocationErrors,
		handleNameChange,
		handleOnBlurName,
		handleAddress1Change,
		handleOnBlurAddress1,
		handleAddress2Change,
		handleOnBlurAddress2,
		handleCityChange,
		handleOnBlurCity,
		handleStateChange,
		handleOnBlurState,
		handleZipChange,
		handleOnBlurZip,
		handlePhoneChange,
		handleOnBlurPhone,
		handleFaxChange,
		handleOnBlurFax,
		handleWebsiteChange,
		handleOnBlurWebsite,
		stateOptions,
	} = props;

	return (
		<section className={styles.officeInformation}>
			<FormFieldWithError
				type='text'
				name='name'
				id='name'
				title='Office Name'
				maxLength='50'
				onChange={handleNameChange}
				onBlur={handleOnBlurName}
				value={officeLocation.name}
				error={officeLocationErrors.name.error}
				errorMessage={officeLocationErrors.name.message}
				fieldWidth='625px'
			/>
			<div className={styles.address}>
				<FormFieldWithError
					type='text'
					name='address1'
					id='address1'
					title='Street Address'
					maxLength='100'
					onChange={handleAddress1Change}
					onBlur={handleOnBlurAddress1}
					value={officeLocation.address1}
					error={officeLocationErrors.address1.error}
					errorMessage={officeLocationErrors.address1.message}
					fieldWidth='400px'
				/>
				<FormFieldWithError
					type='text'
					name='address2'
					id='address2'
					title='Apt, suite, etc'
					maxLength='100'
					onChange={handleAddress2Change}
					onBlur={handleOnBlurAddress2}
					value={officeLocation.address2}
					error={officeLocationErrors.address2.error}
					errorMessage={officeLocationErrors.address2.message}
					optional={true}
					fieldWidth='200px'
				/>
			</div>
			<div className={styles.cityStateZip}>
				<FormFieldWithError
					type='text'
					name='city'
					id='city'
					title='City'
					maxLength='100'
					onChange={handleCityChange}
					onBlur={handleOnBlurCity}
					value={officeLocation.city}
					error={officeLocationErrors.city.error}
					errorMessage={officeLocationErrors.city.message}
					fieldWidth='188px'
				/>
				<FormFieldWithError
					type='select'
					name='state'
					id='state'
					title='State'
					onChange={handleStateChange}
					onBlur={handleOnBlurState}
					value={officeLocation.state.toString()}
					error={officeLocationErrors.state.error}
					errorMessage={officeLocationErrors.state.message}
					fieldWidth='189px'
					options={stateOptions}
					selectFirstOption={{ value: '0', name: '' }}
				/>
				<FormFieldWithError
					type='text'
					name='zip'
					id='zip'
					title='Zip Code'
					maxLength='5'
					onChange={handleZipChange}
					onBlur={handleOnBlurZip}
					value={officeLocation.zip}
					error={officeLocationErrors.zip.error}
					errorMessage={officeLocationErrors.zip.message}
					fieldWidth='200px'
				/>
			</div>
			<div className={styles.phoneFax}>
				<div className={styles.phone}>
					<span className={styles.flag}>
						<img
							src={require('../../../../components/svg/USFlag.png')}
							alt='US Flag'
						/>
					</span>
					<FormFieldWithError
						type='text'
						name='phone'
						id='phone'
						title='Phone'
						maxLength='10'
						onChange={handlePhoneChange}
						onBlur={handleOnBlurPhone}
						value={officeLocation.phone}
						error={officeLocationErrors.phone.error}
						errorMessage={officeLocationErrors.phone.message}
						fieldWidth='188px'
						optional={true}
					/>
				</div>
				<div className={styles.fax}>
					<span className={styles.flag}>
						<img
							src={require('../../../../components/svg/USFlag.png')}
							alt='US Flag'
						/>
					</span>
					<FormFieldWithError
						type='text'
						name='fax'
						id='fax'
						title='Fax'
						maxLength='10'
						onChange={handleFaxChange}
						onBlur={handleOnBlurFax}
						value={officeLocation.fax}
						error={officeLocationErrors.fax.error}
						errorMessage={officeLocationErrors.fax.message}
						fieldWidth='189px'
						optional={true}
					/>
				</div>
			</div>
			<FormFieldWithError
				type='text'
				name='website'
				id='website'
				title='Website'
				maxLength='100'
				onChange={handleWebsiteChange}
				onBlur={handleOnBlurWebsite}
				value={officeLocation.website}
				error={officeLocationErrors.website.error}
				errorMessage={officeLocationErrors.website.message}
				fieldWidth='625px'
				optional={true}
			/>
		</section>
	);
};

export default OfficeInformation;
