const useUserManagement = (props: any) => {
    const {
        userGroupList = {},
        rolesData = {},
        addUserGroup = () => null,
        updateUserGroup = () => null,
        requestUserById = () => null,
        requestAllUsers = () => null,
        requestAllUserGroups = (newGroupIds?:number[]) => null,
        requestRoleDrowpdownList = () => null,
        deleteUserGroup = () => null,
        roleDropdownList = [],
        getUsersInGroup = () => null,
        applyChanges = () => null,
        requestAllRoles = () => null,
        requestAllProducts = () => null,
        requestPermissions = () => null,
        validateUserGroupUpdate = () => null,
    } = props;
    const userGroupsListProps = {
        userGroupList,
        addUserGroup,
        updateUserGroup,
        requestUserById,
        requestAllUsers,
        requestAllUserGroups,
        requestRoleDrowpdownList,
        deleteUserGroup,
        roleDropdownList,
        getUsersInGroup,
        applyChanges,
        validateUserGroupUpdate,
    };

    const rolesProps = {
        requestAllRoles,
        rolesData,
        requestAllProducts,
        requestPermissions
    };

    return {
        userGroupsListProps,
        rolesProps
    };
};

export default useUserManagement;