import axios, { AxiosResponse } from 'axios';
import { SSOAccessRequestStatus } from './UserPermission.model';
import { API_BASE_URL, SSOAccessRequestConstants } from '../../helper/Constants';
import { AppNotifier as VenusNotifier } from '../../helper/AppNotifier';
import { userPermissionResources } from '../../helper/rbac-constants';
import { logger } from 'src/oidcClient/authProvider';

const grantSSOAccessRequest = async (emailAddress: string, callback: () => void) => {
	try {
		const response: AxiosResponse<SSOAccessRequestStatus> = await axios.post(
			API_BASE_URL + `/api/user-permission/approve?emailAddress=${emailAddress}`,
			{},
			{
				headers: {
					'X-Resource-Id': `${userPermissionResources.grant}`,
				},
			},
		);
		if (response.data == SSOAccessRequestStatus.GrantSuccess) {
			VenusNotifier.Success(SSOAccessRequestConstants.StatusMessage.GrantSSOAccessRequestSuccess);
		} else if (response.data == SSOAccessRequestStatus.GrantError) {
			VenusNotifier.Error(SSOAccessRequestConstants.StatusMessage.GrantSSOAccessRequestError);
		} else if (response.data == SSOAccessRequestStatus.AlreadyGranted) {
			VenusNotifier.Error(SSOAccessRequestConstants.StatusMessage.AlreadyGranted);
		} else if (response.data == SSOAccessRequestStatus.AlreadyDenied) {
			VenusNotifier.Error(SSOAccessRequestConstants.StatusMessage.AlreadyDenied);
		}
		callback();
	} catch (error) {
		logger && logger.trackWarning('Error while grantSSOAccessRequest', { 'Error': error?.response?.data});
		VenusNotifier.Error(SSOAccessRequestConstants.StatusMessage.GrantSSOAccessRequestError);
	}
};

const denySSOAccessRequest = async (emailAddress: string, callback: () => void) => {
	try {
		const response: AxiosResponse<SSOAccessRequestStatus> = await axios.post(
			API_BASE_URL + `/api/user-permission/reject?emailAddress=${emailAddress}&comment=`,
			{},
			{
				headers: {
					'X-Resource-Id': `${userPermissionResources.deny}`,
				},
			},
		);
		if (response.data == SSOAccessRequestStatus.DenySuccess) {
			VenusNotifier.Success(SSOAccessRequestConstants.StatusMessage.DenySSOAccessRequestSuccess);
		} else if (response.data == SSOAccessRequestStatus.DenyError) {
			VenusNotifier.Error(SSOAccessRequestConstants.StatusMessage.DenySSOAccessRequestError);
		} else if (response.data == SSOAccessRequestStatus.AlreadyGranted) {
			VenusNotifier.Error(SSOAccessRequestConstants.StatusMessage.AlreadyGranted);
		} else if (response.data == SSOAccessRequestStatus.AlreadyDenied) {
			VenusNotifier.Error(SSOAccessRequestConstants.StatusMessage.AlreadyDenied);
		}
		callback();
	} catch (error) {
		logger && logger.trackWarning('Error while denySSOAccessRequest', { 'Error': error?.response?.data});
		VenusNotifier.Error(SSOAccessRequestConstants.StatusMessage.DenySSOAccessRequestError);
	}
};

const grant = async (param: string, callback: (status: SSOAccessRequestStatus) => void) => {
	try {
		const response: AxiosResponse<SSOAccessRequestStatus> = await axios.get(
			API_BASE_URL + `/api/user-permission/grant?id=${param}`,
			{
				headers: {
					'X-Resource-Id': `${userPermissionResources.grant}`,
				},
			},
		);
		callback(response.data);
	} catch (error) {
		logger && logger.trackWarning('Error while grant', { 'Error': error?.response?.data});
		VenusNotifier.Error(SSOAccessRequestConstants.StatusMessage.GrantSSOAccessRequestError);
	}
};

const deny = async (param: string, callback: (status: SSOAccessRequestStatus) => void) => {
	try {
		const response: AxiosResponse<SSOAccessRequestStatus> = await axios.get(
			API_BASE_URL + `/api/user-permission/deny?id=${param}`,
			{
				headers: {
					'X-Resource-Id': `${userPermissionResources.deny}`,
				},
			},
		);
		callback(response.data);
	} catch (error) {
		logger && logger.trackWarning('Error while deny', { 'Error': error?.response?.data});
		VenusNotifier.Error(SSOAccessRequestConstants.StatusMessage.DenySSOAccessRequestError);
	}
};

export { grantSSOAccessRequest, denySSOAccessRequest, grant, deny };
