import React, { useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { InfoIcon } from "../svg/IconCollection";

const Banner = () => {
    const [hide, setHide] = useState<boolean>(false);

    const onButtonClick = () => {
        window.open("https://safesendreturns.zendesk.com/hc/en-us/articles/8519444042643-Embedded-Help-in-the-SafeSend-Suite");
    };
    return <section className="banner-section">
        <Container fluid="sm">
            {/* THIS ALERT WILL BE HIDDEN FOR NOW */}
            <Alert show={false} onClose={() => setHide(true)} dismissible>
                <InfoIcon />
                <span className="ms-2">Holy guacamole! You should check in on some of those fields below.</span>
            </Alert>
            <Container>
                <Row className="banner-container">
                    <Col className="image-column p-0" xs={6}>
                    </Col>
                    <Col className="content">
                        <div className="sub-title">WALK-THRUS MADE EASY</div>
                        <h3>Introducing SafeSend Steve</h3>
                        <div className="description">Discover new features with our new interactive walk-thrus tool, a step-by-step smart way to help you and your team get informed, understand and learn our products.</div>
                        <div>
                            <button className="button-primary-green-rounded btn-shamrock mt-3" onClick={onButtonClick}>Learn more</button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    </section>;
};

export default Banner;