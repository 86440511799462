import React, { FC } from "react";

const O365: FC = () => {
    return (
        <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14 15.5833L13.9985 15.5824V1.4599L9.00319 0L0.0225446 3.41552L0 3.41995L0.0010944 13.6315L3.06563 12.4166V4.10833L9.00319 2.67038L9.00231 14.8859L0.00612864 13.6315L9.00231 16.9962V17L13.9987 15.5995V15.5833H14Z" fill="#CC4A43" />
        </svg>

    );
};

export default O365;