import { FC, useState, useEffect } from 'react';
import { IPermissionsProps, IProducts, IRoles, IPermissions } from './roles-and-permissions.types';
import ColumnWithHeader from './partials/column-with-header';
import AccordianWithDescription from './partials/accordian-with-description';
import { sortArrayOfObjectWithStrings } from '../../../common';
import './roles-and-permissions.style.scss';

const RolesAndPermissions: FC<IPermissionsProps> = (props) => {
	const { display, requestAllRoles, requestAllProducts, requestPermissions } = props;

	const getProductProps = (products: IProducts[]) => {
		const productProps =
			products &&
			products.map((product: IProducts) => {
				return {
					id: product.productId,
					name: product.productName,
					isSubscribed: product.isSubscribed || product.productId === 1001,
					isActive: product.productStatus === 1 ? true : false,
				};
			});

		return productProps;
	};

	const getRolesProps = (roles: IRoles[]) => {
		const rolesProps =
			roles &&
			sortArrayOfObjectWithStrings(roles, 'roleName').map((role: IRoles) => {
				return {
					id: role.roleId,
					name: role.roleName,
					description: role.products,
					isSubscribed: true,
					isActive: true,
				};
			});

		return rolesProps;
	};
	const [selectedRole, setSelectedRole] = useState(0);
	const [selectedProduct, setSelectedProduct] = useState(0);
	const [selectedAction, setSelectedAction] = useState(-1);
	const [actionList, setActionList] = useState<IPermissions[]>([]);
	const [productList, setProductList] = useState(getProductProps());
	const [rolesList, setRolesList] = useState(getRolesProps());

	useEffect(() => {
		requestAllProducts((data: IProducts[]) => {
			const products = getProductProps(data);
			setProductList(products);
			setSelectedProduct(products.length > 0 ? products.filter(prod => prod.isSubscribed === true)[0].id : 0);

			requestAllRoles(products.filter(prod => prod.isSubscribed === true)[0].id, (data: IRoles[]) => {
				const roles = getRolesProps(data);
				setRolesList(roles);
				setSelectedRole(roles.length > 0 ? roles[0].id : 0);

				requestPermissions(roles[0].id, products.filter(prod => prod.isSubscribed === true)[0].id, (data) => {
					const sortedPermissionsList: IPermissions[] = sortArrayOfObjectWithStrings(data, 'Name');
					setActionList(sortedPermissionsList);
				});
			});
		});
	}, []);
	useEffect(() => {
		requestAllRoles(selectedProduct, (data: IRoles[]) => {
			const roles = getRolesProps(data);
			setRolesList(roles);
			setSelectedRole(roles.length > 0 ? roles[0].id : 0);
		});
	}, [selectedProduct]);

	const handleRoleChange = (id: number) => {
		setSelectedRole(id);
	};
	const handleProductChange = (id: number) => {
		setSelectedProduct(id);
	};
	const handleActionChange = (id: number) => {
		setSelectedAction(id);
	};
	useEffect(() => {
		requestPermissions(selectedRole, selectedProduct, (data) => {
			const sortedPermissionsList: IPermissions[] = sortArrayOfObjectWithStrings(data, 'Name');
			setActionList(sortedPermissionsList);
		});
	}, [selectedRole, selectedProduct]);
	return (
		<div className={`roles-permissions-container ${display ? 'showRoles' : 'hideRoles'}`}>
			<div className='products-container'>
				<ColumnWithHeader
					selected={selectedProduct}
					onClick={handleProductChange}
					title='PRODUCTS'
					roles={productList}
				/>
			</div>
			<div className='roles-container'>
				<ColumnWithHeader
					selected={selectedRole}
					title='ROLES'
					roles={rolesList}
					onClick={handleRoleChange}
				/>
			</div>

			<div className='permissions-container'>
				<AccordianWithDescription
					title='PERMISSIONS'
					listData={actionList}
					selected={selectedAction}
					onClick={handleActionChange}
					selectedProduct={selectedProduct}
				/>
			</div>
		</div>
	);
};

export default RolesAndPermissions;
