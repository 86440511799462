import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SSOAccessRequestStatus } from '../UserPermission.model';
import { grant } from '../UserPermission.api';
import { SSOAccessRequestConstants } from '../../../helper/Constants';
import '../UserPermission.style.scss';

const Grant: React.FC = () => {
	const [searchParams] = useSearchParams();
	const param = searchParams.get('param');

	const [requestData, setRequestData] = useState(SSOAccessRequestStatus.None);

	useEffect(() => {
		if (param) {
			grant(param, (result: SSOAccessRequestStatus) => {
				setRequestData(result);
			});
		}
	}, []);

	return (
		<div className='requestAdmin'>
			<h3 className='requestAdmin_header'>Grant Single Sign-On Access Request</h3>
			{requestData !== SSOAccessRequestStatus.None && (
				<div>
					{requestData === SSOAccessRequestStatus.GrantSuccess && (
						<div className='alert alert-success'>
							<p>{SSOAccessRequestConstants.StatusMessage.GrantSSOAccessRequestSuccess}</p>
						</div>
					)}

					{requestData === SSOAccessRequestStatus.GrantError && (
						<div className='alert alert-danger'>
							<p>{SSOAccessRequestConstants.StatusMessage.GrantSSOAccessRequestError}</p>
						</div>
					)}

					{requestData === SSOAccessRequestStatus.AlreadyGranted && (
						<div className='alert alert-danger'>
							<p>{SSOAccessRequestConstants.StatusMessage.AlreadyGranted}</p>
						</div>
					)}

					{requestData === SSOAccessRequestStatus.AlreadyDenied && (
						<div className='alert alert-danger'>
							<p>{SSOAccessRequestConstants.StatusMessage.AlreadyDenied}</p>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default Grant;
