import { useEffect } from 'react';

import { logger } from '../../oidcClient/authProvider';
import { requestCompanyProfile } from '../common/companySettings/companySettings.apis';
import {
	ICompanyTierDetailsProps,
	type ICompanyDetailsProps,
	type ICompanyManagementHooksReturns,
	type ICompanyManagementProps,
	type ICountryState,
} from './company-management.types';

const useCompanyManagement = (props: ICompanyManagementProps): ICompanyManagementHooksReturns => {
	const {
		companyData: {
			companyProfile: {
				companyInfo: {
					companyName = '',
					address = '',
					city = '',
					stateId = -1,
					zip = 0,
					fax = '',
					phoneNumber = '',
					website = '',
					adminUser = 0,
					unit = '',
				} = {},
				countryStates = [],
				companySubscription: { name = '', id = 0},
				companyProducts = [],
			} = {},
			companyUploadProfileLink = '',
		} = {},
		companyManagementData: { isUserLoading = false, adminUsers = [], officeList = {}, allProducts = [], allAddOnFeatures = [] } = {},
		getAdminUsers,
		getAllCompanyList,
		updateProducts,
		updateCompany,
		notifyTierChange,
		updatePrimaryAdmin,
		getAllProducts,
		getAllAddOnFeatures,
		updateAddOnFeatures
	} = props;

	useEffect(() => {
		logger && logger.trackPageView('Navigated to Company Management');
		getAdminUsers();
		getAllCompanyList();
		getAllProducts();
		getAllAddOnFeatures();
	}, []);
	
	const getStateFromStateId = (id: number): ICountryState => {
		return countryStates.find((state: ICountryState) => state.id === id);
	};

	const getAdminUserDetails = (id: number): any => {
		return adminUsers?.find((user: any) => user.id === id);
	};

	const breadCrumbConfig = [
		{
			activePage: false,
			link: '/',
			name: 'SafeSend Suite',
		},
		{
			activePage: true,
			link: '',
			name: 'My Company',
		},
	];

	const companyDetailsProps: ICompanyDetailsProps = {
		companyName,
		address,
		unit,
		city,
		state: getStateFromStateId(stateId),
		zip,
		fax,
		phoneNumber,
		website,
		countryStates,
		updateCompany,
		requestCompanyProfile,
		companyInfo: props.companyData.companyProfile.companyInfo,
		companyUploadProfileLink,
		officeList,
		getStateFromStateId,
		getAllCompanyList,
	};

	const primaryAdminProps = {
		adminUsers,
		currentAdminUser: getAdminUserDetails(adminUser) || {},
		companyInfo: props.companyData.companyProfile.companyInfo,
		updatePrimaryAdmin,
		requestCompanyProfile,
	};

	const companyTierDetailsProps : ICompanyTierDetailsProps = {
		name,
		subscriptionId: id,
		allProducts: allProducts,
		allAddOnFeatures: allAddOnFeatures,
		subscribedProducts: companyProducts,
		updateProducts,
		notifyTierChange,
		updateAddOnFeatures
	};

	const editCompanyProps = {
		companyName,
		updateCompany,
	};

	return {
		breadCrumbConfig,
		companyDetailsProps,
		primaryAdminProps,
		companyTierDetailsProps,
		editCompanyProps,
	};
};

export default useCompanyManagement;
