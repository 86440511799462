import { actionTypes } from './user-groups.actions';
import { Reducer } from 'redux';
import {  DispatchAction, IUserGroupData } from './user-groups.types';

const unloadedState: IUserGroupData = {
    isLoading: false,
    groups: [],
    usersInGroup: [],
    rolesData: []
} as IUserGroupData;

export const reducer: Reducer<IUserGroupData> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_USER_GROUP_LIST:
            return {
                isLoading: true,
                groups: [...state.groups],                
            } as IUserGroupData;
        case actionTypes.RECEIVE_USER_GROUP_LIST:
            return {
                isLoading: false,
                groups: action.userGroupList,
            } as IUserGroupData;
        case actionTypes.REQUEST_USERS_IN_GROUP:
            return {
                isLoading: true,
                usersInGroup: {...state.usersInGroup}
            } as any;

        case actionTypes.REQUEST_USERS_IN_GROUP_SUCCESS:
            return {
                isLoading: false,
                usersInGroup: action['usersInGroupList']
            } as any;
        default:
        }
    
        return state;
};