import axios, { type AxiosError, type AxiosResponse } from 'axios';
import { myCompanyResources } from 'src/helper/rbac-constants';

import { API_BASE_URL } from '../../helper/Constants';
import { type AppThunkAction } from '../../store';
import { StatusType } from '../common/notification/notification.reducer';
import { actionTypes as notificationActionTypes } from '../common/notification/notification.types';
import { actionTypes } from './company-management.action';
import { type KnownAction } from './company-management.types';
import { logger } from 'src/oidcClient/authProvider';
import { IProduct, IAddOnFeature } from './company-management.types';

export const actionCreators = {
	getAdminUsers: (): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({ type: actionTypes.REQUEST_ADMIN_USERS, isUsersLoading: true });
		axios
			.get(`${API_BASE_URL}/api/users/admins`)
			.then((response: AxiosResponse<any>) => {
				const { data } = response;

				const actionData = {
					isUsersLoading: false,
					data,
				};

				dispatch({ type: actionTypes.RECEIVE_ADMIN_USERS, actionData });
			})
			.catch((error: AxiosError) => {
				logger && logger.trackWarning('Error while getAdminUsers', { 'Error': error?.response?.data});
				const statusMessage: string = error.statusText?.message ?? error.statusText;
				if (typeof statusMessage === 'string')
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusMessage,
						statusType: StatusType.Error,
					});
			});
	},
	getAllCompanyList:
		(pageNumber = 1, pageSize = 7, searchText = '', newLocationIds = ''): AppThunkAction<KnownAction> =>
			(dispatch) => {
				dispatch({ type: actionTypes.REQUEST_ALL_COMPANY_LIST, isCompanyListLoading: true });
				axios
					.get(
						`${API_BASE_URL}/api/office-location?pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}&newLocationIds=${newLocationIds}`,
					)
					.then((response: AxiosResponse<any>) => {
						const { data } = response;

						const actionData = {
							isCompanyListLoading: false,
							data,
						};

						dispatch({ type: actionTypes.RECEIVE_ALL_COMPANY_LIST, actionData });
					})
					.catch((error: AxiosError) => {
						logger && logger.trackWarning('Error while getAllCompanyList', { 'Error': error?.response?.data});
						const statusMessage: string = error.statusText?.message ?? error.statusText;
						if (typeof statusMessage === 'string')
							dispatch({
								type: notificationActionTypes.NOTIFICATION,
								statusMessage,
								statusType: StatusType.Error,
							});
					});
			},

	updateCompany:
		(companyName: string, rbacId = '', callback = () => null): AppThunkAction<KnownAction> =>
			(dispatch) => {
				axios
					.put(`${API_BASE_URL}/api/company/company-profile`,companyName, {
						headers: {
							'X-Resource-Id': rbacId,
							'Content-Type': 'application/json'
						},
					})
					.then(() => {
						dispatch({
							type: notificationActionTypes.NOTIFICATION,
							statusMessage: 'Company Information saved.',
							statusType: StatusType.Success,
						});
						callback();
					})
					.catch((error: AxiosError) => {
						logger && logger.trackWarning('Error while updateCompany', { 'Error': error?.response?.data});
						const statusMessage: string = error.statusText?.message ?? error.statusText;
						if (typeof statusMessage === 'string')
							dispatch({
								type: notificationActionTypes.NOTIFICATION,
								statusMessage,
								statusType: StatusType.Error,
							});
					});
			},

	updateProducts:
		(changedProducts: any): AppThunkAction<KnownAction> =>
			(dispatch) => {
				dispatch({ type: actionTypes.START_UPDATE_PRODUCTS, isProductUpdating: true });
				axios
					.put(`${API_BASE_URL}/api/company/update-products`, changedProducts, {
						headers: {
							'X-Resource-Id': `${myCompanyResources.prefix}_${myCompanyResources.accountDetails}_${myCompanyResources.section}`,
						},
					})
					.then(() => {
						dispatch({ type: actionTypes.START_UPDATE_PRODUCTS, isProductUpdating: false });
					})
					.catch((error: AxiosError) => {
						logger && logger.trackWarning('Error while updateProducts', { 'Error': error?.response?.data});
						const statusMessage: string = error.statusText?.message ?? error.statusText;
						if (typeof statusMessage === 'string')
							dispatch({
								type: notificationActionTypes.NOTIFICATION,
								statusMessage,
								statusType: StatusType.Error,
							});
					});
			},
	notifyTierChange:
		(query: string): AppThunkAction<KnownAction> =>
			(dispatch) => {
				axios.get(`${API_BASE_URL}/api/company/notify-product-tier-change?${query}`).catch((error: AxiosError) => {
					const statusMessage: string = error.statusText?.message ?? error.statusText;
					if (typeof statusMessage === 'string')
						dispatch({
							type: notificationActionTypes.NOTIFICATION,
							statusMessage,
							statusType: StatusType.Error,
						});
				});
			},
	updatePrimaryAdmin: (userId: number, resourceId: string, callback = () => null): AppThunkAction<KnownAction> =>
		(dispatch) => {
			axios
				.put(`${API_BASE_URL}/api/company/update-primary-admin/${userId}`, {
					headers: {
						'X-Resource-Id': resourceId,
					},
				})
				.then(() => {
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusMessage: 'Primary Admin updated successfully.',
						statusType: StatusType.Success,
					});
					callback();
				})
				.catch((error: AxiosError) => {
                    logger && logger.trackWarning('Error while updatePrimaryAdmin', { 'Error': error?.response?.data});
					const statusMessage: string = error.statusText?.message ?? error.statusText;
					if (typeof statusMessage === 'string')
						dispatch({
							type: notificationActionTypes.NOTIFICATION,
							statusMessage,
							statusType: StatusType.Error,
						});
				});
		},
		getAllProducts: (): AppThunkAction<KnownAction> =>
			(dispatch) => {
				dispatch({ type: actionTypes.REQUEST_ALL_PRODUCTS, actionData : { isProductsLoading: true, data: []}});
				axios
					.get(`${API_BASE_URL}/api/company/get-all-products`)
					.then((response: AxiosResponse<IProduct[]>) => {
						const { data } = response;

						const actionData = {
							isProductsLoading: false,
							data,
						};
			
						dispatch({ type: actionTypes.RECEIVE_ALL_PRODUCTS, actionData });
					})
					.catch((error: AxiosError) => {
						logger && logger.trackWarning('Error while gettingAllProducts', { 'Error': error?.response?.data});
						const statusMessage: string = error.statusText?.message ?? error.statusText;
						if (typeof statusMessage === 'string')
							dispatch({
								type: notificationActionTypes.NOTIFICATION,
								statusMessage,
								statusType: StatusType.Error,
							});
					});
		},
		
		getAllAddOnFeatures: (): AppThunkAction<KnownAction> =>
			(dispatch) => {
				dispatch({ type: actionTypes.REQUEST_ALL_ADD_ON_FEATURES, isAddOnFeaturesLoading: true });
				axios
					.get(`${API_BASE_URL}/api/company/get-all-add-on-features`)
					.then((response: AxiosResponse<any>) => {
						const { data } = response;

						const actionData = {
							isAddOnFeaturesLoading: false,
							data,
						};

						dispatch({ type: actionTypes.RECEIVE_ALL_ADD_ON_FEATURES, actionData });
					})
					.catch((error: AxiosError) => {
						logger && logger.trackWarning('Error while getAllAddOnFeatures', { 'Error': error?.response?.data});
						const statusMessage: string = error.statusText?.message ?? error.statusText;
						if (typeof statusMessage === 'string')
							dispatch({
								type: notificationActionTypes.NOTIFICATION,
								statusMessage,
								statusType: StatusType.Error,
							});
					});
			},
		updateAddOnFeatures: (changedAddOnFeatures: IAddOnFeature[]): AppThunkAction<KnownAction> =>
			(dispatch) => {
				dispatch({ type: actionTypes.START_UPDATE_ADD_ON_FEATURES, isAddOnFeaturesUpdating: true });
				axios
					.put(`${API_BASE_URL}/api/company/update-add-on-features`, changedAddOnFeatures)
					.then(() => {
						dispatch({ type: actionTypes.END_UPDATE_ADD_ON_FEATURES, isAddOnFeaturesUpdating: false });
					})
					.catch((error: AxiosError) => {
						logger && logger.trackWarning('Error while updateAddOnFeatures', { 'Error': error?.response?.data});
						const statusMessage: string = error.statusText?.message ?? error.statusText;
						if (typeof statusMessage === 'string')
							dispatch({
								type: notificationActionTypes.NOTIFICATION,
								statusMessage,
								statusType: StatusType.Error,
							});
					});
			},
};
