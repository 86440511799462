import React, { FC } from 'react';

const YellowWarningIcon: FC = () => {
	return (
		<svg
			width='16'
			height='15'
			viewBox='0 0 16 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M8.9817 1.06498C8.88271 0.892539 8.73998 0.749275 8.56791 0.649648C8.39584 0.550021 8.20053 0.497559 8.0017 0.497559C7.80287 0.497559 7.60756 0.550021 7.43549 0.649648C7.26343 0.749275 7.12069 0.892539 7.0217 1.06498L0.164702 12.732C-0.292298 13.51 0.255702 14.499 1.1447 14.499H14.8577C15.7467 14.499 16.2957 13.509 15.8377 12.732L8.9817 1.06498ZM7.9997 4.49898C8.5347 4.49898 8.9537 4.96098 8.8997 5.49398L8.5497 9.00097C8.53794 9.13875 8.4749 9.26709 8.37306 9.36061C8.27121 9.45413 8.13797 9.50603 7.9997 9.50603C7.86143 9.50603 7.72819 9.45413 7.62635 9.36061C7.5245 9.26709 7.46146 9.13875 7.4497 9.00097L7.0997 5.49398C7.08713 5.36821 7.10105 5.2412 7.14055 5.12114C7.18005 5.00108 7.24426 4.89062 7.32905 4.79689C7.41383 4.70315 7.51731 4.62821 7.63282 4.5769C7.74833 4.52559 7.87331 4.49905 7.9997 4.49898ZM8.0017 10.499C8.26692 10.499 8.52127 10.6043 8.70881 10.7919C8.89634 10.9794 9.0017 11.2338 9.0017 11.499C9.0017 11.7642 8.89634 12.0185 8.70881 12.2061C8.52127 12.3936 8.26692 12.499 8.0017 12.499C7.73649 12.499 7.48213 12.3936 7.2946 12.2061C7.10706 12.0185 7.0017 11.7642 7.0017 11.499C7.0017 11.2338 7.10706 10.9794 7.2946 10.7919C7.48213 10.6043 7.73649 10.499 8.0017 10.499Z'
				fill='#D69F38'
			/>
		</svg>
	);
};

export default YellowWarningIcon;
