import React, { useState, FC, useEffect } from 'react';
import DraggableList from '../../../../../components/common/draggable-list';
import Tooltip from '../../../../../components/common/tooltip';
import { AppNotifier } from "../../../../../helper/AppNotifier";
import search from '../../../../../components/svg/search.svg';
import './users-in-group.scss';


const UsersInGroup: FC<any> = (props) => {
    const {
        usersInGroup,
        availableUsers,
        handleDropChange,
        disableDragDrop,
        drapDropDisabledMessage = ''
    } = props;
    const [ searchText, setSearchText] = useState('');
    const [groupUser, setGroupUser] = useState<any[]>([]);
    const [searchError, setSearchError] = useState(false);

    useEffect(() => {
        setGroupUser(filterUsersBySearchText(usersInGroup, searchText));
    }, [usersInGroup, searchText]);

    const dragOverHandler = (event: any) => {
        event.preventDefault();
    };

    const dropHandler = (event: any) => {
        if(!disableDragDrop){
        const user = JSON.parse(event.dataTransfer.getData('text'));
        if (usersInGroup.some((ele: any) => user.findIndex((datum: any) => datum.userId === ele.userId) === -1) || !usersInGroup.length) {
            const newUserList = [...user, ...usersInGroup];
            const newAvailableUser = availableUsers.filter((ele: any) => !user.some((value: any) => value.userId === ele.userId));
            handleDropChange(newUserList, newAvailableUser);
        }
        }
    };

    const handleSearch = (event: any) => {
        const searchText = event.target.value;
        setSearchText(searchText);
        setGroupUser(filterUsersBySearchText(usersInGroup, searchText));
    };

    const filterUsersBySearchText = (usersInGroup: any[], searchText: string) => {
        if (searchText.length === 0) {
            setSearchError(false);
            return usersInGroup;
        } else {
            const filteredData = usersInGroup.filter((data: any) => data.fullName.toLowerCase().includes(searchText.toLowerCase()));
            setSearchError(filteredData.length === 0);
            return filteredData;
        }
    };

    const isAllowDragging = () => {
        if(disableDragDrop) {
            AppNotifier.Warning(drapDropDisabledMessage);
            return false;
        }
        return true;
    }

    return (
        <div className='groups_list_container'>
            <div className='section_header'>
                USER IN GROUP
                <Tooltip text='List of users in selected group.' />
            </div>
            <div className='content_container'
                onDragOver={(event) => dragOverHandler(event)}
                onDrop={(event) => dropHandler(event)}
            >
                <div className='search_container'>
                    <input type="text" id="search" placeholder='Search...' value={searchText} onChange={(event) => handleSearch(event)} autoComplete="off" />
                    <img src={search} />
                </div>
                <div className='list_container' onDragOver={(event) => dragOverHandler(event)}>
                    <DraggableList
                        listData={groupUser}
                        displayError={searchError}
                        errorText="No matching records found."
                        isDragAllowed={isAllowDragging}
                    />
                </div>
            </div>
        </div>
    );
};

export default UsersInGroup;