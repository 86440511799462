import axios, { type AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/Constants';
import { ipFilteringResources } from 'src/helper/rbac-constants';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { type AppThunkAction } from 'src/store/index';

import { actionTypes } from './ip-filtering-list.action';
import { type IFilertingOptionModel, type IListData, type KnownAction } from './ip-filtering-list.types';
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {
	requestIpList: (): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({ type: actionTypes.REQUEST_FILTERING_LIST, isLoading: true });
		axios
		.get(`${API_BASE_URL}/api/security-setting/ipaddress`, {
			headers: {
				'X-Resource-Id': `${ipFilteringResources.prefix}_${ipFilteringResources.ipFilterTable}_${ipFilteringResources.addButton}`,
			},
		})
			.then((response: AxiosResponse<IFilertingOptionModel>) => {
				const { data } = response;
				dispatch({ type: actionTypes.RECEIVE_FILTERING_LIST, data, isLoading: false });
			})
			.catch((error) => {
				logger && logger.trackWarning('Error while requestIpList', { 'Error': error?.response?.data});
				const statusMessage: string = error.statusText;
				if (typeof statusMessage === 'string')
					dispatch({
						type: notificationTypes.NOTIFICATION,
						statusMessage,
						statusType: StatusType.Error,
					});
			});
	},

	addToIpList:
		(updatedEntry: IListData, callback: () => void): AppThunkAction<KnownAction> =>
		(dispatch) => {
			dispatch({ type: actionTypes.REQUEST_FILTERING_LIST, isLoading: true });
			axios
				.post(`${API_BASE_URL}/api/security-setting/ipaddress`, updatedEntry, {
					headers: {
						'X-Resource-Id': `${ipFilteringResources.prefix}_${ipFilteringResources.ipFilterTable}_${ipFilteringResources.addButton}`,
					},
				})
				.then((response: AxiosResponse) => {
					const { data: { success, message } = {} } = response;
					dispatch({
						type: actionTypes.UPDATE_FILTERING_LIST,
						isLoading: false,
					});
					if (success === true) {
						dispatch({
							type: notificationTypes.NOTIFICATION,
							statusMessage: message,
							statusType: StatusType.Success,
						});
						callback();
					} else
						dispatch({
							type: notificationTypes.NOTIFICATION,
							statusMessage: message,
							statusType: StatusType.Warning,
						});
				})
				.catch((error) => {
					logger && logger.trackWarning('Error while addToIpList', { 'Error': error?.response?.data});
					const statusMessage: string = error.statusText;
					if (typeof statusMessage === 'string')
						dispatch({
							type: notificationTypes.NOTIFICATION,
							statusMessage,
							statusType: StatusType.Error,
						});
				});
		},
	editIpList:
		(updatedEntry: any, callback: () => void): AppThunkAction<KnownAction> =>
		(dispatch) => {
			dispatch({ type: actionTypes.REQUEST_FILTERING_LIST, isLoading: true });
			axios
				.put(`${API_BASE_URL}/api/security-setting/ipaddress`, updatedEntry, {
					headers: {
						'X-Resource-Id': `${ipFilteringResources.prefix}_${ipFilteringResources.ipFilterTable}_${ipFilteringResources.editButton}`,
					},
				})
				.then((response: AxiosResponse) => {
					const { data } = response;
					dispatch({
						type: actionTypes.UPDATE_FILTERING_LIST,
						data,
						isLoading: false,
					});

					dispatch({
						type: notificationTypes.NOTIFICATION,
						statusMessage: 'IP Address updated successfully.',
						statusType: StatusType.Success,
					});
					callback();
				})
				.catch((error) => {
					logger && logger.trackWarning('Error while editIpList', { 'Error': error?.response?.data});
					const statusMessage: string = error.statusText;
					if (typeof statusMessage === 'string')
						dispatch({
							type: notificationTypes.NOTIFICATION,
							statusMessage,
							statusType: StatusType.Error,
						});
				});
		},
	deleteIpFromList:
		(id: number, callback: () => void): AppThunkAction<KnownAction> =>
		(dispatch) => {
			dispatch({ type: actionTypes.REQUEST_FILTERING_LIST, isLoading: true });
			axios
				.delete(`${API_BASE_URL}/api/security-setting/ipaddress/${id}`, {
					headers: {
						'X-Resource-Id': `${ipFilteringResources.prefix}_${ipFilteringResources.ipFilterTable}_${ipFilteringResources.deleteButton}`,
					},
				})
				.then((response: AxiosResponse) => {
					const { data } = response;
					dispatch({
						type: actionTypes.DELETE_FILTERING_LIST,
						data,
						isLoading: false,
					});

					dispatch({
						type: notificationTypes.NOTIFICATION,
						statusMessage: 'IP Address deleted successfully.',
						statusType: StatusType.Success,
					});
					callback();
				})
				.catch((error) => {
					logger && logger.trackWarning('Error while deleteIpFromList', { 'Error': error?.response?.data});
					const statusMessage: string = error.statusText;
					if (typeof statusMessage === 'string')
						dispatch({
							type: notificationTypes.NOTIFICATION,
							statusMessage,
							statusType: StatusType.Error,
						});
				});
		},
};
