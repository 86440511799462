import { Reducer } from 'redux';
import axios, { AxiosResponse } from 'axios';

import { AppThunkAction } from 'src/store/index';
import { IMFAIPAddressModel, IMFASettingsState, IMFAUserViewModel, KnownAction, initialMfaSettings } from './MFASettings.model';
import { actionTypes } from './MFASettings.actions';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { API_BASE_URL, IPAddressConstant, UserSettingsConstants } from 'src/helper/Constants';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { logger } from 'src/oidcClient/authProvider';

const commonMfaHttpHeaders = {
    'X-Resource-Id': 'SUITE_Secr_MltFacAuth_IntrnlUsrs_TglEnblOrDisbl'
  };

export const actionCreators = {

    requestMFAIPAddress: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_MFAIPADDRESS_LIST, isLoading: true });
        axios.get(API_BASE_URL + '/api/mfa/ip-address-mfa-setting',
            {
                headers: commonMfaHttpHeaders,
            },   
        )
            .then((response: AxiosResponse<IMFAIPAddressModel[]>) => {
                const { data } = response;
                dispatch({ type: actionTypes.RECEIVE_MFAIPADDRESS_LIST, mfaIpAddress: data, isLoading: false });
            })
            .catch(function (error) {
                logger && logger.trackWarning('Error while requestMFAIPAddress', { 'Error': error?.response?.data});
                const statusMessage: any = error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
    },

    saveMFAIPAddress: (imfaIPAddressModel: IMFAIPAddressModel, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_MFAIPADDRESS_LIST, isLoading: true });
        axios.post(API_BASE_URL + '/api/mfa/ip-address-mfa-setting-add', imfaIPAddressModel,
        {
            headers: commonMfaHttpHeaders,
        },   
        )
            .then((response: AxiosResponse) => {
                const { data } = response;
                dispatch({ type: actionTypes.ADD_MFAIPADDRESS_STATE, data: { ...imfaIPAddressModel, id: data }, isLoading: false });
                dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: IPAddressConstant.SaveSuccessfully, statusType: StatusType.Success });
                callback();
            })
            .catch(function (error) {
                logger && logger.trackWarning('Error while saveMFAIPAddress', { 'Error': error?.response?.data});
                const statusMessage: any = error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                }
            });
    },

    updateMFAIPAddress: (imfaIPAddressModel: IMFAIPAddressModel, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_MFAIPADDRESS_LIST, isLoading: true });
        axios.put(API_BASE_URL + '/api/mfa/ip-address-mfa-setting-update', imfaIPAddressModel,
        {
            headers: commonMfaHttpHeaders,
        },   
        )
            .then((response: AxiosResponse) => {
                dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: IPAddressConstant.UpdateSuccessfully, statusType: StatusType.Success });
                dispatch({ type: actionTypes.UPDATE_MFAIPADDRESS_STATE, data: { ...imfaIPAddressModel }, isLoading: false });
                callback();
            })
            .catch(function (error) {
                logger && logger.trackWarning('Error while updateMFAIPAddress', { 'Error': error?.response?.data});
                const statusMessage: any = error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
    },

    deleteMFAIPAddress: (ipAddressId: number, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_MFAIPADDRESS_LIST, isLoading: true });
        axios.delete(API_BASE_URL + '/api/mfa/ip-address-mfa-setting-delete/?ipAddressId=' + ipAddressId,
        {
            headers: commonMfaHttpHeaders,
        },   
        )
            .then((response: AxiosResponse) => {
                dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: IPAddressConstant.DeleteSuccessfully, statusType: StatusType.Success });
                dispatch({ type: actionTypes.REQUEST_MFAIPADDRESS_LIST, isLoading: false })
                callback();
            })
            .catch(function (error) {   
                logger && logger.trackWarning('Error while deleteMFAIPAddress', { 'Error': error?.response?.data});
                const statusMessage: any = error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
    },

    requestMFAIPUser: (query: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_MFAIPADDRESS_LIST, isLoading: true });
        axios.get(API_BASE_URL + '/api/mfa/user-mfa-setting/' + query,
        {
            headers: commonMfaHttpHeaders,
        },       
        )
            .then((response: AxiosResponse<IMFAUserViewModel>) => {
                const { data } = response;
                dispatch({ type: actionTypes.RECEIVE_MFAUSER_LIST, mfaUsersTable: data, isLoading: false });
            })
            .catch(function (error) {
                logger && logger.trackWarning('Error while requestMFAIPUser', { 'Error': error?.response?.data});
                const statusMessage: any = error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
    },

    updateMFAEnableForAllUser: (mfaEnable: boolean, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_MFAUSER_LIST, isLoading: true });
        axios.put(API_BASE_URL + `/api/mfa/user-all-mfa-update/?mfaEnable=${mfaEnable}`)
            .then((response: AxiosResponse) => {
                callback();
                dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: UserSettingsConstants.saveUserSettingsSuccess, statusType: StatusType.Success });
                dispatch({ type: actionTypes.REQUEST_MFAUSER_LIST, isLoading: false });
            })
            .catch(function (error) {
                logger && logger.trackWarning('Error while updateMFAEnableForAllUser', { 'Error': error?.response?.data});
                const statusMessage: any = error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });

    },

    updateMFAEnableForUser: (userId: number, mfaEnable: boolean, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_MFAUSER_LIST, isLoading: true });
        axios.put(`${API_BASE_URL}/api/mfa/user-mfa-update/?userId=${userId}&mfaEnable=${mfaEnable}`)
            .then((response: AxiosResponse) => {
                callback();
                dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: UserSettingsConstants.saveUserSettingsSuccess, statusType: StatusType.Success });
                dispatch({ type: actionTypes.REQUEST_MFAUSER_LIST, isLoading: false });
            })
            .catch(function (error) {
                logger && logger.trackWarning('Error while updateMFAEnableForUser', { 'Error': error?.response?.data});
                const statusMessage: any = error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
    },
};

const unloadedState: IMFASettingsState = initialMfaSettings;

export const reducer: Reducer<IMFASettingsState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_MFAIPADDRESS_LIST:
            return {
                ...state,
                mfaIpAddress: [...state.mfaIpAddress],
                isLoading: action.isLoading
            } as IMFASettingsState;
        case actionTypes.RECEIVE_MFAIPADDRESS_LIST:
            return {
                ...state,
                mfaIpAddress: action.mfaIpAddress,
                isLoading: false
            } as IMFASettingsState;
        case actionTypes.ADD_MFAIPADDRESS_STATE:
            let received: IMFASettingsState = { ...state };
            received.mfaIpAddress.push(action.data);
            return {
                ...received,
                isLoading: action.isLoading
            } as IMFASettingsState;
        case actionTypes.UPDATE_MFAIPADDRESS_STATE:
            let updated: IMFASettingsState = { ...state };
            let index = updated.mfaIpAddress.findIndex(x => x.id === action.data.id);
            updated.mfaIpAddress[index] = action.data;
            return {
                ...updated,
                isLoading: action.isLoading
            } as IMFASettingsState;
        case actionTypes.REQUEST_MFAUSER_LIST:
            return {
                ...state,
                mfaUsersDetails: { ...state.mfaUsersDetails },
                isLoading: action.isLoading
            } as IMFASettingsState;
        case actionTypes.RECEIVE_MFAUSER_LIST:
            return {
                ...state,
                mfaUsersDetails: action.mfaUsersTable,
                isLoading: false
            } as IMFASettingsState;
    }
    return state;
};