import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { PasswordPolicyConstants } from 'src/helper/Constants';
import PasswordPolicySettings from './PasswordPolicySettings';
import styles from './PasswordPolicy.module.scss';
import {
	IDefinedPasswordPolicy,
	IPasswordPolicySettings,
	IPasswordSettings,
	PasswordOptions,
	PasswordPolicyType,
} from './PasswordPolicySettings.model';
import ButtonWithTextIcon from 'src/components/common/button-with-text-icon';
import { logger } from 'src/oidcClient/authProvider';

interface PasswordPolicyProps {
	passwordPolicySettings: IPasswordPolicySettings;
	definedPasswordPolicies: IDefinedPasswordPolicy[];
	requestPasswordPolicySettings: () => void;
	requestDefinedPasswordPolicies: () => void;
	updatePasswordPolicySettings: (
		passwordPolicySettings: IPasswordPolicySettings,
		callback: () => void,
		resourceId: string,
	) => void;
}

const PasswordPolicy: FC<PasswordPolicyProps> = (props) => {
	const [passwordPolicySettings, setPasswordPolicySettings] = useState<IPasswordPolicySettings>({
		passwordPolicy: PasswordPolicyType.NoPolicy,
		modifiedDate: new Date(),
		passwordSettings: {
			passwordOptions: PasswordOptions.None,
			length: 8,
			passwordAge: 0,
		},
	});
	const [definedPasswordPolicies, setDefinedPasswordPolicies] = useState<IDefinedPasswordPolicy[]>([]);
	const [isPolicyChanged, setIsPolicyChanged] = useState(false);
	const [passwordPolicyValues, setPasswordPolicyValues] = useState('');
	const dispatch = useDispatch();

	useEffect(() => {
		props.requestDefinedPasswordPolicies();
	}, []);

	useEffect(() => {
		setPasswordPolicySettings(JSON.parse(JSON.stringify(props.passwordPolicySettings)));
		setDefinedPasswordPolicies(props.definedPasswordPolicies);
		prepareInitialState(passwordPolicySettings);
		setIsPolicyChanged(false);
	}, [props]);

	const handleApplyButton = (resourceId: string) => {
		if (isPolicyChanged && validatePasswordPolicySettingsOnSave()) {
			logger && logger.trackTrace('handleApplyButton: Updating Password policy settings');
			let temp = structuredClone(passwordPolicySettings) as IPasswordPolicySettings;
			temp.modifiedDate = new Date();
			dispatch({
				type: loaderTypes.LOADING,
				show: true,
				text: 'Updating Account Passwords Settings...',
			});
			props.updatePasswordPolicySettings(
				temp,
				() => {
					dispatch({
						type: loaderTypes.LOADING,
						show: false,
						text: '',
					});
					props.requestPasswordPolicySettings();
				},
				resourceId,
			);
		}
	};

	const getFDPValues = (passwordPolicySettings: IPasswordPolicySettings) => {
		let fdpValues = 'fdp';
		if (passwordPolicySettings) {
			fdpValues += passwordPolicySettings.passwordSettings.passwordOptions;
			fdpValues += passwordPolicySettings.passwordSettings.length;
			fdpValues += passwordPolicySettings.passwordSettings.passwordAge;
		}
		return fdpValues;
	};

	const prepareInitialState = (passwordPolicySettings: IPasswordPolicySettings) => {
		let value = '';
		const element = document.getElementById('SSR Default');
		if (element) {
			if (element.checked) {
				value += 'ssr';
			} else {
				value = getFDPValues(passwordPolicySettings);
			}
			setPasswordPolicyValues(value);
		}
	};

	const observeChanges = (value: string, passwordPolicySettings: IPasswordPolicySettings) => {
		let formValues = '';
		if (value === 'SSRDefault') {
			formValues += 'ssr';
		} else {
			formValues += getFDPValues(passwordPolicySettings);
		}
		if (passwordPolicyValues !== formValues) {
			setIsPolicyChanged(true);
		} else {
			setIsPolicyChanged(false);
		}
	};

	const policyChangeHandler = (event: any) => {
		const definedPolicy = getDefinedPasswordPolicy(event.target.value);
		let tempPasswordPolicySettings;
		if (definedPolicy && definedPolicy.isEnabled) {
			tempPasswordPolicySettings = structuredClone(passwordPolicySettings);
			tempPasswordPolicySettings.passwordPolicy = event.target.value;
			if (props.passwordPolicySettings.passwordPolicy === event.target.value) {
				tempPasswordPolicySettings.passwordSettings = JSON.parse(
					JSON.stringify(props.passwordPolicySettings.passwordSettings),
				);
			} else {
				tempPasswordPolicySettings.passwordSettings = JSON.parse(JSON.stringify(definedPolicy.defaultSettings));
			}
			setPasswordPolicySettings(tempPasswordPolicySettings);
		}
		observeChanges(event.target.value, tempPasswordPolicySettings);
	};

	const handlePasswordOptionChange = (event: any, changedOption: PasswordOptions) => {
		const isPasswordOptionSelectedValue = isPasswordOptionSelected(changedOption);
		let tempSettings = structuredClone(passwordPolicySettings);
		if (event.target.checked && !isPasswordOptionSelectedValue) {
			tempSettings.passwordSettings.passwordOptions |= changedOption;
		} else if (!event.target.checked && isPasswordOptionSelectedValue) {
			tempSettings.passwordSettings.passwordOptions &= ~changedOption;
		}
		setPasswordPolicySettings(tempSettings);
		observeChanges('FirmDefinedPolicy', tempSettings);
	};

	const handlePasswordLengthChange = (event: any) => {
		let tempSettings = structuredClone(passwordPolicySettings);
		tempSettings.passwordSettings.length = parseInt(event.target.value);
		setPasswordPolicySettings(tempSettings);
		observeChanges('FirmDefinedPolicy', tempSettings);
	};

	const handlePasswordMaximumAgeChange = (event: any) => {
		let tempSettings = structuredClone(passwordPolicySettings);
		tempSettings.passwordSettings.passwordAge = parseInt(event.target.value);
		setPasswordPolicySettings(tempSettings);
		observeChanges('FirmDefinedPolicy', tempSettings);
	};

	const isPasswordOptionSelected = (option: PasswordOptions) => {
		return (passwordPolicySettings.passwordSettings.passwordOptions & option) === option;
	};

	const checkPasswordSettings = (pwdSettings1: IPasswordSettings, pwdSettings2: IPasswordSettings) => {
		if (
			pwdSettings1.length === pwdSettings2.length &&
			pwdSettings1.passwordAge === pwdSettings2.passwordAge &&
			pwdSettings1.passwordOptions === pwdSettings2.passwordOptions
		) {
			return true;
		} else {
			return false;
		}
	};

	const validatePasswordPolicySettingsOnSave = () => {
		if (passwordPolicySettings.passwordSettings.length < 8) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: PasswordPolicyConstants.Validate.MinimumCharacter,
			});
			return false;
		}
		if (passwordPolicySettings.passwordPolicy != PasswordPolicyType.NoPolicy) {
			const passwordOptions = passwordPolicySettings.passwordSettings.passwordOptions;

			const selectedOptions = Object.keys(PasswordOptions)
				.filter((key) => !isNaN(Number(key)))
				.map((key) => Number(key))
				.filter((option) => option > 0 && (option & passwordOptions) === option);

			if (!selectedOptions || selectedOptions.length < 2) {
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusType: StatusType.Warning,
					statusMessage: PasswordPolicyConstants.Validate.MinimumOption,
				});
				props.requestPasswordPolicySettings();
				return false;
			}
		}
		const existingPolicy = props.passwordPolicySettings;
		if (
			existingPolicy.passwordPolicy === passwordPolicySettings.passwordPolicy &&
			checkPasswordSettings(existingPolicy.passwordSettings, passwordPolicySettings.passwordSettings)
		) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: PasswordPolicyConstants.Validate.NoChanges,
			});
			return false;
		}
		return true;
	};

	const getDefinedPasswordPolicy = (policyType: string): IDefinedPasswordPolicy | undefined => {
		if (definedPasswordPolicies) {
			const definedPolicies = definedPasswordPolicies.filter((policy: any) => policy.passwordPolicy === policyType);
			if (definedPolicies.length > 0) {
				return definedPolicies[0];
			}
		}
		return undefined;
	};

	const isPasswordPolicyEnabled = (policyType: string): boolean => {
		const definedPolicy = getDefinedPasswordPolicy(policyType);
		if (definedPolicy && definedPolicy.isEnabled) {
			return true;
		}
		return false;
	};

	const getPolicyLabel = (policy: any): string => {
		switch (policy) {
			case PasswordPolicyType.NoPolicy:
				return 'No Policy';
			case PasswordPolicyType.SSRDefault:
				return 'SSR Default';
			case PasswordPolicyType.FirmDefinedPolicy:
				return 'Firm Defined Policy';
			default:
				return policy;
		}
	};

	const getPasswordPolicySection = () => {
		const items: any[] = [];
		const passwordPolicies = Object.keys(PasswordPolicyType).map((key) => key);
		passwordPolicies.forEach((policyType, index) => {
			if (isPasswordPolicyEnabled(policyType) && policyType !== PasswordPolicyType.NoPolicy) {
				const label = getPolicyLabel(policyType);
				items.push(
					<div key={label}>
						<input
							className='magic-radio'
							type='radio'
							id={label}
							checked={passwordPolicySettings.passwordPolicy === policyType}
							onChange={policyChangeHandler}
							value={policyType}
							data-test-auto={policyType + '15ade9d8-c8eb-44f5-8b8c-a02c7075e4d6'}
						/>
						<label
							htmlFor={label}
							className={passwordPolicySettings.passwordPolicy === policyType ? 'checked' : ''}>
							{label}
						</label>
					</div>,
				);
			}
		});
		return <div className={styles.policyRadios}>{items}</div>;
	};

	return (
		<article className='suitePageSection'>
			<h3 className='suitePageSectionHeader'>Account Passwords</h3>
			{getPasswordPolicySection()}
			{passwordPolicySettings.passwordPolicy != PasswordPolicyType.NoPolicy &&
			isPasswordPolicyEnabled(passwordPolicySettings.passwordPolicy) ? (
				<PasswordPolicySettings
					passwordSettings={passwordPolicySettings.passwordSettings}
					disable={
						passwordPolicySettings.passwordPolicy === PasswordPolicyType.FirmDefinedPolicy
							? 'newMainContent'
							: 'ssrDefault'
					}
					handlePasswordOptionChange={handlePasswordOptionChange}
					handlePasswordLengthChange={handlePasswordLengthChange}
					handlePasswordMaximumAgeChange={handlePasswordMaximumAgeChange}
				/>
			) : (
				''
			)}
			<div className={styles.applyPPChanges}>
				<ButtonWithTextIcon
					label='Apply Changes'
					className={isPolicyChanged ? '' : styles.noPolicyChanges}
					disabled={!isPolicyChanged}
					disabledText='Please make changes to enable button'
					onClick={() => handleApplyButton('SUITE_Secr_PswrdPolicy_BtnApply')}
					data-test-auto='b37db75b-fdfb-4e12-b689-18b96baae8db'
					data-resource-id='SUITE_Secr_PswrdPolicy_BtnApply'
				/>
			</div>
		</article>
	);
};

export default PasswordPolicy;
