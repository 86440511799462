import { actionTypes } from './roles-and-permissions.actions';
import { actionTypes as notificationActionTypes } from '../../../pages/common/notification/notification.types';
import { API_BASE_URL } from '../../../helper/Constants';
import { StatusType } from '../../../pages/common/notification/notification.reducer';
import { AppThunkAction } from '../../../store';
import axios, { AxiosResponse } from 'axios';
import { IUserGroups } from '../userGroups/user-groups.types';
import { IPermissions, IProducts, IRoles, KnownAction } from './roles-and-permissions.types';
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {
	requestAllRoles:
		(productId: number, callback?: (data: IRoles[]) => void): AppThunkAction<KnownAction> =>
		(dispatch) => {
			dispatch({ type: actionTypes.REQUEST_ALL_ROLES });
			axios
				.get(`${API_BASE_URL}/api/role/all-with-products/${productId}`)
				.then((response: AxiosResponse<IUserGroups[]>) => {
					const { data } = response;
					dispatch({
						type: actionTypes.REQUEST_ALL_ROLES_SUCCESS,
						rolesData: data,
					});
					if (callback) {
						callback(data);
					}
				})
				.catch(function (error) {
                    logger && logger.trackWarning('Error while requestAllRoles', { 'Error': error?.response?.data});
					const statusMessage: any = error.statusText?.message ?? error.statusText;
					if (typeof statusMessage === 'string') {
						dispatch({
							type: notificationActionTypes.NOTIFICATION,
							statusMessage,
							statusType: StatusType.Error,
						});
					}
				});
		},
	requestAllProducts:
		(callback?: (data: IProducts[]) => void): AppThunkAction<KnownAction> =>
		(dispatch) => {
			dispatch({ type: actionTypes.REQUEST_ALL_PRODUCTS });
			axios
				.get(API_BASE_URL + '/api/company/all-company-product')
				.then((response: AxiosResponse<IUserGroups[]>) => {
					const { data } = response;
					dispatch({
						type: actionTypes.REQUEST_ALL_PRODUCTS_SUCCESS,
						productData: data,
					});
					if (callback) {
						callback(data);
					}
				})
				.catch(function (error) {
                    logger && logger.trackWarning('Error while requestAllProducts', { 'Error': error?.response?.data});
					const statusMessage: any = error.statusText?.message ?? error.statusText;
					if (typeof statusMessage === 'string') {
						dispatch({
							type: notificationActionTypes.NOTIFICATION,
							statusMessage,
							statusType: StatusType.Error,
						});
					}
				});
		},
	requestPermissions:
		(roleId: number, productId: number, successCallback: IPermissions): AppThunkAction<KnownAction> =>
		(dispatch) => {
			if (roleId && productId) {
				axios
					.get(`${API_BASE_URL}/api/role/permission-screen-for-role/${roleId}/${productId}`)
					.then((response: AxiosResponse<IUserGroups[]>) => {
						const { data } = response;
						if (data.length) {
							successCallback(data);
						}
					})
					.catch(function (error) {
						logger && logger.trackWarning('Error while requestPermissions', { 'Error': error?.response?.data});
						const statusMessage: any = error.statusText?.message ?? error.statusText;
						if (typeof statusMessage === 'string') {
							dispatch({
								type: notificationActionTypes.NOTIFICATION,
								statusMessage,
								statusType: StatusType.Error,
							});
						}
					});
			}
		},
};
