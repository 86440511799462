import { actionTypes } from "./Users.actions";
import { actionTypes as notificationActionTypes } from "../../../pages/common/notification/notification.types";
import { API_BASE_URL, monthAbbreviations } from "../../../helper/Constants";
import { StatusType } from "../../../pages/common/notification/notification.reducer";
import { AppThunkAction } from "../../../store";
import { DispatchAction, ISaveUserModel, IUserData, IUserListInfo, IUserModel, KnownAction } from "./Users.model";
import { Reducer } from "redux";
import { download } from "src/helper/FileUtilities";
import axios, { AxiosResponse } from "axios";
import { userTabConstants } from '../../../helper/rbac-constants';
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {
    requestAllUsers: (reload = false, pageIndex = 1, pageSize = 10, searchText = "", newUserIds = "", officeLocations = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        if (reload || state.usersList.users.length === 0) {
            dispatch({ type: actionTypes.REQUEST_USER_LIST });
            const officeLocationsValue = officeLocations.split(',').includes('0') ? '' : officeLocations;
            const queryString = `?pageNumber=${pageIndex}&pageSize=${pageSize}&searchText=${searchText}&newUserIds=${newUserIds}&filterLocation=${officeLocationsValue}`;
            axios.get(API_BASE_URL + "/api/user-management/everyone-for-ssr" + queryString)
                .then((response: AxiosResponse<IUserListInfo>) => {
                    const { data } = response;
                    dispatch({
                        type: actionTypes.RECEIVE_USER_LIST, usersListInfo: data
                    });
                }).catch(function (error) {
                    logger && logger.trackWarning('Error while requestAllUsers', { 'Error': error?.response?.data});
                    dispatch({
                        type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
                        statusType: StatusType.Error
                    });
                });
        }
    },

    requestUserById: (id: number, callback: (user: ISaveUserModel) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.get(API_BASE_URL + "/api/user-management/user-details-with-group?userId=" + id)
            .then((response: AxiosResponse) => {
                const { data } = response;
                callback(data);
            }).catch(function (error) {
                logger && logger.trackWarning('Error while requestUserById', { 'Error': error?.response?.data});
                dispatch({
                    type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
                    statusType: StatusType.Error
                });
            });
    },

    exportUserDetailsAsExcel: (queryString: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.get(API_BASE_URL + "/api/user-management/everyone-for-exportexcel" + queryString, {
            responseType: 'blob',
            headers: {
                'X-Resource-Id': `${userTabConstants.prefix}_${userTabConstants.exportList}`
            }
        })
            .then((response: AxiosResponse) => {
                const { data } = response;
                const url = window.URL.createObjectURL(new Blob([data]));
                const date = new Date();
                const month = monthAbbreviations[date.getMonth()];
                const day = date.toLocaleDateString("en-GB", { day: "2-digit" }).replace(/ /g, "");
                const year = date.getFullYear();
                const formattedDate = `${day}${month}${year}`;
                download(url, `UserList_${formattedDate}.xlsx`);
                if (callback) {
                    callback();
                }
            }).catch(function (error) {
                logger && logger.trackWarning('Error while exportUserDetailsAsExcel', { 'Error': error?.response?.data});
                console.log( error.response.status);
                if(error?.response?.status ==400){
                    dispatch({
                        type: notificationActionTypes.NOTIFICATION,
                        statusMessage: "Search text is not a vaild text",
                        statusType: StatusType.Error
                    });
                    if (callback) {
                        callback();
                    }
                }
            });
    },
};

const unloadedState: IUserData = {
    isLoading: false,
    users: [],
    count: 0,
} as IUserData;

export const reducer: Reducer<IUserData> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    let userList;
    let index;
    switch (action.type) {
        case actionTypes.REQUEST_USER_LIST:
            return {
                isLoading: true,
                users: [...state.users],
                count: state.count,
            } as IUserData;
        case actionTypes.RECEIVE_USER_LIST:
            return {
                isLoading: false,
                users: action.usersListInfo.items,
                count: action.usersListInfo.count,
            } as IUserData;
        case actionTypes.DELETE_USER:
            userList = Object.assign({}, state);
            index = userList.users.findIndex(user => user.id == action.user?.id);
            userList.users.splice(index, 1);
            return {
                users: userList.users,
                isLoading: false
            } as IUserData;
        case actionTypes.SAVE_USER:
            userList = Object.assign({}, state);
            userList.users.push(action.user);
            userList.users = userList.users.sort((userOne: IUserModel, userTwo: IUserModel) => {
                if (userOne.firstName.toLocaleLowerCase() > userTwo.firstName.toLocaleLowerCase()) {
                    return 1;
                }
                if (userOne.firstName.toLocaleLowerCase() < userTwo.firstName.toLocaleLowerCase()) {
                    return -1;
                }
                return 0;
            });
            return {
                users: userList.users,
                isLoading: false
            } as IUserData;
        case actionTypes.UPDATE_USER:
            userList = Object.assign({}, state);
            index = userList.users.findIndex(user => user.id == action.user?.id);
            userList.users.splice(index, 1, action.user);
            return {
                users: userList.users,
                isLoading: false,
            } as IUserData;
        case actionTypes.UPDATE_DEFAULT_CONTACT:
            userList = Object.assign({}, state);
            return {
                users: userList.users,
                isLoading: false,
            } as IUserData;
        case actionTypes.ERROR_USER_MANAGEMENT:
            const errorState: IUserData = Object.assign({}, state);
            errorState.isLoading = false;
            return errorState;
        default:
    }

    return state;
};