import { API_BASE_URL, SSOAccessRequestConstants } from '../../helper/Constants';
import { AppThunkAction } from '../../store/index';
import { actionTypes } from './UserPermission.action';
import { DispatchAction, ISSOAccessRequest, ISSOAccessRequestedUsers, KnownAction } from './UserPermission.model';
import { Reducer } from 'redux';
import axios, { AxiosResponse } from 'axios';
import { StatusType } from '../../pages/common/notification/notification.reducer';
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {
	getAllSSOAccessRequestedUsers:
		(
			reload = false,
			pageNo = 1,
			pageSize = 10,
			sortBy = '',
			sortOrder = '',
			filterText = '',
			callback?: () => void,
		): AppThunkAction<KnownAction> =>
		async (dispatch, getState) => {
			try {
				const state = getState();
				if (reload || state.ssoAccessRequestUsers.users.length === 0) {
					dispatch({ type: actionTypes.REQUEST_SSO_ACCESS_REQUESTS_LIST });
					const query = `?pageNumber=${pageNo}&pageSize=${pageSize}&sortColumn=${sortBy}&sortDirection=${sortOrder}&searchText=${encodeURIComponent(filterText)}`;
					const response: AxiosResponse<ISSOAccessRequest[]> = await axios.get(
						API_BASE_URL + '/api/user-permission/azuread-unauthorized-user' + query,
					);
					dispatch({ type: actionTypes.RECEIVE_SSO_ACCESS_REQUESTS_LIST, users: response.data });
					callback && callback();
				}
			} catch (error) {
				logger && logger.trackWarning('Error while getAllSSOAccessRequestedUsers', { 'Error': error?.response?.data});
				dispatch({
					type: actionTypes.NOTIFICATION_SSO_REQUEST,
					statusMessage: SSOAccessRequestConstants.StatusMessage.SSOAccessRequestError,
					statusType: StatusType.Error,
				});
			}
		},
};

const unloadedState: ISSOAccessRequestedUsers = {
	users: [],
	isLoading: false,
	count: 0,
} as ISSOAccessRequestedUsers;

export const reducer: Reducer<ISSOAccessRequestedUsers> = (state = unloadedState, incomingAction) => {
	const action = incomingAction as DispatchAction;

	switch (action.type) {
		case actionTypes.REQUEST_SSO_ACCESS_REQUESTS_LIST:
			return {
				...state,
				isLoading: true,
			} as ISSOAccessRequestedUsers;
		case actionTypes.RECEIVE_SSO_ACCESS_REQUESTS_LIST:
			return {
				users: action.users,
				isLoading: false,
				count: action.users.length ? action.users[0].totalCount : 0,
			} as ISSOAccessRequestedUsers;
		case actionTypes.NOTIFICATION_SSO_REQUEST:
			const errorState: ISSOAccessRequestedUsers = Object.assign({}, state);
			errorState.isLoading = false;
			return errorState;

		default:
			return state;
	}
};
