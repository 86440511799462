import React from 'react';
import { IInOfficeReturnModel, SignatureStatus } from '../InOfficeESigning.model';
import InOfficeGroupIcon from '../../../components/svg/InOfficeGroupIcon';

interface IInOfficeReturnTable {
	inOfficeReturnList: IInOfficeReturnModel[];
	isLoading: boolean;
	handleGetStartedButtonClick: (returnItem: IInOfficeReturnModel) => void;
	clearSelectedInOfficeSigningReturn: () => void;
}

const InOfficeReturnTable: React.FC<IInOfficeReturnTable> = (props) => {
	const { inOfficeReturnList, isLoading, handleGetStartedButtonClick } = props;

	return (
		<table className='table table-striped table-hover inOfficeESigningTable'>
			<thead className='table-header'>
				<tr>
					<th
						className='taxYear'
						scope='col'>
						Tax Year
					</th>
					<th
						className='clientId'
						scope='col'>
						Client ID
					</th>
					<th
						className='name'
						scope='col'>
						Name
					</th>
					<th
						className='returnStatus'
						scope='col'>
						Return Status
					</th>
					<th
						className='actions'
						scope='col'>
						Action
					</th>
				</tr>
			</thead>
			<tbody>
				{isLoading ? (
					<tr>
						<td
							data-toggle='collapse'
							colSpan={5}
							className='clientsLoadingArea'>
							<div className='text-center'>
								<div
									className='spinner-border'
									role='status'></div>
								<div>
									<span className='clientsLoadingText'>Loading...</span>
								</div>
							</div>
						</td>
					</tr>
				) : inOfficeReturnList.length === 0 ? (
					<tr>
						<td
							data-toggle='collapse'
							colSpan={5}
							className='clientsLoadingArea text-center'>
							No matching records found
						</td>
					</tr>
				) : (
					inOfficeReturnList.map((returnItem: IInOfficeReturnModel) => (
						<tr key={returnItem.documentId}>
							<td className='ellipsis'>{returnItem.taxYear}</td>
							<td
								title={returnItem.clientId}
								className='ellipsis'>
								{returnItem.clientId}
							</td>
							<td>
								<div className='groupNameColumn'>
									<div
										title={returnItem.name}
										className={`leftSideName ${returnItem.groupName ? 'width35' : ''}`}>
										{returnItem.name}
									</div>
									<div className='icons'>
										{returnItem.groupName && (
											<div
												className='groupIcon'
												title={returnItem.groupName}>
												<InOfficeGroupIcon />
											</div>
										)}
									</div>
								</div>
							</td>
							<td className='ellipsis'>
								{returnItem.signatureStatus === SignatureStatus.AwaitingESign ? (
									<span className='awaitingESign'>Awaiting e-Sign</span>
								) : returnItem.signatureStatus === SignatureStatus.PartiallySigned ? (
									<span className='partiallyESigned'>Partially e-Signed</span>
								) : (
									<>{SignatureStatus[returnItem.signatureStatus]}</>
								)}
							</td>
							<td>
								<button
									className='inOfficeESigningTable_getStarted'
									onClick={() => handleGetStartedButtonClick(returnItem)}>
									Get Started
								</button>
							</td>
						</tr>
					))
				)}
			</tbody>
		</table>
	);
};

export default InOfficeReturnTable;
