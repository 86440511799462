import React, { FC } from 'react';

const FilledFilterIcon: FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'>
			<path
				d='M1.5 1.5C1.5 1.36739 1.55268 1.24021 1.64645 1.14645C1.74021 1.05268 1.86739 1 2 1H14C14.1326 1 14.2598 1.05268 14.3536 1.14645C14.4473 1.24021 14.5 1.36739 14.5 1.5V3.5C14.5 3.62331 14.4544 3.74226 14.372 3.834L10 8.692V13.5C9.99992 13.6049 9.96685 13.7071 9.90547 13.7922C9.8441 13.8772 9.75752 13.9409 9.658 13.974L6.658 14.974C6.58287 14.999 6.50288 15.0058 6.4246 14.9939C6.34632 14.982 6.272 14.9516 6.20775 14.9053C6.1435 14.859 6.09116 14.7982 6.05504 14.7277C6.01893 14.6572 6.00006 14.5792 6 14.5V8.692L1.628 3.834C1.54561 3.74226 1.50002 3.62331 1.5 3.5V1.5Z'
				fill='#05386B'
			/>
		</svg>
	);
};

export default FilledFilterIcon;
