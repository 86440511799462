import { type IBrandingManagamentHookReturn, type IBrandingManagementProps } from './branding.types';

const useBrandingManagement = (props: IBrandingManagementProps): IBrandingManagamentHookReturn => {
	const {
		brandingData: {
			companyLogoData: { isSsrLogo = true, logoPath = '', retrivedLogoIsDefault = true } = {},
			isLogoLoading,
			isWhiteLogoLoading,
			isBrandingSettingsLoading,
			whiteLogoPath = '',
			coverPageSetting,
			whiteLogoUploadLink,
			logoUploadLink,
			primaryOfficeLocation = {},
		},
		userAuth: {
			user: {
				profile: { company_name = '' },
			},
		},
		companyData: { companyProfile: { companyInfo = {}, countryStates = [] } = {} },
		getCompanyLogo,
		getCompanyWhiteLogo,
		getCompanyBrandSettings,
		getPrimaryOfficeLocation,
		deleteCompanyLogo,
		deleteCompanyWhiteLogo,
		requestCompanyWhiteLogoUploadLink,
		updateTempCompanyWhiteLogo,
		requestCompanyUploadLink,
		updateTempCompanyLogo,
		updateReminders,
		updateCompanyBrandSettings,
	} = props;

	const breadCrumbConfig = [
		{
			activePage: false,
			link: '/',
			name: 'SafeSend Suite',
		},
		{
			activePage: true,
			link: '',
			name: 'Branding',
		},
	];

	const companyLogoProps = {
		isSsrLogo,
		retrivedLogoIsDefault,
		logoPath,
		isLogoLoading,
		requestCompanyUploadLink,
		getCompanyLogo,
		logoUploadLink,
		updateTempCompanyLogo,
		deleteCompanyLogo,
		updateReminders,
	};

	const companyWhiteLogoProps = {
		getCompanyWhiteLogo,
		isWhiteLogoLoading,
		whiteLogoPath,
		deleteCompanyWhiteLogo,
		requestCompanyWhiteLogoUploadLink,
		whiteLogoUploadLink,
		updateTempCompanyWhiteLogo,
	};

	const brandingSettingsProps = {
		isBrandingSettingsLoading,
		coverPageSetting,
		whiteLogoPath,
		company_name,
		logoPath,
		updateCompanyBrandSettings,
		companyInfo,
		countryStates,
		primaryOfficeLocation,
	};

	return {
		breadCrumbConfig,
		companyLogoProps,
		getCompanyLogo,
		getCompanyWhiteLogo,
		getCompanyBrandSettings,
		getPrimaryOfficeLocation,
		companyWhiteLogoProps,
		brandingSettingsProps,
		primaryOfficeLocation,
	};
};

export { useBrandingManagement };
