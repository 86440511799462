import React from 'react';

const CheckMark: React.FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='22'
			height='22'
			fill='none'
			viewBox='0 0 22 22'>
			<g clipPath='url(#clip0_5251_27709)'>
				<path
					fill='#fff'
					fillOpacity='0.01'
					d='M0 0H22V22H0z'></path>
				<path
					fill='#669440'
					d='M17.515 5.46a1.007 1.007 0 011.44 0c.393.397.399 1.04.014 1.443l-8.13 9.612a1.007 1.007 0 01-1.465.027L4.427 11.53a1.04 1.04 0 01.329-1.681 1.008 1.008 0 011.11.223l4.197 4.253 7.425-8.834a.341.341 0 01.027-.03z'></path>
			</g>
			<defs>
				<clipPath id='clip0_5251_27709'>
					<path
						fill='#fff'
						d='M0 0H22V22H0z'></path>
				</clipPath>
			</defs>
		</svg>
	);
};

export default CheckMark;
