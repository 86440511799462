import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import CustomDropdown from '../../../../components/common/customDropdown';
import CloseIcon from '../../../../components/svg/CloseIcon';
import Warning from '../../../../components/svg/Warning';
import { IDefaultContactPerson } from '../../../userManagement/users/Users.model';
import { IErrorModel } from '../save-office-location-modal/save-office-location-modal.types';
import './update-contact-person-modal.style.scss';
import { ICustomDropdownOption } from 'src/components/common/customDropdown/CustomDropdownWithCustomOptions';

interface IUpdateContactPersonModal {
	contactPersons: IDefaultContactPerson[];
	handleUpdateContactPerson: (callback: () => void) => void;
	selectedOption: ICustomDropdownOption | null;
	setSelectedOption: (option: ICustomDropdownOption | null) => void;
	handleClose: () => void;
}

const UpdateContactPersonModal: React.FC<IUpdateContactPersonModal> = (props) => {
	const { contactPersons, handleUpdateContactPerson, selectedOption, setSelectedOption, handleClose } = { ...props };
	const [availableUsers, setAvailableUsers] = useState<ICustomDropdownOption[]>([]);
	const [disableBtn, setDisableBtn] = useState<boolean>(false);
	const [mandatoryFieldError, setMandatoryFieldError] = useState<IErrorModel>();

	useEffect(() => {

		const userDropdown: ICustomDropdownOption[] = [];

		contactPersons.forEach((user: IDefaultContactPerson) => {
			userDropdown.push({
				label: `${user.firstName} ${user.lastName}`,
				value: user.userId,
			});
		});

		setAvailableUsers(userDropdown);
	}, []);

	const handleOnBlurOfficeLocation = (selectedOption: ICustomDropdownOption | null) => {
		if (!selectedOption) {
			setMandatoryFieldError({ error: true, message: 'This is required field' });
		} else {
			setMandatoryFieldError({ error: false, message: '' });
		}
	};

	const onUserChange = (option: ICustomDropdownOption | null) => {
		setSelectedOption(option);
	};

	const onSave = () => {
		if (!selectedOption) {
			setMandatoryFieldError({ error: true, message: 'This is required field' });
		} else {
			setDisableBtn(true);
			handleUpdateContactPerson(onError);
		}
	};

	const onError = () => {
		setDisableBtn(false);
	};

	return (
		<Modal
			show={true}
			onHide={handleClose}
			className='updateContactPersonModal'
			style={{ fontSize: '14px' }}>
			<Modal.Header className='header'>
				<div>
					<Modal.Title style={{ fontSize: '20px' }}>Update Contact</Modal.Title>
				</div>
				<div
					data-test-auto='67D1A1E9-93E7-4F07-B6A6-4AFAF4527414'
					className='closeIcon'
					onClick={handleClose}>
					<CloseIcon />
				</div>
			</Modal.Header>
			<Modal.Body>
				<Container>
					<Row>
						<div className='warningMessage'>
							<div className='icon'>
								<Warning />
							</div>
							<div className='text'>
								You are updating the location for <strong>user(s)</strong> who is currently designated as the contact
								person for delivered Returns Documents. Please select the new Contact Person to replace the{' '}
								<strong>user(s)</strong> who has been relocated from this location.
							</div>
						</div>
					</Row>

					<Row
						style={{ height: '140px', strokeWidth: '0.5px' }}
						className='flex-justify-center'>
						<Col
							xs={5}
							md={5}>
							<div className='column-1'>
								<div className='title'>Change Contact Person</div>
							</div>
						</Col>
						<Col
							xs={7}
							md={7}
							className='flex-justify-center'>
							<div className='contactPersonDropdown'>
								<CustomDropdown
									id={'contactPerson'}
									className='rowContentDropdown'
									selectedOption={selectedOption}
									setSelectedOption={onUserChange}
									options={availableUsers}
									customPlaceHolder='Select Contact Person'
									customPlaceholderStyles={{
										color: '#A6A9AC',
									}}
									height={31}
									borderColor={mandatoryFieldError?.error ? '#CC4A43' : '#898D91'}
									handleOnBlur={() => handleOnBlurOfficeLocation(selectedOption)}
									scrollableMenu={true}
								/>
								<div
									id='userOfficeLocationError'
									className={`${mandatoryFieldError?.error ? 'saveUserFieldErrorMessage' : ''}`}>
									{mandatoryFieldError?.message}
								</div>
							</div>
						</Col>
					</Row>

				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button
					disabled={disableBtn}
					onClick={handleClose}
					data-test-auto='D4A4AEE4-DE4C-4D09-B1A4-B883EC676E78'
					variant='secondary'>
					Cancel
				</Button>
				<Button
					disabled={disableBtn}
					onClick={onSave}
					data-test-auto='B01336C5-4A9D-408A-8F20-B826A1DA1C60'
					variant='primary'>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default UpdateContactPersonModal;