import './SaveUserGroupModal.style.scss';

import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import CloseIcon from 'src/components/svg/CloseIcon';
import SearchIcon from 'src/components/svg/SearchIcon';
import { dropdownList } from 'src/store/common/dropdown/dropdown-list.types';

import { IUserGroupsAddUpdateModel } from '../user-groups.types';

interface SaveUserGroupModalProps {
	availableRoleList: dropdownList[];
	userGroup: IUserGroupsAddUpdateModel;
	onAdd: (value: IUserGroupsAddUpdateModel, errorCallback: (message: string) => void) => void;
	onUpdate: (value: IUserGroupsAddUpdateModel, errorCallback: (message: string) => void) => void;
	handleClose: () => void;
}
const SaveUserGroupModal: FC<SaveUserGroupModalProps> = (props) => {
	const { availableRoleList, userGroup, onAdd, onUpdate } = props;

	const [group, setGroup] = useState(userGroup);
	const [groupId, setGroupId] = useState(userGroup.groupId);
	const [groupName, setGroupName] = useState(group.groupName);
	const [groupDescription, setGroupDescription] = useState(group.groupDescription);
	const [searchRoleText, setSearchRoleText] = useState('');
	const ugCheckArea = useRef(null);
	const groupNameRef = useRef<HTMLInputElement>(null);
	const [mandatoryFieldError, setMandatoryFieldError] = useState({
		groupName: { error: false, message: '' },
		groupDescription: { error: false, message: '' },
		role: { error: false, message: '' },
	});

	const [selectedRoleList, setSelectedRoleList] = useState<dropdownList[]>([]);
	const [filteredRoleList, setFilteredRoleList] = useState(availableRoleList);

	const handleChangeGroupName = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setGroupName(event.target.value);
	};

	const handleOnBlurGroupName = (id: string, value: string): void => {
		if (value.trim().length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'This field is required';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setGroup({ ...group, groupName: value });
		}
	};

	const handleChangeGroupDescription = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setGroupDescription(event.target.value);
	};

	const handleOnBlurGroupDescription = (id: string, value: string): void => {
		if (value.trim().length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'This field is required';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setGroup({ ...group, groupDescription: value });
		}
	};

	const handleRoleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setSearchRoleText(event.target.value);
		const newFilteredRoleList = availableRoleList.filter((role) => {
			return role.name.toLowerCase().indexOf(event.target.value.toLowerCase()) >= 0;
		});
		setFilteredRoleList(newFilteredRoleList);
	};

	const checkRoleSelection = (): void => {
		if (selectedRoleList.length === 0)
			setMandatoryFieldError({
				...mandatoryFieldError,
				role: { error: true, message: 'Cannot save a User Group without any Roles' },
			});
		else setMandatoryFieldError({ ...mandatoryFieldError, role: { error: false, message: '' } });
	};

	const handleRoleCheck = (event: React.ChangeEvent<HTMLInputElement>, role: dropdownList): void => {
		if (event.target.checked) {
			const newSelectedRoleList = [...selectedRoleList, role];
			const newSelectedRoleIds = newSelectedRoleList.map((s) => s.value);

			setGroup({ ...group, roles: newSelectedRoleIds });
			setSelectedRoleList(newSelectedRoleList);
			setMandatoryFieldError({ ...mandatoryFieldError, role: { error: false, message: '' } });
		} else {
			const newSelectedRoleList = selectedRoleList.filter((eachRole: dropdownList) => {
				return eachRole.value !== role.value;
			});
			const newSelectedRoleIds = newSelectedRoleList.map((s) => s.value);
			setGroup({ ...group, roles: newSelectedRoleIds });
			setSelectedRoleList(newSelectedRoleList);
			if (newSelectedRoleList.length === 0)
				setMandatoryFieldError({
					...mandatoryFieldError,
					role: { error: true, message: 'Cannot save a User Group without any Roles' },
				});
		}
	};

	const deselectRole = (event: React.MouseEvent<HTMLSpanElement>, roleId: number): void => {
		const newSelectedRoleList = selectedRoleList.filter((eachRole: dropdownList) => {
			return eachRole.value !== roleId;
		});

		const newSelectedRoleIds = newSelectedRoleList.map((s) => s.value);

		setGroup({ ...group, roles: newSelectedRoleIds });
		setSelectedRoleList(newSelectedRoleList);
		if (newSelectedRoleList.length === 0)
			setMandatoryFieldError({
				...mandatoryFieldError,
				role: { error: true, message: 'Cannot save a User Group without any Roles' },
			});
	};

	const checkAllMandatoryFields = (): boolean => {
		handleOnBlurGroupName('groupName', groupName);
		handleOnBlurGroupDescription('groupDescription', groupDescription);
		checkRoleSelection();

		for (const field in mandatoryFieldError)
			if (mandatoryFieldError[field as keyof typeof mandatoryFieldError].error) return false;

		return true;
	};

	const handleSaveUserGroup = (): void => {
		if (checkAllMandatoryFields() && selectedRoleList.length !== 0)
			if (groupId === 0) onAdd(group, showErrorMessage);
			else onUpdate(group, showErrorMessage);
	};

	const showErrorMessage = (message: string): void => {
		const newMandatoryFieldError = { ...mandatoryFieldError };
		newMandatoryFieldError.groupName.error = true;
		newMandatoryFieldError.groupName.message = message;
		setMandatoryFieldError(newMandatoryFieldError);
		if (groupNameRef.current !== null) groupNameRef.current.focus();
	};

	useEffect(() => {
		if (group.roles.length > 0 && selectedRoleList.length == 0) {
			const roleList = [...selectedRoleList];
			group.roles.map((roleId: any, i: number) => {
				const index = availableRoleList.findIndex((x) => x.value == roleId);
				roleList.push({
					name: index != -1 ? availableRoleList[index].name : '',
					value: roleId,
				});
			});

			setSelectedRoleList(roleList);
		}
	}, []);

	useEffect(() => {
		setMandatoryFieldError({
			groupName: { error: false, message: '' },
			groupDescription: { error: false, message: '' },
			role: { error: false, message: '' },
		});
	}, []);

	useEffect(() => {
		if (ugCheckArea.current) {
			const current = ugCheckArea.current as HTMLElement;
			Array.from(current.children).map((element) => {
				const elementId = element.id.slice(0, element.id.indexOf('_li'));
				selectedRoleList.map((role) => {
					const input = element.children[`${elementId}_input` as any] as HTMLInputElement;
					if (role.value === Number(elementId) && !input.checked) input.checked = true;
				});
			});
		}
	}, [filteredRoleList]);

	return (
		<Modal
			show={true}
			onHide={props.handleClose}
			className='modalClass saveUserGroupModal'>
			<Modal.Header className='saveUserModalHeader'>
				<div>
					<Modal.Title>{groupId === 0 ? 'Add User Group' : 'Edit User Group'}</Modal.Title>
				</div>
				<div
					data-test-auto='saveUserGroupCloseIcon'
					className='closeIcon'
					onClick={props.handleClose}>
					<CloseIcon />
				</div>
			</Modal.Header>
			<Modal.Body className='show-grid'>
				<Container>
					<Form>
						<h1 className='personalInformationText'>Group Information</h1>
						<Row>
							<Col
								xs={18}
								md={12}>
								<Form.Group
									className='mb-3'
									controlId='groupName'>
									<Form.Label>Name</Form.Label>
									<Form.Control
										type='text'
										value={groupName}
										onChange={handleChangeGroupName}
										onBlur={(e) => {
											handleOnBlurGroupName(e.target.id, e.target.value);
										}}
										className={`${mandatoryFieldError.groupName.error ? 'saveUserFieldErrorBorder' : ''}`}
										maxLength={100}
										ref={groupNameRef}
									/>
									<div
										id='groupNameError'
										className={`fieldErrorSVG ${
											mandatoryFieldError.groupName.error ? 'saveUserFieldErrorMessage' : ''
										}`}>
										{mandatoryFieldError.groupName.message}
									</div>
								</Form.Group>
							</Col>
							<Col
								xs={18}
								md={12}>
								<Form.Group
									className='mb-3'
									controlId='groupDescription'>
									<Form.Label>Description</Form.Label>
									<Form.Control
										as='textarea'
										rows={3}
										value={groupDescription}
										onChange={handleChangeGroupDescription}
										onBlur={(e) => {
											handleOnBlurGroupDescription(e.target.id, e.target.value);
										}}
										className={`${mandatoryFieldError.groupDescription.error ? 'saveUserFieldErrorBorder' : ''}`}
										maxLength={250}
									/>
									<div
										id='groupDescriptionError'
										className={`fieldErrorSVG ${
											mandatoryFieldError.groupDescription.error ? 'saveUserFieldErrorMessage' : ''
										}`}>
										{mandatoryFieldError.groupDescription.message}
									</div>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col
								xs={18}
								md={12}>
								<Form.Group
									className='mb-3'
									controlId='userGroups'>
									<Form.Label>Roles</Form.Label>
									<div className={`ugTags${mandatoryFieldError.role.error ? ' saveUserFieldErrorBorder' : ''}`}>
										{selectedRoleList.map((role: dropdownList) => {
											return (
												<span
													key={`${role.value}`}
													className='eachUGTag defaultGroup'>
													<span className='tagName'>{role.name}</span>
													<span
														data-test-auto={`${role.value}`}
														id={`${role.value}`}
														className='tagDeselect'
														onClick={(e) => {
															deselectRole(e, role.value as any);
														}}>
														<CloseIcon />
													</span>
												</span>
											);
										})}
									</div>
									<div
										id='roleError'
										className={`${mandatoryFieldError.role.error ? 'saveUserFieldErrorMessage' : ''}`}>
										{mandatoryFieldError.role.message}
									</div>
								</Form.Group>
							</Col>
						</Row>

						<div className='searchRoleContainer'>
							<div className='searchUserRoleDiv'>
								<Form.Control
									type='text'
									className='searchUserRole'
									value={searchRoleText}
									onChange={handleRoleSearch}
								/>
								<span>
									<SearchIcon />
								</span>
							</div>
							<ul
								className='ugCheckArea'
								ref={ugCheckArea}>
								{filteredRoleList.length === 0 ? (
									<li className='eachUGCheck'>No results found</li>
								) : (
									filteredRoleList.map((role) => {
										return (
											<li
												className='eachUGCheck'
												data-name={`${role.value}_li`}
												id={`${role.value}_li`}
												key={`${role.value}`}>
												<input
													type='checkbox'
													className='ugCheckbox'
													id={`${role.value}_id`}
													name={`${role.value}_input`}
													onChange={(e) => {
														handleRoleCheck(e, role as any);
													}}
													value={role.name}
													checked={selectedRoleList.some((selectedRole) => {
														return selectedRole.value == role.value;
													})}
												/>
												<label
													htmlFor={`${role.value}_id`}
													data-name={`${role.value}_label`}
													id={`${role.value}_label`}>
													{role.name}
												</label>
											</li>
										);
									})
								)}
							</ul>
						</div>
					</Form>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button
					data-test-auto='cancelSaveUserGroupClick'
					variant='secondary'
					onClick={props.handleClose}>
					Cancel
				</Button>
				<Button
					data-test-auto='saveUserGroupClick'
					variant='primary'
					onClick={handleSaveUserGroup}>
					{' '}
					{groupId === 0 ? 'Add Group' : 'Update'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default SaveUserGroupModal;
