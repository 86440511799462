import React, { FC } from "react";

const ExclamationError: FC = () => {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1581_116343)">
                <rect width="18" height="18" transform="translate(0 0.5)" fill="white" fillOpacity="0.01" />
                <path fillRule="evenodd" clipRule="evenodd" d="M8.99951 3.24921C5.54773 3.24921 2.74951 6.04743 2.74951 9.49921C2.74951 12.951 5.54773 15.7492 8.99951 15.7492C12.4513 15.7492 15.2495 12.951 15.2495 9.49921C15.2495 6.04743 12.4513 3.24921 8.99951 3.24921ZM1.74951 9.49921C1.74951 5.49514 4.99545 2.24921 8.99951 2.24921C13.0036 2.24921 16.2495 5.49514 16.2495 9.49921C16.2495 13.5033 13.0036 16.7492 8.99951 16.7492C4.99545 16.7492 1.74951 13.5033 1.74951 9.49921ZM8.334 5.55803C8.42856 5.45672 8.56093 5.39921 8.69951 5.39921H9.29951C9.4381 5.39921 9.57046 5.45672 9.66503 5.55803C9.75959 5.65934 9.80786 5.79535 9.79833 5.93361L9.49833 10.2836C9.48025 10.5458 9.2623 10.7492 8.99951 10.7492C8.73672 10.7492 8.51878 10.5458 8.5007 10.2836L8.2007 5.93361C8.19116 5.79535 8.23944 5.65934 8.334 5.55803ZM9.89951 12.7992C9.89951 13.2963 9.49657 13.6992 8.99951 13.6992C8.50246 13.6992 8.09951 13.2963 8.09951 12.7992C8.09951 12.3021 8.50246 11.8992 8.99951 11.8992C9.49657 11.8992 9.89951 12.3021 9.89951 12.7992Z" fill="#CC4A43" />
            </g>
            <defs>
                <clipPath id="clip0_1581_116343">
                    <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ExclamationError;