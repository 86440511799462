import React, { FC, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import Banner from "../../components/welcome/Banner";
import ProductList from "../../components/welcome/ProductList";
import TopSection from "../../components/welcome/TopSection";
import { getProductUrls, getAddOnFeatureUrls } from "./welcome.apis";
import { ApplicationState } from "../../store";
import { resetUserCache } from "../common/common.apis";
import { logger } from '../../oidcClient/authProvider';

const Welcome: FC = () => {
    const { isLoading } = useSelector((state: ApplicationState) => state.welcomeState);
    const { isAddonFeatureLoading } = useSelector((state: ApplicationState) => state.welcomeState);
    const dispatch = useDispatch();
    const { isResetCacheApiCalled } = useSelector(
		(state: ApplicationState) => state.commonState
	);

	useEffect(() => {
		logger && logger.trackPageView('Navigated to SUITE WELOCOME PAGE');
	}, []);

    useEffect(() => {
        if(isResetCacheApiCalled)
        {
            dispatch(getProductUrls());
            dispatch(getAddOnFeatureUrls());
        }
        else{
            dispatch(resetUserCache());
        }
    }, [isResetCacheApiCalled]);
    return <Container fluid className="overflow-auto">
        <LoadingOverlay style={{ height: "100%" }}>
            <TopSection />
            <Banner />
            <ProductList />
            <Loader loading={isLoading || isAddonFeatureLoading} />
        </LoadingOverlay>
    </Container>;
};
export default Welcome;