import React, { useRef, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./SignatureImageCrop.style.scss";
import CloseIcon from 'src/components/svg/CloseIcon';

interface Props {
    file: any;
    show: any;
    onHide(): void;
    getCroppedCanvas(event: any): void;
}

const SignatureImageCrop: React.FC<Props> = (props) => {
    const { file, show, onHide, getCroppedCanvas } = props;
    const [dataUrl, setDataUrl] = useState("");
    const cropperRef = useRef<HTMLImageElement>(null);
    const onCrop = () => {
        const imageElement: any = cropperRef?.current;
        const cropper: any = imageElement?.cropper;
        setDataUrl(cropper.getCroppedCanvas().toDataURL());
    };

    return (
        <Modal show={show} onHide={onHide} size='lg' className="signatureImageCrop">
            <Modal.Header>
                <div><Modal.Title>Crop Signature Stamp</Modal.Title></div>
                <div data-test-auto="9db994fe-c1a5-4611-b279-8dc6e5dcd67d" className="closeIcon" onClick={onHide}><CloseIcon /></div>
            </Modal.Header>
            <Modal.Body style={{ width: '100%', height: '74vh' }}>
                <img style={{ maxWidth: '100%' }} id="ImgCropped_Signature" className="userscanneruploadsign_Crop"
                    data-test-auto="9b8601ef-8d8d-4930-966c-ff0c0b24e826"></img>
                {/* <Cropp.default
                    ref='cropper'
                    src={this.props.file}
                    guides={false}
                    autoCrop={true}
                    viewMode={2}
                    build={this.build}
                    style={{ width: '100%', height: '70vh' }}
                /> */}
                <Cropper
                    src={file}
                    style={{ width: '100%', height: '70vh' }}
                    initialAspectRatio={16 / 9}
                    autoCrop={true}
                    viewMode={2}
                    // build={this.build}
                    guides={false}
                    crop={onCrop}
                    ref={cropperRef}
                />
                {/* <Cropper
                        src={file}
                        style={{ height: 400, width: "100%" }}
                        // Cropper.js options
                        initialAspectRatio={16 / 9}
                        guides={false}
                        crop={onCrop}
                        ref={cropperRef}
                    /> */}
            </Modal.Body>
            <Modal.Footer>
                <Button data-test-auto="6c8718ca-3f99-4ec8-ac47-cd397a9cf9f5" variant="secondary" className="cropCancel" onClick={onHide}>
                    Discard
                </Button>
                <Button data-test-auto="241382c4-9f82-4433-a410-56471e9ebe31" variant="primary" className="cropApply" onClick={() => getCroppedCanvas(dataUrl)}>
                    Crop & Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default SignatureImageCrop;