import { Action, Reducer } from "redux";
import { produce } from "immer";
import { DispatchActions, IProductData } from "./welcome.types";
import { actionTypes } from "./welcome.actions";

export interface IWelcomeState {
    isLoading: boolean;
    productList: IProductData[];
    addOnFeatureList: IProductData[];
    helpUrl: string;
    isAddonFeatureLoading: boolean;
    IsAddonFeatureLoaded: boolean;
}

export const initialState: IWelcomeState = {
    isLoading: false,
    productList: [],
    addOnFeatureList: [],
    helpUrl: "",
    isAddonFeatureLoading: false,
    IsAddonFeatureLoaded: false
};


export const reducer: Reducer<IWelcomeState> = produce(
    (draftState: IWelcomeState = initialState, incomingAction: Action) => {
        const action = incomingAction as DispatchActions;
        switch (action.type) {
            case actionTypes.REQUEST_PRODUCT_URLS:
                draftState.isLoading = true;
                return draftState;
            case actionTypes.RECEIVE_PRODUCT_URLS:
                draftState.isLoading = false;
                draftState.productList = action.payload;
                return draftState;
            case actionTypes.COMPLETE_PRODUCT_URLS:
                draftState.isLoading = false;
                return draftState;
            case actionTypes.RECEIVE_HELP_URL:
                draftState.helpUrl = action.payload;
                return draftState;
            case actionTypes.REQUEST_ADDON_FEATURE_URLS:
                draftState.isAddonFeatureLoading = true;
                return draftState;
            case actionTypes.RECEIVE_ADDON_FEATURE_URLS:
                draftState.isAddonFeatureLoading = false;
                draftState.IsAddonFeatureLoaded = true;
                draftState.addOnFeatureList = action.payload;
                return draftState;
        }
        return draftState;
    }
);