/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useState } from 'react';

const useStateWithCallback = (initialValue: any) => {
	const [value, setValue] = useState(initialValue);

	const setValueAndCallback = (newValue: any, callback: any) => {
		setValue((prevValue: any) => {
			if (callback) callback(prevValue, newValue);

			return newValue;
		});
	};

	return [value, setValueAndCallback];
};

export { useStateWithCallback };
