import React, { FC, useState } from 'react';
import { IAccordianProps, IListDatum, ISubActions } from './accordian-with-description.types';
import chevronUp from '../../../../../components/svg/chevron-up.svg';
import chevronDown from '../../../../../components/svg/chevron-down.svg';
import { ReactComponent as CheckCirle } from '../../../../../components/svg/check-circle-fill.svg';
import './accordian-with-description.scss';
import { OverlayTrigger } from 'react-bootstrap';

const AccordianWithDescription: FC<IAccordianProps> = (props) => {
    const [open, setOpen] = useState(false);

    const onCloseClick = () => {
        setOpen(false);
    };

    const onOpenClick = () => {
        setOpen(true);
    };

    const handleClick = (e: React.SyntheticEvent, id: number) => {
        onClick(id);
        open && selected === id ? onCloseClick() : onOpenClick();
    };
   
    const { 
        title = '',
        listData = [],
        selected = -1,
        onClick = () => null,
        selectedProduct = -1
    } = props;
    return (
        <div className='accordian_description_list_container'>
            <div className='accordian_description_list_header'>
                {title}
            </div>
            <div className='accordian_description_list_container'>
                {selectedProduct > 0 &&
                    listData.map((datum: IListDatum, index: number) => (
                        <div 
                            className='accordian_description_list'
                            key={index}
                            onClick={(event) => handleClick(event, datum.Id)}
                        >
                            <div 
                                className={(open && selected === datum.Id) ? 'accordian_description_list_title selected' : 'accordian_description_list_title'}
                                title={datum.Name}
                            >
                                <div className='accordian_description_list_title_text'>
                                    {datum.Name}
                                </div>
                                <div className='accordian_description_list_title_icon'>
                                    { datum.isActive && <>{'Active'}<CheckCirle /></>}
                                    {
                                        open && selected === datum.Id ? 
                                        <div className='image-container'><img src={chevronUp} /></div> :
                                        <div className='image-container'><img src={chevronDown} /></div>
                                    }
                                </div>
                            </div>
                            { open && selected === datum.Id ? 
                                    <div className='accordian_description_list_content' onClick={(e) => e.stopPropagation()}>
                                        <div className='accordian_description_list_dropdown_header'>
                                            Description
                                        </div>
                                        <div className='accordian_description_list_text'>
                                            {
                                                datum.Description
                                            }
                                        </div>
                                        <div className='accordian_description_list_subaction'>
                                            {                                               
                                                datum.screens.map((action: ISubActions, index: number) => (
                                                    <div key={index} className='subaction_list'>                                                      
                                                        <input type="checkbox" id={action.Id} name={action.Name} value={action.Name} checked={action.isPermissionAssigned} disabled />                                                         
                                                        <OverlayTrigger placement='bottom' overlay={ <div className='subaction_list_tooltip'>
                                                               Currently, it is not possible to customize permissions at this time.
                                                          </div>} >
                                                        <label htmlFor={action.Name}>{action.Name}</label>
                                                        </OverlayTrigger>                                                    
                                                        
                                                    </div>
                                                   
                                                ))
                                            }
                                        </div>
                                    </div>
                                    : null                                        
                                }
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default AccordianWithDescription;