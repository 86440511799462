import { NotificationAction } from 'src/pages/common/notification/notification.reducer';
import { actionTypes } from './PaymentHistory.actions';
import { OverlayLoaderAction } from 'src/pages/common/overlayLoader/OverlayLoader.model';

export enum ProductType {
    None = 0,
    SSR = 1,
    Signature = 2,
    Organizers = 3,
    Extensions = 4,
    Exchange = 5,
}

export enum ReportPaymentFlowType {
    None = 0,
    PayNow = 1,
    PayasYouGo = 2,
    ManuallyPaid = 3,
}

export interface IPaymentHistoryData {
    count: number;
    paymentHistory: IPaymentHistoryList[];
}

export const initialPaymentHistoryData = {
    count: 0,
    paymentHistory: [],
};

export interface IPaymentHistoryList {
    paymentDate: string;
    paymentDescription: string;
    amount: number;
    paymentPlan: ReportPaymentFlowType;
    invoiceNo: number;
    quantity: number;
    receipt: string;
    totalBalance: number;
    referenceNumber: string;
    info: string;
}

export enum PaymentFlowType {
    None = 0,
    PayNow = 1,
    PayasYouGo = 2
}

export interface IPurchaseDetails {
    ssrPurchaseDetails: ISSRPurchaseDetails;
    signaturePurchaseDetails: ISignaturePurchaseDetails;
    ssoPurchaseDetails: ISSOPurchaseDetails;
    applicationFees: number;
    ssePurchaseDetails: ISSEPurchaseDetails;
}

export interface ISSRPurchaseDetails {
    pricePerReturn: number;
    deliveryMode: DeliveryMode;
    currentBalance: number;
}

export interface ISignaturePurchaseDetails {
    pricePerDocument: number;
    currentBalance: number;
}

export interface ISSOPurchaseDetails {
    pricePerDocument: number;
    currentBalance: number;
}

export interface ISSEPurchaseDetails extends ISSRPurchaseDetails { }

export enum DeliveryMode {
    ElectronicFiled = 0,
    PaperFiled = 1
}

interface ReceivePaymentHistory {
    type: actionTypes.RECEIVE_PAYMENT_HISTORY;
    paymentData: IPaymentHistoryData;
}

export type KnownAction = ReceivePaymentHistory | NotificationAction | OverlayLoaderAction;