import { LocalStorageConstants } from "../../../helper/Constants";
import { LogoutCause } from "./auth.reducer";

export const STORE_USER = "store_user";
export const USER_SIGNED_OUT = "user_signed_out";
export const USER_EXPIRED = "user_expired";
export const STORE_USER_ERROR = "store_user_error";
export const LOADING_USER = "loading_error";
export const RECEIVED_SESSION_IDLE_MINUTES = "request_session_idle_minutes";
export const USER_PRIVILEGE_CHANGED = "user_privilege_changed";
export const RESET_USER_PRIVILEGE_CHANGE_STATE = "reset_user_privilege_change_state";
export const USER_LOGGEDOUT = "user_logged_out";

export function storeUser(user: any) {
  localStorage.setItem(LocalStorageConstants.UserIdKey, user.profile.user_id);
  return {
    type: STORE_USER,
    payload: user,
  };
}

export function loadingUser() {
  return {
    type: LOADING_USER,
  };
}

export function storeUserError() {
  return {
    type: STORE_USER_ERROR,
  };
}

export function userExpired() {
  return {
    type: USER_EXPIRED,
  };
}

export function userSignedOut() {
  return {
    type: USER_SIGNED_OUT,
  };
}

export function userPrivilegeChanged(payload: LogoutCause) {
    return {
        type: USER_PRIVILEGE_CHANGED,
        userAutoLogoutCause: payload
    }
}

export function resetUserPrivilegeChangeState() {
    return {
        type: RESET_USER_PRIVILEGE_CHANGE_STATE,
    }
}

export function userLoggedOut() {
  return {
    type: USER_LOGGEDOUT,
  };
}
