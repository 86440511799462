export interface IEROSignatureSettings {
    userId: number;
    userName: string;
    isAutoPlaceSignature: boolean;
    isEnableSignatureDelegation: boolean;
    defaultERO: boolean;
    enableSignerDeligation: boolean;
    signaturePath: string;
    delegatee: number[];
}

export const eroSignatureSettingsDefault = {
    userId: 0,
    userName: "",
    isAutoPlaceSignature: false,
    isEnableSignatureDelegation: false,
    defaultERO: false,
    enableSignerDeligation: false,
    signaturePath: "",
    delegatee: []
}