import { type Reducer } from 'redux';

import { actionTypes } from './ip-filtering.action';
import { type IInitialState, type KnownAction } from './ip-filtering-options.types';

const unloadedState: IInitialState = {
	isLoading: false,
	isFilteringEnabled: false,
};

export const reducer: Reducer<IInitialState> = (state = unloadedState, incomingAction) => {
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case actionTypes.REQUEST_FILTERING_OPTION:
			return {
				...state,
				isLoading: action.isLoading,
			};

		case actionTypes.RECEIVE_FILTERING_OPTION:
			return {
				...state,
				isLoading: action.isLoading,
				isFilteringEnabled: action.data,
			};

		case actionTypes.UPDATE_FILTERING_OPTION:
			return {
				...state,
				isLoading: action.isLoading,
				isFilteringEnabled: action.data,
			};

		case actionTypes.RECEIVE_CLIENT_IP:
			return {
				...state,
				clientIpAddress: action.data,
			};

		default:
			return {
				...state,
			};
	}
};
