import * as UsageReportStore from '../../modules/usageReport/UsageReport.store';
import * as ResourceIdStore from '../../pages/common/resource-validator/resource-validator.api';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import UsageReport from '.';

export default connect(
	(state: ApplicationState) => ({
		companyData: state.companyData,
		usageReport: state.usageReport,
	}),
	{
		...UsageReportStore.actionCreators,
		...ResourceIdStore.actionCreators,
	},
)(UsageReport as React.FC);
