import CloseIcon from 'src/components/svg/CloseIcon';
import { FC, useEffect, useState } from 'react';
import { Modal, Container, Popover, OverlayTrigger } from 'react-bootstrap';
import './UserGroupAudit.style.scss';
import {
	DEFAULT_USERS_GROUP_AUDIT_PER_PAGE,
	USER_GROUPS_SHOW_LIMIT,
	UserGroupAuditModalConstants,
} from '../../../../helper/Constants';
import { getUserGroupAuditData } from './UserGroupAudit.api';
import { IUserGroupAuditModel, IUserGroupColorModel } from './UserGroupAudit';
import moment from 'moment';
import { IUserGroups } from '../Users.model';

export interface UserGroupAuditProps {
	showUserGroupAuditModel: boolean;
	userId: number;
	handleClose: () => void;
}

const UserGroupAuditModal: FC<UserGroupAuditProps> = (props) => {
	const { showUserGroupAuditModel, userId, handleClose } = { ...props };

	const [sortColumn, setSortColumn] = useState('TimeStamp');
	const [sortDirection, setSortDirection] = useState('desc');
	const [pageNo, setPageNo] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalCount, setTotalCount] = useState(0);
	const [userGroupAuditList, setUserGroupAuditList] = useState<IUserGroupAuditModel[]>([]);
	const [userGroupColorList, setUserGroupColorList] = useState<IUserGroupColorModel[]>([]);
	const [loading, setLoading] = useState(false);

	const totalPages = Math.ceil(totalCount / pageSize);
	const [pageIndexText, setPageIndexText] = useState('1');

	const sortClass = (columnName: string) => {
		if (sortColumn === columnName) {
			return sortDirection === 'asc' ? 'fa-sort-asc self-end' : 'fa-sort-desc';
		} else {
			return 'fa-sort self-end';
		}
	};

	useEffect(() => {
		setLoading(true);

		getUserGroupAuditData(userId, pageNo, pageSize, sortColumn, sortDirection, (data) => {
			if (data && data.userGroupAuditList) {
				setUserGroupAuditList(data.userGroupAuditList);
				setUserGroupColorList(data.groupColorList);
				setTotalCount(data.userGroupAuditList.length > 0 ? data.userGroupAuditList[0].totalCount : 0);
			}
			setLoading(false);
		});
	}, [userId, pageNo, pageSize, sortColumn, sortDirection]);

	const goToPage = (newPageIndex: number, newPageSize: number) => {
		const totalPages = Math.ceil(totalCount / pageSize);
		if ((newPageIndex !== pageNo && newPageIndex <= totalPages && newPageIndex >= 1) || newPageSize !== pageSize) {
			setPageNo(newPageIndex);
			setPageSize(newPageSize);
		}
	};

	const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		goToPage(1, Number(e.target.value));
	};
	const handlePageIndexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const pattern = new RegExp(/^[0-9]*$/);
		if (pattern.test(e.target.value)) {
			setPageIndexText(e.target.value);
		}
	};
	const handlePageIndexEnter = (e: any) => {
		const newPageIndexString = e.target.value;
		if (e.key === 'Enter' && newPageIndexString.trim().length > 0 && pageNo !== Number(newPageIndexString.trim())) {
			goToPage(Number(newPageIndexString), pageSize);
		}
	};

	const handleSort = (text: string) => {
		if (sortColumn === text) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortColumn(text);
			setSortDirection('asc');
		}
	};

	const showUserGroups = (groups: string) => {
		let userGroups: IUserGroups[] = [];

		groups.split(',').forEach((group) => {
			const userGroup = userGroupColorList.find((x) => x.groupId === parseInt(group));
			if (userGroup) {
				userGroups.push({
					groupId: userGroup.groupId,
					groupName: userGroup.groupName,
					BackgroundColor: userGroup.backgroundColorCode,
					borderColor: userGroup.borderColorCode,
					fontColor: userGroup.fontColorCode,
					groupDescription: '',
					roles: [],
				});
			}
		});

		let userGroupContent;
		const length = userGroups.length;
		if (length > USER_GROUPS_SHOW_LIMIT) {
			const popover = (
				<Popover id='popover-basic'>
					<Popover.Body>
						<ul>
							{userGroups.slice(USER_GROUPS_SHOW_LIMIT).map((userGroup: IUserGroups) => {
								return <li key={userGroup.groupId}>{userGroup.groupName}</li>;
							})}
						</ul>
					</Popover.Body>
				</Popover>
			);
			userGroupContent = userGroups.slice(0, USER_GROUPS_SHOW_LIMIT).map((userGroup: IUserGroups) => {
				return (
					<span
						key={userGroup.groupId}
						className='group-badge max-width26'
						title={userGroup.groupName}
						style={{
							backgroundColor: userGroup.BackgroundColor,
							border: `1px solid ${userGroup.borderColor}`,
							color: userGroup.fontColor,
						}}>
						{userGroup.groupName}
					</span>
				);
			});
			userGroupContent.push(
				<OverlayTrigger
					key='moreGroups'
					placement='bottom'
					overlay={popover}>
					<span className='moreGroups'>+{userGroups.length - USER_GROUPS_SHOW_LIMIT}&nbsp;more</span>
				</OverlayTrigger>,
			);
		} else {
			const groupClass = `group-badge${length === 3 ? ' max-width31' : length === 2 ? ' max-width48' : ' max-width98'}`;
			userGroupContent = userGroups.map((userGroup: IUserGroups) => {
				return (
					<span
						key={userGroup.groupId}
						className={groupClass}
						title={userGroup.groupName}
						style={{
							backgroundColor: userGroup.BackgroundColor,
							border: `1px solid ${userGroup.borderColor}`,
							color: userGroup.fontColor,
						}}>
						{userGroup.groupName}
					</span>
				);
			});
		}
		return userGroupContent;
	};

	return (
		<Modal
			show={showUserGroupAuditModel}
			onHide={handleClose}
			dialogClassName='modal-90w'
			centered
			className='userGroupAuditModal'>
			<Modal.Header>
				<Modal.Title>{UserGroupAuditModalConstants.headerText}</Modal.Title>
				<div
					data-test-auto='3AE0C223-EB6A-4F04-8BBC-72FD908CB985'
					className='closeIcon'
					onClick={handleClose}>
					<CloseIcon />
				</div>
			</Modal.Header>
			<Modal.Body>
				<Container>
					<div className='fixTableHead'>
					<table className='table table-striped table-hover userGroupAuditTable'>
						<thead>
							<tr>
								<th
									className='groupNameCol '
									scope='col'>
									{UserGroupAuditModalConstants.tableHeaderGroupName}
								</th>
								<th
									className='changeCol clickableColumns'
									scope='col'
									onClick={() => handleSort('Change')}>
									<div className='column'>
										<div className='column__name'>{UserGroupAuditModalConstants.tableHeaderChange}</div>
										<i className={`fa fa-fw ${sortClass('Change')} sortIcon`}></i>
									</div>
								</th>
								<th
									className='changedByCol clickableColumns'
									scope='col'
									onClick={() => handleSort('ChangedBy')}>
									<div className='column'>
										<div className='column__name'>{UserGroupAuditModalConstants.tableHeaderChangedBy}</div>
										<i className={`fa fa-fw ${sortClass('ChangedBy')} sortIcon`}></i>
									</div>
								</th>
								<th
									className='timeStampCol clickableColumns'
									scope='col'
									onClick={() => handleSort('TimeStamp')}>
									<div className='column'>
										<div className='column__name'>{UserGroupAuditModalConstants.tableHeaderTimeStamp}</div>
										<i className={`fa fa-fw ${sortClass('TimeStamp')} sortIcon`}></i>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{loading ? (
								<tr>
									<td
										data-toggle='collapse'
										colSpan={4}
										className='usersLoadingArea'>
										<div className='text-center'>
											<div
												className='spinner-border'
												role='status'></div>
											<div>
												<span className='usersLoadingText'>Loading...</span>
											</div>
										</div>
									</td>
								</tr>
							) : userGroupAuditList.length === 0 ? (
								<tr>
									<td
										data-toggle='collapse'
										colSpan={4}
										className='usersLoadingArea text-center'>
										No matching records found
									</td>
								</tr>
							) : (
								userGroupAuditList.map((userGroupItem) => {
									return (
										<tr className='userRow'>
											<td className='userGroupsColData'>{showUserGroups(userGroupItem.groupName)}</td>
											<td>{userGroupItem.change}</td>
											<td
												className='changedByColData'
												title={userGroupItem.changedBy}>
												{userGroupItem.changedBy}
											</td>
											<td>
												{moment.utc(userGroupItem.timeStamp, 'MM/DD/YYYY hh:mm A').local().format('MM/DD/YYYY hh:mm A')}
											</td>
										</tr>
									);
								})
							)}
						</tbody>
					</table>

					<section className='usersPagination'>
						<ul className='showPageNumber'>
							<li
								data-test-auto='AB57B629-7526-44A0-83B4-F93E455A5876'
								title={pageNo <= 1 ? '' : 'First Page'}
								className={pageNo <= 1 ? 'paginationActionDisabled' : ''}
								onClick={() => goToPage(1, pageSize)}>
								<span className='fa fa-angle-double-left'></span>
							</li>
							<li
								data-test-auto='66193841-08ED-4182-B5FA-201F36581B13'
								title={pageNo <= 1 ? '' : 'Previous Page'}
								className={pageNo <= 1 ? 'paginationActionDisabled' : ''}
								onClick={() => goToPage(pageNo - 1, pageSize)}>
								<span className='fa fa-angle-left'></span>
							</li>
							<li
								title={pageNo.toString()}
								className='currentPageNumber'>
								{pageNo.toString()}
							</li>
							<li
								data-test-auto='5B709734-8D1E-4A4E-8844-56E512374F42'
								title={pageNo >= totalPages ? '' : 'Next Page'}
								className={pageNo >= totalPages ? 'paginationActionDisabled' : ''}
								onClick={() => goToPage(pageNo + 1, pageSize)}>
								<span className='fa fa-angle-right'></span>
							</li>
							<li
								data-test-auto='AF686828-34ED-4AC5-AA95-0A0E02A26879'
								title={pageNo >= totalPages ? '' : 'Last Page'}
								className={pageNo >= totalPages ? 'paginationActionDisabled' : ''}
								onClick={() => goToPage(totalPages, pageSize)}>
								<span className='fa fa-angle-double-right'></span>
							</li>
						</ul>
						<div className='configurePagination'>
							<select
								data-test-auto='736E0134-B94A-44BC-8DE1-B903269F2933'
								name='pageSize'
								id='pageSize'
								className={`pageSize${
									totalCount <= DEFAULT_USERS_GROUP_AUDIT_PER_PAGE ? ' paginationActionDisabled' : ''
								}`}
								disabled={totalCount <= DEFAULT_USERS_GROUP_AUDIT_PER_PAGE}
								value={pageSize.toString()}
								onChange={handlePageSizeChange}>
								<option value='10'>10/page</option>
								<option value='15'>15/page</option>
								<option value='20'>20/page</option>
								<option value='25'>25/page</option>
								<option value='50'>50/page</option>
							</select>
							&nbsp;Go&nbsp;to&nbsp;
							<input
								data-test-auto='5C07B247-D992-4EB0-AC23-C466C6F54834'
								type='text'
								name='goToPage'
								id='goToPage'
								className={`goToPage${
									totalCount <= DEFAULT_USERS_GROUP_AUDIT_PER_PAGE ? ' paginationActionDisabled' : ''
								}`}
								maxLength={totalPages.toString().length}
								value={pageIndexText}
								onChange={handlePageIndexChange}
								onKeyDown={handlePageIndexEnter}
								disabled={totalCount <= DEFAULT_USERS_GROUP_AUDIT_PER_PAGE}
							/>
							&nbsp;of&nbsp;<span className='totalNumberOfPages'>{totalPages.toString()}</span>&nbsp;pages.
						</div>
					</section>
					</div>
				</Container>
			</Modal.Body>
		</Modal>
	);
};

export default UserGroupAuditModal;
