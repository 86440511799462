import React, { FC } from 'react';
import styles from './FormFieldWithError.module.scss';

interface ISelectOption {
	value: string;
	name: string;
}

interface FormFieldWithErrorProps {
	type: string;
	name: string;
	id: string;
	onChange: (event: any) => void;
	onBlur?: (event: any) => void;
	title?: string;
	maxLength?: string;
	value: string;
	disabled?: boolean;
	error?: boolean;
	errorMessage?: string;
	fieldWidth?: string;
	className?: string;
	optional?: boolean;
	options?: ISelectOption[];
	selectFirstOption?: ISelectOption;
	placeholder?: string;
}

const FormFieldWithError: FC<FormFieldWithErrorProps> = (props) => {
	return (
		<div className={`${styles.formFieldWithError} ${props.className ?? props.className}`}>
			{props.title && (
				<div className={styles.header}>
					<label
						className={styles.title}
						htmlFor={props.id}>
						{props.title}
					</label>
					{props.optional && <span className={styles.optional}>Optional</span>}
				</div>
			)}
			<div className={styles.field}>
				{props.type === 'text' && (
					<input
						type='text'
						name={props.name}
						id={props.id}
						style={props.fieldWidth ? { width: props.fieldWidth } : { width: '100%' }}
						value={props.value}
						onChange={props.onChange}
						onBlur={props.onBlur}
						maxLength={props.maxLength ?? props.maxLength}
						className={props.error ? styles.errorBorder : ''}
						placeholder={props.placeholder ? props.placeholder : ''}
					/>
				)}
				{props.type === 'select' && (
					<select
						name={props.name}
						id={props.id}
						style={props.fieldWidth ? { width: props.fieldWidth } : { width: '100%' }}
						value={props.value}
						data-test-value={props.options?.find((eachOption) => eachOption.value === props.value)?.name}
						onChange={props.onChange}
						onBlur={props.onBlur}
						className={props.error ? styles.errorBorder : ''}>
						<option value={props.selectFirstOption ? props.selectFirstOption.value : ''}>
							{props.selectFirstOption ? props.selectFirstOption.name : ''}
						</option>
						{props.options &&
							props.options.map((option: ISelectOption, index: number) => {
								return (
									<option
										key={option.value + '-' + index + '-' + option.name}
										value={option.value}>
										{option.name}
									</option>
								);
							})}
					</select>
				)}
			</div>
			{props.errorMessage && (
				<div
					className={styles.errorMessage}
					style={props.fieldWidth ? { width: props.fieldWidth } : { width: '100%' }}>
					{props.errorMessage}
				</div>
			)}
		</div>
	);
};

export default FormFieldWithError;
