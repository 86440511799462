import { type Reducer } from 'redux';

import { actionTypes } from './ip-filtering-list.action';
import { type IInitialState, type KnownAction } from './ip-filtering-list.types';

const unloadedState: IInitialState = {
	isLoading: false,
	listData: [
		{
			fromIPAddress: '',
			ipAddressId: 0,
			name: '',
			toIPAddress: '',
		},
	],
};

export const reducer: Reducer<IInitialState> = (state = unloadedState, incomingAction) => {
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case actionTypes.REQUEST_FILTERING_LIST:
			return {
				...state,
				isLoading: action.isLoading,
			};

		case actionTypes.RECEIVE_FILTERING_LIST:
			return {
				...state,
				isLoading: action.isLoading,
				listData: action.data,
			};

		case actionTypes.UPDATE_FILTERING_LIST:
			return {
				...state,
				isLoading: action.isLoading,
			};
		case actionTypes.DELETE_FILTERING_LIST:
			return {
				...state,
				isLoading: action.isLoading,
			};

		default:
			return {
				...state,
			};
	}
};
