import { StatusType } from '../../pages/common/notification/notification.reducer';
import { actionTypes } from './UserPermission.action';

export interface ISSOAccessRequest {
	totalCount: number;
	userId: number;
	firstName: string;
	lastName: string;
	emailAddress: string;
	tenantId: string;
	createdOn: Date;
	status: AzureADUnAuthorizedUserStatus;
	phone: string;
	extension: string;
	ptin: string;
	fax: string;
	title: string;
	countryCode: string;
	mobileNumber: string;
	companyId: number;
	objectId: string;
}

export enum AzureADUnAuthorizedUserStatus {
	Pending,
	Approved,
	Rejected,
}

export enum SSOAccessRequestStatus {
	None,
	GrantSuccess,
	GrantError,
	DenySuccess,
	DenyError,
	AlreadyGranted,
	AlreadyDenied,
}

export const columnNames = {
	name: 'Name',
	email: 'Email',
}

export interface IPageProps {
	pageIndex: number;
	pageSize: number;
}

export interface ISortColumn {
	sortBy: string;
	sortOrder: string;
}

export type KnownAction = DispatchAction | NotificationAction;

export type DispatchAction = RequestSSOAccessRequestListAction | ReceiveSSOAccessRequestListAction | ErrorSSORequest;

export interface ISSOAccessRequestedUsers {
	users: ISSOAccessRequest[];
	isLoading: boolean;
	count: number;
}

interface RequestSSOAccessRequestListAction {
	type: actionTypes.REQUEST_SSO_ACCESS_REQUESTS_LIST;
}

interface ReceiveSSOAccessRequestListAction {
	type: actionTypes.RECEIVE_SSO_ACCESS_REQUESTS_LIST;
	users: ISSOAccessRequest[];
}

interface ErrorSSORequest {
	type: actionTypes.NOTIFICATION_SSO_REQUEST,
	statusMessage: string;
	statusType: StatusType;
}
