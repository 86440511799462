import React from 'react';

const ExportUsageReport: React.FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			fill='none'
			viewBox='0 0 16 16'>
			<g clipPath='url(#clip0_4866_154053)'>
				<path
					fill='#fff'
					fillOpacity='0.01'
					d='M0 0H16V16H0z'></path>
				<g
					fill='#05386B'
					fillRule='evenodd'
					clipPath='url(#clip1_4866_154053)'
					clipRule='evenodd'>
					<path d='M10 12.499a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5v-9a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v2a.5.5 0 001 0v-2a1.5 1.5 0 00-1.5-1.5h-8a1.5 1.5 0 00-1.5 1.5v9a1.5 1.5 0 001.5 1.5h8a1.5 1.5 0 001.5-1.5v-2a.5.5 0 00-1 0v2z'></path>
					<path d='M15.854 8.353a.5.5 0 000-.708l-3-3a.5.5 0 00-.708.708l2.147 2.146H5.5a.5.5 0 100 1h8.793l-2.147 2.146a.5.5 0 00.708.708l3-3z'></path>
				</g>
			</g>
			<defs>
				<clipPath id='clip0_4866_154053'>
					<path
						fill='#fff'
						d='M0 0H16V16H0z'></path>
				</clipPath>
				<clipPath id='clip1_4866_154053'>
					<path
						fill='#fff'
						d='M0 0H16V16H0z'></path>
				</clipPath>
			</defs>
		</svg>
	);
};

export default ExportUsageReport;
