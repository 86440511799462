/* eslint-disable react/prop-types */

import React from 'react';

/* eslint-disable react/react-in-jsx-scope */
export interface IconImageProps {
  height?: number;
  width?: number;
  color?: string;
  disabled?: boolean;
}

export const InfoIcon: React.FC<IconImageProps> = (props) => (
  <svg
		xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
		fill='#2A4899'
		className='bi bi-info-circle-fill'
		{...props}>
		<path d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z' />
  </svg>
);

export const Headphone: React.FC = (props) => (
  <svg
    width={12}
    height={16}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}>
    <path
			d='M6 1.5a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-5a6 6 0 1 1 12 0v6A2.5 2.5 0 0 1 9.5 15H7.366a1 1 0 0 1-.866.5h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 .866.5H9.5a1.5 1.5 0 0 0 1.5-1.5h-1a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h1v-1a5 5 0 0 0-5-5Z'
			fill='#212529'
    />
  </svg>
);

export const RefreshIcon: React.FC = (props) => (
  <svg
		xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
		fill='currentColor'
		className='bi bi-arrow-clockwise'
		{...props}>
    <path
			fillRule='evenodd'
			d='M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z'
    />
		<path d='M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z' />
  </svg>
);

export const InfoCircle: React.FC = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='16'
		height='16'
		fill='currentColor'
		className='bi bi-info-circle'
		viewBox='0 0 16 16'>
		<path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
		<path d='m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z' />
  </svg>
);

export const EyeIcon: React.FC = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='16'
		height='16'
		fill='currentColor'
		className='bi bi-eye'
		viewBox='0 0 16 16'>
		<path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z' />
		<path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z' />
  </svg>
);

export const EyeSlashIcon: React.FC = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='16'
		height='16'
		fill='currentColor'
		className='bi bi-eye-slash'
		viewBox='0 0 16 16'>
		<path d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z' />
		<path d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z' />
		<path d='M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z' />
	</svg>
);

export const DeleteIcon: React.FC = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'>
		<g clip-path='url(#clip0_7696_2862)'>
			<path
				d='M6.49976 0.999207H9.49976C9.7759 0.999207 9.99976 1.22306 9.99976 1.49921V2.49921H5.99976V1.49921C5.99976 1.22306 6.22361 0.999207 6.49976 0.999207ZM10.9998 2.49921V1.49921C10.9998 0.67078 10.3282 -0.000793457 9.49976 -0.000793457H6.49976C5.67133 -0.000793457 4.99976 0.670779 4.99976 1.49921V2.49921H2.50541C2.50201 2.49917 2.49861 2.49917 2.4952 2.49921H1.49976C1.22361 2.49921 0.999756 2.72306 0.999756 2.99921C0.999756 3.27535 1.22361 3.49921 1.49976 3.49921H2.03816L2.89092 14.1587C2.97407 15.1981 3.84183 15.9992 4.88455 15.9992H11.115C12.1577 15.9992 13.0254 15.1981 13.1086 14.1587L13.9614 3.49921H14.4998C14.7759 3.49921 14.9998 3.27535 14.9998 2.99921C14.9998 2.72306 14.7759 2.49921 14.4998 2.49921H13.5043C13.5009 2.49917 13.4975 2.49917 13.4941 2.49921H10.9998ZM12.9582 3.49921L12.1118 14.079C12.0702 14.5987 11.6363 14.9992 11.115 14.9992H4.88455C4.36319 14.9992 3.92931 14.5987 3.88773 14.079L3.04135 3.49921H12.9582ZM5.4704 4.50007C5.74606 4.48385 5.98268 4.69418 5.99889 4.96985L6.49889 13.4698C6.51511 13.7455 6.30478 13.9821 6.02912 13.9983C5.75345 14.0146 5.51683 13.8042 5.50062 13.5286L5.00062 5.02857C4.9844 4.7529 5.19473 4.51628 5.4704 4.50007ZM10.5291 4.50007C10.8048 4.51628 11.0151 4.7529 10.9989 5.02857L10.4989 13.5286C10.4827 13.8042 10.2461 14.0146 9.9704 13.9983C9.69473 13.9821 9.4844 13.7455 9.50062 13.4698L10.0006 4.96985C10.0168 4.69418 10.2535 4.48385 10.5291 4.50007ZM7.99976 4.49921C8.2759 4.49921 8.49976 4.72306 8.49976 4.99921V13.4992C8.49976 13.7753 8.2759 13.9992 7.99976 13.9992C7.72361 13.9992 7.49976 13.7753 7.49976 13.4992V4.99921C7.49976 4.72306 7.72361 4.49921 7.99976 4.49921Z'
				fill='#05386B'
			/>
		</g>
		<defs>
			<clipPath id='clip0_7696_2862'>
				<rect
					width='16'
					height='16'
					fill='white'
				/>
			</clipPath>
		</defs>
	</svg>
);

export const UpdateOfficeLocationIcon: React.FC = () => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M2 1C2 0.447715 2.44772 0 3 0H13C13.5523 0 14 0.447715 14 1V7.5C14 7.77614 13.7761 8 13.5 8C13.2239 8 13 7.77614 13 7.5V1H3V15H6V12.5C6 12.2239 6.22386 12 6.5 12H8.5C8.77614 12 9 12.2239 9 12.5C9 12.7761 8.77614 13 8.5 13H7V16H3C2.44771 16 2 15.5523 2 15V1Z'
			fill='#05386B'
		/>
		<path
			d='M15.8717 10.2611C16.0428 10.4322 16.0428 10.7096 15.8717 10.8806L14.9579 11.7944L13.2056 10.042L14.1194 9.12831C14.2904 8.95723 14.5678 8.95723 14.7389 9.12831L15.8717 10.2611Z'
			fill='#05386B'
		/>
		<path
			d='M14.6075 12.1449L12.8551 10.3925L9.82238 13.4253C9.77429 13.4734 9.73806 13.532 9.71655 13.5965L9.01162 15.7113C8.95454 15.8826 9.11745 16.0455 9.28869 15.9884L11.4035 15.2835C11.468 15.2619 11.5266 15.2257 11.5747 15.1776L14.6075 12.1449Z'
			fill='#05386B'
		/>
		<path
			d='M4.57166 4.3H5.42834C5.74416 4.3 6 4.0091 6 3.65C6 3.29108 5.74416 3 5.42834 3H4.57166C4.256 3 4 3.29108 4 3.65C4 4.0091 4.256 4.3 4.57166 4.3Z'
			fill='#212529'
		/>
		<path
			d='M7.57253 4.3H8.42747C8.74361 4.3 9 4.0091 9 3.65C9 3.29108 8.74361 3 8.42747 3H7.57253C7.25623 3 7 3.29108 7 3.65C7 4.0091 7.25623 4.3 7.57253 4.3Z'
			fill='#212529'
		/>
		<path
			d='M10.5727 4.3H11.4273C11.7435 4.3 12 4.0091 12 3.65C12 3.29108 11.7435 3 11.4273 3H10.5727C10.2563 3 10 3.29108 10 3.65C10 4.0091 10.2563 4.3 10.5727 4.3Z'
			fill='#212529'
		/>
		<path
			d='M4.57166 6.3H5.42834C5.74416 6.3 6 6.00892 6 5.65C6 5.29108 5.74416 5 5.42834 5H4.57166C4.256 5 4 5.29108 4 5.65C4 6.00892 4.256 6.3 4.57166 6.3Z'
			fill='#212529'
		/>
		<path
			d='M7.57253 6.3H8.42747C8.74361 6.3 9 6.00892 9 5.65C9 5.29108 8.74361 5 8.42747 5H7.57253C7.25623 5 7 5.29108 7 5.65C7 6.00892 7.25623 6.3 7.57253 6.3Z'
			fill='#212529'
		/>
		<path
			d='M10.5727 6.3H11.4273C11.7435 6.3 12 6.00892 12 5.65C12 5.29108 11.7435 5 11.4273 5H10.5727C10.2563 5 10 5.29108 10 5.65C10 6.00892 10.2563 6.3 10.5727 6.3Z'
			fill='#212529'
		/>
		<path
			d='M6 7.65C6 7.29108 5.74367 7 5.42725 7H4.57275C4.25649 7 4 7.29108 4 7.65C4 8.00892 4.25649 8.3 4.57275 8.3H5.43106C5.74588 8.29764 6 8.00728 6 7.65Z'
			fill='#212529'
		/>
		<path
			d='M7.57253 8.3H8.42747C8.74361 8.3 9 8.00892 9 7.65C9 7.29108 8.74361 7 8.42747 7H7.57253C7.25623 7 7 7.29108 7 7.65C7 8.00892 7.25623 8.3 7.57253 8.3Z'
			fill='#212529'
		/>
		<path
			d='M10.5727 8.3H11.4273C11.7435 8.3 12 8.00892 12 7.65C12 7.29108 11.7435 7 11.4273 7H10.5727C10.2563 7 10 7.29108 10 7.65C10 8.00892 10.2563 8.3 10.5727 8.3Z'
			fill='#212529'
		/>
  </svg>
);