import { actionTypes } from './save-custom-authority-modal.actions';
import { type NotificationAction } from '../../../../pages/common/notification/notification.reducer';

export const customAuthorityInitial = {
	id: 0,
	authorityName: '',
	abbreviation: 'AK',
	paymentURL: '',
	prefixStateCode: true,
	active: true,
	inUse: 0,
	isEsignEnabled: true,
	customAuthority: true,
	stateId: '',
};

export interface DeleteCustomTaxingAuthorityAction {
	type: actionTypes.DELETE_CUSTOM_AUTHORITY;
}

export type DispatchActions = DeleteCustomTaxingAuthorityAction;

export type KnownActions = DispatchActions | NotificationAction;
