import React, { FC } from 'react';

const AddUserIcon: FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='21'
			height='15'
			viewBox='0 0 21 15'
			fill='none'>
			<path
				d='M17 0C17.2761 0 17.5 0.223858 17.5 0.5V3.5H20.5C20.7761 3.5 21 3.72386 21 4C21 4.27614 20.7761 4.5 20.5 4.5H17.5V7.5C17.5 7.77614 17.2761 8 17 8C16.7239 8 16.5 7.77614 16.5 7.5V4.5H13.5C13.2239 4.5 13 4.27614 13 4C13 3.72386 13.2239 3.5 13.5 3.5H16.5V0.5C16.5 0.223858 16.7239 0 17 0Z'
				fill='white'
			/>
			<path
				d='M6.5 8.49902C7.36195 8.49902 8.1886 8.15661 8.7981 7.54712C9.40759 6.93763 9.75 6.11098 9.75 5.24902C9.75 4.38707 9.40759 3.56042 8.7981 2.95093C8.1886 2.34143 7.36195 1.99902 6.5 1.99902C5.63805 1.99902 4.8114 2.34143 4.2019 2.95093C3.59241 3.56042 3.25 4.38707 3.25 5.24902C3.25 6.11098 3.59241 6.93763 4.2019 7.54712C4.8114 8.15661 5.63805 8.49902 6.5 8.49902ZM8.66667 5.24902C8.66667 5.82366 8.43839 6.37476 8.03207 6.78109C7.62574 7.18742 7.07464 7.41569 6.5 7.41569C5.92536 7.41569 5.37426 7.18742 4.96794 6.78109C4.56161 6.37476 4.33333 5.82366 4.33333 5.24902C4.33333 4.67439 4.56161 4.12329 4.96794 3.71696C5.37426 3.31063 5.92536 3.08236 6.5 3.08236C7.07464 3.08236 7.62574 3.31063 8.03207 3.71696C8.43839 4.12329 8.66667 4.67439 8.66667 5.24902ZM13 13.9157C13 14.999 11.9167 14.999 11.9167 14.999H1.08333C1.08333 14.999 0 14.999 0 13.9157C0 12.8324 1.08333 9.58236 6.5 9.58236C11.9167 9.58236 13 12.8324 13 13.9157ZM11.9167 13.9114C11.9156 13.6449 11.7498 12.8432 11.0153 12.1087C10.309 11.4024 8.97975 10.6657 6.5 10.6657C4.01917 10.6657 2.691 11.4024 1.98467 12.1087C1.25017 12.8432 1.0855 13.6449 1.08333 13.9114H11.9167Z'
				fill='white'
			/>
		</svg>
	);
};

export default AddUserIcon;
