import CustomDropdown from '../../../components/common/customDropdown';
import React from 'react';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import {
	DateRangeOptions,
	IDropdownStates,
	IUsageReport,
	printTableCss,
	dateRangeOptions,
	getProductTypeName,
	productTypes,
	reportColumns,
	returnTypes,
	ssrReportColumns,
} from '../UsageReport.model';
import './UsageReportHeader.style.scss';
import Print from '../../../components/svg/Print';
import ExportUsageReport from '../../../components/svg/ExportUsageReport';
import CustomDatepicker from '../../../components/common/customDatepicker';
import { ProductType, UsageReportConstants } from '../../../helper/Constants';
import ReactDOMServer from 'react-dom/server';
import { usageReportResources } from '../../../helper/rbac-constants';
import { AppNotifier as VenusNotifier } from '../../../helper/AppNotifier';
import { ICustomDropdownOption } from 'src/components/common/customDropdown/CustomDropdownWithCustomOptions';
import { logger } from 'src/oidcClient/authProvider';
 
interface IUsageReportHeaderProps {
	initialLoad: boolean;
	usageReport: IUsageReport;
	dropdownStates: IDropdownStates;
	setDropdownStates(dropdownStates: IDropdownStates): void;
	onRunReport(): void;
	exportToExcel(): void;
}

const UsageReportHeader: React.FC<IUsageReportHeaderProps> = (props) => {
	const {
		initialLoad,
		usageReport: { isLoading, usageReport },
		dropdownStates,
		setDropdownStates,
		onRunReport,
		exportToExcel,
	} = props;

	const showReturnType = () => {
		return (
			dropdownStates.productType &&
			(dropdownStates.productType.value === ProductType.Returns ||
				dropdownStates.productType.value === ProductType.Extensions)
		);
	};

	const showDatePickers = () => {
		return dropdownStates.dateType?.value === DateRangeOptions.Custom;
	};

	const getColumns = () => {
		let columns;
		if (usageReport.every((data) => data.adjustments === undefined || data.deletedAfterDelivery === undefined)) {
			columns = reportColumns;
		} else {
			columns = ssrReportColumns;
		}
		return Object.values(columns);
	};

	const printToPDF = () => {
		logger && logger.trackTrace('printToPDF: Usage report Print');
		if (!initialLoad && usageReport && usageReport.length > 0) {
				let usageReportTable = ReactDOMServer.renderToString(getPrintableTable());

				let printWindow = window.open('', '_blank', '');
				if (printWindow) {
					printWindow.document.write('<html><body onload="window.print()">' + usageReportTable + '</body></html>');
					printWindow.document.close();
					printWindow.close();
				}
		} else {
			VenusNotifier.Warning(UsageReportConstants.noDataToPrint);
		}
	};

	const getPrintableTable = () => {
		let productname = getProductTypeName(dropdownStates.productType?.value) + 'Usage Report';
		return (
			<div style={{ fontFamily: 'sans-serif' }}>
				<title>{productname}</title>
				<br />
				<table style={printTableCss.tableStyle}>
					<tr style={printTableCss.tableRowStyle}>
						<th></th>
						{getColumns().map((column: string) => {
							return <th>{column}</th>;
						})}
					</tr>
					{usageReport.map((data, index) => {
						return (
							<tr style={printTableCss.tableRowStyle}>
								<td style={printTableCss.tableCellStyle}> {index + 1} </td>
								<td style={printTableCss.tableCellStyle}>{moment(data.usageDate).format('MM/DD/YYYY')}</td>
								<td style={printTableCss.tableCellStyle}> {data.delivered} </td>
								{data.deletedAfterDelivery !== undefined ? (
									<td style={printTableCss.tableCellStyle}> {data.deletedAfterDelivery} </td>
								) : (
									''
								)}
								{data.recalled !== undefined ? (
									<td style={printTableCss.tableCellStyle}> {data.recalled} </td>
								) : (
									''
								)}
								{data.adjustments !== undefined ? (
									<td style={printTableCss.tableCellStyle}> {data.adjustments} </td>
								) : (
									''
								)}
								<td style={printTableCss.tableCellStyle}> {data.totalUsage} </td>
								
							</tr>
						);
					})}
				</table>
			</div>
		);
	};

	return (
		<div className='usageReportHeader'>
			<div className='column1'>
				<Form.Group
					className='column1_group'
					controlId='productTypeDropdown'>
					<Form.Label className='commonLabel'>Product Type</Form.Label>
					<CustomDropdown
						id='productTypeDropdown'
						selectedOption={dropdownStates.productType}
						setSelectedOption={(option: ICustomDropdownOption | null) => {
							setDropdownStates({ ...dropdownStates, productType: option, returnType: null });
						}}
						options={productTypes}
						className='productTypeDropdown'
						customPlaceHolder='Select Product Type'
						clearable={false}
						searchable={false}
						height={38}
					/>
				</Form.Group>
				{showReturnType() && (
					<Form.Group controlId='returnTypeDropdown'>
						<Form.Label className='commonLabel'>Return Type</Form.Label>
						<CustomDropdown
							id='returnTypeDropdown'
							selectedOption={dropdownStates.returnType}
							setSelectedOption={(option: ICustomDropdownOption | null) => {
								setDropdownStates({ ...dropdownStates, returnType: option });
							}}
							options={returnTypes}
							className='returnTypeDropdown'
							customPlaceHolder='Select Return Type'
							clearable={false}
							searchable={false}
							height={38}
						/>
					</Form.Group>
				)}
				<Form.Group controlId='dateDropdown'>
					<Form.Label className='commonLabel'>Date</Form.Label>
					<CustomDropdown
						id='dateDropdown'
						selectedOption={dropdownStates.dateType}
						setSelectedOption={(option: ICustomDropdownOption | null) => {
							setDropdownStates({ ...dropdownStates, dateType: option });
						}}
						options={dateRangeOptions}
						className='dateDropdown'
						customPlaceHolder='Month to Date'
						clearable={false}
						searchable={false}
						height={38}
					/>
				</Form.Group>
				{showDatePickers() && (
					<>
						<Form.Group>
							<Form.Label className='commonLabel'>Start Date</Form.Label>
							<CustomDatepicker
								selectedDate={dropdownStates.startDate}
								setSelectedDate={(date: Date | null | undefined) => {
									setDropdownStates({ ...dropdownStates, startDate: date });
								}}
								className='commonDatepicker'
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label className='commonLabel'>End Date</Form.Label>
							<CustomDatepicker
								selectedDate={dropdownStates.endDate}
								setSelectedDate={(date: Date | null | undefined) => {
									setDropdownStates({ ...dropdownStates, endDate: date });
								}}
								className='commonDatepicker'
							/>
						</Form.Group>
					</>
				)}
			</div>

			<div className='column2'>
				{!initialLoad && (
					<button
						data-test-auto='f33e066d-4fb5-4071-a7cf-a8815144daa2'
						className='runReportBtn'
						data-resource-id={usageReportResources.Prefix + usageReportResources.RunReport}
						disabled={isLoading}
						onClick={onRunReport}>
						Run Report
					</button>
				)}
				<button
					data-test-auto='f33e066d-4fb5-4071-a7cf-a8815144daa2'
					className='printIcon'
					title='Print'
					data-resource-id={usageReportResources.Prefix + usageReportResources.PrintButton}
					disabled={isLoading}
					onClick={printToPDF}>
					<Print />
				</button>
				<button
					data-test-auto='f33e066d-4fb5-4071-a7cf-a8815144daa2'
					className={`exportIcon ${isLoading || usageReport.length === 0 || initialLoad? 'no-drop' : ''}`}
					title='Export to excel'
					data-resource-id={usageReportResources.Prefix + usageReportResources.ExportButton}
					disabled={isLoading || usageReport.length === 0 || initialLoad}
					onClick={exportToExcel}>
					<ExportUsageReport />
				</button>
			</div>
		</div>
	);
};

export default UsageReportHeader;
