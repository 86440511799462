import { Action, Reducer } from "redux";
import { actionTypes } from "./otp.actions";
import { DispatchActions, IOTPState } from "./otp.types";

export interface IOneTimePasswordState {
    otpState: IOTPState;
    isLoading: boolean;
}

const unloadedState: IOneTimePasswordState = {
    otpState: {
        isOTPValid: false,
        mfaOTPLength: 0,
    },
    isLoading: false,
};

export const reducer: Reducer<IOneTimePasswordState> = (
    state: IOneTimePasswordState = unloadedState,
    incomingAction: Action
) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.REQUEST_ONETIMEPASSWORD:
            state.isLoading = true;
            break;
        case actionTypes.RECEIVE_ONETIMEPASSWORD:
            state.isLoading = false;
            break;
        case actionTypes.REQUEST_VALIDATE_ONETIMEPASSWORD:
            state.isLoading = true;
            break;
        case actionTypes.COMPLETED_VALIDATE_ONETIMEPASSWORD:
            state.isLoading = false;
            state.otpState.isOTPValid = action.otpState.isOTPValid;
            break;
        case actionTypes.REQUEST_MFA_ONETIMEPASSWORD_LENGTH:
            state.isLoading = true;
            break;
        case actionTypes.RECEIVE_MFA_ONETIMEPASSWORD_LENGTH:
            state.isLoading = false;
            state.otpState = action.otpState;
            break;
        default:
    }
    return state || unloadedState;
};