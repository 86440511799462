import CloseIcon from "src/components/svg/CloseIcon";
import React, { FC, useEffect, useState } from "react";
import { Modal, Container, Button,Alert  } from "react-bootstrap";
import "./DefaultContactPersonModal.style.scss";
import { IDefaultContactPerson, IUsersDropdown } from "../Users.model";
import {DefaultContactPersonModalConstants} from '../../../../helper/Constants';
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { getAllUsers } from "../SignatureDelegationModal/SignatureDelegationModal.api";
import Select from 'react-select';
import { updateDefaultContactPerson } from "../Users.api";

export interface DefaultContactPersonModalProps{
    defaultContactPersons:IDefaultContactPerson[],
    setShowDefaultContactPersonModal: (ShowDefaultContactPersonModal: boolean) => void;
    userId:number;
    showDeleteErrorPopup:()=> void;
    onSuccess:()=>void;
}

interface dropdownList{
    value:number,
    label:string,
}

const DefaultContactPersonModal: FC<DefaultContactPersonModalProps> = (props) => {

    const {defaultContactPersons,setShowDefaultContactPersonModal,showDeleteErrorPopup,onSuccess}={...props};
    const handleClose = () => setShowDefaultContactPersonModal(false);
    const [availableUsers, setAvailableUsers] = useState<dropdownList[]>([]);
    const [selectedUserId,setSelectedUserId]=useState<number>(0);
    const [disableSaveBtn,setDisableSaveBtn]=useState<boolean>(false);
    
    const  defaultType = (cell: any) => {
        return <div title={cell} className="ellipsis">{cell}</div>;
    }

    const columns = [
        {
            text: DefaultContactPersonModalConstants.tableHeaderFirstName,
            dataField: 'firstName',
            title: (cell: string) => defaultType(cell),
            headerStyle: { width: "250px" },
            formatter: (cell: string) => defaultType(cell),
            dataSort: true,
            toolTip: true,
            hidden: false           
        },
        {
            text: DefaultContactPersonModalConstants.tableHeaderLastName,
            dataField: 'lastName',
            title: (cell: string) => defaultType(cell),
            headerStyle: { width: "250px" },
            formatter: (cell: string) => defaultType(cell),
            dataSort: true,
            toolTip: true,
            hidden: false 
        },
        {
            text: DefaultContactPersonModalConstants.tableHeaderEmailAddress,
            dataField: 'emailAddress',
            title: (cell: string) => defaultType(cell),
            headerStyle: { width: "250px" },
            formatter: (cell: string) => defaultType(cell),
            dataSort: true,
            toolTip: true,
            hidden: false            
        }
    ];

    useEffect(() => {
        getAllUsers((allUsers) => {

            const userDropdown:dropdownList[]=[];

              allUsers.forEach((user:IUsersDropdown)=>{
                    if(props.userId!=user.value)
                    {
                        userDropdown.push({
                            label:user.name,
                            value:user.value
                        }); 
                    }
              });    

              setAvailableUsers(userDropdown);
        });
    });

   const onUserChange = (event: any) => {
    const selectedValue = event ? event.value : event;
    setSelectedUserId(selectedValue);
   }

   const onSave=()=>{
    setDisableSaveBtn(true);
    updateDefaultContactPerson(props.userId,selectedUserId,(status)=>{
        setDisableSaveBtn(false);
        if(status)
        {
            onSuccess();
        }
        else{
            showDeleteErrorPopup();
        }
        
    });
   }

    return (
        <Modal show={true} onHide={handleClose} className="defaultContactPersonModal">
            <Modal.Header>
                <div><Modal.Title>{DefaultContactPersonModalConstants.headerText}</Modal.Title></div>
                <div data-test-auto="3AE0C223-EB6A-4F04-8BBC-72FD908CB985" className="closeIcon" onClick={handleClose}><CloseIcon /></div>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <Container>
                <Alert variant="warning">
                <i className='fas fa-exclamation-triangle warning-icon' style={{ marginRight: '5px' }}> </i>
                <div className="warning-alter" dangerouslySetInnerHTML={{ __html: DefaultContactPersonModalConstants.warningMessage }} />
                </Alert>

                <BootstrapTable
                    classes="custom-table"
                    keyField="defaultContactPerson"
                    columns={columns}
                    data={defaultContactPersons}        
                    noDataIndication={"No data"}
                    bordered={false}        
                    striped={true}
                />
                <div className="row marB03 padT07">
                        <div className="col-sm-2 col-md-3 col-xl-3">
                            <div className="marB10 padT07">{DefaultContactPersonModalConstants.lblContactPerson}</div>
                        </div>
                        <div className="col-sm-3 col-md-4 col-xl-4 my-def-help-tooltip">
                            <div className="padR30">
                            <Select 
                            id="availableUsers"
                            options={availableUsers} 
                            menuPortalTarget={document.body} 
                            menuPosition={'fixed'}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999,fontSize: '16px' })}}
                            data-toggle="dropdown" 
                            data-boundary="window" 
                            data-test-auto="5FE97005-401F-485A-9A43-75A7F7752EB5"
                            onChange={onUserChange}                           
                            />
                            </div>
                        </div>
                    </div>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button data-test-auto="AF335F34-D26C-456E-A6DC-8F1665834793" variant="secondary"  onClick={handleClose}>
                    {DefaultContactPersonModalConstants.closeBtn}
                </Button>
                <Button disabled={disableSaveBtn} onClick={onSave} data-test-auto="62ECF9B0-E98D-4A3A-8961-3F8D0DDA84D3" variant="primary">
                    {DefaultContactPersonModalConstants.saveBtn}
                </Button>
            </Modal.Footer>
           </Modal>
    );
};

export default DefaultContactPersonModal;