import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL, ApprovePasswordConstants } from 'src/helper/Constants';
import { AppNotifier as VenusNotifier } from '../../helper/AppNotifier';
import { approvePasswordResources } from '../../helper/rbac-constants';
import { IApprovePassUserData } from './ApprovePassword.model';
import { logger } from 'src/oidcClient/authProvider';

const getUserPasswordResetRequest = async (param: string, callback: (result: any) => void) => {
	try {
		const response: AxiosResponse<IApprovePassUserData>=await axios.get(
			API_BASE_URL + `/api/approvepassword/get-approve-password/${param}`,
			{
				headers: {
					'X-Resource-Id': `${approvePasswordResources.apprvPassword}`,
				},
			},
		);
		callback(response.data);
	} catch (error) {
		logger && logger.trackWarning('Error while getUserPasswordResetRequest', { 'Error': error?.response?.data});
		VenusNotifier.Error(ApprovePasswordConstants.approvePasswordError);	
	}
};

const approveUserPasswordResetRequest = async (email: string, callback: (result: boolean) => void) => {

	try {
		const response: AxiosResponse<boolean> =await axios.post(
			API_BASE_URL + `/api/approvepassword/approve-password-by-admin/${email}`,
			{
				headers: {
					'X-Resource-Id': `${approvePasswordResources.apprvPassword}`,
				},
			},
		);
		callback(response.data);
	} catch (error) {
		VenusNotifier.Error(ApprovePasswordConstants.approvePasswordError);
	}
};

export { getUserPasswordResetRequest, approveUserPasswordResetRequest };