import { actionTypes } from './user-groups.actions';
import { NotificationAction, StatusType } from '../../../pages/common/notification/notification.reducer';
import { dropdownList } from 'src/store/common/dropdown/dropdown-list.types';
import { ProductType } from '../../../helper/Constants';
import { IUserGroup } from '../../../pages/common/userProfile/userProfile.types';
import { PageProps } from '../users/Users.model';

export interface IUserGroups {
    groupId: number;
    groupName: string;
    groupDescription: string;
    roles: IRoleWithProducts[];
    isDefault: boolean;
    tooltip?: string;
}

export interface IUserGroupsAddUpdateModel {
    groupId: number;
    groupName: string;
    groupDescription: string;
    roles: number[];
    isDefault: boolean;
}


export interface IRoleWithProducts {
    roleId: number,
    roleName: string,
    products: ProductType[]
}

export const newGroup: IUserGroups = {
    groupId: 0,
    groupName: '',
    groupDescription: '',
    isDefault: false,
    roles: []
};

export interface IUserInGroup {
    groupId: number;
    userId: number;
    fullName: string;
}

export interface IGroupsUserList {
    groupId: number;
    usersInGroup: IUserInGroup[];
    availableUsers: IUserInGroup[]
}

export interface IUserGroupData {
    isLoading: boolean;
    groups: IUserGroups[];
    usersInGroup: IGroupsUserList[];
}

export interface IRoles {
    [id: number]: string
}

export interface IUserGroupsProps {
    display: boolean;
    userGroupList: { isLoading: boolean, groups: IUserGroups[] };
    roleDropdownList: dropdownList[];
    requestRoleDrowpdownList: (reload: boolean) => void;
    requestAllUserGroups: (newGroupIds?: number[]) => void;
    addUserGroup: (group: IUserGroupsAddUpdateModel, successCallback: (id: number) => void, errorCallback: (message: string) => void) => void;
    updateUserGroup: (group: IUserGroupsAddUpdateModel, successCallback: () => void, errorCallback: (message: string) => void) => void;
    deleteUserGroup: (groupId: number, successCallback: () => void) => void;
    getUsersInGroup: (groupId: number, successCallback: (data: any) => void) => void;
    applyChanges: (value: any, successCallback: (data: any) => void) => void;
    validateUserGroupUpdate: (usersRemovedFromGroup: any, callback: (data: boolean) => void) => void;
    requestAllUsers: (reload: boolean, pageIndex: number, pageSize: number, searchString: string, newUserIds: string) => void;
    page: PageProps;
    searchText: string;
    newUserIds: number[];
}

export const getRoles = (roleList: dropdownList[]): IRoles => {
    const roles: IRoles = {};
    if (roleList) {
        roleList.forEach((role: dropdownList, index) => roles[role.value] = role.name);
    }
    return roles;
}

export interface apiResponse {
    isSuccess: boolean,
    errorMessage: string,
    id: number,
}

export type KnownAction =
    DispatchAction |
    NotificationAction;

export type DispatchAction =
    RequestUserGroupListAction |
    ReceiveUserGroupListAction |
    RequestUsersInGroupsAction |
    RequestUsersInGroupsSuccessAction;

interface RequestUserGroupListAction {
    type: actionTypes.REQUEST_USER_GROUP_LIST;
}

interface ReceiveUserGroupListAction {
    type: actionTypes.RECEIVE_USER_GROUP_LIST;
    userGroupList: IUserGroups[]
}

interface RequestUsersInGroupsAction {
    type: actionTypes.REQUEST_USERS_IN_GROUP;
}
interface RequestUsersInGroupsSuccessAction {
    type: actionTypes.REQUEST_USERS_IN_GROUP_SUCCESS;
    usersInGroupList: IGroupsUserList;
}

export const getReorderedGroups = (groups: IUserGroups[], newGroupIds: number[]) => {
    newGroupIds.forEach((id, i) => {
        const index = groups.findIndex(x => x.groupId === id);
        if (index != -1) {
            groups.splice(0, 0, groups.splice(index, 1)[0]);
        }
    });
    return groups;
}