import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Tab, Modal, Button } from 'react-bootstrap';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import './MFASettingsModal.style.scss';
import { MFASettingsConstants } from 'src/helper/Constants';
import { ICompanyMfaSettings, IMFAIPAddressModel, IMFASettingsState, initialcMfaSettings } from '../MFASettings.model';
import { ValidateTenDigitNumber, ValidateTrustedDeviceExpiryDays } from 'src/helper/Validations';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import MFAUserSettings from '../MFAUserSettings';
import MFAIPSettings from '../MFAIPSettings';

interface MFASettingsModalProps {
	cMfaSettings: ICompanyMfaSettings;
	updateMfaSettings(cMfaSettings: ICompanyMfaSettings, resourceId?: string): void;
	onHide: () => void;
	mfaSettings: IMFASettingsState;
	saveMFAIPAddress(imfaIPAddressModel: IMFAIPAddressModel, callback: () => void): void;
	deleteMFAIPAddress(ipAddressId: number, callback: () => void): void;
	updateMFAIPAddress(imfaIPAddressModel: IMFAIPAddressModel, callback: () => void): void;
	requestMFAIPAddress(reload: boolean): void;
	requestMFAIPUser(query: string): void;
	updateMFAEnableForAllUser(mfaEnable: boolean, callback: () => void): void;
	updateMFAEnableForUser(userId: number, mfaEnable: boolean, callback: () => void): void;
}
const MFASettingsModal: FC<MFASettingsModalProps> = (props) => {
	const {
		cMfaSettings,
		updateMfaSettings,
		onHide,
		mfaSettings,
		saveMFAIPAddress,
		deleteMFAIPAddress,
		updateMFAIPAddress,
		requestMFAIPAddress,
		requestMFAIPUser,
		updateMFAEnableForAllUser,
		updateMFAEnableForUser,
	} = props;

	const [activeUserTab, setActiveUserTab] = useState(true);
	const [settings, setSettings] = useState(initialcMfaSettings);
	const dispatch = useDispatch();

	useEffect(() => {
		setSettings(structuredClone(cMfaSettings));
	}, []);

	const handleSkipMfa = (event: any) => {
		let temp = structuredClone(settings);
		temp.skipMfaForIP = event.currentTarget.checked;
		setSettings(temp);
	};

	const handleRememberTrustedDevice = (event: any) => {
		let temp = structuredClone(settings);
		temp.isAllowUsertoRememberTrustedDevice = event.currentTarget.checked;
		if (!event.currentTarget.checked) {
			temp.trustedDeviceExpiryDays = 0;
		}
		setSettings(temp);
	};

	const onChangetrustedDeviceExpiryDays = (event: any) => {
		let temp = structuredClone(settings);
		if (ValidateTenDigitNumber(event)) {
			temp.trustedDeviceExpiryDays = event.target.value;
			setSettings(temp);
		}
	};

	const onSave = () => {
		let temp = structuredClone(settings);
		if (!temp.isAllowUsertoRememberTrustedDevice || ValidateTrustedDeviceExpiryDays(temp.trustedDeviceExpiryDays)) {
			dispatch({
				type: loaderTypes.LOADING,
				show: true,
				text: 'Updating Multi-factor Authentication Settings...',
			});
			updateMfaSettings(temp, 'SUITE_Secr_MltFacAuth_IntrnlUsrs_TglEnblOrDisbl');
			onHide();
		} else {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: MFASettingsConstants.Validate.TrustedDeviceExpiryDays,
			});
		}
	};

	const handleSelect = (key: any) => {
		setActiveUserTab(key === 'users' ? true : false);
	};

	return (
		<>
			<Modal.Body className='ssoModalBody'>
				<Tabs
					defaultActiveKey='users'
					id='mfausers'
					onSelect={handleSelect}>
					<Tab
						eventKey='users'
						title='Users'>
						<LoadingOverlay>
							<MFAUserSettings
								mfaSettings={mfaSettings}
								requestMFAIPUser={requestMFAIPUser}
								updateMFAEnableForAllUser={updateMFAEnableForAllUser}
								updateMFAEnableForUser={updateMFAEnableForUser}
							/>
							<Loader loading={mfaSettings.isLoading} />
						</LoadingOverlay>
					</Tab>
					<Tab
						eventKey='settings'
						title='Settings'>
						<LoadingOverlay>
							<MFAIPSettings
								mfaSettings={mfaSettings}
								saveMFAIPAddress={saveMFAIPAddress}
								deleteMFAIPAddress={deleteMFAIPAddress}
								requestMFAIPAddress={requestMFAIPAddress}
								updateMFAIPAddress={updateMFAIPAddress}
								handleSkipMfa={handleSkipMfa}
								handleRememberTrustedDevice={handleRememberTrustedDevice}
								onChangetrustedDeviceExpiryDays={onChangetrustedDeviceExpiryDays}
								settings={settings}
							/>
							<Loader loading={mfaSettings.isLoading} />
						</LoadingOverlay>
					</Tab>
				</Tabs>
			</Modal.Body>
			<Modal.Footer className='ssoModalFooter'>
				<Button
					variant='secondary'
					className='btn-white'
					onClick={onHide}
					data-test-auto='9ecbc113-d4f5-4010-937d-639e46368d34'>
					Cancel
				</Button>
				<Button
					className={activeUserTab ? 'button-hide' : ''}
					variant='primary'
					onClick={onSave}
					data-test-auto='ce1a027d-0bf0-4f4e-96af-dac69a12123d'>
					Save
				</Button>
			</Modal.Footer>
		</>
	);
};

export default MFASettingsModal;
