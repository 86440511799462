import './index.scss';

import React, { type FC } from 'react';

interface ButtonWithTextIconProps {
	className?: string;
	label: string;
	icon?: string;
	SvgIcon?: JSX.Element;
	onClick: () => void;
	disabled?: boolean;
	'data-resource-id'?: string;
	'data-test-auto'?: string;
	bordered?: boolean;
	disabledText?: string;
}

const ButtonWithTextIcon: FC<ButtonWithTextIconProps> = (props) => {
	const {
		className = '',
		label = '',
		icon = null,
		onClick = () => null,
		disabled = false,
		bordered = false,
		'data-resource-id': dataResourceId,
		'data-test-auto': dataTestAuto = '',
		SvgIcon,
		disabledText = 'Please make changes to enable button',
	} = props;

	return (
		<div data-resource-id={dataResourceId}>
			<button
				type='button'
				className={`button ${className} ${disabled ? 'disabled_button' : ''} ${
					bordered && !disabled ? 'button_border' : ''
				}`}
				onClick={onClick}
				disabled={disabled}
				title={`${disabled ? disabledText : ''}`}
				data-test-auto={dataTestAuto}>
				{SvgIcon != null && SvgIcon}
				{icon && (
					<img
						src={icon}
						className='button_icon'
					/>
				)}
				{label}
			</button>
		</div>
	);
};

export default ButtonWithTextIcon;
