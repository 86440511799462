
export const download = (url: string, fileName?: string): void => {
    const link: HTMLAnchorElement = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    if (fileName) {
        link.download = fileName;
    }
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
        document.body.removeChild(link);
    }, 100);
};

export const getFileName = (response : any ): string => {
    const contentDisposition = response.headers["content-disposition"];
    const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
    let fileName='';
    if (fileNameMatch && fileNameMatch.length > 1) {
        fileName = fileNameMatch[1];
    }
    return fileName;
}
