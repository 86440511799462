import { connect } from 'react-redux';
import * as UsersStore from '../../modules/userManagement/users/Users.store';
import { ApplicationState } from '../../store/index';
import UserManagement from './index';
import * as UserGroupStore from '../../modules/userManagement/userGroups/user-groups.apis';
import * as DropdownListStore from '../../store/common/dropdown/dropdown-list.apis';
import * as RolesStore from '../../modules/userManagement/rolesAndPermissions/roles-and-permissions.api';
import * as ResourceIdStore from '../common/resource-validator/resource-validator.api';
export default connect(
	(state: ApplicationState) => ({
		auth: state.userAuth,
		usersList: state.usersList,
		profile: state.userProfile,
		roleDropdownList: state.dropdownListData.roles,
		userGroupList: state.userGroupList,
		rolesData: state.rolesData,
	}),
	{
		...UsersStore.actionCreators,
		...UserGroupStore.actionCreators,
		...DropdownListStore.actionCreators,
		...RolesStore.actionCreators,
		...ResourceIdStore.actionCreators,
	},
)(UserManagement as React.FC);
