import React from 'react';

const ExportIcon2: React.FC = () => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_4603_154025)'>
				<rect
					width='16'
					height='16'
					fill='white'
					fillOpacity='0.01'
				/>
				<g clipPath='url(#clip1_4603_154025)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M10 12.499C10 12.6316 9.94732 12.7588 9.85355 12.8526C9.75979 12.9463 9.63261 12.999 9.5 12.999H1.5C1.36739 12.999 1.24021 12.9463 1.14645 12.8526C1.05268 12.7588 1 12.6316 1 12.499V3.49902C1 3.36642 1.05268 3.23924 1.14645 3.14547C1.24021 3.0517 1.36739 2.99902 1.5 2.99902H9.5C9.63261 2.99902 9.75979 3.0517 9.85355 3.14547C9.94732 3.23924 10 3.36642 10 3.49902V5.49902C10 5.63163 10.0527 5.75881 10.1464 5.85258C10.2402 5.94634 10.3674 5.99902 10.5 5.99902C10.6326 5.99902 10.7598 5.94634 10.8536 5.85258C10.9473 5.75881 11 5.63163 11 5.49902V3.49902C11 3.1012 10.842 2.71967 10.5607 2.43836C10.2794 2.15706 9.89782 1.99902 9.5 1.99902H1.5C1.10218 1.99902 0.720644 2.15706 0.43934 2.43836C0.158035 2.71967 0 3.1012 0 3.49902V12.499C0 12.8968 0.158035 13.2784 0.43934 13.5597C0.720644 13.841 1.10218 13.999 1.5 13.999H9.5C9.89782 13.999 10.2794 13.841 10.5607 13.5597C10.842 13.2784 11 12.8968 11 12.499V10.499C11 10.3664 10.9473 10.2392 10.8536 10.1455C10.7598 10.0517 10.6326 9.99902 10.5 9.99902C10.3674 9.99902 10.2402 10.0517 10.1464 10.1455C10.0527 10.2392 10 10.3664 10 10.499V12.499Z'
						fill='#05386B'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M15.854 8.35302C15.9006 8.30658 15.9375 8.2514 15.9627 8.19066C15.9879 8.12991 16.0009 8.06479 16.0009 7.99902C16.0009 7.93326 15.9879 7.86813 15.9627 7.80739C15.9375 7.74664 15.9006 7.69147 15.854 7.64502L12.854 4.64502C12.7601 4.55114 12.6328 4.49839 12.5 4.49839C12.3672 4.49839 12.2399 4.55114 12.146 4.64502C12.0521 4.73891 11.9994 4.86625 11.9994 4.99902C11.9994 5.1318 12.0521 5.25914 12.146 5.35302L14.293 7.49902H5.5C5.36739 7.49902 5.24021 7.5517 5.14645 7.64547C5.05268 7.73924 5 7.86642 5 7.99902C5 8.13163 5.05268 8.25881 5.14645 8.35258C5.24021 8.44635 5.36739 8.49902 5.5 8.49902H14.293L12.146 10.645C12.0521 10.7389 11.9994 10.8662 11.9994 10.999C11.9994 11.1318 12.0521 11.2591 12.146 11.353C12.2399 11.4469 12.3672 11.4997 12.5 11.4997C12.6328 11.4997 12.7601 11.4469 12.854 11.353L15.854 8.35302Z'
						fill='#05386B'
					/>
				</g>
			</g>
			<defs>
				<clipPath id='clip0_4603_154025'>
					<rect
						width='16'
						height='16'
						fill='white'
					/>
				</clipPath>
				<clipPath id='clip1_4603_154025'>
					<rect
						width='16'
						height='16'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default ExportIcon2;
