import { Action, Reducer } from "redux";
import { produce } from "immer";
import { DispatchActions, IPasswordState } from "./password.types";
import { actionTypes } from "./password.actions";

export interface IPasswordData {
  passwordState: IPasswordState;
  isLoading: boolean;
}

const unloadedState: IPasswordData = {
  passwordState: {
    password: "",
    newPassword: "",
  },
  isLoading: false,
};

export const reducer: Reducer<IPasswordData> = produce(
  (
    draftState: IPasswordData = unloadedState,
    incomingAction: Action
  ) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
      case actionTypes.UPDATE_PASSWORD:
        draftState.isLoading = true;
        break;
    }
    return draftState;
  }
);
