const createPropsFromObject = (responseObject: any, listOfProps: any) => {
    let propList: any = [];
    try {
        if (responseObject instanceof Array) {
            propList = responseObject.map((obj) => {
                const newObj: any = {};
                listOfProps.forEach((ele: string) => {
                    newObj[`${ele}`] = obj[`${ele}`];
                });
                return newObj;
            });
        }
    } catch (err: any) {
        console.error(err);
        throw new Error(err);
    }
    return propList;
};

export default createPropsFromObject