import { useEffect, useState } from 'react';

import { type IIpFilterHookReturn, type IIpFilterOptionsProps } from './ip-filtering-options.types';

const useIpFiltering = (props: IIpFilterOptionsProps): IIpFilterHookReturn => {
	const {
		isFilteringEnabled,
		requestIpFilterOption,
		updateIpFilterOption,
		getClientIpAddress,
		clientIpAddress,
		ipFilterList: { listData = [] } = {},
	} = props;

	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [clientIp, setClientIp] = useState<string>('');

	useEffect(() => {
		requestIpFilterOption();
		getClientIpAddress();
	}, []);

	useEffect(() => {
		setIsChecked(isFilteringEnabled);
	}, [isFilteringEnabled]);

	useEffect(() => {
		setClientIp(clientIpAddress);
	}, [clientIpAddress]);

	const handleChangeOptionState = (): void => {
		if (listData.length === 0) return;

		updateIpFilterOption(!isChecked);
	};

	return {
		isChecked,
		clientIp,
		updateIpFilterOption,
		handleChangeOptionState,
	};
};

export { useIpFiltering };
