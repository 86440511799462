import React from 'react';

const Print: React.FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			fill='none'
			viewBox='0 0 16 16'>
			<g clipPath='url(#clip0_4866_154069)'>
				<path
					fill='#fff'
					fillOpacity='0.01'
					d='M0 0H16V16H0z'></path>
				<g
					fill='#05386B'
					clipPath='url(#clip1_4866_154069)'>
					<path d='M2.5 7.999a.5.5 0 100-1 .5.5 0 000 1z'></path>
					<path d='M5 .999a2 2 0 00-2 2v2H2a2 2 0 00-2 2v3a2 2 0 002 2h1v1a2 2 0 002 2h6a2 2 0 002-2v-1h1a2 2 0 002-2v-3a2 2 0 00-2-2h-1v-2a2 2 0 00-2-2H5zm-1 2a1 1 0 011-1h6a1 1 0 011 1v2H4v-2zm1 5a2 2 0 00-2 2v1H2a1 1 0 01-1-1v-3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-1 1h-1v-1a2 2 0 00-2-2H5zm7 2v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-3a1 1 0 011-1h6a1 1 0 011 1z'></path>
				</g>
			</g>
			<defs>
				<clipPath id='clip0_4866_154069'>
					<path
						fill='#fff'
						d='M0 0H16V16H0z'></path>
				</clipPath>
				<clipPath id='clip1_4866_154069'>
					<path
						fill='#fff'
						d='M0 0H16V16H0z'></path>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Print;
