import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/Constants';

import { IApiAccessSettingRequest, IApiAccessSettingResponse } from './ApiAccessSetting.model';
import { logger } from 'src/oidcClient/authProvider';

export const getApiSetting = (
	callback: (responseObject: IApiAccessSettingResponse) => void): void => {
	const responseObject: IApiAccessSettingResponse = {
		error: false,
		isApiAccessEnabled: false,
	};
	axios
		.get(`${API_BASE_URL}/api/developer/api-access`, {
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
			}
		})
		.then((response: AxiosResponse) => {
			responseObject.error = false;
			responseObject.isApiAccessEnabled = response.data?.isApiAccessEnabled;
			callback(responseObject);
		})
		.catch((error) => {
            logger && logger.trackWarning('Error while getApiSetting', { 'Error': error?.response?.data});
			responseObject.error = true;
			callback(responseObject);
		});
};

export const updateApiSetting = (
	resourceId: string,
	requestObject: IApiAccessSettingRequest,
	callback: (response: IApiAccessSettingResponse) => void,
): void => {
	const responseObject: IApiAccessSettingResponse = {
		error: false,
		isApiAccessEnabled: false,
	};
	axios
		.put(`${API_BASE_URL}/api/developer/api-access`, requestObject, {
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
				'X-Resource-Id': resourceId,
			},
			withCredentials: true
		})
		.then((response: AxiosResponse) => {
			responseObject.error = false;
			responseObject.isApiAccessEnabled = response.data?.isApiAccessEnabled;
			callback(responseObject);
		})
		.catch((error) => {
            logger && logger.trackWarning('Error while updateApiSetting', { 'Error': error?.response?.data});
			responseObject.error = true;
			callback(responseObject);
		});
};
