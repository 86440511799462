import { Reducer } from 'redux';
import axios, { AxiosResponse } from 'axios';

import { AppThunkAction } from 'src/store/index';
import { ITaxSoftwareSetting, initialState, KnownAction } from './TaxSoftwareSetting.model';
import { actionTypes } from './TaxSoftwareSetting.actions';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { API_BASE_URL, IPAddressConstant, UserSettingsConstants } from 'src/helper/Constants';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {
	requestTaxSoftwareSetting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: actionTypes.REQUEST_TAXSOFTWARE_SETTING, isLoading: true });
		axios.get(API_BASE_URL + '/api/tax-software')
			.then((response: AxiosResponse<ITaxSoftwareSetting>) => {
				const { data } = response;
				dispatch({ type: actionTypes.RECEIVE_TAXSOFTWARE_SETTING, taxSoftwareSetting: data, isLoading: false });
				dispatch({
					type: loaderTypes.LOADING,
					show: false,
					text: '',
				});
			})
			.catch(function (error) {
				logger && logger.trackWarning('Error while requestTaxSoftwareSetting', { 'Error': error?.response?.data});
				const statusMessage: any = error.statusText;
				if (typeof (statusMessage) === "string") {
					dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
				}
			});
	},

	updateTaxSoftwareSetting: (setting: ITaxSoftwareSetting, resourceId: string, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		axios.put(API_BASE_URL + '/api/tax-software', setting, {
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
				'X-Resource-Id': resourceId,
			},
			withCredentials: true,
		})
			.then((response: AxiosResponse) => {
				dispatch({ type: actionTypes.UPDATE_TAXSOFTWARE_SETTING, taxSoftwareSetting: { ...setting } });
				callback();
			})
			.catch(function (error) {
				logger && logger.trackWarning('Error while updateTaxSoftwareSetting', { 'Error': error?.response?.data});
				const statusMessage: any = error.statusText;
				if (typeof (statusMessage) === "string") {
					dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
				}
			});
	}
}

const unloadedState: ITaxSoftwareSetting = initialState;

export const reducer: Reducer<ITaxSoftwareSetting> = (state = unloadedState, incomingAction) => {
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case actionTypes.REQUEST_TAXSOFTWARE_SETTING:
			return {
				...state,
				isLoading: action.isLoading
			} as ITaxSoftwareSetting;
		case actionTypes.RECEIVE_TAXSOFTWARE_SETTING:
			return {
				...state,
				taxSoftware: action.taxSoftwareSetting.taxSoftware,
				isLoading: action.isLoading
			} as ITaxSoftwareSetting;
		case actionTypes.UPDATE_TAXSOFTWARE_SETTING:
			return {
				...state,
				taxSoftware: action.taxSoftwareSetting.taxSoftware,
			} as ITaxSoftwareSetting;
	}
	return state;
};