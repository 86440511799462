import React, { FC, useRef, useState } from 'react';
import { Alert, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { IPasswordState } from '../../../pages/common/password/password.types';
import { savePassword } from '../../../pages/common/password/password.apis';
import { AppNotifier } from '../../../helper/AppNotifier';
import { getPasswordOptionLabel } from '../../../helper/HelperFunctions';
import { ChangePasswordConstants, ChangeSecurityConstants, ValidationContants } from '../../../helper/Constants';
import * as Validation from '../../../helper/Validations';
import ConfirmModal from '../ui/modals/confirmModal';
import { IMyAccountLayout } from './myAccountLayout';
import { ApplicationState } from '../../../store';
import { EyeIcon, EyeSlashIcon } from 'src/components/svg/IconCollection';
import { PasswordOptions } from 'src/modules/securitySettings/PasswordPolicy/PasswordPolicySettings.model';
import { WithLogout, WithLogoutProps } from 'src/oidcClient/WithLogout';

interface IVisibility {
	current: boolean;
	new: boolean;
	confirm: boolean;
}
interface IPasswordForm {
	current: string;
	new: string;
	confirm: string;
}
interface IMyPasswordState {
	alert: string;
	message: string;
	showAlert: boolean;
	saving: boolean;
}

const Password: FC<IMyAccountLayout & WithLogoutProps> = (props) => {
	const ref = useRef<HTMLFormElement>(null);
	const [validated, setValidated] = useState<boolean>(false);
	const [toggleVisibility, setToggleVisibility] = useState<IVisibility>({ current: false, new: false, confirm: false });
	const [passwordData, setPasswordData] = useState<IPasswordForm>({ current: '', new: '', confirm: '' });
	const passwordPolicySettings = useSelector((state: ApplicationState) => state.passwordPolicySettings);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [state, setState] = useState<IMyPasswordState>({
		alert: '',
		message: '',
		showAlert: false,
		saving: false,
	});
	const dispatch = useDispatch();

	const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const form = ref.current;
		if (form) {
			setValidated(false);
			submitChanges();
		} else {
			setValidated(true);
		}
	};

	const handleVisibility = (name: string) => {
		setToggleVisibility({
			...toggleVisibility,
			[name]: !toggleVisibility[name as keyof IVisibility],
		});
	};

	const handleOnChange: React.ChangeEventHandler = (e) => {
		const element = e.target as HTMLInputElement;
		const data = { ...passwordData, [element.id]: element.value };
		setPasswordData(data);
		setState({
			alert: '',
			message: '',
			showAlert: false,
			saving: false,
		});
	};
	const passwordoptions = () => {
		const items: any[] = [];
		const selectedOptions = Object.keys(PasswordOptions)
			.filter((key) => !isNaN(Number(key)))
			.map((key) => Number(key))
			.filter((option) => option > 0 && (option & passwordPolicySettings.passwordSettings.passwordOptions) === option);

		selectedOptions.forEach((option) => {
			const label = getPasswordOptionLabel(option);
			items.push(<li> {label}</li>);
		});

		return <div>{items}</div>;
	};

	const submitChanges = () => {
		if (!passwordData.current || passwordData.current.trim() === '') {
			setState({
				...state,
				message: ValidationContants.OldPasswordWarning,
				alert: 'warning',
				showAlert: true,
			});
			return false;
		}

		if (!passwordData.new || passwordData.new.trim() === '') {
			setState({
				...state,
				message: ValidationContants.NewPasswordWarning,
				alert: 'warning',
				showAlert: true,
			});
			return false;
		}

		if (!passwordData.confirm || passwordData.confirm.trim() === '') {
			setState({
				...state,
				message: ValidationContants.ConfirmPasswordWarning,
				alert: 'warning',
				showAlert: true,
			});
			return false;
		}

		const validationResult = Validation.validatePasswordBasedOnPolicy(
			passwordData.new,
			passwordPolicySettings.passwordSettings,
		);
		if (validationResult.isError) {
			setState({
				...state,
				message: validationResult.errorDescription,
				alert: 'warning',
				showAlert: true,
			});
			return false;
		}

		if (passwordData.new.trim() === passwordData.confirm.trim()) {
			setShowConfirmationModal(true);
		} else {
			setState({
				...state,
				message: ValidationContants.PasswordNotMatchedWarning,
				alert: 'warning',
				showAlert: true,
			});
			return false;
		}
	};

	const handlePasswordConfirmation = () => {
		setShowConfirmationModal(false);
		setState({
			alert: '',
			message: '',
			showAlert: false,
			saving: true,
		});
		const passwordstate: IPasswordState = { password: passwordData.current, newPassword: passwordData.new };
		dispatch(
			savePassword(passwordstate, false, (response: any, error: any) => {
				setState({ ...state, saving: false });
				if (error) {
					AppNotifier.Error(error.response.data ?? ChangeSecurityConstants.SECURITY_UPDATE_FAILED);
				} else {
					if (response) {
						AppNotifier.Success(ChangeSecurityConstants.SECURITY_UPDATE_SUCCESS);
						setState({
							...state,
							alert: '',
							message: '',
							showAlert: false,
						});
						setPasswordData({
							current: '',
							new: '',
							confirm: '',
						});
						props.onHide();
						props.onLogout();
					} else {
						setState({
							...state,
							message: response,
							alert: 'warning',
							showAlert: true,
						});
					}
				}
			}),
		);
	};

	const hideModal: React.MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		e.stopPropagation();
		props.onHide();
	};
	return (
		<div>
			<h3 className='heading-blue-1'>{ChangePasswordConstants.TITLE}</h3>
			<p className='text-gray-description'>{ChangePasswordConstants.DESCRIPTION}</p>
			<LoadingOverlay
				style={{ height: '100%' }}
				className='d-flex flex-column'>
				<Loader loading={state.saving} />
				{state.showAlert && (
					<Alert variant={state.alert}>
						<i
							className='fas fa-exclamation-triangle'
							style={{ marginRight: '5px' }}></i>
						{state.message}
					</Alert>
				)}
				<Row>
					<Col>
						<Form
							ref={ref}
							id='change-password-form'
							noValidate
							validated={validated}
							className='me-3'>
							<Form.Group controlId='current'>
								<Form.Label className='fontWeight700'>{ChangePasswordConstants.LABEL_CURRENT_PASSWORD}</Form.Label>
								<InputGroup className='mb-3 input-with-toggle'>
									<Form.Control
										size='sm'
										type={toggleVisibility['current'] ? 'text' : 'password'}
										onChange={handleOnChange}
										placeholder={ChangePasswordConstants.PLACEHOLDER_CURRENT_PASSWORD}
									/>
									<InputGroup.Text onClick={() => handleVisibility('current')}>
										{toggleVisibility['current'] ? <EyeIcon /> : <EyeSlashIcon />}
									</InputGroup.Text>
								</InputGroup>
							</Form.Group>

							<Form.Group controlId='new'>
								<Form.Label className='fontWeight700'>{ChangePasswordConstants.LABEL_NEW_PASSWORD}</Form.Label>
								<InputGroup className='mb-3 input-with-toggle'>
									<Form.Control
										size='sm'
										type={toggleVisibility['new'] ? 'text' : 'password'}
										onChange={handleOnChange}
										placeholder={ChangePasswordConstants.PLACEHOLDER_NEW_PASSWORD}
									/>
									<InputGroup.Text onClick={() => handleVisibility('new')}>
										{toggleVisibility['new'] ? <EyeIcon /> : <EyeSlashIcon />}
									</InputGroup.Text>
								</InputGroup>
							</Form.Group>

							<Form.Group controlId='confirm'>
								<Form.Label className='fontWeight700'>{ChangePasswordConstants.LABEL_CONFIRM_PASSWORD}</Form.Label>
								<InputGroup className='mb-3 input-with-toggle'>
									<Form.Control
										size='sm'
										type={toggleVisibility['confirm'] ? 'text' : 'password'}
										onChange={handleOnChange}
										placeholder={ChangePasswordConstants.PLACEHOLDER_CONFIRM_PASSWORD}
									/>
									<InputGroup.Text onClick={() => handleVisibility('confirm')}>
										{toggleVisibility['confirm'] ? <EyeIcon /> : <EyeSlashIcon />}
									</InputGroup.Text>
								</InputGroup>
							</Form.Group>
						</Form>
					</Col>
					{passwordPolicySettings && (
						<Col
							className='password-policies'
							xs={5}>
							<div className='mx-3 mb-2'>
								<b>
									<u>{ChangePasswordConstants.PASSWORD_POLICY_TITLE}</u>
								</b>
							</div>
							<ul>
								<li className='margin-bottom-10-px'>
									{ChangePasswordConstants.PASSWORD_LENGTH}
									{passwordPolicySettings.passwordSettings.length}
								</li>
								{passwordPolicySettings.passwordSettings.passwordOptions != PasswordOptions.None && (
									<li>
										{ChangePasswordConstants.PASSWORD_REQUIRED}
										<ul className='margin-top-10-px'>{passwordoptions()}</ul>
										{(passwordPolicySettings.passwordSettings.passwordOptions & PasswordOptions.SpecialCharacters) ===
											PasswordOptions.SpecialCharacters && (
											<OverlayTrigger overlay={<Tooltip>{ChangePasswordConstants.PASSWORD_CHARACTERS_LIST}</Tooltip>}>
												<div className='margin-top-20-px tooltip-target'>
													{ChangePasswordConstants.PASSWORD_SPECIAL_CHARACTERS}
												</div>
											</OverlayTrigger>
										)}
									</li>
								)}
							</ul>
						</Col>
					)}
				</Row>
				<div className='mt-auto d-flex justify-content-end'>
					<Button
						data-test-auto='71675d6a-a9ae-4e67-8d42-bf4a5f6b77de'
						variant='outline-secondary'
						className='me-2'
						onClick={hideModal}>
						{ChangePasswordConstants.CANCEL_BUTOTN}
					</Button>
					<Button
						data-test-auto='ba779241-c37b-4c7e-af30-8b0bb67f0c57'
						variant='primary'
						onClick={handleSubmit}>
						{ChangePasswordConstants.OK_BUTTON}
					</Button>
				</div>
				<ConfirmModal
					show={showConfirmationModal}
					title={ChangeSecurityConstants.SECURITY_CHANGE_MODAL_TITLE}
					confirmButtonName={ChangeSecurityConstants.CONFIRM_BUTTON_NAME}
					onSubmit={handlePasswordConfirmation}
					onHide={() => {
						setShowConfirmationModal(false);
					}}
					size='sm'
					className='modal-confirm-delete'>
					<span>{ChangeSecurityConstants.SECURITY_CHANGE_LOGOUT_WARNING}</span>
				</ConfirmModal>
			</LoadingOverlay>
		</div>
	);
};

export default WithLogout(Password);
