import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as ResourceIdStore from '../../pages/common/resource-validator/resource-validator.api';
import * as UserPermissionStore from '../../modules/userPermission/UserPermission.store';
import UserPermission from '.';

export default connect(
	(state: ApplicationState) => ({
		ssoAccessRequestUsers: state.ssoAccessRequestUsers,
	}),
	{
		...ResourceIdStore.actionCreators,
		...UserPermissionStore.actionCreators,
	},
)(UserPermission as React.FC);
