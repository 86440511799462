import ClientManagement from '.';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as ClientStore from '../../modules/clientManagement/Client.store';
import * as ResourceIdStore from '../../pages/common/resource-validator/resource-validator.api';

export default connect(
    (state: ApplicationState) => ({
        clientsList: state.clientsList,
    }),
    {
        ...ClientStore.actionCreators,
        ...ResourceIdStore.actionCreators
    }
)(ClientManagement as React.FC);
