import React, { useEffect, useState } from 'react';
import Select, { GroupBase } from 'react-select';
import { ValueComponent, OptionComponent, MenuComponent, MenuListComponent } from './helper';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { ICustomDropdownOption } from 'src/components/common/customDropdown/CustomDropdownWithCustomOptions';

interface IDropdownProps {
	selectedOptions: ICustomDropdownOption[];
	setSelectedOptions: (option: ICustomDropdownOption[]) => void;
	options: ICustomDropdownOption[];
	clearable?: boolean;
	disabled?: boolean;
	searchable?: boolean;
	customPlaceHolder?: string;
	id: string;
	className?: string;
	height?: number;
	title?: string;
	borderColor?: string | undefined;
	handleOnBlur?: () => void;
	requestAllUsers: (selectedOfficeLocations: ICustomDropdownOption[]) => void;
}

const Filter: React.FC<IDropdownProps> = (props) => {
	const {
		options,
		clearable,
		disabled,
		customPlaceHolder,
		id,
		className,
		height,
		searchable,
		title,
		selectedOptions,
		setSelectedOptions,
		borderColor,
		handleOnBlur,
		requestAllUsers,
	} = props;

	const [tempOfficeLocations, setTempOfficeLocations] = useState<ICustomDropdownOption[]>([]);
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	const data = {
		value: 0,
		label: `Select all (${options.length})`,
	};

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			background: disabled ? '#C4C6C8' : '#fff',
			borderColor: borderColor ?? '#898D91',
			minHeight: `${height ?? 38}px`,
			height: `${height ?? 38}px`,
			borderRadius: '2px',
			boxShadow: state.isFocused ? null : null,
			'&:hover': {
				borderColor: borderColor ?? '#898D91',
			},
		}),
		valueContainer: (provided: any, state: any) => ({
			...provided,
			height: `${height ?? 36}px`,
			padding: '0 8px',
			fontSize: '14px',
			marginBottom: '1px',
			width: '58px',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		}),
		singleValue: (provided: any, state: any) => ({
			...provided,
			marginBottom: '1px',
		}),
		input: (provided: any, state: any) => ({
			...provided,
			margin: '0px',
			padding: '0px',
		}),
		placeholder: (provided: any, state: any) => ({
			...provided,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			color: '#212529',
			marginBottom: '1px',
		}),
		indicatorSeparator: (state: any) => ({
			display: 'none',
		}),
		indicatorsContainer: (provided: any, state: any) => ({
			...provided,
			height: `${height ? height - 2 : 36}px`,
			alignSelf: 'center',
		}),
		option: (provided: any, state: any) => ({
			...provided,
			cursor: 'pointer',
			backgroundColor: state.isSelected ? '#fff' : 'transparent',
			color: state.isSelected ? 'inherit' : 'inherit',
			padding: '4px 12px',
		}),
		menuList: (provided: any) => ({
			...provided,
			maxHeight: '280px',
			paddingBottom: '5px',
		}),
		menu: (provided: any) => ({
			...provided,
			fontSize: '14px',
			width: 'calc(100% + 190px)',
			maxHeight: '330px',
			left: '-190px',
			marginTop: '-1px',
			border: '1px solid #898D91',
			borderTopLeftRadius: '0',
			borderTopRightRadius: '0',
			paddingBottom: '2px',
		}),
	};

	useEffect(() => {
		const inputElement = document.querySelector('#clickableInput') as HTMLInputElement;

		if (inputElement) {
			inputElement.style.height = '0';
		}
	});

	const customComponents: Partial<SelectComponents<any, boolean, GroupBase<any>>> = {
		IndicatorSeparator: null,
		Option: OptionComponent,
		ValueContainer: ValueComponent,
		DropdownIndicator: null,
		ClearIndicator: undefined,
		Menu: MenuComponent,
		MenuList: MenuListComponent,
	};

	const onMenuOpen = () => setIsMenuOpen(true);

	const onMenuClose = () => {
		setTempOfficeLocations(selectedOptions);
		setIsMenuOpen(false);
	};

	const handleChanges = (selections: ICustomDropdownOption[], action: any) => {
		const arr = [data, ...options];
		if (action.option?.value === 0) {
			if (tempOfficeLocations.some((x) => x.value === 0)) {
				setTempOfficeLocations([]);
			} else {
				setTempOfficeLocations(arr);
			}
		} else {
			if (tempOfficeLocations.some((x) => x.value === 0)) {
				setTempOfficeLocations(selections.filter((x) => x.value !== 0));
			} else if (selections.length === options.length) {
				setTempOfficeLocations(arr);
			} else {
				setTempOfficeLocations(selections);
			}
		}
	};

	const customProps = {
		tempOfficeLocations,
		setTempOfficeLocations,
		setSelectedOptions,
		setIsMenuOpen,
		requestAllUsers,
		selectedOptions,
	};

	return (
		<div className={`${disabled ? 'cursor-nodrop' : ''}`}>
			<Select
				id={id}
				inputId='clickableInput'
				onChange={handleChanges}
				value={tempOfficeLocations}
				options={[data, ...options]}
				isClearable={clearable ?? false}
				isDisabled={disabled ?? false}
				isSearchable={searchable ?? false}
				className={className + `${title ? ' custom-react-select-dropdown' : ''}`}
				placeholder={customPlaceHolder?.length ? customPlaceHolder : 'Select...'}
				styles={customStyles}
				components={customComponents}
				isMulti
				hideSelectedOptions={false}
				onBlur={handleOnBlur}
				closeMenuOnSelect={false}
				onMenuClose={onMenuClose}
				onMenuOpen={onMenuOpen}
				menuIsOpen={isMenuOpen}
				{...customProps}
			/>
		</div>
	);
};

export default Filter;
