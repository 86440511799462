import { IMenuSection } from "@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu";
import axios, { AxiosResponse, AxiosError } from "axios";
import { API_BASE_URL, ProductType } from "../../helper/Constants";
import { AppThunkAction } from "../../store";
import { actionTypes } from "./common.actions";
import { IWidgetData, KnownActions } from "./common.types";
import { StatusType } from "./notification/notification.reducer";
import { actionTypes as notificationTypes } from "./notification/notification.types";
import { logger } from 'src/oidcClient/authProvider';

export const fetchLoginHistory =
	(pageNumber: number, pageSize: number): AppThunkAction<KnownActions> =>
		(dispatch) => {
			dispatch({
				type: actionTypes.REQUEST_LOGIN_HISTORY
			});
			axios
				.get(`${API_BASE_URL}/api/login-history/${pageNumber}/${pageSize}`)
				.then((response: any) => {
					const data = response.data;
					dispatch({
						type: actionTypes.RECEIVE_LOGIN_HISTORY,
						payload: {
							deviceId: data?.deviceId || "",
							count: data?.loginHistories?.count || 0,
							loginHistory: data?.loginHistories?.items || []
						}
					});
				})
				.catch(function (error: AxiosError) {
					logger && logger.trackWarning('Error while fetchLoginHistory', { 'Error': error?.response?.data});
					if (error.response && error.response.status != 419 && error.response.status != 512) {
						dispatch({
							type: notificationTypes.NOTIFICATION,
							statusMessage: error.message,
							statusType: StatusType.Error,
						});
					}
				})
				.finally(() => {
					dispatch({
						type: actionTypes.COMPLETE_LOGIN_HISTORY,
					});
				});
		};

export const getWidgetData = (navigate: any): AppThunkAction<KnownActions> => dispatch => {
	dispatch({
		type: actionTypes.REQUEST_WIDGET_DATA,
	});
	axios
		.get(`${API_BASE_URL}/api/menu/widget-menu/${ProductType.SSSuitePortal}`)
		.then((response: AxiosResponse<IWidgetData[]>) => {
			const { data } = response;
			dispatch({
				type: actionTypes.RECEIVE_WIDGET_DATA,
				payload: data,
			});
			dispatch({
				type: actionTypes.CHANGE_ACTIVE_STATE,
				route: window.location.pathname
			});
		})
		.catch(function (error: Error) {
			logger && logger.trackWarning('Error while getWidgetData', { 'Error': error?.response?.data});
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusMessage: error.message,
				statusType: StatusType.Error,
			});
		})
		.finally(() => {
			dispatch({
				type: actionTypes.COMPLETE_WIDGET_DATA,
			});
		});
};

export const resetUserCache = (callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
	axios.get(`${API_BASE_URL}/api/user-management/reset-user-status-cache/${ProductType.SSSuitePortal}`)
		.then((response: AxiosResponse) => {
			callback && callback();
			dispatch({
				type: actionTypes.RESET_CACHE_API_CALLED
			});
		}).catch(function (error: AxiosError) {
			logger && logger.trackWarning('Error while resetUserCache', { 'Error': error?.response?.data});
			if (error.response && error.response.status != 419 && error.response.status != 512) {
				dispatch({
					type: actionTypes.RESET_CACHE_API_CALLED
				});
			}
		});
};

export const getWalkMeScript = (callback: (script: string) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {

	axios.get(`${API_BASE_URL}/api/company/walk-me-script`)
		.then((response: AxiosResponse<string>) => {

			const { data } = response;
			callback(data);

		}).catch(function (error: AxiosError) {
			logger && logger.trackWarning('Error while getWalkMeScript', { 'Error': error?.response?.data});
			if (error.response && error.response.status != 419 && error.response.status != 512) {
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusMessage: error.message,
					statusType: StatusType.Error,
				});
			}
		});
};

export const getSidemenuData = (): AppThunkAction<KnownActions> => dispatch => {
	axios
		.get(`${API_BASE_URL}/api/menu/product-left-menu/${ProductType.SSSuitePortal}`)
		.then((response: AxiosResponse<IMenuSection[]>) => {
			const { data } = response;
			dispatch({ type: actionTypes.RECEIVE_SIDEMENU_DATA, payload: data });
		})
		.catch(function (error: Error) {
			logger && logger.trackWarning('Error while getSidemenuData', { 'Error': error?.response?.data});
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusMessage: error.message,
				statusType: StatusType.Error,
			});
		})
};
