import { type FC, useState } from 'react';

import ButtonWithTextIcon from '../../../components/common/button-with-text-icon';
import ConfirmationPopup from '../../../components/common/confirmationPopup/index';
import { ReactComponent as EditIcon } from '../../../components/svg/pencil.svg';
import { ReactComponent as TrashIcon } from '../../../components/svg/trash.svg';
import { brandingSecResources } from '../../../helper/rbac-constants';
import { logger } from '../../../oidcClient/authProvider';
import ImageCard from '../partials/image-card/image-card';
import LogoUploadOverlay from '../partials/logo-upload-overlay/logo-upload-overlay';
import SectionDescription from '../partials/section-description/section-description';
import SectionHeader from '../partials/section-header/section-header';
import { useCompanyWhiteLogo } from './company-white-logo.hooks';
import { type ICompanyWhiteLogoProps } from './company-white-logo.types';
import styles from './index.module.scss';

const CompanyWhiteLogo: FC<ICompanyWhiteLogoProps> = (props) => {
	const {
		setShowWhiteLogoDeletePopup,
		showWhiteLogoDeletePopup,
		showLogoEditPopup,
		setShowLogoEditPopup,
		whiteLogoPath,
		isWhiteLogoLoading,
		deleteCompanyWhiteLogo,
		requestCompanyWhiteLogoUploadLink,
		whiteLogoUploadLink,
		getCompanyWhiteLogo,
		updateTempCompanyWhiteLogo,
	} = useCompanyWhiteLogo(props);

	const [isWhiteLogo, setIsWhiteLogo] = useState(false);
	const handleCancel = (): void => {
		getCompanyWhiteLogo();
		setShowLogoEditPopup(false);
	};

	const handleSave = (): void => {
		logger && logger.trackTrace('handleSave: Saving company white logo');
		getCompanyWhiteLogo();
		setShowLogoEditPopup(false);
	};

	return (
		<div className={styles.company_white_logo_wrapper}>
			<div className={styles.company_white_logo_header}>
				<SectionHeader title='Company Logo - White Version' />
			</div>
			<div className={styles.company_white_logo_description}>
				<SectionDescription
					text={`The white version of your chosen logo will be applied in all areas where a colored background is present. 
					Please ensure that the dimensions of the company's white logo do not exceed 300x100px.
				`}
				/>
			</div>
			<div className={styles.company_white_logo_card}>
				<ImageCard
					src={whiteLogoPath}
					alt='company white logo'
					isLoading={isWhiteLogoLoading}
					isWhiteLogo={true}
				/>
			</div>
			<div className={styles.company_white_logo_buttons_wrapper}>
				<ButtonWithTextIcon
					label='Edit'
					SvgIcon={<EditIcon />}
					className={styles.company_white_logo_action_buttons}
					data-test-auto='branding-management_company-logo-white_edit'
					onClick={() => {
						setShowLogoEditPopup(true);
						setIsWhiteLogo(true);
					}}
					data-resource-id={`${brandingSecResources.prefix}_${brandingSecResources.companyWhiteLogo}_${brandingSecResources.EditButton}`}
					bordered
				/>
				<ButtonWithTextIcon
					disabled={whiteLogoPath.length === 0}
					disabledText=''
					label='Delete'
					SvgIcon={<TrashIcon />}
					className={styles.company_white_logo_action_buttons}
					data-test-auto='branding-management_company-logo-white_delete'
					onClick={() => {
						setShowWhiteLogoDeletePopup(true);
					}}
					data-resource-id={`${brandingSecResources.prefix}_${brandingSecResources.companyWhiteLogo}_${brandingSecResources.DeleteButton}`}
					bordered
				/>
			</div>
			<ConfirmationPopup
				show={showWhiteLogoDeletePopup}
				actionButtonType={'danger'}
				cancelText='No, Keep It'
				header='Delete Logo'
				message={'Are you sure you wish to delete your company logo white version?'}
				okText='Yes, Delete It'
				onCancel={() => {
					setShowWhiteLogoDeletePopup(false);
				}}
				onOk={() => {
					deleteCompanyWhiteLogo();
					setShowWhiteLogoDeletePopup(false);
				}}
				onHide={() => {
					setShowWhiteLogoDeletePopup(false);
				}}
				actionDisabled={false}
			/>
			<LogoUploadOverlay
				logo={whiteLogoPath}
				show={showLogoEditPopup}
				requestUploadLink={requestCompanyWhiteLogoUploadLink}
				uploadLink={whiteLogoUploadLink}
				callbackAfterUpload={updateTempCompanyWhiteLogo}
				header='Upload Logo'
				dataTestPrefix='branding-management_company-logo-white_upload-logo'
				description={
					"Upload your Company Logo here. Please ensure that the dimensions of the company's white logo do not exceed 300x100px. "
				}
				onHide={() => {
					setShowLogoEditPopup(false);
				}}
				onCancel={() => {
					handleCancel();
				}}
				onSave={() => {
					handleSave();
				}}
				isWhiteLogo={isWhiteLogo}
			/>
		</div>
	);
};

export default CompanyWhiteLogo;
