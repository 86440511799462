export enum actionTypes {
    RECEIVE_USER_SIGNED_EVENT_SETTINGS,
    REQUEST_USER_SIGNED_EVENT_SETTINGS,
    REQUEST_EVENT_SERVICE_STATUS,
    RECEIVE_EVENT_SERVICE_STATUS,
    REQUEST_RESTART_USER_SIGNED_EVENT_SERVICE,
    RESPONSE_RESTART_USER_SIGNED_EVENT_SERVICE,
    REQUEST_PRODUCT_SUBSCRIPTION,
    RESPONSE_PRODUCT_SUBSCRIPTION,
    SHOW_HIDE_LOADING_WINDOW,    
}