import { actionTypes } from "./dropdown-list.actions";
import { actionTypes as notificationActionTypes } from "../../../pages/common/notification/notification.types";
import { API_BASE_URL } from "../../../helper/Constants";
import { StatusType } from "../../../pages/common/notification/notification.reducer";
import { AppThunkAction } from "../..";
import axios, { AxiosResponse } from "axios";
import {  dropdownList, KnownAction } from "./dropdown-list.types";
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {
    requestRoleDrowpdownList:(reload = false): AppThunkAction<KnownAction> => (dispatch, getState) =>{
        const state = getState();
        if (reload || state.dropdownListData.roles.length === 0) {
            dispatch({ type: actionTypes.REQUEST_ROLE_DROPDOWN_LIST });
            axios.get(API_BASE_URL + "/api/dropdown/roles")
                    .then((response: AxiosResponse<dropdownList[]>) => {
                        const { data } = response;
                        dispatch({
                            type: actionTypes.RECEIVE_ROLE_DROPDOWN_LIST, roleList: data
                        });
                    }).catch(function (error) {
                        logger && logger.trackWarning('Error while requestRoleDrowpdownList', { 'Error': error?.response?.data});
                        const statusMessage: any = error.statusText?.message ?? error.statusText;
                        if (typeof (statusMessage) === "string") {
                            dispatch({ type: notificationActionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                        }
                    });
        }
    },
}
