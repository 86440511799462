import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './BreadCrumbNav.module.scss';
import BreadCrumSeparator from '../svg/BreadCrumSeparator';

interface BreadCrumbNavProps {
    config: IBreadCrumbNav[];
}

interface IBreadCrumbNav {
    name: string;
    activePage: boolean;
    link: string;
}

let BreadCrumbNav: FC<BreadCrumbNavProps> = props => {
    const { config } = props;
    return (
        <nav className={styles.breadCrumbNav} aria-label="breadcrumb">
            <ol className={styles.breadcrumb}>
                {config.map((nav, index) => {
                    return (
                        <React.Fragment key={nav.name}>
                            <li className={`${styles.breadcrumbItem} ${nav.activePage ? styles.activePage : ''}`}>
                                {nav.activePage ? nav.name : <Link to={nav.link}>{nav.name}</Link>}
                            </li>
                            {index !== config.length - 1 && <BreadCrumSeparator />}
                        </React.Fragment>
                    );
                })}
            </ol>
        </nav>
    );
};

BreadCrumbNav = React.memo(BreadCrumbNav);

export default BreadCrumbNav;
