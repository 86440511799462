import { NotificationAction, StatusType } from "../../../pages/common/notification/notification.reducer";
import { actionTypes } from "./Users.actions";
import { IContactPersonViewModelList } from "./saveUserModal/SaveUserModal.model";

export interface IUserData {
    isLoading: boolean;
    users: IUserModel[];
    count: number;
}
export interface PageProps {
    pageIndex: number;
    pageSize: number;
}
export interface IUserListInfo {
    items: IUserModel[];
    count: number;
}
export interface IUserModel {
    id: number;
    firstName: string;
    middleName: string;
    lastName: string;
    phoneNumber: string;
    extension: string;
    ptin: string;
    email: string;
    confirmEmail: string;
    faxNumber: string;
    title: string;
    customUserGroup: IUserGroups[];
    groups: IUserGroups[];
    officeLocations: IUserOfficeLocation[];
    createdOn?: any;
    password?: string;
    authenticationProviders: number;
    metadata: string;
    isDeceased: boolean;
    mobileNumber: string;
    countryCode: string;
    isMobileVerify: boolean;
    isMFAEnabled: boolean;
    lastLogin: Date;
    reviewStatus: UserReviewStatus,
    revokeStatus: SSORevokeStatus,
    isPartner: boolean
    inactive: boolean;
}

export enum AuthenticationProvider {
    None,
    AzureAD,
    SAML,
    Google
}

export interface IUserGroups {
    groupId: number;
    groupName: string;
    groupDescription: string;
    roles: IRoles[];
    BackgroundColor: string;
    borderColor: string;
    fontColor: string;
}

export interface IUserOfficeLocation {
    locationId: number;
    locationName: string;
    isPrimary: boolean;
    userId: number;
}
export interface IRoles {
    id: number;
    name: string;
}

export type KnownAction =
    DispatchAction |
    NotificationAction;

export type DispatchAction =
    RequestUserListAction |
    ReceiveUserListAction |
    DeleteUserAction |
    SaveUserAction |
    UpdateUserAction |
    ErrorUserSettings |
    UpdateDefaultContactAction;

interface UpdateDefaultContactAction {
    type: actionTypes.UPDATE_DEFAULT_CONTACT;
}

interface ErrorUserSettings {
    type: actionTypes.ERROR_USER_MANAGEMENT;
    statusMessage: string;
    statusType: StatusType;
}

interface RequestUserListAction {
    type: actionTypes.REQUEST_USER_LIST;
}

interface ReceiveUserListAction {
    type: actionTypes.RECEIVE_USER_LIST;
    usersListInfo: IUserListInfo;
}

interface SaveUserAction {
    type: actionTypes.SAVE_USER;
    user: IUserModel;
}

interface DeleteUserAction {
    type: actionTypes.DELETE_USER;
    user: IUserModel;
}

interface UpdateUserAction {
    type: actionTypes.UPDATE_USER;
    user: IUserModel;
}

export enum UserReviewStatus {
    YetToReview = 0,
    Reviewed = 1,
    NotApplicable = 100
}

export enum SSORevokeStatus {
    None = 0,
    PermanentlyRevoked = 1,
    TemporarilyRevoked = 2,
}

export interface IContactPersonViewModel {
	id: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    locationId: number;
    selected: boolean;
}

export interface ISaveUserModel {
    userId: number;
    firstName: string;
    lastName: string;
    phone: string;
    extension: string;
    ptin: string;
    emailAddress: string;
    confirmEmail: string;
    fax: string;
    officeLocations: string;
    userGroups: string;
    mobileNumber: string;
    countryCode: string;
    isMFAEnabled: boolean;
    reviewStatus: UserReviewStatus;
    revokeStatus: SSORevokeStatus;
    contactPersons: IContactPersonViewModel[] | null;
    removedOfficeLocations: string;
    inactive: boolean;
}

export const newUser: ISaveUserModel = {
    userId: 0,
    firstName: "",
    lastName: "",
    phone: "",
    extension: "",
    ptin: "",
    emailAddress: "",
    confirmEmail: "",
    fax: "",
    officeLocations: "",
    userGroups: "",
    mobileNumber: "",
    countryCode: "+1",
    isMFAEnabled: false,
    reviewStatus: UserReviewStatus.NotApplicable,
    revokeStatus: SSORevokeStatus.None,
    contactPersons: [],
    removedOfficeLocations: '',
    inactive : false
};

export enum ChangePasswordStatus {
    None = 0,
    TemporaryPassword = 1,
    ResetPassword = 2
}
export interface IResetPasswordModel {
    show: boolean;
    type: number;
    email: string;
}

export const resetPasswordModal: IResetPasswordModel = {
    show: false,
    type: 0,
    email: ""
};

export interface IUsersDropdown {
    name: string;
    value: number;
}

export interface IUserDeleteResponse {
    userId: number;
    isDeleted: boolean;
    isPrimaryAdmin: boolean;
    defaultContactPerson: IDefaultContactPerson[];
    contactPersons: IContactPersonViewModelList[];
}

export interface IDefaultContactPerson {
    userId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
}

export const initialContactPerson: IDefaultContactPerson = {
    userId: 0,
    firstName: "",
    lastName: "",
    emailAddress: ""
}

export interface IUserLocation extends IUsersDropdown {
    locationIds?: string;
}