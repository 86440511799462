/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';

import { type IAddressListHook, type IAddressListProps } from './ip-filtering-list.types';

export const useIpFilteringList = (props: IAddressListProps): IAddressListHook => {
	const {
		addIp,
		requestIpList,
		deleteIp,
		updateIp,
		clientIpAddress,
		ipFilterList,
		isFilteringEnabled,
		updateIpFilterOption,
	} = props;

	const [ipList, setIpList] = useState([]);
	const [searchResults, setSearchResults] = useState([]);
	const [searchName, setSearchName] = useState('');
	const [searchFromIp, setSearchFromIp] = useState('');
	const [searchToIp, setSearchToIp] = useState('');
	const [newIpName, setNewIpName] = useState('');
	const [newFromIp, setNewFromIp] = useState('');
	const [newToIp, setNewToIp] = useState('');
	const [selectedRecord, setSelectedRecord] = useState({});
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [idToDelete, setIdToDelete] = useState(null);
	const [pages, setPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchProperty, setSearchProperty] = useState('');
	const pageSize = 10;

	const ipRegex = /^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$)){4}$/;
	const ipInputRegex = /^$|^[0-9\.]+$/;

	const dispatch = useDispatch();

	const handleHide = (): void => {
		setShowDeletePopup(false);
	};

	const handleDiscard = (): void => {
		handleHide();
		setSelectedRecord({});
		setIdToDelete(null);
	};

	const handleAdd = (): void => {
		if (validateIpAdd()) {
			const newIp = {
				ipAddressId: 0,
				name: newIpName,
				fromIPAddress: newFromIp,
				toIPAddress: newToIp,
			};
			addIp(newIp, () => {
				requestIpList();
				setNewIpName('');
				setNewFromIp('');
				setNewToIp('');
				setSearchName('');
				setSearchToIp('');
				setSearchFromIp('');
				handleSearchReset();
			});
		}
	};

	const handleDelete = (): void => {
		if (ipFilterList?.listData?.length <= 1 && isFilteringEnabled)
			updateIpFilterOption(false, () => {
				deleteIp(idToDelete, () => {
					requestIpList();
					handleHide();
					setIdToDelete(null);
				});
			});
		else
			deleteIp(idToDelete, () => {
				requestIpList();
				handleHide();
				setIdToDelete(null);
			});
	};

	const handleUpdate = (): void => {
		updateIp(selectedRecord, () => {
			requestIpList();
			setSelectedRecord({});
		});
	};

	const handleSearchReset = (): void => {
		setSearchResults(ipList);
		setPages(Math.ceil(ipList.length / pageSize));
		setCurrentPage(1);
		setSearchProperty('');
	};

	const handleSearch = (searchText: string, propertyName: string): void => {
		if (searchText.length > 0) {
			setSearchProperty(propertyName);
			const searchedIpList =
				ipList.filter((addr) => {
					if (propertyName === 'name') return addr[propertyName].toLowerCase().includes(searchText.toLowerCase());

					return addr[propertyName].includes(searchText);
				}) || [];
			setSearchResults(searchedIpList);
			setPages(Math.ceil(searchedIpList.length / pageSize));
			setCurrentPage(1);
		} else {
			setSearchResults(ipList);
			setPages(Math.ceil(ipList.length / pageSize));
			setCurrentPage(1);
			setSearchProperty('');
		}
	};

	const atoi = (addr: string): number => {
		const parts = addr.split('.').map((str) => {
			return parseInt(str);
		});

		return (
			(parts[0] ? parts[0] << 24 : 0) + (parts[1] ? parts[1] << 16 : 0) + (parts[2] ? parts[2] << 8 : 0) + parts[3]
		);
	};

	const validateRange = (): boolean => {
		return atoi(newFromIp) <= atoi(newToIp);
	};

	const validateIpAdd = (): boolean => {
		if (newIpName === '' || newIpName === undefined || newIpName === null) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusMessage: 'Please enter IP name.',
				statusType: StatusType.Warning,
			});

			return false;
		}

		if (newFromIp === '' || newFromIp === undefined || newFromIp === null) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusMessage: 'Please enter from IP address.',
				statusType: StatusType.Warning,
			});

			return false;
		}
		if (!ipRegex.test(newFromIp)) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusMessage: 'Please enter a valid from IP address.',
				statusType: StatusType.Warning,
			});

			return false;
		}

		if (newToIp === '' || newToIp === undefined || newToIp === null) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusMessage: 'Please enter to IP address.',
				statusType: StatusType.Warning,
			});

			return false;
		}
		if (!ipRegex.test(newToIp)) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusMessage: 'Please enter a valid to IP address.',
				statusType: StatusType.Warning,
			});

			return false;
		}
		if (!validateRange()) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusMessage: 'Invalid IP address range!',
				statusType: StatusType.Warning,
			});

			return false;
		}

		return true;
	};

	useEffect(() => {
		requestIpList();
	}, []);

	useEffect(() => {
		setNewToIp(clientIpAddress);
		setNewFromIp(clientIpAddress);
	}, [clientIpAddress]);

	useEffect(() => {
		if (searchProperty.length > 0 && (searchName.length > 0 || searchFromIp.length > 0 || searchToIp.length > 0)) {
			const searchText = searchName.length > 0 ? searchName : searchFromIp.length > 0 ? searchFromIp : searchToIp || '';
			const searchedIpList =
				ipFilterList.listData?.filter((addr) => {
					if (searchProperty === 'name') return addr[searchProperty].toLowerCase().includes(searchText.toLowerCase());

					return addr[searchProperty].includes(searchText);
				}) || [];
			setSearchResults(searchedIpList);
		} else setSearchResults(ipFilterList.listData || []);

		setIpList(ipFilterList.listData || []);
		setPages(Math.ceil(ipFilterList?.listData?.length / pageSize) || 1);
	}, [ipFilterList]);

	return {
		ipList,
		setIpList,
		searchResults,
		setSearchResults,
		searchName,
		setSearchName,
		searchFromIp,
		setSearchFromIp,
		searchToIp,
		setSearchToIp,
		newIpName,
		setNewIpName,
		newFromIp,
		setNewFromIp,
		newToIp,
		setNewToIp,
		selectedRecord,
		setSelectedRecord,
		showDeletePopup,
		setShowDeletePopup,
		idToDelete,
		setIdToDelete,
		pages,
		setPages,
		currentPage,
		setCurrentPage,
		pageSize,
		ipRegex,
		ipInputRegex,
		dispatch,
		handleHide,
		handleDiscard,
		handleAdd,
		validateIpAdd,
		validateRange,
		atoi,
		handleDelete,
		handleUpdate,
		handleSearch,
		handleSearchReset,
	};
};
