import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { signinRedirect, signinRedirectWithCallbackArgs } from "../oidcClient/userService";
import LayoutWrapper from "../components/LayoutWrapper";
import LoadingScreen from "../components/LoadingScreen";
import { ApplicationState } from "../store";
import { Unauthorized } from "../components/Unauthorized";
import { RouteConstants } from "../helper/Constants";

interface IAdminRoute {
	component: React.FC;
	sideMenuNeeded: boolean;
	hideHeader?: boolean;
	path?: string;
}

function AdminRoute({ component: Component, sideMenuNeeded, hideHeader = false, ...rest }: IAdminRoute) {
	const userState = useSelector((state: ApplicationState) => state.userAuth);
	const location = useLocation();
	const navigate = useNavigate();
	const role = userState.user?.profile?.role.toLowerCase();
	const isAdmin = role?.split(',').includes('admin')

	useEffect(() => {
		(function login() {
			const path = location.pathname + location.search;
			if (!userState.isLoadingUser && !userState.user)
				if (path === '/') signinRedirect();
				else signinRedirectWithCallbackArgs(path);
			else navigate(rest.path ? rest.path : path);
		})();
	}, []);

	return userState.user ? (
		<LayoutWrapper
			sideMenuNeeded={sideMenuNeeded}
			hideHeader={hideHeader}>
			{isAdmin ? <Component /> :
				<Unauthorized
				message={RouteConstants.ACCESSDENIED_MESSAGE_SCREENPERMISION}
				isUserLogoutEnable={false}
				isAllMenuDisabled={sideMenuNeeded}
				{...rest}
			/>}
		</LayoutWrapper>
	) : (
		<LoadingScreen />
	);
}

export default AdminRoute;