import { type FC, useEffect } from 'react';
import OverlayLoader from 'src/components/common/OverlayLoader';
import BreadCrumbNav from 'src/components/userManagement/BreadCrumbNav';

import CompanyLogo from '../../modules/branding-management/company-logo';
import CompanyWhiteLogo from '../../modules/branding-management/company-white-logo';
import HeaderDesign from '../../modules/branding-management/header-design';
import { logger } from '../../oidcClient/authProvider';
import { useBrandingManagement } from './branding.hooks';
import { type IBrandingManagementProps } from './branding.types';
import styles from './index.module.scss';

const BrandingManagement: FC<IBrandingManagementProps> = (props) => {
	const {
		breadCrumbConfig,
		companyLogoProps,
		getCompanyLogo,
		getCompanyWhiteLogo,
		getCompanyBrandSettings,
		getPrimaryOfficeLocation,
		companyWhiteLogoProps,
		brandingSettingsProps,
	} = useBrandingManagement(props);

	useEffect(() => {
		logger && logger.trackPageView('Navigated to Branding Management');
		getCompanyLogo();
		getCompanyWhiteLogo();
		getCompanyBrandSettings();
		getPrimaryOfficeLocation();
	}, []);

	const isLoading =
		companyLogoProps.isLogoLoading ||
		companyWhiteLogoProps.isWhiteLogoLoading ||
		brandingSettingsProps.isBrandingSettingsLoading;

	return (
		<div className='suitePageContent'>
			<div className='suitePageInnerContent'>
				<BreadCrumbNav config={breadCrumbConfig} />
				<div className='suitePageHeading'>
					<h1 className='suitePageHeading'>Branding</h1>
				</div>
				<OverlayLoader
					show={isLoading}
					text={'Loading'}
				/>
				<div className='suitePageSection'>
					<div className={styles.company_logo_container}>
						<CompanyLogo {...companyLogoProps} />
					</div>
					<hr className={styles.horizontal_divider} />
					<div className={styles.company_white_logo_container}>
						<CompanyWhiteLogo {...companyWhiteLogoProps} />
					</div>
					<hr className={styles.horizontal_divider} />
					<div className={styles.header_design_container}>
						<HeaderDesign {...brandingSettingsProps} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default BrandingManagement;
