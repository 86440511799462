export enum actionTypes {
    RECEIVE_MFAIPADDRESS_LIST = 'receive_mfaipaddress_list',
    REQUEST_MFAIPADDRESS_LIST = 'request_mfaipaddress_list',
    ADD_MFAIPADDRESS_STATE = 'add_mfaipaddress_state',
    UPDATE_MFAIPADDRESS_STATE = 'update_mfaipaddress_state',
    RECEIVE_MFAUSER_LIST = 'receive_mfauser_list',
    REQUEST_MFAUSER_LIST = 'request_mfauser_list',
    UPDATE_MFA_SETTINGS = 'update_mfa_settings',
    REQUEST_MFA_SETTINGS = 'request_mfa_settings',
    RECEIVE_MFA_SETTINGS = 'receive_mfa_settings',
}
