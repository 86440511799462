import React from "react";

const DownIcon: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
            <g clipPath="url(#clip0_3208_164749)">
                <path fill="#fff" fillOpacity="0.01" d="M0 0H12V12H0z"></path>
                <path
                    fill="#212529"
                    fillRule="evenodd"
                    d="M1.234 3.485a.375.375 0 01.531 0L6 7.72l4.234-4.235a.376.376 0 01.613.409.375.375 0 01-.082.122l-4.5 4.5a.375.375 0 01-.53 0l-4.5-4.5a.375.375 0 010-.531z"
                    clipRule="evenodd"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_3208_164749">
                    <path fill="#fff" d="M0 0H12V12H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
};

export default DownIcon;
