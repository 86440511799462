import { type FC } from 'react';

import styles from './section-description.module.scss';
import { type ISectionDescriptionProps } from './section-description.types';

const SectionDescription: FC<ISectionDescriptionProps> = (props) => {
	const { text = '' } = props;

	return <p className={styles.section_description}>{text}</p>;
};

export default SectionDescription;
