import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { EventSettingsConstants } from '../../../helper/Constants';
import { isValidEmailAddress } from '../../../helper/formValidations';
import { AppNotifier as VenusNotifier } from '../../../helper/AppNotifier';
import { EventType, IEventSettings, initialEventSettings } from '../developer-section.model';
import styles from './index.module.scss';
import CloseIcon from 'src/components/svg/CloseIcon';
import { developersectionResources } from '../../../helper/rbac-constants'
import { ReactComponent as Trash } from '../../../components/svg/trash.svg';

interface EventModalProps {
    show: boolean;
    onToggleEventModal(show: boolean):any;
    event: IEventSettings;    
    onSave(emails: string, resourceId?: string):any;
    parentResourceIdentifier : string,
}


interface EventModalState {
    event: IEventSettings;
    email: string;
    emailValidationFailedMessage: string;
    emailList : string;
}


export class EventModal extends React.Component<EventModalProps, EventModalState> {

    constructor(props: EventModalProps) {
        super(props);

        this.state = {
            event: initialEventSettings,
            email: "",
            emailValidationFailedMessage: "",
            emailList : props.event.failureMail
        }

    }

    UNSAFE_componentWillReceiveProps(props: EventModalProps) {
        this.setState({ event: props.event, email:"",emailList : props.event.failureMail  });
    }

    private validateEmail = (email: string) => {
        let emails: string[] = JSON.parse(this.state.emailList);
        return !(emails.some(x => x == email))
    }

    private handleAlertEmailAddClick = () => {
        if (this.state.email === '') {
            VenusNotifier.Warning(EventSettingsConstants.EmailWarning);
        }
        else if (this.validateEmail(this.state.email) && isValidEmailAddress(this.state.email)) {

            let emails: string[] = JSON.parse(this.state.emailList);

            emails.push(this.state.email);            

            this.setState({
                email: '',
                emailValidationFailedMessage: '',
                emailList :JSON.stringify(emails)
            });
        }
        else {
            VenusNotifier.Warning(this.state.emailValidationFailedMessage);
        }
    }


    private handleAlertEmailRemoveClick = (email: string) => {

        let emails: string[] = JSON.parse(this.state.emailList);

        let emailList = JSON.stringify(emails.filter(x => x != email));

        this.setState({
            email: '',
            emailList
        });
    }

    private handleAlertEmailChange = (event: any) => {

        let emailValidationFailedMessage: string = "";

        if (event.target.value) {

            if (!this.validateEmail(event.target.value)) {
                emailValidationFailedMessage = EventSettingsConstants.EmailAlreadyExist;
            }

            if (!isValidEmailAddress(event.target.value)) {
                emailValidationFailedMessage = EventSettingsConstants.InvalidEmailWarning;
            }
        }

        this.setState({
            email: event.target.value,
            emailValidationFailedMessage: emailValidationFailedMessage
        });
    }


    private handleEventChange = (event: any) => {

        this.setState({
            event: {
                ...this.state.event,
                eventType: event.value
            }
        });
    }

    private handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            this.handleAlertEmailAddClick();
        }
    }

    private createAlertEmailUI = () => {

        let emailElements: any[] = [];

        JSON.parse(this.state.emailList).map((sub:any, index:any) => (

            emailElements.push(<div key={index}>
                <label>{sub}</label>
                <Trash className={styles.trash_icon} onClick={() => { this.handleAlertEmailRemoveClick(sub) }}></Trash>
            </div>)

        ));

        return emailElements;
    }


    private validateForm = () => {
        return (this.state.emailValidationFailedMessage.length == 0
            && this.state.event.eventType != EventType.None);
    }

    private handleEventSave = () => {
        if (this.validateForm()) {
            let resourceId = this.props.parentResourceIdentifier + developersectionResources.Events + developersectionResources.ButtonEdit;
            this.props.onSave(this.state.emailList,resourceId);
        }
    }

    private handleClose =() => {
        this.setState({
            emailValidationFailedMessage:'',
            emailList:'[]'
        },() => {
            this.props.onToggleEventModal(false);
        });        
    }


    public render() {

        const options = [{value : this.props.event.eventType,label : this.props.event.name,isDisabled:true}];

        return (<Modal className={styles.event_modal}
            show={this.props.show}
            onHide={this.handleClose}
            >

            <Modal.Header>
                <div>
                    <Modal.Title>                   
                        {EventSettingsConstants.ConfigureEmail}
                    </Modal.Title>         
                </div>
                <div
					data-test-auto='e2ab6c41-b6f5-4181-b38f-3455585b2142'
					className={styles.close_icon}
					onClick={this.handleClose}>
					<CloseIcon />
				</div>       
            </Modal.Header>

            <Modal.Body>
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-xl-12'>
                        <div className="row mb-2">
                            <div className="col-lg-6">
                                <label className='fw-bold'>Event</label>
                                <span className={styles.asterisk}>*</span>
                            </div>
                            <div className="col-lg-12 padT05">                                
                                    <input
                                        readOnly={true}
                                        data-test-auto="E0AC865E-F703-40C8-98BA-170E4F999B49"
                                        type="text"
                                        className="form-control"
                                        value={this.props.event.name}
                                        data-lpignore="true"
                                    />
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-lg-6" >
                                <label className='fw-bold'>{EventSettingsConstants.EmailLabel}</label>
                            </div>
                            <div className="input-group col-lg-12">
                                <input className="form-control input-sm"
                                    type="text"
                                    value={this.state.email}
                                    placeholder={EventSettingsConstants.EmailPlaceholder}
                                    onChange={this.handleAlertEmailChange}
                                    onKeyPress={this.handleKeyPress}
                                    data-test-auto="F1C8DF76-64BF-4984-B670-48E493596454">
                                </input>

                                <div>
                                    <button hidden={this.state.emailValidationFailedMessage.length == 0}
                                        style={{ padding: "6px 15px" }}
                                        title={this.state.emailValidationFailedMessage}
                                        data-test-auto="9FC776B1-D679-4F37-AC72-4EFDF9390072"
                                        className="btn btn-default btn-lg">
                                        <span className="fa fa-exclamation-circle text-danger"></span>
                                    </button>

                                    <button                                        
                                        onClick={this.handleAlertEmailAddClick}
                                        title={EventSettingsConstants.Add}
                                        data-test-auto="9FC776B1-D679-4F37-AC72-4EFDF9390072"
                                        className= { styles.add_email}
                                        disabled={this.state.emailValidationFailedMessage.length > 0} >
                                        <span className="fa fa-plus"></span>
                                    </button>
                                </div>
                            </div>

                        </div>

                        <div className="row mb-2">
                            <div className="col-lg-12">
                                {this.createAlertEmailUI()}
                            </div>
                        </div>

                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer className="clearfix">
                <Button
                    data-test-auto="975B65F0-0BC5-4EB6-B224-A36D7E42151B"
                    variant='outline-secondary'                    
                    onClick={() => { this.props.onToggleEventModal(false) }} >
                    {EventSettingsConstants.Close}
                </Button>
                <Button
                    disabled={!this.validateForm()}
                    variant='primary'
                    onClick={this.handleEventSave}
                    data-test-auto="1F1EBE5A-7B39-4065-9447-919D20FB9502">
                    {EventSettingsConstants.Save}
                </Button>
            </Modal.Footer>
        </Modal>);
    }

}

export default EventModal;