import React, { useEffect } from 'react';
import './UserPermission.style.scss';
import { UserPermissionProps } from './UserPermission.model';
import BreadCrumbNav from '../../components/userManagement/BreadCrumbNav';
import { UserPermission as UserPemissionComponent } from '../../modules/userPermission';
import { logger } from '../../oidcClient/authProvider';

const UserPermission: React.FC<UserPermissionProps> = (props) => {
	const breadCrumbConfig = [
		{
			name: 'SafeSend Suite',
			activePage: false,
			link: '/',
		},
		{
			name: 'User Permission',
			activePage: true,
			link: '',
		},
	];

	useEffect(() => {
		logger && logger.trackPageView('Navigated to User Management');
	}, []);

	return (
		<div className='content'>
			<section className='userPermission'>
				<BreadCrumbNav config={breadCrumbConfig} />
				<h1 className='userPermissionHeading'>User Permission</h1>
				<section className='mainSection'>
					<UserPemissionComponent
						usersData={props.ssoAccessRequestUsers}
						getAllSSOAccessRequestedUsers={props.getAllSSOAccessRequestedUsers}
						requestResourceIds={props.requestResourceIds}
					/>
				</section>
			</section>
		</div>
	);
};

export default UserPermission;
