import * as React from "react";
import { IProductSubscription } from '../developer-section.model';
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { EventSettingsConstants } from '../../../helper/Constants';
import { AppNotifier as VenusNotifier } from '../../../helper/AppNotifier';
import styles from './index.module.scss';
import { developersectionResources } from '../../../helper/rbac-constants'
import { ReactComponent as Copy } from '../../../components/svg/copy.svg';
import { logger } from 'src/oidcClient/authProvider';

interface ProductSubscriptionProps {
    productSubscription: IProductSubscription;
    parentResourceIdentifier : string,
    onSubscribe(productId: string, callback: () => void,resourceId:string): void;
    onRegeneratePrimaryKey(subscriptionId: string, callback: () => void,resourceId:string): void;
    onRegenerateSecondaryKey(subscriptionId: string, callback: () => void,resourceId:string): void;
    onGetProduct() : any;
}

export const ProductSubscription : React.FC<ProductSubscriptionProps> = props => {

    let handleSubscribe = (productId: string) => {
        logger && logger.trackTrace('handleSubscribe: developer section');
        let resourceId = props.parentResourceIdentifier + developersectionResources.Subscription + developersectionResources.ButtonSubscribe;
        props.onSubscribe(productId, getProductSubscription,resourceId);
    };

    let handleRegeneratePrimaryKey = (subscriptionId: string) => {
        let resourceId = props.parentResourceIdentifier + developersectionResources.Subscription + developersectionResources.ButtonRegeneratePrimaryKey;
        props.onRegeneratePrimaryKey(subscriptionId, getProductSubscription,resourceId);
    };

    let handleRegenerateSecondaryKey = (subscriptionId: string) => {
        let resourceId = props.parentResourceIdentifier + developersectionResources.Subscription + developersectionResources.ButtonRegenerateSecondaryKey;
        props.onRegenerateSecondaryKey(subscriptionId, getProductSubscription,resourceId);
    };

    let handleCopyPrimaryKey = () => {
        VenusNotifier.Success(EventSettingsConstants.PrimaryKeyCopied);
    };

    let handleCopySecondaryKey = () => {
        VenusNotifier.Success(EventSettingsConstants.SecondaryKeyCopied);
    };

    let getProductSubscription = () => {
        props.onGetProduct();
    };

    const title = props.productSubscription.productName + " - " +  EventSettingsConstants.SubscriptionKey;
    const { productSubscription } = props;

    if (productSubscription.id === 0) return <></>;

    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <h3 className={styles.table_header} >{title}</h3>
                    <div hidden={productSubscription.subscriptionId ? true : false}>
                        <Button
                            className="button-default"
                            variant='primary'
                            onClick={() => handleSubscribe(productSubscription.productId)}
                            data-test-auto="FAAECF64-84C0-40FB-9ABF-E694F61E9917"
                        >
                            {EventSettingsConstants.Subscribe}
                        </Button>
                    </div>
                    <div hidden={productSubscription.subscriptionId ? false : true} className={styles.clear}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <label className={styles.label}>{EventSettingsConstants.PrimaryKey}</label>
                            </div>
                            <div className="col-sm-6 col-md-4 col-xl-4">
                                <input
                                    readOnly={true}
                                    type="text"
                                    className= "form-control"
                                    value={productSubscription.primaryKey}
                                    data-lpignore="true"
                                />
                                <CopyToClipboard text={productSubscription.primaryKey}>
                                    <button
                                        onClick={handleCopyPrimaryKey}
                                        className={styles.copy_key}
                                        title={EventSettingsConstants.CopyPrimaryKey}
                                        data-test-auto="2F50796F-2DA6-4F24-AE48-C3F5D5152A58"
                                    >
                                        <Copy></Copy>
                                    </button>
                                </CopyToClipboard>
                            </div>
                            <div className="col-sm-6 col-md-4 col-xl-3">
                                <Button
                                    className="padding-left12"
                                    variant='primary'
                                    title={EventSettingsConstants.RegeneratePrimaryKey}
                                    onClick={() => handleRegeneratePrimaryKey(productSubscription.subscriptionId)}
                                    data-test-auto="BC255D54-9DDB-43B5-B391-6412E142B278"
                                >
                                    {EventSettingsConstants.Regenerate}
                                </Button>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <label className={styles.label}>{EventSettingsConstants.SecondaryKey}</label>
                            </div>
                            <div className="col-sm-6 col-md-4 col-xl-4">
                                <div>
                                    <input
                                        readOnly={true}
                                        type="text"
                                        className= "form-control"
                                        value={productSubscription.secondaryKey}
                                        data-lpignore="true"
                                    />
                                    <CopyToClipboard text={productSubscription.secondaryKey}>
                                        <button
                                            onClick={handleCopySecondaryKey}
                                            className={styles.copy_key}
                                            title={EventSettingsConstants.CopySecondaryKey}
                                            data-test-auto="02CAE30B-CEBE-4679-B1CF-4EFA78271F76"
                                        >
                                            <Copy></Copy>
                                        </button>
                                    </CopyToClipboard>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-xl-3">
                                <Button
                                    className="padding-left12"
                                    title={EventSettingsConstants.RegenerateSecondaryKey}
                                    variant='primary'
                                    onClick={() => handleRegenerateSecondaryKey(productSubscription.subscriptionId)}
                                    data-test-auto="9A05714F-756B-4D50-A4FA-594BFEE5E35C"
                                >
                                    {EventSettingsConstants.Regenerate}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


  