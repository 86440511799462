import { FC } from 'react';

const ClientSearchImage: FC = () => {
	return (
		<svg
			width='267'
			height='240'
			viewBox='0 0 267 240'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M233.434 226.776C231.746 220.138 228.552 213.981 224.1 208.786C219.648 203.591 214.06 199.497 207.77 196.825C201.481 194.152 194.661 192.974 187.842 193.381C181.024 193.787 174.391 195.769 168.462 199.17'
				stroke='#0973BA'
				stroke-width='5.36'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M67.3382 183.655C71.5901 183.655 75.7465 182.391 79.2819 180.022C82.8173 177.653 85.5727 174.286 87.1999 170.346C88.827 166.407 89.2528 162.072 88.4232 157.889C87.5937 153.707 85.5462 149.866 82.5397 146.851C79.5331 143.835 75.7025 141.782 71.5322 140.95C67.362 140.118 63.0394 140.545 59.1111 142.177C55.1829 143.809 51.8253 146.572 49.4631 150.118C47.1008 153.663 45.84 157.831 45.84 162.096C45.8425 167.813 48.1083 173.295 52.1394 177.338C56.1706 181.38 61.6373 183.653 67.3382 183.655Z'
				fill='#B5D5EA'
			/>
			<path
				d='M200.672 183.655C204.923 183.655 209.08 182.391 212.615 180.022C216.151 177.653 218.906 174.286 220.533 170.346C222.16 166.407 222.586 162.072 221.757 157.889C220.927 153.707 218.88 149.866 215.873 146.851C212.866 143.835 209.036 141.782 204.866 140.95C200.695 140.118 196.373 140.545 192.445 142.177C188.516 143.809 185.159 146.572 182.796 150.118C180.434 153.663 179.173 157.831 179.173 162.096C179.176 167.813 181.442 173.295 185.473 177.338C189.504 181.38 194.971 183.653 200.672 183.655Z'
				fill='#B5D5EA'
			/>
			<path
				d='M13.6753 226.699C15.3524 220.086 18.5252 213.948 22.948 208.762C27.3707 203.576 32.9247 199.481 39.1794 196.794C45.4342 194.108 52.2218 192.901 59.0164 193.268C65.811 193.635 72.4302 195.566 78.3609 198.911'
				stroke='#0973BA'
				stroke-width='5.36'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M176.106 226.774C176.106 212.797 170.569 199.392 160.714 189.509C150.859 179.625 137.492 174.073 123.555 174.073C109.617 174.073 96.2509 179.625 86.3956 189.509C76.5404 199.392 71.0038 212.797 71.0038 226.774'
				stroke='#0973BA'
				stroke-width='5.36'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M139.01 159.7C145.152 159.7 151.156 157.874 156.262 154.452C161.369 151.03 165.349 146.166 167.699 140.476C170.05 134.785 170.665 128.524 169.466 122.483C168.268 116.442 165.311 110.893 160.968 106.538C156.625 102.183 151.092 99.2167 145.068 98.0151C139.045 96.8135 132.801 97.4302 127.127 99.7872C121.452 102.144 116.603 106.136 113.191 111.257C109.778 116.378 107.957 122.399 107.957 128.558C107.957 136.818 111.229 144.739 117.052 150.579C122.876 156.419 130.774 159.7 139.01 159.7Z'
				fill='#B5D5EA'
				stroke='#B5D5EA'
				stroke-width='2.84444'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M211.197 86.9364C232.303 86.9364 249.413 69.7773 249.413 48.6114C249.413 27.4454 232.303 10.2865 211.197 10.2865C190.091 10.2865 172.981 27.4454 172.981 48.6114C172.981 69.7773 190.091 86.9364 211.197 86.9364Z'
				fill='#B5D5EA'
				stroke='#B5D5EA'
				stroke-width='5.36'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M219.549 75.7057L249.854 106.097'
				stroke='#0973BA'
				stroke-width='5.36'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M192.53 86.9364C213.636 86.9364 230.746 69.7773 230.746 48.6114C230.746 27.4454 213.636 10.2865 192.53 10.2865C171.424 10.2865 154.314 27.4454 154.314 48.6114C154.314 69.7773 171.424 86.9364 192.53 86.9364Z'
				stroke='#0973BA'
				stroke-width='5.36'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M56.6715 183.655C60.9235 183.655 65.0799 182.391 68.6153 180.022C72.1506 177.653 74.9061 174.286 76.5333 170.346C78.1604 166.407 78.5861 162.072 77.7566 157.889C76.9271 153.707 74.8796 149.866 71.873 146.851C68.8665 143.835 65.0359 141.782 60.8656 140.95C56.6954 140.118 52.3728 140.545 48.4445 142.177C44.5162 143.809 41.1587 146.572 38.7964 150.118C36.4342 153.663 35.1733 157.831 35.1733 162.096C35.1759 167.813 37.4417 173.295 41.4728 177.338C45.504 181.38 50.9706 183.653 56.6715 183.655Z'
				stroke='#0973BA'
				stroke-width='5.36'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M190.438 183.655C194.69 183.655 198.847 182.391 202.382 180.022C205.917 177.653 208.673 174.286 210.3 170.346C211.927 166.407 212.353 162.072 211.523 157.889C210.694 153.707 208.646 149.866 205.64 146.851C202.633 143.835 198.803 141.782 194.632 140.95C190.462 140.118 186.14 140.545 182.211 142.177C178.283 143.809 174.926 146.572 172.563 150.118C170.201 153.663 168.94 157.831 168.94 162.096C168.943 167.813 171.209 173.295 175.24 177.338C179.271 181.38 184.737 183.653 190.438 183.655Z'
				stroke='#0973BA'
				stroke-width='5.36'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M123.01 159.7C129.152 159.7 135.156 157.874 140.262 154.452C145.369 151.03 149.349 146.166 151.699 140.476C154.05 134.785 154.665 128.524 153.466 122.483C152.268 116.442 149.311 110.893 144.968 106.538C140.625 102.183 135.092 99.2168 129.068 98.0152C123.045 96.8136 116.801 97.4303 111.127 99.7873C105.452 102.144 100.603 106.136 97.1905 111.257C93.7784 116.378 91.9572 122.399 91.9572 128.558C91.9572 136.818 95.2288 144.739 101.052 150.579C106.876 156.419 114.774 159.7 123.01 159.7Z'
				stroke='#0973BA'
				stroke-width='5.36'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M96.257 112.747C103.536 120.312 112.941 125.473 123.218 127.545C133.495 129.616 144.158 128.499 153.786 124.342'
				stroke='#0973BA'
				stroke-width='5.36'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	);
};

export default ClientSearchImage;
