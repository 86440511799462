import React, { FC } from 'react';
import { Modal, Container, Button } from 'react-bootstrap';
import './SSOSettingsModal.style.scss';
import InfoIcon from 'src/components/svg/InfoIcon';
import { AuthorizationMode } from '../SSOSettings.model';
import { NO_INDEX } from 'src/helper/Constants';
import Tag from 'src/components/common/Tag';

interface SSOSettingsModalProps {
	onHide: () => void;
	onSaveButtonClick: (event: any) => void;
	onChangeGroupTagValue(e: any, groupIds: string[]): void;
	authorizationType: AuthorizationMode[];
	onAuthorizationTypeChange(authMode: AuthorizationMode): void;
	groupIds: string[];
	tenantIds: string[];
	onGroupValidate(key: string[]): void;
	onTenantValidate(key: string[]): void;
	onChangeTenantTagValue(e: any, groupIds: string[]): void;
	isValidGroupKey: boolean;
	inProgress: boolean;
}
const SSOSettingsModal: FC<SSOSettingsModalProps> = (props) => {
	return (
		<>
			<Modal.Body className='show-grid'>
				<Container>
					<h5 className='infoText'>Add</h5>
					<ul className='addOptions'>
						<li>
							<input
								type='radio'
								name='ssoSettings'
								id='azureGroups'
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									props.onAuthorizationTypeChange(AuthorizationMode.AzureAdGroups);
								}}
								checked={props.authorizationType.findIndex((x) => x == AuthorizationMode.AzureAdGroups) != NO_INDEX}
								value={AuthorizationMode.AzureAdGroups}
								data-test-auto='e130cdce-9bf6-4de0-aa79-b1e3ef58e457'
							/>
							<label htmlFor='azureGroups'>Azure Ad Group(s)</label>
						</li>
						<li>
							<input
								type='radio'
								name='ssoSettings'
								id='ssrUsersList'
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									props.onAuthorizationTypeChange(AuthorizationMode.SSRUserList);
								}}
								checked={props.authorizationType.findIndex((x) => x == AuthorizationMode.SSRUserList) != NO_INDEX}
								value={AuthorizationMode.SSRUserList}
								data-test-auto='89332d39-2aae-4ecf-b03c-12034f15546d'
							/>
							<label htmlFor='ssrUsersList'>SSR Users List</label>
							<span title='All SSR users will have ability to login through O365'>
								<InfoIcon />
							</span>
						</li>
						<li>
							<input
								type='radio'
								name='ssoSettings'
								id='azureTenantIDs'
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									props.onAuthorizationTypeChange(AuthorizationMode.AzureTenantId);
								}}
								checked={props.authorizationType.findIndex((x) => x == AuthorizationMode.AzureTenantId) != NO_INDEX}
								value={AuthorizationMode.AzureTenantId}
								data-test-auto='2d685b60-e364-4a0b-a347-50441ef6de38'
							/>
							<label htmlFor='azureTenantIDs'>Azure Tenant ID</label>
						</li>
					</ul>

					{props.authorizationType.findIndex((x) => x == AuthorizationMode.AzureAdGroups) != NO_INDEX ? (
						<div className='azureContainer'>
							<h5 className='infoText'>Azure Group IDs</h5>
							<div className='listBlock'>
								<ul className='list'>
									<Tag
										data-test-auto='F7A259F6-0833-4B63-BCAD-CA3536A4C940'
										listOfTagsInput={props.groupIds}
										onChangeTagValue={props.onChangeGroupTagValue}
										placeHolder='+ Add New Group ID'
										onValidate={props.onGroupValidate}
									/>
								</ul>
							</div>
						</div>
					) : null}

					{props.authorizationType.findIndex((x) => x == AuthorizationMode.AzureTenantId) != NO_INDEX ? (
						<div className='azureContainer'>
							<h5 className='infoText'>Azure Tenant ID</h5>
							<div className='listBlock'>
								<ul className='list'>
									<Tag
										data-test-auto='74E49297-2CCB-44CF-B70F-F16C94A9987D'
										listOfTagsInput={props.tenantIds}
										onChangeTagValue={props.onChangeTenantTagValue}
										placeHolder='+ Add New Tenant ID'
										onValidate={props.onTenantValidate}
										maxTags={1}
									/>
								</ul>
							</div>
						</div>
					) : null}
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button
					disabled={props.inProgress}
					data-test-auto='57ea56ef-5fe5-4210-a20a-249f68318465'
					variant='secondary'
					onClick={props.onHide}>
					Cancel
				</Button>
				<Button
					disabled={props.inProgress}
					data-test-auto='9926d599-c8e2-4755-bf53-b69cfe385f06'
					variant='primary'
					onClick={props.onSaveButtonClick}>
					Add
				</Button>
			</Modal.Footer>
		</>
	);
};

export default SSOSettingsModal;
