export enum actionTypes {
	REQUEST_ADMIN_USERS = 'request_admin_users',
	RECEIVE_ADMIN_USERS = 'receive_admin_users',
	UPDATE_ADMIN_USERS = 'update_admin_users',
	START_UPDATE_PRODUCTS = 'start_update_products',
	END_UPDATE_PRODUCTS = 'end_update_products',
	REQUEST_ALL_COMPANY_LIST = 'request_all_company_list',
	RECEIVE_ALL_COMPANY_LIST = 'receive_all_company_list',
    REQUEST_ALL_PRODUCTS = 'request_all_products',
    RECEIVE_ALL_PRODUCTS = 'receive_all_products',
	REQUEST_ALL_ADD_ON_FEATURES = 'request_all_add_on_features',
	RECEIVE_ALL_ADD_ON_FEATURES = 'receive_all_add_on_features',
	START_UPDATE_ADD_ON_FEATURES = 'start_update_add_on_features',
	END_UPDATE_ADD_ON_FEATURES = 'end_update_add_on_features'
}
