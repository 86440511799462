import React from 'react';
import './ClientListTable.style.scss';
import Divider from '../../../components/svg/Divider';
import {
	IClientModel,
	IColumnOptions,
	SortingColumnNames,
	clickableColumns,
	clientType,
	columnNames,
} from '../Client.model';
import DeceasedIcon from '../../../components/svg/DeceasedIcon';
import MFJIcon from '../../../components/svg/MFJIcon';
import { clientMngmntResources } from '../../../helper/rbac-constants';
import moment from 'moment';
import { formatEin, formatSsn } from '../../../helper/formValidations';
import { phoneNumberDisplay, countryCodeDisplay } from '../../../helper/HelperFunctions';
import { ISortColumn } from '..';
import NewUserIcon from '../../../components/svg/NewUserIcon';

interface ClientListTableProps {
	clients: IClientModel[];
	isLoading: boolean;
	sortColumn: ISortColumn;
	setSortColumn: (sortColumn: ISortColumn) => void;
	handleDeleteClientClick: (client: IClientModel) => void;
	setShowClientModal: (showClientModal: boolean) => void;
	activeColumns: IColumnOptions[];
	requestClientInfoById: (clientBasicInfoId: number, callback: (client: IClientModel) => void) => void;
	setSelectedClient: (selectedClient: IClientModel) => void;
	newClientBasicInfoIds: number[];
	firstColumnWidth: string;
	firstColumnRef: any;
	selectedClients: IClientModel[];
	isSelectAll: boolean;
	handleSelect: (client: IClientModel) => void;
	handleSelectAll: () => void;	
}

const ClientListTable: React.FC<ClientListTableProps> = (props) => {
	const {
		clients,
		isLoading,
		sortColumn,
		setSortColumn,
		activeColumns,
		newClientBasicInfoIds,
		firstColumnWidth,
		firstColumnRef,
	} = props;

	const handleEditClientInfoButtonClick = (client: IClientModel) => {
		props.requestClientInfoById(client.clientBasicInfoId, (data) => {
			props.setSelectedClient(data);
			props.setShowClientModal(true);
		});
	};

	const handleSort = (text: string) => {
		const newSortColumn = { ...sortColumn };

		switch (text) {
			case columnNames.clientId:
				if (newSortColumn.sortBy === SortingColumnNames[0]) {
					newSortColumn.sortOrder = newSortColumn.sortOrder === 'desc' ? 'asc' : 'desc';
				} else {
					newSortColumn.sortBy = SortingColumnNames[0];
					newSortColumn.sortOrder = 'desc';
				}
				setSortColumn(newSortColumn);
				break;

			case columnNames.name:
				if (newSortColumn.sortBy === SortingColumnNames[1]) {
					newSortColumn.sortOrder = newSortColumn.sortOrder === 'desc' ? 'asc' : 'desc';
				} else {
					newSortColumn.sortBy = SortingColumnNames[1];
					newSortColumn.sortOrder = 'desc';
				}
				setSortColumn(newSortColumn);
				break;

			case columnNames.emailAddress:
				if (newSortColumn.sortBy === SortingColumnNames[2]) {
					newSortColumn.sortOrder = newSortColumn.sortOrder === 'desc' ? 'asc' : 'desc';
				} else {
					newSortColumn.sortBy = SortingColumnNames[2];
					newSortColumn.sortOrder = 'desc';
				}
				setSortColumn(newSortColumn);
				break;

			case columnNames.spouseName:
				if (newSortColumn.sortBy === SortingColumnNames[3]) {
					newSortColumn.sortOrder = newSortColumn.sortOrder === 'desc' ? 'asc' : 'desc';
				} else {
					newSortColumn.sortBy = SortingColumnNames[3];
					newSortColumn.sortOrder = 'desc';
				}
				setSortColumn(newSortColumn);
				break;

			case columnNames.spouseEmailAddress:
				if (newSortColumn.sortBy === SortingColumnNames[4]) {
					newSortColumn.sortOrder = newSortColumn.sortOrder === 'desc' ? 'asc' : 'desc';
				} else {
					newSortColumn.sortBy = SortingColumnNames[4];
					newSortColumn.sortOrder = 'desc';
				}
				setSortColumn(newSortColumn);
				break;

			case columnNames.officeLocation:
				if (newSortColumn.sortBy === SortingColumnNames[5]) {
					newSortColumn.sortOrder = newSortColumn.sortOrder === 'desc' ? 'asc' : 'desc';
				} else {
					newSortColumn.sortBy = SortingColumnNames[5];
					newSortColumn.sortOrder = 'desc';
				}
				setSortColumn(newSortColumn);
				break;

			default:
				break;
		}
	};

	const sortClass = (sortBy: string, columnName: string) => {
		if (sortBy === SortingColumnNames[0] && columnName === columnNames.clientId) {
			return sortColumn.sortOrder === 'asc' ? 'fa-sort-asc self-end' : 'fa-sort-desc';
		} else if (sortBy === SortingColumnNames[1] && columnName === columnNames.name) {
			return sortColumn.sortOrder === 'asc' ? 'fa-sort-asc self-end' : 'fa-sort-desc';
		} else if (sortBy === SortingColumnNames[2] && columnName === columnNames.emailAddress) {
			return sortColumn.sortOrder === 'asc' ? 'fa-sort-asc self-end' : 'fa-sort-desc';
		} else if (sortBy === SortingColumnNames[3] && columnName === columnNames.spouseName) {
			return sortColumn.sortOrder === 'asc' ? 'fa-sort-asc self-end' : 'fa-sort-desc';
		} else if (sortBy === SortingColumnNames[4] && columnName === columnNames.spouseEmailAddress) {
			return sortColumn.sortOrder === 'asc' ? 'fa-sort-asc self-end' : 'fa-sort-desc';
		} else if (sortBy === SortingColumnNames[5] && columnName === columnNames.officeLocation) {
			return sortColumn.sortOrder === 'asc' ? 'fa-sort-asc self-end' : 'fa-sort-desc';
		} else {
			return 'fa-sort self-end';
		}
	};

	return (
		<table className='table table-striped table-hover clientsListTable'>
			<thead className='table-header'>
				<tr>
				<th
					ref={ firstColumnRef }
					style={{width: '25px'}}
					className='widthAuto clickableColumns'
					scope='col'				
				>
					<input 
          				type="checkbox" 
						className='tableSelectCheckbox'
          				onChange={props.handleSelectAll}
          				checked={props.isSelectAll}
						disabled={clients.length === 0}
        			/>
				</th>
					{activeColumns.length &&
						activeColumns
							.filter((column: IColumnOptions) => column.isActive)
							.map((column: IColumnOptions, index: number) => {
								return (
									<th
										ref={column.columnName === columnNames.clientId ? firstColumnRef : null}
										style={{ width: column.columnName === columnNames.clientId ? firstColumnWidth : '100%' }}
										className={`widthAuto ${clickableColumns.includes(column.columnName) ? 'clickableColumns' : ''}`}
										scope='col'
										key={index}
										title={column.columnName}
										onClick={() => handleSort(column.columnName)}>
										<div className='column'>
											<div className='column__name'>{column.columnName}</div>
											{clickableColumns.includes(column.columnName) ? (
												<i className={`fa fa-fw ${sortClass(sortColumn.sortBy, column.columnName)} sortIcon`}></i>
											) : (
												<></>
											)}
										</div>
									</th>
								);
							})}
					<th
						className='actions'
						scope='col'>
						Actions
					</th>
				</tr>
			</thead>
			<tbody>
				{isLoading ? (
					<tr>
						<td
							data-toggle='collapse'
							colSpan={activeColumns.length + 1}
							className='clientsLoadingArea'>
							<div className='text-center'>
								<div
									className='spinner-border'
									role='status'></div>
								<div>
									<span className='clientsLoadingText'>Loading...</span>
								</div>
							</div>
						</td>
					</tr>
				) : clients.length === 0 ? (
					<tr>
						<td
							data-toggle='collapse'
							colSpan={activeColumns.length + 1}
							className='clientsLoadingArea text-center'>
							No matching records found
						</td>
					</tr>
				) : (
					clients.map((client) => {
						return (
							<tr
								key={client.clientId}
								className='clientRow'>
								<td
								className='widthAuto'
								style={{width: '25px'}}
								>
									<input 
              							type="checkbox" 
										className='tableSelectCheckbox'
              							onChange={() => props.handleSelect(client)}
              							checked={props.selectedClients.includes(client)}
            						/>								
								</td>
								<td
									className='widthAuto clientIdTableData'
									title={client.clientId}>
									<div className='clientIdColumn'>
										<div style={{ marginRight: client.isDeceased ? '3px' : '' }} className={`leftSideName`}>
											{newClientBasicInfoIds.some((id: number) => client.clientBasicInfoId === id) && <NewUserIcon />}
											{client.clientId}
										</div>
										<div className='icons'>
											{client.isDeceased && (
												<div
													className='deceasedIcon'
													title=''>
													<DeceasedIcon />
												</div>
											)}
											{client.mfj && (
												<div
													className='mfjIcon'
													title='MFJ'>
													<MFJIcon />
												</div>
											)}
										</div>
									</div>
								</td>
								{activeColumns
									.filter((column: IColumnOptions) => column.isActive)
									.map((column: IColumnOptions, index: number) => {
										if (column.columnName === columnNames.clientId) {
											return <React.Fragment key={index}></React.Fragment>;
										} else if (
											column.columnName === columnNames.eroSigner &&
											client.eroFirstName &&
											client.eroLastName
										) {
											return (
												<td
													key={index}
													className='widthAuto'
													title={`${client.eroLastName}, ${client.eroFirstName}`}>
													{`${client.eroLastName}, ${client.eroFirstName}`}
												</td>
											);
										} else if (column.columnName === columnNames.officeLocation && client.locationName) {
											return (
												<td
													key={index}
													className='widthAuto'
													title={client.locationName}>
													{client.locationName}
												</td>
											);
										} else if (column.columnName === columnNames.name && client.name) {
											return (
												<td
													key={index}
													className='widthAuto'
													title={client.name}>
													{client.name}
												</td>
											);
										} else if (column.columnName === columnNames.SsnEin && client.uid) {
											return (
												<td
													key={index}
													className='widthAuto'
													title={client.type === clientType.SSN ? formatSsn(client.uid) : formatEin(client.uid)}>
													{client.type === clientType.SSN ? formatSsn(client.uid) : formatEin(client.uid)}
												</td>
											);
										} else if (column.columnName === columnNames.emailAddress && client.emailAddress) {
											return (
												<td
													key={index}
													className='widthAuto'
													title={client.emailAddress}>
													{client.emailAddress}
												</td>
											);
										} else if (column.columnName === columnNames.dob && client.dob) {
											return (
												<td
													key={index}
													className='widthAuto'
													title={moment(client.dob).format('MM/DD/YYYY')}>
													{moment(client.dob).format('MM/DD/YYYY')}
												</td>
											);
										} else if (column.columnName === columnNames.mobile && client.mobileNumber) {
											return (
												<td
													key={index}
													className='widthAuto'
													title={countryCodeDisplay(client.countryCode) + phoneNumberDisplay(client.mobileNumber)}>
													{countryCodeDisplay(client.countryCode)}
													{phoneNumberDisplay(client.mobileNumber)}
												</td>
											);
										} else if (column.columnName === columnNames.spouseName && client.spouseName) {
											return (
												<td
													key={index}
													className='widthAuto'
													title={client.spouseName}>
													{client.spouseName}
												</td>
											);
										} else if (column.columnName === columnNames.spouseSsn && client.spouseSsn) {
											return (
												<td
													key={index}
													className='widthAuto'
													title={formatSsn(client.spouseSsn)}>
													{formatSsn(client.spouseSsn)}
												</td>
											);
										} else if (column.columnName === columnNames.spouseEmailAddress && client.spouseEmailAddress) {
											return (
												<td
													key={index}
													className='widthAuto'
													title={client.spouseEmailAddress}>
													{client.spouseEmailAddress}
												</td>
											);
										} else if (column.columnName === columnNames.spouseDob && client.spouseDob) {
											return (
												<td
													key={index}
													className='widthAuto'
													title={moment(client.spouseDob).format('MM/DD/YYYY')}>
													{moment(client.spouseDob).format('MM/DD/YYYY')}
												</td>
											);
										} else if (column.columnName === columnNames.spouseMobile && client.spouseMobileNumber) {
											return (
												<td
													key={index}
													className='widthAuto'
													title={
														countryCodeDisplay(client.spouseCountryCode) + phoneNumberDisplay(client.spouseMobileNumber)
													}>
													{countryCodeDisplay(client.spouseCountryCode)}
													{phoneNumberDisplay(client.spouseMobileNumber)}
												</td>
											);
										} else {
											return (
												<td
													key={index}
													className='widthAuto'></td>
											);
										}
									})}
								<td className='actionsColData'>
									<div className='alignActionButtons'>
										<div>
											<button
												data-test-auto={`3cfb242a-38b9-4835-ba3c-507d9e69c162${client.clientId}`}
												className='editClientButton'
												onClick={() => handleEditClientInfoButtonClick(client)}>
												Edit
											</button>
										</div>
										<div className='divider'>
											<Divider />
										</div>
										<div>
											<button
												data-resource-id={`${clientMngmntResources.gridPrefix}_${clientMngmntResources.deleteButton}`}
												data-test-auto={`912e12c4-617c-433e-946c-124e2e3d63cf${client.clientId}`}
												className='deleteClientButton'
												onClick={() => props.handleDeleteClientClick(client)}>
												Delete
											</button>
										</div>
									</div>
								</td>
							</tr>
						);
					})
				)}
			</tbody>
		</table>
	);
};

export default ClientListTable;
