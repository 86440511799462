import { type FC } from 'react';

import styles from './image-card.module.scss';
import { type IImageCardProps } from './image-card.types';

const ImageCard: FC<IImageCardProps> = ({ src = '', alt = '', isLoading = false, isWhiteLogo = false }) => {
	if (isLoading) return <div className={styles.skeleton_loader} />;

	return (
		<div className={`${styles.image_card_wrapper} ${isWhiteLogo && styles.image_card_white_logo}`}>
			{src.length > 0 || isLoading ? (
				<img
					src={src}
					alt={alt}
					className={styles.image_card_content}
				/>
			) : (
				<div className={styles.no_white_logo}>
					<h1 className={`${isWhiteLogo && styles.no_white_logo_text}`}>No White Logo</h1>
				</div>
			)}
		</div>
	);
};

export default ImageCard;
