import React, { FC } from 'react';
import styles from './StepInProgressIndicator.module.scss';
import TickIcon from 'src/components/svg/TickIcon';

export interface IStepData {
	number: number;
	name: string;
	inProgress: boolean;
	completed: boolean;
}

interface StepInProgressIndicatorProps {
	data: IStepData[];
	connectorLength?: string;
}

const StepInProgressIndicator: FC<StepInProgressIndicatorProps> = ({ data, connectorLength }) => {
	return (
		<section className={styles.stepperContainer}>
			{data.map((eachStep, index) => {
				return (
					<React.Fragment key={`${eachStep.number} - ${index}`}>
						<div
							className={`${styles.stepGroup} ${
								eachStep.completed ? styles.stepCompleted : eachStep.inProgress ? styles.stepInProgress : null
							}`}>
							<span className={styles.stepNumber}>{eachStep.completed ? <TickIcon /> : eachStep.number}</span>
							<span className={styles.stepTitle}>{eachStep.name}</span>
						</div>
						{data.length > index + 1 && (
							<div
								className={`${styles.stepConnector} ${
									eachStep.inProgress || eachStep.completed ? styles.blueConnector : null
								}`}
								style={connectorLength && { width: connectorLength }}
							/>
						)}
					</React.Fragment>
				);
			})}
		</section>
	);
};

export default StepInProgressIndicator;
