import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CustomDropdown from '../../../../../components/common/customDropdown';
import { IContactPersonViewModelList } from '../../saveUserModal/SaveUserModal.model';
import { IErrorField, IMandatoryFieldError } from '../ChangeContactPersonModal.model';
import { modificationType } from '../..';
import { MenuPlacement } from 'react-select';
import { ICustomDropdownOption } from 'src/components/common/customDropdown/CustomDropdownWithCustomOptions';

interface IRowContentProps {
	contactPersonData: IContactPersonViewModelList;
	className: string;
	setMandatoryFieldError(locationId: string, errorValues: IErrorField): void;
	mandatoryFieldError: IMandatoryFieldError;
	processType: modificationType;
	menuPlacement: MenuPlacement;
}

const RowContent: React.FC<IRowContentProps> = ({
	contactPersonData,
	className,
	setMandatoryFieldError,
	mandatoryFieldError,
	processType,
	menuPlacement,
}) => {
	const { LocationId, locationName, locationNumber, contactPerson, isGroupedReturn } = contactPersonData;
	const [selectedOption, setSelectedOption] = useState<ICustomDropdownOption | null>(null);

	const handleChanges = (option: ICustomDropdownOption | null) => {
		setSelectedOption(option);
		contactPerson.forEach((x) => {
			if (option?.value === x.id) {
				x.selected = true;
			} else {
				x.selected = false;
			}
		});
		handleOnBlurOfficeLocation(contactPersonData.LocationId.toString(), option);
	};

	const handleOnBlurOfficeLocation = (id: string, selectedOption: ICustomDropdownOption | null) => {
		if (!selectedOption) {
			setMandatoryFieldError(id, { error: true, message: 'This is required field' });
		} else {
			setMandatoryFieldError(id, { error: false, message: '' });
		}
	};

	return isGroupedReturn && processType === modificationType.Edit ? (
		<></>
	) : (
		<Row
			className={className}
			style={{ height: '100px', strokeWidth: '0.5px' }}>
			<Col
				xs={9}
				md={4}
				className='flex-column-justify-center'>
				<div className='column-1 flex-column-justify-center'>
					{!isGroupedReturn && <div className='locationId'>LOCATION #{locationNumber}</div>}
					<div
						className={`locationName ${!isGroupedReturn ? 'locationName-ellipsis' : ''}`}
						title={!isGroupedReturn ? locationName : ''}>
						{!isGroupedReturn ? locationName : 'Grouped Returns'}
					</div>
				</div>
			</Col>
			<Col
				xs={9}
				md={8}
				className='flex-column-justify-center'>
				<div className='column-2 flex-column-justify-center'>
					<div className='title'>Change Contact Person</div>
					<div className='contactPersonDropdown'>
						<CustomDropdown
							id={'contactPerson'}
							className='rowContentDropdown'
							selectedOption={selectedOption}
							setSelectedOption={handleChanges}
							options={contactPerson.map((x) => ({ label: `${x.firstName} ${x.lastName}`, value: x.id }))}
							customPlaceHolder='Select Contact Person'
							customPlaceholderStyles={{
								color: '#A6A9AC',
							}}
							height={31}
							borderColor={
								mandatoryFieldError[`LocationId${contactPersonData.LocationId}`]?.error ? '#CC4A43' : '#898D91'
							}
							handleOnBlur={() => handleOnBlurOfficeLocation(contactPersonData.LocationId.toString(), selectedOption)}
							scrollableMenu={true}
							menuPlacement={menuPlacement}
						/>
						<div
							id='userOfficeLocationError'
							className={`${
								mandatoryFieldError[`LocationId${contactPersonData.LocationId}`]?.error
									? 'saveUserFieldErrorMessage'
									: ''
							}`}>
							{mandatoryFieldError[`LocationId${contactPersonData.LocationId}`]?.message}
						</div>
					</div>
				</div>
			</Col>
		</Row>
	);
};

export default RowContent;
