import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LayoutWrapper from '../components/LayoutWrapper';
import LoadingScreen from '../components/LoadingScreen';

import { Unauthorized } from '../components/Unauthorized';
import { RouteConstants } from '../helper/Constants';
import { IsRouteDisabled,HasMenuAccess } from '../oidcClient/UserRoles';
import { signinRedirect, signinRedirectWithCallbackArgs } from '../oidcClient/userService';
import { getSidemenuData, getWidgetData, resetUserCache } from '../pages/common/common.apis';
import { getAddOnFeatureUrls } from '../pages/welcome/welcome.apis';
import { ApplicationState } from '../store';

type Props = {
	Component: React.ComponentType<any>;
	sideMenuNeeded: boolean;
	path: string ;
	exact?: boolean | undefined;
	sensitive?: boolean | undefined;
	strict?: boolean | undefined;
	headerMenu?: string | undefined;
	isWelcomePage?: boolean | undefined;
};

const RoleBasedRoute: React.FC<Props> = ({ Component, sideMenuNeeded, headerMenu, isWelcomePage, ...rest }) => {
	const userState = useSelector((state: ApplicationState) => state.userAuth);
	const leftMenuData = useSelector((state: ApplicationState) => state.commonState.sidemenuData);
	const addOnFeatureData = useSelector((state: ApplicationState) => state.welcomeState.addOnFeatureList);
	const isSideMenuDataLoaded = useSelector((state: ApplicationState) => state.commonState.isSideMenuDataLoaded);
	const widgetData = useSelector((state: ApplicationState) => state.commonState.widgetData);
	const isWidgetMenuDataLoaded = useSelector((state: ApplicationState) => state.commonState.isWidgetMenuDataLoaded);
	const addOnFeatureLoaded = useSelector((state: ApplicationState) => state.welcomeState.IsAddonFeatureLoaded);
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { isResetCacheApiCalled } = useSelector((appState: ApplicationState) => appState.commonState);
	useEffect(() => {
		(function login() {
			let path = location.pathname + location.search;
			if (isWelcomePage) {
				path= rest.path
            }
			if (!userState.isLoadingUser && !userState.user) {
				if (path === '/') {
					signinRedirect();
				} else {
					signinRedirectWithCallbackArgs(path);
				}
			} else {
				navigate(path);
			}
		})();
	}, [isResetCacheApiCalled]);

	if (userState.user) {
		if (isSideMenuDataLoaded && leftMenuData && isWidgetMenuDataLoaded && widgetData && addOnFeatureLoaded) {
			const isDisabled = IsRouteDisabled(leftMenuData, widgetData, addOnFeatureData, rest.path);
			if(!headerMenu){
				sideMenuNeeded = sideMenuNeeded ? !isDisabled : sideMenuNeeded;
			}
			else{
				sideMenuNeeded = HasMenuAccess(leftMenuData,headerMenu);
			}

			return (
				<LayoutWrapper sideMenuNeeded={sideMenuNeeded}>
					{isDisabled && rest.path !=='/' ? (
						<Unauthorized
							message={RouteConstants.ACCESSDENIED_MESSAGE_SCREENPERMISION}
							isUserLogoutEnable={false}
							isAllMenuDisabled={!sideMenuNeeded}
							{...rest}
						/>
					) : (
						<Component {...rest} />
					)}
				</LayoutWrapper>
			);
		} else {
			if (!isResetCacheApiCalled) {
				dispatch(resetUserCache());
			}
			if (isResetCacheApiCalled) {
				dispatch(getSidemenuData());
				dispatch(getWidgetData(navigate));
				dispatch(getAddOnFeatureUrls());
			}
		}
	}
	return <LoadingScreen />;
};

RoleBasedRoute.displayName = 'RoleBasedRoute';

export default RoleBasedRoute;
