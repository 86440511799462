import axios, { AxiosError, AxiosResponse } from "axios";
import { AppThunkAction } from "../../store";
import { StatusType } from "../common/notification/notification.reducer";
import { actionTypes } from "./welcome.actions";
import { IProductData, KnownActions } from "./welcome.types";
import { actionTypes as notificationTypes } from "../common/notification/notification.types";
import { logger } from 'src/oidcClient/authProvider';


export const getHelpUrl =
    (): AppThunkAction<KnownActions> =>
        (dispatch) => {
            dispatch({
                type: actionTypes.REQUEST_HELP_URL
            });
            axios
                .get(`${process.env.REACT_APP_API_URL}/api/helper/help-url`)
                .then((response: AxiosResponse<string>) => {
                    const { data } = response;
                    dispatch({
                        type: actionTypes.RECEIVE_HELP_URL,
                        payload: data
                    });
                    window.open(data);
                })
                .catch(function (error: AxiosError) {
                    logger && logger.trackWarning('Error while getHelpUrl', { 'Error': error?.response?.data});
                    if(error.response && error.response.status!=419 && error.response.status!=512)
                    {
                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: error.message,
                            statusType: StatusType.Error,
                        });
                    }
                })
                .finally(() => {
                    dispatch({
                        type: actionTypes.COMPLETE_HELP_URL,
                    });
                });
        };

export const getProductUrls =
    (): AppThunkAction<KnownActions> =>
        (dispatch) => {
            dispatch({
                type: actionTypes.REQUEST_PRODUCT_URLS
            });
            axios
                .get(`${process.env.REACT_APP_API_URL}/api/menu/accessible-products`)
                .then((response: AxiosResponse<IProductData[]>) => {
                    const { data } = response;
                    dispatch({
                        type: actionTypes.RECEIVE_PRODUCT_URLS,
                        payload: data
                    });
                })
                .catch(function (error: AxiosError) {
                    logger && logger.trackWarning('Error while getProductUrls', { 'Error': error?.response?.data});
                    if(error.response && error.response.status!=419 && error.response.status!=512)
                    {
                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: error.message,
                            statusType: StatusType.Error,
                        });
                    }
                })
                .finally(() => {
                    dispatch({
                        type: actionTypes.COMPLETE_PRODUCT_URLS,
                    }); 
                });
        };        

export const getAddOnFeatureUrls =
    (): AppThunkAction<KnownActions> =>
        (dispatch) => {
            dispatch({
                type: actionTypes.REQUEST_ADDON_FEATURE_URLS
            });
            axios
                .get(`${process.env.REACT_APP_API_URL}/api/menu/accessible-add-on-feature`)
                .then((response: AxiosResponse<IProductData[]>) => {
                    const { data } = response;
                    dispatch({
                        type: actionTypes.RECEIVE_ADDON_FEATURE_URLS,
                        payload: data
                    });
                })
                .catch(function (error: AxiosError) {
                    logger && logger.trackWarning('Error while getAddOnFeatureUrls', { 'Error': error?.response?.data });
                    if (error.response && error.response.status != 419 && error.response.status != 512) {
                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: error.message,
                            statusType: StatusType.Error,
                        });
                    }
                }) 
        };       