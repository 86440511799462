import { ApplicationState } from '../../store/index';
import { connect } from 'react-redux';
//import { stat } from "fs";
import EventSettings from ".";
import * as EventSettingsStore from "../../modules/developer-section/developer-section.store";

export default connect(
    (state: ApplicationState) => ({
        eventSettingsState: state.eventSettingsState,
        companyProfileState: state.companyData.companyProfile
    }),
    {
        ...EventSettingsStore.actionCreators,
    }
)(EventSettings as any);
