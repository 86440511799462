import { Reducer } from 'redux';
import { IOverlayLoader, DispatchAction } from './OverlayLoader.model';
import { actionTypes } from './OverlayLoader.actions';

export const overlayLoaderInitialState: IOverlayLoader = {
    show: false,
    text: ''
};

export const reducer: Reducer<IOverlayLoader> = (state = overlayLoaderInitialState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.LOADING:
            const loader: IOverlayLoader = { ...state };
            loader.show = action.show;
            loader.text = action.text;
            return loader;
        default:
    }
    return state || overlayLoaderInitialState;
};
