import React, { Fragment, FC } from 'react';
import ButtonWithTextIcon from '../../../../../components/common/button-with-text-icon';
import AddUsers from '../../../../../components/svg/add-users.svg';
import { userGroupsConstants } from '../../../../../helper/rbac-constants';
import './user-groups-header.scss';

interface UserGroupsHeaderProps {
    setShowSaveUserGroupModal: (showSaveUserGroupModal: boolean) => void;
    applyDisabled: boolean;
    initiateGroupUpdates: () => void;
}

const UserGroupsHeader: FC<UserGroupsHeaderProps> = (props) => {
    return (
        <Fragment >
            <div className='user_groups_container'>
                <div className='user_groups_header_container'>
                    <div className='user_groups_header_text'>
                        Drag and drop users into custom created user groups. 
                    </div>
                    <div className='user_groups_header_buttons'>
                        <ButtonWithTextIcon
                            label='Add User Group'
                            onClick={() => props.setShowSaveUserGroupModal(true)}
                            icon={AddUsers}
                            className="add_user_button"
                            data-resource-id={`${userGroupsConstants.prefix}_${userGroupsConstants.addGroups}`}
                            data-test-auto='user_groups_header_add_user_button'
                        />
                        <ButtonWithTextIcon
                            label='Apply changes'
                            onClick={() => props.initiateGroupUpdates()}
                            className="apply_change_button"
                            disabled={props.applyDisabled}
                            data-resource-id={`${userGroupsConstants.prefix}_${userGroupsConstants.applyChanges}`}
                            data-test-auto='user_groups_header_apply_change_button'
                            bordered
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default UserGroupsHeader;