import React, { FC, useEffect, useRef, useState } from "react";
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { IUserProfile } from "../../../pages/common/userProfile/userProfile.types";
import { ApplicationState } from "../../../store";
import * as Validation from "../../../helper/Validations";
import { MyAccountConstants, ProfileInformation, ValidationContants } from "../../../helper/Constants";
import { saveMyAccount } from "../../../pages/common/userProfile/userProfile.apis";
import { AppNotifier } from "../../../helper/AppNotifier";
import { saveOneTimePassword, validateOTP } from "../../../pages/common/myAccount/otp.apis";
import { countryCodeDisplay, GetCountryCode, phoneNumberDisplay } from "../../../helper/HelperFunctions";
import { PhoneNumber } from "../PhoneNumber";
import { IMyAccountLayout } from "./myAccountLayout";
import { InfoCircle } from "src/components/svg/IconCollection";

interface IMyAccountForm extends IUserProfile {
    existingEmailAddress: string;
    showChangePassword: boolean;
    showOtpVerification: boolean;
    saving: boolean;
    otpValue: string;
    disableVerifyLink: boolean;
    ptin: string;
    title: string;
}

export interface CountryData {
    name: string;
    dialCode: string;
    countryCode: string;
    format: string;
}

const initialFormState: IMyAccountForm = {
    existingEmailAddress: "",
    showChangePassword: false,
    showOtpVerification: false,
    saving: false,
    otpValue: "",
    disableVerifyLink: false,
    firstName: "",
    lastName: "",
    phone: "",
    extension: "",
    fax: "",
    emailAddress: "",
    userId: 0,
    userGroups: [],
    authenticationProviders: [],
    metadata: "",
    countryCode: "",
    mobileNumber: "",
    isMobileVerify: false,
    ptin: "",
    title: "",
    isMFAEnabled: false,
    readonlyFields: []
};

const Profile: FC<IMyAccountLayout> = (props) => {
    const ref = useRef<HTMLFormElement>(null);
    const [formDetail, setFormDetail] = useState<IMyAccountForm>(initialFormState);
    const [validated, setValidated] = useState<boolean>(false);
    const [mobileValidation, setMobileValidation] = useState<{ message: string, error: boolean }>({ message: "", error: false });
    const profile = useSelector((state: ApplicationState) => state.userProfile);
    const otpData = useSelector((state: ApplicationState) => state.oneTimePasswordData.otpState);
    const dispatch = useDispatch();

    const onFormChange: React.ChangeEventHandler = (e) => {
        const element = (e.target as HTMLInputElement);
        const data = { ...formDetail, [element.id]: element.value };
        setFormDetail(data);
    };
    const onFaxChange: React.ChangeEventHandler = (e) => {
        const element = (e.target as HTMLInputElement);
        if (element.value.match(/^\d+$/) || element.value === "") {
            setFormDetail({ ...formDetail, fax: element.value });
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    const onExtChange: React.ChangeEventHandler = (e) => {
        const element = (e.target as HTMLInputElement);
        if (element.value.match(/^\d+$/) || element.value === "") {
            setFormDetail({ ...formDetail, extension: element.value });
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    const onChangeMobileNumber = (phone: string, data: CountryData) => {
        setFormDetail({
            ...formDetail,
            countryCode: `+${data.dialCode}`,
            mobileNumber: phone.slice(data.dialCode.length),
            disableVerifyLink: false,
            isMobileVerify: false,
        });
    };
    const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const validatedMobileNumberData = validateMobileNumber();
        setMobileValidation(validatedMobileNumberData);
        const validPtin = Validation.isValidatePTIN(formDetail.ptin);
        const validFax = Validation.validateFax(formDetail.fax, true) || !Validation.NullandEmptyCheck(formDetail.fax);
        const validPhone = formDetail.phone.length === ProfileInformation.PHONE_NUMBER_LENGTH || (!formDetail.phone && !formDetail.extension);        
        const validExt = formDetail.extension.length < ProfileInformation.EXTENSION_LENGTH;
        const validName = Validation.ValidateName(formDetail.firstName) && Validation.ValidateName(formDetail.lastName);
        const allValid = !validatedMobileNumberData.error && validPtin && validFax && validPhone && validExt && validName;

        const form = ref.current;
        if (!!form && form.checkValidity() && allValid) {
            setFormDetail({
                ...formDetail,
                saving: true,
                showOtpVerification: false,
                otpValue: "",
                disableVerifyLink: false,
            });
            dispatch(saveMyAccount(formDetail,
                false,
                (response: any, error: any) => {
                    setFormDetail({ ...formDetail, saving: false });
                    if (error) {
                        AppNotifier.Error(error.response.data || MyAccountConstants.UserUpdateFailedMessage);
                    } else {
                        setFormDetail({
                            ...formDetail
                        });
                        if (response) {
                            AppNotifier.Success(MyAccountConstants.APIResponse.UserUpdateSuccess);
                            props.onHide();
                        } else {
                            AppNotifier.Error(response);
                        }
                    }
                }));
            setValidated(false);
        } else {
            setValidated(true);
        }
    };

    const onVerifyClick = () => {
        const validationData = validateMobileNumber(true);
        setMobileValidation(validationData);
        if (!validationData.error) {
            setFormDetail({ ...formDetail, disableVerifyLink: true });
            const DISABLE_VERIFY_LINK_TIMEOUT = 108000;
            setTimeout(() => {
                setFormDetail({ ...formDetail, disableVerifyLink: false });
            }, DISABLE_VERIFY_LINK_TIMEOUT);
            const mobileNumber = formDetail.countryCode + formDetail.mobileNumber;
            const countryCode = formDetail.countryCode;
            setFormDetail({
                ...formDetail,
                showOtpVerification: true,
                otpValue: "",
            });
            dispatch(saveOneTimePassword(mobileNumber, countryCode));
        }
    };

    const onChangeOtpValue = (event: any) => {
        if (Validation.ValidateTenDigitNumber(event)) {
            const value = event.target.value;
            setFormDetail({ ...formDetail, otpValue: value });
        }
    };

    const onOtpSubmit = () => {
        if (Validation.ValidateTenDigitNumber(event)) {
            const value = formDetail.otpValue;
            if (otpData.mfaOTPLength === value.length) {
                const mobileNumber = formDetail.countryCode + formDetail.mobileNumber;
                dispatch(validateOTP(value, mobileNumber, hideVerifyLink));
            }
        }
    };

    const hideVerifyLink = (isOTPValid: boolean) => {
        if (isOTPValid) {
            setFormDetail({
                ...formDetail,
                showOtpVerification: false,
                isMobileVerify: true
            });
        }
    };

    const onChangePhoneNumber = (value: string) =>
        setFormDetail({ ...formDetail, phone: value });

    useEffect(() => {
        setFormDetail({
            ...formDetail,
            existingEmailAddress: profile.emailAddress,
            ...profile,
            countryCode: profile.countryCode || GetCountryCode()[0].value,
        });
    }, [profile]);

    const validateMobileNumber = (isVerify?: boolean) => {
        const enabledMfa = isVerify || profile.isMFAEnabled;
        if (enabledMfa && !Validation.NullandEmptyCheck(formDetail.mobileNumber)) {
            return {
                message: ValidationContants.MobileNumberWarning,
                error: true
            };
        } else if (
            Validation.NullandEmptyCheck(formDetail.mobileNumber) &&
            !Validation.validatePhoneLength(formDetail.mobileNumber)
        ) {
            return {
                message: ValidationContants.MobileNumberLengthWarning,
                error: true
            };
        } else if (formDetail.countryCode === "" || formDetail.countryCode === undefined) {
            return {
                message: ValidationContants.CountryCodeWarning,
                error: true
            };
        } else {
            return {
                message: "",
                error: false
            };
        }
    };

    const onHide = () => {
        props.onHide();
    };
    return <div>
        <h3 className="heading-blue-1">{ProfileInformation.TITLE}</h3>
        <p className="text-gray-description">{ProfileInformation.DESCRIPTION}</p>
        <LoadingOverlay style={{ height: "100%" }}>
            <Form
                ref={ref}
                id="profile-form"
                noValidate
                validated={validated}
            >
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="title" xs={2}>
                        <Form.Label>{ProfileInformation.LABEL_TITLE}</Form.Label>
                        <Form.Control size="sm" type="text" onChange={onFormChange} value={formDetail?.title} placeholder={ProfileInformation.PLACEHOLDER_TITLE} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="firstName">
                        <Form.Label>{ProfileInformation.LABEL_FIRST_NAME}</Form.Label>
                        <Form.Control size="sm" type="text" onChange={onFormChange} value={formDetail?.firstName} required disabled={Validation.isControlDisabled("FirstName", profile) }
                            placeholder={ProfileInformation.PLACEHOLDER_FIRST_NAME} isInvalid={!Validation.ValidateName(formDetail.firstName)}/>
                        <Form.Control.Feedback type="invalid">
                            {!formDetail.firstName ?
                                ValidationContants.FirstNameWarning : 
                                ValidationContants.NameLengthWarning}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="lastName">
                        <Form.Label>{ProfileInformation.LABEL_LAST_NAME}</Form.Label>
                        <Form.Control size="sm" type="text" onChange={onFormChange} value={formDetail?.lastName} required disabled={Validation.isControlDisabled("LastName", profile)}
                            placeholder={ProfileInformation.PLACEHOLDER_LAST_NAME} isInvalid={!Validation.ValidateName(formDetail.lastName)}/>
                        <Form.Control.Feedback type="invalid">
                        {!formDetail.lastName ?
                                ValidationContants.LastNameWarning : 
                                ValidationContants.NameLengthWarning}
                        </Form.Control.Feedback>
                    </Form.Group>

                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="emailAddress" xs={4}>
                        <Form.Label>{ProfileInformation.LABEL_EMAIL}</Form.Label>
                        <Form.Control size="sm" type="email" onChange={onFormChange} value={formDetail?.emailAddress} required disabled={Validation.isControlDisabled("EmailAddress", profile)}
                            placeholder={ProfileInformation.PLACEHOLDER_EMAIL} 
                            isInvalid={!Validation.isValidEmailAddress(formDetail.emailAddress)}/>
                        <Form.Control.Feedback type="invalid">
                            {!formDetail?.emailAddress ?
                                ValidationContants.EmailAdrressWarning :
                                ValidationContants.ValidEmailAddressWarning}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} className={(validated &&
                        formDetail.phone.length > 0 &&
                        formDetail.phone.length < ProfileInformation.PHONE_NUMBER_LENGTH) ? "input-error" : ""}>
                        <div className="d-flex justify-content-between">
                            <Form.Label>{ProfileInformation.LABEL_PHONE_NUMBER}</Form.Label>
                            <span>Optional</span>
                        </div>
                        <PhoneNumber
                            phoneNumber={formDetail?.phone}
                            handleChangePhoneNumber={onChangePhoneNumber}
                            disabled={Validation.isControlDisabled("Phone", profile)}
                            isInvalid={!formDetail.phone && (formDetail.extension.length!=0 && formDetail.extension.length < ProfileInformation.EXTENSION_LENGTH) }
                        />
                        <Form.Control.Feedback type="invalid">
                            {ValidationContants.PhoneNumberWarning}
                        </Form.Control.Feedback>
                        {validated &&
                            formDetail.phone.length > 0 &&
                            formDetail.phone.length < ProfileInformation.PHONE_NUMBER_LENGTH && formDetail.extension.length!=0 &&
                            <div className="input-error-message">
                                {(!formDetail.phone && formDetail.extension) ? ValidationContants.PhoneNumberWarning : ValidationContants.PhoneNumberLengthWarning}
                            </div>}
                    </Form.Group>

                    <Form.Group as={Col} controlId="extension" xs={4}
                        className={validated && (formDetail.extension.length > ProfileInformation.EXTENSION_LENGTH) ? "input-error" : ""}>
                        <Form.Label>{ProfileInformation.LABEL_EXTENSION}</Form.Label>
                        <Form.Control size="sm" type="text" onChange={onExtChange} value={formDetail?.extension} disabled={Validation.isControlDisabled("Extension", profile)}
                            placeholder={ProfileInformation.PLACEHOLDER_EXTENSION}
                            isInvalid={validated && (formDetail.extension.length >= ProfileInformation.EXTENSION_LENGTH)} />
                        <Form.Control.Feedback type="invalid">
                            {ValidationContants.ExtensionWarning}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Form.Group as={Row} className="mb-3">
                    <Col xs={8} className={(mobileValidation.error) ? "input-error" : ""}>

                        <div className="d-flex justify-content-between align-items-center">
                            <div className="flex-fill">
                                <div className="d-flex justify-content-between">
                                    <Form.Label>
                                        {ProfileInformation.LABEL_MOBILE_NUMBER}
                                    </Form.Label>
                                    {!profile.isMFAEnabled && <span>Optional</span>}
                                </div>
                                <PhoneInput
                                    containerClass={(mobileValidation.error) ? "error" : ""}
                                    autoFormat={true}
                                    placeholder="() -"
                                    value={`${formDetail.countryCode}${formDetail.mobileNumber}`}
                                    onChange={onChangeMobileNumber} />
                                {(mobileValidation.error) && <span className="input-error-message w-100">{mobileValidation.message}</span>}
                            </div>
                            {!formDetail.isMobileVerify && <div className="d-flex align-items-center mt-3">
                                <Button size="sm" variant="link" onClick={() => !formDetail.disableVerifyLink && onVerifyClick()} className="heading-blue-2 cursor-pointer">{ProfileInformation.VERIFY_TITLE}</Button>
                                <OverlayTrigger
                                    trigger="hover"
                                    placement="bottom"
                                    rootClose
                                    overlay={
                                        <Tooltip id={"mobile-verification-tooltip"}>
                                            {ProfileInformation.VERIFY_HELP_TEXT}
                                        </Tooltip>
                                    }
                                >
                                    {({ ref, ...triggerHandler }) => (
                                        <span ref={ref} {...triggerHandler}>
                                            <InfoCircle />
                                        </span>
                                    )}
                                </OverlayTrigger>
                            </div>}
                        </div>
                    </Col>

                    <Form.Group as={Col} controlId="fax" xs={4}>
                        <div className="d-flex justify-content-between">
                            <Form.Label>{ProfileInformation.LABEL_FAX_NUMBER}</Form.Label>
                            <span>Optional</span>
                        </div>
                        <Form.Control size="sm" type="text" onChange={onFaxChange}
                            value={formDetail?.fax}
                            disabled={Validation.isControlDisabled("Fax", profile)}
                            placeholder={ProfileInformation.PLACEHOLDER_FAX_NUMBER}
                            minLength={ProfileInformation.FAX_LENGTH}
                            maxLength={ProfileInformation.FAX_LENGTH} />
                        <Form.Control.Feedback type="invalid">
                            {ValidationContants.FaxWarning}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Group>

                {formDetail.showOtpVerification && !formDetail.isMobileVerify && (
                    <div className="verification-container mb-3">
                        <div className="mb-3 verification-description">
                            <b>Verify Phone Number</b><br />
                            Please enter the verification code sent to <b>{`${countryCodeDisplay(formDetail.countryCode)} ${phoneNumberDisplay(formDetail.mobileNumber)}`}</b>
                        </div>
                        <div className="input-wrapper">
                            <Form.Control size="sm" onChange={onChangeOtpValue} value={formDetail.otpValue} placeholder={ProfileInformation.PLACEHOLDER_ACCESS_CODE} />
                            <Button variant={"secondary"} onClick={onOtpSubmit} disabled={otpData.mfaOTPLength !== formDetail.otpValue.length}>{ProfileInformation.ACCESS_CODE_BUTTON}</Button>
                        </div>
                        <div>Didn’t receive the code?<Button variant="link" onClick={onVerifyClick}>Resend</Button></div>
                    </div>
                )}

                <div className="mt-auto d-flex justify-content-end">
                    <Button variant="outline-secondary" className="me-2" onClick={onHide}>{ProfileInformation.CANCEL_BUTTON}</Button>
                    <Button variant="primary" onClick={handleSubmit}>{ProfileInformation.OK_BUTTON}</Button>
                </div>
            </Form>
            <Loader loading={formDetail.saving} />
        </LoadingOverlay>
    </div>;
};

export default Profile;