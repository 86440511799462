export enum actionTypes {
    REQUEST_HELP_URL = "request_help_url",
    RECEIVE_HELP_URL = "receive_help_url",
    COMPLETE_HELP_URL = "complete_help_url",

    REQUEST_PRODUCT_URLS = "request_product_urls",
    RECEIVE_PRODUCT_URLS = "receive_product_urls",
    COMPLETE_PRODUCT_URLS = "complete_product_urls",

    REQUEST_ADDON_FEATURE_URLS = "request_addon_feature_urls",
    RECEIVE_ADDON_FEATURE_URLS = "receive_addon_feature_urls"
}