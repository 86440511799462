import CloseIcon from "src/components/svg/CloseIcon";
import React, { FC } from "react";
import { Modal, Container, Button } from "react-bootstrap";
import "./ResetPasswordModal.style.scss";
import { ChangePasswordStatus, IResetPasswordModel, resetPasswordModal } from "../Users.model";

interface ResetPasswordModalProps {
    setShowResetPasswordModal: (showResetPasswordModal: IResetPasswordModel) => void;
    showResetPasswordModal: IResetPasswordModel;
}

const ResetPasswordModal: FC<ResetPasswordModalProps> = (props) => {
    const { showResetPasswordModal, setShowResetPasswordModal } = props;
    const handleClose = () => setShowResetPasswordModal(resetPasswordModal);
    const type = showResetPasswordModal.type;
    const email = type === ChangePasswordStatus.TemporaryPassword ?
        <span>This user has not yet finished creating their account, an email with a temporary password will be sent to <b>{showResetPasswordModal.email}</b> so they can complete their account setup.</span> :
        type === ChangePasswordStatus.ResetPassword ?
            <span>An email with a reset password link was sent to <b>{showResetPasswordModal.email}</b>.</span> :
            <span>Resetting password for the user <b>{showResetPasswordModal.email}</b> failed.</span>
    return (
        <Modal show={true} onHide={handleClose} className="resetPasswordModal">
            <Modal.Header>
                <div><Modal.Title>{type === ChangePasswordStatus.TemporaryPassword ? "Temporary Password Sent" : type === ChangePasswordStatus.ResetPassword ? "Password Reset Link Sent" : "Reset Password Failure"}</Modal.Title></div>
                <div data-test-auto="c791435a-fe99-4342-9409-ddb98babd206" className="closeIcon" onClick={handleClose}><CloseIcon /></div>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <Container>
                    {email}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button data-test-auto="f7c01a52-5ad5-4a99-9dc3-c2570c40722e" variant="primary" onClick={handleClose}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ResetPasswordModal;