import { Reducer } from 'redux';
import axios, { AxiosResponse } from 'axios';

import { AppThunkAction } from 'src/store/index';
import { DispatchAction, ISSOSettings, KnownAction } from './SSOSettings.model';
import { actionTypes } from './SSOSettings.actions';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';
import { API_BASE_URL, SSOSettingsConstants } from 'src/helper/Constants';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { AuthenticationProvider } from 'src/modules/userManagement/users/Users.model';
import { logger } from 'src/oidcClient/authProvider';

export const initialSSOState: ISSOSettings[] = [{
    enabled: false,
    isModified: false,
    provider: AuthenticationProvider.AzureAD,
    data: [],
    authorizationType: []
}];

export const actionCreators = {
    getSingleSignOnSettings: (callback: (ssoSettings: ISSOSettings[]) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.get(API_BASE_URL + '/api/sso',
        {
            headers: {
                'X-Resource-Id': 'SUITE_Secr_SnglSignOn_TglEnblOrDisbl'
            }
        })
            .then((response: AxiosResponse<ISSOSettings[]>) => {
                const { data } = response;
                dispatch({
                    type: loaderTypes.LOADING,
                    show: false,
                    text: '',
                });
                dispatch({
                    type: actionTypes.RECEIVE_SSO_SETTINGS, ssoSettings: data
                });
                callback(data);
            }).catch(function (error) {
                logger && logger.trackWarning('Error while getSingleSignOnSettings', { 'Error': error?.response?.data});
                dispatch({
                    type: loaderTypes.LOADING,
                    show: false,
                    text: '',
                });
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: SSOSettingsConstants.StatusMessage.GetSSOSettingsError,
                    statusType: StatusType.Error
                });
                callback(initialSSOState);
            });
    },

    updateSSOSettings: (ssoSettings: ISSOSettings[], resourceId = '', callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.put(API_BASE_URL + '/api/sso/external-authentication-setting-update', ssoSettings, {
            headers: {
                'X-Resource-Id': resourceId
            }
        })
            .then((response: AxiosResponse) => {
                const { data } = response;
                dispatch({ type: actionTypes.UPDATE_SSO_SETTINGS, ssoSettings: ssoSettings });
                callback();
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: SSOSettingsConstants.StatusMessage.UpdateSSOSettingsSuccess,
                    statusType: StatusType.Success
                });
            })
            .catch((error) => {
                logger && logger.trackWarning('Error while updateSSOSettings', { 'Error': error?.response?.data});
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: SSOSettingsConstants.StatusMessage.UpdateSSOSettingsError,
                    statusType: StatusType.Error
                });
            });
    },

    switchSingleSignOnSetting: (isEnabled: boolean, resourceId = '', callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.put(API_BASE_URL + `/api/sso/is-single-sign-on-enabled?isEnabled=${isEnabled}`, {
            headers: {
                'X-Resource-Id': resourceId
            }
        })
            .then((response: AxiosResponse) => {
                const { data } = response;
                if (data) {
                    callback();
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: SSOSettingsConstants.StatusMessage.UpdateSSOSettingsSuccess,
                        statusType: StatusType.Success
                    });
                }
            })
            .catch((error) => {
                logger && logger.trackWarning('Error while switchSingleSignOnSetting', { 'Error': error?.response?.data});
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: SSOSettingsConstants.StatusMessage.UpdateSSOSettingsError,
                    statusType: StatusType.Error
                });
            });
    },

    validateAzureADGroup: (keyList: string[], callback: (groupIDs: string[]) => void, resourceId = ''):
        AppThunkAction<KnownAction> => (dispatch, getstate) => {
            axios.post(API_BASE_URL + '/api/sso/azure-ad-group-validate', keyList, {
                headers: {
                    'X-Resource-Id': resourceId
                }
            })
                .then((response: AxiosResponse) => {
                    const { data } = response;
                    callback(data);
                }).catch(error => {
                    logger && logger.trackWarning('Error while validateAzureADGroup', { 'Error': error?.response?.data});
                    dispatch({
                        type: notificationTypes.NOTIFICATION, statusType: StatusType.Error,
                        statusMessage: SSOSettingsConstants.StatusMessage.ADAzureExist
                    });
                });
        },
};

export const reducer: Reducer<ISSOSettings[]> = (state = initialSSOState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.UPDATE_SSO_SETTINGS:
            const newSSOSettings = action.ssoSettings;
            return newSSOSettings;
        case actionTypes.RECEIVE_SSO_SETTINGS:
            const ssoSettings = action.ssoSettings;
            return ssoSettings;
        default:
    }
    return state;
};