import React, { FC, useState } from 'react';
import {ReactComponent as Grip} from '../../svg/grip-vertical.svg';
import './draggable-list.scss';
import { useSelector } from 'react-redux';  

const DraggableList: FC<any> = (props) => {
    const {
        listData = [],
        displayError = false,
        errorText = '',
        isDragAllowed = () => Boolean,
    } = props;

    const [selectedList, setSelectedList] = useState<any>([]);
    const primaryAdminId = useSelector((store) => store?.companyData?.companyProfile?.companyInfo?.adminUser);
    
    const dragStart = (e: any,data: any) => {
        if(isDragAllowed && !isDragAllowed()){
            return;
        }
        const  newSelectedList = [...selectedList, data]; 
        setSelectedList([...newSelectedList]);
        
        e.dataTransfer.setData('text/plain', JSON.stringify(newSelectedList));
        e.dataTransfer.dropEffect = "copy";
    };

    const dragEnd = () => {
        setSelectedList([]);
    }

    const handleClick = (data: any) => {
        let newSelectedList: any = []
        if (selectedList.findIndex((listDatum: any) => listDatum.userId === data.userId) === -1) {
            newSelectedList = [...selectedList, data];
        } else {
            newSelectedList = selectedList.filter((datum: any) => datum.userId !== data.userId)
        }
        setSelectedList([...newSelectedList]);
    };

    return (
        <div className='draggable-list-container'>
            { displayError ? 
                <div className='error_div'>
                    {errorText}
                </div> : 
                listData.map((datum: any, index: any) => (
                    <div
                        className={selectedList.findIndex((listItem: any) => datum.userId === listItem.userId) !== -1 ? 'draggable-list-card selected-card' : 'draggable-list-card'}
                        onDragStart={(e) => dragStart(e,datum)}
                        onDragEnd = {() => dragEnd()}
                        onClick={() => handleClick(datum)}
                        key={index}
                        draggable={true}
                        title={datum.fullName}
                    >
                        <div className='grip-container'>
                            <Grip />
                        </div>
                        <div className='content-container'>
                            {datum.fullName.slice(0, 50)}
                        </div>                
                    </div>
                ))
            }
        </div>
    );
};

export default DraggableList;