import React, { FC } from "react";

const IconMore: FC = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1296_62166)">
                <rect width="16" height="16" fill="white" fillOpacity="0.01" />
                <path d="M2.99976 9.49927C2.60193 9.49927 2.2204 9.34123 1.9391 9.05993C1.65779 8.77862 1.49976 8.39709 1.49976 7.99927C1.49976 7.60144 1.65779 7.21991 1.9391 6.93861C2.2204 6.6573 2.60193 6.49927 2.99976 6.49927C3.39758 6.49927 3.77911 6.6573 4.06042 6.93861C4.34172 7.21991 4.49976 7.60144 4.49976 7.99927C4.49976 8.39709 4.34172 8.77862 4.06042 9.05993C3.77911 9.34123 3.39758 9.49927 2.99976 9.49927ZM7.99976 9.49927C7.60193 9.49927 7.2204 9.34123 6.9391 9.05993C6.65779 8.77862 6.49976 8.39709 6.49976 7.99927C6.49976 7.60144 6.65779 7.21991 6.9391 6.93861C7.2204 6.6573 7.60193 6.49927 7.99976 6.49927C8.39758 6.49927 8.77911 6.6573 9.06042 6.93861C9.34172 7.21991 9.49976 7.60144 9.49976 7.99927C9.49976 8.39709 9.34172 8.77862 9.06042 9.05993C8.77911 9.34123 8.39758 9.49927 7.99976 9.49927ZM12.9998 9.49927C12.6019 9.49927 12.2204 9.34123 11.9391 9.05993C11.6578 8.77862 11.4998 8.39709 11.4998 7.99927C11.4998 7.60144 11.6578 7.21991 11.9391 6.93861C12.2204 6.6573 12.6019 6.49927 12.9998 6.49927C13.3976 6.49927 13.7791 6.6573 14.0604 6.93861C14.3417 7.21991 14.4998 7.60144 14.4998 7.99927C14.4998 8.39709 14.3417 8.77862 14.0604 9.05993C13.7791 9.34123 13.3976 9.49927 12.9998 9.49927Z" fill="#05386B" />
            </g>
            <defs>
                <clipPath id="clip0_1296_62166">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default IconMore;