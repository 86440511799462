import CloseIcon from "src/components/svg/CloseIcon";
import React, { FC, useEffect, useState } from "react";
import { Modal, Container, Button } from "react-bootstrap";
import "./EROSignatureModal.style.scss";
import InfoIcon from "src/components/svg/InfoIcon";
import UploadIcon from "src/components/svg/UploadIcon";
import { getEROSignatureSettings, saveEROSignatureSettings, uploadSignature } from "./EROSignatureModal.api";
import { eroSignatureSettingsDefault, IEROSignatureSettings } from "./EROSignatureModal.model";
import SignatureImageCrop from "../SignatureImageCrop/SignatureImageCrop";
import SignatureDelegationModal from "../SignatureDelegationModal";
import { logger } from 'src/oidcClient/authProvider';
import PersonHeart from "src/components/svg/PersonHeart";

interface EROSignatureModalProps {
    setShowEROSignatureModal: (showEROSignatureModal: boolean) => void;
    eroSignatureInProgress: boolean;
    signatureForUserId: number;
    setEroSignatureInProgress: (eroSignatureInProgress: boolean) => void;
}

const EROSignatureModal: FC<EROSignatureModalProps> = (props) => {
    const { setShowEROSignatureModal, eroSignatureInProgress, signatureForUserId, setEroSignatureInProgress } = props;
    const handleClose = () => setShowEROSignatureModal(false);
    const [eroSignatureSettings, setEROSignatureSettings] = useState<IEROSignatureSettings>(eroSignatureSettingsDefault);
    const [file, setFile] = useState<any>("");
    const [showCropPanel, setShowCropPanel] = useState(false);
    const [isSignatureUploaded, setIsSignatureUploaded] = useState(false);
    const [signatureUploadError, setSignatureUploadError] = useState(false);
    const [imageSelectionError, setImageSelectionError] = useState(false);
    const [signatureChanged, setSignatureChanged] = useState(false);
    const [showSignatureDelegationModal, setShowSignatureDelegationModal] = useState(false);

    const onStampUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target !== null && e.target.files !== null) {
            const fileType = e.target?.files[0].type;
            if (!(fileType === "image/jpeg" || fileType === "image/jpg" || fileType === "image/png" || fileType === "image/bmp")) {
                setImageSelectionError(true);
                return;
            }
            setImageSelectionError(false);
            setFile(URL.createObjectURL(e.target?.files[0]));
            setShowCropPanel(true);
        }
    }

    const getCroppedCanvas = (croppedImage: any) => {
        setSignatureUploadError(false);
        setFile("");
        setShowCropPanel(false);
        setEROSignatureSettings({ ...eroSignatureSettings, signaturePath: croppedImage });
        setIsSignatureUploaded(true);
        setSignatureChanged(true);
    }

    const cancelCrop = () => {
        setFile("");
        setShowCropPanel(false);
    }

    const saveEROSignature = (eroSignatureSettings: IEROSignatureSettings) => {
        if (!eroSignatureSettings.signaturePath) {
            setSignatureUploadError(true);
        } else {
			logger && logger.trackTrace('saveEROSignature: Saving ERO Signature in usermanagement');
            setEroSignatureInProgress(true);
            if (signatureChanged) {
                uploadSignature(eroSignatureSettings.userId, eroSignatureSettings.signaturePath, (signaturePath) => {
                    const newEROSignatureSettings = { ...eroSignatureSettings, signaturePath: signaturePath };
                    setEROSignatureSettings(newEROSignatureSettings);
                    setIsSignatureUploaded(false);
                    saveEROSignatureSettings(newEROSignatureSettings, () => {
                        setEroSignatureInProgress(false);
                        handleClose();
                        setSignatureChanged(false);
                    });
                });
            } else {
                saveEROSignatureSettings(eroSignatureSettings, () => {
                    setEroSignatureInProgress(false);
                    handleClose();
                    setSignatureChanged(false);
                });
            }

        }
    }

    const saveDelegateeIDs = (delegateIDs: number[]) => {
        setEROSignatureSettings({ ...eroSignatureSettings, delegatee: delegateIDs });
        setShowSignatureDelegationModal(false);
    }

    useEffect(() => {
        setEroSignatureInProgress(true);
        getEROSignatureSettings(signatureForUserId, (eroSignatureSettingsFromAPI: IEROSignatureSettings) => {
            if (eroSignatureSettingsFromAPI.signaturePath && eroSignatureSettingsFromAPI.signaturePath.length > 0) {
                setIsSignatureUploaded(true);
            } else {
                setIsSignatureUploaded(false);
            }
            setEROSignatureSettings(eroSignatureSettingsFromAPI);
            setEroSignatureInProgress(false);
        });
    }, []);

    return (
        <Modal show={true} onHide={handleClose} className="eroSignatureModal">
            <Modal.Header>
                <div><Modal.Title>ERO Signature Stamp</Modal.Title></div>
                <div data-test-auto="f27ba423-8adf-4dc1-b74e-9626c61a0733" className="closeIcon" onClick={handleClose}><CloseIcon /></div>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <Container>
                    <div className="defaultSignerOption form-switch">
                        <span className="defaultSignerText">Default ERO/Signer</span>
                        <input className="defaultSignerButton form-check-input" type="checkbox" id="defaultSignerButton" checked={eroSignatureSettings.defaultERO} onChange={(e) => setEROSignatureSettings({ ...eroSignatureSettings, defaultERO: e.target.checked })} data-test-auto="4853c2fc-e885-474b-8422-111296906260" />
                        <span className="label"></span>
                    </div>
                    <div className="preview">
                        Preview
                    </div>
                    <div className="signature">
                        <div className="signBlock">
                            <img className="userscanneruploadsign" alt="" src={eroSignatureSettings.signaturePath} data-test-auto="A6296DBF-243D-420C-941B-4BABB92565BB" />
                        </div>
                        <div className="uploadBlock">
                            <a href="#" className={"uploadSignature"}>
                                <UploadIcon />Upload Signature
                                <input type="file"
                                    value=""
                                    onChange={onStampUpload}
                                    className='btnuploadsettings' data-test-auto="00683e09-1cc5-4e47-b9a5-b7226749433e"
                                />
                            </a>
                            {imageSelectionError && <div className="imageSelectionError">Please upload valid image <br />format file.</div>}
                        </div>
                        <div className="infoBlock">
                            <button data-test-auto="612b1602-745b-403a-859f-b9cf65ccc348" title="Files must be jpg, png or bmp format and cannot exceed 300 x 100 px."><InfoIcon /></button>
                        </div>
                    </div>
                    {signatureUploadError && <div className="signatureUploadError">Please select a signature file to upload!</div>}
                    <div className={`automaticPlacement${isSignatureUploaded ? "" : " disabledItem"}`}>
                        <input type="checkbox" name="automaticPlacementCheckbox" id="automaticPlacementCheckbox" checked={eroSignatureSettings.isAutoPlaceSignature} onChange={(e) => setEROSignatureSettings({ ...eroSignatureSettings, isAutoPlaceSignature: e.target.checked })} data-test-auto="08b8e402-8719-4399-a57b-8c5c54f29a79" className={isSignatureUploaded ? "" : "disabledItem"} />
                        <div id="automaticPlacementText" className="automaticPlacementText">Automatically place <span>{eroSignatureSettings.userName}'s</span> signature stamp on e-file forms.</div>
                    </div>
                    {eroSignatureSettings.enableSignerDeligation && <div className="allowUsers">
                        <div className={`inputAndText${isSignatureUploaded ? "" : " disabledItem"}`}>
                            <input type="checkbox" name="allowUsersCheckbox" id="allowUsersCheckbox" checked={eroSignatureSettings.isEnableSignatureDelegation} onChange={(e) => setEROSignatureSettings({ ...eroSignatureSettings, isEnableSignatureDelegation: e.target.checked })} data-test-auto="8a49d4a6-358f-43f8-92f0-907cb2a8346d" className={isSignatureUploaded ? "" : "disabledItem"} />
                            <div id="allowUsersText" className="allowUsersText">Allow others to apply <span>{eroSignatureSettings.userName}'s</span> SafeSend signature.</div>
                        </div>
                        <div><button disabled={!eroSignatureSettings.isEnableSignatureDelegation} className={eroSignatureSettings.isEnableSignatureDelegation ? "" : "disableSelectUsers"} data-test-auto="4d3fb765-9d4c-470b-b699-af885dcac2c1" onClick={() => setShowSignatureDelegationModal(true)}><PersonHeart />Select Users</button></div>
                    </div>}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button data-test-auto="b5efd93e-4475-4016-9f7e-da39f78592b9" variant="secondary" className="eroSignatureCancel" onClick={handleClose}>
                    Cancel
                </Button>
                <Button data-test-auto="16a78c56-1abf-4fe8-b1e2-2680c9d26188" variant="primary" className="eroSignatureApply" disabled={eroSignatureInProgress} onClick={() => saveEROSignature(eroSignatureSettings)}>
                    Save & Close
                </Button>
            </Modal.Footer>
            <SignatureImageCrop
                file={file}
                show={showCropPanel}
                onHide={cancelCrop}
                getCroppedCanvas={getCroppedCanvas}
            />
            {showSignatureDelegationModal && <SignatureDelegationModal setShowSignatureDelegationModal={setShowSignatureDelegationModal} saveDelegateeIDs={saveDelegateeIDs} delegateeIDsFromDB={eroSignatureSettings.delegatee} />}
        </Modal>
    );
};

export default EROSignatureModal;