import React, { FC } from 'react';
import { ButtonToolbar, DropdownButton, OverlayTrigger, Popover } from 'react-bootstrap';
import Divider from 'src/components/svg/Divider';
import IconMore from 'src/components/svg/IconMore';
import NewUserIcon from 'src/components/svg/NewUserIcon';
import O365 from 'src/components/svg/O365';
import { USER_GROUPS_SHOW_LIMIT, CommonMessages, USER_OFFICE_LOCATION_SHOW_LIMIT } from 'src/helper/Constants';
import { userTabConstants } from '../../../../helper/rbac-constants';
import {
	IUserModel,
	IUserGroups,
	SSORevokeStatus,
	UserReviewStatus,
	AuthenticationProvider,
	IUserOfficeLocation,
} from '../Users.model';
import './UsersListTable.style.scss';

interface UsersListTableProps {
    users: IUserModel[];
    isLoading: boolean;
    setShowSaveUserModal: (showAddUserModal: boolean) => void;
    setSaveForUserId: (saveForUserId: number) => void;
    newUserIds: number[];
    resetPassword: (userId: number, userEmail: string, isO365: boolean) => void;
    setShowRevokeO365AccessModal: (showRevokeO365AccessModal: boolean) => void;
    setUserEmailRevoke: (userEmailRevoke: string) => void;
    setShowEROSignatureModal: (showEROSignatureModal: boolean) => void;
    setSignatureForUserId: (signatureForUserId: number) => void;
    isUseSignatureStampEnabled: boolean;
    setIsO365User: (isO365User: boolean) => void;
    setUserReviewStatus: (reviewStatus: number) => void;
    handleDeleteUserButtonClick: (user: IUserModel) => void;
	handleAuditReportBtnClick: (user: IUserModel) => void;
}
let UsersListTable: FC<UsersListTableProps> = (props) => {
	const {
		users,
		isLoading,
		newUserIds,
		resetPassword,
		setUserEmailRevoke,
		setShowRevokeO365AccessModal,
		setShowEROSignatureModal,
		isUseSignatureStampEnabled,
		setSignatureForUserId,
		setIsO365User,
		setUserReviewStatus,
		handleAuditReportBtnClick,
	} = props;

    const showName = (user: IUserModel) => {
		const isO365 =
			user.authenticationProviders === AuthenticationProvider.AzureAD && user.revokeStatus === SSORevokeStatus.None;
		const yellowBackgroundClass =
			isO365 && user.reviewStatus === UserReviewStatus.YetToReview ? ' yellow-background' : '';
        return (
			<div
				title={`${user.firstName.trim()} ${user.lastName.trim()}`}
				className={`nameColumnData${yellowBackgroundClass}`}>
                <div className={`${isO365 ? 'leftSideName width60' : 'leftSideName'}`}>
                    {newUserIds.some((newId) => newId === user.id) && <NewUserIcon />}
                    {`${user.firstName} ${user.lastName}`}
                </div>
				{isO365 && (
					<div className='rightSideIcon'>
						<div className='OS365Icon'>
                            <O365 />
                        </div>
						<div className='OS365Text'>Office365</div>
                        </div>
				)}
            </div>
        );
    };

    const showUserGroups = (userGroups: IUserGroups[]) => {
        let userGroupContent;
        const length = userGroups.length;
        if (length > USER_GROUPS_SHOW_LIMIT) {
            const popover = (
				<Popover id='popover-basic'>
                    <Popover.Body>
                        <ul>
							{userGroups.slice(USER_GROUPS_SHOW_LIMIT).map((userGroup: IUserGroups) => {
                                    return <li key={userGroup.groupId}>{userGroup.groupName}</li>;
							})}
                        </ul>
                    </Popover.Body>
                </Popover>
            );
            userGroupContent = userGroups.slice(0, USER_GROUPS_SHOW_LIMIT).map((userGroup: IUserGroups) => {
				return (
					<span
						key={userGroup.groupId}
						className='group-badge max-width26'
						title={userGroup.groupName}
						style={{
							backgroundColor: userGroup.BackgroundColor,
							border: `1px solid ${userGroup.borderColor}`,
							color: userGroup.fontColor,
						}}>
						{userGroup.groupName}
					</span>
				);
            });
            userGroupContent.push(
				<OverlayTrigger
					key='moreGroups'
					placement='bottom'
					overlay={popover}>
					<span className='moreGroups'>+{userGroups.length - USER_GROUPS_SHOW_LIMIT}&nbsp;more</span>
				</OverlayTrigger>,
            );
        } else {
            const groupClass = `group-badge${length === 3 ? ' max-width31' : length === 2 ? ' max-width48' : ' max-width98'}`;
            userGroupContent = userGroups.map((userGroup: IUserGroups) => {
				return (
					<span
						key={userGroup.groupId}
						className={groupClass}
						title={userGroup.groupName}
						style={{
							backgroundColor: userGroup.BackgroundColor,
							border: `1px solid ${userGroup.borderColor}`,
							color: userGroup.fontColor,
						}}>
						{userGroup.groupName}
					</span>
				);
            });
        }
        return userGroupContent;
    };

    const showOfficeLocations = (officeLocations: IUserOfficeLocation[]) => {
        let officeLocationContent;
        const length = officeLocations.length;
        if (length > USER_OFFICE_LOCATION_SHOW_LIMIT) {
            const popover = (
				<Popover id='popover-basic'>
                    <Popover.Body>
                        <ul>
							{officeLocations.slice(USER_OFFICE_LOCATION_SHOW_LIMIT).map((officeLocation: IUserOfficeLocation) => {
                                    return <li key={officeLocation.locationId}>{officeLocation.locationName}</li>;
							})}
                        </ul>
                    </Popover.Body>
                </Popover>
            );  
			officeLocationContent = officeLocations
				.slice(0, USER_OFFICE_LOCATION_SHOW_LIMIT)
				.map((officeLocation: IUserOfficeLocation) => {
					return (
						<span
							key={officeLocation.locationId}
							className='officeLocation-values max-width65'
							title={officeLocation.locationName}>
							{officeLocation.locationName}
						</span>
            );
				});
            officeLocationContent.push(
				<OverlayTrigger
					key='moreOfficeLocations'
					placement='bottom'
					overlay={popover}>
					<span className='moreOfficeLocations'>
						+{officeLocations.length - USER_OFFICE_LOCATION_SHOW_LIMIT}&nbsp;more
					</span>
				</OverlayTrigger>,
            );
        } else {
			const officeLocationClass = `officeLocation-values${
				length === 3 ? ' max-width31' : length === 2 ? ' max-width48' : ' max-width98'
			}`;
            officeLocationContent = officeLocations.map((officeLocation: IUserOfficeLocation) => {
				return (
					<span
						key={officeLocation.locationId}
						className={officeLocationClass}
						title={officeLocation.locationName}>
						{officeLocation.locationName}
					</span>
            );
			});
        }
        return officeLocationContent;
    };

    const hoverEROSignature = (user: IUserModel) => {
		let text = '';
        if (isUseSignatureStampEnabled && user.isPartner) {
			text = '';
        } else if (!user.isPartner) {
            text = CommonMessages.NonPartner;
        } else if (!isUseSignatureStampEnabled) {
            text = CommonMessages.SignatureStampDisabled;
        }
        return text;
    };

    const createMoreActions = (user: IUserModel) => {
        const isO365 = user.authenticationProviders === AuthenticationProvider.AzureAD;
        const partnerGroupCheck = user.isPartner;
        return (
			<ButtonToolbar className='btn-group-vertical'>
                <DropdownButton
					size='sm'
					title={<IconMore />}
					id='userMoreActions'
					data-test-auto='271576c6-b729-4e50-b51a-480831e76dc4'
					className='userMoreActions'>
					{!user.inactive && (
                    <li
							data-test-auto='2eafb813-cd9e-4419-9457-b540c3a68908'
                        data-resource-id={`${userTabConstants.prefix}_${userTabConstants.grid}_${userTabConstants.resetPassword}`}
							key='ResetPassword'
                        onClick={() => resetPassword(user.id, user.email, isO365 && user.revokeStatus === SSORevokeStatus.None)}
							className={isO365 && user.revokeStatus === SSORevokeStatus.None ? 'disabledItem' : ''}>
                        Reset&nbsp;Password
                    </li>
					)}
                    <li
						data-test-auto='b2970083-2474-4fc1-ae4f-080623edd883'
                        data-resource-id={`${userTabConstants.prefix}_${userTabConstants.grid}_${userTabConstants.eroSign}`}
						key='EROSignature'
                        title={hoverEROSignature(user)}
                        onClick={() => handleEROSignatureClick(user)}
						className={isUseSignatureStampEnabled && partnerGroupCheck ? '' : 'disabledItem'}>
                        ERO&nbsp;Signature
                    </li>
                    <li
						data-test-auto='a08f98d1-e6eb-4522-a97f-dcae59bf187a'
                        data-resource-id={`${userTabConstants.prefix}_${userTabConstants.grid}_${userTabConstants.revokeOffice365}`}
						key='RevokeOffice365'
                        onClick={() => handleRevokeO365AccessClick(user.email, isO365, user.revokeStatus)}
						className={isO365 && user.revokeStatus === SSORevokeStatus.None ? '' : 'disabledItem'}>
                        Revoke&nbsp;Office&nbsp;365
                    </li>
                    <li
						data-test-auto='F80C4006-FAA1-4821-8A0F-E5048F06AF68'
						data-resource-id={`${userTabConstants.prefix}_${userTabConstants.grid}_${userTabConstants.auditReport}`}
						key='AuditReport'
						onClick={() => handleAuditReportBtnClick(user)}>
						Audit&nbsp;Report
					</li>
                </DropdownButton>
            </ButtonToolbar>
        );
    };

    const handleEditUserButtonClick = (user: IUserModel) => {
		const isO365 =
			user.authenticationProviders === AuthenticationProvider.AzureAD && user.revokeStatus === SSORevokeStatus.None;
        if (isO365) {
            setIsO365User(true);
        } else {
            setIsO365User(false);
        }
        setUserReviewStatus(user.reviewStatus);
        props.setSaveForUserId(user.id);
        props.setShowSaveUserModal(true);
    };

    const handleRevokeO365AccessClick = (userEmail: string, isO365: boolean, revokeStatus: number) => {
        if (isO365 && revokeStatus === SSORevokeStatus.None) {
            setShowRevokeO365AccessModal(true);
            setUserEmailRevoke(userEmail);
        }
    };

    const handleEROSignatureClick = (user: IUserModel) => {
        const partnerGroupCheck = user.isPartner;
        if (isUseSignatureStampEnabled && partnerGroupCheck) {
            setShowEROSignatureModal(true);
            setSignatureForUserId(user.id);
        }
    };

    return (
		<table className='table table-striped table-hover usersListTable'>
            <thead>
                <tr>
					<th
						className='nameCol'
						scope='col'>
						Name
					</th>
					<th
						className='emailCol'
						scope='col'>
						Email
					</th>
					<th
						className='userGroupsCol'
						scope='col'>
						User&nbsp;Group
					</th>
					<th
						className='officeLocationsCol'
						scope='col'>
						Office&nbsp;Location
					</th>
					<th
						className='actionsCol'
						scope='col'>
						Actions
					</th>
                </tr>
            </thead>
            <tbody>
				{isLoading ? (
                        <tr>
						<td
							data-toggle='collapse'
							colSpan={5}
							className='usersLoadingArea'>
							<div className='text-center'>
								<div
									className='spinner-border'
									role='status'></div>
                                    <div>
									<span className='usersLoadingText'>Loading...</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
				) : users.length === 0 ? (
                            <tr>
						<td
							data-toggle='collapse'
							colSpan={5}
							className='usersLoadingArea text-center'>
                                    No matching records found
                                </td>
                            </tr>
				) : (
                            users.map((user) => {
                                return (
							<tr
								key={user.id}
								className='userRow'
								data-resource-id={`${userTabConstants.prefix}_${userTabConstants.grid}`}>
								<td className='nameColData'>{showName(user)}</td>
								<td
									className='emailColData'
									title={user.email}>
									{user.email}
                                        </td>
								<td className='userGroupsColData'>{showUserGroups(user.groups)}</td>
								<td className='officeLocationsColData'>{showOfficeLocations(user.officeLocations)}</td>
								<td className='actionsColData'>
									<div className='alignActionButtons'>
										<div
											data-resource-id={`${userTabConstants.prefix}_${userTabConstants.grid}_${userTabConstants.editButton}`}>
											<button
												data-test-auto={`3cfb242a-38b9-4835-ba3c-507d9e69c162${user.id}`}
												className='editUserButton'
												onClick={() => handleEditUserButtonClick(user)}>
												Edit
											</button>
										</div>
										<div className='divider'>
											<Divider />
										</div>
										<div
											data-resource-id={`${userTabConstants.prefix}_${userTabConstants.grid}_${userTabConstants.deleteButton}`}>
											<button
												data-test-auto={`912e12c4-617c-433e-946c-124e2e3d63cf${user.id}`}
												className='deleteUserButton'
												onClick={() => props.handleDeleteUserButtonClick(user)}>
												Delete
											</button>
                                        </div>
										<div className='divider'>
											<Divider />
                                        </div>
                                                {createMoreActions(user)}
                                    </div>
                                        </td>
                                    </tr>
                                );
                            })
				)}
            </tbody>
        </table>
    );
};
UsersListTable = React.memo(UsersListTable);
export default UsersListTable;