import { connect } from 'react-redux';

import { type ApplicationState } from '../../store/index';
import * as CompanyManagementStore from './company-management.apis';
import CompanyManagement from './index';

export default connect(
	(state: ApplicationState) => ({
		companyData: state.companyData,
		companyManagementData: state.companyManagementData,
	}),
	{
		...CompanyManagementStore.actionCreators,
	},
)(CompanyManagement as React.FC);
