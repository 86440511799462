import { ICustomDropdownOption } from 'src/components/common/customDropdown/CustomDropdownWithCustomOptions';
import { NotificationAction, StatusType } from '../../pages/common/notification/notification.reducer';
import { actionTypes } from './Client.actions';

export interface IClientData {
	isLoading: boolean;
	clients: IClientModel[];
	count: number;
}

export interface PageProps {
	pageIndex: number;
	pageSize: number;
}

export interface IClientListInfo {
	items: IClientModel[];
	count: number;
}

export interface IClientModel {
	clientBasicInfoId: number;
	clientId: string;
	type: number;
	uid: string;
	name: string;
	emailAddress: string;
	countryCode: string;
	mobileNumber: string;
	ero: number;
	eroFirstName: string;
	eroLastName: string;
	dob: string;
	isDeceased: boolean;
	mfj: boolean;
	defaultSignFirst: boolean;
	spouseSsn: string;
	spouseName: string;
	spouseEmailAddress: string;
	spouseDob: string;
	spouseCountryCode: string;
	spouseMobileNumber: string;
	spouseIsDeceased: boolean;
	locationId: number;
	locationName: string;
	taxPayerOptedIn?: boolean;
	spouseOptedIn?: boolean;
	isTaxpayerSentSTOP?: boolean;
	isSpouseSentSTOP?: boolean;
}

export const initialClient: IClientModel = {
	clientBasicInfoId: 0,
	clientId: '',
	type: 0,
	uid: '',
	name: '',
	emailAddress: '',
	countryCode: '',
	mobileNumber: '',
	ero: 0,
	eroFirstName: '',
	eroLastName: '',
	dob: '',
	isDeceased: false,
	mfj: false,
	defaultSignFirst: false,
	spouseSsn: '',
	spouseName: '',
	spouseEmailAddress: '',
	spouseDob: '',
	spouseCountryCode: '',
	spouseMobileNumber: '',
	spouseIsDeceased: false,
	locationId: 0,
	locationName: '',
	taxPayerOptedIn: false,
	spouseOptedIn: false,
	isTaxpayerSentSTOP: false,
	isSpouseSentSTOP: false,
};

export interface IEro {
	id: number;
	firstName: string;
	lastName: string;
	emailAddress: string;
}

export interface IColumnOptions {
	columnName: string;
	columnOrder: number;
	isActive: boolean;
}

export enum clientType {
	SSN = 1,
	EIN = 2,
}

export interface IOfficeLocation {
	address1: string;
	address2: string;
	city: string;
	countryCode: string;
	fax: string;
	isPrimary: boolean;
	locationId: number;
	locationNumber: number;
	name: string;
	phone: string;
	state: number;
	website: string;
	zip: string;
}

export const columnNames = {
	clientId: 'Client ID',
	eroSigner: 'ERO / Signer',
	officeLocation: 'Office Location',
	name: 'Name',
	SsnEin: 'SSN / EIN',
	emailAddress: 'Email Address',
	dob: 'DOB',
	mobile: 'Mobile',
	spouseName: 'Spouse Name',
	spouseSsn: 'Spouse SSN',
	spouseEmailAddress: 'Spouse Email Address',
	spouseDob: 'Spouse DOB',
	spouseMobile: 'Spouse Mobile',
};

export const defaultColumnsOptions: IColumnOptions[] = [
	{
		columnName: columnNames.clientId,
		columnOrder: 1,
		isActive: true,
	},
	{
		columnName: columnNames.eroSigner,
		columnOrder: 2,
		isActive: true,
	},
	{
		columnName: columnNames.officeLocation,
		columnOrder: 3,
		isActive: true,
	},
	{
		columnName: columnNames.name,
		columnOrder: 4,
		isActive: true,
	},
	{
		columnName: columnNames.SsnEin,
		columnOrder: 5,
		isActive: true,
	},
	{
		columnName: columnNames.emailAddress,
		columnOrder: 6,
		isActive: true,
	},
	{
		columnName: columnNames.dob,
		columnOrder: 7,
		isActive: false,
	},
	{
		columnName: columnNames.mobile,
		columnOrder: 8,
		isActive: false,
	},
	{
		columnName: columnNames.spouseName,
		columnOrder: 9,
		isActive: false,
	},
	{
		columnName: columnNames.spouseSsn,
		columnOrder: 10,
		isActive: false,
	},
	{
		columnName: columnNames.spouseEmailAddress,
		columnOrder: 11,
		isActive: false,
	},
	{
		columnName: columnNames.spouseDob,
		columnOrder: 12,
		isActive: false,
	},
	{
		columnName: columnNames.spouseMobile,
		columnOrder: 13,
		isActive: false,
	},
];

export const columnOptionsOrder: string[] = [
	columnNames.clientId,
	columnNames.eroSigner,
	columnNames.officeLocation,
	columnNames.dob,
	columnNames.emailAddress,
	columnNames.mobile,
	columnNames.name,
	columnNames.SsnEin,
	columnNames.spouseDob,
	columnNames.spouseEmailAddress,
	columnNames.spouseMobile,
	columnNames.spouseName,
	columnNames.spouseSsn,
];

export enum SortingColumnNames {
	clientId = 0,
	name = 1,
	emailAddress = 2,
	spouseName = 3,
	spouseEmailAddress = 4,
	officeLocation = 5,
}

export const clickableColumns: string[] = [
	columnNames.clientId,
	columnNames.name,
	columnNames.emailAddress,
	columnNames.spouseName,
	columnNames.spouseEmailAddress,
	columnNames.officeLocation,
];

export enum templateType {
	Individual = 1,
	Entity = 2,
}

export const templateOptions: ICustomDropdownOption[] = [
	{ value: templateType.Individual, label: 'for Individual' },
	{ value: templateType.Entity, label: 'for Entity' },
];

export type KnownAction = DispatchAction | NotificationAction;

export type DispatchAction = RequestClientListAction | ReceiveClientListAction | ErrorClientManagement;

interface ErrorClientManagement {
	type: actionTypes.ERROR_CLIENT_MANAGEMENT;
	statusMessage: string;
	statusType: StatusType;
}

interface RequestClientListAction {
	type: actionTypes.REQUEST_CLIENT_LIST;
}

interface ReceiveClientListAction {
	type: actionTypes.RECEIVE_CLIENT_LIST;
	clientListInfo: IClientListInfo;
}
