import { IMenuSection, ISideMenuItem } from '@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu';
import { IWidgetData, IWidgetDropdownItem } from 'src/pages/common/common.types';
import { IProductData } from '../pages/welcome/welcome.types';

export function IsRouteDisabled(leftMenu: IMenuSection[], widgetData: IWidgetData[], addonFeatureData: IProductData[], url: string | string[]) {
    let matchedMenu;
    leftMenu.forEach((menuSection: IMenuSection) => {
        if (menuSection.items?.length) {
            menuSection.items.forEach((sideMenuItem: ISideMenuItem) => {
                if (sideMenuItem.items?.length) {
                    const leftMenu = GetMatchedLeftMenu(sideMenuItem, url);
                    if (leftMenu) matchedMenu = leftMenu;
                } else if (sideMenuItem.route != '') {
                    if (typeof url === 'string' && url.toLowerCase() == sideMenuItem.route.toLowerCase()) {
                        matchedMenu = sideMenuItem;
                    } else if (Array.isArray(url)) {
                        url.forEach(optionalUrl => {
                            if (optionalUrl.toLowerCase() == sideMenuItem.route.toLowerCase()) {
                                matchedMenu = sideMenuItem;
                            }
                        });
                    }
                }
            });
        }
    });

    widgetData.forEach((widgetMenu: IWidgetData) => {
        if (widgetMenu.items?.length > 0) {
            widgetMenu.items.forEach((widgetItem: IWidgetDropdownItem) => {
                if (widgetItem.route != '') {
                    const widgetURL = new URL(widgetItem.route);
                    if (typeof url === 'string' && url.toLowerCase() == widgetURL.pathname.toLowerCase()) {
                        matchedMenu = widgetItem;
                    } else if (Array.isArray(url)) {
                        url.forEach(optionalUrl => {
                            if (optionalUrl.toLowerCase() == widgetURL.pathname.toLowerCase()) {
                                matchedMenu = widgetItem;
                            }
                        });
                    }
                }
            });
        }
    });

    addonFeatureData.forEach((addOnFeatureItem: IProductData) => {
        if (addOnFeatureItem.route != '') {
            const addonFeatureURL = new URL(addOnFeatureItem.route);
            if (typeof url === 'string' && url.toLowerCase() == addonFeatureURL.pathname.toLowerCase()) {
                matchedMenu = addOnFeatureItem;
            } else if (Array.isArray(url)) {
                url.forEach(optionalUrl => {
                    if (optionalUrl.toLowerCase() == addOnFeatureItem.route.toLowerCase()) {
                        matchedMenu = addOnFeatureItem;
                    }
                });
            }
        }
    });
    return matchedMenu ? matchedMenu.disabled : true;
}

export function GetMatchedLeftMenu(sideMenuItem: ISideMenuItem, url: string | string[]) {
    let matchedLeftMenu;
    if (sideMenuItem.items?.length) {
        sideMenuItem.items.forEach((childSideMenuItem: ISideMenuItem) => {
            const leftMenu = GetMatchedLeftMenu(childSideMenuItem, url);
            if (leftMenu) matchedLeftMenu = leftMenu;
        });
    } else if (sideMenuItem.route != '') {
        if (typeof url === 'string' && url.toLowerCase() == sideMenuItem.route.toLowerCase()) {
            matchedLeftMenu = sideMenuItem;
        } else if (Array.isArray(url)) {
            url.forEach(optionalUrl => {
                if (optionalUrl.toLowerCase() == sideMenuItem.route.toLowerCase()) {
                    matchedLeftMenu = sideMenuItem;
                }
            });
        }
    }
    return matchedLeftMenu;
}

export function HasMenuAccess(leftMenu: IMenuSection[], headerMenuCaption:string) {
    let hasAccces:boolean=true;
    leftMenu.forEach((menuSection: IMenuSection) => {
        if (menuSection.items?.length) {
            menuSection.items.forEach((sideMenuItem: ISideMenuItem) => {
                 if(headerMenuCaption==sideMenuItem.caption){
                    hasAccces = !sideMenuItem.disabled;
                    return;
                 }
            });
        }
    });
    return hasAccces;
}
