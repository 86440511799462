import { type FC } from 'react';
import { ButtonToolbar, DropdownButton } from 'react-bootstrap';
import { ReactComponent as Down } from 'src/components/svg/chevron-grey-down.svg';
import { ReactComponent as Up } from 'src/components/svg/chevron-grey-up.svg';
import { ReactComponent as Menu } from 'src/components/svg/group-menu.svg';
import { ReactComponent as New } from 'src/components/svg/new-location.svg';
import { ReactComponent as StarFill } from 'src/components/svg/star-fill.svg';

import { initialOfficeLocationData } from '../save-office-location-modal/save-office-location-modal.types';
import styles from './company-cards.module.scss';
import { type ICompanyCards } from './company-cards.types';

const CompanyCards: FC<ICompanyCards> = (props) => {
	const {
		item = initialOfficeLocationData,
		isNew = false,
		address1 = '',
		address2 = '',
		city = '',
		countryCode = '',
		fax = '',
		isPrimary = false,
		locationId = -1,
		locationNumber = -1,
		name = '',
		phone = '',
		website = '',
		zip = '',
		stateDetails = {},
		cardOpen = -1,
		setCardOpen,
		handleEditClick,
		handleRemoveClick,
	} = props;

	const getClassName = (id: number): string => {
		if (isNew) {
			if (cardOpen === id)
				return `${styles.company_cards_container} ${styles.new_card_container} ${
					(phone && phone.length > 0) || (fax && fax.length > 0) || (website && website.length > 0)
						? styles.full_height_container
						: ''
				}`;
			return `${styles.company_cards_container} ${styles.new_card_container}`;
		}
		if (cardOpen === id)
			return `${styles.company_cards_container} ${
				(phone && phone.length > 0) || (fax && fax.length > 0) || (website && website.length > 0)
					? styles.full_height_container
					: ''
			}`;
		return `${styles.company_cards_container}`;
	};

	const createMoreActions = () => {
		return (
			<ButtonToolbar className='btn-group-vertical'>
				<DropdownButton
					size='sm'
					title={<Menu />}
					id='userMoreActions'
					data-test-auto='271576c6-b729-4e50-b51a-480831e76dc4'
					className={styles.officeCardMoreOptions}>
					<li
						data-test-auto='2eafb813-cd9e-4419-9457-b540c3a68908'
						key='Edit'
						onClick={() => {
							handleEditClick(item);
						}}
						className={styles.company_card_header_menu_dropdown_edit}>
						Edit
					</li>
					<li
						data-test-auto='b2970083-2474-4fc1-ae4f-080623edd883'
						key='Remove'
						onClick={() => {
							handleRemoveClick(item);
						}}
						title={isPrimary ? 'Primary location cannot be removed' : ''}
						className={`${styles.company_card_header_menu_dropdown_remove} ${isPrimary ? styles.disableMenu : ''}`}>
						Remove
					</li>
				</DropdownButton>
			</ButtonToolbar>
		);
	};

	return (
		<div className={styles.company_card_wrapper}>
			{isNew === true ? (
				<div className={styles.new_card_indicator}>
					<New />
				</div>
			) : null}
			<div className={getClassName(locationId)}>
				<div className={styles.company_card_content}>
					<div className={styles.company_card_header}>
						<div
							className={` ${
								isPrimary ? styles.company_card_header_left_section_primary : styles.company_card_header_left_section
							}`}>
							<div className={styles.company_card_header_location}>LOCATION &#x23;{locationNumber}</div>
							<div
								className={styles.company_card_header_name}
								title={name}>
								{name}
							</div>
						</div>
						<div className={styles.company_card_header_right_section}>
							{isPrimary && (
								<div className={styles.company_card_header_primaryMark}>
									<StarFill />
								</div>
							)}
							{createMoreActions()}
						</div>
					</div>
					<div className={styles.company_card_body}>
						<div
							className={styles.company_card_body_address1}
							title={`${address2} ${address1}`}>
							{address2} {address1}
						</div>
						<div
							className={styles.company_card_body_city}
							title={`${city} ${stateDetails.name}`}>
							{city}, {stateDetails?.name || ''}
						</div>

						<div className={styles.company_card_body_address2}>{zip}</div>
						<br />
						{phone && (
							<div className={styles.company_card_body_phone}>
								{`1-${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`}
							</div>
						)}
						{fax && (
							<div className={styles.company_card_body_fax}>
								{`1-${fax.slice(0, 3)}-${fax.slice(3, 6)}-${fax.slice(6)}`}
							</div>
						)}
						{website && (
							<div
								className={styles.company_card_body_secondary_website}
								title={website}>
								{website}
							</div>
						)}
					</div>
					<div
						data-test-auto='21c4f274-e553-4085-9da4-ed306a47fa54'
						className={`${cardOpen === locationId ? styles.drop_action_button : styles.drop_action_button_Up}`}
						onClick={() => {
							if (cardOpen === locationId) setCardOpen(-1);
							else setCardOpen(locationId);
						}}>
						{cardOpen === locationId ? (
							<div>
								<Up />
							</div>
						) : (
							<div>
								<Down />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CompanyCards;
