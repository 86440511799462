import {
	USER_SIGNED_OUT,
	STORE_USER_ERROR,
	USER_EXPIRED,
	STORE_USER,
	LOADING_USER,
	RECEIVED_SESSION_IDLE_MINUTES,
	USER_PRIVILEGE_CHANGED,
	RESET_USER_PRIVILEGE_CHANGE_STATE,
	USER_LOGGEDOUT
} from "./auth.actions";

export interface UserAuthState {
	user: any;
	isLoadingUser: boolean;
	sessionIdleMinutes: number;
    loggedOut: boolean;
}

export interface IUserPreviligeState {
	logoutCause: LogoutCause;
	userPrivilegesChanged: boolean;
}

const initialState: UserAuthState = {
	user: null,
	isLoadingUser: false,
	sessionIdleMinutes: 0,
	loggedOut: false
};

export enum LogoutCause {
	None = 0,
	UserPermissionChange = 1,
	UserDelete = 2,
	ProductTierChange = 3,
	CompanyDisable = 4,
}

const initialUserPreviligeState: IUserPreviligeState = {
	logoutCause: LogoutCause.None,
	userPrivilegesChanged: false,
}

export const reducer = (state = initialState, action: any) => {
	switch (action.type) {
		case LOADING_USER:
			return {
				...state,
				isLoadingUser: true,
			};
		case STORE_USER:
			return {
				...state,
				isLoadingUser: false,
				user: action.payload,
			};
		case USER_EXPIRED:
		case STORE_USER_ERROR:
		case USER_SIGNED_OUT:
			return {
				...state,
				user: null,
				isLoadingUser: false,
			};
		case RECEIVED_SESSION_IDLE_MINUTES:
			return {
				...state,
				sessionIdleMinutes: action.payload,
			};
		case USER_LOGGEDOUT:
			return {
				...state,
				loggedOut: true
			}
		default:
			return state;
	}
};

export const userPrivilegeReducer = (state = initialUserPreviligeState, action: any) => {
	switch (action.type) {
		case USER_PRIVILEGE_CHANGED:
			return {
				...state,
				userPrivilegesChanged: true,
				logoutCause: action.userAutoLogoutCause
			}
		case RESET_USER_PRIVILEGE_CHANGE_STATE:
			return {
				...state,
				userPrivilegesChanged: false,
				logoutCause: LogoutCause.None
			}
		default:
			return state
	}
}
