import axios, { type AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/Constants';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { type AppThunkAction } from 'src/store/index';
import { ipFilteringResources } from 'src/helper/rbac-constants';

import { actionTypes } from './ip-filtering.action';
import { type IFilertingOptionModel, type KnownAction } from './ip-filtering-options.types';
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {
	getClientIpAddress: (): AppThunkAction<KnownAction> => (dispatch) => {
		axios
			.get(`${API_BASE_URL}/api/security-setting/ip-filtering-setting/client-ip-address`, {
				headers: {
					'X-Resource-Id': `${ipFilteringResources.prefix}_${ipFilteringResources.ipFilterControl}_${ipFilteringResources.saveButton}`,
				},
			})
			.then((response: AxiosResponse<any>) => {
				const { data } = response;
				dispatch({ type: actionTypes.RECEIVE_CLIENT_IP, data });
			})
			.catch((error) => {
				logger && logger.trackWarning('Error while getClientIpAddress', { 'Error': error?.response?.data});
				const statusMessage: string = error.statusText;
				if (typeof statusMessage === 'string')
					dispatch({
						type: notificationTypes.NOTIFICATION,
						statusMessage,
						statusType: StatusType.Error,
					});
			});
	},

	requestFilteringOption: (): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({ type: actionTypes.REQUEST_FILTERING_OPTION, isLoading: true });
		axios
			.get(`${API_BASE_URL}/api/security-setting/ip-filtering-setting`, {
				headers: {
					'X-Resource-Id': `${ipFilteringResources.prefix}_${ipFilteringResources.ipFilterControl}_${ipFilteringResources.saveButton}`,
				},
			})
			.then((response: AxiosResponse<IFilertingOptionModel>) => {
				const { data } = response;
				dispatch({ type: actionTypes.RECEIVE_FILTERING_OPTION, data, isLoading: false });
			})
			.catch((error) => {
				logger && logger.trackWarning('Error while requestFilteringOption', { 'Error': error?.response?.data});
				const statusMessage: string = error.statusText;
				if (typeof statusMessage === 'string')
					dispatch({
						type: notificationTypes.NOTIFICATION,
						statusMessage,
						statusType: StatusType.Error,
					});
			});
	},

	updateFilteringOption:
		(value: boolean, callback: () => void = () => null): AppThunkAction<KnownAction> =>
		(dispatch) => {
			dispatch({ type: actionTypes.REQUEST_FILTERING_OPTION, isLoading: true });
			axios
				.post(`${API_BASE_URL}/api/security-setting/ip-filtering-setting`, value.toString(), {
					headers: {
						'Content-Type': 'application/json-patch+json',
						'X-Resource-Id': `${ipFilteringResources.prefix}_${ipFilteringResources.ipFilterControl}_${ipFilteringResources.saveButton}`,
					},
				})
				.then((response: AxiosResponse) => {
					if (response.status === 200)
						dispatch({
							type: actionTypes.UPDATE_FILTERING_OPTION,
							data: value,
							isLoading: false,
						});

					dispatch({
						type: notificationTypes.NOTIFICATION,
						statusMessage: 'IP Filtering settings updated successfully.',
						statusType: StatusType.Success,
					});
					callback();
				})
				.catch((error) => {
					logger && logger.trackWarning('Error while updateFilteringOption', { 'Error': error?.response?.data});
					const statusMessage: string = error.statusText;
					if (typeof statusMessage === 'string')
						dispatch({
							type: notificationTypes.NOTIFICATION,
							statusMessage,
							statusType: StatusType.Error,
						});
				});
		},
};
