import React, { FC } from 'react';

const Download: FC = () => {
	return (
		<svg
			width='17'
			height='17'
			viewBox='0 0 17 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_3597_163953)'>
				<rect
					width='16'
					height='16'
					transform='translate(0.5 0.5)'
					fill='white'
					fillOpacity='0.01'
				/>
				<g clipPath='url(#clip1_3597_163953)'>
					<path
						d='M1 10.399C1.13261 10.399 1.25979 10.4517 1.35355 10.5455C1.44732 10.6392 1.5 10.7664 1.5 10.899V13.399C1.5 13.6642 1.60536 13.9186 1.79289 14.1061C1.98043 14.2937 2.23478 14.399 2.5 14.399H14.5C14.7652 14.399 15.0196 14.2937 15.2071 14.1061C15.3946 13.9186 15.5 13.6642 15.5 13.399V10.899C15.5 10.7664 15.5527 10.6392 15.6464 10.5455C15.7402 10.4517 15.8674 10.399 16 10.399C16.1326 10.399 16.2598 10.4517 16.3536 10.5455C16.4473 10.6392 16.5 10.7664 16.5 10.899V13.399C16.5 13.9295 16.2893 14.4382 15.9142 14.8132C15.5391 15.1883 15.0304 15.399 14.5 15.399H2.5C1.96957 15.399 1.46086 15.1883 1.08579 14.8132C0.710714 14.4382 0.5 13.9295 0.5 13.399V10.899C0.5 10.7664 0.552678 10.6392 0.646447 10.5455C0.740215 10.4517 0.867392 10.399 1 10.399Z'
						fill='#0973BA'
					/>
					<path
						d='M8.146 12.353C8.19245 12.3996 8.24762 12.4365 8.30837 12.4617C8.36911 12.4869 8.43423 12.4999 8.5 12.4999C8.56577 12.4999 8.63089 12.4869 8.69163 12.4617C8.75238 12.4365 8.80755 12.3996 8.854 12.353L11.854 9.35302C11.9479 9.25914 12.0006 9.1318 12.0006 8.99902C12.0006 8.86625 11.9479 8.73891 11.854 8.64502C11.7601 8.55114 11.6328 8.49839 11.5 8.49839C11.3672 8.49839 11.2399 8.55114 11.146 8.64502L9 10.792V1.99902C9 1.86642 8.94732 1.73924 8.85355 1.64547C8.75979 1.5517 8.63261 1.49902 8.5 1.49902C8.36739 1.49902 8.24021 1.5517 8.14645 1.64547C8.05268 1.73924 8 1.86642 8 1.99902V10.792L5.854 8.64502C5.76011 8.55114 5.63278 8.49839 5.5 8.49839C5.36722 8.49839 5.23989 8.55114 5.146 8.64502C5.05211 8.73891 4.99937 8.86625 4.99937 8.99902C4.99937 9.1318 5.05211 9.25914 5.146 9.35302L8.146 12.353Z'
						fill='#0973BA'
					/>
				</g>
			</g>
			<defs>
				<clipPath id='clip0_3597_163953'>
					<rect
						width='16'
						height='16'
						fill='white'
						transform='translate(0.5 0.5)'
					/>
				</clipPath>
				<clipPath id='clip1_3597_163953'>
					<rect
						width='16'
						height='16'
						fill='white'
						transform='translate(0.5 0.5)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Download;
