import GripVertical from 'src/components/svg/GripVertical';
import React, { FC, useState } from 'react';
import './DraggableListSD.style.scss';

const DraggableListSD: FC<any> = (props) => {
    const {
        listData = [],
        displayError = false,
        errorText = ''
    } = props;

    const [selectedList, setSelectedList] = useState<any>([]);

    const dragStart = (e: any, data: any) => {
        const newSelectedList = [...selectedList, data];
        setSelectedList([...newSelectedList]);

        e.dataTransfer.setData('text/plain', JSON.stringify(newSelectedList));
    };

    const dragEnd = () => {
        setSelectedList([]);
    }

    const handleClick = (data: any) => {
        let newSelectedList: any = []
        if (selectedList.findIndex((listDatum: any) => listDatum.value === data.value) === -1) {
            newSelectedList = [...selectedList, data];
        } else {
            newSelectedList = selectedList.filter((datum: any) => datum.value !== data.value)
        }
        setSelectedList([...newSelectedList]);
    };

    return (
        <div className='draggable-list-container signatureDelegationDragContainer'>
            {displayError ?
                <div className='error_div'>
                    {errorText}
                </div> :
                listData.sort((a: any, b: any) => a.name.toLowerCase().localeCompare(b.name)).map((datum: any, index: any) => (
                    <div
                        className={selectedList.findIndex((listItem: any) => datum.value === listItem.value) !== -1 ? 'draggable-list-card-SD selected-card' : 'draggable-list-card-SD'}
                        onDragStart={(e) => dragStart(e, datum)}
                        onDragEnd={() => dragEnd()}
                        onClick={() => handleClick(datum)}
                        key={index}
                        draggable={true}
                        title={datum.name}
                    >
                        <div className='grip-container'>
                            <GripVertical />
                        </div>
                        <div className='content-container'>
                            {datum.name.slice(0, 50)}
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export default DraggableListSD;