export enum actionTypes {
    REQUEST_LOGIN_HISTORY = "request_login_history",
    RECEIVE_LOGIN_HISTORY = "receive_login_history",
    COMPLETE_LOGIN_HISTORY = "complete_login_history",

    REQUEST_WIDGET_DATA = "request_widget_data",
    RECEIVE_WIDGET_DATA = "receive_widget_data",
    COMPLETE_WIDGET_DATA = "complete_widget_data",

    RECEIVE_SIDEMENU_DATA = "receive_sidemenu_data",
    RESET_CACHE_API_CALLED = "reset_cache_api_called",
    CHANGE_ACTIVE_STATE = "change_active_state"
}