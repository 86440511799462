import axios from "axios";
import { API_BASE_URL, UserManagementConstants } from "../../../helper/Constants";
import { StatusType } from "../notification/notification.reducer";
import { AppThunkAction } from "../../../store";
import { IUserProfile, KnownAction } from "./userProfile.types";
import { actionTypes } from "./userProfile.actions";
import { actionTypes as notificationTypes } from "../notification/notification.types";
import { logger } from 'src/oidcClient/authProvider';


export const requestUserProfile =
    (reload = false): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            if (reload || !getState().userProfile.emailAddress) {
                dispatch({ type: actionTypes.REQUEST_USER_PROFILE });

                axios
                    .get(`${API_BASE_URL}/api/user-management/user-details`)
                    .then((response: any) => {
                        const data = response.data;
                        dispatch({ type: actionTypes.RECEIVE_USER_PROFILE, user: data });
                    });
            }
        };

export const saveMyAccount =
    (
        userInfo: IUserProfile,
        notification: boolean,
        onResponse: (response: any, error: any) => void
    ): AppThunkAction<KnownAction> =>
        (dispatch) => {
            dispatch({ type: actionTypes.REQUEST_USER_PROFILE });

            axios
                .put(`${API_BASE_URL}/api/user-management/user-details`,
                    userInfo
                )
                .then((response: any) => {
                    const data = response.data;
                    dispatch({
                        type: actionTypes.UPDATE_USER_PROFILE,
                        userDetails: userInfo,
                    });
                    if (onResponse) {
                        onResponse(data, null);
                    }
                    if (notification) {
                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: UserManagementConstants.UserUpdationSuccess,
                            statusType: StatusType.Success,
                        });
                    }
                })
                .catch((error) => {
                    logger && logger.trackWarning('Error while saveMyAccount', { 'Error': error?.response?.data});
                    if (onResponse) {
                        onResponse(null, error);
                    }
                    if (notification) {
                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: UserManagementConstants.SavingUserError,
                            statusType: StatusType.Error,
                        });
                    }
                });
        };
