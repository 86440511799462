import React, { FC } from 'react';
import FilterIcon from 'src/components/svg/FilterIcon';
import ExportIcon2 from 'src/components/svg/ExportIcon2';
import DollarIcon from 'src/components/svg/DollarIcon';
import CustomDatepicker from 'src/components/common/customDatepicker';
import CalenderIcon from 'src/components/svg/CalendarIcon';
import styles from './PaymentHistoryHead.module.scss';
import { IPaymentHistoryList } from '../PaymentHistory.model';
import { paymentConstants } from '../../../helper/rbac-constants';

interface PaymentHistoryHeadProps {
	fromDate: Date;
	onFromDateChange: (date: any) => void;
	toDate: Date;
	onToDateChange: (date: any) => void;
	onFilter: () => void;
	exportToExcel: () => void;
	paymentHistoryList: IPaymentHistoryList[];
	currentBalance: number;
	setShowPurchaseReturnModal: (showPurchaseReturnModal: boolean) => void;
}

const PaymentHistoryHead: FC<PaymentHistoryHeadProps> = (props) => {
	const {
		fromDate,
		onFromDateChange,
		toDate,
		onToDateChange,
		onFilter,
		exportToExcel,
		paymentHistoryList,
		currentBalance,
		setShowPurchaseReturnModal,
	} = props;

	const showCalendar = (target: string) => {
		target === 'fromDate'
			? document.querySelector('#fromDateInput .react-datepicker__input-container input').click()
			: document.querySelector('#toDateInput .react-datepicker__input-container input').click();
	};

	return (
		<div className={styles.paymentsHeadSection}>
			<div className={styles.filterSection}>
				<ul className={styles.filterHeaderTitle}>
					<li className={styles.fromText}>From</li>
					<li className={styles.toText}>To</li>
				</ul>
				<ul className={styles.filterDate}>
					<li
						className={styles.fromDate}
						data-test-auto='9bfd7dc2-212c-45d5-9900-7567949ab1a3'
						id='fromDateInput'>
						<CustomDatepicker
							selectedDate={fromDate}
							setSelectedDate={onFromDateChange}
							placeholderText='MM/DD/YYYY'
						/>
						<div
							onClick={() => {
								showCalendar('fromDate');
							}}
							className={styles.calendarIcon}
							data-test-auto='f8d60085-a052-438c-bcfd-5fa7ef2307fc'>
							<CalenderIcon />
						</div>
					</li>
					<li
						className={styles.toDate}
						data-test-auto='64f376fc-b8fd-4d81-9646-bd970e95e7dc'
						id='toDateInput'>
						<CustomDatepicker
							selectedDate={toDate}
							setSelectedDate={onToDateChange}
							placeholderText='MM/DD/YYYY'
						/>
						<div
							onClick={() => {
								showCalendar('toDate');
							}}
							className={styles.calendarIcon}
							data-test-auto='6de9d52c-46e1-4f25-98bd-8fd4b5ca5234'>
							<CalenderIcon />
						</div>
					</li>
					<li
						className={styles.filterButton}
						onClick={onFilter}
						data-test-auto='82be95e8-244f-44cd-8e88-315eb2e8dca6'
						data-resource-id={paymentConstants.filterPaymentControl}>
						<span className={styles.filterIcon}>
							<FilterIcon />
						</span>
						<span className={styles.filterText}>Filter</span>
					</li>
				</ul>
			</div>
			<div className={styles.rightSection}>
				<ul className={styles.currentReturnBalance}>
					<li className={currentBalance < 0 ? styles.negativeBalance : ''}>
						Current Return Balance:&nbsp;
						<span>{currentBalance}</span>
					</li>
				</ul>
				<ul className={styles.exportPurchase}>
					<li
						data-test-auto='28a1fabf-d9ce-407e-8845-1a51874dc468'
						title='Export to excel'
						className={`${styles.export} ${paymentHistoryList.length > 0 ? '' : styles.disableExport}`}
						data-resource-id={paymentConstants.exportPaymentHistoryControl}
						onClick={exportToExcel}>
						<ExportIcon2 />
					</li>
					<li
						className={styles.purchase}
						data-test-auto='667537e3-4095-4978-8a48-37f201dc8eb7'
						onClick={() => setShowPurchaseReturnModal(true)}
						data-resource-id={paymentConstants.paymentHistoryBuyControl}>
						<span className={styles.dollarIcon}>
							<DollarIcon />
						</span>
						<span
							className={styles.purchaseText}
							title='Purchase return'>
							Purchase
						</span>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default PaymentHistoryHead;
