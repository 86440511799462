import React, { FC } from "react";

const NewUserIcon: FC = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 5L8 5L8 -3.49691e-07L10 -4.37114e-07L10 5ZM4.76 6.17L1.93 3.34L3.34 1.93L6.17 4.76L4.76 6.17ZM0 10L-8.74228e-08 8L5 8L5 10L0 10Z" fill="#669440" />
        </svg>

    );
};

export default NewUserIcon;