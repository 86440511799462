import { type FC } from 'react';

import styles from './section-header.module.scss';
import { type ISectionHeaderProps } from './section-header.types';

const SectionHeader: FC<ISectionHeaderProps> = (props) => {
	const { title = '' } = props;

	return <h1 className={styles.section_header}>{title}</h1>;
};

export default SectionHeader;
