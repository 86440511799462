import React, { FC, useEffect, useState } from "react";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { IUserLoginHistory } from "../../pages/common/common.types";
import { ApplicationState } from "../../store";
import { updateLastLoginEnable } from "../../pages/common/userSettings/userSettings.apis";
import { fetchLoginHistory } from "../../pages/common/common.apis";
import { DateFormatConstants, LoginHistoryConstants } from "../../helper/Constants";
import { getFormattedLocalDateTime } from "../../helper/DateHelperFunctions";
import { getBrowserDetails } from "../../helper/HelperFunctions";

interface ILastLoginProps {
    show: boolean;
    onHide: React.Dispatch<React.SetStateAction<boolean>>;
}

const LastLoginModal: FC<ILastLoginProps> = ({ show, onHide }) => {
    const [enablePrompt, setEnablePrompt] = useState(false);
    const [lastLogin, setLastLogin] = useState<IUserLoginHistory>();
    const {
        loginHistoryLoading,
        loginHistory: { loginHistory, deviceId },
    } = useSelector((state: ApplicationState) => state.commonState);
    const dispatch = useDispatch();
    const handleCancel = () => {
        onHide(false);
    };
    const handleToggleChange = (value: boolean) => {
        dispatch(updateLastLoginEnable(!value));
        setEnablePrompt(value);
    };

    useEffect(() => {
        dispatch(fetchLoginHistory(1, LoginHistoryConstants.COUNT_PER_PAGE));
    }, []);

    useEffect(() => {
        if (loginHistory.length > 1) {
            loginHistory[0].deviceId === deviceId
                ? setLastLogin(loginHistory[1])
                : setLastLogin(loginHistory[0]);
        }
    }, [loginHistory]);

    return (
        <Modal
            show={show && (lastLogin ? true : false)}
            onHide={handleCancel}
            dialogClassName="last-login"
        >
            <Modal.Header closeButton closeVariant="white">
                {LoginHistoryConstants.LAST_LOGIN_MODAL_TITLE}
            </Modal.Header>
            <Modal.Body style={{ width: "500px" }}>
                <LoadingOverlay>
                    <Row className="mb-3">
                        <Col sm={3}><b>Log In On</b></Col>
                        <Col>
                            {getFormattedLocalDateTime(lastLogin?.loggedInOn ?? "", DateFormatConstants.LAST_LOGIN_DATE_FORMAT)}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={3}><b>IP Address</b></Col>
                        <Col>{lastLogin?.ipAddress}</Col>
                    </Row>
                    <Row>
                        <Col sm={3}><b>Browser</b></Col>
                        <Col>{lastLogin && getBrowserDetails(lastLogin.clientApp)}</Col>
                    </Row>
                    <Loader
                        classNamePrefix="sm"
                        text=""
                        loading={loginHistoryLoading}
                    />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <Form.Check
                    onChange={() => handleToggleChange(!enablePrompt)}
                    checked={enablePrompt}
                    type="checkbox"
                    id="last_login"
                    label={LoginHistoryConstants.LAST_LOGIN_PROMPT_TEXT}
                />
                <Button
                    size="lg"
                    onClick={handleCancel}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LastLoginModal;
