import CloseIcon from 'src/components/svg/CloseIcon';
import React, { FC, ReactElement } from 'react';
import { Modal } from 'react-bootstrap';
import './SuiteModal.style.scss';

interface SuiteModalProps {
	width: string;
	title: string;
	theme: string;
	hide: () => void;
	children: ReactElement<any, any>;
	className?: string;
}
const SuiteModal: FC<SuiteModalProps> = (props) => {
	const { width, title, theme, hide, children, className } = props;
	let widthClassName = `modal${width}`;
	return (
		<Modal
			show={true}
			onHide={hide}
			className={`suiteModal ${widthClassName} ${className ? className : ''}`}>
			<Modal.Header className={theme === 'light' ? 'lighttheme' : 'darktheme'}>
				<div>
					<Modal.Title>{title}</Modal.Title>
				</div>
				<div
					data-test-auto='e2ab6c41-b6f5-4181-b38f-3455585b2141'
					className='closeIcon'
					onClick={hide}>
					<CloseIcon />
				</div>
			</Modal.Header>
			{children}
		</Modal>
	);
};

export default SuiteModal;
