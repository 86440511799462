import { API_BASE_URL, CustomAuthorityConstants } from 'src/helper/Constants';
import axios, { AxiosResponse } from 'axios';
import { actionTypes as notificationActionTypes } from '../../../../pages/common/notification/notification.types';
import { AppThunkAction } from '../../../../store';
import { type KnownActions } from './save-custom-authority-modal.types';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { ICustomAuthorities } from 'src/modules/voucher-invoices/custom-authorities/custom-authorities.types';
import { logger } from 'src/oidcClient/authProvider';

export const deleteCustomAuthority =
	(taxingAuthorityId: number, resourceId: string, callback: () => void): AppThunkAction<KnownActions> =>
	(dispatch) => {
		let url: string = `${API_BASE_URL}/api/custom-authorities/delete-taxing-authority/${taxingAuthorityId}`;
		axios
			.delete(url, { headers: { 'X-Resource-Id': resourceId } })
			.then((response: AxiosResponse<boolean>) => {
				const { data } = response;
				if (!data) {
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusMessage: CustomAuthorityConstants.deleteAuthorityErrorMessage,
						statusType: StatusType.Error,
					});
				} else {
					callback();
				}
			})
			.catch(function (error) {
				logger && logger.trackWarning('Error while deleteCustomAuthority', { 'Error': error?.response?.data});
				dispatch({
					type: notificationActionTypes.NOTIFICATION,
					statusMessage: CustomAuthorityConstants.deleteAuthorityErrorMessage,
					statusType: StatusType.Error,
				});
			});
	};

export const activateOrDeactivateAuthority =
	(customAuthoritydata: ICustomAuthorities, resourceId: string, callback: () => void): AppThunkAction<KnownActions> =>
	(dispatch) => {
		let url: string = `${API_BASE_URL}/api/custom-authorities/update-active-deactive-authority`;
		axios
			.put(url, customAuthoritydata, { headers: { 'X-Resource-Id': resourceId } })
			.then((response: AxiosResponse<boolean>) => {
				const { data } = response;
				if (!data) {
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusMessage: CustomAuthorityConstants.activeOrDeactiveErrorMessage,
						statusType: StatusType.Error,
					});
				} else {
					callback();
				}
			})
			.catch(function (error) {
				logger && logger.trackWarning('Error while activateOrDeactivateAuthority', { 'Error': error?.response?.data});
				dispatch({
					type: notificationActionTypes.NOTIFICATION,
					statusMessage: CustomAuthorityConstants.activeOrDeactiveErrorMessage,
					statusType: StatusType.Error,
				});
			});
	};

export const addAuthority =
	(customAuthoritydata: ICustomAuthorities, resourceId: string, callback: () => void): AppThunkAction<KnownActions> =>
	(dispatch) => {
		let url: string = `${API_BASE_URL}/api/custom-authorities`;
		axios
			.post(url, customAuthoritydata, { headers: { 'X-Resource-Id': resourceId } })
			.then((response: AxiosResponse<boolean>) => {
				const { data } = response;
				if (!data) {
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusMessage: CustomAuthorityConstants.addOrUpdateErrorMessage,
						statusType: StatusType.Error,
					});
				} else {
					callback();
				}
			})
			.catch(function (error) {
				logger && logger.trackWarning('Error while addAuthority', { 'Error': error?.response?.data});
				dispatch({
					type: notificationActionTypes.NOTIFICATION,
					statusMessage: CustomAuthorityConstants.addOrUpdateErrorMessage,
					statusType: StatusType.Error,
				});
			});
	};

export const updateAuthority =
	(customAuthoritydata: ICustomAuthorities, resourceId: string, callback: () => void): AppThunkAction<KnownActions> =>
	(dispatch) => {
		let url: string = `${API_BASE_URL}/api/custom-authorities`;
		axios
			.put(url, customAuthoritydata, { headers: { 'X-Resource-Id': resourceId } })
			.then((response: AxiosResponse<boolean>) => {
				const { data } = response;
				if (!data) {
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusMessage: CustomAuthorityConstants.addOrUpdateErrorMessage,
						statusType: StatusType.Error,
					});
				} else {
					callback();
				}
			})
			.catch(function (error) {
				logger && logger.trackWarning('Error while updateAuthority', { 'Error': error?.response?.data});
				dispatch({
					type: notificationActionTypes.NOTIFICATION,
					statusMessage: CustomAuthorityConstants.addOrUpdateErrorMessage,
					statusType: StatusType.Error,
				});
			});
	};

export const getAllCustomAuthority =
	(callback: (allCustomAuthoritydata: ICustomAuthorities[]) => void): AppThunkAction<KnownActions> =>
	(dispatch) => {
		let url: string = `${API_BASE_URL}/api/custom-authorities/get-all-custom-authorities`;
		axios
		    .get(url,{ headers: { 'X-Resource-Id': 'SUITE_VchrAndIns_CustAuth_BtnAdd' }})
			.then((response: AxiosResponse<ICustomAuthorities[]>) => {
				const { data } = response;
				if (!data) {
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusMessage: CustomAuthorityConstants.fetchAllCustomAuthorityErrorMessage,
						statusType: StatusType.Error,
					});
				} else {
					callback(data);
				}
			})
			.catch(function (error) {
				logger && logger.trackWarning('Error while getAllCustomAuthority', { 'Error': error?.response?.data});
				dispatch({
					type: notificationActionTypes.NOTIFICATION,
					statusMessage: CustomAuthorityConstants.fetchAllCustomAuthorityErrorMessage,
					statusType: StatusType.Error,
				});
			});
	};

export const getAuthorityRelatedTaxDocuments =
	(taxingAuthorityId: number, callback: (taxingAuthorityDocuments: string[]) => void): AppThunkAction<KnownActions> =>
	(dispatch) => {
		let url: string = `${API_BASE_URL}/api/custom-authorities/get-authority-related-tax-documents/${taxingAuthorityId}`;
		axios
			.get(url)
			.then((response: AxiosResponse<string[]>) => {
				const { data } = response;
				if (!data) {
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusMessage: CustomAuthorityConstants.authorityRelatedTaxDocumentsErrorMessage,
						statusType: StatusType.Error,
					});
				} else {
					callback(data);
				}
			})
			.catch(function (error) {
				logger && logger.trackWarning('Error while getAuthorityRelatedTaxDocuments', { 'Error': error?.response?.data});
				dispatch({
					type: notificationActionTypes.NOTIFICATION,
					statusMessage: CustomAuthorityConstants.authorityRelatedTaxDocumentsErrorMessage,
					statusType: StatusType.Error,
				});
			});
	};
