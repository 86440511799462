import './accordian-list.scss';

import React, { type FC, useState } from 'react';

import { Products, type ProductType } from '../../../helper/Constants';
import { type IRoleWithProducts } from '../../../modules/userManagement/userGroups/user-groups.types';
import chevronDown from '../../svg/chevron-down.svg';
import chevronUp from '../../svg/chevron-up.svg';
import NewUserIcon from '../../svg/NewUserIcon';
import { ReactComponent as Pencil } from '../../svg/pencil.svg';
import { ReactComponent as Trash } from '../../svg/trash.svg';
import Tooltip from '../tooltip';
import { UserManagementConstants } from '../../../helper/Constants';
import { type IAccordianListProps, type IUserGroups } from './accordian-list.types';

const AccordianList: FC<IAccordianListProps> = (props) => {
    const [showIcon, setShowIcon] = useState(false);
    const { 
        groups,
        onEdit = () => null,
        onDelete = () => null,
        newGroupIds,
        selectedGroup,
        deleteDisabled,
        handleGroupSelection,
        displayError,
        errorText,
        selectDataResourceId,
        deleteDataResourceId,
        editDataResourceId,
        applyDisabled=true
    } = props;

	const getProductNames = (products: ProductType[]): string => {
		const prodList = products?.map((item, i) => {
			return Products[item];
		});
		return prodList != undefined ? prodList.join(',') : '';
	};

	return (
		<div className='accordian_list_container'>
			{displayError ? (
				<div className='error_div'>{errorText}</div>
			) : (
				groups.map((datum: IUserGroups, index: number) => {
					const open = selectedGroup === datum.groupId;
					const showEditIcons = showIcon && open;
					return (
						<div
							className='accordian_list'
							key={index}>
							<div
								className={open ? 'accordian_list_title selected' : 'accordian_list_title'}
								onClick={() => {
									handleGroupSelection(datum.groupId);
								}}
								data-resource-id={selectDataResourceId}
								onMouseOver={() => {
									open && setShowIcon(true);
								}}
								onMouseOut={() => {
									setShowIcon(false);
								}}>
								<div
									className='accordian_list_title_text'
									title={datum.groupName}>
									{newGroupIds.includes(datum.groupId) && (
										<div className='new_user_indicator'>
											<NewUserIcon />
										</div>
									)}
									<div className='title_text'>{datum.groupName.trim()}</div>
									{
										datum.tooltip && <Tooltip text={datum.tooltip.trim()} />
									}
								</div>
								
								{
									<div className={datum.roles.length > 0 ? 'accordian_list_title_icon': ''}>
									{!datum.isDefault && showEditIcons && (
										<div data-resource-id={editDataResourceId}>
											<Pencil
												className={`${!applyDisabled ? 'disabled_action_icon' : ''} icon-btn`}
												title={!applyDisabled ? 'Save your changes before trying to edit group.' : 'Edit Group'}
												onClick={(event) => {
													event.preventDefault();
													event.stopPropagation();
													if (applyDisabled) {
														onEdit && onEdit(datum);
													}
												}}
											/>
										</div>
									)}

									{!datum.isDefault && showEditIcons && (
										<div data-resource-id={deleteDataResourceId}>
											<Trash
												className={`${!applyDisabled ? 'disabled_action_icon' : ''} icon-btn`}
												title={!applyDisabled ? 'Save your changes before trying to delete group.' : 'Delete Group'}
												onClick={(event) => {
													event.preventDefault();
													event.stopPropagation();
													if (applyDisabled) {
														onDelete && onDelete(datum);
													}
												}}
											/>
										</div>
									)}

										{
											datum.roles.length > 0? <>
									{open ? (
										<img
											className='cursor-pointer'
											src={chevronUp}
										/>
									) : (
										<img
											className='cursor-pointer'
											src={chevronDown}
										/>
											)}</> : <></>
										}
								</div>
								}
							</div>

							{datum.roles.length > 0 && open ? (
								<div className='accordian_list_content'>
									{datum.roles?.map((childItem: IRoleWithProducts, index: number) => {
										return (
											<div
												className='accordian_list_data'
												key={index}>
												<div
													className='list_text'
													title={childItem.roleName}>
													{childItem.roleName}
												</div>
												<Tooltip text={childItem.products ? getProductNames(childItem.products) : ''} />
											</div>
										);
									})}
								</div>
							) : null}
						</div>
					);
				})
			)}
		</div>
	);
};

export default AccordianList;
