import { NotificationAction } from 'src/pages/common/notification/notification.reducer';
import { actionTypes } from './MFASettings.actions';
import { OverlayLoaderAction } from 'src/pages/common/overlayLoader/OverlayLoader.model';

export const initialcMfaSettings: ICompanyMfaSettings = {
    isMfaEnabled: false,
    trustedDeviceExpiryDays: 0,
    isAllowUsertoRememberTrustedDevice: false,
    skipMfaForIP: false,
    iSTPMfaEnabled: false,
};

export const initialMfaSettings: IMFASettingsState = {
    mfaIpAddress: [],
    mfaUsersDetails: {
        count: 0,
        mfaEnableCount: 0,
        mfaUsers: []
    },
    isLoading: false
};

export const initialMFAIPAddress: IMFAIPAddressModel = {
    id: 0,
    fromIPAddress: '',
    toIPAddress: ''
}

export interface ICompanyMfaSettings {
    isMfaEnabled: boolean;
    trustedDeviceExpiryDays: number;
    isAllowUsertoRememberTrustedDevice: boolean;
    skipMfaForIP: boolean;
    iSTPMfaEnabled: boolean;
}

export interface IMFAUserModel {
    userId: number;
    userName: string;
    emailAddress: string;
    isMfaEnabled: boolean;
}

export interface IMFAUserViewModel {
    count: number;
    mfaEnableCount: number;
    mfaUsers: IMFAUserModel[];
}

export interface IMFAIPAddressModel {
    id: number;
    fromIPAddress: string;
    toIPAddress: string;
}

export interface IMFASettingsState {
    mfaIpAddress: IMFAIPAddressModel[];
    mfaUsersDetails: IMFAUserViewModel;
    isLoading: boolean;
}

interface ReceiveMFASettings {
    type: actionTypes.RECEIVE_MFA_SETTINGS,
    cMfaSettings: ICompanyMfaSettings
}

interface UpdateMFASettings {
    type: actionTypes.UPDATE_MFA_SETTINGS,
    cMfaSettings: ICompanyMfaSettings
}

interface ReceiveMFAIAddressListAction {
    type: actionTypes.RECEIVE_MFAIPADDRESS_LIST;
    mfaIpAddress: IMFAIPAddressModel[];
    isLoading: boolean;
}

interface RequestMFAIAddressListAction {
    type: actionTypes.REQUEST_MFAIPADDRESS_LIST;
    isLoading: boolean;
}

interface RequestMFAUserListAction {
    type: actionTypes.REQUEST_MFAUSER_LIST;
    isLoading: boolean;
}

interface ReceiveMFAUserListAction {
    type: actionTypes.RECEIVE_MFAUSER_LIST;
    mfaUsersTable: IMFAUserViewModel;
    isLoading: boolean;
}

interface AddMFAIPAddressAction {
    type: actionTypes.ADD_MFAIPADDRESS_STATE;
    data: IMFAIPAddressModel;
    isLoading: boolean;
}

interface UpdateMFAIPAddressAction {
    type: actionTypes.UPDATE_MFAIPADDRESS_STATE;
    data: IMFAIPAddressModel;
    isLoading: boolean;
}


export type KnownAction = ReceiveMFASettings | UpdateMFASettings | ReceiveMFAIAddressListAction | RequestMFAIAddressListAction |
    RequestMFAUserListAction | ReceiveMFAUserListAction | AddMFAIPAddressAction |
    UpdateMFAIPAddressAction | NotificationAction |
    OverlayLoaderAction;