import React, { FC } from "react";

const Divider: FC = () => {
    return (
        <svg width="2" height="16" viewBox="0 0 2 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="0" height="16" transform="translate(1)" fill="white" fillOpacity="0.01" />
            <path d="M1 0V16" stroke="#212529" strokeOpacity="0.25" />
        </svg>


    );
};

export default Divider;