import axios from "axios";
import { AppThunkAction } from "../../../store";
import { RECEIVED_SESSION_IDLE_MINUTES } from "./auth.actions";
import { API_BASE_URL } from "src/helper/Constants";
import { logger } from 'src/oidcClient/authProvider';

export const requestSessionIdleMinutes =
    (): AppThunkAction<any> => (dispatch) => {
        axios
            .get(
                `${API_BASE_URL}/api/helper/session-timeout-minutes`
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: RECEIVED_SESSION_IDLE_MINUTES,
                    payload: data,
                });
            });
    };

export const updateLogoutDate = async () => {
    await axios
    .put(`${API_BASE_URL}/api/login-history/update-logout-date`)
    .catch(error => {
        logger && logger.trackWarning('Error while updateLogoutDate', { 'Error': error?.response?.data});
    });
}