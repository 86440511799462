import { API_BASE_URL } from '../../helper/Constants';
import axios, { AxiosResponse } from 'axios';
import {
	IClientModel,
	IColumnOptions,
	IEro,
	IOfficeLocation,
	defaultColumnsOptions,
	templateType,
} from './Client.model';
import { clientMngmntResources } from '../../helper/rbac-constants';
import { download } from '../../helper/FileUtilities';
import { logger } from 'src/oidcClient/authProvider';

export const fetchColumnOptions = async (callback: (data: IColumnOptions[]) => void) => {
	try {
		const response: AxiosResponse<IColumnOptions[]> = await axios.get(API_BASE_URL + '/api/filter-column/1');
		const { data } = response;

		const defaultColumns: IColumnOptions[] = JSON.parse(JSON.stringify(defaultColumnsOptions));
		let updatedColumns: IColumnOptions[];

		if (data?.length) {
			updatedColumns = defaultColumns.map((obj1: IColumnOptions) => {
				const matchedObj = data.find((obj2: IColumnOptions) => obj1.columnName === obj2.columnName);
				if (matchedObj?.isActive || matchedObj?.columnName === 'Client ID') {
					return { ...obj1, isActive: true };
				} else {
					return { ...obj1, isActive: false };
				}
			});
		} else {
			updatedColumns = defaultColumns;
		}
		callback(updatedColumns);
	} catch (error) {
		logger && logger.trackWarning('Error while fetchColumnOptions', { 'Error': error?.response?.data});
	}
};

export const saveColumnOptions = async (data: IColumnOptions[], callback: () => void) => {
	try {
		const response = await axios.post(API_BASE_URL + '/api/filter-column/1', data, {
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
				'X-Resource-Id': `${clientMngmntResources.columnOptions}`,
			},
			withCredentials: true,
		});
		if (response.status === 200) {
			callback();
		}
	} catch (error) {
		logger && logger.trackWarning('Error while saveColumnOptions', { 'Error': error?.response?.data});
	}
};

export const addClient = async (
	data: IClientModel,
	callback: (clientBasicInfoId: number) => void,
	errorCallback: (message: string) => void,
) => {
	try {
		const response = await axios.post(API_BASE_URL + '/api/client-info', data, {
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
				'X-Resource-Id': `${clientMngmntResources.addClient}`,
			},
			withCredentials: true,
		});
		if (response.status === 200) {
			callback(response.data);
		}
	} catch (error: any) {
		logger && logger.trackWarning('Error while addClient', { 'Error': error?.response?.data});
		errorCallback(error.response?.data);
	}
};

export const editClient = async (
	data: IClientModel,
	callback: () => void,
	errorCallback: (message: string) => void,
) => {
	try {
		const response = await axios.put(API_BASE_URL + '/api/client-info', data, {
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
				'X-Resource-Id': `${clientMngmntResources.editClient}`,
			},
			withCredentials: true,
		});
		if (response.status === 200) {
			callback();
		}
	} catch (error: any) {
		logger && logger.trackWarning('Error while editClient', { 'Error': error?.response?.data});
		errorCallback(error.response?.data);
	}
};

export const fetchEro = async (  
	callback: (eros: IEro[]) => void) => {
	try {
		const response: AxiosResponse<IEro[]> = await axios.get(API_BASE_URL + '/api/users/partners/true');
		if (response.status === 200) {
			callback(response.data);
		}
	} catch (error) {
		logger && logger.trackWarning('Error while fetchEro', { 'Error': error?.response?.data});
	}
};

export const fetchOfficeLocation = async (callback: (officeLocation: IOfficeLocation[]) => void) => {
	try {
		const response: AxiosResponse<IOfficeLocation[]> = await axios.get(
			API_BASE_URL + '/api/office-location/office-location-list',
		);
		if (response.status === 200) {
			callback(response.data);
		}
	} catch (error) {
		logger && logger.trackWarning('Error while fetchOfficeLocation', { 'Error': error?.response?.data});
	}
};

export const downloadImportTemplate = async (clientImportTemplateType: number) => {
	try {
		const response: AxiosResponse = await axios.get(
			API_BASE_URL +
				'/api/client-info/download-import-template' +
				`?clientImportTemplateType=${clientImportTemplateType}`,
			{ headers: { 'X-Resource-Id': clientMngmntResources.downloadTemplate } },
		);
		if (response.status === 200) {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			download(
				url,
				`clientinfo_${clientImportTemplateType === templateType.Entity ? 'entity' : 'individual'}_template.csv`,
			);
		}
	} catch (error) {
		logger && logger.trackWarning('Error while downloadImportTemplate', { 'Error': error?.response?.data});
	}
};

export const getImportLink = async (callback: (source: { sas: string; fileName: string }) => void) => {
	try {
		const response: AxiosResponse = await axios.get(API_BASE_URL + '/api/client-info/csv-import-uploadlink', {
			headers: { 'X-Resource-Id': clientMngmntResources.importClientData },
		});
		if (response.status === 200) {
			callback(response.data);
		}
	} catch (error) {
		logger && logger.trackWarning('Error while getImportLink', { 'Error': error?.response?.data});
	}
};

export const sendImportData = async (fileName: string, friendlyFileName: string) => {
	try {
		const response: AxiosResponse = await axios.get(
			API_BASE_URL +
				'/api/client-info/csv-import-clientdata' +
				`?fileName=${fileName}&friendlyFileName=${encodeURIComponent(friendlyFileName)}`,
			{ headers: { 'X-Resource-Id': clientMngmntResources.importClientData } },
		);
	} catch (error) {
		logger && logger.trackWarning('Error while sendImportData', { 'Error': error?.response?.data});
	}
};
