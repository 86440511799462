import { type FC } from 'react';

import ButtonWithTextIcon from '../../../components/common/button-with-text-icon';
import { ReactComponent as EditIcon } from '../../../components/svg/pencil.svg';
import ColorDisplay from '../partials/color-display/color-display';
import HeaderDesignOverlay from '../partials/header-design-overlay/header-design-overlay';
import SectionDescription from '../partials/section-description/section-description';
import SectionHeader from '../partials/section-header/section-header';
import { useHeaderDesign } from './header-design.hooks';
import { type IHeaderDesignProps } from './header-design.types';
import styles from './index.module.scss';
import { logger } from '../../../oidcClient/authProvider';

const HeaderDesign: FC<IHeaderDesignProps> = (props) => {
	const {
		setShowHeaderDesignPopup,
		showHeaderDesignPopup,
		bgColorCode,
		foreColorCode,
		whiteLogoPath,
		companyName,
		logoPath,
		updateCompanyBrandSettings,
		address,
		city,
		zip,
		stateName
	} = useHeaderDesign(props);

	const handleSave = (fontColor, bgColor) => {
		logger && logger.trackTrace('handleSave: Saving header design');
		updateCompanyBrandSettings(fontColor, bgColor);
		setShowHeaderDesignPopup(false);
	};

	return (
		<div className={styles.header_design_wrapper}>
			<div className={styles.header_design_header}>
				<SectionHeader title='Header Design' />
			</div>
			<div className={styles.header_design_description}>
				<SectionDescription
					text={
						'You can personalize the colors of the Taxpayer Page and the email format colors according to your preferences.'
					}
				/>
			</div>
			<div className={styles.color_display_wrapper}>
				<ColorDisplay
					title='Font Color'
					color={foreColorCode}
				/>
				<ColorDisplay
					title='Background Color'
					color={bgColorCode}
				/>
			</div>
			<div className={styles.header_design_action}>
				<ButtonWithTextIcon
					label='Edit'
					SvgIcon={<EditIcon />}
					className={styles.header_design_action_buttons}
					data-test-auto='branding-management_header-design_edit'
					onClick={() => {
						setShowHeaderDesignPopup(true);
					}}
					bordered
				/>
			</div>
			<HeaderDesignOverlay
				show={showHeaderDesignPopup}
				onHide={() => {
					setShowHeaderDesignPopup(false);
				}}
				header='Edit Header Design'
				fontColor={foreColorCode}
				backgroundColor={bgColorCode}
				logoPath={logoPath}
				whiteLogoPath={whiteLogoPath}
				companyName={companyName}
				onSave={handleSave}
			/>
		</div>
	);
};

export default HeaderDesign;
