import React, { FC, useEffect, useState } from 'react';
import { IMFASettingsState, IMFAUserModel } from '../MFASettings.model';

import styles from './MFAUserSettings.module.scss';
import SearchIcon from 'src/components/svg/SearchIcon';

interface MFAUserSettingsProps {
	mfaSettings: IMFASettingsState;
	requestMFAIPUser(query: string): void;
	updateMFAEnableForAllUser(mfaEnable: boolean, callback: () => void): void;
	updateMFAEnableForUser(userId: number, mfaEnable: boolean, callback: () => void): void;
}
const MFAUserSettings: FC<MFAUserSettingsProps> = (props) => {
	const { mfaSettings, requestMFAIPUser, updateMFAEnableForAllUser, updateMFAEnableForUser } = props;

	const [enableForAll, setEnableForAll] = useState(false);
	const [sortName, setSortName] = useState('default');
	const [sortOrder, setSortOrder] = useState('asc');
	const [searchString, setSearchString] = useState('');
	const [page, setPage] = useState(1);

	const pageSize: number = 10;
	const totalPages = Math.ceil(mfaSettings.mfaUsersDetails.count / pageSize);

	useEffect(() => {
		setEnableForAll(
			mfaSettings.mfaUsersDetails.mfaEnableCount === mfaSettings.mfaUsersDetails.count
				? mfaSettings.mfaUsersDetails.count !== 0
					? true
					: enableForAll
				: false,
		);
	}, [mfaSettings.mfaUsersDetails]);

	useEffect(() => {
		loadUserDetails(page, pageSize, 'userName', sortOrder, searchString);
	}, []);

	const onChangeMfaSetting = (event: any, user: any) => {
		let checked = event.target.checked;
		setEnableForAll(false);
		updateMFAEnableForUser(user.userId, checked, handleRequestMFAIPUser);
	};

	const checkBox = (user: IMFAUserModel) => {
		return (
			<div className={`${styles.switchEachUser} form-switch`}>
				<input
					className='switchSettingsButton form-check-input'
					type='checkbox'
					id='defaultSignerButton'
					data-test-auto={`ac4ec414-445e-4903-a0a8-0b08a29858bc${user.userId}`}
					onChange={(event) => onChangeMfaSetting(event, user)}
					checked={user.isMfaEnabled}
					data-resource-id='SUITE_Secr_MltFacAuth_IntrnlUsrs_TglEnblOrDisbl'
				/>
			</div>
		);
	};

	const handleRequestMFAIPUser = () => {
		requestMFAIPUser(buildQuery(page, pageSize));
	};

	const handleEnableForAll = (event: any) => {
		let checked = event.target.checked;
		setEnableForAll(checked);
		updateMFAEnableForAllUser(checked, handleRequestMFAIPUser);
	};

	const buildQuery = (
		page: number,
		pageSize: number,
		newSortName: string = sortName,
		newSortOrder: string = sortOrder,
		newSearchString: string = searchString,
	) => {
		return (
			'?pageNo=' +
			page +
			'&pageSize=' +
			pageSize +
			'&sortBy=' +
			(newSortName === 'default' ? 'userName' : newSortName) +
			'&sortOrder=' +
			newSortOrder +
			'&searchString=' +
			newSearchString
		);
	};

	const onSortChange = (e: React.MouseEvent<HTMLElement>, newSortName: string) => {
		let newSortOrder = sortOrder;
		if (sortName !== newSortName) {
			setSortName(newSortName);
			newSortOrder = 'desc';
			setSortOrder(newSortOrder);
		} else {
			setSortName(newSortName);
			newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
			setSortOrder(newSortOrder);
		}
		setPage(1);
		loadUserDetails(1, pageSize, newSortName, newSortOrder, searchString);
	};

	const goToPage = (newPageIndex: number) => {
		if (newPageIndex !== page && newPageIndex <= totalPages && newPageIndex >= 1) {
			setPage(newPageIndex);
			loadUserDetails(newPageIndex, pageSize, sortName, sortOrder, searchString);
		}
	};

	const loadUserDetails = (
		page: number,
		pageSize: number,
		newSortName: string = sortName,
		newSortOrder: string = sortOrder,
		newSearchString: string = searchString,
	) => {
		let queryString = buildQuery(page, pageSize, newSortName, newSortOrder, newSearchString);
		requestMFAIPUser(queryString);
	};

	const onSearchChange = (newSearchString: string) => {
		setSearchString(newSearchString);
		setPage(1);
		loadUserDetails(1, pageSize, sortName, sortOrder, newSearchString);
	};

	return (
		<div>
			<div className={styles.searchMFAUsersEnableAll}>
				<div className={styles.searchUser}>
					<input
						type='text'
						id='searchText'
						placeholder='Search'
						onChange={(e) => onSearchChange(e.target.value)}
						value={searchString}
					/>
					<button className={styles.searchIcon}>
						<SearchIcon />
					</button>
				</div>
				<div className={`${styles.enableAllMFAUsers} form-switch`}>
					<input
						className='form-check-input'
						type='checkbox'
						id='cbEnableForAll'
						checked={enableForAll}
						onChange={handleEnableForAll}
						data-test-auto='2ee4ef86-1cfe-487e-8e0c-081e9e118b28'
					/>
					<label
						htmlFor='cbEnableForAll'
						className={enableForAll ? 'checked' : ''}>
						Enable&nbsp;All
					</label>
				</div>
			</div>
			<div>
				<table className={`table table-striped table-hover ${styles.mfaUsersListTable}`}>
					<thead>
						<tr>
							<th
								className={styles.nameCol}
								scope='col'
								onClick={(e) => onSortChange(e, 'userName')}>
								Name{' '}
								<i
									className={`fa fa-fw fa-sort${
										sortName === 'emailAddress' || sortName === 'default' ? '' : sortOrder === 'asc' ? '-asc' : '-desc'
									} sortIcon`}></i>
							</th>
							<th
								className={styles.emailCol}
								scope='col'
								onClick={(e) => onSortChange(e, 'emailAddress')}>
								Email{' '}
								<i
									className={`fa fa-fw fa-sort${
										sortName === 'userName' || sortName === 'default' ? '' : sortOrder === 'asc' ? '-asc' : '-desc'
									} sortIcon`}></i>
							</th>
							<th
								className={styles.actionsCol}
								scope='col'>
								Action
							</th>
						</tr>
					</thead>
					<tbody>
						{mfaSettings.mfaUsersDetails.mfaUsers.length === 0 ? (
							<tr>
								<td
									data-toggle='collapse'
									colSpan={3}
									className='text-center'>
									There is no data to display
								</td>
							</tr>
						) : (
							mfaSettings.mfaUsersDetails.mfaUsers.map((eachUser) => {
								return (
									<tr
										className={styles.userRow}
										key={eachUser.userId}>
										<td
											title={eachUser.userName}
											className={styles.nameColData}>
											{eachUser.userName}
										</td>
										<td
											title={eachUser.emailAddress}
											className={styles.emailColData}>
											{eachUser.emailAddress}
										</td>
										<td className={styles.actionsColData}>{checkBox(eachUser)}</td>
									</tr>
								);
							})
						)}
					</tbody>
				</table>
			</div>
			<section className={styles.usersPagination}>
				<div className={styles.paginationDetails}>
					Showing <span className={styles.userFirst}>{mfaSettings.mfaUsersDetails.mfaUsers.length === 0 ? 0 : (page - 1) * 10 + 1}</span> to{' '}
					<span className={styles.userLast}>
						{page * 10 > mfaSettings.mfaUsersDetails.count ? mfaSettings.mfaUsersDetails.count : page * 10}
					</span>{' '}
					of <span className={styles.userTotal}>{mfaSettings.mfaUsersDetails.count}</span> entries
				</div>
				<ul className={styles.showPageNumber}>
					<li
						data-test-auto='702ca110-899b-4615-a44c-814baf7ae7cf'
						title={page <= 1 ? '' : 'First Page'}
						className={page <= 1 ? styles.paginationActionDisabled : ''}
						onClick={() => goToPage(1)}>
						<span className='fa fa-angle-double-left'></span>
					</li>
					<li
						data-test-auto='0096563f-0cdb-485d-9390-2afc62d3d233'
						title={page <= 1 ? '' : 'Previous Page'}
						className={page <= 1 ? styles.paginationActionDisabled : ''}
						onClick={() => goToPage(page - 1)}>
						<span className='fa fa-angle-left'></span>
					</li>
					<li
						title={page.toString()}
						className={styles.currentPageNumber}>
						{page.toString()}
					</li>
					<li
						data-test-auto='cc16adb5-10d6-4109-975d-1da91ed4bd21'
						title={page >= mfaSettings.mfaUsersDetails.count ? '' : 'Next Page'}
						className={page >= totalPages ? styles.paginationActionDisabled : ''}
						onClick={() => goToPage(page + 1)}>
						<span className='fa fa-angle-right'></span>
					</li>
					<li
						data-test-auto='ca1be3fc-9740-4418-a123-d7072dc75e41'
						title={page >= mfaSettings.mfaUsersDetails.count ? '' : 'Last Page'}
						className={page >= totalPages ? styles.paginationActionDisabled : ''}
						onClick={() => goToPage(totalPages)}>
						<span className='fa fa-angle-double-right'></span>
					</li>
				</ul>
			</section>
		</div>
	);
};

export default MFAUserSettings;
