import React, { FC } from 'react';
import { Modal, Container, Button } from 'react-bootstrap';
import './DisableMFAModal.style.scss';

interface DisableMFAModalProps {
	onDisableAlertNo: () => void;
	onDisableAlertYes: () => void;
}
const DisableMFAModal: FC<DisableMFAModalProps> = (props) => {
	return (
		<>
			<Modal.Body className='show-grid'>
				<Container>
					<div className='disableMessage'>Are you sure you want to “Turn OFF” text message Authentication?</div>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button
					data-test-auto='45ba105b-d383-4bdd-87e5-a6d3bdc085cf'
					variant='secondary'
					onClick={props.onDisableAlertNo}>
					No
				</Button>
				<Button
					data-test-auto='c6568a96-e92e-4d53-afc9-314ffc474bf8'
					variant='primary'
					onClick={props.onDisableAlertYes}>
					Yes
				</Button>
			</Modal.Footer>
		</>
	);
};

export default DisableMFAModal;
