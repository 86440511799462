export enum actionTypes {
	REQUEST_COMPANY_LOGO = 'request_company_logo',
	RECEIVE_COMPANY_LOGO_BRANDING = 'receive_company_logo_branding',
	DELETE_COMPANY_LOGO = 'delete_company_logo',
	UPDATE_COMPANY_LOGO = 'update_company_logo',
	REQUEST_COMPANY_WHITE_LOGO = 'request_company_white_logo',
	RECEIVE_COMPANY_WHITE_LOGO = 'receive_company_white_logo',
	DELETE_COMPANY_WHITE_LOGO = 'delete_company_white_logo',
	UPDATE_COMPANY_WHITE_LOGO = 'update_company_white_logo',
	REQUEST_BRANDING_SETTINGS = 'request_branding_settings',
	RECEIVE_BRANDING_SETTINGS = 'receive_branding_settings',
	DELETE_BRANDING_SETTINGS = 'delete_branding_settings',
	UPDATE_BRANDING_SETTINGS = 'update_branding_settings',
	RECEIVE_PRIMARY_OFFICE_LOCATION = "receive_primary_office_location",
	RESET_CUSTOM_COMPANY_LOGO_UPLOAD_LINK='reset_custom_company_logo_upload_link',
}
