import React, { FC, useEffect, useState } from "react";
import { IUsersDropdown } from "../Users.model";
import DraggableListSD from "../DraggableListSD";
import InfoIcon from "src/components/svg/InfoIcon";

interface AvailableUsersSDProps {
    usersInDelegation: IUsersDropdown[];
    availableUsers: IUsersDropdown[];
    handleDropChange: (delegationUsers: IUsersDropdown[], usersAvailable: IUsersDropdown[]) => void;
}

let AvailableUsersSD: FC<AvailableUsersSDProps> = (props) => {
    const {
        usersInDelegation = [],
        availableUsers = [],
        handleDropChange = () => null
    } = props;

    const [usersAvailable, setUsersAvailable] = useState<any[]>([]);

    useEffect(() => {
        setUsersAvailable(availableUsers);
    }, [availableUsers]);

    const dragOverHandler = (event: any) => {
        event.preventDefault();
    };

    const dropHandler = (event: any) => {
        const user = JSON.parse(event.dataTransfer.getData('text'));
        if (availableUsers.some((ele: any) => user.findIndex((datum: any) => datum.value === ele.value) === -1) || !availableUsers.length) {
            const newUserList = [...user, ...availableUsers];
            const newUsersInDelegation = usersInDelegation.filter((ele: any) => !user.some((eachUser: any) => eachUser.value === ele.value));
            handleDropChange(newUsersInDelegation, newUserList);
        }
    };

    return (
        <article className="availableUsersContainer">
            <div className="availableUsersHeaderDiv"><span className="availableUsersHeader">AVAILABLE USERS</span><span className="availableUsersInfo" title="Below are all users able to delegate signature stamp"><InfoIcon /></span></div>
            <ul className="list_container" onDragOver={(event) => dragOverHandler(event)} onDrop={(event) => dropHandler(event)}>
                <DraggableListSD
                    listData={usersAvailable}
                />
            </ul>
        </article>
    );
};
AvailableUsersSD = React.memo(AvailableUsersSD);
export default AvailableUsersSD;