import React from 'react';

const PersonHeart = () => {
	return (
		<svg
			width='17'
			height='16'
			viewBox='0 0 17 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_4592_3958)'>
				<path
					d='M9.5 4.99927C9.5 5.79492 9.18393 6.55798 8.62132 7.12059C8.05871 7.6832 7.29565 7.99927 6.5 7.99927C5.70435 7.99927 4.94129 7.6832 4.37868 7.12059C3.81607 6.55798 3.5 5.79492 3.5 4.99927C3.5 4.20362 3.81607 3.44056 4.37868 2.87795C4.94129 2.31534 5.70435 1.99927 6.5 1.99927C7.29565 1.99927 8.05871 2.31534 8.62132 2.87795C9.18393 3.44056 9.5 4.20362 9.5 4.99927ZM0.5 12.9993C0.5 13.9993 1.5 13.9993 1.5 13.9993H11.5C11.5 13.9993 12.5 13.9993 12.5 12.9993C12.5 11.9993 11.5 8.99927 6.5 8.99927C1.5 8.99927 0.5 11.9993 0.5 12.9993ZM14 4.90927C15.387 3.48427 18.855 5.97927 14 9.18627C9.146 5.97927 12.613 3.48427 14 4.91027V4.90927Z'
					fill='white'
				/>
			</g>
			<defs>
				<clipPath id='clip0_4592_3958'>
					<rect
						width='16'
						height='16'
						fill='white'
						transform='translate(0.5)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default PersonHeart;
