import * as React from 'react';
import { Button } from 'react-bootstrap';
import { EventSettingsConstants } from '../../../helper/Constants';
import { AuthType, EventServiceStatus } from '../developer-section.model';
import styles from './index.module.scss';
import { ReactComponent as Plug } from '../../../components/svg/plug.svg';

interface EventsAPIAuthTypesProps {
    authCred: string;
    authType: number;
    onTestConnection(): void;
    testConnectionStatus: EventServiceStatus;
    onApiAuthCredChange(event: any): void;
    onApiAuthTypeChange(event: any): void;
}

export const EventsAPIAuthTypes: React.FC<EventsAPIAuthTypesProps> = ({ authCred, authType,
    testConnectionStatus,
    onTestConnection,
    onApiAuthCredChange, onApiAuthTypeChange }) => (
    <>
        <br/><br/>
        <div className="row mb-2">
            <div className="col-lg-6">
                <label className='fw-bold'>Authentication Method</label>                
            </div>

            <div className="col-lg-12 padT05">
                <select value={authType} onChange={onApiAuthTypeChange} hidden={true} className= "form-control">
                    {Object.keys(AuthType).filter( value => isNaN(Number(value)) === false).map((value, key) => (
                        <option value={key} key={key}>{AuthType[key]}</option>
                    ))}
                </select>
            </div>
        </div>        

        <APIKeyAuthentication authCred={authCred} authType={authType}
            testConnectionStatus={testConnectionStatus}
            onTestConnection={onTestConnection} onApiAuthCredChange={onApiAuthCredChange} onApiAuthTypeChange={onApiAuthCredChange} />

    </>);


export const APIKeyAuthentication: React.FC<EventsAPIAuthTypesProps> = ({ authCred, authType,
    testConnectionStatus,
    onTestConnection,
    onApiAuthCredChange }) => {

    let getTestConnectionStatusIcon = (): any => {

        if (testConnectionStatus == EventServiceStatus.Running) {
            return (<i className= { styles.test_connection_status + ' fas fa-check-circle ' +  styles.icon_correct } title={EventSettingsConstants.TestEventAPISuccess}></i>);
        }
        else if (testConnectionStatus == EventServiceStatus.Stopped) {
            return (<i className = { styles.test_connection_status + ' fas fa-exclamation-triangle ' + styles.icon_error } title={EventSettingsConstants.TestEventAPIFailed}></i>)
        }
        else if (testConnectionStatus == EventServiceStatus.Unknown) {
            return (<Plug className = { styles.test_connection_status + ' ' + styles.test_connection_not_loaded} title={EventSettingsConstants.TestConnection}></Plug>)
        }
        else {
            return (<i className = { styles.test_connection_status + ' fas fa-spinner fa-spin text-warning bg-color-transparent' }  title={EventSettingsConstants.TestEventAPIInProgress}></i>)
        }
    }

    return (<>
        <div className="row">
            <div className="col-lg-6">
                <label className='fw-semibold'> {EventSettingsConstants.ApiKeyLabel}</label>
                <span className={styles.asterisk}>*</span>
            </div>

            <div className="col-lg-12">               
                    <input type="text"
                        className= "form-control"
                        placeholder={EventSettingsConstants.ApiKeyPlaceholder}
                        value={authCred}
                        data-lpignore="true"
                        onChange={onApiAuthCredChange}
                    />                
                <br />               
                <Button
                    onClick={onTestConnection}
                    className= {styles.float_right}
                    variant='outline-secondary'
                    data-test-auto="DA5737F2-BF6F-4430-BDCB-D9E9AB9E4D12">
                    {getTestConnectionStatusIcon()}
                    {EventSettingsConstants.TestConnection}
                </Button>
            
            </div>
        </div>
    </>);

};