import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useDispatch } from 'react-redux';
import ButtonWithTextIcon from 'src/components/common/button-with-text-icon';
import ConfirmationPopup from 'src/components/common/confirmationPopup/index';
import SuiteModal from 'src/components/common/SuiteModal';
import AddIconCircular from 'src/components/svg/AddIconCircular';
import { ReactComponent as DoubleLeft } from 'src/components/svg/chevron-double-left.svg';
import { ReactComponent as DoubleRight } from 'src/components/svg/chevron-double-right.svg';
import { ReactComponent as Left } from 'src/components/svg/chevron-left.svg';
import { ReactComponent as Right } from 'src/components/svg/chevron-right.svg';
import DeactivateIcon from 'src/components/svg/DeactivateIcon';
import EditIcon from 'src/components/svg/EditIcon';
import TrashDeleteIcon from 'src/components/svg/TrashDeleteIcon';
import { CustomAuthorityConstants } from 'src/helper/Constants';
import { onlyAlphabetsAndSpaces } from 'src/helper/formValidations';
import { TaxReturnSettingsResources } from 'src/helper/rbac-constants';
import { ValidateWebsite } from 'src/helper/Validations';
import SaveCustomAutorityModal from 'src/modules/voucher-invoices/custom-authorities/save-custom-authority-modal/index';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { actionTypes as notificationActionTypes } from 'src/pages/common/notification/notification.types';

import getCustomAuthorities from './custom-authorities.api';
import styles from './custom-authorities.module.scss';
import {
	ICustomAuthorities,
	IPaginatedCustomAuthorityResponse,
	sortColumns,
	sortDirections,
} from './custom-authorities.types';
import {
	activateOrDeactivateAuthority,
	addAuthority,
	deleteCustomAuthority,
	getAllCustomAuthority,
	getAuthorityRelatedTaxDocuments,
	updateAuthority,
} from './save-custom-authority-modal/save-custom-authority-modal.api';
import { customAuthorityInitial } from './save-custom-authority-modal/save-custom-authority-modal.types';
import { logger } from 'src/oidcClient/authProvider';

interface IVoucherAndInvoicesProps {
	parentResourceIdentifier: string;
}

const CustomAuthoritiesTable: React.FC<IVoucherAndInvoicesProps> = (props) => {
	const { parentResourceIdentifier } = props;

	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);
	const [pages, setPages] = useState(1);
	const [authorities, setAuthorities] = useState<ICustomAuthorities[]>([]);
	const [cityName, setCityName] = useState('');
	const [state, setState] = useState('');
	const [sortDirection, setSortDirection] = useState<sortDirections>('asc');
	const [sortColumn, setSortColumn] = useState<sortColumns>('');
	const [showDeletePopUp, setShowDeletePopUp] = useState(false);
	const [buttonName, setButtonName] = useState('');
	const [modalTitle, setModalTitle] = useState('');
	const [showCustomAuthorityModal, setShowCustomAuthorityModal] = useState(false);
	const [deletedAuthorityId, setDelelteAuthorityId] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const [customAuthorityData, setCustomAuthorityData] = useState<ICustomAuthorities>(customAuthorityInitial);

	const [allCustomAuthority, setAllCustomAuthority] = useState<ICustomAuthorities[]>([]);

	const [showTaxingAuthorityDocuments, setShowTaxingAuthorityDocuments] = useState<string[]>([]);

	const [mandatoryFieldError, setMandatoryFieldError] = useState<any>({
		city: { error: false, message: '' },
		url: { error: false, message: '' },
	});

	useEffect(() => {
		fetchPaginatedData();
	}, [cityName, state, currentPage, sortDirection, sortColumn]);

	useEffect(() => {
		fetchAllCustomAuthoritiesData();
	}, []);

	useEffect(() => {
		setMandatoryFieldError({ ...mandatoryFieldError, state: { error: false, message: '' } });
	}, [customAuthorityData.abbreviation]);

	const fetchPaginatedData = (): void => {
		setIsLoading(true);
		getCustomAuthorities({
			city: cityName,
			pageNo: currentPage,
			pageSize: CustomAuthorityConstants.RESULTS_PER_PAGE,
			sortColumn: sortColumn,
			sortDirection: sortDirection,
			state: state,
			callback: (response?: IPaginatedCustomAuthorityResponse) => {
				setIsLoading(false);
				if (response) {
					setAuthorities(response.items);
					setPages(Math.ceil(response.count / CustomAuthorityConstants.RESULTS_PER_PAGE));
				} else
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusType: StatusType.Error,
						statusMessage: CustomAuthorityConstants.StatusMessage.CustomAuthoritiesError,
					});
			},
		});
	};

	const fetchAllCustomAuthoritiesData = (): void => {
		dispatch(
			getAllCustomAuthority((response: ICustomAuthorities[]) => {
				setAllCustomAuthority(response);
			}),
		);
	};

	const onHide = () => {
		setShowCustomAuthorityModal(false);
		setMandatoryFieldError({
			city: { error: false, message: '' },
			url: { error: false, message: '' },
		});
	};

	const onClickAdd = () => {
		setCustomAuthorityData(customAuthorityInitial);
		setButtonName('Add');
		setModalTitle('Add Custom Authority');
		setShowCustomAuthorityModal(true);
		setMandatoryFieldError({
			city: { error: false, message: '' },
			url: { error: false, message: '' },
		});
	};

	const onClickEdit = (editedAuthorityData: ICustomAuthorities) => {
		setMandatoryFieldError({
			city: { error: false, message: '' },
			url: { error: false, message: '' },
		});

		setCustomAuthorityData(editedAuthorityData);

		setButtonName('Update'), setModalTitle('Edit Custom Authority');
		setShowCustomAuthorityModal(true);
		dispatch(
			getAuthorityRelatedTaxDocuments(editedAuthorityData.id, (taxingAuthorityDocuments) => {
				setShowTaxingAuthorityDocuments(taxingAuthorityDocuments);
			}),
		);
	};

	const validateCustomAuthority = (customAuthority: ICustomAuthorities) => {
		return allCustomAuthority.findIndex(
			(x) =>
				x.abbreviation?.toLowerCase() === customAuthority.abbreviation.toLowerCase() &&
				(x.authorityName.toLowerCase() === customAuthority.authorityName.toLowerCase() ||
					x.authorityName.toLowerCase() ===
						customAuthority.abbreviation.toLowerCase() + ' - ' + customAuthority.authorityName.toLowerCase()),
		) === -1
			? true
			: false;
	};

	const addCustomAuthority = (resourceId: string) => {
		if (checkAllMandatoryFields()) {
			let bodyData: ICustomAuthorities = {
				id: customAuthorityData.id,
				active: customAuthorityData.active,
				customAuthority: true,
				paymentURL: customAuthorityData.paymentURL,
				prefixStateCode: customAuthorityData.prefixStateCode,
				authorityName: customAuthorityData.authorityName,
				isEsignEnabled: true, //For the Custom authorities, by default Esign should be enabled
				abbreviation: customAuthorityData.abbreviation,
				inUse: customAuthorityData.inUse,
				stateId: customAuthorityData.stateId,
			};

			if (validateCustomAuthority(bodyData)) {
				logger && logger.trackTrace('addCustomAuthority: Adding custom authority');
				setShowCustomAuthorityModal(false);
				dispatch(
					addAuthority(bodyData, resourceId, () => {
						fetchPaginatedData();
						dispatch({
							type: notificationActionTypes.NOTIFICATION,

							statusMessage: CustomAuthorityConstants.addCustomAuthoritySuccessMessage,

							statusType: StatusType.Success,
						});
						fetchAllCustomAuthoritiesData();
					}),
				);
			} else {
				dispatch({
					type: notificationActionTypes.NOTIFICATION,

					statusMessage: CustomAuthorityConstants.duplicateAuthorityWarningMessage,

					statusType: StatusType.Warning,
				});
			}
		}
	};

	const editCustomAuthority = (resourceId: string) => {
		if (checkAllMandatoryFields()) {
			logger && logger.trackTrace('editCustomAuthority: Updating custom authority');
			let bodyData: ICustomAuthorities = {
				id: customAuthorityData.id,
				active: customAuthorityData.active,
				customAuthority: true,
				paymentURL: customAuthorityData.paymentURL,
				prefixStateCode: customAuthorityData.prefixStateCode,
				authorityName: customAuthorityData.authorityName,
				isEsignEnabled: true, //For the Custom authorities, by default Esign should be enabled
				abbreviation: customAuthorityData.abbreviation,
				inUse: customAuthorityData.inUse,
				stateId: customAuthorityData.stateId,
			};
			setShowCustomAuthorityModal(false);
			dispatch(
				updateAuthority(bodyData, resourceId, () => {
					fetchPaginatedData();
					dispatch({
						type: notificationActionTypes.NOTIFICATION,

						statusMessage: CustomAuthorityConstants.editCustomAuthoritySuccessMessage,

						statusType: StatusType.Success,
					});
				}),
			);
		}
	};

	const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		if (onlyAlphabetsAndSpaces(event.target.value)) {
			setCustomAuthorityData({ ...customAuthorityData, authorityName: event.target.value });
		}
		setMandatoryFieldError({ ...mandatoryFieldError, city: { error: false, message: '' } });
	};

	const onChangeURL = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		setCustomAuthorityData({ ...customAuthorityData, paymentURL: event.target.value });
		setMandatoryFieldError({ ...mandatoryFieldError, url: { error: false, message: '' } });
	};

	const handlePrefixChange = () => {
		if (customAuthorityData.prefixStateCode) setCustomAuthorityData({ ...customAuthorityData, prefixStateCode: false });
		else setCustomAuthorityData({ ...customAuthorityData, prefixStateCode: true });
	};

	const handleActivateOrDeactivate = () => {
		if (customAuthorityData.active) setCustomAuthorityData({ ...customAuthorityData, active: false });
		else setCustomAuthorityData({ ...customAuthorityData, active: true });
	};

	const checkAllMandatoryFields = () => {
		let result = true;
		if (customAuthorityData.authorityName.trim().length === 0) {
			setMandatoryFieldError({ ...mandatoryFieldError, city: { error: true, message: 'This field is required' } });
			result = false;
		} else if (
			customAuthorityData.paymentURL.trim().length > 0 &&
			!ValidateWebsite(customAuthorityData.paymentURL.trim())
		) {
			setMandatoryFieldError({
				...mandatoryFieldError,
				url: { error: true, message: 'Please enter valid online payment url.' },
			});
			result = false;
		}
		return result;
	};

	const onClickActivateOrDeactivate = (resourceId: string, customAuthorities: ICustomAuthorities) => {
		customAuthorities.active = !customAuthorities.active;
		dispatch(
			activateOrDeactivateAuthority(customAuthorities, resourceId, () => {
				fetchPaginatedData();

				dispatch({
					type: notificationActionTypes.NOTIFICATION,

					statusMessage: CustomAuthorityConstants.activeOrDeactiveSuccessMessage,

					statusType: StatusType.Success,
				});
			}),
		);
	};

	const deleteModal = (authorityInUse: number) => {
		if (authorityInUse === 0) {
			setShowDeletePopUp(true);
		} else {
			dispatch({
				type: notificationActionTypes.NOTIFICATION,

				statusMessage: CustomAuthorityConstants.inUseWarning,

				statusType: StatusType.Warning,
			});
		}
	};

	const onOkDeleteAuthority = (resourceId: string) => {
		let taxingAuthority = authorities.filter((x) => x.id === deletedAuthorityId)[0];
		if (taxingAuthority.inUse !== 0) {
			setShowDeletePopUp(false);
			dispatch({
				type: notificationActionTypes.NOTIFICATION,

				statusMessage: CustomAuthorityConstants.inUseWarning,

				statusType: StatusType.Warning,
			});
		} else {
			setShowDeletePopUp(false);
			dispatch(
				deleteCustomAuthority(deletedAuthorityId, resourceId, () => {
					fetchPaginatedData();
					dispatch({
						type: notificationActionTypes.NOTIFICATION,

						statusMessage: CustomAuthorityConstants.deleteAuthoritySuccess,

						statusType: StatusType.Success,
					});
				}),
			);
		}
	};

	return (
		<article className='suitePageSection'>
			<h3 className={'suitePageSectionHeader ' + styles.sectionHeaderAlignments}>
				Custom Authorities
				<ButtonWithTextIcon
					label='Add'
					SvgIcon={<AddIconCircular />}
					onClick={() => onClickAdd()}
					data-test-auto='830e1685-aaa8-4eb2-b522-5e5fdc8a3a6b'
					className={styles.addButton}
				/>
			</h3>
			<div className={styles.table_container}>
				<Table
					striped
					bordered>
					<thead>
						<tr>
							<th className={styles.stateHeader}>
								Authority Name
								<i
									onClick={() => {
										setSortColumn('abbreviation');
										setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
									}}
									data-test-auto='1864cac5-8d05-48ed-bf1a-c23d5e025ad8'
									className={`${styles.sortIcons} fa fa-fw fa-sort${
										sortColumn !== 'abbreviation' ? '' : sortDirection === 'asc' ? '-asc' : '-desc'
									} sortIcon`}></i>
								<input
									type='text'
									placeholder='Search by State'
									className={styles.searchInput}
									value={state}
									onChange={({ target }) => {
										setState(target.value);
										setCurrentPage(1);
									}}
								/>
							</th>
							<th className={styles.cityHeader}>
								City
								<i
									onClick={() => {
										setSortColumn('name');
										setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
									}}
									data-test-auto='5b45dc3c-ff26-41b7-b7ea-96b79015b629'
									className={`${styles.sortIcons} fa fa-fw fa-sort${
										sortColumn !== 'name' ? '' : sortDirection === 'asc' ? '-asc' : '-desc'
									} sortIcon`}></i>
								<input
									type='text'
									placeholder='Search by City'
									className={styles.searchCity}
									value={cityName}
									onChange={({ target }) => {
										setCityName(target.value);
										setCurrentPage(1);
									}}
								/>
							</th>
							<th className={styles.actionSection}>Actions</th>
						</tr>
					</thead>
					<tbody>
						{isLoading ? (
							<tr>
								<td
									data-toggle='collapse'
									colSpan={3}
									className={styles.authoritiesLoadingArea}>
									<div className='text-center'>
										<div
											className='spinner-border'
											role='status'></div>
										<div className={styles.authoritiesLoadingTextWrapper}>
											<span className={styles.authoritiesLoadingText}>Loading...</span>
										</div>
									</div>
								</td>
							</tr>
						) : authorities.length < 1 ? (
							<tr>
								<td className={styles.noRecordDiv}>There is no data to display</td>
							</tr>
						) : (
							authorities.map((authority) => (
								<tr key={authority.id}>
									<td className={styles.stateColumnValue}>{authority.abbreviation}</td>
									<td
										className={styles.cityColumnValue}
										title={authority.authorityName}>
										{authority.authorityName}
									</td>
									<td className={styles.actionsColumnValue}>
										<div className={styles.actionDivButtonSection}>
											<ButtonWithTextIcon
												label='Edit'
												className={styles.editButton}
												SvgIcon={<EditIcon />}
												onClick={() => {
													onClickEdit(authority);
												}}
												data-test-auto={`34bd0472-6da0-49f9-84c9-77ee1ec3b3b9${authority.id}`}
											/>
											<ButtonWithTextIcon
												label='Delete'
												className={styles.deleteButton}
												disabled={authority.inUse > 0}
												disabledText={CustomAuthorityConstants.inUseWarning}
												SvgIcon={<TrashDeleteIcon />}
												onClick={() => {
													setDelelteAuthorityId(authority.id);
													deleteModal(authority.inUse);
												}}
												data-test-auto={`2763635e-58a8-4b8a-a55d-839ba16af7ac${authority.id}`}
											/>
											<ButtonWithTextIcon
												label={authority.active ? 'Deactivate' : 'Activate'}
												className={styles.deactivateButton}
												SvgIcon={<DeactivateIcon />}
												onClick={() => {
													onClickActivateOrDeactivate(
														parentResourceIdentifier +
															TaxReturnSettingsResources.CustomAuthorities +
															TaxReturnSettingsResources.ButtonActivateOrDeactivate,
														authority,
													);
												}}
												data-test-auto={`275bd06b-a3da-412e-9e32-2b906a01e7be${authority.id}`}
											/>
										</div>
									</td>
								</tr>
							))
						)}
					</tbody>
				</Table>
			</div>
			<div className={styles.navigatorContainer}>
				<div
					className={`${styles.navAction} ${currentPage === 1 ? styles.disabled_nav : ''}`}
					title='First Page'
					data-test-auto='5328c679-334e-466e-a28d-b84d09fc859e'
					onClick={() => {
						if (currentPage > 1) setCurrentPage(1);
					}}>
					<DoubleLeft />
				</div>
				<div
					className={`${styles.navAction} ${currentPage === 1 ? styles.disabled_nav : ''}`}
					title='Previous Page'
					data-test-auto='41d997b5-b890-4993-9007-eb9adc209216'
					onClick={() => {
						if (currentPage > 1) setCurrentPage(currentPage - 1);
					}}>
					<Left />
				</div>
				<div
					className={styles.pageNumbers}
					title={currentPage.toString()}>
					{currentPage}
				</div>
				<div
					className={`${styles.navAction} ${currentPage === pages || pages === 0 ? styles.disabled_nav : ''}`}
					title='Next Page'
					data-test-auto='235be87a-f941-4e69-9a65-de0c35fc11be'
					onClick={() => {
						if (currentPage < pages) setCurrentPage(currentPage + 1);
					}}>
					<Right />
				</div>
				<div
					className={`${styles.navAction} ${currentPage === pages || pages === 0 ? styles.disabled_nav : ''}`}
					title='Last Page'
					data-test-auto='8b0caadb-4fa9-4553-93e4-a0180e0780e2'
					onClick={() => {
						if (currentPage < pages) setCurrentPage(pages);
					}}>
					<DoubleRight />
				</div>
			</div>

			{showCustomAuthorityModal && (
				<SuiteModal
					width='692'
					theme='dark'
					title={modalTitle}
					hide={onHide}>
					<SaveCustomAutorityModal
						buttonName={buttonName}
						setShowCustomAuthorityModal={setShowCustomAuthorityModal}
						addCustomAuthority={addCustomAuthority}
						editCustomAuthority={editCustomAuthority}
						handleCityChange={handleCityChange}
						onChangeURL={onChangeURL}
						handleActivateOrDeactivate={handleActivateOrDeactivate}
						handlePrefixChange={handlePrefixChange}
						mandatoryFieldError={mandatoryFieldError}
						parentResourceIdentifier={parentResourceIdentifier}
						showTaxingAuthorityDocuments={showTaxingAuthorityDocuments}
						setCustomAuthorityData={setCustomAuthorityData}
						customAuthorityData={customAuthorityData}
					/>
				</SuiteModal>
			)}
			{showDeletePopUp && (
				<ConfirmationPopup
					className={styles.customDeleteModal}
					show={showDeletePopUp}
					actionButtonType={'danger'}
					cancelText={CustomAuthorityConstants.cancelText}
					header={CustomAuthorityConstants.headerText}
					message={CustomAuthorityConstants.bodyText}
					okText={CustomAuthorityConstants.okText}
					onCancel={() => {
						setShowDeletePopUp(false);
					}}
					onOk={() => {
						onOkDeleteAuthority(
							parentResourceIdentifier +
								TaxReturnSettingsResources.CustomAuthorities +
								TaxReturnSettingsResources.ButtonDelete,
						);
					}}
					onHide={() => {
						setShowDeletePopUp(false);
					}}
					disabledText={''}></ConfirmationPopup>
			)}
		</article>
	);
};

export default CustomAuthoritiesTable;
