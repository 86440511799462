import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/Constants';

import { ISMSNotificationRequest, ISMSNotificationResponse } from './SMSNotifications.model';
import { logger } from 'src/oidcClient/authProvider';

export const getSMSNotification = (callback: (responseObject: ISMSNotificationResponse) => void): void => {
	const responseObject: ISMSNotificationResponse = {
		error: false,
		isSMSNotificationEnabled: false,
	};
	axios
		.get(`${API_BASE_URL}/api/notification-setting`)
		.then((response: AxiosResponse) => {
			responseObject.error = false;
			responseObject.isSMSNotificationEnabled = response.data?.isSMSNotificationEnabled;
			callback(responseObject);
		})
		.catch((error) => {
            logger && logger.trackWarning('Error while getSMSNotification', { 'Error': error?.response?.data});
			responseObject.error = true;
			callback(responseObject);
		});
};

export const updateSMSNotification = (
	resourceId: string,
	requestObject: ISMSNotificationRequest,
	callback: (success: boolean) => void,
): void => {
	axios
		.put(`${API_BASE_URL}/api/notification-setting`, requestObject, {
			headers: {
				'X-Resource-Id': resourceId,
			},
		})
		.then((response: AxiosResponse) => {
			callback(response.data);
		})
		.catch((error) => {
            logger && logger.trackWarning('Error while updateSMSNotification', { 'Error': error?.response?.data});
			callback(false);
		});
};
