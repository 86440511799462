import React, { useEffect } from "react";
import { storeUser, userExpired } from "../pages/common/auth/auth.actions";
import { userManager } from "./userService";
import { ILogger } from "src/logger/Logger";
import { TelemetryLogger } from "src/logger/TelemetryLogger";

export let logger:ILogger;
export default function AuthProvider({
  userManager: manager,
  store,
  children,
}: any) {
  useEffect(() => {

    const onUserLoaded = (user: any) => {
      logger = TelemetryLogger.getInstance();
      store.dispatch(storeUser(user));
    };

    const onUserUnloaded = () => {
      //setAuthHeader(null)
    };

    const onAccessTokenExpiring = () => {
      userManager
        .signinSilent()
        .then((user) => {
          store.dispatch(storeUser(user));
        })
        .catch(() => {
          userManager
            .getUser()
            .then((user) => store.dispatch(storeUser(user)));
        });
    };

    const onAccessTokenExpired = () => {
      userExpired();
    };

    const onUserSignedOut = () => {
      //
    };

    // events for user

    userManager.events.addUserLoaded(onUserLoaded);
    userManager.events.addUserUnloaded(onUserUnloaded);
    userManager.events.addAccessTokenExpiring(onAccessTokenExpiring);
    userManager.events.addAccessTokenExpired(onAccessTokenExpired);
    userManager.events.addUserSignedOut(onUserSignedOut);

    // Specify how to clean up after this effect:
    return function cleanup() {
      userManager.events.removeUserLoaded(onUserLoaded);
      userManager.events.removeUserUnloaded(onUserUnloaded);
      userManager.events.removeAccessTokenExpiring(
        onAccessTokenExpiring
      );
      userManager.events.removeAccessTokenExpired(onAccessTokenExpired);
      userManager.events.removeUserSignedOut(onUserSignedOut);
    };
  }, [manager, store]);

  return React.Children.only(children);
}
