import React, { useState, useEffect, FC } from 'react';
import { IPaymentReceiptInfo } from '../PurchaseReturn.model';
import { fetchPaymentDetails } from './PaymentReceipt.store';
import { useParams } from 'react-router-dom';
import PurchaseSuccessIcon from 'src/components/svg/PurchaseSuccessIcon';
import PrintIcon from 'src/components/svg/PrintIcon';
import styles from './PaymentReceipt.module.scss';
import { logger } from 'src/oidcClient/authProvider';

const PaymentReceipt: FC = () => {
	const [paymentReceipt, setPaymentReceipt] = useState<IPaymentReceiptInfo>(null);
	const { id } = useParams();

	useEffect(() => {
		fetchPaymentDetails(id)
			.then((data: IPaymentReceiptInfo) => {
				setPaymentReceipt(data);
			})
			.catch((error) => {
				logger && logger.trackWarning('Error while fetchPaymentDetails', { 'Error': error?.response?.data});
				setPaymentReceipt(null);
			});
	});

	const printDiv = () => {
		document.getElementById('print-page').style.display = 'none';
		window.print();
		document.getElementById('print-page').style.display = 'block';
	};

	return paymentReceipt ? (
		<div className={styles.purchaseSummary}>
			<section className={styles.main}>
				<div className={styles.purchaseSuccessIcon}>
					<PurchaseSuccessIcon />
				</div>
				<div className={styles.print}>
					<span
						data-test-auto='223f782a-6708-4bcd-8713-aec22f5485c2'
						id='print-page'
						onClick={() => {
							printDiv();
						}}>
						<span className={styles.printIcon}>
							<PrintIcon />
						</span>
						<span className={styles.printText}>Print</span>
					</span>
				</div>
				<div className={styles.thankyou}>
					Thank you
					<br />
					for your purchase!
				</div>
				<div className={styles.invoice}>
					<span className={styles.invoiceText}>Invoice Number: </span>
					<span className={styles.invoiceNumber}>{paymentReceipt.invoiceNumber}</span>
				</div>
				<div className={styles.purchaseDetailsTable}>
					<table>
						<thead>
							<th className={styles.productCol}>Product</th>
							<th className={styles.descriptionCol}>Description</th>
							<th className={styles.totalCol}></th>
						</thead>
						<tbody>
							{paymentReceipt.lineItems.map((item) => {
								return (
									<tr key={item.name}>
										<td className={styles.productDataCol}>{item.name}</td>
										<td className={styles.descriptionDataCol}>
											{item.quntity} Unit at ${item.costPerItem}/ {item.docType}
										</td>
										<td className={styles.totalDataCol}>${item.total.toFixed(2)}</td>
									</tr>
								);
							})}
							<tr>
								<td className={styles.productDataCol}>Sales Tax</td>
								<td className={styles.descriptionDataCol}></td>
								<td className={styles.totalDataCol}>${paymentReceipt.taxAmount.toFixed(2)}</td>
							</tr>
							<tr>
								<td className={styles.productDataCol}></td>
								<td className={styles.descriptionDataCol}></td>
								<td className={`${styles.totalDataCol} ${styles.formatTotal}`}>
									<span>Total</span>
									<span>${paymentReceipt.totalAmount.toFixed(2)}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className={styles.query}>
					If you have any question about your purchase,
					<br />
					please contact us{' '}
					<a
						href='mailto:sales@safesend.com'
						data-test-auto='40906f92-48a8-42e0-ae5c-91f3758b8c7a'>
						sales@safesend.com
					</a>
				</div>
			</section>
			<section className={styles.footer}>
				<div className={styles.copyright}>Copyright &copy; 2023 cPaperless LLC</div>
				<div className={styles.terms}>
					<span>
						<a
							href='https://safesend.com/legal-policies/terms-of-use.php'
							target='_blank'
							rel='noreferrer'
							data-test-auto='df236bcc-750b-4e22-883c-d280d99101f2'>
							Terms of service
						</a>
					</span>{' '}
					|{' '}
					<span>
						<a
							href='https://safesend.com/legal-policies/privacy-statement.php'
							target='_blank'
							rel='noreferrer'
							data-test-auto='303e87fe-ec03-46a3-bde8-622a8ab12789'>
							Privacy Policy
						</a>
					</span>
				</div>
			</section>
		</div>
	) : (
		<div></div>
	);
};

export default PaymentReceipt;
