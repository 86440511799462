import axios from "axios";
import { API_BASE_URL } from '../../helper/Constants';
import { logger } from '../../oidcClient/authProvider';

export async function getWebhookFeatureEnabled(): Promise<boolean> {
    try{
        const response = await axios.get(API_BASE_URL + '/api/developer-section/webhook-feature-enabled');
        return response.data;
    }
    catch(error){
        logger && logger.trackWarning('Error while getWebhookFeatureEnabled', { 'Error': error});
        return false;
    }
}