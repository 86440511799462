import { ClientManagementConstants } from '../../helper/Constants';
import { AppNotifier as VenusNotifier } from '../../helper/AppNotifier';

const parseCSV = (fileContents: Uint8Array): boolean => {
	const csvString = new TextDecoder().decode(fileContents);
	const rows = csvString.split('\n');

	if (rows.length) {
		let totalRows;
		const headerRow = rows[0].split(',');
		const lastRow = rows[rows.length - 1];

		if (headerRow.length <= 1) {
			VenusNotifier.Error(ClientManagementConstants.invalidCSVFile);
			return false;
		}

		if (lastRow.trim().length > 0) {
			const lastRecord = lastRow.split(',');
			if (lastRecord.length === headerRow.length) {
				totalRows = rows.length;
			} else {
				totalRows = rows.length - 1;
			}
		} else {
			totalRows = rows.length - 1;
		}

		if (totalRows <= 1) {
			VenusNotifier.Error(ClientManagementConstants.emptyFile);
		} else if (totalRows > 1 && totalRows <= 5001) {
			return true;
		} else if (totalRows > 5001) {
			VenusNotifier.Error(ClientManagementConstants.recordsExceeded);
		}
	} else {
		VenusNotifier.Error(ClientManagementConstants.emptyFile);
	}

	return false;
};

export const isValidAndReadableCsv = (file: File, callback: () => void) => {
	if (
		(file.type === 'text/csv' || file.type === 'application/vnd.ms-excel') &&
		file.name.toLowerCase().endsWith('.csv')
	) {
		const fileReader = new FileReader();
		fileReader.onloadend = () => {
			const fileContents = new Uint8Array(fileReader.result as ArrayBuffer);
			const result = parseCSV(fileContents);
			if (result) {
				callback();
			}
		};
		fileReader.readAsArrayBuffer(file);
	} else {
		VenusNotifier.Error(ClientManagementConstants.invalidCSVFile);
	}
};
