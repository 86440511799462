import { download } from '../../helper/FileUtilities';
import { API_BASE_URL } from '../../helper/Constants';
import axios, { AxiosResponse } from 'axios';
import { usageReportResources } from '../../helper/rbac-constants';
import { logger } from 'src/oidcClient/authProvider';

export const exportUsageReportToExcel = async (query: string, productType: string) => {
	try {
		const response: AxiosResponse = await axios.get(API_BASE_URL + '/api/Billing/ExportExcelAsync' + query, {
			responseType: 'blob',
			headers: {
				'X-Resource-Id': `${usageReportResources.Prefix + usageReportResources.ExportButton}`
			}
		});
		const url = window.URL.createObjectURL(new Blob([response.data]));
		download(url, `${productType} Usage Report.xlsx`);
	} catch (error) {
		logger && logger.trackWarning('Error while exportUsageReportToExcel', { 'Error': error?.response?.data});
	}
};
