import React, { useRef, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import InfoIcon from 'src/components/svg/InfoIcon';
import YellowWarningIcon from 'src/components/svg/YellowWarningIcon';
import styles from './save-custom-authority-modal.module.scss';
import CustomDropdown from 'src/components/common/customDropdown';
import { useSelector } from 'react-redux';
import { type ICountryState } from 'src/pages/company-management/company-management.types';
import { TaxReturnSettingsResources } from 'src/helper/rbac-constants';
import { ICustomAuthorities } from '../custom-authorities.types';
import FormFieldWithError from 'src/components/common/FormFieldWithError';

interface CustomModalProps {
	setShowCustomAuthorityModal: (showCustomAuthorityModal: boolean) => void;
	addCustomAuthority: (resourceId: string) => void;
	editCustomAuthority: (resourceID: string) => void;
	handleCityChange: (event: any) => void;
	onChangeURL: (event: any) => void;
	handlePrefixChange: (event: any) => void;
	handleActivateOrDeactivate: (event: any) => void;
	buttonName: string;
	mandatoryFieldError: any;
	parentResourceIdentifier: string;
	showTaxingAuthorityDocuments: string[];
	customAuthorityData: ICustomAuthorities;
	setCustomAuthorityData: (event: any) => void;
}
interface ISelectOption {
	value: string;
	name: string;
}

const SaveCustomAutorityModal: React.FC<CustomModalProps> = (props) => {
	const {
		setShowCustomAuthorityModal,
		addCustomAuthority,
		editCustomAuthority,
		handleCityChange,
		onChangeURL,
		handlePrefixChange,
		handleActivateOrDeactivate,
		buttonName,
		mandatoryFieldError,
		parentResourceIdentifier,
		showTaxingAuthorityDocuments,
		setCustomAuthorityData,
		customAuthorityData,
	} = props;

	const setSelectedState = (event: any) => {
		setCustomAuthorityData({ ...customAuthorityData, abbreviation: event.target.value });
	};

	const containerRef = useRef<HTMLInputElement>(null);

	const [showAssociatedTaxReturns, setShowAssociatedTaxReturns] = useState(false);

	const handleCustomAuthority = () => {
		setShowCustomAuthorityModal(false);
	};

	const onClickAssociatedReturns = () => {
		setShowAssociatedTaxReturns(true);
	};

	const companyData = useSelector((store) => {
		return store?.companyData;
	});

	const getArrayOfStateOptions = (): ISelectOption[] => {
		const countryStates: ICountryState[] = companyData?.companyProfile.countryStates;
		const stateOptions: ISelectOption[] = countryStates.map((eachCountry: ICountryState) => {
			return { value: eachCountry.name, name: eachCountry.name };
		});
		const stateOptionsFiltered: ISelectOption[] = stateOptions.filter((eachCountry: ISelectOption) => {
			return eachCountry.name && eachCountry.value;
		});
		return stateOptionsFiltered;
	};

	return (
		<>
			<Modal.Body className={styles.showGrid}>
				<Container ref={containerRef}>
					<Form>
						{customAuthorityData.inUse > 0 && (
							<div className={styles.warningMessageDisplayFlex}>
								<div className={styles.warningSymbol}>
									<YellowWarningIcon />
								</div>

								<div className={styles.warningText}>
									This authority you are trying to edit is currently in use. Please remove the Authority from below tax
									returns and try again. Click&nbsp;
									<a
										data-toggle='tab'
										className={styles.showAssociatedReturnTab}
										href='javascript:void(0)'
										onClick={onClickAssociatedReturns}>
										View Associated Returns
									</a>
									{' to view the linked returns.'}
								</div>
							</div>
						)}

						<FormFieldWithError
							type='select'
							name='state'
							id='state'
							title='State'
							fieldWidth='158px'
							onChange={setSelectedState}
							className={`${customAuthorityData.inUse > 0 ? styles.disableStateAndCity : styles.stateStyle}`}
							value={customAuthorityData.abbreviation}
							options={getArrayOfStateOptions()}
							selectFirstOption={{ value: '0', name: '' }}
						/>

						<FormFieldWithError
							type='text'
							name='city'
							id='city'
							title='City'
							fieldWidth='400px'
							maxLength='100'
							onChange={handleCityChange}
							className={`${customAuthorityData.inUse > 0 ? styles.disableStateAndCity : ''}`}
							value={customAuthorityData.authorityName}
							error={mandatoryFieldError.city.error}
							errorMessage={mandatoryFieldError.city.message}
							placeholder={'City'}
						/>

						<Row>
							<Col>
								<Form.Group controlId='url'>
									<Form.Label
										htmlFor='basic-url'
										className={styles.urlLabel}>
										{' '}
										Online Payment URL{' '}
										<span className={styles.paymentURLInfo} title='http://example.com or https://example.com or www.example.com'>
											<InfoIcon />
										</span>
									</Form.Label>
									<InputGroup className={styles.urlWrapper}>
										<FormFieldWithError
											name='url'
											onChange={onChangeURL}
											className={styles.url}
											value={customAuthorityData.paymentURL}
											placeholder='URL'
											type='text'
											id='url'
											maxLength='100'
											error={mandatoryFieldError.url.error}
										/>
									</InputGroup>
									<div className={`${mandatoryFieldError.url.error ? styles.requiredErrorText : ''}`}>
										{mandatoryFieldError.url.message}
									</div>
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col className={styles.prefix}>
								<Form.Group className='mb-3'>
									<div>
										<input
											type='checkbox'
											className={styles.prefixCheckBox}
											id={'prefixCheckBox_id'}
											checked={customAuthorityData.prefixStateCode}
											onChange={handlePrefixChange}
										/>

										<label
											htmlFor={'prefixCheckBox_id'}
											className={styles.prefixText}
											id={'prefixCheckBox_id'}>
											&nbsp; Prefix State Code with City Name
											<span title='State code will be Prefixed to the Entered City'>
												<InfoIcon />
											</span>
										</label>
									</div>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Group
								className='mb-3'
								xs={5}
								md={8}
								sm={6}>
								<div>
									<input
										type='checkbox'
										className={styles.activeCheckBox}
										id={'activeCheckBox_id'}
										checked={customAuthorityData.active}
										onChange={handleActivateOrDeactivate}></input>

									<label
										htmlFor={'activeCheckBox_id'}
										className={styles.activeText}>
										&nbsp; Activated
									</label>
								</div>
							</Form.Group>
						</Row>

						{showAssociatedTaxReturns && (
							<div className='associatedDropDownWrapper'>
								<CustomDropdown
									customPlaceHolder='Associated Tax Returns'
									className={styles.associatedDropDown}
									height={38}
									borderColor={'#898D91'}
									scrollableMenu={true}
									clearable={false}
									searchable={false}
									customPlaceholderStyles={{
										color: '#000000',
										height: '20px',
										width: '175px',
										paddingBottom: '25px',
										fontWeight: '400',
									}}
									backgroundColor={'#F0F1F1'}
									classNamePrefix='associatedReturns'
									options={showTaxingAuthorityDocuments.map((x, index) => ({ value: index, label: x }))}
								/>
							</div>
						)}
					</Form>
				</Container>
			</Modal.Body>
			<Modal.Footer className={styles.footerStyle}>
				<div>
					<Button
						className={styles.cancelBtn}
						onClick={handleCustomAuthority}
						variant='secondary'>
						Cancel
					</Button>
					{buttonName === 'Add' && (
						<Button
							className={styles.addBtn}
							onClick={() =>
								addCustomAuthority(
									parentResourceIdentifier +
										TaxReturnSettingsResources.CustomAuthorities +
										TaxReturnSettingsResources.ButtonAdd,
								)
							}>
							Add
						</Button>
					)}
					{buttonName === 'Update' && (
						<Button
							className={styles.addBtn}
							onClick={() =>
								editCustomAuthority(
									parentResourceIdentifier +
										TaxReturnSettingsResources.CustomAuthorities +
										TaxReturnSettingsResources.ButtonEdit,
								)
							}>
							Update
						</Button>
					)}
				</div>
			</Modal.Footer>
		</>
	);
};
export default SaveCustomAutorityModal;
