import { API_BASE_URL } from 'src/helper/Constants';
import axios, { AxiosResponse } from 'axios';
import { ICustomAuthoritiesRequestProps, IPaginatedCustomAuthorityResponse } from './custom-authorities.types';
import { logger } from 'src/oidcClient/authProvider';

export default function getCustomAuthorities(params: ICustomAuthoritiesRequestProps) {
	let url: string = `${API_BASE_URL}/api/custom-authorities?SortColumn=${params.sortColumn}&SortDirection=${params.sortDirection}&AbbreviationText=${params.state}&NameText=${params.city}&pageNo=${params.pageNo}&pageSize=${params.pageSize}`;
	axios
		.get(url)
		.then((res: AxiosResponse<IPaginatedCustomAuthorityResponse>) => params.callback(res.data))
		.catch((error) => {
            logger && logger.trackWarning('Error while getCustomAuthorities', { 'Error': error?.response?.data});
			params.callback();
		});
}
	