import { AppThunkAction } from '../../store/index';
import { API_BASE_URL } from '../../helper/Constants';
import { ICompanyLedgerModelV2, KnownAction } from '../../modules/usageReport/UsageReport.model';
import { DispatchAction, IUsageReport } from './UsageReport.model';
import { Reducer } from 'redux';
import { actionTypes } from './UsageReport.action';
import { StatusType } from '../../pages/common/notification/notification.reducer';
import axios, { AxiosResponse } from 'axios';
import { usageReportResources } from '../../helper/rbac-constants';
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {
	requestClientLedgerData:
		(query: string, callback?: (data?: any) => void): AppThunkAction<KnownAction> =>
		async (dispatch, getState) => {
			try {
				dispatch({ type: actionTypes.REQUEST_USAGE_REPORT });
				const response: AxiosResponse<ICompanyLedgerModelV2[]> = await axios.get(
					API_BASE_URL + '/api/Billing/GetCompanyLedgerAsync' + query,
					{
						headers: {
							'X-Resource-Id': `${usageReportResources.Prefix + usageReportResources.RunReport}`,
						},
					},
				);
				dispatch({ type: actionTypes.RECEIVE_USAGE_REPORT, usageReport: response.data });
			} catch (error: any) {
				logger && logger.trackWarning('Error while requestClientLedgerData', { 'Error': error?.response?.data});
				dispatch({
					type: actionTypes.ERROR_USAGE_REPORT,
					statusMessage: error,
					statusType: StatusType.Error,
				});
			}
		},
};

const unloadedState: IUsageReport = {
	isLoading: false,
	usageReport: [],
	count: 0,
} as IUsageReport;

export const reducer: Reducer<IUsageReport> = (state = unloadedState, incomingAction) => {
	const action = incomingAction as DispatchAction;

	switch (action.type) {
		case actionTypes.REQUEST_USAGE_REPORT:
			return {
				isLoading: true,
				usageReport: [...state.usageReport],
				count: state.count,
			} as IUsageReport;
		case actionTypes.RECEIVE_USAGE_REPORT:
			return {
				isLoading: false,
				usageReport: action.usageReport,
				count: action.usageReport[0]?.count ?? 0,
			} as IUsageReport;
		case actionTypes.ERROR_USAGE_REPORT:
			const errorState: IUsageReport = Object.assign({}, state);
			errorState.isLoading = false;
			return errorState;

		default:
			return state;
	}
};
