import axios, { AxiosResponse,AxiosError } from "axios";
import { AppThunkAction } from "../../../store";
import { actionTypes } from "./userSettings.actions";
import { KnownActions } from "./userSettings.types";
import { StatusType } from "../notification/notification.reducer";
import { actionTypes as notifactionAction } from "../notification/notification.types";
import { AppNotifier } from "../../../helper/AppNotifier";
import { API_BASE_URL, userSettingsConstant } from "../../../helper/Constants";
import { logger } from 'src/oidcClient/authProvider';

export const fetchLastLoginEnable =
    (): AppThunkAction<KnownActions> =>
        (dispatch) => {
            dispatch({ type: actionTypes.REQUEST_LAST_LOGIN_ENABLE });
            axios
                .get(`${API_BASE_URL}/api/user-management/last-login-user-settings`)
                .then((response: AxiosResponse<boolean>) => {
                    const { data } = response;
                    dispatch({
                        type: actionTypes.RECEIVE_LAST_LOGIN_ENABLE,
                        payload: data
                    });
                })
                .catch(function (error: AxiosError) {
                    logger && logger.trackWarning('Error while fetchLastLoginEnable', { 'Error': error?.response?.data});
                    if(error.response && error.response.status!=419 && error.response.status!=512)
                    {
                        dispatch({
                            type: notifactionAction.NOTIFICATION,
                            statusMessage: error.message,
                            statusType: StatusType.Error,
                        });
                    }
                    dispatch({
                        type: actionTypes.COMPLETE_LAST_LOGIN_ENABLE,
                    });
                    
                });
        };

export const updateLastLoginEnable =
    (payload: boolean): AppThunkAction<KnownActions> =>
        (dispatch) => {
            dispatch({ type: actionTypes.REQUEST_UPDATE_LAST_LOGIN_ENABLE });
            const value = { enableLastLogin: payload };
            axios
                .put(`${API_BASE_URL}/api/user-management/last-login-user-settings/${payload}`)
                .then(() => {
                    dispatch({ type: actionTypes.RECEIVE_UPDATE_LAST_LOGIN_ENABLE, payload: value });
                    AppNotifier.Success(userSettingsConstant.APPLY_CHANGES_SUCCESS);
                })
                .catch(function (error: Error) {
                    logger && logger.trackWarning('Error while updateLastLoginEnable', { 'Error': error?.response?.data});
                    dispatch({
                        type: notifactionAction.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                    });
                    dispatch({
                        type: actionTypes.COMPLETE_UPDATE_LAST_LOGIN_ENABLE,
                    });
                });
        };