export interface ISaleTaxItem {
    productNumber: string;
    price: number;
    quantity: number;
    taxCode: string;
    itemCode: string;
    taxIncluded: boolean;
    companyCode: string;
}

export const initSaleTaxData = {
    productNumber: '',
    price: 0,
    quantity: 0,
    taxCode: '',
    itemCode: '',
    taxIncluded: false,
    companyCode: '',
};

export interface ITaxInfo {
    totalTax: number;
    baseTaxLineInfo: IBaseTaxLineInfo[];
}

export interface IBaseTaxLineInfo {
    productNumber: string;
    discountAmount: number;
    exemptAmount: number;
    lineAmount: number;
    quantity: number;
    taxAmount: number;
    taxableAmount: number;
    taxCalculated: number;
    taxPerItem: number;
}

export enum TaxCodeType {
    None = 0,
    SSR = 1,
    Signature = 2,
    SSO = 3,
}

export interface ITaxCodeData {
    taxCode: string;
    taxCodeType: TaxCodeType;
    itemCode: string;
}

export interface ISaleTaxSetting {
    companyCode: string;
    taxCodes: ITaxCodeData[];
}

export const initialSaleTaxSetting = {
    companyCode: '',
    taxCodes: [],
}