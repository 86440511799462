import { signoutRedirect } from "../../../oidcClient/userService";
import React, { FC } from "react";
import { Modal, Container, Button } from "react-bootstrap";
import { LogoutCause } from "../../../pages/common/auth/auth.reducer";
import { UserAutoLogoutPopupConstants } from "../../../helper/Constants";
import store from "../../../configureStore";
import { resetUserPrivilegeChangeState } from "../../../pages/common/auth/auth.actions";

export interface UserResourceChangePopupProps {
	show: boolean,
	logoutCause: LogoutCause
	setShowUserResourceChangeModal: (showUserResourceChangeModal: boolean) => void;
	resetUserCache: (callback?: () => void) => void;
}

const UserResourceChangePopup: FC<UserResourceChangePopupProps> = (props) => {

	const { show, setShowUserResourceChangeModal } = props;
	React.useEffect(() => {
		if (props.show && props.logoutCause != LogoutCause.None) {
			props.resetUserCache();
			const d = new Date();
			d.setTime(d.getTime() + (4 * 60 * 1000));
			const expires = "expires=" + d.toUTCString();
			document.cookie = "UserPermissionChanged=true;" + expires + ";path=/";
		}
		else { logOutUserOnReloadIfPermissionsChanged() }
	}, [props.show, props.logoutCause]);

	const logOutUserOnReloadIfPermissionsChanged = () => {
		if (CheckIfUserPermissionsChanged()) {
			onCancel();
		}
	}

	const CheckIfUserPermissionsChanged = (): boolean => {
		const name = "UserPermissionChanged=";
		const ca = document.cookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length) == 'true';
			}
		}
		return false;
	}

	const onCancel = (): void => {
		setShowUserResourceChangeModal(false);
		store.dispatch(resetUserPrivilegeChangeState());
		document.cookie = "UserPermissionChanged=false;"
		props.resetUserCache(() => {
			signoutRedirect();
		});
	}


	return (
		<Modal className="confirmationPopup" show={show}>
			<Modal.Header >
				<div><Modal.Title>{UserAutoLogoutPopupConstants.Header}</Modal.Title></div>
			</Modal.Header>
			<Modal.Body className="show-grid">
				<Container>
					{UserAutoLogoutPopupConstants.Message}
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={onCancel}>
					Login
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default UserResourceChangePopup;