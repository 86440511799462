import { useState } from 'react';

import { type ICompanyLogoHookReturn, type ICompanyLogoProps } from './company-logo.types';

const useCompanyLogo = (props: ICompanyLogoProps): ICompanyLogoHookReturn => {
	const {
		logoPath = '',
		isSsrLogo,
		retrivedLogoIsDefault,
		isLogoLoading,
		requestCompanyUploadLink,
		getCompanyLogo,
		logoUploadLink,
		updateTempCompanyLogo,
		deleteCompanyLogo,
		updateReminders
	} = props;

	const [showLogoDeletePopup, setShowLogoDeletePopup] = useState(false);
	const [showLogoEditPopup, setShowLogoEditPopup] = useState(false);

	return {
		logoPath,
		isSsrLogo,
		isLogoLoading,
		setShowLogoDeletePopup,
		setShowLogoEditPopup,
		showLogoDeletePopup,
		showLogoEditPopup,
		requestCompanyUploadLink,
		getCompanyLogo,
		logoUploadLink,
		updateTempCompanyLogo,
		deleteCompanyLogo,
		updateReminders,
		retrivedLogoIsDefault,
	};
};

export { useCompanyLogo };
