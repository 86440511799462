import { type Reducer } from 'redux';

import { actionTypes } from './branding-management.actions';

export const reducer: Reducer<any> = (state = {}, action): any => {
	switch (action.type) {
		case actionTypes.REQUEST_COMPANY_LOGO:
			return {
				...state,
				isLogoLoading: true,
			};

		case actionTypes.RECEIVE_COMPANY_LOGO_BRANDING:
			return {
				...state,
				companyLogoData: { ...state.companyLogoData, ...action.actionData.data },
				isLogoLoading: false,
			};

		case actionTypes.UPDATE_COMPANY_LOGO:
			return {
				...state,
				logoUploadLink: action.data,
			};
		case actionTypes.REQUEST_COMPANY_WHITE_LOGO:
			return {
				...state,
				isWhiteLogoLoading: true,
			};

		case actionTypes.RECEIVE_COMPANY_WHITE_LOGO:
			return {
				...state,
				whiteLogoPath: action.actionData.whiteLogoPath,
				isWhiteLogoLoading: false,
			};
		case actionTypes.DELETE_COMPANY_WHITE_LOGO:
			return {
				...state,
				whiteLogoPath: action.actionData.whiteLogoPath,
				isWhiteLogoLoading: false,
			};
		case actionTypes.UPDATE_COMPANY_WHITE_LOGO:
			return {
				...state,
				whiteLogoUploadLink: action.data,
			};
		case actionTypes.REQUEST_BRANDING_SETTINGS:
			return {
				...state,
				isBrandingSettingsLoading: true,
			};

		case actionTypes.RECEIVE_BRANDING_SETTINGS:
			return {
				...state,
				coverPageSetting: action.actionData.coverPageSetting,
				isBrandingSettingsLoading: false,
			};

		case actionTypes.RECEIVE_PRIMARY_OFFICE_LOCATION:
		return {
			...state,
			primaryOfficeLocation: action.actionData.primaryOfficeLocation,
		};

		case actionTypes.UPDATE_BRANDING_SETTINGS:
			return {
				...state,
				coverPageSetting: action.actionData.coverPageSetting,
				isBrandingSettingsLoading: false,
			};

		case actionTypes.RESET_CUSTOM_COMPANY_LOGO_UPLOAD_LINK:
			return {
				...state,
				logoUploadLink: { sas: '' },
			};

		default:
			return state;
	}
};
