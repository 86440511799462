import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';

import { AppNotifier } from '../../../helper/AppNotifier';
import { type ApplicationState } from '../../../store';
import { StatusType } from './notification.reducer';

export const Notification: React.FunctionComponent = () => {
	const [currentPath, setCurrentPath] = useState('');
	const notification = useSelector((state: ApplicationState) => state.notification);
	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		if (currentPath !== location.pathname) {
			toast.dismiss();
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusMessage: '',
				statusType: StatusType.None,
			});
			setCurrentPath(location.pathname);
			return;
		}

		if (notification.type === StatusType.Success) AppNotifier.Success(notification.message);
		else if (notification.type === StatusType.Error) AppNotifier.Error(notification.message);
		else if (notification.type === StatusType.Warning) AppNotifier.Warning(notification.message);
	}, [notification.identifier, location]);

	return <div />;
};
