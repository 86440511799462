/* eslint-disable max-lines */
import axios, { type AxiosResponse, AxiosError } from 'axios';

import { API_BASE_URL } from '../../helper/Constants';
import { brandingSecResources } from '../../helper/rbac-constants';
import { type AppThunkAction } from '../../store';
import { StatusType } from '../common/notification/notification.reducer';
import { actionTypes as notificationActionTypes } from '../common/notification/notification.types';
import {
	type IBrandingSettingsResponse,
	type ICompanyLogoResponse,
	type ICompanyWhiteLogoResponse,
	type KnownAction,
} from './branding.types';
import { actionTypes } from './branding-management.actions';
import { IOfficeLocation } from '@modules/clientManagement/Client.model';
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {
	getCompanyLogo: (): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({ type: actionTypes.REQUEST_COMPANY_LOGO, isLogoLoading: true });
		axios
			.get(`${API_BASE_URL}/api/logo/company-logo`)
			.then((response: any) => {
				const { data } = response;
				const isSsrLogo = data.isSsrlogo === 'True';
				const actionData = {
					data: {
						isSsrLogo,
						logoPath: data.logoPath,
						retrivedLogoIsDefault: isSsrLogo,
					},
					isLogoLoading: false,
				};
				dispatch({ type: actionTypes.RECEIVE_COMPANY_LOGO_BRANDING, actionData });
			})
			.catch(function (error: AxiosError) {
				logger && logger.trackWarning('Error while getCompanyLogo', { 'Error': error?.response?.data});
                if(error.response && error.response.status!=419 && error.response.status!=512)
                {
					const statusMessage: string = error.statusText?.message ?? error.statusText;
					if (typeof statusMessage === 'string')
						dispatch({
							type: notificationActionTypes.NOTIFICATION,
							statusMessage,
							statusType: StatusType.Error,
						});
				}
			});
	},

	getCompanyWhiteLogo: (): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({ type: actionTypes.REQUEST_COMPANY_WHITE_LOGO, isWhiteLogoLoading: true });
		axios
			.get(`${API_BASE_URL}/api/logo/company-whitelogo`)
			.then((response: AxiosResponse<ICompanyWhiteLogoResponse>) => response.data)
			.then((data) => {
				const actionData = {
					whiteLogoPath: data,
					isWhiteLogoLoading: false,
				};
				dispatch({ type: actionTypes.RECEIVE_COMPANY_WHITE_LOGO, actionData });
			})
			.catch((error) => {
				logger && logger.trackWarning('Error while getCompanyWhiteLogo', { 'Error': error?.response?.data});
				const statusMessage: string = error.statusText?.message ?? error.statusText;
				if (typeof statusMessage === 'string')
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusMessage,
						statusType: StatusType.Error,
					});
			});
	},

	getCompanyBrandSettings: (): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({ type: actionTypes.REQUEST_BRANDING_SETTINGS, isBrandingSettingsLoading: true });
		axios
			.get(`${API_BASE_URL}/api/brand-setting`,
			{
				headers: {
					'X-Resource-Id': `${brandingSecResources.prefix}_${brandingSecResources.headerDesign}_${brandingSecResources.SaveButton}`,
				},
			})
			.then((response: AxiosResponse<IBrandingSettingsResponse>) => response.data)
			.then((data) => {
				const actionData = {
					coverPageSetting: data.coverPageSetting,
					isBrandingSettingsLoading: false,
				};
				dispatch({ type: actionTypes.RECEIVE_BRANDING_SETTINGS, actionData });
			})
			.catch((error) => {
				logger && logger.trackWarning('Error while getCompanyBrandSettings', { 'Error': error?.response?.data});
				const statusMessage: string = error.statusText?.message ?? error.statusText;
				if (typeof statusMessage === 'string')
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusMessage,
						statusType: StatusType.Error,
					});
			});
	},

	getPrimaryOfficeLocation: (): AppThunkAction<KnownAction> => (dispatch) => {
		axios
			.get(`${API_BASE_URL}/api/office-location/get-primary-office-location`)
			.then((response: AxiosResponse<IOfficeLocation>) => {
				const actionData = {
					primaryOfficeLocation: response.data,
				};
				dispatch({ type: actionTypes.RECEIVE_PRIMARY_OFFICE_LOCATION, actionData });
			})
			.catch((error) => {
				const statusMessage: string = error.statusText?.message ?? error.statusText;
				if (typeof statusMessage === 'string')
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusMessage,
						statusType: StatusType.Error,
					});
			});
	},

	updateCompanyBrandSettings:
		(updatedFontColor: string, updatedBackgroundColor: string): AppThunkAction<KnownAction> =>
		(dispatch) => {
			const apiObject = {
				coverPageSetting: {
					bgColorCode: updatedBackgroundColor,
					foreColorCode: updatedFontColor,
				},
			};
			dispatch({ type: actionTypes.REQUEST_BRANDING_SETTINGS, isBrandingSettingsLoading: true });
			axios
				.put(`${API_BASE_URL}/api/brand-setting`, apiObject, {
					headers: {
						'X-Resource-Id': `${brandingSecResources.prefix}_${brandingSecResources.headerDesign}_${brandingSecResources.SaveButton}`,
					},
				})
				.then((response: AxiosResponse<IBrandingSettingsResponse>) => {
					if (response) {
						const actionData = {
							coverPageSetting: apiObject.coverPageSetting,
							isBrandingSettingsLoading: false,
						};
						dispatch({ type: actionTypes.UPDATE_BRANDING_SETTINGS, actionData });
					}
				})
				.catch((error) => {
					logger && logger.trackWarning('Error while updateCompanyBrandSettings', { 'Error': error?.response?.data});
					const statusMessage: string = error.statusText?.message ?? error.statusText;
					if (typeof statusMessage === 'string')
						dispatch({
							type: notificationActionTypes.NOTIFICATION,
							statusMessage,
							statusType: StatusType.Error,
						});
				});
		},

	updateReminders: (): AppThunkAction<any> => (dispatch) => {
		axios
			.put(`${API_BASE_URL}/api/reminder`, {
				headers: {
					'X-Resource-Id': `${brandingSecResources.prefix}_${brandingSecResources.headerDesign}_${brandingSecResources.SaveButton}`,
				},
			})
			.catch((error) => {
				logger && logger.trackWarning('Error while updateReminders', { 'Error': error?.response?.data});
				const statusMessage: string = error.statusText?.message ?? error.statusText;
				if (typeof statusMessage === 'string')
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusMessage,
						statusType: StatusType.Error,
					});
			});
	},

	deleteCompanyLogo: (callback: () => void): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({ type: actionTypes.REQUEST_COMPANY_LOGO, isLogoLoading: true });
		axios
			.delete(`${API_BASE_URL}/api/logo/company-logo`, {
				headers: {
					'X-Resource-Id': `${brandingSecResources.prefix}_${brandingSecResources.companyLogo}_${brandingSecResources.DeleteButton}`,
				},
			}).then((response) => {
				callback();
			})
			.catch((error) => {
				logger && logger.trackWarning('Error while deleteCompanyLogo', { 'Error': error?.response?.data});
				const statusMessage: string = error.statusText?.message ?? error.statusText;
				if (typeof statusMessage === 'string')
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusMessage,
						statusType: StatusType.Error,
					});
			});
	},
	deleteCompanyWhiteLogo: (): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({ type: actionTypes.REQUEST_COMPANY_WHITE_LOGO, isWhiteLogoLoading: true });
		axios
			.delete(`${API_BASE_URL}/api/logo/company-whitelogo`, {
				headers: {
					'X-Resource-Id': `${brandingSecResources.prefix}_${brandingSecResources.companyWhiteLogo}_${brandingSecResources.DeleteButton}`,
				},
			})
			.then((response: AxiosResponse<ICompanyWhiteLogoResponse>) => {
				const actionData = {
					whiteLogoPath: '',
					isWhiteLogoLoading: false,
				};
				dispatch({ type: actionTypes.DELETE_COMPANY_WHITE_LOGO, actionData });
			})
			.catch((error) => {
				logger && logger.trackWarning('Error while deleteCompanyWhiteLogo', { 'Error': error?.response?.data});
				const statusMessage: string = error.statusText?.message ?? error.statusText;
				if (typeof statusMessage === 'string')
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusMessage,
						statusType: StatusType.Error,
					});
			});
	},

	requestCompanyUploadLink: (): AppThunkAction<KnownAction> => (dispatch) => {
		axios
			.get(`${API_BASE_URL}/api/logo/company-logo-upload-link`, {
				headers: {
					'X-Resource-Id': `${brandingSecResources.prefix}_${brandingSecResources.companyLogo}_${brandingSecResources.EditButton}`,
				},
			})
			.then((response: any) => response.data)
			.then((data) => {
				dispatch({ type: actionTypes.UPDATE_COMPANY_LOGO, data });
			})
			.catch((error) => {
				logger && logger.trackWarning('Error while requestCompanyUploadLink', { 'Error': error?.response?.data});
				const statusMessage: string = error.statusText?.message ?? error.statusText;
				if (typeof statusMessage === 'string')
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusMessage,
						statusType: StatusType.Error,
					});
			});
	},

	requestCompanyWhiteLogoUploadLink: (): AppThunkAction<KnownAction> => (dispatch) => {
		axios
			.get(`${API_BASE_URL}/api/logo/company-whitelogo-upload-link`, {
				headers: {
					'X-Resource-Id': `${brandingSecResources.prefix}_${brandingSecResources.companyWhiteLogo}_${brandingSecResources.EditButton}`,
				},
			})
			.then((response: any) => response.data)
			.then((data) => {
				dispatch({ type: actionTypes.UPDATE_COMPANY_WHITE_LOGO, data });
			})
			.catch((error) => {
				logger && logger.trackWarning('Error while requestCompanyWhiteLogoUploadLink', { 'Error': error?.response?.data});
				const statusMessage: string = error.statusText?.message ?? error.statusText;
				if (typeof statusMessage === 'string')
					dispatch({
						type: notificationActionTypes.NOTIFICATION,
						statusMessage,
						statusType: StatusType.Error,
					});
			});
	},

	updateTempCompanyWhiteLogo:
		(link: string): AppThunkAction<KnownAction> =>
		(dispatch) => {
			const actionData = {
				whiteLogoPath: link,
				isWhiteLogoLoading: false,
			};
			dispatch({ type: actionTypes.RECEIVE_COMPANY_WHITE_LOGO, actionData });
		},
	updateTempCompanyLogo:
		(link: string): AppThunkAction<KnownAction> =>
		(dispatch) => {
			const actionData = {
				data: {
					isSsrLogo: false,
					logoPath: link,
				},
				isLogoLoading: false,
			};
			dispatch({ type: actionTypes.RECEIVE_COMPANY_LOGO_BRANDING, actionData });
		},
};
