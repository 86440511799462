import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './TaxSofwareSetting.module.scss';
import { ITaxSoftwareSetting, TaxSoftwareType, initialState } from './TaxSoftwareSetting.model';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { TaxSoftwareSettingsConstants } from 'src/helper/Constants';
import { ApplicationState } from '../../../store';
import ButtonWithTextIcon from 'src/components/common/button-with-text-icon';
import { logger } from 'src/oidcClient/authProvider';

interface TaxSoftwareProps {
	requestTaxSoftwareSetting: () => void;
	updateTaxSoftwareSetting: (setting: ITaxSoftwareSetting, resourceId: string, callback: () => void) => void;
	bindCCH: (isSelected: boolean) => void;
}

export const TaxSoftwareTypes: FC<TaxSoftwareProps> = (props) => {
	const taxSoftwares = useSelector((state: ApplicationState) => state.taxSoftwareSetting);
	const [taxSoftwareSetting, setTaxSoftware] = useState<ITaxSoftwareSetting>(initialState);
	const dispatch = useDispatch();
	const [isApplyBtnDisabled, setApplyBtnState] = useState<boolean>(true);

	useEffect(() => {
		props.requestTaxSoftwareSetting();
	}, []);

	useEffect(() => {
		setTaxSoftware(taxSoftwares);
		const { taxSoftware } = taxSoftwares;
		taxSoftware.findIndex((x) => x == TaxSoftwareType.ProSystems) > -1 ? props.bindCCH(true) : props.bindCCH(false);
	}, [taxSoftwares]);

	const isTaxSoftwareSelected = (taxSoftwareType: TaxSoftwareType): boolean => {
		const { taxSoftware } = taxSoftwareSetting;
		return taxSoftware.findIndex((x) => x == taxSoftwareType) > -1;
	};

	const handleTaxSoftwareChange = (event: any, taxSoftware: TaxSoftwareType) => {
        logger && logger.trackTrace('handleTaxSoftwareChange: Changing Tax Software settings in general settings');
		let temp = structuredClone(taxSoftwareSetting);
		if (event.target.checked) {
			temp.taxSoftware.push(taxSoftware);
		} else {
			const index = temp.taxSoftware.findIndex((x: any) => x == taxSoftware);
			if (index > -1) {
				temp.taxSoftware.splice(index, 1);
			}
		}

		const containsAll =
			taxSoftwares.taxSoftware.length !== temp.taxSoftware.length
				? false
				: taxSoftwares.taxSoftware.every((x) => temp.taxSoftware.includes(x));

		if (!containsAll) {
			setApplyBtnState(false);
		} else {
			setApplyBtnState(true);
		}
		if (taxSoftware == TaxSoftwareType.ProSystems) {
			props.bindCCH(event.target.checked)
        }

		setTaxSoftware(temp);
	};

	const handleApplyButton = (resourceId: string) => {
		if (taxSoftwareSetting.taxSoftware.length == 0) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: TaxSoftwareSettingsConstants.NoTaxSoftwareMessage,
			});
		} else {
			setApplyBtnState(true);
			props.updateTaxSoftwareSetting(taxSoftwareSetting, resourceId, () => {
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusType: StatusType.Success,
					statusMessage: TaxSoftwareSettingsConstants.UpdateTaxSofwareSuccess,
				});
			});
		}
	};

	return (
		<article className='suitePageSection'>
			<h3 className='suitePageSectionHeader'>Tax Software</h3>
			<p className='suitePageSectionDescription'>Enable tax software you will upload from</p>
			<h3 className='suitePageSectionSubHeader'>Available Software</h3>
			<div className={styles.taxSoftwares}>
				<div>
					<input
						type='checkbox'
						id='CCH'
						checked={isTaxSoftwareSelected(TaxSoftwareType.ProSystems)}
						onChange={(event) => {
							handleTaxSoftwareChange(event, TaxSoftwareType.ProSystems);
						}}
						data-test-auto='e748c94b-3aeb-4669-9ab1-f041b4ae37e4'
					/>
					<label
						htmlFor='CCH'
						className={isTaxSoftwareSelected(TaxSoftwareType.ProSystems) ? 'checked' : ''}>
						CCH
					</label>
				</div>
				<div>
					<input
						type='checkbox'
						id='GoSystem'
						checked={isTaxSoftwareSelected(TaxSoftwareType.GoSystem)}
						onChange={(event) => {
							handleTaxSoftwareChange(event, TaxSoftwareType.GoSystem);
						}}
						data-test-auto='d8836275-871d-471e-8da6-de3b3caab615'
					/>
					<label
						htmlFor='GoSystem'
						className={isTaxSoftwareSelected(TaxSoftwareType.GoSystem) ? 'checked' : ''}>
						GoSystem
					</label>
				</div>
				<div>
					<input
						type='checkbox'
						id='Lacerte'
						checked={isTaxSoftwareSelected(TaxSoftwareType.Lacerte)}
						onChange={(event) => {
							handleTaxSoftwareChange(event, TaxSoftwareType.Lacerte);
						}}
						data-test-auto='e08654f6-b5d8-43ca-bd09-5a0de7b9827a'
					/>
					<label
						htmlFor='Lacerte'
						className={isTaxSoftwareSelected(TaxSoftwareType.Lacerte) ? 'checked' : ''}>
						Lacerte
					</label>
				</div>
				<div>
					<input
						type='checkbox'
						id='UltraTax'
						checked={isTaxSoftwareSelected(TaxSoftwareType.UltraTax)}
						onChange={(event) => {
							handleTaxSoftwareChange(event, TaxSoftwareType.UltraTax);
						}}
						data-test-auto='1c1f8de4-655c-4b06-8988-c984ffb9fa0e'
					/>
					<label
						htmlFor='UltraTax'
						className={isTaxSoftwareSelected(TaxSoftwareType.UltraTax) ? 'checked' : ''}>
						UltraTax
					</label>
				</div>
			</div>
			<div className={styles.action_button_wrapper}>
				<ButtonWithTextIcon
					label='Apply Changes'
					className={styles.action_button}
					disabled={isApplyBtnDisabled}
					disabledText='Please make changes to enable button'
					onClick={() => {
						handleApplyButton('SUITE_Gnrl_TxSftwre_AplyBtn');
					}}
					data-resource-id={'SUITE_Gnrl_TxSftwre_AplyBtn'}
				/>
			</div>
		</article>
	);
};
