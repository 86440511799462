import { API_BASE_URL } from "src/helper/Constants";
import { ISaveUserModel } from "../Users.model";
import axios, { AxiosResponse } from "axios";
import { IAddUserReturn, IEditUserResponse, IEditUserReturn } from "./SaveUserModal.model";
import { userTabConstants } from "../../../../helper/rbac-constants";
import { logger } from 'src/oidcClient/authProvider';

export function addNewUser(newUser: ISaveUserModel, callback: (responseData: IAddUserReturn) => void) {
    const responseData = { userId: 0, errorMessage: "" };
    axios.post(API_BASE_URL + "/api/user-management", newUser, {
        headers: {
            'X-Resource-Id': `${userTabConstants.prefix}_${userTabConstants.addUser}`
        }
    })
        .then((response: AxiosResponse) => {
            const { data } = response;
            responseData.userId = data;
            callback(responseData);
        })
        .catch(error => {
            logger && logger.trackWarning('Error while addNewUser', { 'Error': error?.response?.data});
            responseData.errorMessage = error.response.data;
            callback(responseData);
        });
}

export function updateUserInfo(user: ISaveUserModel, callback: (responseData: IEditUserReturn, successResponse?: IEditUserResponse) => void) {
    const responseData = { errorStatus: 0, errorMessage: "" };
    axios.put(API_BASE_URL + "/api/user-management/user-details-with-group", user, {
        headers: {
            'X-Resource-Id': `${userTabConstants.prefix}_${userTabConstants.grid}_${userTabConstants.editButton}`
        }
    })
        .then((response: AxiosResponse<IEditUserResponse>) => {
            const { data } = response;
            if (data) {
                callback(responseData, data);
            } else {
                responseData.errorStatus = 1;
                responseData.errorMessage = "Error. API returned false.";
                callback(responseData);
            }
        }).catch((error) => {
            logger && logger.trackWarning('Error while updateUserInfo', { 'Error': error?.response?.data});
            responseData.errorStatus = 1;
            responseData.errorMessage = error.response.data;
            callback(responseData);
        });
}