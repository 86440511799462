import React from 'react';

const TickIcon: React.FC = () => {
	return (
		<svg
			width='13'
			height='10'
			viewBox='0 0 13 10'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.2679 -3.94623e-05L4.50854 6.79192L1.58926 3.86723L0 5.46561L4.50854 10L12.8571 1.60967L11.2679 -3.94623e-05Z'
				fill='white'
			/>
		</svg>
	);
};

export default TickIcon;
