import { EventSettingsConstants } from '../../../helper/Constants';
import  React, {useState} from 'react';
import CustomToggleSwitch   from "../../../components/common/ui/switch/customToggleSwitch";
import { IEventSettings, IClientAPIDetail, EventServiceStatus,EventType } from "../developer-section.model";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import  styles from './index.module.scss';
import Divider from '../../../components/svg/Divider';
import { ProductType } from '../../../helper/Constants';
import { developersectionResources } from '../../../helper/rbac-constants'
import { ReactComponent as Download } from '../../../components/svg/file-download.svg';

interface EventsTableProps {
    events: IEventSettings[];
    eventApi: IClientAPIDetail[];
    productType : ProductType;
    onApiSettingsActionClick(clientApiId: number, resourceId?: string): void;
    onEventEdit(eventType: EventType, productType : ProductType, resourceId?: string): void;    
    onDownloadLog(eventType: EventType, productType : ProductType, resourceId?: string): void;    
    onResumeService(eventType: EventType,productType : ProductType): void;
    onChangeEventStatus(eventType: EventType, productType : ProductType, eventId: number, enabled: boolean, resourceId?: string): void;
    parentResourceIdentifier: string;
    onDownloadDocument(productType : ProductType, onDownloadComplete: () => void): void;
}

export const EventsTable : React.FC<EventsTableProps> = props => {

    const [downloadingState,setDownloadingState] = useState(false);

    let handleEventStatusToggle = (event: any, eventType: EventType, productType :ProductType, eventSettingsId: number, resourceId?: string) => {
        props.onChangeEventStatus(eventType,productType, eventSettingsId, event.target.checked, resourceId);
    }

    let createEvents = (cell: any, row: any) => {        
        return (<div  >            
            <span title={ row.name }>{ row.name }</span>
        </div>);
    }

    let createEmailsItems = (emails: string) => {        
        let emailElements: any = [];

        JSON.parse(emails).map((email : any, index : any) => (
            emailElements.push(<><b>[</b><span title={email} key={index}>{email}</span><b>]</b></>)
        ));

        return emailElements;
    }

    let createEmails = (cell: any, row: any) => {
        return (<>{createEmailsItems(row.failureMail)}</>);
    }

    let createEventStatus = (cell: any, row: any) => {
        return (<div>
                <CustomToggleSwitch
                    switched={row.eventEnabled}                    
                    handleChange={(event :any) => { handleEventStatusToggle(event, row.eventType, row.productType, row.id, props.parentResourceIdentifier + developersectionResources.Events + developersectionResources.ToggleEnableOrDisable) }}
                />
        </div>);
    }

    let createActions = (cell: any, row: any) => {
        return (<div className={styles.align_action_buttons}>
            <div>
            <button
                data-test-auto={`3cfb242a-38b9-4835-ba3c-507d9e69c164${row.eventType}`}
                title={EventSettingsConstants.ConfigureEndPoint} type="button"
                onClick={() => { props.onApiSettingsActionClick(row.id, props.parentResourceIdentifier + developersectionResources.APISettings + developersectionResources.ButtonEdit) }}
                data-resource-id = { props.parentResourceIdentifier + developersectionResources.APISettings + developersectionResources.ButtonEdit }
                className="p-0">
              {EventSettingsConstants.ConfigureEndPoint}
            </button>
            </div>
            <div className={styles.divider}>
                <Divider />
            </div>
            <div>
            <button 
                data-test-auto={`3cfb242a-38b9-4835-ba3c-507d9e69c163${row.eventType}`}
                title={EventSettingsConstants.ConfigureEmail} type="button"
                onClick={() => { props.onEventEdit(row.eventType, row.productType, props.parentResourceIdentifier + developersectionResources.Events + developersectionResources.ButtonEdit ) }}
                data-resource-id = { props.parentResourceIdentifier + developersectionResources.Events + developersectionResources.ButtonEdit }
                className="p-0">
            {EventSettingsConstants.ConfigureEmail}
            </button>        
            </div>
            <div className={styles.divider}>
                <Divider />
            </div>
            <div>
            <button 
                data-test-auto={`163ACB7C-B397-4D1C-AFB3-9AA739DDB328${row.eventType}`}
                title={EventSettingsConstants.Log} type="button"
                onClick={() => { props.onDownloadLog(row.eventType, row.productType, props.parentResourceIdentifier + developersectionResources.Events + developersectionResources.ButtonEdit ) }}
                data-resource-id = { props.parentResourceIdentifier + developersectionResources.Events + developersectionResources.ButtonEdit }
                className="p-0">
            {EventSettingsConstants.Log}
            </button>        
            </div>
      
        </div>);
    }


    let createEventsServiceStatus = (cell: any, row: any) => {

        if (!row.eventEnabled) {
            return (<button 
                title={EventSettingsConstants.Stopped}
                className={styles.btn_status + ' ' + styles.btn_stopped} 
                data-test-auto="DA5737F2-BF6F-4430-BDCB-D9E9AB9E4D12">
            {EventSettingsConstants.Stopped}
            </button>);
        }
        switch (row.serviceStatus) {
            case EventServiceStatus.Running:              
            return (<button 
                title={EventSettingsConstants.ServiceRunning}
                className={styles.btn_status + ' ' + styles.btn_active}              
                data-test-auto="DA5737F2-BF6F-4430-BDCB-D9E9AB9E4D13">
            {EventSettingsConstants.Active}
            </button>);

            case EventServiceStatus.Stopped:
                return (<button 
                    title={EventSettingsConstants.ResumeService}
                    className={styles.btn_status + ' ' + styles.btn_resume} 
                    onClick={() => { props.onResumeService(row.eventType,row.productType) }}
                    data-test-auto="DA5737F2-BF6F-4430-BDCB-D9E9AB9E4D12">
                   {EventSettingsConstants.Resume}
                </button>);
            default:
            case EventServiceStatus.Unknown:
                return (<button 
                    title={EventSettingsConstants.ResumeService}
                    className={styles.btn_status + ' ' + styles.btn_resume} 
                    onClick={() => { props.onResumeService(row.eventType,row.productType) }}
                    data-test-auto="DA5737F2-BF6F-4430-BDCB-D9E9AB9E4D14">                   
                    {EventSettingsConstants.Resume}
                </button>);
        }
    }

    let onDownloadComplete = () => {
        setDownloadingState(false);
    }

    let columns = [
        {
            text: EventSettingsConstants.EventLabel,
            dataField: 'eventType',                
            sort: false,
            hidden: false, 
            headerStyle : { width: "20%" },
            formatter: createEvents
        },
        {
            text:EventSettingsConstants.EmailLabel,
            dataField: 'failureMail',
            sort: false,
            hidden: false, 
            headerStyle : { width: "35%" },
            formatter: createEmails
        },
        {
            text: EventSettingsConstants.EnabledLabel,
            dataField: 'eventEnabled',
            sort: false,
            hidden: false,
            headerStyle : { width: "12%" },
            formatter: createEventStatus
        },      
        {
            text: EventSettingsConstants.ServiceStatus,
            dataField: 'serviceStatus',
            sort: false,
            hidden: false,
            headerStyle : { width: "13%" },
            formatter: createEventsServiceStatus
        },          
        {
            text: EventSettingsConstants.Actions,
            dataField: 'clientApiId',
            sort: false,
            hidden: false,
            headerStyle : { width: "20%" },
            formatter: createActions
        }
    ];  

    return(           
        
            <div className={styles.developer_section}>
              
              <div>
              <button
                    onClick={()=> props.onDownloadDocument(props.productType,onDownloadComplete)}
					data-test-auto='f33e066d-4fb5-4071-a7cf-a8815144daa2'
					className={styles.download_icon}
					title='Download API Documentation'>					
                    <Download></Download>
				</button>
              </div>
                    <BootstrapTable
                    classes="custom-table"
                    keyField="events"
                    columns={columns}
                    data={props.events}
                    remote
                    noDataIndication={"No data"}
                    bordered={false}
                    striped
                    />
          </div>
    );
}