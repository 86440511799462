export const dropdownIndicatorStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	paddingLeft: '1px',
	color: '#212529',
};

export const clearIndicatorStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	paddingRight: '1px',
	color: 'gray',
	'&:hover': {
		color: 'gray',
		cursor: 'pointer',
	},
	'& svg': {
		width: '14px',
	},
};

export const placeholderStyles = {
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	color: '#212529',
	marginBottom: '1px',
};
