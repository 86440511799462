import React from 'react';
import './InOfficeESigning.style.scss';
import InOfficeESigningFlow from '../../modules/inoffice-esigning';
import { IInOfficeReturnProps } from './InOfficeESigning.model';

const InOfficeESigning: React.FC<IInOfficeReturnProps> = (props) => {
	return (
		<div className='in-office-esigning'>
			<InOfficeESigningFlow {...props} />
		</div>
	);
};

export default InOfficeESigning;
