import { useRef, useState } from 'react';
import { Step } from '@sssuite-component-ui/multi-step-wizard/dist/Body/Step';
import { Wizard } from '@sssuite-component-ui/multi-step-wizard/dist/Body/Wizard';
import InOfficeSearchScreen from './inOfficeSearchScreen';
import InOfficeReturnScreen from './inOfficeReturnScreen';
import { IInOfficeReturnProps } from 'src/pages/inoffice-esigning/InOfficeESigning.model';
import { PageProps } from '../../modules/clientManagement/Client.model';
import { AppNotifier } from '../../helper/AppNotifier';
import { CommonMessages } from '../../helper/Constants';

export enum InOfficeScreen {
	SearchScreen,
	ReturnScreen,
}

const InOfficeESigningFlow: React.FC<IInOfficeReturnProps> = (props) => {
	const { inOfficeReturnList, requestInOfficeReturns, requestInOfficeReturnsAsEmpty, redirectToTaxpayerSigningFlow } =
		props;

	const wizardRef = useRef<any>(null);
	const [inputText, setInputText] = useState<string>('');
	const [searchText, setSearchText] = useState<string>('');
	const [page, setPage] = useState<PageProps>({ pageIndex: 1, pageSize: 10 });

	const handleInputTextChange = (text: string) => {
		setInputText(text);
	};

	const handleOnSearchClick = (text: string, screenId: InOfficeScreen) => {
		const newSearchText = text.trim();

		if (newSearchText !== '') {
			const newPage = { ...page };
			newPage.pageIndex = 1;
			setPage(newPage);
			requestInOfficeReturns(
				true,
				newSearchText,
				newPage.pageIndex,
				newPage.pageSize,
				screenId === InOfficeScreen.SearchScreen ? goToNextStep : checkForEmptyData,
			);
			setSearchText(newSearchText);
		}
	};

	const handleOnRefreshClick = () => {
		if (searchText.trim() !== '') {
			const newPage = { ...page };
			newPage.pageIndex = 1;
			setPage(newPage);
			requestInOfficeReturns(true, searchText, newPage.pageIndex, newPage.pageSize);
		}
	};

	const goToNextStep = (data: any[]) => {
		if (data?.length > 0) {
			wizardRef.current?.goToNextStep();
		} else {
			AppNotifier.Error(CommonMessages.NoResultsFound);
		}
	};

	const checkForEmptyData = (data: any[]) => {
		if (data?.length === 0) {
			AppNotifier.Error(CommonMessages.NoResultsFound);
		}
	};

	const handleClearSearchOnClick = () => {
		const newPage = { ...page };
		newPage.pageIndex = 1;
		setPage(newPage);
		requestInOfficeReturnsAsEmpty();
		setSearchText('');
	};

	const goToPage = (newPageIndex: number, newPageSize: number) => {
		const totalPages = Math.ceil(inOfficeReturnList.count / page.pageSize);
		if (
			((newPageIndex !== page.pageIndex && newPageIndex <= totalPages && newPageIndex >= 1) ||
				newPageSize !== page.pageSize) &&
			searchText.trim() !== ''
		) {
			const newPage = { ...page };
			newPage.pageIndex = newPageIndex;
			newPage.pageSize = newPageSize;
			setPage(newPage);
			requestInOfficeReturns(true, searchText, newPageIndex, newPageSize);
		}
	};

	return (
		<Wizard
			hideTopNavigation={true}
			hideFooter={true}
			ref={(ref: any) => (wizardRef.current = ref)}>
			<Step
				id={InOfficeScreen.SearchScreen.toString()}
				name={'SearchScreen'}
				tooltip={'SearchScreen'}
				hideScrollbar={true}>
				<InOfficeSearchScreen
					inputText={inputText}
					handleInputTextChange={handleInputTextChange}
					handleOnSearchClick={handleOnSearchClick}
				/>
			</Step>
			<Step
				id={InOfficeScreen.ReturnScreen.toString()}
				name={'ReturnScreen'}
				tooltip={'ReturnScreen'}
				hideScrollbar={true}>
				<InOfficeReturnScreen
					redirectToTaxpayerSigningFlow={redirectToTaxpayerSigningFlow}
					handleClearSearchOnClick={handleClearSearchOnClick}
					handleInputTextChange={handleInputTextChange}
					handleOnRefreshClick={handleOnRefreshClick}
					inOfficeReturnList={inOfficeReturnList}
					handleOnSearchClick={handleOnSearchClick}
					inputText={inputText}
					page={page}
					goToPage={goToPage}
				/>
			</Step>
		</Wizard>
	);
};

export default InOfficeESigningFlow;
