import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/Constants';
import { logger } from 'src/oidcClient/authProvider';

export const downloadMailMergeExe = (resourceId: string = '', callback: (success: boolean) => void) => {
    axios.get(API_BASE_URL + '/api/product-download/mail-merge', {
        headers: {
            'X-Resource-Id': resourceId
        }
    })
        .then((response: AxiosResponse) => {
            location.href = response.data;
            callback(true);
        }).catch((error) => {
            logger && logger.trackWarning('Error while downloadMailMergeExe', { 'Error': error?.response?.data});
            callback(false);
        });
};

export const downloadWebAddInManifest = (resourceId: string = '', callback: (success: boolean) => void) => {
    axios.get(API_BASE_URL + '/api/product-download/safesend-exchange-xml', {
        headers: {
            'X-Resource-Id': resourceId
        }
    })
        .then((response: AxiosResponse) => {
            location.href = response.data;
            callback(true);
        }).catch((error) => {
            logger && logger.trackWarning('Error while downloadWebAddInManifest', { 'Error': error?.response?.data});
            callback(false);
        });
};

export const downloadTicTieCalculate64bitMsi = (resourceId: string = '', callback: (success: boolean) => void) => {
    axios.get(API_BASE_URL + '/api/product-download/tictie-calculate-64bit-msi', {
        headers: {
            'X-Resource-Id': resourceId
        }
    })
        .then((response: AxiosResponse) => {
            location.href = response.data;
            callback(true);
        }).catch((error) => {
            logger && logger.trackWarning('Error while downloadWebAddInManifest', { 'Error': error?.response?.data});
            callback(false);
        });
};

export const downloadTicTieCalculate32bitMsi = (resourceId: string = '', callback: (success: boolean) => void) => {
    axios.get(API_BASE_URL + '/api/product-download/tictie-calculate-32bit-msi', {
        headers: {
            'X-Resource-Id': resourceId
        }
    })
        .then((response: AxiosResponse) => {
            location.href = response.data;
            callback(true);
        }).catch((error) => {
            logger && logger.trackWarning('Error while downloadWebAddInManifest', { 'Error': error?.response?.data});
            callback(false);
        });
};
