import React, { useEffect } from 'react';
import Select, { GroupBase } from 'react-select';
import { ValueComponent, OptionComponent, ClearIndicator, DropdownIndicator } from './helper';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { ICustomDropdownOption } from '../customDropdown/CustomDropdownWithCustomOptions';

interface IDropdownProps {
	selectedOptions: any;
	options: ICustomDropdownOption[];
	setSelectedOption: (option: any) => void;
	clearable?: boolean;
	disabled?: boolean;
	searchable?: boolean;
	customPlaceHolder?: string;
	id: string;
	className?: string;
	height?: number;
	title?: string;
	borderColor?: string;
	handleOnBlur: () => void;
}

const CustomMultiDropdown: React.FC<IDropdownProps> = (props) => {
	const {
		options,
		clearable,
		disabled,
		customPlaceHolder,
		id,
		className,
		height,
		searchable,
		title,
		selectedOptions,
		setSelectedOption,
		borderColor,
		handleOnBlur,
	} = props;

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			background: disabled ? '#C4C6C8' : '#fff',
			borderColor: borderColor ?? '#898D91',
			minHeight: `${height ?? 32}px`,
			height: `${height ?? 32}px`,
			borderRadius: '2px',
			boxShadow: state.isFocused ? null : null,
			'&:hover': {
				borderColor: borderColor ?? '#898D91',
			},
		}),
		valueContainer: (provided: any, state: any) => ({
			...provided,
			height: `${height ?? 30}px`,
			padding: '0 2px 0 8px',
			fontSize: '14px',
			marginBottom: '1px',
			width: '160px',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		}),
		singleValue: (provided: any, state: any) => ({
			...provided,
			marginBottom: '1px',
		}),
		input: (provided: any, state: any) => ({
			...provided,
			margin: '0px',
			padding: '0px',
		}),
		placeholder: (provided: any, state: any) => ({
			...provided,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			color: '#212529',
			marginBottom: '1px',
		}),
		indicatorSeparator: (state: any) => ({
			display: 'none',
		}),
		indicatorsContainer: (provided: any, state: any) => ({
			...provided,
			height: `${height ? height - 2 : 30}px`,
			alignSelf: 'center',
		}),
		dropdownIndicator: (provided: any, state: any) => ({
			...provided,
			width: `${height ? height - 2 : 30}px`,
			height: `${height ? height - 2 : 30}px`,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			paddingLeft: '1px',
			color: '#212529',
		}),
		clearIndicator: (provided: any) => ({
			...provided,
			width: `${height ? height - 7 : 30}px`,
			height: `${height ? height - 7 : 30}px`,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			paddingRight: '1px',
			color: 'gray',
			'&:hover': {
				color: 'gray',
				cursor: 'pointer',
			},
		}),
		option: (provided: any, state: any) => ({
			...provided,
			cursor: 'pointer',
			backgroundColor: state.isSelected ? '#fff' : 'transparent',
			color: state.isSelected ? 'inherit' : 'inherit',
			padding: '4px 12px',
		}),
		menuList: (provided: any) => ({
			...provided,
			maxHeight: '95px',
		}),
		menu: (provided: any) => ({
			...provided,
			fontSize: '14px',
			width: '100%',
			border: '1px solid #898D91',
			marginTop: '-1px',
			marginBottom: '-1px',
			borderRadius: '0 0 2px 2px',
			outline: 'none',
			boxShadow: 'none',
		}),
	};

	useEffect(() => {
		const inputElement = document.querySelector('#clickableInput') as HTMLInputElement;

		if (inputElement) {
			inputElement.style.height = '0';
		}
	});

	const customComponents: Partial<SelectComponents<any, boolean, GroupBase<any>>> = {
		IndicatorSeparator: null,
		Option: OptionComponent,
		ValueContainer: ValueComponent,
		DropdownIndicator: DropdownIndicator,
		ClearIndicator: ClearIndicator,
	};

	return (
		<div
			className={`${disabled ? 'cursor-nodrop' : ''}`}
			title={title}>
			<Select
				id={id}
				inputId='clickableInput'
				onChange={setSelectedOption}
				value={selectedOptions}
				options={options}
				isClearable={clearable ?? false}
				isDisabled={disabled ?? false}
				isSearchable={searchable ?? false}
				className={className + `${title ? ' custom-react-select-dropdown' : ''}`}
				placeholder={customPlaceHolder?.length ? customPlaceHolder : 'Select...'}
				styles={customStyles}
				components={customComponents}
				isMulti
				hideSelectedOptions={false}
				onBlur={handleOnBlur}
				closeMenuOnSelect={false}
			/>
		</div>
	);
};

export default CustomMultiDropdown;
