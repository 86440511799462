import React from "react";

export const ExportIcon = (style?: React.CSSProperties) => {
    return  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={style ? style : {}}>
        <g clipPath="url(#clip0_3269_129942)">
            <path fill="#fff" fillOpacity="0.01" d="M0 0H16V16H0z"></path>
            <path
            fill="currentColor"
                fillRule="evenodd"
                d="M11.354 8.853l-3 3a.5.5 0 01-.708 0l-3-3a.5.5 0 11.708-.708L7.5 10.292V1.499a.5.5 0 011 0v8.793l2.146-2.147a.5.5 0 01.708.708zM3 6.499a.5.5 0 01.5-.5h2a.5.5 0 100-1h-2a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 001.5 1.5h9a1.5 1.5 0 001.5-1.5v-8a1.5 1.5 0 00-1.5-1.5h-2a.5.5 0 000 1h2a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-8z"
                clipRule="evenodd"
            ></path>
        </g>
        <defs>
            <clipPath id="clip0_3269_129942">
                <path fill="#fff" d="M0 0H16V16H0z"></path>
            </clipPath>
        </defs>
    </svg>
};

export default ExportIcon;
