import React, { FC } from "react";
import "./UserManagementTabs.style.scss";

interface Props {
    tabs: { [key: string]: boolean };
    showTab: (id: string) => void;
}
const UserManagementTabs: FC<Props> = (props) => {
    const handleTabClick = (e: any) => {
        props.showTab(e.target.id);
    };
    return (
        <ul id="tabHeader" className="tabHeader">
            <li id="usersTab" data-test-auto="1de9ab26-7934-49f7-a612-66514885afa6"
                className={`usersTab${props.tabs.usersTab ? " tabSelected" : ""}`} onClick={handleTabClick}
            >Users</li>
            <li id="userGroupsTab" data-test-auto="189f6422-5688-4c6d-8395-66b4f9dc56ac"
                className={`userGroupsTab${props.tabs.userGroupsTab ? " tabSelected" : ""}`} onClick={handleTabClick}
            >User&nbsp;Groups</li>
            <li id="rolesAndPermissionsTab" data-test-auto="f194a782-a17e-4e5e-923d-69601112c2ad"
                className={`rolesAndPermissionsTab${props.tabs.rolesAndPermissionsTab ? " tabSelected" : ""}`} onClick={handleTabClick}
            >Roles&nbsp;&&nbsp;Permissions</li>
        </ul>
    );
};

export default UserManagementTabs;