/* eslint-disable max-lines-per-function */
import { type FC, useState } from 'react';
import { ReactComponent as Menu } from 'src/components/svg/group-menu.svg';
import EditAdminModal from '../partials/edit-primary-admin-modal/edit-primary-admin-modal';
import styles from './primary-admin.module.scss';
import { ButtonToolbar, DropdownButton } from 'react-bootstrap';

const PrimaryAdmin: FC = (props: any) => {
	const [showEdit, setShowEdit] = useState(false);

	const handleModalClose = () => {
		setShowEdit(false);
	};

	const {
		currentAdminUser: {
			firstName = '',
			lastName = '',
			emailAddress = '',
			extension = '',
			phoneNumber = '',
			mobileNumber = '',
			countryCode = '',
		} = {},
		adminUsers = [],
		companyInfo,
		updatePrimaryAdmin,
		requestCompanyProfile,
	} = props;

	const createMoreActions = () => {
		return (
			<ButtonToolbar className='btn-group-vertical'>
				<DropdownButton
					size='sm'
					title={<Menu />}
					id='userMoreActions'
					data-test-auto='ee9b19bf-a988-4ad2-91da-160701446a97'
					className={styles.adminCardMoreOptions}>
					<li
						data-test-auto='5ddab107-dd4b-4886-9634-29193ebf4f9a'
						key='Edit'
						onClick={() => {
							setShowEdit(true);
						}}
						className={styles.company_card_header_menu_dropdown_edit}>
						Edit
					</li>
				</DropdownButton>
			</ButtonToolbar>
		);
	};

	return (
		<div className={styles.primary_admin_wrapper}>
			<div className={styles.sectional_header}>Primary Admin</div>
			<div className={styles.primary_admin_card_wrapper}>
				<div className={styles.primary_admin_cards_container}>
					<div className={styles.primary_admin_card_header}>
						<div className={styles.primary_admin_card_left}>
							<div
								className={styles.primary_admin_card_name}
								title={`${firstName} ${lastName}`}>{`${firstName} ${lastName}`}</div>
						</div>
						<div className={styles.primary_admin_card_right}>{createMoreActions()}</div>
					</div>
					<div className={styles.primary_admin_card_content}>
						<div className={styles.primary_admin_card_content_top}>
							<div
								className={styles.primary_admin_card_email_address}
								title={emailAddress}>
								{emailAddress}
							</div>
						</div>
					</div>

					{countryCode?.length > 0 ? (
						<div
							className={styles.primary_admin_card_mobile_number}
							title={countryCode + ' ' + mobileNumber}>
							{'(' + countryCode + ')'} {mobileNumber}
						</div>
					) : null}
				</div>
			</div>
			{showEdit && (
				<EditAdminModal
					show={showEdit}
					handleClose={handleModalClose}
					emailAddress={emailAddress}
					mobileNumber={mobileNumber}
					officeNumber={phoneNumber}
					extension={extension}
					userList={adminUsers}
					companyInfo={companyInfo}
					currentAdmin={props.currentAdminUser}
					updatePrimaryAdmin={updatePrimaryAdmin}
					requestCompanyProfile={requestCompanyProfile}
				/>
			)}
		</div>
	);
};

export default PrimaryAdmin;
