import { ProductType } from "../src/helper/Constants";
import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import { NavigateFunction } from "react-router-dom";
import store from "./configureStore";
import { userPrivilegeChanged } from "./pages/common/auth/auth.actions";

type ErrorResponse = {
    logoutCause: any
};

const AxiosInterceptorsSetup = (navigate: NavigateFunction) => {
    // Add a request interceptor
    axios.interceptors.request.use((config: any): any => {
        const token = store.getState().userAuth.user?.access_token;
        if (process.env.REACT_APP_API_URL) {
            const isApiUrl = config.url && config.url.startsWith(process.env.REACT_APP_API_URL);

            if (token && isApiUrl) {
                config.headers["Authorization"] = `Bearer ${token}`;
                config.headers["X-Product-Type"]=ProductType.SSSuitePortal;
                config.withCredentials=true;
            }            
        }

        return config;
    });

    axios.interceptors.response.use(
        function (response: AxiosResponse) {
            return response;
        },
        function (error: AxiosError<ErrorResponse>) {
            const errorResponseStatus = error.response?.status;
            if (errorResponseStatus == 512) {
                if (location.pathname !== "/restricted-access") {
                    error.response && navigate("/restricted-access");
                }
            }
            else if (errorResponseStatus == 419) {
                store.dispatch(userPrivilegeChanged(error.response?.data.logoutCause));
            }
            else {
                if (window.location.href.includes("restricted-access")) {
                    navigate("/");
                }
            }
            return Promise.reject(error);
        }
    );
};

export default AxiosInterceptorsSetup;
