import { Reducer } from 'redux';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { AppThunkAction } from '../../store/index';
import { actionTypes } from './Client.actions';
import { DispatchAction, IClientData, IClientListInfo, IClientModel, KnownAction } from './Client.model';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { API_BASE_URL, CommonMessages } from '../../helper/Constants';
import { actionTypes as notificationActionTypes } from 'src/pages/common/notification/notification.types';
import { download } from "src/helper/FileUtilities";
import { logger } from 'src/oidcClient/authProvider';
import { clientMngmntResources } from 'src/helper/rbac-constants';

export const actionCreators = {
    requestAllClients:
        (
            reload = false,
            pageNo = 1,
            pageSize = 10,
            sortBy = '',
            sortOrder = '',
            filterText = '',
            newClientBasicInfoIds = '',
            filterLocation = '',
        ): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            try {
                const state = getState();
                if (reload || state.clientsList.clients.length === 0) {
                    dispatch({ type: actionTypes.REQUEST_CLIENT_LIST });
                    const officeLocationsValue = filterLocation.split(',').includes('0') ? '' : filterLocation;
                    const queryString = `?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filterText=${encodeURIComponent(filterText)}&newClientBasicInfoIds=${newClientBasicInfoIds}&filterLocation=${officeLocationsValue}`;
                    const response: AxiosResponse<IClientListInfo> = await axios.get(
                        API_BASE_URL + '/api/client-info/all' + queryString
                    );
                    dispatch({
                        type: actionTypes.RECEIVE_CLIENT_LIST,
                        clientListInfo: response.data,
                    });
                }
            } catch (error: any) {
                logger && logger.trackWarning('Error while requestAllClients', { 'Error': error?.response?.data});
                dispatch({
                    type: actionTypes.ERROR_CLIENT_MANAGEMENT,
                    statusMessage: error,
                    statusType: StatusType.Error,
                });
            }
        },
    deleteClient: (clientBasicInfoId:number, callback: (value:boolean)=>void, resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.delete(API_BASE_URL + "/api/client-info/"+clientBasicInfoId, { headers: { 'X-Resource-Id': resourceId } })
        .then((response: AxiosResponse) => {
            callback(true);
        }).catch(function (error) {
            logger && logger.trackWarning('Error while deleteClient', { 'Error': error?.response?.data});
            callback(false);
            dispatch({
                type: actionTypes.ERROR_CLIENT_MANAGEMENT, statusMessage: error,
                statusType: StatusType.Error
            });
        });
    },

    deleteMultipleClients: (clientBasicInfoIds: number[], callback: (value: boolean) => void, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.post(API_BASE_URL + "/api/client-info/delete-multiple", clientBasicInfoIds, { headers: { 'X-Resource-Id': resourceId } })
            .then((response: AxiosResponse) => {
                callback && callback(true);
            }).catch(function (error) {
                callback && callback(false);
                dispatch({
                    type: actionTypes.ERROR_CLIENT_MANAGEMENT, statusMessage: error,
                    statusType: StatusType.Error
                });
            });
    },

    editMultipleClients: (clientBasicInfoIds: number[],locationId: number, callback: (value: boolean) => void, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.put(API_BASE_URL + "/api/client-info/edit-multiple",{ clientBasicInfoIds:clientBasicInfoIds,locationId:locationId}, { headers: { 'X-Resource-Id': resourceId } })
            .then((response: AxiosResponse) => {
                callback && callback(true);
            }).catch(function (error) {
                callback && callback(false);
                dispatch({
                    type: actionTypes.ERROR_CLIENT_MANAGEMENT, statusMessage: error,
                    statusType: StatusType.Error
                });
            });
    },

    bulkEditClients: (unSelectedClientBasicInfoIds: number[],locationId: number,callback: (value: boolean) => void, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.put(API_BASE_URL + "/api/client-info/bulk-edit", {unSelectedClientBasicInfoIds:unSelectedClientBasicInfoIds, locationId: locationId,},{ headers: { 'X-Resource-Id': resourceId } })
            .then((response: AxiosResponse) => {
                dispatch({type: notificationActionTypes.NOTIFICATION, 
                    statusMessage: "",
                    statusType: StatusType.Success});
                callback && callback(true);

            }).catch(function (error) {
                callback && callback(false);
                dispatch({
                    type: actionTypes.ERROR_CLIENT_MANAGEMENT, statusMessage: error,
                    statusType: StatusType.Error
                });
            });
    },

    bulkDeleteClients: (unSelectedClientBasicInfoIds: number[], callback: (value: boolean) => void, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.post(API_BASE_URL + "/api/client-info/bulk-delete", unSelectedClientBasicInfoIds, { headers: { 'X-Resource-Id': resourceId } })
            .then((response: AxiosResponse) => {
                dispatch({type: notificationActionTypes.NOTIFICATION, 
                    statusMessage: CommonMessages.BulkOperationDelay,
                    statusType: StatusType.Success});
                callback && callback(true);

            }).catch(function (error) {
                callback && callback(false);
                dispatch({
                    type: actionTypes.ERROR_CLIENT_MANAGEMENT,
                    statusMessage: error,
                    statusType: StatusType.Error
                });
            });
    },

    requestClientInfoById: (clientBasicInfoId: number, callback: (client: IClientModel) => void):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            axios.get(API_BASE_URL + '/api/client-info/GetClientInfoById/' + clientBasicInfoId,
            {
                headers: {
                    'X-Resource-Id': clientMngmntResources.editClient,
                }
            })
                .then((response: AxiosResponse) => {
                    const { data } = response;
                    callback(data);
                }).catch(function (error) {
                    logger && logger.trackWarning('Error while requestClientInfoById', { 'Error': error?.response?.data});
                    dispatch({
                        type: actionTypes.ERROR_CLIENT_MANAGEMENT, statusMessage: error,
                        statusType: StatusType.Error
                    });
                });
        },
        exportClientDetailsAsExcel: (queryString: string, callback?: () => void, resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
            axios.get(API_BASE_URL + "/api/client-info/clients-for-exportexcel" + queryString, {
                responseType: 'blob',
                headers: {
                    'X-Resource-Id': resourceId
                }
            })
                .then((response: AxiosResponse) => {
                    const { data } = response;
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const date = new Date();
                    const formattedDate = date.toLocaleDateString("en-GB", {
                        day: "2-digit", month: "short", year: "numeric"
                    }).replace(/ /g, "");
                    download(url, `ClientManagement_${formattedDate}.xlsx`);
                    if (callback) {
                        callback();
                    }
                })
                .catch(function (error: AxiosError) {
                    logger && logger.trackWarning('Error while exportClientDetailsAsExcel', { 'Error': error?.response?.data});
                    if(error.response)
                    {
                        const statusMessage: string = error.message ?? error.message;
                        if (typeof statusMessage === 'string')
                            dispatch({
                                type: notificationActionTypes.NOTIFICATION,
                                statusMessage,
                                statusType: StatusType.Error,
                            });
                    }
                });
        }
};

const unloadedState: IClientData = {
    isLoading: false,
    clients: [],
    count: 0,
} as IClientData;

export const reducer: Reducer<IClientData> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchAction;

    switch (action.type) {
        case actionTypes.REQUEST_CLIENT_LIST:
            return {
                isLoading: true,
                clients: [...state.clients],
                count: state.count,
            } as IClientData;
        case actionTypes.RECEIVE_CLIENT_LIST:
            return {
                isLoading: false,
                clients: action.clientListInfo.items,
                count: action.clientListInfo.count,
            } as IClientData;
        case actionTypes.ERROR_CLIENT_MANAGEMENT:
            const errorState: IClientData = Object.assign({}, state);
            errorState.isLoading = false;
            return errorState;

        default:
            return state;
    }
};
