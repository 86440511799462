import * as React from 'react';
import './OverlayLoader.style.scss';

export interface IOverlayProps {
	show: boolean;
	text: string;
	id?: string;
	width?: string;
	setOverlayRef?: (instance: any) => void;
}

export class OverlayLoader extends React.Component<IOverlayProps, {}> {
	static defaultProps = {
		id: 'loading',
		setOverlayRef: (instance) => {},
	} as IOverlayProps;

	public render() {
		if (this.props.show) {
			return (
				<div
					className='overlay-loader'
					style={{ zIndex: 100, width: this.props.width }}
					id={this.props.id!}
					ref={(instance: any) => {
						return this.props.setOverlayRef ? this.props.setOverlayRef(instance) : false;
					}}>
					<p className='overlay-loader-text'>{this.props.text}</p>
				</div>
			);
		}
		return false;
	}
}
export default OverlayLoader;
