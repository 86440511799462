import React, { useEffect, useRef, useState } from 'react';
import ClientHeader from './clientHeader';
import ClientListTable from './clientListTable';
import {
	IColumnOptions,
	IEro,
	PageProps,
	defaultColumnsOptions,
	initialClient,
	IOfficeLocation,
} from '../../modules/clientManagement/Client.model';
import { IClientData, IClientModel } from './Client.model';
import ClientPagination from './clientPagination';
import ConfirmationPopup from '../../components/common/confirmationPopup';
import {
	ClientBulkDeleteConfirmationPopupConstants,
	ClientBulkEditConfirmationPopupConstants,
	ClientDeleteConfirmationPopupConstants,
} from '../../helper/Constants';
import { clientMngmntResources } from '../../helper/rbac-constants';
import ColumnOptions from './columnOptions';
import { fetchColumnOptions, fetchEro, fetchOfficeLocation } from './Client.api'; 
import ClientModal from './clientModal';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ICustomDropdownOption } from 'src/components/common/customDropdown/CustomDropdownWithCustomOptions';
import { logger } from '../../oidcClient/authProvider';
import BulkOfficeLocationUpdationModal from './bulkOfficeLocationUpdationModal/bulkOfficeLocationUpdationModal';

interface ClientsProps {
	clientsList: IClientData;
	requestAllClients: (
		reload?: boolean,
		pageNo?: number,
		pageSize?: number,
		sortBy?: string,
		sortOrder?: string,
		filterText?: string,
		newClientBasicInfoIds?: string,
		filterLocation?: string,
	) => void;
	requestResourceIds: () => void;
	exportClientDetailsAsExcel: (queryString: string, callback?: () => void) => void;
	deleteClient: (clientBasicInfoId: number, callback: (value: boolean) => void, resourceId?: string) => void;
	deleteMultipleClients: (
		clientBasicInfoIds: number[],
		callback: (value: boolean) => void,
		resourceId?: string,
	) => void;
	bulkDeleteClients: (clientBasicInfoIds: number[], callback: (value: boolean) => void, resourceId?: string) => void;
	requestClientInfoById: (clientBasicInfoId: number, callback: (client: IClientModel) => void) => void;
	editMultipleClients: (
		clientBasicInfoIds: number[],
		locationId: number,
		callback: (value: boolean) => void,
		resourceId?: string,
	) => void;
	bulkEditClients: (
		unSelectedClientBasicInfoIds: number[],
		locationId: number,
		callback: (value: boolean) => void,
		resourceId?: string,
	) => void;
}

export interface ISortColumn {
	sortBy: string;
	sortOrder: string;
}

const Clients: React.FC<ClientsProps> = (props) => {
	const { clients, isLoading, count } = props.clientsList;
	const [showDeleteClientModal, setShowDeleteClientModal] = useState(false);
	const [selectedClient, setSelectedClient] = useState(initialClient as IClientModel);
	const [deleteDisabled, setDeleteDisabled] = useState(false);
	const [page, setPage] = useState<PageProps>({ pageIndex: 1, pageSize: 10 });
	const [inputText, setInputText] = useState<string>('');
	const [searchText, setSearchText] = useState<string>('');
	const [sortColumn, setSortColumn] = useState<ISortColumn>({
		sortBy: 'clientId',
		sortOrder: 'asc',
	});
	const [showClientModal, setShowClientModal] = useState<boolean>(false);
	const [showBulkDeleteModal, setShowBulkDeleteModal] = useState<boolean>(false);
	const [showBulkEditModal, setShowBulkEditModal] = useState<boolean>(false);
	const [showColumnOptions, setShowColumnOptions] = useState<boolean>(false);
	const [activeColumns, setActiveColumns] = useState<IColumnOptions[]>(defaultColumnsOptions);
	const [eros, setEros] = useState<IEro[]>([]);
	const [newClientBasicInfoIds, setNewClientBasicInfoIds] = useState<number[]>([]);
	const [firstColumnWidth, setFirstColumnWidth] = useState<string>('100%');
	const [officeLocations, setOfficeLocations] = useState<IOfficeLocation[]>([]);
	const [selectedOfficeLocations, setSelectedOfficeLocations] = useState<ICustomDropdownOption[]>([]);
	const [selectedClients, setSelectedClients] = useState<IClientModel[]>([]);
	const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
	const [showBulkSelectionMessage, setShowBulkSelectionMessage] = useState<boolean>(false);
	const [isBulkSelectionEnabled, setIsBulkSelectionEnabled] = useState<boolean>(false);
	const [unSelectedClients, setUnSelectedClients] = useState<IClientModel[]>([]);
	const [bulkEditDisabled, setBulkEditDisabled] = useState(false);
	const [isOfficeLocationOptionNotSelected, setIsOfficeLocationOptionNotSelected] = useState(false);

	const firstColumnRef = useRef<any>(null);

	useEffect(() => {
		props.requestAllClients(
			true,
			page.pageIndex,
			page.pageSize,
			sortColumn.sortBy,
			sortColumn.sortOrder,
			searchText,
			newClientBasicInfoIds.join(),
			selectedOfficeLocations.map((x) => x.value).join(),
		);
		props.requestResourceIds();
		fetchColumnOptions(setActiveColumns);
		fetchEro(setEros);
		fetchOfficeLocation(setOfficeLocations);
	}, []);
	
	useEffect(() => {
		if (firstColumnRef.current?.offsetWidth < 211) {
			setFirstColumnWidth('210px');
		} else {
			setFirstColumnWidth('100%');
		}
	}, [activeColumns]);  

	useEffect(() => {
		props.requestAllClients(
			true,
			page.pageIndex,
			page.pageSize,
			sortColumn.sortBy,
			sortColumn.sortOrder,
			searchText,
			newClientBasicInfoIds.join(),
			selectedOfficeLocations.map((x) => x.value).join(),
		);
	}, [sortColumn]);

	//#region Selection
	useEffect(() => {
		if (isBulkSelectionEnabled) {
			let newSelectedClients = clients;
			newSelectedClients = newSelectedClients.filter(c => !unSelectedClients.find(uc => uc.clientBasicInfoId === c.clientBasicInfoId));
			setSelectedClients(newSelectedClients);	
			if (newSelectedClients.length === clients.length) {
				setIsSelectAll(true);
			} else {
				setIsSelectAll(false);
			}
		} else {
			setSelectedClients([]);
			setUnSelectedClients([]);
			setIsSelectAll(false);
			setShowBulkSelectionMessage(false);
		}
	}, [clients]);

	const handleSelect = (client: IClientModel) => {
		setSelectedClients((prevSelectedClients) => {
			const clientIsAlreadySelected = prevSelectedClients.some((c) => c.clientBasicInfoId === client.clientBasicInfoId);

			const newSelectedClients = clientIsAlreadySelected
				? prevSelectedClients.filter((c) => c.clientBasicInfoId !== client.clientBasicInfoId)
				: [...prevSelectedClients, client];

			setUnSelectedClients((prevUnSelectedClients) => {
				if (clientIsAlreadySelected) {
					const clientIsAlreadyUnselected = prevUnSelectedClients.some(
						(c) => c.clientBasicInfoId === client.clientBasicInfoId,
					);
					return clientIsAlreadyUnselected ? prevUnSelectedClients : [...prevUnSelectedClients, client];
				} else {
					return prevUnSelectedClients.filter((c) => c.clientBasicInfoId !== client.clientBasicInfoId);
				}
			});

			const allItemsAreSelected = newSelectedClients.length === clients.length;
			setIsSelectAll(allItemsAreSelected);

			return newSelectedClients;
		});
	};

	const handleSelectAll = () => {
		setIsSelectAll((prevSelectAll) => {
			const selectAllToggled = !prevSelectAll;
			setShowBulkSelectionMessage(selectAllToggled);

			if (selectAllToggled) {
				setSelectedClients(clients);
			} else {
				resetSelections();
			}

			return selectAllToggled;
		});
	};

	const resetSelections = () => {
		setIsBulkSelectionEnabled(false);
		setSelectedClients([]);
		setUnSelectedClients([]);
	};
	//#endregion Selection

	const goToPage = (newPageIndex: number, newPageSize: number) => {
		const totalPages = Math.ceil(count / page.pageSize);
		if (
			(newPageIndex !== page.pageIndex && newPageIndex <= totalPages && newPageIndex >= 1) ||
			newPageSize !== page.pageSize
		) {
			const newPage = { ...page };
			newPage.pageIndex = newPageIndex;
			newPage.pageSize = newPageSize;
			setPage(newPage);
			props.requestAllClients(
				true,
				newPageIndex,
				newPageSize,
				sortColumn.sortBy,
				sortColumn.sortOrder,
				searchText,
				newClientBasicInfoIds.join(),
				selectedOfficeLocations.map((x) => x.value).join(),
			);
		}
	};

	const filterSearchText = (text: string) => {
		logger && logger.trackTrace('filterSearchText: Search button clicked');
		const newSearchText = text.trim();
		const newPage = { ...page };
		newPage.pageIndex = 1;
		setPage(newPage);
		props.requestAllClients(
			true,
			newPage.pageIndex,
			newPage.pageSize,
			sortColumn.sortBy,
			sortColumn.sortOrder,
			newSearchText,
			newClientBasicInfoIds.join(),
			selectedOfficeLocations.map((x) => x.value).join(),
		);
		setSearchText(newSearchText);
	};

	const onDeleteClientSelection = (client: IClientModel) => {
		logger && logger.trackTrace('onDeleteClientSelection: Delete client button clicked');
		setSelectedClient(client);
		setShowDeleteClientModal(true);
	};

	const onDelete = (client: IClientModel) => {
		logger && logger.trackTrace('onDelete: Deleting client');
		setDeleteDisabled(true);
		props.deleteClient(
			client.clientBasicInfoId,
			(success: boolean) => {
				if (success) {
					onDeleteSuccess();
				} else {
					setDeleteDisabled(false);
					setShowDeleteClientModal(false);
					setSelectedClient(initialClient);
				}
			},
			clientMngmntResources.gridPrefix + '_' + clientMngmntResources.deleteButton,
		);
	};

	const goToPreviousPageOnDeleteClient = () => {
		const currentPageIndex = page.pageIndex;
		if (clients.length === 1 && currentPageIndex > 1) {
			return currentPageIndex - 1;
		} else {
			return currentPageIndex;
		}
	};

	const onDeleteSuccess = () => {
		logger && logger.trackTrace('onDeleteSuccess: After deleting client');
		const newPageIndex = goToPreviousPageOnDeleteClient();
		props.requestAllClients(
			true,
			newPageIndex,
			page.pageSize,
			sortColumn.sortBy,
			sortColumn.sortOrder,
			searchText,
			newClientBasicInfoIds.join(),
			selectedOfficeLocations.map((x) => x.value).join(),
		);
		setPage({ ...page, pageIndex: newPageIndex });
		setSelectedClient(initialClient);
		setDeleteDisabled(false);
		setShowDeleteClientModal(false);
	};

	const onDeleteCancel = () => {
		logger && logger.trackTrace('onDeleteCancel: Delete client cancelled');
		setShowDeleteClientModal(false);
		setSelectedClient(initialClient);
	};

	//#region Bulk Delete
	const onBulkDeleteSuccess = () => {
		setDeleteDisabled(false);
		setShowBulkDeleteModal(false);
		setSelectedClients([]);
		setIsSelectAll(false);
		setIsBulkSelectionEnabled(false);
		const newPageIndex = goToPreviousPageOnDeleteClient();
		props.requestAllClients(
			true,
			newPageIndex,
			page.pageSize,
			sortColumn.sortBy,
			sortColumn.sortOrder,
			searchText,
			newClientBasicInfoIds.join(),
			selectedOfficeLocations.map((x) => x.value).join(),
		);
		setPage({ ...page, pageIndex: newPageIndex });
	};
	const onBulkDelete = () => {
		setDeleteDisabled(true);
		if (selectedClients.length === 1) {
			props.deleteClient(
				selectedClients[0].clientBasicInfoId,
				onBulkDeleteSuccess,
				clientMngmntResources.gridPrefix + '_' + clientMngmntResources.deleteButton,
			);
		} else {
			if (isBulkSelectionEnabled) {
				props.bulkDeleteClients(
					unSelectedClients.map((x) => x.clientBasicInfoId),
					onBulkDeleteSuccess,
					clientMngmntResources.gridPrefix + '_' + clientMngmntResources.deleteButton,
				);
			} else {
				props.deleteMultipleClients(
					selectedClients.map((x) => x.clientBasicInfoId),
					onBulkDeleteSuccess,
					clientMngmntResources.gridPrefix + '_' + clientMngmntResources.deleteButton,
				);
			}
		}
	};
	//#endregion

	const onBulkEditSuccess = () => {
		setBulkEditDisabled(false);
		setShowBulkEditModal(false);
		setSelectedClients([]);
		setIsSelectAll(false);
		setIsBulkSelectionEnabled(false);
		const newPageIndex = goToPreviousPageOnDeleteClient();
		props.requestAllClients(
			true,
			newPageIndex,
			page.pageSize,
			sortColumn.sortBy,
			sortColumn.sortOrder,
			searchText,
			newClientBasicInfoIds.join(),
			selectedOfficeLocations.map((x) => x.value).join(),
		);
		setPage({ ...page, pageIndex: newPageIndex });
	};

	const onBulkEdit = (locationId: number) => {
		if (!locationId) {
			setIsOfficeLocationOptionNotSelected(true);
		} else {
			setBulkEditDisabled(true);
			if (isBulkSelectionEnabled) {
				props.bulkEditClients(
					unSelectedClients.map((x) => x.clientBasicInfoId),
					locationId,
					onBulkEditSuccess,
				);
			} else {
				props.editMultipleClients(
					selectedClients.map((x) => x.clientBasicInfoId),
					locationId,
					onBulkEditSuccess,
				);
			}
		}
	};

	const requestClientsAndColumns = () => {
		props.requestAllClients(
			true,
			page.pageIndex,
			page.pageSize,
			sortColumn.sortBy,
			sortColumn.sortOrder,
			searchText,
			newClientBasicInfoIds.join(),
			selectedOfficeLocations.map((x) => x.value).join(),
		);
		fetchColumnOptions(setActiveColumns);
		setFirstColumnWidth('100%');
	};

	const requestAllClients = (selectedOfficeLocations: ICustomDropdownOption[]) => {
        props.requestAllClients(
            true,
			page.pageIndex,
			page.pageSize,
			sortColumn.sortBy,
			sortColumn.sortOrder,
			searchText,
			newClientBasicInfoIds.join(),
            selectedOfficeLocations.map((x) => x.value).join(),
		);
	};

	const showBulkAlertMessage = () => {
		let totalCount = count;
		if (totalCount > page.pageSize && showBulkSelectionMessage)
			return (
				<div
					className='col-sm-6 col-lg-8'
					style={{ display: 'inline-block', zIndex: 10, padding: '0px 0px' }}>
					<Alert
						variant='warning'
						style={{ padding: '6px', width: 'fit-content', marginBottom: '10px' }}>
						<span
							className='fa fa-exclamation-triangle'
							style={{ marginRight: '5px', paddingLeft: '5px' }}></span>{' '}
						All {page.pageSize} records on this page are selected. To select the remaining {totalCount - page.pageSize}{' '}
						filtered records,&nbsp;
						<Link
							to={'#'}
							className='ml-1'
							style={{ textDecoration: 'underline' }}
							onClick={() => onBulkSelectionChange(true)}>
							click here
						</Link>
					</Alert>
				</div>
			);
	};

	const onBulkSelectionChange = (isBulkEnabled: boolean) => {
		setIsBulkSelectionEnabled(isBulkEnabled);
		setShowBulkSelectionMessage(false);
		setSelectedClients(clients);
    };

	const onHide = () => {
		setShowBulkEditModal(false);
		setIsOfficeLocationOptionNotSelected(false);
	};
	return (
		<>
			<article
				id='usersDetails'
				className='usersDetails'>
				<ClientHeader
					inputText={inputText}
					page={page}
					sortColumn={sortColumn}
					clientCount={count}
					newClientBasicInfoIds={newClientBasicInfoIds}
					setInputText={setInputText}
					filterSearchText={filterSearchText}
					showClientModal={showClientModal}
					setShowClientModal={setShowClientModal}
					selectedClients={selectedClients}
					setShowBulkDeleteModal={setShowBulkDeleteModal}
					setShowBulkEditModal={setShowBulkEditModal}
					setShowColumnOptions={setShowColumnOptions}
					exportClientDetailsAsExcel={props.exportClientDetailsAsExcel}
					selectedOfficeLocations={selectedOfficeLocations}
					setSelectedOfficeLocations={setSelectedOfficeLocations}
					officeLocationList={officeLocations}
					requestAllUsers={requestAllClients}
				/>

				{showBulkAlertMessage()}
				<ClientListTable
					clients={clients}
					isLoading={isLoading}
					sortColumn={sortColumn}
					setSortColumn={setSortColumn}
					handleDeleteClientClick={onDeleteClientSelection}
					setShowClientModal={setShowClientModal}
					activeColumns={activeColumns}
					requestClientInfoById={props.requestClientInfoById}
					setSelectedClient={setSelectedClient}
					newClientBasicInfoIds={newClientBasicInfoIds}
					firstColumnWidth={firstColumnWidth}
					firstColumnRef={firstColumnRef}
					selectedClients={selectedClients}
					isSelectAll={isSelectAll}
					handleSelect={handleSelect}
					handleSelectAll={handleSelectAll}
				/>
				<ClientPagination
					count={count}
					page={page}
					goToPage={goToPage}
				/>
			</article>

			{showClientModal && (
				<ClientModal
					eros={eros}
					page={page}
					searchText={searchText}
					sortColumn={sortColumn}
					client={selectedClient}
					setPage={setPage}
					setClient={setSelectedClient}
					setShowClientModal={setShowClientModal}
					requestAllClients={props.requestAllClients}
					newClientBasicInfoIds={newClientBasicInfoIds}
					setNewClientBasicInfoIds={setNewClientBasicInfoIds}
					officeLocations={officeLocations}
					selectedOfficeLocations={selectedOfficeLocations}
				/>
			)}

			{showColumnOptions && (
				<ColumnOptions
					activeColumns={activeColumns}
					setActiveColumns={setActiveColumns}
					setShowColumnOptions={setShowColumnOptions}
					requestClientsAndColumns={requestClientsAndColumns}
				/>
			)}

			{showDeleteClientModal && selectedClient && (
				<ConfirmationPopup
					show={showDeleteClientModal}
					actionButtonType={'danger'}
					cancelText={ClientDeleteConfirmationPopupConstants.cancelText}
					header={ClientDeleteConfirmationPopupConstants.headerText}
					message={
						<>
							{ClientDeleteConfirmationPopupConstants.bodyText} <strong>"{selectedClient.clientId}"</strong>.
						</>
					}
					model={selectedClient}
					okText={ClientDeleteConfirmationPopupConstants.okText}
					onCancel={onDeleteCancel}
					onOk={onDelete}
					onHide={onDeleteCancel}
					actionDisabled={deleteDisabled}
					disabledText={''}
				/>
			)}

			{showBulkDeleteModal && (
				<ConfirmationPopup
					show={showBulkDeleteModal}
					actionButtonType={'danger'}
					cancelText={ClientBulkDeleteConfirmationPopupConstants.cancelText}
					header={ClientBulkDeleteConfirmationPopupConstants.headerText}
					message={ClientBulkDeleteConfirmationPopupConstants.bodyText}
					model={selectedClients}
					okText={ClientBulkDeleteConfirmationPopupConstants.okText}
					onCancel={() => setShowBulkDeleteModal(false)}
					onOk={onBulkDelete}
					onHide={() => setShowBulkDeleteModal(false)}
					actionDisabled={deleteDisabled}
				/>
			)}

			{showBulkEditModal && (
				<BulkOfficeLocationUpdationModal
					show={showBulkEditModal}
					actionButtonType={'primary'}
					cancelText={ClientBulkEditConfirmationPopupConstants.cancelText}
					header={ClientBulkEditConfirmationPopupConstants.headerText}
					model={selectedClients}
					okText={ClientBulkEditConfirmationPopupConstants.okText}
					onCancel={onHide}
					onOk={onBulkEdit}
					onHide={onHide}
					actionDisabled={bulkEditDisabled}
					officeLocations={officeLocations}
					isOfficeLocationOptionNotSelected={isOfficeLocationOptionNotSelected}
					setIsOfficeLocationOptionNotSelected={setIsOfficeLocationOptionNotSelected}
				/>
			)}
		</>
	);
};

export default Clients;
