/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import { type FC, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from 'src/components/svg/CloseIcon';
import { ReactComponent as Header } from 'src/components/svg/person.svg';

import { brandingSecResources } from '../../../../helper/rbac-constants';
import styles from './header-design-overlay.module.scss';
import { type IHeaderDesignOverlayProps } from './header-design-overlay.types';

const HeaderDesignOverlay: FC<IHeaderDesignOverlayProps> = (props) => {
	const { show, onHide, header, fontColor, backgroundColor, whiteLogoPath, companyName, logoPath, onSave } = props;
	const [changedFontColor, setChangedFontColor] = useState('');
	const [changedBackgroundColor, setChangedBackgroundColor] = useState('');

	const handleFontColorChange = (color: string): void => {
		setChangedFontColor(color);
	};

	const handleBackgroundColorChange = (color: string): void => {
		setChangedBackgroundColor(color);
	};

	useEffect(() => {
		setChangedFontColor(fontColor);
		setChangedBackgroundColor(backgroundColor);
	}, [fontColor, backgroundColor]);

	const handleHide = (): void => {
		onHide();
		setChangedFontColor(fontColor);
		setChangedBackgroundColor(backgroundColor);
	};

	return (
		<Modal
			className={styles.header_design_modal}
			show={show}
			onHide={handleHide}>
			<Modal.Header>
				<Modal.Title className={styles.header_design_modal_tilte}>{header}</Modal.Title>
				<div
					className={styles.header_design_modal_closeIcon}
					data-test-auto='branding-management_header-design_edit-design_hide'
					onClick={handleHide}>
					<CloseIcon />
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className={styles.header_design_section_header}>
					<div className={styles.header_design_section_header_title}>Update Design</div>
					<div className={styles.header_design_section_header_color}>
						<div className={styles.header_design_section_color_description}>
							<div className={styles.header_design_section_color_title}>Font Color</div>
							<input
								className={styles.header_design_section_color_display}
								type='color'
								value={changedFontColor}
								data-test-auto='branding-management_header-design_edit-design_font-color-picker'
								onChange={(event) => {
									handleFontColorChange(event.target.value);
								}}
							/>
						</div>
						<div className={styles.header_design_section_color_description}>
							<div className={styles.header_design_section_color_title}>Background Color</div>
							<input
								className={styles.header_design_section_color_display}
								type='color'
								value={changedBackgroundColor}
								data-test-auto='branding-management_header-design_edit-design_bg-color-picker'
								onChange={(event) => {
									handleBackgroundColorChange(event.target.value);
								}}
							/>
						</div>
					</div>
				</div>
				<div className={styles.header_design_section_preview}>
					<div className={styles.header_design_section_preview_header}>Preview</div>
					<div
						className={styles.header_design_section_preview_section}
						style={{ backgroundColor: changedBackgroundColor }}>
						<div className={styles.preview_company_section}>
							{whiteLogoPath.length > 0 ? (
								<img
									src={whiteLogoPath}
									className={styles.company_logo}
									alt='Company Logo'
								/>
							) : (
								<div
									className={styles.company_name}
									style={{ color: changedFontColor }}>
									{companyName}
								</div>
							)}
						</div>
						<div
							className={styles.contact_div}
							style={{ color: changedFontColor }}>
							<Header />
							Contact
						</div>
					</div>
				</div>
				<div style={{ height: '280px' }}>
					<div className={styles.welcome_page}>
						<div
							style={{ flexWrap: 'nowrap' }}
							className='row'>
							<div
								style={{ alignSelf: 'center' }}
								className='col-lg-6 col-md-6 col-sm-6'>
								<div className={styles.welcome_text}>
									<div style={{ color: '#0973BA' }}>Hello again,</div>
									<div>It's tax season.</div>
								</div>

								<div className={styles.client_details_header}>
									<div className={styles.tax_year_header}>{new Date().getUTCFullYear() - 1} Tax Return</div>
									<div className={styles.client_name_header}>Taxpayer & Spouse</div>
								</div>

								<div>
									<button className={`${styles.get_started_button} btn btn-primary`}>Get Started</button>
								</div>

								<div>
									<div>
										<div className={styles.prepared_by_title}>Prepared by</div>
									</div>
									<div>
										<img
											alt='Company Logo'
											src={logoPath}
											className={styles.company_logo_div}
										/>
									</div>
								</div>
							</div>
							<div className='col-lg-6 col-md-6 col-sm-6'>
								<img
									style={{
										alignSelf: 'center',
										height: 200,
										width: 180,
										padding: 0,
									}}
									src={require('../../../../assets/images/WelcomeScreenIllustration.svg').default}
									alt='welcome-screen-logo'
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.app_footer}>
					<div className={styles.copyright}>Copyright © 2024 cPaperless LLC</div>
					<div className={styles.privacy_policy}>
						<a>Terms of service</a>
						<div className={styles.hyphen}>-</div>
						<a>Privacy Policy</a>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					className={styles.headerDesignOverlayCancel}
					variant='outline-secondary'
					data-test-auto='branding-management_header-design_edit-design_cancel'
					onClick={handleHide}>
					Cancel
				</Button>

				<Button
					data-resource-id={`${brandingSecResources.prefix}_${brandingSecResources.headerDesign}_${brandingSecResources.SaveButton}`}
					data-test-auto='branding-management_header-design_edit-design_update'
					onClick={() => onSave(changedFontColor, changedBackgroundColor)}>
					Update
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default HeaderDesignOverlay;
