import React, { FC } from 'react';

const HorizontalDivider: FC = () => {
    const styleAttr = {
        margin: '0px',
        padding: '0px',
        color: '#6B7075',
    };
    return <hr style={styleAttr} />;
};

export default HorizontalDivider;
