import React, { FC } from "react";
import { LoadingScreenConstants } from "../helper/Constants";
import { SafeSendSuite, SuiteLogo } from "./svg/LogoCollection";

const LoadingScreen: FC = () => {

    return (
    <div className="loading-screen-container flex-column-center-all">
        <SafeSendSuite width={350} />
        <div className="text-wrapper">
            <div className="loading-text" data-title="dot-spin">
                <div className="dot-spin"></div>
            </div>
        </div>
    
    </div>
    );
};

export default LoadingScreen;